import {useTranslation} from 'gatsby-plugin-react-i18next';
import React, {FC} from 'react';
import SocialNetworkAuthentication from '../../SocialNetworkAuthentication/SocialNetworkAuthentication';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import TextInputController from 'design-system/src/Components/Form/TextInputController/TextInputController';
import Metrics from 'design-system/src/Theme/Metrics';
import {useForm} from 'react-hook-form';
import Button from 'design-system/src/Components/Button/Button';
import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
import {toast} from 'react-toastify';
import {useAppDispatch} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {Pressable} from 'react-native';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface ISignUpForm {
  email: string;
}

const FirstSignUp: FC = () => {
  const {t} = useTranslation('auth');
  const {isMobile} = useBreakpoint();
  const {control, handleSubmit} = useForm<ISignUpForm>();

  const {isEmailExist} = useFireBaseAuthentication();

  const dispatch = useAppDispatch();

  const handleClick = async () => {
    if (await isEmailExist(control._formValues.email)) {
      toast.error(t('Email already exist'));
      return;
    }
    dispatch(
      appAuthSlice.actions.setFormData({email: control._formValues.email}),
    );
    dispatch(appAuthSlice.actions.setSocialNetworkSource('email'));
    dispatch(appAuthSlice.actions.setAuthStep({page: 'form', step: 0}));
  };

  const handleError = (error: any) => {
    console.log(error);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          maxWidth: 434,
          padding: '0 16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography
          variant={isMobile ? 'body2' : 'formTitle'}
          style={{
            textTransform: 'uppercase',
            // fontSize: 48,
            marginBottom: 25,
          }}>
          {t('Sign up')}
        </Typography>
        <SocialNetworkAuthentication />
        <Typography variant="body2" style={{marginBottom: 25, marginTop: 25}}>
          {t('Or')}
        </Typography>
        <TextInputController
          control={control}
          name="email"
          label={t('Email') + ''}
          rules={{
            required: t('Email is required') + '',
          }}
          placeholder={t('your@email.com') || ''}
          // placeholderTextColor={'#fff'}
          style={{marginBottom: Metrics.verticalScale(32)}}
          variant="transparent"
        />
        <Button
          variant="contained"
          size="small"
          title={t('Register')}
          fullWidth
          // loading={loading}
          onPress={handleSubmit(handleClick, handleError)}
        />
      </div>

      <div style={{marginTop: 34, display: 'flex', zIndex: 1}}>
        <Typography variant="body3">
          {t('Already have an account ?')}
        </Typography>
        <div style={{marginLeft: 6}}>
          <Pressable
            onPress={() => dispatch(appAuthSlice.actions.setAuthType('login'))}>
            <Typography variant="body3" color="primary">
              {t('Sign in')}
            </Typography>
          </Pressable>
        </div>
      </div>
    </>
  );
};

export default FirstSignUp;
