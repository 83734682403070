import { IContentTagFragmentFragment } from '@src/Api/Graphql/gql/types.generated';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useRegionCategoryTags from './useRegionCategoryTags';

const useRegionCategoryTagsMenu = (): {
  list: IContentTagFragmentFragment[];
  loading: boolean;
} => {
  const [fetchRegionTags, {data: regionTags, loading}] =
    useRegionCategoryTags();
  const {t} = useTranslation('translation');

  useEffect(() => {
    fetchRegionTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const taglist: IContentTagFragmentFragment[] = useMemo(() => {
    const _list: IContentTagFragmentFragment[] = [];
    if (regionTags && regionTags.content && regionTags.content.children?.items) {
      regionTags.content.children.items.forEach(item => {
        if (item && item.__typename === 'ContentTag') {
          _list.push(item);
        }
      });
    }
    return _list;
  }, [regionTags]);
  return {
    list: [
      {
        __typename: 'ContentTag',
        id: '',
        name: t('All') || '',
      },
      ...taglist,
    ],
    loading,
  };
};

export default useRegionCategoryTagsMenu;
