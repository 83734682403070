import React, {FC} from 'react';
import Carousel, {
  INavigationPosition,
} from 'design-system/src/WebComponent/Carousel/Carousel.web';
import ItemAppCarousel, {
  IItemAppCarouselProps,
} from './ItemAppCarousel/ItemAppCarousel';

interface IAppCarouselProps {
  items: IItemAppCarouselProps[];
  title?: string;
  navigationPosition?: INavigationPosition;
  aspectRatio?: number | [mobile: number, desktop: number];
  style?: React.CSSProperties;
}

const AppCarousel: FC<IAppCarouselProps> = ({
  items,
  title,
  navigationPosition = 'bottom',
  aspectRatio,
  style,
}) => {
  return (
    <Carousel
      style={style}
      title={title}
      items={items}
      render={(item, index) => {
        return (
          <ItemAppCarousel
            key={`carousel-${index}`}
            {...item}
            aspectRatio={aspectRatio}
          />
        );
      }}
      navigation={true}
      navigationPosition={navigationPosition}
      autoLoop
      loopTime={5000}
    />
  );
};

export default AppCarousel;
