import useOutsideClick from '@src/Hooks/useOutsideClick';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import useViewPort, {
  useGetAbsoluteLeft,
} from 'design-system/src/Hooks/useViewPort';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import {typographyVariantStyled} from 'design-system/src/Components/Typography/Typography.styles';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {FC, useEffect, useRef, useState} from 'react';

interface IAppDropDownTopMenuProps {
  title?: string;
  items?: IAppDropDownItem[];
  titleVariant?: keyof typeof typographyVariantStyled;
  containerWidth?: number | string;
}

export interface IAppDropDownItem {
  title?: string;
  onPress?(): void;
}

const AppDropDownTopMenu: FC<IAppDropDownTopMenuProps> = ({
  title,
  items,
  titleVariant = 'h2',
  containerWidth,
}) => {
  const {isMobile} = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const dropdownMenuRef = useRef(null);
  const triggerRef = useRef(null);
  useOutsideClick(triggerRef, () => setIsOpen(false));

  const {viewportHeight} = useViewPort();
  const {leftPosition} = useGetAbsoluteLeft(triggerRef, [isOpen]);

  return (
    <div
      ref={triggerRef}
      style={{
        position: 'relative',
      }}>
      <div
        style={{
          display: 'inline-flex',
          gap: 8,
          alignItems: 'center',
          width: 'fit-content',
          cursor: 'pointer',
        }}
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <Typography
          variant={titleVariant}
          color={hover ? 'primary' : undefined}>
          {title}
        </Typography>
        <Icon name="caret-down" color={hover ? '#EA6C13' : undefined} />
        {/* <span
          style={{
            fontSize: 12,
            cursor: 'pointer',
            color: hover ? '#EA6C13' : undefined,
          }}>
          {isOpen ? '▲' : '▼'}
        </span> */}
      </div>
      {isOpen && (
        <div
          ref={dropdownMenuRef}
          style={{
            position: isMobile ? 'fixed' : 'absolute',
            width: containerWidth ?? '100vw',
            top: isMobile ? 0 : 60,
            left: isMobile ? 0 : leftPosition,
            zIndex: 1000,
            padding: isMobile ? 0 : '0 60px',
            boxSizing: 'border-box',
            height: isMobile ? `${viewportHeight}px` : undefined,
          }}>
          <div
            style={{
              width: '100%',
              height: isMobile ? `${viewportHeight}px` : undefined,
              backgroundColor: 'rgba(27, 28, 33, 0.50)',
              backdropFilter: `blur(${isMobile ? 30 : 20}px)`,
              padding: isMobile ? '60px 0' : 40,
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'center' : undefined,
              justifyContent: isMobile ? 'space-between' : undefined,
            }}>
            {isMobile && <Typography variant="htitle">{title}</Typography>}
            {isMobile && (
              <style
                dangerouslySetInnerHTML={{
                  __html: 'body {overflow: hidden;}',
                }}
              />
            )}
            <div
              style={{
                gap: 11,
                display: isMobile ? 'inline-flex' : 'grid',
                flexDirection: isMobile ? 'column' : undefined,
                alignItems: isMobile ? 'center' : undefined,
                gridTemplateRows: 'repeat(5, 1fr)',
                gridTemplateColumns: 'repeat(4, 20%)',
                width: '100%',
                gridAutoFlow: 'column',
              }}>
              {items?.map((item, index) => (
                <PressableCustom key={`menu-${index}`} onPress={item.onPress}>
                  {({hover: itemHover}) => (
                    <Typography
                      variant="body2"
                      color={itemHover ? 'primary' : undefined}>
                      {item?.title}
                    </Typography>
                  )}
                </PressableCustom>
              ))}
            </div>
            {isMobile && (
              <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  height: 64,
                  width: 64,
                  borderRadius: 32,
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}>
                <Icon name="close" size="76" color="black" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppDropDownTopMenu;
