import React, {Children, PropsWithChildren} from 'react';
import {Pressable, View} from 'react-native';
import {darkTheme} from '../../../styles/Themes';
import Typography from '../../Typography/Typography';
import {cardAsideStyles} from './CardAside.styles';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';

type CardAsideProps = {
  title?: string;
  subtitle?: string;
  TrailingIc?: React.FC;
  titleColor?: string;
  infoColor?: string;
  leadingWidth?: number;
  isFilledParent?: boolean;
  isTrailingIconCentered?: boolean;
  onPressTrailingIc?: () => void;
};

const CardAside: React.FC<PropsWithChildren<CardAsideProps>> = ({
  title,
  subtitle,
  TrailingIc,
  titleColor,
  infoColor,
  leadingWidth,
  isFilledParent,
  isTrailingIconCentered,
  onPressTrailingIc,
  children,
}) => {
  const arrayChildren = Children.toArray(children);
  const {isMobile} = useBreakpoint();
  return (
    <View
      style={{
        position: 'relative',
        ...cardAsideStyles.container,
        ...(isFilledParent && {flex: 1}),
      }}>
      <View
        style={{
          ...cardAsideStyles.leading,
          ...(leadingWidth && {width: leadingWidth}),
          height: '100%',
          // @ts-ignore
          gap: isMobile ? 0 : 8,
        }}>
        {title && (
          <Typography
            variant="h4"
            color={() => titleColor || defaultTheme.white}
            numberOfLines={2}
            style={{
              ...cardAsideStyles.title,
              ...(!isMobile ? {marginTop: 0, marginBottom: 0} : {}),
              ...(isFilledParent ? {maxWidth: undefined} : {}),
              height: Metrics.verticalScale(32),
            }}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant="body1"
            numberOfLines={4}
            color={() => infoColor ?? darkTheme.colors.white}
            style={{
              ...cardAsideStyles.subtitle,
              color: infoColor ?? darkTheme.colors.white,
            }}>
            {subtitle}
          </Typography>
        )}
        {Children!.map(arrayChildren, (child, index) => {
          if (arrayChildren[index]) {
            if (index !== arrayChildren.length - 1) {
              return <View style={cardAsideStyles.itemMargin}>{child}</View>;
            }
            return (
              <View
                style={{
                  // marginTop: 'auto',
                  paddingBottom: Metrics.verticalScale(6),
                }}>
                {child}
              </View>
            );
          }
        })}
      </View>
      {TrailingIc && (
        <View
          style={{
            paddingTop: isMobile ? 0 : 32,
            flexDirection: 'column',
            justifyContent: isMobile ? 'center' : 'flex-start',
          }}>
          <Pressable
            onPress={onPressTrailingIc}
            style={[
              {
                ...(isTrailingIconCentered ? {justifyContent: 'center'} : {}),
              },
              {
                width: Metrics.horizontalScale(36),
                height: Metrics.horizontalScale(36),
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            ]}>
            <TrailingIc />
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default CardAside;
