import {StyleSheet} from 'react-native';
import Metrics, {isWeb, width} from '../../../Theme/Metrics';

export const HighlightedCarouselStyles = StyleSheet.create({
  container: {
    // TODO: handle web with
    width: isWeb ? 375 : width,
    // height: Metrics.verticalScale(408),
    height: '100%',
    justifyContent: 'flex-end',
  },
  header: {
    paddingTop: Metrics.verticalScale(138),
    paddingLeft: Metrics.horizontalScale(16),
    paddingRight: Metrics.horizontalScale(74),
  },
  tag: {
    marginBottom: Metrics.verticalScale(8),
  },
  titleDesktop: {
    marginBottom: Metrics.verticalScale(13),
    fontSize: 40,
  },
  titleMobile: {
    marginBottom: Metrics.verticalScale(13),
    fontSize: 18,
  },
  gradient: {
    color: 'rgba(0,0,0,0.2)',
  },
});
