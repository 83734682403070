import React, {FC} from 'react';

interface IItemPartnerProps {
  isOnRight: boolean;
  children?: React.ReactNode;
  onPress?(): void;
}

const ItemPartner: FC<IItemPartnerProps> = ({isOnRight, children, onPress}) => {
  return (
    <div
      style={{
        borderTop: '1px solid #32343E',
        borderRight: isOnRight ? undefined : '1px solid #32343E',
        borderBottom: '1px solid #32343E',
        cursor: onPress ? 'pointer' : 'default',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -1,
      }}
      onClick={onPress}>
      {children}
    </div>
  );
};

export default ItemPartner;
