/* eslint-disable react-native/no-inline-styles */
import React, {Suspense, useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {InnerLayoutProps} from '@src/LayoutProvider';
import useIsLoggedIn from '@src/Providers/AuthProvider/useIsLoggedIn';
import HomeContentLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/HomeContentLoader';
import {createPortal} from 'react-dom';
import AppLayoutConnectLogic from './AppLayoutConnectLogic';
import styled from 'styled-components';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import {useAppSelector} from '@src/Store/hooks';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useMember from '@src/Store/selectors/useMember';
import useChangeLanguage from '@src/Hooks/useChangeLanguage';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import {languageMapper, languageUnmap} from '@src/Services/i18n/languageMapper';
import useSetupRegion from '@src/Hooks/useSetupRegion';
import {cultureRegionMapper} from '@src/tools/cultureRegionMapper';

const RadioPlayerPage = React.lazy(
  () => import('@src/App/components/pages/listen/Radio/RadioPlayerPage'),
);
const AppBottomBar = React.lazy(() => import('./AppBottomBar/AppBottomBar'));
const AppHeader = React.lazy(() => import('./AppHeader/AppHeader'));
const PodCastPlayerPage = React.lazy(
  () => import('@src/App/components/pages/listen/PodCast/PodCastPlayerPage'),
);
// import AppContent from './AppContent/AppContent';

// TO DO : cast - app header go back
interface IAppLayoutProps extends InnerLayoutProps {
  children: React.ReactNode;
}
// @ts-ignore
const MainStyled = styled('main')(() => {
  return {
    position: 'relative',
    [`@media ${responsiveMap.mobile}`]: {
      paddingBottom: 92,
    },
  };
});

const AppLayout = (props: IAppLayoutProps) => {
  const {context, children} = props;
  const [isFetchingRegion, setIsFetchingRegion] = useState(true);
  const {fetch} = useSetupRegion(true);
  const {isLoggedIn, isLoading} = useIsLoggedIn();
  const {currentRegion} = useRegionUrl();
  const showLoading = (isLoading && !isLoggedIn) || isFetchingRegion;
  const {isMobile} = useBreakpoint();
  const member = useMember();
  const {updateStoreLang, currentLanguage} = useChangeLanguage();
  const pageLang = context?.language as string | undefined;

  useEffect(() => {
    setIsFetchingRegion(true);
    const unmap = languageUnmap(pageLang || '');
    fetch(unmap).finally(() => {
      setIsFetchingRegion(false);
    });
  }, [pageLang]);

  useEffect(() => {
    const memberRegionCulture = currentRegion?.defaultLanguage
      ? cultureRegionMapper(currentRegion.defaultLanguage)
      : null;
    const memberRegionPageLang =
      memberRegionCulture && languageMapper(memberRegionCulture);
    const storeLang =
      memberRegionPageLang && languageUnmap(memberRegionPageLang);

    if (
      !isFetchingRegion &&
      storeLang &&
      memberRegionPageLang &&
      pageLang &&
      (pageLang !== memberRegionPageLang || currentLanguage !== storeLang)
    ) {
      updateStoreLang(storeLang);
    }
  }, [member, currentRegion, isFetchingRegion, pageLang, currentLanguage]);

  const {
    appListen: {isOpenPlayer: isOpenRadioPlayer, isMini: isRadioMini},
    appPodcast: {isOpenPlayer: isOpenPodcastPlayer, isMini: isPodcastMini},
  } = useAppSelector(state => ({
    appListen: state.appListen,
    appPodcast: state.appPodcast,
  }));

  return (
    <div
      className="app-layout"
      style={{
        maxWidth: 1920,
        margin: '0 auto',
      }}>
      {isLoggedIn && <AppLayoutConnectLogic />}
      <Suspense>
        <AppHeader />
      </Suspense>
      <MainStyled>
        {/* {showLoading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1100,
            }}>
            <HomeContentLoader />
          </div>
        )} */}
        {showLoading ? <HomeContentLoader /> : children}
      </MainStyled>
      {createPortal(
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{zIndex: 99999}}
        />,
        document.body,
        'app-tostify-container',
      )}
      {isOpenPodcastPlayer && (
        <div
          style={{
            height: isPodcastMini ? 64 : 0,
          }}>
          <Suspense>
            <PodCastPlayerPage />
          </Suspense>
        </div>
      )}
      {isOpenRadioPlayer && (
        <div
          style={{
            height: isRadioMini ? 64 : 0,
          }}>
          <Suspense>
            <RadioPlayerPage />
          </Suspense>
        </div>
      )}
      {isMobile && (
        <Suspense>
          <AppBottomBar />
        </Suspense>
      )}
    </div>
  );
};

export default AppLayout;
