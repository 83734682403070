import {useAppSelector} from '@src/Store/hooks';
import {UnitProgressionData} from './CourseUnit.slice';

export const useUnitProgression = (unitId: string): UnitProgressionData =>
  useAppSelector(state =>
    state.courseUnit.unitProgression
      ? state.courseUnit.unitProgression[unitId] || {}
      : {},
  );

export const useCourseTestProgression = (testId: string): UnitProgressionData =>
  useAppSelector(state =>
    state.courseUnit.courseTestProgression
      ? state.courseUnit.courseTestProgression[testId] || {}
      : {},
  );

export const useAllUnitProgression = () =>
  useAppSelector(state => state.courseUnit.unitProgression || {});
