import React, {FC, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import {_CustomModalRoot} from './CustomModal.styled';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import CustomModalHeader from './CustomModalHeader';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import appSlice from '@src/Store/Slices/AppSlice';
import useViewPort from 'design-system/src/Hooks/useViewPort';

export interface ICustomModalProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  onBack?: () => void;
  varToCheckChangeScreen?: string;
  containerStyle?: Modal.Styles['content'];
  closable?: boolean;
  canGoBack?: boolean;
  backgroundImage?: string;
  extraContent?: React.ReactNode;
  zIndex?: number;
}

const CustomModal: FC<ICustomModalProps> = props => {
  const {
    children,
    isOpen,
    onClose,
    varToCheckChangeScreen,
    header,
    containerStyle,
    closable,
    canGoBack,
    onBack,
    zIndex = 50,
  } = props;

  const modalRef = useRef<any>();
  const {isMobile} = useBreakpoint();
  const {modalHeader} = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();
  const {viewportHeight} = useViewPort();

  useEffect(() => {
    if (isOpen) {
      dispatch(appSlice.actions.setGoBackInBackground(onClose));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      document
        .querySelector('body')
        ?.classList.remove('ReactModal__Body--open');
    }
  }, [isOpen]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [varToCheckChangeScreen]);

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      onClose?.();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      appElement={document.body}
      onRequestClose={onClose}
      overlayElement={(d, contentElement) => (
        <div
          {...d}
          style={{
            ...d.style,
            background: 'rgba(17, 18, 22, 0.60)',
            backdropFilter: 'blur(10px)',
            zIndex: zIndex,
          }}>
          {contentElement}
        </div>
      )}
      style={{
        ...customStyles(isMobile, viewportHeight),
        content: {
          ...customStyles(isMobile, viewportHeight).content,
          ...containerStyle,
        },
      }}>
      {header ? (
        <div
          style={{
            position: 'absolute',
            zIndex: 5,
            width: '100%',
          }}>
          {header}
        </div>
      ) : !!modalHeader ? (
        modalHeader
      ) : (
        <CustomModalHeader
          onGoBack={canGoBack ? handleBack : undefined}
          onClose={closable ? onClose : undefined}
        />
      )}
      <_CustomModalRoot ref={modalRef}>{children}</_CustomModalRoot>
      <style
        dangerouslySetInnerHTML={{
          __html: `body {overflow: hidden;}`,
        }}></style>
    </Modal>
  );
};

const customStyles = (
  isMobile: boolean,
  viewportHeight: number,
): Modal.Styles => {
  return {
    content: isMobile
      ? {
          padding: 0,
          inset: 'auto',
          height: '100%',
          width: '100%',
          maxWidth: '100%',
          borderRadius: 12,
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
        }
      : {
          padding: 0,
          inset: 'auto',
          height: `calc(${viewportHeight}px - 24px)`,
          width: '80%',
          maxWidth: '80%',
          borderRadius: 12,
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
        },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.3)',
      zIndex: 9999,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  };
};

export default CustomModal;
