import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const CardBookmarkStyles = StyleSheet.create({
  spacingTop: {
    marginTop: Metrics.verticalScale(5),
  },
  spaceBetweenIcon: {
    marginLeft: Metrics.horizontalScale(6),
  },
  tag: {
    position: 'absolute',
    top: Metrics.verticalScale(8),
    left: Metrics.horizontalScale(9),
    zIndex: 2,
  },
});
