import React, {useState} from 'react';
import {Modal, TouchableOpacity, View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import {LearnRowCard} from '../Card';
import ContentEmpty from '../ContentEmpty/ContentEmpty';
import MenuModal, {MenuModalProps} from '../MenuModal/MenuModal';
import {AchievmentContentProps} from './AchievmentContent.props';
import styled from 'styled-components';
import {responsiveMap} from '../../WebComponent/Grid/_utils/responsiveObserve';

const dataMenuModal: MenuModalProps['content'] = [
  {
    icon: 'download',
    label: 'Download',
    value: 'download',
  },
  {
    icon: 'share',
    label: 'Share',
    value: 'share',
  },
  {
    icon: 'news',
    label: 'See the job',
    value: 'job',
  },
  {
    icon: 'parametre',
    label: 'See the partner',
    value: 'partner',
  },
  {
    icon: 'favoris',
    label: 'See details',
    value: 'favoris',
  },
];

const ListContainer = styled.div(() => {
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: 12,
    rowGap: 65,

    [`@media ${responsiveMap.mobile}`]: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
  };
});

export default function AchievmentContent(props: AchievmentContentProps) {
  const {data, noBorder = false, onItemPress, errorMessage} = props;

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <View
      style={{
        paddingTop: !noBorder ? Metrics.verticalScale(16) : 0,
      }}>
      {data && data.length > 0 ? (
        <ListContainer
          style={{
            borderTopWidth: !noBorder ? 1 : 0,
            borderTopColor: 'rgba(115, 115, 115, 0.5)',
            paddingTop: Metrics.verticalScale(20),
          }}>
          {data?.map((d, index) => (
            <TouchableOpacity
              onPress={() => {
                onItemPress && onItemPress(d);
              }}
              key={`ac-${index}`}
              style={{
                width: '100%',
              }}>
              <LearnRowCard
                onPress={() => onItemPress && onItemPress(d)}
                {...d}
                onPressTrailingIcon={handleShowMenu}
              />
            </TouchableOpacity>
          ))}
        </ListContainer>
      ) : (
        <ContentEmpty title={errorMessage || ''} />
      )}

      <Modal animationType="slide" visible={false} transparent={true}>
        <MenuModal
          title="nom de la certification"
          content={dataMenuModal}
          onClose={() => {
            setShowMenu(false);
          }}
        />
      </Modal>
    </View>
  );
}
