import React, {ReactNode} from 'react';
import {
  IAwardHomePageFragmentFragment,
  IButtonFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import {HeroSliderData} from '~Umbraco/Utils/content/mapHeroSlide';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {IButtonProps} from 'design-system/src/Components/Button/Button';
import {RouteTypename} from '~Navigation/type';
import {useDispatch} from 'react-redux';
import useNavigation from '@src/Navigation/useNavigation';
import useElementRenderer from './useElementRenderer';

// TODO: check duplicted title variable

interface IButton {
  url?: string;
  id?: string;
  buttonProps?: IButtonProps;
  __typename: string;
}

const mapButton = (buttonContent: IButtonFragmentFragment): IButton => {
  let url;
  let id;
  let __typename = 'Unknown';
  const buttonProps: HeroSliderData['button'] | undefined = {
    variant:
      (buttonContent.variant as NonNullable<
        HeroSliderData['button']
      >['variant']) || 'outlined',
    icon: (buttonContent.icon || '').toLowerCase() as NonNullable<
      HeroSliderData['button']
    >['icon'],
    size:
      (buttonContent.size as NonNullable<HeroSliderData['button']>['size']) ||
      'small',
    title: buttonContent.title || '',
  };

  if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    (buttonContent.link_to[0].content.__typename === 'InternalLink' ||
      buttonContent.link_to[0].content.__typename === 'ContentLink' ||
      buttonContent.link_to[0].content.__typename === 'ExternalLink')
  ) {
    if (
      buttonContent.link_to[0].content.__typename === 'InternalLink' ||
      buttonContent.link_to[0].content.__typename === 'ContentLink'
    ) {
      url = buttonContent.link_to[0].content.link?.url || '';
      id = buttonContent.link_to[0].content.link?.id || '';
      __typename =
        buttonContent.link_to[0]?.content.link?.__typename || 'Unknown';
    } else if (buttonContent.link_to[0].content.__typename === 'ExternalLink') {
      url = buttonContent.link_to[0].content.externalUrl;
      __typename = buttonContent.link_to[0]?.content.__typename;
    }
  }
  return {
    buttonProps: {
      ...buttonProps,
      title: buttonProps.title || '',
    },
    url,
    id: id || '',
    __typename,
  };
};

const useAwardHomePageUtils = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {renderBlock} = useElementRenderer();

  const handleButtonHeader = async (item: IButton | undefined) => {
    if (item) {
      const {id, __typename, url} = item;

      new RouteFactory(
        {
          id: id || '',
          __typename: __typename as RouteTypename,
          url: url || '',
        },
        navigation,
        dispatch,
      ).navigate();
    }
  };

  const renderPage = (artistServicesPage: IAwardHomePageFragmentFragment) => {
    const {
      pageBlocks: pageBlocksData,
      title,
      introduction: subtitle,
      cover,
      cTA: button,
      logo,
    } = artistServicesPage;

    const pageBlocks: ReactNode[] = [];
    let buttonData: IButton | undefined;

    if (button && button[0] && button[0].content.__typename === 'Button') {
      let buttonContent = button[0].content as IButtonFragmentFragment;
      buttonData = mapButton(buttonContent);
    }

    pageBlocks.push(
      <ServiceHeader
        key={'header'}
        gradiantVariant="bottom"
        title={title ?? '-'}
        // TODO: remove logo
        // topLogo={logo ? {uri: logo.url} : undefined}
        onTopLogoClick={() => navigation.navigate('/awards')}
        image={{
          uri:
            cover &&
            cover[0] &&
            cover[0].content.__typename === 'HeroVideoCover'
              ? cover[0].content.image?.url || ''
              : '',
        }}
        desktopImage={{
          uri:
            cover &&
            cover[0] &&
            cover[0].content.__typename === 'HeroVideoCover'
              ? cover[0].content.desktopImage?.url || ''
              : '',
        }}
        description={subtitle ?? ''}
        buttonTitle={buttonData?.buttonProps?.title ?? ''}
        buttonProps={{
          ...buttonData?.buttonProps,
          variant: 'contained',
          size: 'small',
        }}
        onPressButton={() => {
          handleButtonHeader(buttonData);
        }}
      />,
    );

    pageBlocksData && pageBlocks.push(...renderBlock(pageBlocksData));

    return pageBlocks;
  };

  return {renderPage};
};

export default useAwardHomePageUtils;
