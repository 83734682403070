import React, {Children, ReactNode} from 'react';
import {Pressable, View} from 'react-native';
import defaultTheme from '../../../../Theme/defaultTheme';
import Typography from '../../../Typography/Typography';
import {CarouselCardInfoStyles} from './CarouselCardInfo.styles';

interface ICarouselCardInfoProps {
  title: string;
  subtitle?: string;
  TrailingIc?: React.FC;
  titleColor?: string;
  infoColor?: string;
  leadingWidth?: number;
  isFilledParent?: boolean;
  isTrailingIconCentered?: boolean;
  onPressTrailingIc?: () => void;
  children?: ReactNode[];
  hideTitle?: boolean;
  hideSubtitle?: boolean;
}

const CarouselCardInfo = (props: ICarouselCardInfoProps) => {
  const {
    title,
    subtitle,
    TrailingIc,
    titleColor,
    infoColor,
    leadingWidth,
    isFilledParent,
    isTrailingIconCentered,
    onPressTrailingIc,
    children,
    hideTitle,
    hideSubtitle,
  } = props;
  const arrayChildren = Children.toArray(children);
  return (
    <View
      style={{
        ...CarouselCardInfoStyles.container,
        ...(isFilledParent && {flex: 1}),
      }}>
      <View
        style={{
          ...CarouselCardInfoStyles.leading,
          ...(leadingWidth && {width: leadingWidth}),
        }}>
        {!hideTitle && (
          <Typography
            variant="h4"
            numberOfLines={1}
            color={() => titleColor || defaultTheme.white}
            style={CarouselCardInfoStyles.title}>
            {title}
          </Typography>
        )}
        {!hideSubtitle && Boolean(subtitle) && (
          <Typography
            variant="ui3"
            color={() => infoColor ?? defaultTheme.gray}
            numberOfLines={1}
            style={{
              ...CarouselCardInfoStyles.subtitle,
              color: infoColor ?? defaultTheme.gray,
            }}>
            {subtitle}
          </Typography>
        )}
        {Children!.map(arrayChildren, (child, index) => {
          if (arrayChildren[index]) {
            if (index !== arrayChildren.length - 1) {
              return (
                <View style={CarouselCardInfoStyles.itemMargin}>{child}</View>
              );
            }
            return <View>{child}</View>;
          }
        })}
      </View>
      {TrailingIc && (
        <Pressable
          onPress={onPressTrailingIc}
          style={{
            ...(isTrailingIconCentered ? {justifyContent: 'center'} : {}),
          }}>
          <TrailingIc />
        </Pressable>
      )}
    </View>
  );
};

export default CarouselCardInfo;
