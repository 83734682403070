import React from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Metrics from '../../../../Theme/Metrics';

type SubscribeCardGradientViewProps = {
  children: React.ReactNode;
  variant?: 'default' | 'active';
  style?: StyleProp<ViewStyle>;
};

const SubscribeCardGradientView = (props: SubscribeCardGradientViewProps) => {
  const {children, variant = 'default', style} = props;
  const sharedStyle = {
    flex: 1,
    borderRadius: Metrics.moderateScale(8),
    borderWidth: 1,
    borderColor: '#FA6217',
  };
  if (variant === 'active') {
    return (
      <LinearGradient
        colors={['rgba(250, 98, 23, 0.10)', 'rgba(255, 157, 29, 0.10)']}
        locations={[0.4984, 1]}
        useAngle={true}
        angle={90}
        angleCenter={{x: 0.5, y: 0.5}}
        style={[sharedStyle, style]}>
        {children}
      </LinearGradient>
    );
  }
  return (
    <View style={[sharedStyle, {borderColor: 'transparent'}, style]}>
      {children}
    </View>
  );
};

export default SubscribeCardGradientView;
