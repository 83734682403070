import React, {ReactNode, useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import * as d3 from 'd3';
import Svg, {Circle, CircleProps, G, Text as SVGText} from 'react-native-svg';
export interface IBubbleChartData {
  name: string;
  color: string;
  value: number;
}
export interface IBubbleChartProps {
  height: number;
  width: number;
  data: Array<IBubbleChartData>;
  circleProps?: CircleProps;
  containerStyle?: StyleProp<ViewStyle>;
}

const BubbleChart = (props: IBubbleChartProps) => {
  const {height, width, data, circleProps, containerStyle} = props;

  let pack = (data: Array<IBubbleChartData>) =>
    d3
      .pack()
      .size([width - 2, height - 2])
      .padding(3)(
      d3.hierarchy({children: data}).sum((d: IBubbleChartData) => d.value),
    );

  const root = pack(data);

  const leaves: ReactNode[] = useMemo(() => {
    let _leaves: ReactNode[] = [];
    root.leaves().forEach((leaf: any, index: number) => {
      _leaves.push(
        <G
          key={`leaf-${index}`}
          transform={`translate(${leaf.x + 1},${leaf.y + 1})`}>
          <Circle {...circleProps} r={leaf.r} fill={leaf.data.color} />
          {/* <SVGText
            {...textProps}
            fill="#FFFFFF"
            fontSize={fontSizeGenerator(leaf.data.value)}
            x="0"
            y={leaf.data.value * 0.1}
            textAnchor="middle">
            {leaf.data.name}
          </SVGText> */}
        </G>,
      );
    });
    return _leaves;
  }, [root]);

  return (
    <View style={containerStyle}>
      <Svg width={width || 400} height={height || 300}>
        {leaves}
      </Svg>
    </View>
  );
};

export default BubbleChart;
