import React from 'react';
import {View} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import Statistic from 'design-system/src/Components/Statistic/Statistic';

const KeyFigures = (props: {content: Queries.Umbraco_KeyFigures}) => {
  const {content} = props;
  const {list} = content;
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        paddingHorizontal: Metrics.horizontalScale(29),
        paddingVertical: Metrics.verticalScale(96),
      }}>
      {list?.map((d, index) => {
        let {figure, description} = d!
          .content as unknown as Queries.KeyFigureFragmentFragment;
        return (
          <View
            key={`st-${index}`}
            style={{
              maxWidth: 149,
              width: Metrics.horizontalScale(375 / 2),
              marginBottom: 35,
            }}>
            <Statistic value={figure ?? '-'} label={description ?? '-'} />
          </View>
        );
      })}
    </View>
  );
};

export default KeyFigures;
