import {StyleSheet} from 'react-native';
import Metrics from '../../Theme/Metrics';

export const carouselStyles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Metrics.verticalScale(13),
  },
});
