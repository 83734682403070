import {jwtDecode} from 'jwt-decode';

export default class Token {
  constructor(private token?: string) {
    this.token = localStorage.getItem('ft') || undefined;
  }

  isValid(): boolean {
    const currentToken = this.token;
    let hasValideToken = false;
    if (currentToken) {
      const decodedToken = jwtDecode(currentToken);
      if (decodedToken?.exp) {
        hasValideToken = decodedToken.exp > Date.now() / 1000;
      }
    }

    return hasValideToken;
  }
}
