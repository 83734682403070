import React from 'react';
import {IContentSliderItem} from '~Umbraco/Components/ContentSlider/ContentSlider';
import {IMulticategoriesSliderFragmentFragment} from '~Api/Graphql/gql/types.generated';
import Typography from 'design-system/src/Components/Typography/Typography';

const mapMultiCategoryItems = (
  content: IMulticategoriesSliderFragmentFragment,
) => {
  const {title, template, categories} = content;

  const items: IContentSliderItem[] = [];

  categories?.forEach(item => {
    if (item) {
      if (
        (item.__typename === 'WatchCateg' ||
          item.__typename === 'RadioCateg' ||
          item.__typename === 'PodcastCateg') &&
        item.url &&
        item.id
      ) {
        const {thumbnail} = item;
        items.push({
          id: item.id ?? 'unknown',
          url: item.url,
          title: item?.name,
          overlayContent: (
            <Typography variant="h4" style={{textAlign: 'center'}}>
              {item?.name || ''}
            </Typography>
          ),
          __typename: item?.__typename || 'Unknown',
          accessData: {},
          // TODO: fix backgroundColor
          backgroundColor: (item as any).backgroundColor || '',
          cover:
            thumbnail && thumbnail.url
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
        });
      }
    }
  });
  return {
    title,
    template,
    items,
  };
};

export default mapMultiCategoryItems;
