import React from 'react';
import {FlatList, ListRenderItem, Pressable, View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';
import CustomFastImage from '../CustomFastImage/CustomFastImage.web';
import {CustomFastImageProps} from '../CustomFastImage/CustomFastImageProps';
import ServiceTitle from '../ServiceTitle/ServiceTitle';

export interface IItemServiceChannel {
  logo?: CustomFastImageProps['source'];
  url?: string;
}

export type ServiceChannelProps<T extends IItemServiceChannel> = {
  title: string;
  items: Array<T>;
  onItemPress?: (item: T) => void;
};

export default function ServiceChannel<T extends IItemServiceChannel>(
  props: ServiceChannelProps<T>,
) {
  const {title, items, onItemPress} = props;
  const {isMobile} = useBreakpoint();

  const renderItem: ListRenderItem<T> = ({index, item}) => (
    <Pressable
      key={index}
      style={{marginLeft: 8, marginRight: 8}}
      onPress={() => {
        onItemPress && onItemPress(item);
      }}>
      <CustomFastImage
        source={item.logo}
        width={Metrics.horizontalScale(144)}
        height={Metrics.verticalScale(100)}
        backgroundFallbackColor="transparent"
      />
    </Pressable>
  );

  return (
    <View style={{marginTop: 42, marginBottom: 29}}>
      <ServiceTitle title={title} />
      <View style={{marginTop: 28}}>
        <FlatList
          contentContainerStyle={{
            ...(isMobile
              ? {}
              : {
                  width: '100%',
                  justifyContent: 'center',
                }),
          }}
          data={items}
          renderItem={renderItem}
          keyExtractor={(__, i) => i + ''}
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </View>
  );
}
