import {
  ILiveEventFragmentFragment,
  IMainContentListFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {ICategorySliderItem} from '~Umbraco/Components/CategorySlider/CategorySlider';
import {getCoverMedia} from '../getCoverMedia';

const mapMainContent = (
  content?: IMainContentListFragmentFragment,
  findProgression?: (id: string) => number,
  canSeeContent?: (
    accessType?: ILiveEventFragmentFragment['accessType'],
  ) => boolean,
  calculateLiveEventProgress?: (startDate: any, endDate: any) => number,
): ICategorySliderItem | undefined => {
  if (!content) {
    return undefined;
  }
  if (content.__typename === 'Collection') {
    const {
      title,
      subtitle,
      description,
      shortDescription,
      thumbnail,
      url = '',
      id,
      cover,
      __typename,
    } = content;

    return {
      title: title || '',
      subtitle: subtitle || shortDescription || description || '',
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      mediaCover: getCoverMedia(cover),
      url,
      __typename,
      id,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      raw: content,
      isFreemium: canSeeContent?.(content.accessType),
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'VODItem') {
    const {
      title,
      subtitle,
      shortDescription,
      description,
      thumbnail,
      url = '',
      cover,
      __typename,
      id,
      liveEventId,
      sourceUrl,
      vimeoHlsUrl,
    } = content;
    const parentAccessType =
      content.parent && content.parent.__typename === 'Collection'
        ? content.accessType
        : undefined;
    return {
      title: title || '',
      subtitle: subtitle || shortDescription || description || '',
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      mediaCover: getCoverMedia(cover),
      url,
      __typename,
      id,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      progress: findProgression?.(id),
      raw: content,
      liveEventId,
      sourceUrl,
      vimeoHlsUrl,
      isFreemium:
        canSeeContent?.(content.accessType) &&
        canSeeContent?.(parentAccessType),
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'Radio') {
    const {
      title,
      subtitle,
      description,
      thumbnail,
      url = '',
      cover,
      __typename,
      id,
    } = content;

    return {
      title: title || '',
      subtitle: subtitle || description || '',
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      mediaCover: getCoverMedia(cover),
      url,
      __typename,
      id,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      raw: content,
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'Podcast') {
    const {
      title,
      subtitle,
      description,
      thumbnail,
      url = '',
      id,
      cover,
      __typename,
    } = content;

    return {
      title: title || '',
      subtitle: subtitle || description || '',
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      mediaCover: getCoverMedia(cover),
      url,
      __typename,
      id,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      progress: findProgression?.(id),
      raw: content,
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'Playlist') {
    const {
      title,
      subtitle,
      thumbnail,
      url = '',
      id,
      cover,
      __typename,
    } = content;

    return {
      title: title || '',
      subtitle: subtitle,
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      mediaCover: getCoverMedia(cover),
      url,
      __typename,
      id,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      raw: content,
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'PodcastItem') {
    const {
      title,
      shortDescription,
      thumbnail,
      url = '',
      id,
      vimeoId,
      __typename,
      vimeoHlsUrl,
    } = content;

    return {
      title: title || '',
      subtitle: shortDescription,
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      url,
      id,
      __typename,
      liveEventId: vimeoId,
      accessData: {},
      progress: findProgression?.(id),
      vimeoHlsUrl,
      raw: content,
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'LiveEvent') {
    const {
      title,
      subtitle,
      description,
      thumbnail,
      url = '',
      id,
      cover,
      __typename,
      liveEventId,
      sourceUrl,
    } = content;

    return {
      title: title || '',
      subtitle: subtitle || description || '',
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      mediaCover: getCoverMedia(cover),
      url,
      id,
      __typename,
      liveEventId,
      sourceUrl,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      progress: calculateLiveEventProgress
        ? calculateLiveEventProgress(content.startDate, content.endDate)
        : 0.0,
      raw: content,
      isFreemium: canSeeContent?.(content.accessType),
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'News') {
    const {
      title,
      shortDescription,
      thumbnail,
      url = '',
      id,
      vimeoId,
      vimeoHlsUrl,
      sourceUrl,
      __typename,
    } = content;

    return {
      title: title || '',
      subtitle: shortDescription,
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      url,
      id,
      __typename,
      liveEventId: vimeoId,
      sourceUrl,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      raw: content,
      vimeoHlsUrl,
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  } else if (content.__typename === 'Channel') {
    const {
      title,
      shortDescription,
      thumbnail,
      url = '',
      id,
      __typename,
      liveEventId,
      sourceUrl,
    } = content;

    return {
      title: title || '',
      subtitle: shortDescription,
      cover: thumbnail
        ? {
            uri: thumbnail.url,
          }
        : undefined,
      url,
      id,
      __typename,
      liveEventId,
      sourceUrl,
      accessData: {
        accessType: content.accessType,
        paywall: content.paywall,
      },
      raw: content,
      isFreemium: canSeeContent?.(content.accessType),
      bubble:
        thumbnail && thumbnail.bubble
          ? {
              uri: thumbnail.bubble,
            }
          : undefined,
    };
  }
  console.warn('[Map main content] Content not handled', content.__typename);
  return undefined;
};

export default mapMainContent;
