import React from 'react';
import IconWithText from '../../../IconWithText/IconWithText';
import CardAside from '../../CardAside/CardAside';
import {VodCardProps} from '../VodCardProps';
import {TextStyles} from '../../../../styles/TextStyles';
import Typography from '../../../Typography/Typography';
import defaultTheme from '../../../../Theme/defaultTheme';
import {ITypographyProps} from '../../../Typography/Typography.props';

const VodCardAside: React.FC<{
  asideProps: VodCardProps;
  TrailingIc?: React.FC;
}> = ({asideProps, TrailingIc}) => {
  const infoColor: ITypographyProps['color'] = asideProps
    ? () => asideProps.infoColor || defaultTheme.gray
    : 'gray';
  return (
    <CardAside
      title={asideProps.title}
      subtitle={asideProps.subtitle}
      isFilledParent={asideProps.asideExpanded}
      titleColor={asideProps.titleColor}
      infoColor={asideProps.infoColor}
      TrailingIc={TrailingIc}
      onPressTrailingIc={asideProps.onPressTrailingIcon}
      leadingWidth={asideProps.widthInfoContainer}
      isTrailingIconCentered={asideProps.centerTrailingIc}>
      {asideProps.vodeType === 'movie' && !asideProps.isSingleType ? (
        <Typography variant="ui3" color={infoColor}>
          {asideProps.vodeType} -{' '}
          {asideProps.isDownloadType
            ? asideProps.videoSize
            : asideProps.shortDescription}
        </Typography>
      ) : undefined}
      {asideProps.vodeType === 'serie' && !asideProps.isSingleType && (
        <Typography variant="ui3" color={infoColor}>
          {asideProps.episode} -{' '}
          {asideProps.isDownloadType ? asideProps.videoSize : asideProps.saison}
        </Typography>
      )}
      {asideProps.length && (
        <IconWithText
          text={asideProps.length}
          icon="clock"
          size="16"
          color={infoColor}
          textStyle={TextStyles.infoText}
        />
      )}
      {asideProps.description && (
        <Typography
          numberOfLines={asideProps.maxLineDescription}
          variant="ui3"
          color={infoColor}>
          {asideProps.description}
        </Typography>
      )}
    </CardAside>
  );
};

export default VodCardAside;
