import {RouteTypename} from '~Navigation/type';
import useTracker from '~Services/Amplitude/hooks/useTracker';

const useShareContent = (options?: {screenSource?: 'discover' | 'detail'}) => {
  const {screenSource} = options || {};
  const tracker = useTracker();

  const share = async (params: {
    url: string;
    id: string;
    __typename: RouteTypename;
    message?: string;
    episode?: number;
    category?: string;
    cover: string;
    description: string;
    title?: string;
    countryOfProduction?: Array<string | undefined> | string;
  }) => {
    const {
      id,
      __typename,
      message,
      url,
      cover,
      description,
      category,
      title,
      countryOfProduction,
    } = params;
    const countryArray = Array.isArray(countryOfProduction)
      ? countryOfProduction
      : [countryOfProduction];

    if (screenSource === 'discover') {
      tracker.discover.discoverSlideShare({
        title: message || '',
        id: id,
        __typename: __typename,
        url: url,
        name: message,
        countryOfProduction: countryArray,
      });
    } else if (screenSource === 'detail') {
      tracker.contentShared({
        id,
        __typename,
        title,
        categoryName: category,
        countryOfProduction: countryArray,
      });
    }
  };
  return {share};
};

export default useShareContent;
