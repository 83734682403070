import React from 'react';
import CardTips from 'design-system/src/Components/Card/CardTips/CardTips';
import {
  IAdvantageFragmentFragment,
  INavMessageFragmentFragment,
} from '~Api/Graphql/gql/types.generated';

export interface ICourseAdvantageProps {
  advantage?: IAdvantageFragmentFragment | INavMessageFragmentFragment;
  cardVertical?: boolean;
}

const CourseAdvantage = (props: ICourseAdvantageProps) => {
  const {advantage, cardVertical} = props;
  if (!advantage) {
    return null;
  }
  return (
    <CardTips
      image={advantage.image?.url || ''}
      title={advantage.title || ''}
      description={advantage.description || ''}
      vertical={cardVertical}
    />
  );
};

export default CourseAdvantage;
