import CategoryPage from '@src/Umbraco/Pages/CategoryPage/CategoryPage';
import React, {useEffect} from 'react';
import {useLocation} from '@reach/router';
import queryString from 'query-string';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {RouteSource} from '@src/Navigation/type';

const PodCastCategoryPage: React.FC = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const tracker = useTracker();
  const {id} = params || {};
  const {t} = useTranslation('translation');

  useEffect(() => {
    tracker.general.contentCategoryViewed({
      __typename: 'PodcastCateg',
      source: (params?.source as RouteSource) || 'browse',
    });
  }, [params?.source]);

  return (
    <CategoryPage
      activeId={id as string}
      regionFilter={true}
      type="PodcastCateg"
    />
  );
};

export default PodCastCategoryPage;
