import {StyleSheet} from 'react-native';
import Metrics, {isWeb, width} from '../../Theme/Metrics';

export const singleHeaderStyles = StyleSheet.create({
  progressContainer: {
    display: 'flex',

    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: Metrics.verticalScale(14),
    paddingHorizontal: Metrics.horizontalScale(16),
  },

  image: {
    // position: 'absolute',
    width: '100%',
    alignItems: 'center',
    // top: Metrics.verticalScale(120),
    borderRadius: Metrics.moderateScale(4),
    marginBottom: Metrics.verticalScale(34),
  },
});
