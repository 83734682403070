import {
  ICourseOverviewFragmentFragment,
  ICourseUnitOverviewFragmentFragment,
  IJobTrainingOverviewFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {
  ICourseUnitOverview,
  ICourseOverview,
  IJobTrainingOverview,
} from './overview.interface';
import {formatDuration} from '@src/Hooks/useStringExtension';

export class OverviewService {
  public static getUnitOverview(
    unit: ICourseUnitOverviewFragmentFragment,
  ): ICourseUnitOverview {
    const {pages} = unit;
    let videosCount = 0;
    let quizzCount = 0;
    pages?.forEach(page => {
      if (page && page.content) {
        if (page.content.__typename === 'CoursePage') {
          const {blocks} = page.content;
          blocks?.forEach(block => {
            if (
              block &&
              block.content &&
              block.content.__typename === 'UcVideo'
            ) {
              videosCount += 1;
            }
          });
        } else if (page.content.__typename === 'CourseNugget') {
          quizzCount += page.content.nuggets?.length || 0;
        }
      }
    });
    return {
      videosCount,
      quizzCount,
      quizz: quizzCount > 0,
    };
  }

  public static getJobTrainingOverView(
    job: IJobTrainingOverviewFragmentFragment,
  ): IJobTrainingOverview {
    console.log('🚀 ~ OverviewService ~ job:', job);
    let multimediaUnits = 0;
    let videosCount = 0;
    let length = 0;
    let coursesCertificates = 0;
    let _duration: string | undefined = undefined;

    if (job.courses) {
      job.courses.forEach(course => {
        if (course && course.__typename === 'Course') {
          const courseOverview = this.getCourseOverview(course);
          length += courseOverview.length || 0;
          videosCount += courseOverview.videosCount || 0;
          multimediaUnits += courseOverview.multimediaUnits || 0;
          if (courseOverview.certificate) {
            coursesCertificates++;
          }
        }
      });
    }

    if (length) {
      _duration = formatDuration(length, length < 3600);
    }

    return {
      multimediaUnits,
      videosCount,
      length,
      duration: _duration,
      coursesCertificates,
    };
  }

  public static getCourseOverview(
    course: ICourseOverviewFragmentFragment,
  ): ICourseOverview {
    const {children} = course;
    let multimediaUnits = 0;
    let certificate = false;
    let videosCount = 0;
    let quizz = false;
    let length = 0;
    let quizzesCount = 0;

    let _duration: string | undefined = undefined;
    if (children) {
      children.items?.forEach(item => {
        if (item) {
          if (item.__typename === 'CourseUnit') {
            multimediaUnits += 1;
            length += item.length || 0;
            const unitOverview = this.getUnitOverview(item);
            videosCount += unitOverview.videosCount || 0;
            if (!quizz && unitOverview.quizz) {
              quizz = unitOverview.quizz;
            }
            if (unitOverview.quizz) {
              quizzesCount += unitOverview.quizzCount;
            }
          } else if (item.__typename === 'CourseExam') {
            certificate = true;
          }
        }
      });
    }
    if (length) {
      _duration = formatDuration(length, length < 3600);
    }
    return {
      multimediaUnits,
      certificate,
      videosCount,
      quizz,
      length,
      duration: _duration,
      quizzCount: quizzesCount,
    };
  }
}
