import React from 'react';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import Metrics from 'design-system/src/Theme/Metrics';
import {
  ILiveEventFragmentFragment,
  ILiveTvPageFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {ICategorySliderItem} from '~Umbraco/Components/CategorySlider/CategorySlider';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';

export const mapLiveTVPage = (
  category: ILiveTvPageFragmentFragment,
  canSeeContent: (
    accessType?: ILiveEventFragmentFragment['accessType'],
  ) => boolean,
): ICategorySliderItem[] => {
  const {liveTVContent} = category;
  const items: ICategorySliderItem[] = [];
  liveTVContent?.forEach(item => {
    if (item && !isElementRestricted(item)) {
      switch (item.__typename) {
        case 'Channel':
          const {
            title,
            thumbnail,
            name,
            logo,
            shortDescription,
            url,
            liveEventId,
            sourceUrl,
            __typename,
            id,
          } = item;
          url &&
            url !== '#' &&
            items.push({
              url: url || '',
              title: title || name || '',
              subtitle: shortDescription || '',
              overlayContent: logo ? (
                <CustomFastImage
                  source={{uri: logo.url}}
                  width={'100%'}
                  height={'100%'}
                  backgroundFallbackColor={'transparent'}
                  resizeMode="contain"
                />
              ) : undefined,
              cover: thumbnail
                ? {
                    uri: thumbnail.url,
                  }
                : undefined,
              __typename,
              liveEventId,
              sourceUrl,
              id,
              accessData: {
                accessType: item.accessType,
                paywall: item.paywall,
              },
              isFreemium: canSeeContent(item.accessType),
            });
          break;

        default:
          break;
      }
    }
  });

  return items;
};
