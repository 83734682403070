import React from 'react';
import Metrics from '../../../../Theme/Metrics';
import LeadingTopCard from '../../LeadingTopCard/LeadingTopCard';
import RowCard from '../../RowCard/RowCard.web';
import ListenCardAside from '../ListenCardAside/ListenCardAside';
import {ListenCardProps} from '../ListenCardProps';

export interface TopListenCardProps extends ListenCardProps {
  number: string;
}

const TopListenCard = (props: TopListenCardProps) => {
  const {number, cover} = props;

  return (
    <RowCard
      flexDirection={props.flexDirection}
      left={
        <LeadingTopCard
          size="medium"
          number={number}
          source={cover}
          dist={Metrics.horizontalScale(25)}
          variant="top"
        />
      }
      right={<ListenCardAside asideProps={props} />}
    />
  );
};

export default TopListenCard;
