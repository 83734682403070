import React from 'react';
import Icon from '../Icon/Icon';
import {Platform, StyleSheet, View} from 'react-native';

const PremiumIcon = () => {
  return (
    <View style={premiumIconStyles.container}>
      <View style={premiumIconStyles.blur} />
      <Icon
        name={'crown'}
        size={'24'}
        autoResize={true}
        
      />
    </View>
  );
};

export default PremiumIcon;

const premiumIconStyles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
  },
  blur: {
    position: 'absolute',
    width: 24,
    height: 24,
    backgroundColor:
      Platform.OS === 'web' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.5)',
    borderRadius: 3,
  },
});
