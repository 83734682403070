import {useAppSelector} from '@src/Store/hooks';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {IMessageResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {
  useLazyDeleteMemberMessageQuery,
  useLazyGetAllMemberMessageQuery,
  useLazyUpdateMemberMessageQuery,
} from '~Api/TraceApi/traceApi';
import memberSlice from '~Store/Slices/Member.slice';

const useMemberMessage = (): {
  fetch: () => Promise<void>;
  markAsRead: (content: IMessageResponse) => Promise<void>;
  remove: (contentId: string) => Promise<boolean>;
  refresh: () => Promise<void>;
  loading: boolean;
  isRemoving: boolean;
  data: IMessageResponse[] | undefined;
} => {
  const {messages = [], member} = useAppSelector(({member: memberState}) => ({
    messages: memberState.messages,
    member: memberState.member,
  }));
  const dispatch = useDispatch();
  const [updateMessage] = useLazyUpdateMemberMessageQuery();
  const [deleteMessage, {isLoading: isRemoving}] =
    useLazyDeleteMemberMessageQuery();
  const [fetchAllMessage, {isLoading: loading}] =
    useLazyGetAllMemberMessageQuery();

  const handleFetchedData = useCallback(
    (fetchedData: IMessageResponse[]) => {
      dispatch(memberSlice.actions.setMessages(fetchedData));
      return fetchedData;
    },
    [dispatch],
  );

  const fetch = useCallback(async () => {
    if (member) {
      const response = await fetchAllMessage();
      if (response && response.data) {
        handleFetchedData(response.data);
      }
    }
  }, [handleFetchedData, fetchAllMessage]);

  const refresh = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (member) {
          fetch()
            .then(() => resolve())
            .catch(reject);
        }
      }, 500);
    });
  }, [fetch]);

  const markAsRead = useCallback(
    async (content: IMessageResponse) => {
      if (content) {
        const {title, message, thumbnail, _id} = content;
        await updateMessage({
          id: _id,
          data: {
            title,
            message,
            thumbnail,
            read: true,
          },
        });
        refresh();
      }
    },
    [updateMessage, refresh],
  );

  const remove = async (id: string): Promise<boolean> => {
    const response = await deleteMessage({id});
    if (!response.error) {
      dispatch(memberSlice.actions.removeItem({type: 'messages', id}));
      return true;
    }
    return false;
  };

  return {
    refresh,
    loading,
    data: messages,
    fetch,
    remove,
    isRemoving,
    markAsRead,
  };
};

export default useMemberMessage;
