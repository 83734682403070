import React, {useMemo, useState} from 'react';
import {Pressable} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography';
import {useLanguages} from '@src/Store/Slices/LanguageSlice/Language.selector';
import Icon from 'design-system/src/Components/Icon/Icon';
import DropDown from 'design-system/src/WebComponent/Dropdown/Dropdown';
import useChangeLanguage from '@src/Hooks/useChangeLanguage';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useDefaultTheme from 'design-system/src/Theme/useTheme';
import {_AwardHeaderRoot} from './AwardHeader.styled';

interface IAwardHeaderProps {}

const AwardHeader = (props: IAwardHeaderProps) => {
  const languages = useLanguages();
  const defaultTheme = useDefaultTheme();
  const {currentLanguage, updateStoreLang} = useChangeLanguage();
  const [loadingLanguage, setLoadingLanguage] = useState<string>('');
  const {t} = useTranslation('language');
  const selectedLanguage = currentLanguage;
  const data: Array<{
    value: string;
    label: string;
  }> = useMemo(
    () =>
      languages.map(lang => ({value: lang.isoCode, label: lang.cultureName})),
    [languages],
  );

  const selectedItem =
    data.find(el => el.value === selectedLanguage) || data[0];
  const handleChooseLanguage = (lang: string) => async () => {
    setLoadingLanguage(lang);
    await updateStoreLang(lang);
    setLoadingLanguage('');
  };
  return (
    <_AwardHeaderRoot>
      <div className="lang-switcher">
        <DropDown
          // trigger="hover"
          placement="bottomRight"
          content={
            <>
              {data.map(item => {
                const isActive = selectedItem?.value === item.value;
                return (
                  <Pressable
                    key={item.value}
                    disabled={isActive}
                    onPress={handleChooseLanguage(item.value)}
                    // @ts-ignore
                    style={{flexWrap: 'nowrap', flexDirection: 'row', gap: 8}}>
                    <Typography
                      variant="body3"
                      color={
                        (isActive && (() => defaultTheme.primary)) || undefined
                      }>
                      {t(item.value, '') || item.label}
                    </Typography>
                    {loadingLanguage === item.value ? (
                      <CustomActivityIndicator />
                    ) : (
                      isActive && (
                        <Icon name="check" color={defaultTheme.primary} />
                      )
                    )}
                  </Pressable>
                );
              })}
            </>
          }>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 'auto',
              display: 'flex',
              minHeight: 48,
              cursor: 'pointer',
            }}>
            <Typography variant="body3">
              {t(selectedLanguage, '') || selectedItem?.label}
            </Typography>
            <Icon name={'arrow-down'} size="16" color="white" />
          </div>
        </DropDown>
      </div>
    </_AwardHeaderRoot>
  );
};

export default AwardHeader;
