import Typography from 'design-system/src/Components/Typography/Typography.web';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {View} from 'react-native';
import HtmlRender from '@src/Umbraco/Components/HtmlRender/HtmlRender';
import {Styles} from 'styled-components/dist/types';

interface ICourseNuggetWrapperProps {
  title?: string;
  description?: string;
  detailsRender?: ReactNode;
  optionsRender?: ReactNode;
  optionsWrapperStyle?: Styles<object>;
}

const CourseNuggetWrapperRoot = styled.div<{
  $optionsWrapperStyle?: Styles<object>;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0;
  row-gap: 0;

  @media ${responsiveMap.mobile} {
    display: flex;
    flex-direction: column;
  }

  .options-wrapper {
    ${props => ({
      maxWidth: '70%',
      width: '100%',
      ...props.$optionsWrapperStyle,
    })}
    @media ${responsiveMap.mobile} {
      max-width: unset;
    }
  }

  .options-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${responsiveMap.mobile} {
      align-items: center;
    }
  }
`;

const CourseNuggetWrapper = (props: ICourseNuggetWrapperProps) => {
  const {
    title,
    description,
    detailsRender,
    optionsRender,
    optionsWrapperStyle,
  } = props;
  const {isMobile} = useBreakpoint();
  return (
    <CourseNuggetWrapperRoot $optionsWrapperStyle={optionsWrapperStyle}>
      <div className="details-content">
        <View>
          {!!title && (
            <View
              style={{
                alignItems: isMobile ? 'center' : 'flex-start',
              }}>
              <Typography
                style={{
                  textAlign: isMobile ? 'center' : 'left',
                  maxWidth: isMobile ? 250 : 540,
                  paddingTop: 24,
                }}
                variant={isMobile ? 'h2' : 'formTitle'}>
                {title}
              </Typography>
            </View>
          )}
          {!!description && (
            <HtmlRender
              html={description || ''}
              variant="body3"
              textAlign={isMobile ? 'center' : 'left'}
              style={{
                marginTop: isMobile ? 13 : 18,
                paddingHorizontal: isMobile ? 10 : 0,
                maxWidth: isMobile ? undefined : 309,
              }}
            />
          )}
          {detailsRender}
        </View>
      </div>
      <div className="options-content">
        <div className="options-wrapper">{optionsRender}</div>
      </div>
    </CourseNuggetWrapperRoot>
  );
};

export default CourseNuggetWrapper;
