import React, {useRef, useEffect, useState, Suspense} from 'react';
import {View, Pressable} from 'react-native';
import type {HlsPlayerProps} from '@ducanh2912/react-hls-player/dist/index.d.ts';
import LinearGradient from 'react-native-linear-gradient';
import {useVimeoContext} from '../../Provider/VimeoProvider/VimeoContext';
import debounce from 'lodash/debounce';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';
import useOnScreen from '../../Hooks/useOnScreen';

const ReactHlsPlayer = React.lazy(() => import('@ducanh2912/react-hls-player'));
const ControlPlayer = React.lazy(
  () => import('../Player/ControlPlayer/ControlPlayer'),
);

interface IVideoBlockProps extends Omit<HlsPlayerProps, 'playerRef'> {
  vimeoId?: string;
  aspectRatio?: number;
  posterDesktop?: string;
}

const VideoBlock = (props: IVideoBlockProps) => {
  const {
    style,
    vimeoId,
    src,
    aspectRatio = 16 / 9,
    poster: defaultPoster,
    posterDesktop,
    ...rest
  } = props;
  const [ref, isIntersecting] = useOnScreen<HTMLDivElement>(undefined, {
    ignoreReverse: true,
  });
  const {getVimeoVideo} = useVimeoContext();
  const [playing, setPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [videoUrl, setVideoUrl] = useState<string>('');
  const playerRef = useRef<HTMLVideoElement>(null);
  const videoUrlRef = useRef<string>('');
  const videoUrl = videoUrlRef.current || '';
  const setVideoUrl = (v: string) => (videoUrlRef.current = v);
  const timeOut = useRef<any>(null);
  const hasVideo = !!src || (!!vimeoId && !!videoUrl);
  const {isMobile} = useBreakpoint();
  const poster = isMobile
    ? defaultPoster || posterDesktop
    : posterDesktop || defaultPoster;

  const handlePlay = debounce(async () => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.pause();
        setPlaying(false);
      } else {
        await playerRef.current.play();
        setPlaying(true);
        if (timeOut.current) {
          clearTimeout(timeOut.current);
        }
        timeOut.current = setTimeout(() => {
          setShowControls(false);
        }, 500);
      }
    }
  }, 500);
  const handleShowControls = () => {
    setShowControls(true);
    if (playing) {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
      timeOut.current = setTimeout(() => {
        setShowControls(false);
      }, 500);
    }
  };

  const fetchVimeo = async (id: string) => {
    try {
      setLoading(true);
      const data = await getVimeoVideo(id);
      if (data && data.uri && videoUrl !== data.uri) {
        setVideoUrl(data.uri);
      } else {
        setVideoUrl('');
      }
    } catch (error) {
      setVideoUrl('');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (vimeoId) {
      fetchVimeo(vimeoId);
    }
  }, [vimeoId, src]);

  return (
    <View>
      <div
        ref={ref}
        style={{
          maxWidth: 1096,
          width: '100%',
          margin: '0 auto',
          // marginHorizontal: 'auto',
          // marginVertical: '0',
          aspectRatio,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseMoveCapture={() => {
          if (hover) {
            handleShowControls();
          }
        }}>
        {isIntersecting && (
          <Suspense>
            <ReactHlsPlayer
              {...rest}
              src={vimeoId ? videoUrl : src}
              playerRef={playerRef}
              poster={poster}
              onPlaying={() => {
                console.log('onPlaying');
                setPlaying(true);
              }}
              onPause={() => {
                console.log('onPause');
                setPlaying(false);
              }}
              style={{
                ...(style || {}),
                aspectRatio,
                objectFit: 'cover',
              }}
            />
          </Suspense>
        )}

        {hasVideo && (hover || !playing) && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {(showControls || !playing) && (
              <LinearGradient
                useAngle
                angle={0}
                locations={[0, 1]}
                colors={['rgba(0, 0, 0, 0.20)', 'rgba(0, 0, 0, 0.20)']}
                style={{
                  opacity: 1,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: '100%',
                  width: '100%',
                }}
              />
            )}
            <Pressable
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={handlePlay}>
              {(showControls || !playing) && (
                <Suspense>
                  <ControlPlayer
                    isLoading={loading}
                    isPlaying={playing}
                    onPlay={handlePlay}
                  />
                </Suspense>
              )}
            </Pressable>
          </View>
        )}
      </div>
    </View>
  );
};

export default VideoBlock;
