import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import {toast} from 'react-toastify';

const useSignUpSubmit = () => {
  const authState = useAppSelector(state => state.appAuth);
  const dispatch = useAppDispatch();
  const {signUp} = useFireBaseAuthentication();

  const submit = async (formData: any) => {
    try {
      const data = {
        ...formData,
        dateOfBirth: formData.dateOfBirthValue || formData.dateOfBirth,
        language:
          typeof formData.language === 'string' ? [formData.language] : [],
        'account type': authState.socialNetworkSource || 'email',
        password: formData.password ?? 'AZERTY1234',
      };
      const response = await signUp(data);

      if (response.status === 'success') {
        dispatch(appAuthSlice.actions.resetAuth());
        return true;
      } else {
        toast.error(response.errorMessage);
        return false;
      }
    } catch (e) {
      toast.error(e as unknown as any);
      return false;
    }
  };

  return {submit};
};

export default useSignUpSubmit;
