import React, {useEffect, useState} from 'react';
import Metrics from '../../Theme/Metrics';
import CustomFastImage from '../CustomFastImage/CustomFastImage';
import {Image} from 'react-native';
import {CustomFastImageProps} from '../CustomFastImage/CustomFastImageProps';

export interface AutoScaleImageProps
  extends Omit<CustomFastImageProps, 'source' | 'width' | 'height'> {
  uri: string;
  initWidth: number;
  initHeight: number;
  hasHeight?: boolean;
}
const AutoScaleImage = (props: AutoScaleImageProps) => {
  const {uri, initWidth, initHeight, children, hasHeight, ...restProps} = props;
  const [logoSize, setLogoSize] = useState({
    width: initWidth,
    height: initHeight,
  });
  useEffect(() => {
    if (uri) {
      Image.getSize(uri, (width, height) => {
        const h = height / width;
        setLogoSize({
          width: initWidth,
          height: initWidth * h,
        });
      });
    }
  }, [uri]);

  return (
    <CustomFastImage
      width={Metrics.horizontalScale(logoSize.width)}
      height={
        hasHeight
          ? Metrics.verticalScale(logoSize.height)
          : Metrics.verticalScale(logoSize.width)
      }
      source={{uri}}
      {...restProps}
    />
  );
};

export default AutoScaleImage;
