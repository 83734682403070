import React from 'react';
import {HeadProps} from 'gatsby';

export type PageHeadProps<DataType = object, PageContextType = any> = HeadProps<
  DataType,
  PageContextType
> & {
  children?: React.ReactNode;
  title?: string;
  description?: string;
};

const PageHead = (headProps: PageHeadProps) => {
  const {pageContext, children, title = '', description = ''} = headProps;

  let meta_title = title || pageContext.metaTitle || '';
  let meta_description = description || pageContext.metaDescription || '';

  return (
    <>
      <title key={'default_meta_title'}>{meta_title}</title>
      <meta key="og:title" property="og:title" content={meta_title} />
      <meta key="description" name="description" content={meta_description} />
      <meta
        key="og:description"
        name="og:description"
        content={meta_description}
      />
      {children}
    </>
  );
};

export default PageHead;
