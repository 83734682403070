import {ListRenderItem, View} from 'react-native';
import React, {useCallback, useEffect, useState} from 'react';
import CarouselCard, {
  ICarouselCardProps,
} from '../../CarouselCard/CarouselCard';
import {Carousel} from '../../../Carousel';
import {CarouselProps} from '../../../Carousel/Carousel';
import {CardSize} from '../../../../tools/CardSizeTools';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';
import {PageInterface} from 'siema';
import {size} from '../../../../WebComponent/Grid/_utils/size';
import styled from 'styled-components';
import {useTranslation} from 'gatsby-plugin-react-i18next';
// import Trigger from 'rc-trigger';
// import 'rc-trigger/assets/index.css';
// import defaultTheme from 'design-system/src/Theme/defaultTheme';
// import {CSSTransition, TransitionGroup} from 'react-transition-group';

export interface IBlockSliderItem<T extends object = object>
  extends Omit<ICarouselCardProps, 'size' | 'premiumText'> {
  title?: string;
  progress?: number;
  metadata?: T;
  url: string;
}

export interface IBlockSliderProps<
  T extends IBlockSliderItem = IBlockSliderItem,
> extends Omit<CarouselProps<T>, 'renderItem'> {
  title: string;
  data: T[];
  size?: CardSize;
  isLink?: boolean;
  onSeeMore?: () => void;
  customRenderItem?: ListRenderItem<T>;
  premiumText?: string;
  hideTitle?: boolean;
  hideSubtitle?: boolean;
  mb?: number;
}

const mobileBreak = size.md - 1;
const desktopBreak = size.md;

const sizeMapper: Record<CardSize, number | PageInterface> = {
  small: {
    [desktopBreak]: 5,
    [mobileBreak]: 2,
  },
  small_w: 6,
  medium: {
    [desktopBreak]: 4,
    [mobileBreak]: 1.54,
  },
  big: {
    [desktopBreak]: 2,
    [mobileBreak]: 1,
  },
  xsmall: {
    [desktopBreak]: 7,
    [mobileBreak]: 2,
  },
  xsmall_w: {
    [desktopBreak]: 7,
    [mobileBreak]: 2,
  },
  channel: {
    [desktopBreak]: 6,
    [mobileBreak]: 2,
  },
  news: {
    [desktopBreak]: 4,
    [mobileBreak]: 2,
  },
  'xsmall-news': {
    [desktopBreak]: 6,
    [mobileBreak]: 2,
  },
  top10: {
    [desktopBreak]: 6,
    [mobileBreak]: 1.54,
  },
  xbig: {
    [desktopBreak]: 2,
    [mobileBreak]: 1,
  },
};

const BlockSliderRoot = styled('div')(() => {
  return {
    '&:not(:hover) [data-testid="carousel-previous-button"]': {
      display: 'none',
    },
    '&:not(:hover) [data-testid="carousel-next-button"]': {
      display: 'none',
    },
  };
});

const BlockSlider = <T extends IBlockSliderItem = IBlockSliderItem>(
  props: IBlockSliderProps<T>,
) => {
  const {
    data,
    title,
    size = 'small',
    isLink,
    onSeeMore,
    seeMore,
    customRenderItem,
    premiumText = 'premium',
    hideTitle,
    hideSubtitle,
    mb,
    ...carouselProps
  } = props;

  const {t} = useTranslation('translation');

  const {mobile: isMobile} = useBreakpoint();
  const [itemSize, setItemSize] = useState<number>();
  const gap = isMobile ? 8 : 12;
  const containerPadding = isMobile ? 16 : 60;

  const handleSeeMore = () => {
    onSeeMore && onSeeMore();
  };
  useEffect(() => {
    const handler = () => {
      const sliderPageSize = sizeMapper[size] || sizeMapper.small;
      const perPageCount = isMobile
        ? sliderPageSize[mobileBreak]
        : sliderPageSize[desktopBreak];
      const newItemSize =
        (window.innerWidth - containerPadding * 2 - (perPageCount - 1) * gap) /
        perPageCount;
      if (itemSize !== newItemSize) {
        if (newItemSize > 321 && size === 'news') {
          setItemSize(321);
        } else if (newItemSize > 654 && size === 'big') {
          setItemSize(654);
        } else {
          setItemSize(newItemSize);
        }
      }
    };

    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [containerPadding, gap, itemSize]);

  const defaultRenderItem: ListRenderItem<T> = useCallback(
    ({item, index}) => {
      const {title: itemTitle, subtitle, cover, ...rest} = item;
      return (
        <CarouselCard
          key={index}
          title={itemTitle}
          subtitle={subtitle}
          size={size}
          cover={cover}
          number={index + 1}
          premiumText={premiumText}
          hideTitle={hideTitle}
          hideSubtitle={hideSubtitle}
          {...rest}
          forcedWidth={itemSize}
          onPress={() => {
            carouselProps.onItemPress?.(item, index);
          }}
        />
      );
    },
    [size, premiumText, hideTitle, hideSubtitle, carouselProps, itemSize],
  );

  return (
    <BlockSliderRoot
      style={{
        paddingLeft: containerPadding,
        marginBottom: mb ? mb : isMobile ? 10 : 49,
      }}
      data-test-id="block-slider"
      // accessibilityLabel="data-custom-attribute-value"
    >
      <Carousel
        title={title}
        data={data}
        renderItem={customRenderItem ?? defaultRenderItem}
        initialNumToRender={5}
        gap={gap}
        // perPage={sliderPageSize}
        seeMore={
          isLink
            ? seeMore ?? {label: t('See more'), onPress: handleSeeMore}
            : undefined
        }
        itemSize={
          isMobile
            ? undefined
            : size === 'top10'
              ? (itemSize || 0) * (332 / 269) // ratio of top10 card with number before image size
              : itemSize
        }
        {...carouselProps}
      />
    </BlockSliderRoot>
  );
};

export default BlockSlider;
