import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import Icon from 'design-system/src/Components/Icon/Icon';
import useDefaultTheme from 'design-system/src/Theme/useTheme';
import {IconName} from 'design-system/src/assets/Icones';
import React, {FC} from 'react';

interface IMenuActionHeaderProps {
  iconName: IconName;
  onClick?: () => void;
}

const MenuActionHeader: FC<IMenuActionHeaderProps> = props => {
  const {onClick, iconName} = props;
  const defaultTheme = useDefaultTheme();
  return (
    <PressableCustom onPress={onClick}>
      {({hover}) => (
        <Icon
          name={iconName}
          color={hover ? defaultTheme.primary : undefined}
        />
      )}
    </PressableCustom>
  );
};

export default MenuActionHeader;
