import ActionGroup, {
  ActionGroupItem,
} from 'design-system/src/Components/ActionGroup/ActionGroup';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import {ItemOverviewProps} from 'design-system/src/Components/ItemOverview/ItemOverview';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useCallback} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View, Pressable, Alert} from 'react-native';
import {
  ICourseFragmentFragment,
  ICourseUnitFragmentFragment,
  useGetCourseDownloadLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import {ILearnHomeCardProps} from 'design-system/src/Components/Card/LearnCard/LearnHomeCard/LearnHomeCard';
import {useDispatch} from 'react-redux';

import useUpdateBookmarks from '~Hooks/useUpdateBookmarks';
import FavorisIcon from 'design-system/src/Components/CheckBox/FavoirsIcon/FavorisIcon';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import {formatDuration} from '~Hooks/useStringExtension';
import AutoScaleImage from 'design-system/src/Components/AutoScaleImage/AutoScaleImage';
import {useGetCourseOverview} from './useGetCourseOverview';
import {OverviewService} from '~Umbraco/Utils/Overview/overview.service';
import {ICourseTestResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import useAppNavigation from '@src/utils/useAppNavigation';
import {useSelecteUnitsProgression} from '@src/Store/selectors/useMember';
import {AppRoutesEnum} from '@src/Constant/routes';
import CouseUnitList from '../Components/CouseUnitList/CouseUnitList';
import LearnOverview from '../Components/LearnOverview/LearnOverview';
import {getContentEndUrl} from '@src/Umbraco/Utils/getContentEndUrl';

export const useSingleLearnPage = (courseId: string) => {
  const {t} = useTranslation('learn');
  const navigation = useAppNavigation();
  const dispatch = useDispatch();
  const unitsProgression = useSelecteUnitsProgression();
  const {loading: overviewLoading, overview} = useGetCourseOverview(courseId);

  const handleButtonHeader = useCallback(
    (
      courseUnitIds: string[],
      courseUnitUrls: string[],
      courseId: string,
      coursUnit?: ICourseUnitFragmentFragment,
      currentIndex?: number,
    ) => {
      if (coursUnit) {
        navigation.navigate(AppRoutesEnum.COURSE_UNIT, {
          state: {
            params: {
              id: coursUnit.id,
              endUrl: getContentEndUrl(coursUnit.url || '', 'CourseUnit'),
              courseUnitUrls,
              courseUnitIds,
              courseId,
              currentIndex,
            },
          },
        });
      } else {
        Alert.alert(t('Unit error'));
      }
    },
    [],
  );

  const handleClickUnit =
    (
      courseUnits: ICourseUnitFragmentFragment,
      courseUnitIds: string[],
      courseUnitUrls: string[],
      courseId: string,
      index: number,
    ) =>
    () => {
      if (courseUnits) {
        navigation.navigate(AppRoutesEnum.COURSE_UNIT, {
          state: {
            params: {
              id: courseUnits.id,
              endUrl: getContentEndUrl(courseUnits.url || '', 'CourseUnit'),
              courseUnitIds,
              courseUnitUrls,
              courseId,
              currentIndex: index,
            },
          },
        });
      } else {
        Alert.alert(t('Unit error'));
      }
    };
  const {isBookmarked, updateBookMark} = useUpdateBookmarks({
    screenSource: 'detail',
  });

  const [fetchDownloadCourse] = useGetCourseDownloadLazyQuery();

  const findUnifinishedUnit = useCallback(
    (items: ICourseUnitFragmentFragment[]) => {
      let unFinishedUnit: ICourseUnitFragmentFragment | undefined;
      let isFinishedUnitNotExist: boolean = true;
      for (let unit of items) {
        if (unit && unit.__typename === 'CourseUnit') {
          let findElement = unitsProgression.find(
            unitProgression => unitProgression.name === unit.id,
          );
          if (!findElement && !unFinishedUnit) {
            unFinishedUnit = unit;
          } else if (findElement) {
            isFinishedUnitNotExist = false;
          }
        }
      }
      return {unFinishedUnit, isFinishedUnitNotExist};
    },
    [unitsProgression],
  );

  const handleDownload = useCallback(
    async (isDownload: boolean, learnProperty: ILearnPropertyName) => {
      // ??
    },
    [fetchDownloadCourse, courseId, dispatch],
  );

  const openTrailer = (item: ICourseFragmentFragment) => {
    if (item.trailer) {
      navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
        state: {
          params: {
            liveEventId: item.trailer,
            videoUrl:
              typeof item.trailer === 'string' ? item.trailer : undefined,
            title: item.title || '',
          },
        },
      });
    }
  };

  const renderSingleLearnPage = (
    course: ICourseFragmentFragment,
    isDownload: boolean,
    learnProperty: ILearnPropertyName,
    hasCertificate: boolean,
    examProgress?: ICourseTestResponse,
  ): ReactNode[] => {
    const pageBlocks: ReactNode[] = [];

    const {
      id: courseId,
      name,
      title,
      linkedPartners,
      description,
      children: {items},
      cover,
      thumbnail,
    } = course;

    let courseExamId: string | undefined;
    let courseExamUrl: string | undefined;
    const findExam = items.find(_item => _item?.__typename === 'CourseExam');
    if (findExam && findExam.__typename === 'CourseExam') {
      courseExamId = findExam.id;
      courseExamUrl = findExam.url;
    }
    let coursPartners: ReactNode[] = [];
    if (linkedPartners) {
      linkedPartners.forEach((partner, index) => {
        if (
          partner &&
          partner.__typename === 'Partner' &&
          partner.logo &&
          partner.logo.url
        ) {
          coursPartners.push(
            <AutoScaleImage
              key={`pt-${index}`}
              uri={partner.logo.url}
              initWidth={60}
              initHeight={16}
              backgroundFallbackColor="transparent"
              resizeMode="contain"
            />,
          );
        }
      });
    }
    let buttonHeaderTitle: string | undefined = undefined;
    const _units = items as ICourseUnitFragmentFragment[];
    const {unFinishedUnit, isFinishedUnitNotExist} =
      findUnifinishedUnit(_units);
    const isExamSuccess = examProgress?.success;
    let isCompleted: boolean = false;
    const filteredUnits = _units.filter(_d => _d.__typename === 'CourseUnit');
    const isAllUnitFinished = filteredUnits.every(
      _unit =>
        unitsProgression.find(
          _unitFinished => _unitFinished.name === _unit?.id,
        ) !== undefined,
    );
    let isTakeExam: boolean = false;
    let isContinueCourse: boolean = false;
    if (isAllUnitFinished) {
      if (isExamSuccess || !courseExamId) {
        buttonHeaderTitle = t('COMPLETED') as string;
        isCompleted = true;
      } else if (courseExamId) {
        buttonHeaderTitle = t('TAKE THE EXAM') as string;
        isTakeExam = true;
      }
    } else if (
      isFinishedUnitNotExist &&
      items[0] &&
      items[0].__typename === 'CourseUnit'
    ) {
      buttonHeaderTitle = t('START COURSE') as string;
      isContinueCourse = true;
    } else if (unFinishedUnit) {
      buttonHeaderTitle = t('CONTINUE WITH THIS COURSE') as string;
      isContinueCourse = true;
    }

    let courseUnitIds: string[] = [];
    let courseUnitUrls: string[] = [];
    if (items) {
      items.forEach(item => {
        if (item && item.__typename === 'CourseUnit') {
          courseUnitIds.push(item.id);
          courseUnitUrls.push(item.url || '');
        }
      });
    }
    pageBlocks.push(
      <ServiceHeader
        key={'header'}
        image={{
          uri:
            cover &&
            cover[0] &&
            cover[0].content &&
            cover[0].content.__typename === 'HeroVideoCover' &&
            cover[0].content.image
              ? cover[0].content.image.cropUrl || cover[0].content.image.url
              : thumbnail?.cropUrl || thumbnail?.url,
        }}
        desktopImage={{
          uri:
            cover &&
            cover[0] &&
            cover[0].content &&
            cover[0].content.__typename === 'HeroVideoCover'
              ? cover[0].content.desktopImage?.url ||
                cover?.[0]?.content.image?.url ||
                thumbnail?.url
              : thumbnail?.url,
        }}
        title={title || name || ''}
        description=""
        buttonProps={{
          icon: isAllUnitFinished && isCompleted ? undefined : 'play',
        }}
        buttonTitle={buttonHeaderTitle}
        gradiantVariant="bottom"
        onPressButton={() => {
          if (isTakeExam) {
            navigation.navigate(AppRoutesEnum.EXAM, {
              state: {
                params: {
                  id: courseExamId || '',
                  url: courseExamUrl || '',
                  courseId,
                },
              },
            });
          } else if (unFinishedUnit && isContinueCourse) {
            const findIndex = filteredUnits.findIndex(
              _item => _item.id === unFinishedUnit.id,
            );
            handleButtonHeader(
              courseUnitIds,
              courseUnitUrls,
              courseId,
              unFinishedUnit,
              findIndex,
            );
          }
        }}
        partner={{
          label: t('Avec'),
          logo: coursPartners.length ? coursPartners : undefined,
          displayCol: coursPartners.length > 1,
        }}
      />,
    );

    // action group
    const isCourseLiked = isBookmarked(course.id);
    let actionGroups: Array<ActionGroupItem> = [
      {
        icon: ({onHoverChange, onValueChange}) => (
          <FavorisIcon
            style={{padding: 13, margin: -13}}
            isLiked={isCourseLiked}
            iconSize={'24'}
            onHoverChange={onHoverChange}
            onValueChange={onValueChange}
            onPressFav={value => {
              tracker.learn.course.liked(learnProperty);
              course &&
                course.url &&
                updateBookMark(course.id, course.url!, value, {
                  id: course.id,
                  url: course.url,
                  __typename: course.__typename,
                  raw: course,
                });
            }}
          />
        ),
        label: t('Favoris'),
        isActive: isCourseLiked,
      },
    ];
    if (course.trailer) {
      actionGroups.push({
        icon: 'play',
        label: t('Teaser'),
        onPress: () => {
          openTrailer(course);
        },
      });
    }

    pageBlocks.push(
      <View
        key={'action-group'}
        style={{
          marginTop: Metrics.verticalScale(36),
        }}>
        <ActionGroup data={actionGroups} />
      </View>,
    );
    let _courseOverview: ItemOverviewProps | undefined = undefined;
    let itemOverview = overview;
    if (isDownload) {
      itemOverview = OverviewService.getCourseOverview(course);
    }
    if (itemOverview) {
      const {multimediaUnits, videosCount, quizz, duration, quizzCount} =
        itemOverview;
      const quizzLabel = quizzCount > 1 ? t('Quizzes') : t('Quizze');
      _courseOverview = {
        title: t('Course overview'),
        data: {
          multimedia: `${multimediaUnits || 0} ${t('Multimedia Units')}`,
          videos: `${videosCount || 0} ${t('Videos')}`,
          quizzes: quizz ? `${quizzCount} ${quizzLabel}` : undefined,
          hours: duration,
        },
        loading: overviewLoading,
      };
    }
    pageBlocks.push(
      <LearnOverview
        key={'overview'}
        title={t('Course description') || undefined}
        overviewData={_courseOverview}
        description={description}
        actionButton={
          hasCertificate
            ? {
                title: t('Linked Job training'),
                onPress: () => {
                  navigation.navigate(AppRoutesEnum.LINKED_JOB, {
                    state: {
                      params: {
                        id: courseId || '',
                        url: course.url,
                      },
                    },
                  });
                },
              }
            : undefined
        }
      />,
    );

    // list course units
    let courseUnits: Array<ILearnHomeCardProps<ICourseUnitFragmentFragment>> =
      [];
    let gradingPolicy: number = 0;
    if (items) {
      items.forEach((item, index) => {
        if (item && item.__typename === 'CourseUnit') {
          let {title, length, thumbnail: _thumbnail, id, url} = item;
          let _duration: string | undefined = undefined;
          if (length) {
            _duration = formatDuration(length, length < 3600);
          }
          const findUnit = unitsProgression.find(item => item.name === id);
          courseUnits.push({
            cover: {
              uri: _thumbnail?.cropUrl || _thumbnail?.url,
            },
            title: title || '',
            duration: _duration,
            tag: {
              text: `Unit ${index + 1}`,
              variant: 'unit',
            },
            id,
            url,
            raw: item,
            onPress: () =>
              handleClickUnit(
                item,
                courseUnitIds,
                courseUnitUrls,
                courseId,
                index,
              ),
            isDone: findUnit !== undefined,
          });
        } else if (item && item.__typename === 'CourseExam') {
          gradingPolicy = item.gradingPolicy || 0;
        }
      });
      const buttonExamTitle = !isExamSuccess
        ? t('Test available')
        : t('See my results');
      pageBlocks.push(
        <CouseUnitList
          key={'list'}
          courseUnits={courseUnits}
          onItemClick={(item, index) => {
            item.raw &&
              handleClickUnit(
                item.raw,
                courseUnitIds,
                courseUnitUrls,
                courseId,
                index,
              )();
          }}
          courseExamId={courseExamId}
          courseExamUrl={courseExamUrl}
          buttonExamTitle={buttonExamTitle}
          isExamSuccess={isExamSuccess}
          examProgress={examProgress}
          gradingPolicy={gradingPolicy}
          courseId={courseId}
        />,
      );
    }
    return pageBlocks;
  };

  return {renderSingleLearnPage};
};
