import React from 'react';
import CustomFastImage from '../CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../CustomFastImage/CustomFastImageProps';
import {channelLogoSizeStypes} from './ChannelLogo.styles';

export interface ChannelLogoProps {
  size?: keyof typeof channelLogoSizeStypes;
  source: CustomFastImageProps['source'];
}

const ChannelLogo = (props: ChannelLogoProps) => {
  const {size = 'large', source} = props;
  const {width, height} = channelLogoSizeStypes[size];
  return <CustomFastImage width={width} height={height} source={source} />;
};

export default ChannelLogo;
