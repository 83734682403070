import React, {useEffect} from 'react';
import {UnitItem} from './components/UnitResult';
import Metrics from 'design-system/src/Theme/Metrics';
import {View} from 'react-native';
import {IExamResult, IExamResultItem} from '@src/Api/TraceApi/interface/exam';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useParams from '@src/Hooks/useParams';
import ExamResult from './ExamResult';
import {useAppDispatch} from '@src/Store/hooks';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useAppNavigation from '@src/utils/useAppNavigation';
import appSlice from '@src/Store/Slices/AppSlice';
import CustomModalHeader from '@src/components/general/CustomModal/CustomModalHeader';

export interface IExamResultProps {
  data: IExamResult;
  positiveResult?: {
    title: string;
    body: string;
  };
  negativeResult?: {
    title: string;
    body: string;
  };
  gradingPolicy?: number;
  delay?: number;
  onCourseFinished?: () => void;
}
type IUnitResultData = Pick<
  IExamResultItem,
  'correct' | 'total' | 'title' | 'unitTitle'
>;
export const mapUnitResult = (
  items: Array<IUnitResultData>,
): Array<UnitItem> => {
  return items.map(e => ({
    score: e.correct,
    total: e.total,
    name: e.title || '',
  }));
};

const ExamResultPage = () => {
  const {
    data,
    positiveResult,
    negativeResult,
    gradingPolicy = 0,
    delay,
    onCourseFinished,
  } = useParams();

  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const {t} = useTranslation('translation');

  const {isMobile} = useBreakpoint();

  useEffect(() => {
    dispatch(
      appSlice.actions.setModalHeader(
        <CustomModalHeader
          title={t('your marks').toUpperCase() || ''}
          onClose={() => navigation.goBack()}
        />,
      ),
    );
    return () => {
      dispatch(appSlice.actions.setModalHeader(null));
    };
  }, []);

  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: isMobile ? Metrics.horizontalScale(24) : undefined,
        marginTop: isMobile ? Metrics.verticalScale(100) : undefined,
      }}>
      {data && (
        <ExamResult
          data={data}
          positiveResult={positiveResult}
          negativeResult={negativeResult}
          gradingPolicy={gradingPolicy}
          delay={delay}
          onCourseFinished={onCourseFinished}
        />
      )}
    </View>
  );
};

export default ExamResultPage;
