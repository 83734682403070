import {IButtonFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import {IButtonProps} from 'design-system/src/Components/Button/Button';
import {HeroSliderData} from '../content/mapHeroSlide';

export interface IButtonData {
  url?: string;
  id?: string;
  buttonProps?: IButtonProps;
  __typename: string;
}

const mapButtonData = (buttonContent: IButtonFragmentFragment): IButtonData => {
  let url;
  let id;
  let __typename = 'Unknown';
  const buttonProps: HeroSliderData['button'] | undefined = {
    variant:
      (buttonContent.variant as NonNullable<
        HeroSliderData['button']
      >['variant']) || 'outlined',
    icon: (buttonContent.icon || '').toLowerCase() as NonNullable<
      HeroSliderData['button']
    >['icon'],
    size:
      (buttonContent.size as NonNullable<HeroSliderData['button']>['size']) ||
      'small',
    title: buttonContent.title || '',
  };

  if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    (buttonContent.link_to[0].content.__typename === 'InternalLink' ||
      buttonContent.link_to[0].content.__typename === 'ContentLink')
  ) {
    url = buttonContent.link_to[0].content.link?.url || '';
    id = buttonContent.link_to[0].content.link?.id || '';
    __typename =
      buttonContent.link_to[0]?.content.link?.__typename || 'Unknown';
  } else if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    buttonContent.link_to[0].content.__typename === 'ExternalLink'
  ) {
    url = buttonContent.link_to[0].content.externalUrl || '';
    id = '';
    __typename = 'ExternalLink';
  }
  return {
    buttonProps: {
      ...buttonProps,
      title: buttonProps.title || '',
    },
    url,
    id: id || '',
    __typename,
  };
};

export default mapButtonData;
