import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {ReactNode, useMemo} from 'react';
import {View} from 'react-native';
import { 
  useArtistServicesPageByIdQuery,
} from '~Api/Graphql/gql/types.generated';
import useArtistServicesPageUtils from '~Umbraco/Hooks/useArtistServicesPageUtils';
interface ArtistServicesPageProps {
  id: string;
}

const ArtistServicesPage = (props: ArtistServicesPageProps) => {
  const {id} = props;
  const {isMobile} = useBreakpoint()

  const response = useArtistServicesPageByIdQuery({
    variables: {
      id,
    },
    errorPolicy: 'all',
    skip: !id,
  });
  const {renderPage} = useArtistServicesPageUtils();

  const loading = response.loading;

  const {
    pageContent,
  }: {pageContent: ReactNode[]; } =
    useMemo(() => {
      const {data} = response;
      if (
        data &&
        data.artistServicesPage &&
        data.artistServicesPage.__typename === 'ArtistServicesPage' &&
        data.artistServicesPage.services
      ) {
        return {
          pageContent: renderPage(data.artistServicesPage),
        };
      }
      return {pageContent: []};
    }, [ renderPage, response]); 

  return (
    <View style={{flex: 1}}>
      {loading ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CustomActivityIndicator />
        </View>
      ) : <div 
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: isMobile ? 48 : 96,
            }}>
        { pageContent }
      </div> }
    </View>
  );
};

export default ArtistServicesPage;
