import styled from 'styled-components';

import borderIcon from '../../../../icons/awards-cat-border-icon-min.png';
import circleIcon from '../../../../icons/awards-cat-circle-icon-min.png';
import triangleIcon from '../../../../icons/awards-cat-triangle-icon-min.png';
import squareIcon from '../../../../icons/awrads-cat-square-icon-min.png';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';

const iconVariant = {
  border: borderIcon,
  circle: circleIcon,
  triangle: triangleIcon,
  square: squareIcon,
};

export const _AwardCategoryBorderIcon = styled('span')<{
  $variant?: keyof typeof iconVariant;
}>(({$variant = 'circle'}) => {
  return {
    background: `url(${iconVariant[$variant]})`,
    backgroundSize: 'cover',
    width: 24,
    height: 24,
    transform: 'rotate(90deg)',
  };
});
export const _AwardCategoryWrapper = styled('div')(() => {
  return {
    top: 48,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    transform: 'rotate(-90deg)',
    transformOrigin: '100% 0%',
    flexWrap: 'nowrap',
    '& > *': {
      whiteSpace: 'nowrap',
    },
    [`@media ${responsiveMap.mobile}`]: {
      display: 'none',
    },
  };
});
