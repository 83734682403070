import React from 'react';
import {View} from 'react-native';
import {darkTheme} from '../../../../styles/Themes';
import Metrics from '../../../../Theme/Metrics';
import {selectSize} from '../../../../tools/CardSizeTools';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import PostTypeText from '../../../Text/PostTypeText/PostTypeText';
import GenericCard from '../../GenericCard/GenericCard';
import ListenCardAside from '../ListenCardAside/ListenCardAside';
import {ListenCardProps} from '../ListenCardProps';

const ListenHomeCard = (props: ListenCardProps) => {
  const size = React.useCallback(selectSize, [props])(props, false);
  const {cover, listenTypeTextColor, listenType, isWithProgressBar, progress} =
    props;
  return (
    <View
      style={{
        width: Metrics.horizontalScale(size.width),
      }}>
      <View style={{marginBottom: Metrics.verticalScale(11)}}>
        <GenericCard
          coverImage={cover}
          backgroundColor={darkTheme.colors.fifthGray}
          width={size.width}
          height={size.height}
          contentStyle={{
            paddingBottom: isWithProgressBar
              ? Metrics.verticalScale(4)
              : undefined,
          }}
          center={
            <PostTypeText textColor={listenTypeTextColor} text={listenType} />
          }
          footer={
            isWithProgressBar && <CustomProgressBar progress={progress} />
          }
        />
      </View>
      <ListenCardAside asideProps={props} />
    </View>
  );
};

export default ListenHomeCard;
