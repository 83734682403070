import {IPrivateRouteProps} from '@src/App/components/PrivateRoute/PrivateRoute';
import {AppRoutesEnum} from '@src/Constant/routes';
import EmptyRoute from '../EmptyRoute/EmptyRoute';
import React from 'react';
import JobVacancyScreen from '@src/App/components/pages/LearnHomePage/JobBoardsScreen/JobVacancyScreen';
const TutorialScreen = React.lazy(
  () => import('@src/App/components/pages/TutorialScreen/TutorialScreen'),
);
const ProfileOpportunities = React.lazy(
  () =>
    import('@src/Navigation/Profile/ProfileOpportunities/ProfileOpportunities'),
);
const QuizzSurveyCollectionScreen = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/QuizzSurvey/QuizzSurveyCollectionScreen'
    ),
);
const QuizzSurveyScreen = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/QuizzSurvey/QuizzSurveyScreen'
    ),
);
const QuizzSurveyCategoryScreen = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/QuizzSurvey/QuizzSurveyCategoryScreen'
    ),
);
const PlayerQuizSurveyScreen = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/QuizzSurvey/PlayerQuizSurveyScreen'
    ),
);

const AppFormPage = React.lazy(
  () => import('@src/App/components/pages/AppFormPage/AppFormPage'),
);

const UnitScreen = React.lazy(
  () => import('@src/App/components/pages/LearnHomePage/UnitScreen/UnitScreen'),
);

const LinkedJob = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/LinkedJob/LinkedJob'
    ),
);

const SingleCourse = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/SingleCourse/SingleCourse'
    ),
);

const PromoCodePage = React.lazy(
  () => import('@src/Navigation/PromoCodePage/PromoCodePage'),
);
const AppCategorieWatch = React.lazy(
  () => import('@src/App/components/pages/categorieWatch/AppCategorieWatch'),
);

const PaywallPage = React.lazy(
  () => import('@src/Navigation/PaywallPage/PaywallPage'),
);
const LearnHomePage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/LearnHomePage/LearnHomePage'
    ),
);
const ArtistHomePage = React.lazy(
  () => import('@src/App/components/pages/ArtistHomePage/ArtistHomePage'),
);
const SuccessPlanPage = React.lazy(
  () =>
    import('@src/Navigation/ChoosePlanPage/SuccessPlanPage/SuccessPlanPage'),
);
const ChoosePlanPage = React.lazy(
  () => import('@src/Navigation/ChoosePlanPage/ChoosePlanPage'),
);

const ProfileHome = React.lazy(
  () => import('@src/Navigation/Profile/ProfileHome/ProfileHome'),
);
const ProfileMyProfile = React.lazy(
  () => import('@src/Navigation/Profile/ProfileMyProfile/ProfileMyProfile'),
);
const ProfileAchievement = React.lazy(
  () => import('@src/Navigation/Profile/ProfileAchievement/ProfileAchievement'),
);
const ProfileNotification = React.lazy(
  () =>
    import('@src/Navigation/Profile/ProfileNotification/ProfileNotification'),
);
const ProfileSetting = React.lazy(
  () => import('@src/Navigation/Profile/ProfileSetting/ProfileSetting'),
);
const ProfileSubscription = React.lazy(
  () =>
    import('@src/Navigation/Profile/ProfileSubscription/ProfileSubscription'),
);
const AppSearch = React.lazy(
  () => import('@src/App/components/pages/Search/AppSearch'),
);

const AppHome = React.lazy(
  () => import('@src/App/components/pages/home/AppHome'),
);
const AppHomeWatch = React.lazy(
  () => import('@src/App/components/pages/homeWatch/AppHomeWatch'),
);
const AppHomeListen = React.lazy(
  () => import('@src/App/components/pages/listen/AppHomeListen'),
);
const AppWatchLiveTV = React.lazy(
  () => import('@src/App/components/pages/homeWatch/LiveTvScreen/LiveTvScreen'),
);
const AppWatchLiveEvents = React.lazy(
  () =>
    import(
      '@src/App/components/pages/homeWatch/LiveEventScreen/LiveEventScreen'
    ),
);
const MenuDrawerContent = React.lazy(
  () => import('@src/layout/AppLayout/AppDrawer/MenuDrawer/MenuDrawerContent'),
);

const AppVideoPlayer = React.lazy(
  () => import('@src/App/components/pages/AppVideoPlayer/AppVideoPlayer'),
);
const TermsAndConditions = React.lazy(
  () =>
    import('@src/App/components/pages/TermsAndConditions/TermsAndConditions'),
);
const ComingSoon = React.lazy(
  () => import('@src/App/components/pages/ComingSoon/ComingSoon'),
);
const ProfileHelpdesk = React.lazy(
  () => import('@src/Navigation/Profile/ProfileHelpdesk/ProfileHelpdesk'),
);
const ProfileZendeskForm = React.lazy(
  () => import('@src/Navigation/Profile/ProfileZendeskForm/ProfileZendeskForm'),
);
const ProfileBookmarksScreen = React.lazy(
  () =>
    import(
      '@src/Navigation/Profile/ProfileBookmarksScreen/ProfileBookmarksScreen'
    ),
);
const AppVideoPlayerVerticale = React.lazy(
  () =>
    import('@src/App/components/pages/AppVideoPlayer/AppVideoPlayerVerticale'),
);

const VodHomePage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/homeWatch/VodPage/VodHomePage/VodHomePage'
    ),
);

const VodSinglePage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/homeWatch/VodPage/VodSinglePage/VodSinglePage'
    ),
);

const VodCollectionPage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/homeWatch/VodPage/VodCollectionPage/VodCollectionPage'
    ),
);

const NewsWatchPage = React.lazy(
  () =>
    import('@src/App/components/pages/homeWatch/NewsWatchPage/NewsWatchPage'),
);

const RadioHomePage = React.lazy(
  () => import('@src/App/components/pages/listen/Radio/RadioHomePage'),
);

const RadioCategoryPage = React.lazy(
  () => import('@src/App/components/pages/listen/Radio/RadioCategoryPage'),
);
const PodCastHomePage = React.lazy(
  () => import('@src/App/components/pages/listen/PodCast/PodCastHomePage'),
);

const PodCastCategoryPage = React.lazy(
  () => import('@src/App/components/pages/listen/PodCast/PodCastCategoryPage'),
);

const PodCastSinglePage = React.lazy(
  () => import('@src/App/components/pages/listen/PodCast/PodCastSinglePage'),
);

const PartnersPage = React.lazy(
  () =>
    import('@src/App/components/pages/LearnHomePage/PartnersPage/PartnersPage'),
);

const PartnerSingle = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/PartnersPage/PartnerSingle/PartnerSingle'
    ),
);

const HomeQuizPage = React.lazy(
  () => import('@src/App/components/pages/QuizPage/HomeQuizPage/HomeQuizPage'),
);

const QuizQuestionPage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/QuizPage/QuizQuestionPage/QuizQuestionPage'
    ),
);
const QuizResultPage = React.lazy(
  () =>
    import('@src/App/components/pages/QuizPage/QuizResultPage/QuizResultPage'),
);

const QuizPersonalityPage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/QuizPage/QuizPersonalityPage/QuizPersonalityPage'
    ),
);

const PersonalityPage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/QuizPage/PersonalityPage/PersonalityPage'
    ),
);

const QuizMethodologyPage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/QuizPage/QuizMethodologyPage/QuizMethodologyPage'
    ),
);

const SingleJob = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/SingleJob/SingleJob'
    ),
);

const JobHubScreen = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/JobHubScreen'
    ),
);

const JobBoardEntrepreneurial = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/JobBoardEntrepreneurial'
    ),
);

const OpportunitiesScreen = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/OpportunitiesScreen'
    ),
);

const SingleOpportunity = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/SingleOpportunity/SingleOpportunity'
    ),
);

const JobBoardsScreen = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/JobBoardsScreen/JobBoardsScreen'
    ),
);

const ExamScreen = React.lazy(
  () => import('@src/App/components/pages/LearnHomePage/ExamScreen/ExamScreen'),
);

const ExamResultPage = React.lazy(
  () =>
    import('@src/App/components/pages/LearnHomePage/ExamScreen/ExamResultPage'),
);

const AppThankYouPage = React.lazy(
  () => import('@src/App/components/pages/AppThankYouPage/AppThankYouPage'),
);

const CourseCategoryPage = React.lazy(
  () =>
    import(
      '@src/App/components/pages/LearnHomePage/CourseCategoryPage/CourseCategoryPage'
    ),
);

const ListenRedirection = React.lazy(
  () => import('@src/App/components/pages/listen/ListenRedirection'),
);

const emptyRoute = {component: EmptyRoute};

// const FormDetailPage = React.lazy(
//   () => import('@src/App/components/pages/FormDetailPage/FormDetailPage'),
// );

// const OpportunitySinglePage = React.lazy(
//   () => import('@src/App/components/pages/OpportunitySinglePage/OpportunitySinglePage'),
// );

// const QuizDetailPage = React.lazy(
//   () => import('@src/App/components/pages/QuizDetailPage/QuizDetailPage'),
// );

export const appRoutes: {
  [path in AppRoutesEnum]: Omit<IPrivateRouteProps, 'path'> & {
    parent?: AppRoutesEnum;
  };
} = {
  [AppRoutesEnum.DynamicPage]: emptyRoute,
  // watch
  [AppRoutesEnum.HOME_WATCH]: {component: AppHomeWatch},

  [AppRoutesEnum.LIVE_TV]: {
    component: AppWatchLiveTV,
    parent: AppRoutesEnum.HOME_WATCH,
  },
  [AppRoutesEnum.LIVE_EVENTS_ITEM]: emptyRoute,
  [AppRoutesEnum.LIVE_EVENTS]: {
    component: AppWatchLiveEvents,
    parent: AppRoutesEnum.HOME_WATCH,
  },

  // [AppRoutesEnum.CATEGORY_WATCH]: {component: AppCategorieWatch},
  // -----> NEWS
  [AppRoutesEnum.NEWS_HOME]: {
    component: NewsWatchPage,
    parent: AppRoutesEnum.HOME_WATCH,
  },
  [AppRoutesEnum.NEWS_SINGLE]: {
    component: AppVideoPlayerVerticale,
    parent: AppRoutesEnum.HOME_WATCH,
  },
  // -----> VOD
  [AppRoutesEnum.VOD_HOME]: {
    component: VodHomePage,
    parent: AppRoutesEnum.HOME_WATCH,
  },
  [AppRoutesEnum.VOD_CATEGORY]: {
    component: AppCategorieWatch,
    parent: AppRoutesEnum.HOME_WATCH,
  },
  [AppRoutesEnum.VOD_CATEGORY_HOME]: {
    component: AppCategorieWatch,
    parent: AppRoutesEnum.HOME_WATCH,
  },
  [AppRoutesEnum.VOD_SINGLE]: {
    component: VodSinglePage,
    parent: AppRoutesEnum.HOME_WATCH,
  }, // single - standard
  [AppRoutesEnum.VOD_COLLECTION]: {
    component: VodCollectionPage,
    parent: AppRoutesEnum.HOME_WATCH,
  }, // collection - serie

  // Listen
  [AppRoutesEnum.HOME_LISTEN]: {component: AppHomeListen},
  [AppRoutesEnum.RADIO_HOME]: {
    component: RadioHomePage,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.RADIO_CATEGORY]: {
    component: RadioCategoryPage,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.RADIO_CATEGORY_HOME]: {
    component: RadioCategoryPage,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.RADIO_PLAYER]: {
    ...emptyRoute,
    parent: AppRoutesEnum.HOME_LISTEN,
  },

  [AppRoutesEnum.PODCAST_CATEGORY]: {
    component: PodCastCategoryPage,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.PODCAST_CATEGORY_HOME]: {
    component: PodCastCategoryPage,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.PODCAST_SINGLE]: {
    component: PodCastSinglePage,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.PODCAST_HOME]: {
    component: PodCastHomePage,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.PODCAST_PLAYER]: {
    ...emptyRoute,
    parent: AppRoutesEnum.HOME_LISTEN,
  },
  [AppRoutesEnum.LISTEN_REDIRECTION]: {
    component: ListenRedirection,
    parent: AppRoutesEnum.HOME_LISTEN,
  },

  // Learn
  [AppRoutesEnum.HOME_LEARN]: {component: LearnHomePage},
  [AppRoutesEnum.COURSE_SINGLE]: {
    component: SingleCourse,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.COURSE_UNIT]: {
    component: UnitScreen,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.EXAM]: {
    component: ExamScreen,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.LINKED_JOB]: {
    component: LinkedJob,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.EXAM_RESULT_OVERVIEW]: {
    component: ExamResultPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.JOB_TRAINING]: {
    ...emptyRoute,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.PARTNERS_LANDING]: {
    component: PartnersPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.PARTNER_SINGLE]: {
    component: PartnerSingle,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.HOME_QUIZ]: {
    component: HomeQuizPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.QUIZ_QUESTION]: {
    component: QuizQuestionPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.QUIZ_PERSONALITY]: {
    component: QuizPersonalityPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.QUIZ_METHODOLOGY]: {
    component: QuizMethodologyPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.QUIZ_RESULT]: {
    component: QuizResultPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.PERSONALITY]: {
    component: PersonalityPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.SINGLE_JOB]: {
    component: SingleJob,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.JOB_HUB]: {
    component: JobHubScreen,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.JOB_VACANCIES]: {
    component: JobVacancyScreen,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.JOB_BOARD_PAGE]: {
    component: JobBoardsScreen,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.ENTREPRENEURIAL_HUB]: {
    component: JobBoardEntrepreneurial,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.OPPORTUNITY_CENTER]: {
    component: OpportunitiesScreen,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.OPPORTUNITY_SINGLE]: {
    component: SingleOpportunity,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.COURSE_CATEGORY]: {
    component: CourseCategoryPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },
  [AppRoutesEnum.COURSE_CATEGORY_HOME]: {
    component: CourseCategoryPage,
    parent: AppRoutesEnum.HOME_LEARN,
  },

  // Artist
  [AppRoutesEnum.ARTIST_SERVICE_HOME]: {component: ArtistHomePage},
  [AppRoutesEnum.ARTIST_SERVICE]: emptyRoute,

  [AppRoutesEnum.FORMS]: {
    component: AppFormPage,
  },

  // Search
  [AppRoutesEnum.SEARCH]: {component: AppSearch},

  // Profile
  [AppRoutesEnum.PROFILE_HOME]: {component: ProfileHome},
  [AppRoutesEnum.PROFILE_MY_PROFILE]: {
    component: ProfileMyProfile,
  },
  [AppRoutesEnum.PROFILE_SUBSCRIPTION]: {component: ProfileSubscription},
  [AppRoutesEnum.PROFILE_SETTINGS]: {component: ProfileSetting},
  [AppRoutesEnum.PROFILE_LEGAL_INFORMATION]: emptyRoute,
  [AppRoutesEnum.PROFILE_HELPDESK]: {
    component: ProfileHelpdesk,
  },
  [AppRoutesEnum.PROFILE_ZENDESK_FORM]: {
    component: ProfileZendeskForm,
  },
  [AppRoutesEnum.PROFILE_ACHIEVEMENT]: {
    component: ProfileAchievement,
  },
  [AppRoutesEnum.PROFILE_NOTIFICATION]: {
    component: ProfileNotification,
  },
  [AppRoutesEnum.PROFILE_OPPORTUNITIES]: {
    component: ProfileOpportunities,
  },

  // More
  [AppRoutesEnum.MORE]: {component: MenuDrawerContent},

  // Home
  [AppRoutesEnum.HOME]: {component: AppHome},

  // Paywall
  [AppRoutesEnum.PAYWALL]: {
    component: PaywallPage,
  },
  [AppRoutesEnum.CHOOSE_PLAN]: {
    component: ChoosePlanPage,
  },
  [AppRoutesEnum.SUCCESS_PLAN]: {
    component: SuccessPlanPage,
  },
  [AppRoutesEnum.VIDEO_PLAYER]: {
    component: AppVideoPlayer,
  },
  [AppRoutesEnum.TERMS_AND_CONDITION]: {
    component: TermsAndConditions,
  },
  [AppRoutesEnum.COMING_SOON]: {
    component: ComingSoon,
  },

  [AppRoutesEnum.BOOKMARKS]: {
    component: ProfileBookmarksScreen,
  },
  [AppRoutesEnum.PROMOCODE]: {
    component: PromoCodePage,
  },
  [AppRoutesEnum.THANK_YOU_MODAL]: emptyRoute,

  [AppRoutesEnum.THANK_YOU]: {
    component: AppThankYouPage,
  },

  // [AppRoutesEnum.FORMS_DETAIL]: {
  //   component: FormDetailPage,
  //   parent: AppRoutesEnum.FORMS,
  // },
  [AppRoutesEnum.QUIZ_DETAIL]: emptyRoute,
  // [AppRoutesEnum.QUIZ_DETAIL]: {
  //   component: QuizDetailPage,
  //   parent: AppRoutesEnum.QUIZ,
  // },
  [AppRoutesEnum.QUIZZ_SURVEY_COLLECTION]: {
    component: QuizzSurveyCollectionScreen,
  },
  [AppRoutesEnum.QUIZZ_SURVEY]: {
    component: QuizzSurveyScreen,
  },
  [AppRoutesEnum.QUIZZ_SURVEY_CATEGORY]: {
    component: QuizzSurveyCategoryScreen,
  },
  [AppRoutesEnum.PLAYER_QUIZ_SURVEY]: {
    component: PlayerQuizSurveyScreen,
  },
  [AppRoutesEnum.TUTORIAL]: {
    component: TutorialScreen,
  },
};
