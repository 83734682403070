import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const leadingCoverSize = StyleSheet.create({
  large: {
    width: Metrics.horizontalScale(109),
    height: Metrics.verticalScale(120),
    fontSize: Metrics.verticalScale(96),
  },
  medium: {
    width: Metrics.horizontalScale(109),
    height: Metrics.verticalScale(96),
    fontSize: Metrics.verticalScale(64),
  },
});

export const leadingTopCardStyles = StyleSheet.create({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cover: {
    borderRadius: Metrics.moderateScale(4),
    backgroundColor: 'blue'
  },
});
