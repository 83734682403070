import {StyleSheet} from 'react-native';
import Metrics from '../../../../Theme/Metrics';

export const gradVariantStyle = {
  afrikora: {
    firstColor: '#6BBCBC',
    secondColor: '#EC8F7D',
  },
  caribean: {
    firstColor: '#69C07F',
    secondColor: '#F3AE00',
  },
  ayiti: {
    firstColor: '#C32928',
    secondColor: '#1E3D74',
  },
  naija: {
    firstColor: '#A11671',
    secondColor: '#1F4E9B',
  },
};

export const gradChannelSize = {
  large: {
    width: Metrics.horizontalScale(168),
    height: Metrics.verticalScale(94),
  },
  medium: {
    width: Metrics.horizontalScale(108),
    height: Metrics.verticalScale(82),
  },
};

export const gradientChannelCardStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: Metrics.moderateScale(4),
  },
});
