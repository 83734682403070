import {useCourseExamByUrlEndQuery} from '@src/Api/Graphql/gql/types.generated';

const useExamFetch = (endUrl: string) => {
  const {data, loading} = useCourseExamByUrlEndQuery({
    variables: {
      endUrl: endUrl,
    },
    skip: !endUrl,
  });

  const exam = data?.allCourseExam.items[0];
  const examId = exam?.id;
  const courseId: string | undefined =
    (exam?.parent?.__typename === 'Course' && exam?.parent?.id) || undefined;

  return {exam, loading, examId, courseId};
};

export default useExamFetch;
