import {IFormPageFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {Form} from '../interface/UmbracoTypes';
import {useAppSelector} from '@src/Store/hooks';

export type IInterestFormPage = {form?: Form} & Omit<
  IFormPageFragmentFragment,
  'form'
>;

const useSignUpForm = () => {
  const {signupForm, additionnalStep} = useAppSelector(
    state => state.settings.signup,
  );

  return {
    form: signupForm,
    additionnalStep,
    loading: false,
  };
};

export default useSignUpForm;
