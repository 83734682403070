import React, {FC, useMemo, useState} from 'react';
import {RouteComponentProps} from '@reach/router';
import useDefaultTheme from 'design-system/src/Theme/useTheme';
import {useAllHelpdeskFolderQuery} from '@src/Api/Graphql/gql/types.generated';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useMember from '@src/Store/selectors/useMember';
import {Form} from '@src/Api/Umbraco/interface/UmbracoTypes';
import {appNavigateBack} from '@src/utils/navigationUtils';
import {View, StyleSheet} from 'react-native';
import UmbracoForm from '@src/Umbraco/Components/UmbracoForm/UmbracoForm';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import CustomActivityIndicator from 'design-system/src/Components/CustomActivityIndicator/CustomActivityIndicator';
import FlexBox from 'design-system/src/WebComponent/FlexBox/FlexBox.web';

const ProfileZendeskForm: FC<
  RouteComponentProps<{
    title: string;
  }>
> = ({title}) => {
  const titleDecoded = decodeURIComponent(title ?? '');
  const theme = useDefaultTheme();
  const {data: helpdeskFolderData, loading} = useAllHelpdeskFolderQuery();
  const {t} = useTranslation('profile');
  const [thanks, setThanks] = useState<string>();
  const member = useMember();

  const form: Form | undefined = useMemo(() => {
    if (
      helpdeskFolderData &&
      helpdeskFolderData.allHelpdeskFolder &&
      helpdeskFolderData.allHelpdeskFolder.items &&
      helpdeskFolderData.allHelpdeskFolder.items[0]
    ) {
      return helpdeskFolderData.allHelpdeskFolder.items[0].zendeskForm;
    }
  }, [helpdeskFolderData]);

  const handleSuccess = () => {
    if (form && form.messageOnSubmit) {
      setThanks(form.messageOnSubmit);
    } else {
      appNavigateBack();
    }
  };

  if (loading) {
    return (
      <FlexBox flex={1} justify="center" align="center">
        <CustomActivityIndicator />
      </FlexBox>
    );
  }

  return (
    <div>
      <View style={{flex: 1}}>
        {form && member && titleDecoded ? (
          <UmbracoForm
            form={form}
            additionnalFormData={{
              memberID: member._id,
              sourceQuestion: titleDecoded,
            }}
            onSuccess={handleSuccess}
            contentContainerStyle={{flex: 1}}
          />
        ) : (
          <ContentEmpty title={t('Form not found')} />
        )}
 
        {thanks && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {backgroundColor: theme.page.background},
            ]}>
            <ContentEmpty
              title={thanks}
              buttonTitle={t('Go back') || ''}
              onPress={() => appNavigateBack()}
            />
          </View>
        )}
      </View>
    </div>
  );
};

export default ProfileZendeskForm;
