import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export const radioPlayerCardStyles = StyleSheet.create({
  container: {
    backgroundColor: defaultTheme.player.background,
    borderTopRightRadius: Metrics.moderateScale(2),
    borderTopLeftRadius: Metrics.moderateScale(2),
    // height: Metrics.horizontalScale(64),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: Metrics.horizontalScale(16),
    paddingVertical: Metrics.verticalScale(14),
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
  },
  card: {
    width: Metrics.horizontalScale(64),
    height: Metrics.horizontalScale(64),
    borderRadius: Metrics.moderateScale(4),
    position: 'absolute',
  },
  body: {
    flex: 1,
  },
  aside: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  trailing: {
    paddingRight: Metrics.horizontalScale(8),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconPlay: {
    marginLeft: Metrics.horizontalScale(8),
  },
  iconFav: {
    marginRight: Metrics.horizontalScale(8),
  },
  radioName: {
    marginRight: Metrics.horizontalScale(8),
  },
  title: {
    marginBottom: Metrics.verticalScale(5),
  },
});
