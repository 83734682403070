import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import BarLoader from '../ComponentLoader/BarLoader/BarLoader';
import CardLoader from '../ComponentLoader/CardLoader/CardLoader';

interface ICarouselLoaderProps {
  itemsCount?: number;
}

const CarouselLoader = (props: ICarouselLoaderProps) => {
  const {itemsCount = 2} = props;
  return (
    <View style={carouselLoaderStyles.root}>
      <BarLoader
        type="medium"
        style={{
          marginLeft: Metrics.horizontalScale(16),
          marginBottom: Metrics.verticalScale(8),
        }}
      />
      <View style={carouselLoaderStyles.container}>
        {Array.from({length: itemsCount}).map((_, index) => (
          <CardLoader key={index} type="small" />
        ))}
      </View>
    </View>
  );
};

export default CarouselLoader;

const carouselLoaderStyles = StyleSheet.create({
  root: {
    marginVertical: Metrics.verticalScale(23),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: Platform.OS === 'web' ? undefined : 'space-between',
    paddingHorizontal: Metrics.horizontalScale(16),
    gap: Platform.OS === 'web' ? 16 : undefined,
  },
});
