import channelSlice from '@src/Store/Slices/Channel.slice';
import {TraceApiEndpointBuilder} from '../traceApi';
import {TvModel} from '@src/Models/TvModel';

export const createChannelEndpoint = (builder: TraceApiEndpointBuilder) => ({
  channelProgram: builder.query<any, {channelId: string; url: string}>({
    query: ({url}) => ({
      url: '/channel/program',
      method: 'POST',
      body: {
        link: url,
      },
    }),
    transformResponse: response => {
      return new TvModel({}).getGuideFormatted(response as any);
    },
    async onQueryStarted({channelId}, {dispatch, queryFulfilled}) {
      try {
        const response = await queryFulfilled;
        if (response && response.data) {
          dispatch(
            channelSlice.actions.setChannelGuide({
              id: channelId,
              data: response.data,
            }),
          );
        }
      } catch (error) {
        console.log('getTvGuideXml--', error);
      }
    },
  }),
});
