import React, {FC} from 'react';
import AppProgressBar from './AppProgressBar';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface IAppMiniViewCarouselProps {
  backgroundUrl?: string;
  progress?: number;
  stay?: string;
}

const AppMiniViewCarousel: FC<IAppMiniViewCarouselProps> = ({
  backgroundUrl,
  progress,
  stay,
}) => {
  const {isMobile} = useBreakpoint();
  return (
    <div
      style={{
        marginTop: 40,
        width: isMobile ? 226 : '100%',
        height: isMobile ? 100 : 264,
        backgroundImage: `url(${backgroundUrl})`,
        marginBottom: 30,
        borderRadius: 4,
        padding: 8,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}>
      {typeof progress == 'number' && (
        <div
          style={{
            width: '98%',
          }}>
          {!isMobile && (
            <AppProgressBar
              progress={progress}
              stay={stay}
              stayColor={defaultTheme.white}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AppMiniViewCarousel;
