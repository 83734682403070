import {useAllMusicGenrePerLangQuery} from '~Api/Graphql/gql/types.generated';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';

interface MusicGenreItem {
  name: string;
  id: string;
}

interface MusicGenre {
  fr: MusicGenreItem[];
  en: MusicGenreItem[];
  pt: MusicGenreItem[];
}
const tagLanguage: Record<string, keyof MusicGenre> = {
  'en-US': 'en',
  fr: 'fr',
  'pt-BR': 'pt',
};

const useMusicGenre = () => {
  const {data, loading} = useAllMusicGenrePerLangQuery({
    fetchPolicy: 'no-cache',
  });
  const currentLanguage = useCurrentLanguage();
  const tagLang = tagLanguage[currentLanguage];

  const musicGenre: MusicGenre = {
    fr:
      data?.frTag?.children?.items?.map(item => ({
        name: item?.name || '',
        id: item?.id || '',
      })) || [],
    en:
      data?.enTag?.children?.items?.map(item => ({
        name: item?.name || '',
        id: item?.id || '',
      })) || [],
    pt:
      data?.ptTag?.children?.items?.map(item => ({
        name: item?.name || '',
        id: item?.id || '',
      })) || [],
  };

  const findMusicGenreItem = (tag: string) => {
    let tagItem: MusicGenreItem | undefined;
    const tagInFr = musicGenre.fr.find(
      item => item.name === tag || item.id === tag,
    );
    if (tagInFr) tagItem = tagInFr;

    const tagInEn = musicGenre.en.find(
      item => item.name === tag || item.id === tag,
    );
    if (tagInEn) tagItem = tagInEn;

    const tagInPt = musicGenre.pt.find(
      item => item.name === tag || item.id === tag,
    );
    if (tagInPt) tagItem = tagInPt;

    if (tagItem) {
      return tagLang
        ? musicGenre[tagLang]?.find(item => item.id === tagItem.id) || tagItem
        : tagItem;
    }
    return null;
  };

  return {
    musicGenre: tagLang
      ? musicGenre[tagLang] || musicGenre['en']
      : musicGenre['en'],
    loading,
    findMusicGenreItem,
  };
};

export default useMusicGenre;
