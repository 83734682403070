import {usePromoCodeContent} from '@src/Navigation/PromoCodePage/usePromoCodeContent';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import React from 'react';
import {View} from 'react-native';
const PromoCodeImage = () => {
  const promoCodeContent = usePromoCodeContent();

  const {footerImage} = promoCodeContent;
  return (
    <>
      {footerImage && (
        <View
          style={{
            width: '100%',
            aspectRatio: 16 / 9,
            position: 'relative',
            borderRadius: 4,
            overflow: 'hidden',
            marginTop: 'auto',
          }}>
          <CustomFastImage
            source={{uri: footerImage}}
            width={'100%'}
            height={'100%'}
            style={{width: '100%', height: '100%'}}
            wrapperStyles={{
              width: '100%',
              aspectRatio: 16 / 9,
            }}
          />
        </View>
      )}
    </>
  );
};

export default PromoCodeImage;
