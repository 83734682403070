import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  GetTutorialDocument,
  IGetTutorialQuery,
  IGetTutorialQueryVariables,
  ITutorialSlideFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {mapHeroVideoCover} from '~Umbraco/Utils/mapHeroVideoCover';
import TutorialWidget, {ITutorialWidget} from './TutorialWidget';
import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {HomeContentLoader} from 'design-system/src/Components/ContentLoader';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Pressable, Text, View} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import Typography from 'design-system/src/Components/Typography/Typography';
import {
  useCurrentLanguage,
  useLanguages,
} from '~Store/Slices/LanguageSlice/Language.selector';
import {getLocales} from 'react-native-localize';
import {translationClientInstance} from '~Api/Graphql';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

const TutorialScreen = () => {
  const params = useParams();
  const navigation = useAppNavigation();
  const languages = useLanguages();
  const currentLanguage = useCurrentLanguage();
  const {t} = useTranslation(['auth']);
  const {isMobile} = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const {step = 0} = params || {};
  const [tutorialSlides, setTutorialSlides] = useState<
    ITutorialSlideFragmentFragment[] | undefined
  >(undefined);

  const lng: string = useMemo(() => {
    const localLng = getLocales()[0];
    const selectedItem =
      languages.find(
        el =>
          el.isoCode === localLng.languageCode ||
          el.isoCode === localLng.languageTag,
      ) || languages[0];
    return selectedItem.isoCode;
  }, [languages, currentLanguage]);

  useEffect(() => {
    setLoading(true);
    translationClientInstance
      .query<IGetTutorialQuery, IGetTutorialQueryVariables>({
        query: GetTutorialDocument,
        variables: {
          culture: lng,
        },
        fetchPolicy: 'no-cache',
      })
      .then(response => {
        const {data} = response;

        if (
          data &&
          data.allSettings &&
          data.allSettings.items &&
          data.allSettings.items[0]
        ) {
          const {tutorialSlides} = data.allSettings.items[0];
          if (tutorialSlides) {
            const list: ITutorialSlideFragmentFragment[] = [];

            tutorialSlides.forEach(tutorialSlide => {
              if (
                tutorialSlide &&
                tutorialSlide.content &&
                tutorialSlide.content.__typename === 'TutorialSlide'
              ) {
                list.push(tutorialSlide.content);
              }
            });
            setTutorialSlides(list);
          }
        }
      })
      .catch(error => {
        console.log('error when getting tutorial', error);
        setTutorialSlides([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [lng]);

  const tutorials: ITutorialWidget[] = useMemo(() => {
    let _tutorials: ITutorialWidget[] = [];
    tutorialSlides?.forEach((tutorial, index) => {
      if (tutorial) {
        const {title, description, cover} = tutorial;
        let _mediaCover: IMediaCover | undefined = undefined;
        if (
          cover &&
          cover[0] &&
          cover[0].content &&
          cover[0].content.__typename === 'HeroVideoCover'
        ) {
          _mediaCover = mapHeroVideoCover(cover[0].content, isMobile);
        }
        _tutorials.push({
          mediaCover: _mediaCover,
          title: title || '',
          subtitle: description || '',
          index,
        });
      }
    });
    return _tutorials;
  }, [tutorialSlides, isMobile]);

  const handleClose = useCallback(() => {
    navigation.goBack();
    // if (isNotFirstLaunch) {
    //   navigation.goBack();
    // } else {
    //   navigation.reset({
    //     index: 0,
    //     routes: [{name: 'AuthOnBoarding'}],
    //   });
    // }
  }, [navigation]);

  useEffect(() => {
    const handleBack = async () => {
      if (step === 0) {
        handleClose();
      } else {
        navigation.setParams({
          step: Math.max(step - 1, 0),
        });
      }
    };
    if (tutorials) {
      navigation.setOptions({
        isCenterTitle: true,
        overrideTitle: (
          <Typography>
            <Typography variant="htitle" children={t('Step ')} />
            <Typography
              variant="htitle"
              color="white"
              children={t('{{current}} of {{total}}', {
                current: step + 1,
                total: tutorials.length,
              })}
            />
          </Typography>
        ),
        isWithLogo: false,
        hideAction: true,
        extraAction: (
          <Pressable onPress={handleClose}>
            <Icon name="close" />
          </Pressable>
        ),

        back: step > 0 ? {title: ''} : undefined,
        noBack: step === 0,
        onPressArrowBack: step > 0 ? handleBack : undefined,
      });
    } else {
      navigation.setOptions({});
    }
    return () => {
      navigation.setOptions({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorials, step]);

  if (loading) {
    return <HomeContentLoader />;
  }

  if (tutorials && tutorials[step]) {
    return (
      <TutorialWidget
        {...tutorials[step]}
        isActive={true}
        buttonTitle={
          (step < tutorials.length - 1 ? t('Continue') : t('close')) as string
        }
        onPressButton={() => {
          if (step < tutorials.length - 1) {
            navigation.setParams({step: step + 1});
          } else {
            handleClose();
          }
        }}
      />
    );
  }
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ContentEmpty
        title={t('Tutorial page not found')}
        buttonTitle={t('Continue') || ''}
        onPress={handleClose}
      />
    </View>
  );
};

export default TutorialScreen;
