import React, {ReactNode, useEffect, useState} from 'react';
import {NativeScrollEvent, ScrollView, View} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';

import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import TabView from 'design-system/src/Components/DataDisplay/TabView/TabView';
import {FavorisTabContentProps} from 'design-system/src/Components/FavorisTab/FavorisTabContent';
import {
  useSearchLearnLazyQuery,
  useSearchListenLazyQuery,
  useSearchWatchLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import apiConfig from '~Configs/apiConfig';
import {
  initialLearnPageInfo,
  initialListenPageInfo,
  initialWatchPageInfo,
  useMapSearchResult,
} from '@src/Hooks/useMapSearchResult';
// import {useMapCourseCateg} from '@src/Umbraco/Utils/content/mapCourseCategItems';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import styled from 'styled-components';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';

const ResponsiveWrapper = styled.div<{
  $rowGap: number;
}>`
  row-gap: ${({$rowGap}) => Metrics.verticalScale($rowGap)}px;
  column-gap: ${Metrics.horizontalScale(12)}px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media ${responsiveMap.mobile} {
    grid-template-columns: 1fr;
  }
  @media ${responsiveMap.md} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${responsiveMap.xxl} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const SuggestionSearchResult = ({keyWord}: {keyWord: string}) => {
  const [dataWatchSearch, setDataWatchSearch] = useState<ReactNode[]>();
  const tracker = useTracker();
  const [dataListenSearch, setDataListenSearch] = useState<ReactNode[]>();
  const [dataLearnSearch, setDataLearnSearch] = useState<ReactNode[]>();
  const [_, forceUpdate] = useState(0);

  const triggerForceUpdate = () => {
    forceUpdate(prev => prev + 1);
  };
  const {
    mapSearchWatchData,
    mapSearchListenData,
    mapSearchLearnData,
    watchPageInfo,
    listenPageInfo,
    learnPageInfo,
    hasSearchWatchNextPage,
    hasSearchListenNextPage,
    hasSearchLearnNextPage,
  } = useMapSearchResult();
  const {t} = useTranslation(['search']);
  const [fetchSearchWatch, {loading: watchLoading}] = useSearchWatchLazyQuery();
  const [fetchSearchListen, {loading: listenLoading}] =
    useSearchListenLazyQuery();
  const [fetchSearchLearn, {loading: learnLoading}] = useSearchLearnLazyQuery();
  // const {mapCourseCategItems} = useMapCourseCateg();
  const [paginationLoading, setPaginationLoading] = useState(false);

  // const [currentIndex, setCurrentIndex] = useState(0);

  const {isMobile} = useBreakpoint();

  const fetchSearchData = (
    index: number,
    key: string,
    lastSearch?: ReactNode[],
    lastListen?: ReactNode[],
    lastLearn?: ReactNode[],
  ): Promise<number> => {
    return new Promise(async (resolve) => {
    tracker.general.searchLaunched();
    switch (index) {
      case 0:
        if (hasSearchWatchNextPage()) {
          fetchSearchWatch({
            variables: {
              first: 10,
              keyWord: key,
              vodCursor: watchPageInfo.current.vodPageInfo?.endCursor,
              collectionCursor:
                watchPageInfo.current.collectionPageInfo?.endCursor,
              liveEventCursor:
                watchPageInfo.current.liveEventPageInfo?.endCursor,
              newsCursor: watchPageInfo.current.newsPageInfo?.endCursor,
              channelCursor: watchPageInfo.current.channelPageInfo?.endCursor,
            },
          }).then(value => {
            const total: number =
              (value.data?.allChannel.edges?.length || 0) +
              (value.data?.allCollection.edges?.length || 0) +
              (value.data?.allLiveEvent.edges?.length || 0) +
              (value.data?.allNews.edges?.length || 0) +
              (value.data?.allVODItem.edges?.length || 0); 
            if (value.data) {
              setDataWatchSearch(
                mapSearchWatchData(value.data, key, lastSearch),
              );
            }
            setPaginationLoading(false);
            resolve(total);
          }).catch(error => {
            resolve(0);
          });
        } else {
          resolve(0);
        }
        break;
      case 1:
        if (hasSearchListenNextPage()) {
          fetchSearchListen({
            variables: {
              keyWord: key,
              first: 10,
              playlistCursor:
                listenPageInfo.current.playlistPageInfo?.endCursor,
              podcastCursor: listenPageInfo.current.podcastPageInfo?.endCursor,
              podcastItemCursor:
                listenPageInfo.current.podcastItemPageInfo?.endCursor,
              radioCursor: listenPageInfo.current.radioPageInfo?.endCursor,
            },
          })
            .then(value => {
              const total: number =
                (value.data?.allPlaylist.edges?.length || 0) +
                (value.data?.allPodcast.edges?.length || 0) +
                (value.data?.allPodcastItem.edges?.length || 0) +
                (value.data?.allRadio.edges?.length || 0) 
              if (value.data) {
                setDataListenSearch(
                  mapSearchListenData(value.data, key, lastListen),
                );
              }
              resolve(total);
            })
            .catch(error => {
              resolve(0);
            })
            .finally(() => setPaginationLoading(false));
        } else {
          resolve(0);
        }
        break;
      case 2:
        if (hasSearchLearnNextPage()) {
          fetchSearchLearn({
            variables: {
              keyWord: key,
              first: 10,
              courseCursor: learnPageInfo.current.coursePageInfo?.endCursor,
              jobCursor: learnPageInfo.current.jobPageInfo?.endCursor,
            },
          })
            .then(value => {
              const total: number =
                (value.data?.allCourse.edges?.length || 0) +
                (value.data?.allJobTraining.edges?.length || 0) +
                (value.data?.allJobHub?.edges?.length || 0) +
                (value.data?.allJobVacancy?.edges?.length || 0) +
                (value.data?.allPartner?.edges?.length || 0);

              if (value.data) {
                setDataLearnSearch(
                  mapSearchLearnData(value.data, key, lastLearn),
                );
              }
              resolve(total);
            })
            .catch(error => {
              resolve(0);
            })
            .finally(() => setPaginationLoading(false));
        } else {
          resolve(0);
        }
        break;
      default:
        resolve(0);
        break;
      } 
    });
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const isLoading = watchLoading || listenLoading || learnLoading;
  const fetchAllData = (options: {
    dataWatchSearch?: ReactNode[];
    dataListenSearch?: ReactNode[];
    dataLearnSearch?: ReactNode[];
  }) => {
    Promise.allSettled([
      fetchSearchData(0, keyWord, options.dataWatchSearch, options.dataListenSearch, options.dataLearnSearch),
      fetchSearchData(1, keyWord, options.dataWatchSearch, options.dataListenSearch, options.dataLearnSearch),
      fetchSearchData(2, keyWord, options.dataWatchSearch, options.dataListenSearch, options.dataLearnSearch),
    ]).then(([watch, listen, learn]) => {
      const currentTotal = (options.dataWatchSearch?.length || 0) + (options.dataListenSearch?.length || 0) + (options.dataLearnSearch?.length || 0);
      const total: number = (watch.status === 'fulfilled' ? watch.value || 0 : 0) + (listen.status === 'fulfilled' ? listen.value || 0 : 0) + (learn.status === 'fulfilled' ? learn.value || 0 : 0) + currentTotal;

      triggerForceUpdate();

      tracker.general.searchCompleted({
        keywords: keyWord,
        number_of_search_results: total,
      });
    });
  }

 
  const allData = [
    ...(dataWatchSearch || []),
    ...(dataListenSearch || []),
    ...(dataLearnSearch || []),
  ];

  useEffect(() => {
    if (keyWord && keyWord.length >= 3) {
      watchPageInfo.current = initialWatchPageInfo;
      listenPageInfo.current = initialListenPageInfo;
      learnPageInfo.current = initialLearnPageInfo;
      setDataListenSearch(undefined);
      setDataWatchSearch(undefined);
      setDataLearnSearch(undefined);
      fetchAllData({});
    }
  }, [keyWord]);

  const favorisData: Array<{
    key: FavorisTabContentProps['type'];
    label: string;
  }> = [
    {
      key: 'all',
      label: t('All'),
    },
    {
      key: 'watch',
      label: t('Watch'),
    },
    {
      key: 'listen',
      label: t('Listen'),
    },
    {
      key: 'learn',
      label: t('Learn'),
    },
  ];

  return (
    <View style={{flex: 1}}>
      <TabView
        activeKey=""
        items={favorisData}
        variant={'custom'}
        // onChangeIndex={index => {
        //   if (index !== currentIndex) {
        //     setCurrentIndex(index);
        //     if (
        //       !watchLoading &&
        //       !learnLoading &&
        //       !listenLoading &&
        //       keyWord.length >= 3
        //     ) {
        //       switch (index) {
        //         case 0:
        //           if (!dataWatchSearch) {
        //             fetchSearchData(index, keyWord);
        //           }
        //           break;
        //         case 1:
        //           if (!dataListenSearch) {
        //             fetchSearchData(index, keyWord);
        //           }
        //           break;
        //         case 2:
        //           if (!dataLearnSearch) {
        //             fetchSearchData(index, keyWord);
        //           }
        //           break;
        //         default:
        //           break;
        //       }
        //     }
        //   }
        // }}
        customRenderScene={({route}) => (
          <ScrollView
            onScroll={({nativeEvent}) => {
              if (isCloseToBottom(nativeEvent)) {
                if (
                  !watchLoading &&
                  !listenLoading &&
                  !learnLoading &&
                  (hasSearchWatchNextPage() ||
                    hasSearchListenNextPage() ||
                    hasSearchLearnNextPage())
                ) {
                  setPaginationLoading(true);
                  fetchAllData(
                    {
                    dataWatchSearch,
                    dataListenSearch,
                    dataLearnSearch,}
                  );
                }
              }
            }}
            scrollEventThrottle={400}
            contentContainerStyle={{
              paddingTop: Metrics.verticalScale(24),
              // paddingHorizontal: Metrics.horizontalScale(16),
            }}>
            {route.key === 'all' &&
              (isLoading && !paginationLoading ? (
                <View
                  style={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CustomActivityIndicator />
                </View>
              ) : allData && allData.length > 0 ? (
                <ResponsiveWrapper $rowGap={isMobile ? 12 : 50}>
                  {allData}
                </ResponsiveWrapper>
              ) : (
                <NoResultView noResultMessage={t('search.noresult')} />
              ))}
            {route.key === 'watch' &&
              (watchLoading && !paginationLoading ? (
                <View
                  style={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CustomActivityIndicator />
                </View>
              ) : dataWatchSearch && dataWatchSearch.length > 0 ? (
                <ResponsiveWrapper $rowGap={isMobile ? 12 : 50}>
                  {dataWatchSearch}
                </ResponsiveWrapper>
              ) : (
                <NoResultView noResultMessage={t('search.noresult')} />
              ))}
            {route.key === 'listen' &&
              (listenLoading && !paginationLoading ? (
                <View
                  style={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CustomActivityIndicator />
                </View>
              ) : dataListenSearch && dataListenSearch.length > 0 ? (
                <ResponsiveWrapper $rowGap={isMobile ? 12 : 50}>
                  {dataListenSearch}
                </ResponsiveWrapper>
              ) : (
                <NoResultView noResultMessage={t('search.noresult')} />
              ))}
            {route.key === 'learn' &&
              (learnLoading && !paginationLoading ? (
                <View
                  style={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <CustomActivityIndicator />
                </View>
              ) : dataLearnSearch && dataLearnSearch.length > 0 ? (
                <ResponsiveWrapper $rowGap={isMobile ? 12 : 50}>
                  {dataLearnSearch}
                </ResponsiveWrapper>
              ) : (
                <NoResultView noResultMessage={t('search.noresult')} />
              ))}
          </ScrollView>
        )}
      />
    </View>
  );
};

const NoResultView = ({noResultMessage}: {noResultMessage: string}) => {
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <ContentEmpty title={noResultMessage} />
    </View>
  );
};
