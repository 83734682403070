import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IQuizzSurveyResponse} from './interface/quizz';
import {IExamProgress} from '~Api/TraceApi/interface/exam';
import {ICourseTestResponse} from '../memberDataEndpoint/interface/memberDataResponse.interface';

const endpoint = '/quizz-survey';

export const createQuizzSurveyEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  postQuizzSurvey: builder.query<IQuizzSurveyResponse, IExamProgress>({
    query: body => ({
      url: `${endpoint}?culture=${body.culture}`,
      method: 'POST',
      body,
    }),
  }),
  getQuizzSurveyProgress: builder.query<ICourseTestResponse, {id: string}>({
    forceRefetch: () => true,
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
});
