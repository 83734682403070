import { StyleSheet } from "react-native";
import defaultTheme from "../../../../Theme/defaultTheme";
import Metrics from "../../../../Theme/Metrics";

export const vodThemeVariant = StyleSheet.create({
    primary: {
        backgroundColor: defaultTheme.card.vodTheme.primary
    },
    secondary: {
        backgroundColor: defaultTheme.card.vodTheme.secondary
    },
    tertiary: {
        backgroundColor: defaultTheme.card.vodTheme.tertiary
    }
});

export const vodThemeStyles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: Metrics.moderateScale(4),
        width: Metrics.horizontalScale(108),
        height: Metrics.verticalScale(80)
    }
})