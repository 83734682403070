import {useDispatch} from 'react-redux';
import memberSlice, {WatchedItem} from '~Store/Slices/Member.slice';
import {useCallback} from 'react';
import {useLazyGetAllMemberWatchedQuery} from '~Api/TraceApi/traceApi';
import {IWatchedResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {useAppSelector} from '@src/Store/hooks';

const useMemberWatched = (): {
  fetchWatched: () => Promise<IWatchedResponse[]>;
  loading: boolean;
  data: Record<string, WatchedItem>;
} => {
  const {watched = {}, member} = useAppSelector(({member: memberState}) => ({
    watched: memberState.allWatched,
    member: memberState.member,
  }));
  const dispatch = useDispatch();
  const [fetchWatched, {isLoading: loading}] =
    useLazyGetAllMemberWatchedQuery();
  const handleResponse = useCallback(
    async (fetchedData?: IWatchedResponse[]) => {
      const list: IWatchedResponse[] = [];
      if (fetchedData && fetchedData.length) {
        let allWatched: Record<string, WatchedItem> = {};
        let watchedUnPublished = localStorage.getItem('watched');
        if (watchedUnPublished) {
          let decodeUnPublished: Array<Record<string, WatchedItem>> =
            JSON.parse(watchedUnPublished);
          decodeUnPublished.forEach(unPublished => {
            allWatched = {
              ...allWatched,
              ...unPublished,
            };
          });
        }

        fetchedData.forEach(item => {
          if (item) {
            list.push(item);
            let contentId = item.contentId;
            let apiTime = item.watchedTimestamp ?? 0;
            if (!allWatched[`${contentId}`]) {
              allWatched[`${contentId}`] = {
                time: apiTime,
                parentId: item._id,
                length: item.length ?? 0,
                metadata: item,
                contentSource: item.contentSource,
                updatedAt: item._updateDate,
              };
            }
          }
        });
        dispatch(memberSlice.actions.setAllWatched(allWatched));
      }

      return list;
    },
    [dispatch],
  );

  const _fetchWatched = useCallback(async () => {
    if (member) {
      try {
        const {data: fetchedData} = await fetchWatched();
        handleResponse(fetchedData);
        return fetchedData || [];
      } catch (error) {
        return [];
      }
    }
    return [];
  }, [fetchWatched, handleResponse, member]);

  return {
    fetchWatched: _fetchWatched,
    loading,
    data: watched,
  };
};

export default useMemberWatched;
