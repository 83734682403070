import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {createMemberCourseStatusEndpoint} from './memberCourseStatus.endpoint';
import {createMemberCourseTestProgressionEndpoint} from './memberCourseTestProgression.endpoint';
import {createMemberDownloadedEndpoint} from './memberDownloaded.endpoint';
import {createMemberJobProgressEndpoint} from './memberJobProgress.endpoint';
import {createMemberLearnShareEndpoint} from './memberLearnShare.endpoint';
import {createMemberMessageEndpoint} from './memberMessage.endpoint';
import {createMemberNotifyEndpoint} from './memberNotify.endpoint';
import {createMemberObtainedJobEndpoint} from './memberObtainedJob.endpoint';
import {createMemberOpportunityEndpoint} from './memberOpportunity.endpoint';
import {createMemberPersonalityTypeEndpoint} from './memberPersonalityType.endpoint';
import {createMemberSavedEndpoint} from './memberSaved.endpoint';
import {createMemberUnitProgressionEndpoint} from './memberUnitProgression.endpoint';
import {createMemberWatchedEndpoint} from './memberWatched.endpoint';
import {createMemberSearchEndpoint} from './memberSearch.endpoint';
import {createPromocodeEndpoint} from './memberPromocode.endpoint';

export const createMemberDataEndpoint = (builder: TraceApiEndpointBuilder) => ({
  ...createMemberCourseStatusEndpoint(builder),
  ...createMemberCourseTestProgressionEndpoint(builder),
  ...createMemberDownloadedEndpoint(builder),
  ...createMemberJobProgressEndpoint(builder),
  ...createMemberLearnShareEndpoint(builder),
  ...createMemberMessageEndpoint(builder),
  ...createMemberNotifyEndpoint(builder),
  ...createMemberObtainedJobEndpoint(builder),
  ...createMemberOpportunityEndpoint(builder),
  ...createMemberPersonalityTypeEndpoint(builder),
  ...createMemberSavedEndpoint(builder),
  ...createMemberUnitProgressionEndpoint(builder),
  ...createMemberWatchedEndpoint(builder),
  ...createMemberSearchEndpoint(builder),
  ...createPromocodeEndpoint(builder),
});
