import React, {PropsWithChildren} from 'react';
import {
  ImageBackground,
  View,
  Image,
  StyleSheet,
  ImageStyle,
  StyleProp,
} from 'react-native';

import {CustomFastImageProps} from './CustomFastImageProps';
import useOnScreen from '../../Hooks/useOnScreen';
import defaultTheme from '../../Theme/defaultTheme';

const CustomFastImage = (props: PropsWithChildren<CustomFastImageProps>) => {
  const {
    width,
    height,
    children,
    source,
    style,
    wrapperStyles,
    radius,
    isNotSetContainerHeight,
    resizeMode,
    backgroundFallbackColor = defaultTheme.gray,
    aspectRatio,
  } = props;
  const uri = typeof source === 'number' ? '' : source?.uri;
  const flattenStyle = StyleSheet.flatten([style || {}]);
  const [rootRef, isOnScreen] = useOnScreen<HTMLDivElement>(undefined, {
    ignoreReverse: true,
  });
  return (
    <div
      ref={rootRef}
      testid="custom-fast-image-web"
      // @ts-ignore
      style={{
        width: width,
        height: isNotSetContainerHeight ? undefined : height,
        borderRadius: radius || flattenStyle.borderRadius,
        aspectRatio,
        overflow: 'hidden',
        background: backgroundFallbackColor,
        ...(StyleSheet.flatten(wrapperStyles) || {}),
      }}>
      {children !== undefined ? (
        <ImageBackground
          style={[
            {
              width: width,
              height: height,
              borderRadius: radius || flattenStyle.borderRadius,
              borderBottomLeftRadius: flattenStyle.borderBottomLeftRadius,
              borderBottomRightRadius: flattenStyle.borderBottomRightRadius,
              borderTopLeftRadius: flattenStyle.borderTopLeftRadius,
              borderTopRightRadius: flattenStyle.borderTopRightRadius,
              zIndex: 0,
              aspectRatio,
            },
            style,
          ]}
          source={isOnScreen ? {uri} : {}}
          resizeMode={resizeMode}>
          {children}
        </ImageBackground>
      ) : (
        <Image
          style={
            {
              width: width,
              height: height,
              ...flattenStyle,
              borderRadius: radius || flattenStyle.borderRadius,
              borderBottomLeftRadius: flattenStyle.borderBottomLeftRadius,
              borderBottomRightRadius: flattenStyle.borderBottomRightRadius,
              borderTopLeftRadius: flattenStyle.borderTopLeftRadius,
              borderTopRightRadius: flattenStyle.borderTopRightRadius,
              aspectRatio,
            } as StyleProp<ImageStyle>
          }
          source={isOnScreen ? {uri} : {}}
          resizeMode={resizeMode}
        />
      )}
    </div>
  );
};

export default CustomFastImage;
