import {useState, useCallback, useMemo} from 'react';
import {
  BasicMediaCover,
  IMediaCover,
} from '../../Components/Card/CarouselCard/CarouselCard';

const useMediaCover = (cover?: IMediaCover) => {
  const initialCover: BasicMediaCover | undefined =
    cover && cover.type === 'video' && cover.uri ? cover : undefined;
  const [media, setMedia] = useState<BasicMediaCover | undefined>(initialCover);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const isVideo: boolean = !!media && media.type === 'video';
  const fetchMedia = useCallback(async (): Promise<
    undefined | BasicMediaCover
  > => {
    try {
      setLoading(true);
      if (cover && (!fetched || !media)) {
        if (cover.type === 'delay') {
          const response = await cover.request();
          if (response) {
            setMedia(response);
            setFetched(true);
            return response;
          }
        } else {
          setMedia(cover);
          setFetched(true);
          return cover;
        }
      }
    } catch (error) {
      setFetched(false);
    } finally {
      setLoading(false);
    }
  }, [cover]);
  return {
    poster: {
      type: 'image',
      uri: cover?.poster,
    } as BasicMediaCover & {type: 'image'},
    loading,
    media,
    isVideo,
    fetchMedia,
  };
};

export default useMediaCover;
