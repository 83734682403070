import React, {useState} from 'react';
import {View} from 'react-native';
import {
  IChannelFragmentFragment,
  ILiveEventFragmentFragment,
  IVodItemFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {IPaywallSetting} from '~Hooks/usePaywal';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import PlayerPaywallChoosePlan from './PlayerPaywallChoosePlan/PlayerPaywallChoosePlan';
import PlayerPaywallHome from './PlayerPaywallHome/PlayerPaywallHome';

const PlayerPaywall = (props: {
  setting: IPaywallSetting;
  isLive: boolean;
  content:
    | IVodItemFragmentFragment
    | ILiveEventFragmentFragment
    | IChannelFragmentFragment
    | undefined;
}) => {
  const {setting, isLive, content} = props;
  const {currentPaywall} = setting;
  // const navigation = useNavigation();
  const [isChoosePlan, setIsChoosePlan] = useState(false);
  const handleChoosePlan = () => {
    if (content) {
      const {previewDurationTv, previewDurationVod} = setting;
      tracker.goToPaywall({
        id: content.id,
        title: content.title,
        __typename: content.__typename,
        duration: isLive ? previewDurationTv : previewDurationVod,
      });
    }
    setIsChoosePlan(true);
    // navigation.dispatch(
    //   StackActions.replace('Paywall', {
    //     headerTitle: '',
    //     paywall: currentPaywall,
    //   }),
    // );
  };

  if (isChoosePlan) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          width: '100%',
          height: '100%',
          maxWidth: '100%',
          position: 'relative',
        }}>
        <PlayerPaywallChoosePlan paywallId={currentPaywall?.id} />
      </View>
    );
  }

  return (
    <PlayerPaywallHome
      setting={setting}
      isLive={isLive}
      content={content}
      onChoosePlan={handleChoosePlan}
    />
  );
};

export default PlayerPaywall;
