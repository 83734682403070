import {View} from 'react-native';
import React, {ReactNode, useEffect, useMemo} from 'react';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import ProfileMenu, {
  ProfileMenuProps,
} from 'design-system/src/Components/ProfileMenu/ProfileMenu.web';
import useGetTopMenu from '~Umbraco/Hooks/useGetTopMenu';
import {ITabBarHeaderItem} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import usePushUtils from '~Umbraco/Hooks/usePushUtils';
import {IconName} from 'design-system/src/assets/Icones';
import {getLinkPickerUrl} from '~Umbraco/Utils/element/getLinkPickerUrl';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import {useAppDispatch} from '@src/Store/hooks';
import useSelectedRegion from '@src/Store/selectors/useSelectedRegion';
import {useSelectIsMenuDrawerOpen} from '@src/Store/Slices/AppSlice/useAppSliceSelector';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import useAppNavigation from '@src/utils/useAppNavigation';

const MenuDrawerContent = () => {
  const region = useSelectedRegion();
  const navigation = useAppNavigation();
  const {getTopMenu} = useGetTopMenu();
  const {renderPush} = usePushUtils();
  const dispatch = useAppDispatch();
  const tracker = useTracker();
  const isOpen = useSelectIsMenuDrawerOpen();
  const {isMobile} = useBreakpoint();
  useEffect(() => {
    if (isOpen) {
      tracker.general.more();
    }
  }, [tracker, isOpen]);

  //   const {version, build, env}: {version: string; build: string; env: string} =
  //     useMemo(() => {
  //       return {
  //         version: DeviceInfo.getVersion(),
  //         build: DeviceInfo.getBuildNumber(),
  //         env: apiConfig.env !== 'prod' ? `- ${apiConfig.env}` : '',
  //       };
  //     }, []);

  const {
    categoryMenu,
    push,
    mainMenu,
  }: {
    categoryMenu: ITabBarHeaderItem[];
    push: ReactNode;
    mainMenu: ProfileMenuProps[];
  } = useMemo(() => {
    let _categoryMenu: ITabBarHeaderItem[] = [];
    let _mainMenu: ProfileMenuProps[] = [];
    let _push: ReactNode = null;
    if (region) {
      if (region.topMenu) {
        // @ts-ignore
        _categoryMenu = getTopMenu(region.topMenu) || [];
      }
      if (region.subMenu) {
        region.subMenu.forEach(menu => {
          if (menu && menu.content && menu.content.__typename === 'LinkIcon') {
            const {icon, title} = menu.content;
            const {url, __typename, id} = getLinkPickerUrl(menu.content);

            if (title && id && __typename) {
              _mainMenu.push({
                icon: (icon || '').trim().toLowerCase() as IconName,
                title: title,
                onPress: async () => {
                  new RouteFactory(
                    {url, __typename, title, id},
                    navigation,
                    dispatch,
                  ).navigate();
                },
              });
            }
          }
        });
      }
      if (
        region.push &&
        region.push[0] &&
        region.push[0].content.__typename === 'Push'
      ) {
        _push = renderPush(region.push[0].content, {
          containerStyle: {padding: 0},
        });
      }
    }
    return {categoryMenu: _categoryMenu, push: _push, mainMenu: _mainMenu};
  }, [region]);

  return (
    <View
      style={{
        height: '100%',
      }}>
      <div className="io-le-push">{push}</div>
      <View
        style={{
          marginTop: isMobile ? Metrics.verticalScale(8) : 48,
        }}>
        {categoryMenu.map((category, index) => (
          <PressableCustom
            key={`cm-${index}`}
            style={{
              paddingTop: isMobile ? Metrics.verticalScale(13) : 6,
              paddingHorizontal: Metrics.horizontalScale(16),
              paddingBottom: isMobile ? Metrics.verticalScale(14) : 6,
              backgroundColor: isMobile ? '#1B1C21' : 'transparent',
              borderTopLeftRadius: index === 0 ? 4 : 0,
              borderTopRightRadius: index === 0 ? 4 : 0,
              borderBottomLeftRadius: index === categoryMenu.length - 1 ? 4 : 0,
              borderBottomRightRadius:
                index === categoryMenu.length - 1 ? 4 : 0,
              marginBottom: isMobile
                ? index < categoryMenu.length - 1
                  ? Metrics.verticalScale(1)
                  : 0
                : 0,
            }}
            onPress={() => {
              category.onClick && category.onClick();
            }}>
            {({hover}) => {
              return (
                <Typography
                  variant="body2"
                  color={hover ? 'primary' : undefined}>
                  {category.title}
                </Typography>
              );
            }}
          </PressableCustom>
        ))}
      </View>
      <View
        style={{
          marginTop: isMobile ? Metrics.verticalScale(30) : 96,
          paddingBottom: Metrics.verticalScale(32),
          // @ts-ignore
          gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
          display: 'grid',
        }}>
        {mainMenu.map((s, index) => (
          <View
            style={{
              overflow: 'hidden',
            }}
            key={`sm-${index}`}>
            <ProfileMenu {...s} hide={!isMobile} />
          </View>
        ))}
      </View>
    </View>
  );
};

export default MenuDrawerContent;
