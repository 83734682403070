import React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import Typography from '../../../../Components/Typography/Typography';
import Metrics from '../../../../Theme/Metrics';

export interface PayWallTitleProps {
  title?: string;
  description?: string;
  style?: StyleProp<ViewStyle>;
}

const PayWallHeaderTitle = (props: PayWallTitleProps) => {
  const {title, description, style} = props;
  return (
    <View style={[styles.container, style]}>
      <Typography
        children={title}
        variant="h1"
        style={StyleSheet.flatten([
          styles.text,
          description ? styles.title : null,
        ])}
      />
      {description && (
        <Typography
          children={description}
          variant="body2"
          style={styles.text}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    paddingHorizontal: Metrics.horizontalScale(37),
  },
  title: {
    marginBottom: Metrics.verticalScale(11),
  },
  text: {
    textAlign: 'center',
  },
});

export default PayWallHeaderTitle;
