import {PageProps} from 'gatsby';
import React, {PropsWithChildren, useEffect} from 'react';
import {PageContextI18n, PageContextProvider} from './PageContext';
import {useDispatch} from 'react-redux';
import pageSlice from '@src/Store/Slices/PageSlice';

type WrapPageElementProps = PropsWithChildren<PageProps<any, PageContextI18n>>;

const WrapPageElement = (props: WrapPageElementProps) => {
  const {children, ...rest} = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageSlice.actions.setCurrentPageContext(rest.pageContext));
  }, [dispatch, rest]);

  return <PageContextProvider value={rest}>{children}</PageContextProvider>;
};

export default WrapPageElement;
