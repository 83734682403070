import { StyleSheet } from "react-native";
import Metrics from "../../../Theme/Metrics";

export const summaryCardStyles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingHorizontal: Metrics.horizontalScale(16)
    }, 
    text: {
        textAlign: 'center'
    },
    title: {
        margin: Metrics.verticalScale(28),
        marginBottom: Metrics.verticalScale(6)
    }
})