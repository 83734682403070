import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const controlPlayer = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  playButton: {
    marginHorizontal: Metrics.horizontalScale(32),
  },
  next: {
    margin: -13,
    marginRight: Metrics.horizontalScale(37) - 13,
    padding: 13,
  },
  prev: {
    margin: -13,
    marginLeft: Metrics.horizontalScale(37) - 13,
    padding: 13,
  },
});
