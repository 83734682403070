import * as React from 'react';
import {graphql, HeadFC, PageProps} from 'gatsby';
import HomePage from '@src/Umbraco/Pages/HomePage/HomePage';

import PageHead from '@src/components/general/PageHead/PageHead';

const LandingPageTemplate = (
  props: PageProps<Queries.LandingPageTemplateQuery>,
) => {
  const {data} = props;

  return <HomePage item={data?.umbraco.landingPage ?? null} />;
};

LandingPageTemplate.layoutComponent = 'DefaultLayout';

export const Head: HeadFC<Queries.AwardHomePageQuery, any> = headProps => {
  return <PageHead {...headProps} />;
};

export const query = graphql`
  query LandingPageTemplate($id: ID!, $language: String!, $lang: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    umbraco {
      landingPage(id: $id, culture: $lang) {
        id
        __typename
        contentTypeAlias
        ...LandingPageFragment
      }
    }
  }
`;

export default LandingPageTemplate;
