import React, {ReactNode, useEffect, useRef, useState} from 'react';
import Siema, {SiemaOptions} from 'siema';
import {
  _CarouselHeaderWrapper,
  _CarouselWrapper,
  _CarouselRoot,
} from './Carousel.styled';
import useBreakpoint from '../Grid/hooks/useBreakpoint';
import Point from './Point/Point';
import FlexBox from '../FlexBox/FlexBox.web';
import PressableCustom from '../../Components/PressableCustom/PressableCustom';
import Icon from '../../Components/Icon/Icon';
import defaultTheme from '../../Theme/defaultTheme';

declare global {
  const window: any;
}

export type INavigationPosition = 'top' | 'bottom' | 'left' | 'right';

export interface ICarouselProps<Data>
  extends Omit<SiemaOptions, 'onChange' | 'onInit'> {
  style?: React.CSSProperties;
  title?: ReactNode;
  items: Array<Data>;
  gap?: number;
  hideHeader?: boolean;
  autoLoop?: boolean;
  loopTime?: number;
  render: (
    data: Data,
    actions: {next(): void; prev(): void; index: number},
  ) => React.ReactNode;
  itemKey?: (data: Data, index: number) => string | number;
  navigation?: boolean;
  navigationPosition?: INavigationPosition;
  innerPadding?: boolean;
  onSnapToItem?: (index: number) => void;
  mode?: 'parallax';
}

const resolveSlidesNumber = (
  perPage: SiemaOptions['perPage'],
  total: number,
): number => {
  // Fix SSR
  if (typeof window === 'undefined') return 1;

  if (typeof perPage === 'number') {
    return total / perPage;
  } else if (typeof perPage === 'object') {
    let pp = 1;
    for (let viewport in perPage) {
      if (window.innerWidth >= parseInt(viewport)) {
        pp = perPage[viewport];
      }
    }

    return total / pp;
  }
  return 1;
};

const Carousel = <T extends object>(props: ICarouselProps<T>) => {
  const {
    title,
    items,
    render,
    itemKey,
    startIndex = 0,
    perPage = 1,
    gap,
    hideHeader,
    autoLoop = false,
    loopTime = 5000,
    navigation = true,
    navigationPosition = 'top',
    innerPadding,
    draggable = true,
    onSnapToItem,
    mode,
    style,
    ...sliderOptions
  } = props;

  const carousel = useRef<Siema | null>(null);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(startIndex);
  const [slideCount, setSlideCount] = useState<number>(
    resolveSlidesNumber(perPage, items.length),
  );
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseMove, setMouseMove] = useState(false);
  const [sliderInitialized, setSliderInitialized] = useState<boolean>(false);
  // const canMoveNext: boolean =
  //   slideCount > 1 && !!(currentSlide < Math.ceil(items.length / slideCount));
  // const canMovePrev: boolean = !!(currentSlide > 0);

  const {isMobile} = useBreakpoint();
  const navigationPositionValue = () => {
    if (navigationPosition == 'left' || navigationPosition == 'right') {
      if (isMobile) {
        return 16;
      } else {
        return 60;
      }
    } else if (navigationPosition == 'bottom') {
      return 18;
    } else {
      return 10;
    }
  };

  const onChange = () => {
    if (carousel.current) {
      const _slideIndex = carousel.current.currentSlide || 0;
      setCurrentSlide(_slideIndex);
      onSnapToItem?.(_slideIndex);
    }
  };

  const loop = () => {
    handleNext();
  };

  useEffect(() => {
    setSlideCount(resolveSlidesNumber(perPage, items.length));

    if (autoLoop) {
      if (intervalId.current) {
        clearTimeout(intervalId.current);
      }
      intervalId.current = setTimeout(loop, loopTime);
    }

    setTimeout(() => {
      if (container.current && items.length > 0 && !carousel.current) {
        carousel.current = new Siema({
          selector: container.current,
          perPage,
          onChange,
          onInit: () => {
            onChange && onChange();
            setSliderInitialized(true);
          },
          // draggable:
          //   "touchstart" in document.documentElement ||
          //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          //     navigator.userAgent
          //   ),
          draggable: draggable,
          multipleDrag: false,
          duration: 400,
          easing: 'ease-out',
          startIndex: 0,
          threshold: 20,
          loop: autoLoop,
          rtl: false,
          ...sliderOptions,
        });
      }
    }, 300);

    function onResize() {
      setSlideCount(resolveSlidesNumber(perPage, items.length));
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      if (carousel.current) {
        carousel.current.destroy(true);
        carousel.current = null;
        setSliderInitialized(false);
      }
      if (intervalId) clearInterval(intervalId);
    };
  }, [perPage, autoLoop, loopTime, items]);

  // -------------------- handlers --------------------------------
  const handleNext = () => {
    if (carousel.current) {
      carousel.current.next();
    }
    if (autoLoop) {
      if (intervalId.current) {
        clearTimeout(intervalId.current);
      }
      intervalId.current = setTimeout(loop, loopTime);
    }
  };
  const handlePrev = () => {
    if (carousel.current) {
      carousel.current.prev();
    }
    if (autoLoop) {
      if (intervalId.current) {
        clearTimeout(intervalId.current);
      }
      intervalId.current = setTimeout(loop, loopTime);
    }
  };

  const canMoveNext: boolean =
    slideCount > 1 && !!(currentSlide < Math.ceil(items.length / slideCount));
  const canMovePrev: boolean = !!(currentSlide > 0);

  const renderNavigation = (
    <>
      {(canMovePrev || canMoveNext) && !isMobile && (
        <FlexBox gap={8} style={{marginLeft: 'auto'}}>
          <PressableCustom
            style={{
              backgroundColor: 'rgba(20,20,20, 0.5)',
              width: 40,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              left: 0,
              zIndex: 50,
              paddingBottom: 10,
              paddingTop: 10,
              top: '50%',
              transform: [{translateY: '-50%'}],
            }}
            onPress={handlePrev}
            testID="app-carousel-previous-button">
            {({hover}) => (
              <Icon
                name="arrow-left"
                style={hover ? {transform: [{scale: 1.2}]} : undefined}
                color={hover ? defaultTheme.primary : undefined}
              />
            )}
          </PressableCustom>
          <PressableCustom
            style={{
              backgroundColor: 'rgba(20,20,20, 0.5)',
              width: 40,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              right: 0,
              zIndex: 50,
              paddingBottom: 10,
              paddingTop: 10,
              top: '50%',
              transform: [{translateY: '-50%'}],
            }}
            onPress={handleNext}
            testID="app-carousel-next-button">
            {({hover}) => (
              <Icon
                name="arrow-right"
                style={hover ? {transform: [{scale: 1.2}]} : undefined}
                color={hover ? defaultTheme.primary : undefined}
              />
            )}
          </PressableCustom>
        </FlexBox>
      )}
    </>
  );

  const isHorizontal =
    navigationPosition === 'left' || navigationPosition == 'right';

  return (
    <_CarouselRoot
      className={'carouse-web-root'}
      style={{
        ...style,
        paddingBottom: !isHorizontal ? '40px' : undefined,
      }}>
      {/* {!hideHeader && (
        <_CarouselHeaderWrapper
          align="baseline"
          className="carousel-header-wrapper"
          $innerPadding={innerPadding}>
          {title && <Typography mb={18}>{title}</Typography>}
          {navigation && navigationPosition === 'top' ? renderNavigation : null}
        </_CarouselHeaderWrapper>
      )} */}
      <_CarouselWrapper
        ref={container}
        $gap={gap}
        $sliderInitialized={sliderInitialized}
        $totalPage={slideCount}
        $perPage={perPage}
        className="carousel-wrapper"
        onMouseMove={() => {
          if (mouseDown) {
            if (!mouseMove) {
              setMouseMove(true);
            }
          }
        }}
        onMouseDownCapture={() => {
          setMouseDown(true);
        }}
        onMouseUpCapture={() => {
          setMouseDown(false);
          if (mouseMove) {
            setTimeout(() => {
              setMouseMove(false);
            }, 200);
          }
        }}>
        {items?.map((item, index) => {
          return (
            <div
              key={itemKey ? itemKey(item, index) : index}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className="carousel-item"
              style={{
                position: 'relative',
              }}>
              {render(item, {next: handleNext, prev: handlePrev, index})}

              {/* <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  background: 'transparent',
                  zIndex: mouseMove ? 3 : -20,
                }}
                onClick={e => {
                  if (mouseMove) {
                    e.stopPropagation();
                  }
                }}
              /> */}
            </div>
          );
        })}
      </_CarouselWrapper>
      {navigation && navigationPosition === 'bottom' ? (
        // <FlexBox
        //   justify={'flex-end'}
        //   style={{
        //     marginTop: 16,
        //     display: 'flex',
        //     position: 'absolute',
        //     bottom: 10,
        //     left: 0,
        //     right: 0,
        //   }}>
        <>{renderNavigation}</>
      ) : // </FlexBox>
      null}

      {navigation && (
        <div
          style={{
            position: 'absolute',
            zIndex: 9,
            [navigationPosition]: navigationPositionValue(),
            display: 'inline-flex',
            width: isHorizontal ? undefined : '100%',
            height: !isHorizontal ? undefined : '100%',
            justifyContent: 'center',
            flexDirection: isHorizontal ? 'column' : 'row',
            gap: 26,
            top: isHorizontal ? 0 : undefined,
          }}>
          {Array.from({length: slideCount}).map((_, i) => {
            return (
              <Point
                key={i}
                isActive={i === currentSlide}
                onClick={() => {
                  if (carousel.current) {
                    carousel.current.goTo(i);
                  }
                }}
              />
            );
          })}
        </div>
      )}
    </_CarouselRoot>
  );
};

export default Carousel;
