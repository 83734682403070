import React from 'react';
import {View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import Typography from '../Typography/Typography';
// import Accordion from '../Accordion/Accordion';

export interface IServiceFaItem {
  title: string;
  description: string;
}

export type ServiceFaqProps = {
  title: string;
  data: Array<IServiceFaItem>
};


export default function ServiceFaq(props: ServiceFaqProps) {
  const {title, data} = props;

  return (
    <View
      style={{
        paddingLeft: Metrics.horizontalScale(16),
        paddingRight: Metrics.horizontalScale(16),
        // marginTop: Metrics.verticalScale(48),
        marginBottom: Metrics.verticalScale(19),
      }}>
      <Typography variant="h3" style={{marginBottom: 15, marginTop: 10}}>
        {title}
      </Typography>
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: 'rgba(115, 115, 115, 0.5)',
        }}>
          {/* TODO: accordion */}
        {/* {data.map((d, index) => (
          <Accordion {...d} key={`sfq-${index}`} />
        ))} */}
      </View>
    </View>
  );
}
