import React from 'react';
import {View} from 'react-native';
import Button from '../../../../Components/Button/Button';
import {HomeLiveCard} from '../../../../Components/Card';
import {LiveCardProps} from '../../../../Components/Card/LiveCard/LiveCardProps';
import defaultTheme from '../../../../Theme/defaultTheme';
import Metrics from '../../../../Theme/Metrics';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';

export interface LiveEventCardProps extends LiveCardProps {
  onPressButton: () => void;
  isNofified?: boolean;
  isLive?: boolean;
  notifiedLabel?: string;
  toNotifyLabel?: string;
  liveLabel?: string;
}

const LiveEventCard = (props: LiveEventCardProps) => {
  const {
    title,
    releaseDate,
    startDate,
    endDate,
    description,
    onPressButton,
    cover,
    isNofified,
    isLive,
    notifiedLabel,
    toNotifyLabel,
    liveLabel = 'Watch Now',
  } = props;

  const buttonNotified = isNofified
    ? notifiedLabel || 'NOTIFIED'
    : toNotifyLabel || 'Notify me';
  const buttonTitle = isLive ? liveLabel : buttonNotified;

  const {isMobile} = useBreakpoint();

  return (
    <View>
      <HomeLiveCard
        cover={cover}
        title={title}
        releaseDate={releaseDate}
        startDate={startDate}
        endDate={endDate}
        description={description}
        descriptionColor={'white'}
        type={isMobile ? 'big' : 'xbig'}
      />
      <Button
        title={buttonTitle}
        onPress={onPressButton}
        style={{
          marginTop: Metrics.verticalScale(19),
          borderColor: isNofified && !isLive ? 'white' : defaultTheme.primary,
        }}
        size="xsmall"
        icon={isNofified && !isLive ? 'check' : undefined}
        buttonColor={isNofified && !isLive ? 'white' : undefined}
      />
    </View>
  );
};

export default LiveEventCard;
