import React from 'react';
import {View, Pressable, StyleProp, ViewStyle, StyleSheet} from 'react-native';
import {IconName} from '../../assets/Icones';
import Metrics from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import PressableCustom from '../PressableCustom/PressableCustom';
import defaultTheme from '../../Theme/defaultTheme';

export type ProfileMenuProps = {
  title: string;
  icon: IconName;
  hide?: boolean;
  disabled?: boolean;
  onPress?(): void;
  customStyle?: StyleProp<ViewStyle>;
};

export default function ProfileMenu(props: ProfileMenuProps) {
  const {title, icon, onPress, hide, disabled, customStyle} = props;

  return (
    <PressableCustom
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: !hide ? 'space-between' : undefined,
          paddingVertical: Metrics.verticalScale(12),
          opacity: disabled ? 0.5 : 1,
        },
        customStyle,
      ]}
      disabled={disabled}
      onPress={onPress}>
      {({hover}) => {
        const hoverColor = hover ? defaultTheme.primary : undefined;
        return (
          <>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Icon name={icon} color={hoverColor} />
              <Typography
                numberOfLines={2}
                style={{
                  marginLeft: Metrics.horizontalScale(24),
                }}
                variant="body2"
                color={hoverColor ? () => hoverColor : undefined}>
                {title}
              </Typography>
            </View>
            {!hide && <Icon name="arrow-right" size="16" />}
          </>
        );
      }}
    </PressableCustom>
  );
}
