/* eslint-disable react-native/no-inline-styles */
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import VideoPlayerVertical from 'design-system/src/Widget/Player/VideoPlayer/VideoPlayerVertical.web';
import {darkTheme} from 'design-system/src/styles/Themes';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useTracker, {
  IPlayerPropertyName,
} from '@src/Services/Amplitude/hooks/useTracker';
import useUpdateBookmarks from '@src/Hooks/useUpdateBookmarks';
import useMember from '@src/Store/selectors/useMember';
import {useRegions} from '@src/Store/selectors/useRegions';
import {useNewsByUrlEndUrlLazyQuery} from '@src/Api/Graphql/gql/types.generated';
import {useLazyGetVimeoVideoQuery} from '@src/Api/TraceApi/traceApi';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useBookmarkCount from '@src/Hooks/useBookmarkCount';
import checkVimeoUrl from '@src/Umbraco/Utils/CheckVimeoUrl';
import DateFactory from '@src/tools/DateFactory';
import DiscoverActionButton from 'design-system/src/Widget/Discover/DiscoverActionButton/DiscoverActionButton';
import {RouteComponentProps} from '@reach/router';
import {OnLoadData} from 'react-native-video';
import PaywallChecker from '@src/Umbraco/Components/PaywallChecker/PaywallChecker';
import useShareContent from '@src/Hooks/useShareContent';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';
import useViewPort from 'design-system/src/Hooks/useViewPort';
import useParams from '@src/Hooks/useParams';

const AppVideoPlayerVerticale: FC<
  RouteComponentProps<{id: string}>
> = props => {
  const {endUrl} = useParams();
  const tracker = useTracker();
  const {t} = useTranslation('translation');

  const shareContent = useShareContent();
  const [isOpenDialogShare, setIsOpenDialogShare] = useState(false);
  const [isLoadingVideoUri, setIsLoadingVideoUri] = useState(true);

  const {isBookmarked, updateBookMark} = useUpdateBookmarks();
  const member = useMember();
  const {findRegionsByDocumentUrl} = useRegions();
  const [fetchNews, {data, loading}] = useNewsByUrlEndUrlLazyQuery();
  const [fetchVimeoVideo, {isLoading: loadingVideo}] =
    useLazyGetVimeoVideoQuery();
  const [videoUrl, setVideoUrl] = useState<string>();
  const {fetchContentLike, bookmarkCount = {}} = useBookmarkCount();
  const [loadingComponent, setLoadingComponent] = useState(true);
  const {viewportHeight} = useViewPort();

  const [originalInfo, setOriginalInfo] = useState<
    | {
        originalLanguage?: string;
        originalQuality?: string;
        contentTimestamp: Date;
        loadTime?: number;
      }
    | undefined
  >();

  const refCurrentTime = useRef<number>(0);
  const refCurrentDuration = useRef<number>(0);
  const news = data?.allNews?.items[0];
  const id = news?.id;

  useEffect(() => {
    if (endUrl) {
      setLoadingComponent(false);
      fetchNews({
        variables: {
          endUrl: endUrl,
        },
      });
    }
  }, [endUrl]);

  useEffect(() => {
    if (id && !bookmarkCount[id]) {
      fetchContentLike({
        contentId: id,
      });
    }
  }, [id, bookmarkCount]);

  const count: number = news && news.id ? bookmarkCount[news.id] : 0;

  useEffect(() => {
    setIsLoadingVideoUri(true);
    if (news && news.vimeoId) {
      if (news.vimeoHlsUrl && checkVimeoUrl(news.vimeoHlsUrl)) {
        if (videoUrl !== news.vimeoHlsUrl) {
          setVideoUrl(news.vimeoHlsUrl);
        }
        setIsLoadingVideoUri(false);
      } else {
        fetchVimeoVideo(news.vimeoId + '')
          .then(({data: videoData}) => {
            if (videoData && videoData.hls && videoUrl !== videoData.hls) {
              setVideoUrl(videoData.hls);
            }
          })
          .finally(() => {
            setIsLoadingVideoUri(false);
          });
      }
    } else {
      setIsLoadingVideoUri(false);
    }

    if (news) {
      tracker.general.contentDetailPageViewed({
        content_id: news.id,
        content_title: news.title || '',
        content_type: news.__typename,
        genre: news.parent?.name || '',
        // TODO : source
        source: 'browse',
      });
    }
  }, [fetchVimeoVideo, news, tracker.general, videoUrl]);

  useEffect(() => {
    if (news && originalInfo) {
      tracker.player.started(getContentProperties());
      if (originalInfo.loadTime) {
        tracker.player.loadTime({
          ...getContentProperties(),
          duration: originalInfo.loadTime,
        });
      }
    }
  }, [news, originalInfo, tracker.player]);

  useEffect(() => {
    return () => {
      trackEnd();
    };
  });

  const isLiked = news && news.id ? isBookmarked(news.id) : false;

  const getContentProperties = useCallback((): IPlayerPropertyName => {
    const content = news;
    if (content) {
      const {parent, interests, musicGenre} = content;
      const properties: IPlayerPropertyName = {
        id: news.id,
        title: news.title ?? '',
        typename: news.__typename,
        category:
          parent && parent.__typename === 'NewsCateg' && parent.name
            ? parent.name
            : '',
        length: '',
        contentRegion:
          (member &&
            member.region &&
            findRegionsByDocumentUrl(member.region)?.name) ||
          '',
        interestName:
          interests && interests[0] && interests[0].__typename === 'ContentTag'
            ? interests[0].name
            : '',
        contentTimestamp: originalInfo?.contentTimestamp,
        //TO DO
        // source: (params.source as string) || '',
        originalLanguage: originalInfo?.originalLanguage,
        originalQuality: originalInfo?.originalQuality,
        musicGenre: musicGenre
          ? musicGenre.map((e: any) => e?.name).join(',')
          : '',
        loadTime: originalInfo?.loadTime || 0,
        restrictedCountries:
          (content as any).countriesRestriction &&
          Array.isArray((content as any).countriesRestriction)
            ? (content as any).countriesRestriction.map((v: any) => {
                const [country] = (v || '').split('|');
                return country ? country.trim() : '';
              })
            : '',
      };

      return properties;
    }

    return {
      id: '',
      title: '',
      typename: '',
      musicGenre: '',
      length: '',
      category: '',
      loadTime: 0,
      contentRegion: '',
    };
  }, [originalInfo, news]);

  const trackEnd = useCallback(() => {
    if (news) {
      tracker.player.ended({
        ...getContentProperties(),
        watched: Math.round(
          refCurrentDuration.current
            ? (refCurrentTime.current / refCurrentDuration.current) * 100
            : 0,
        ),
      });
    }
  }, [news, tracker.player]);

  const {isMobile} = useBreakpoint();

  const handleOnVideoLoad = useCallback(
    (_data: OnLoadData, originalQuality?: number, loadTime?: number) => {
      let currentText: string | undefined;
      _data.textTracks.forEach(textTrack => {
        // @ts-ignore
        if (textTrack && textTrack.selected) {
          currentText = textTrack.language;
        }
      });
      _data.audioTracks.forEach(track => {
        // @ts-ignore
        if (track.selected) {
          currentText = track.language;
        }
      });
      setOriginalInfo({
        originalLanguage: currentText || '',
        originalQuality: originalQuality ? originalQuality + '' : '',
        contentTimestamp: new Date(),
        loadTime,
      });
    },
    [],
  );

  const handleOnTimeProgress = useCallback((_currentTime: number) => {
    refCurrentTime.current = _currentTime;
  }, []);

  if (loadingComponent || loading || loadingVideo || isLoadingVideoUri) {
    return (
      <div
        style={{
          width: '100%',
          height: viewportHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CustomActivityIndicator />
      </div>
    );
  }

  if (
    !videoUrl &&
    !(loading || loadingVideo || loadingComponent || isLoadingVideoUri)
  ) {
    return (
      <div
        style={{
          width: '100%',
          height: viewportHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography variant="h2">{t('No video found')}</Typography>
      </div>
    );
  }

  return (
    <PaywallChecker accessType={news?.accessType} paywall={news?.paywall}>
      <div
        style={{
          backgroundColor: darkTheme.colors.backgroundBlack,
          width: isMobile ? '100vw' : '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundImage: isMobile
            ? undefined
            : `url(${news?.thumbnail?.url || ''})`,
          backgroundSize: 'cover',
          position: isMobile ? 'fixed' : 'relative',
          minHeight: '100%',
          height: viewportHeight,
          zIndex: 1000,
        }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backdropFilter: 'blur(40px)',
            background:
              'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 15.54%, rgba(17, 18, 22, 0.80) 57.45%, #111216 74.32%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // ...(isMobile ? mobileStyle : {}),
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: isMobile ? '100%' : 430,
              height: '100%',
              padding: !isMobile ? '52px 0' : 0,
            }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}>
              <VideoPlayerVertical
                source={{
                  uri: videoUrl || '',
                }}
                title={news?.title || ''}
                borderRadius={isMobile ? 0 : 8}
                poster={news?.thumbnail?.url || ''}
                onVideoLoad={handleOnVideoLoad}
                onTimeProgress={handleOnTimeProgress}
                // onGoBack={() => {
                //   goBack();
                // }}
              />
            </div>
            <div
              style={{
                marginTop: 12,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                position: isMobile ? 'absolute' : 'relative',
                left: 0,
                bottom: isMobile ? '60px' : 0,
                padding: isMobile ? '0 16px' : 0,
                alignItems: isMobile ? 'flex-end' : 'flex-start',
              }}>
              {/* left */}
              <div>
                <Typography variant="h2">{news?.title || ''}</Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 12,
                    gap: 12,
                  }}>
                  <Typography
                    variant="ui3"
                    color={() => darkTheme.colors.fontGray}>
                    {(news?.author &&
                      news?.author[0] &&
                      news?.author[0].content &&
                      news?.author[0].content.__typename === 'Author' &&
                      news?.author[0].content.authorName) ||
                      t('Unknown')}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      gap: 6,
                      alignItems: 'center',
                    }}>
                    <Icon name="desktop" size="24" color="white" />
                    <Typography
                      variant="ui3"
                      color={() => darkTheme.colors.fontGray}>
                      {new DateFactory(news?.pubDate).format()}
                    </Typography>
                  </div>
                </div>
              </div>
              {/* right */}
              <div
                style={{
                  display: 'flex',
                  gap: 18,
                  flexDirection: isMobile ? 'column' : 'row',
                  height: '100%',
                }}>
                <DiscoverActionButton
                  style={{marginBottom: 20}}
                  icon={{name: isLiked ? 'favoris-fill' : 'favoris'}}
                  isLikeType={true}
                  isLiked={isLiked}
                  onPressFav={value => {
                    news?.id &&
                      news.url &&
                      updateBookMark(news?.id, news?.url, value, {
                        id: news?.id,
                        url: news?.url,
                        __typename: news?.__typename,
                        name: news?.title,
                        categoryName: news.parent?.name,
                        raw: news,
                      });
                  }}
                  count={count}
                />
                <DiscoverActionButton
                  icon={{name: 'share'}}
                  onPress={() => {
                    news &&
                      news.url &&
                      shareContent.share({
                        __typename: news.__typename,
                        url: news.url,
                        message: news.title,
                        id: news.id,
                        category: news.parent?.name,
                        cover:
                          news.thumbnail && news.thumbnail.url
                            ? news.thumbnail.url
                            : '',
                        description: news.shortDescription || '',
                      });
                    setIsOpenDialogShare(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ShareDialog
          isOpen={isOpenDialogShare}
          onClose={() => setIsOpenDialogShare(false)}
        />
      </div>
    </PaywallChecker>
  );
};

export default AppVideoPlayerVerticale;
