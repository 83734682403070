/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {StyleSheet, View} from 'react-native';
import Metrics from '../../../../../Theme/Metrics';
import CustomFastImage from '../../../../CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../../../../CustomFastImage/CustomFastImageProps';
import Typography from '../../../../Typography/Typography';
import {leadingCoverSize, TopTenCardStyles} from './TopTenCard.styles';
import PremiumIcon from '../../../PremiumIcon';

export interface TopTenCardProps {
  number: string;
  source: CustomFastImageProps['source'];
  size?: keyof typeof leadingCoverSize;
  dist?: number;
  premiumText?: string;
  forceWidth?: string | number;
  forceHeight?: string | number;
  isHover?: boolean;
}

const TopTenCard = (props: TopTenCardProps) => {
  const {
    number,
    source,
    size = 'large',
    dist,
    premiumText,
    forceWidth,
    forceHeight,
    isHover,
  } = props;
  const sizeStyles = leadingCoverSize[size];
  const width = forceWidth ?? sizeStyles.width;
  const height = forceHeight ?? sizeStyles.height;

  const fontSize =
    typeof width === 'number' ? width * 0.6 : sizeStyles.fontSize;
  return (
    <>
      {/* {isHover && (
        <View style={TopTenCardStyles.card}>
          <View style={{width: width}} />
          <View style={{width: width}} />
        </View>
      )} */}
      <View style={StyleSheet.flatten([{height}, TopTenCardStyles.card])}>
        <View
          style={{
            position: 'relative',
            zIndex: 3,
            justifyContent: 'flex-start',
            flex: 1,
            paddingLeft: Metrics.horizontalScale(6),
          }}>
          <Typography
            children={number}
            style={{
              fontSize: fontSize,
              lineHeight: fontSize,
              // TODO: find a better way to fix text vertical align bottom
              marginBottom: Metrics.verticalScale(-22),
            }}
            color="white"
          />
        </View>
        <View
          style={{
            width,
            height,
            position: 'relative',
          }}>
          <CustomFastImage
            source={source}
            wrapperStyles={{marginLeft: dist, position: 'absolute', right: 0}}
            width={width}
            height={height}
            style={StyleSheet.flatten([
              {width, height},
              TopTenCardStyles.cover,
            ])}
          />
          {premiumText && (
            <View
              style={{
                width,
                height,
                position: 'absolute',
              }}>
              <View
                style={{
                  position: 'absolute',
                  bottom: Metrics.horizontalScale(9),
                  right: Metrics.horizontalScale(9),
                }}>
                <PremiumIcon />
              </View>
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default TopTenCard;
