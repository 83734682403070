import {
  ILinkIconFragmentFragment,
  IPushFragmentFragment,
  IRegionWebFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import useMember from './useMember';
import {findRegionsByDocumentUrl, useRegions} from './useRegions';
import {RouteTypename} from '~Navigation/type';
import store from '..';
import {useMemo} from 'react';

export interface IPageData {
  id: string;
  __typename: RouteTypename;
  url?: string;
}

interface IRegionSubPage {
  home?: IPageData;
  watch?: IPageData & {
    children: {
      vod?: IPageData;
      liveTv?: IPageData;
      liveEvent?: IPageData;
    };
  };
  listen?: IPageData & {
    children: {
      radio?: IPageData;
      podcast?: IPageData;
    };
  };
  learn?: IPageData;
  artist?: IPageData;
  searchTopBlocks?: ILinkIconFragmentFragment[];
  searchPageBlocks?: any;
  push?: IPushFragmentFragment;
}

export const getActiveRegionPage = (): IRegionSubPage => {
  const {
    member: {member},
    region: {regions},
  } = store.getState();
  const activeRegion =
    member && member.region && findRegionsByDocumentUrl(member.region, regions);
  const currentRegion = activeRegion || regions[0];

  const page: IRegionSubPage = {};

  if (currentRegion && currentRegion.__typename === 'Region') {
    const {
      homepageUrl,
      webArtistHome,
      watchHomepage,
      listenHomepage,
      learnHomepage,
      searchTopBlocks,
      searchPageBlocks,
      push,
    } = currentRegion;
    if (
      push &&
      push[0] &&
      push[0].content &&
      push[0].content.__typename === 'Push'
    ) {
      page.push = push[0].content;
    }
    if (homepageUrl) {
      page.home = {
        id: homepageUrl.id,
        __typename: homepageUrl.__typename,
        url: homepageUrl.url || '',
      };
    }
    if (webArtistHome) {
      page.artist = {
        id: webArtistHome.id,
        __typename: webArtistHome.__typename,
        url: webArtistHome.url || '',
      };
    }
    if (
      watchHomepage &&
      watchHomepage.children &&
      watchHomepage.children.items
    ) {
      const children: NonNullable<IRegionSubPage['watch']>['children'] = {};
      watchHomepage.children.items.forEach(item => {
        if (item) {
          if (item.__typename === 'SectionPage') {
            children.vod = {
              id: item.id,
              __typename: item.__typename,
              url: item.url,
            };
          } else if (item.__typename === 'LiveTVPage') {
            children.liveTv = {
              id: item.id,
              __typename: item.__typename,
              url: item.url,
            };
          } else if (item.__typename === 'LiveEventsPage') {
            children.liveEvent = {
              id: item.id,
              __typename: item.__typename,
              url: item.url,
            };
          }
        }
      });
      page.watch = {
        id: watchHomepage.id,
        __typename: watchHomepage.__typename,
        url: watchHomepage.url,
        children,
      };
    }
    if (
      listenHomepage &&
      listenHomepage.children &&
      listenHomepage.children.items
    ) {
      const children: IPageData[] = [];
      listenHomepage.children.items.forEach(item => {
        if (item) {
          if ((item.url || '').indexOf('radio') >= 0) {
            children[0] = {
              id: item.id,
              __typename: item.__typename,
              url: item.url,
            };
          } else {
            children[1] = {
              id: item.id,
              __typename: item.__typename,
              url: item.url,
            };
          }
        }
      });
      page.listen = {
        id: listenHomepage.id,
        __typename: listenHomepage.__typename,
        url: listenHomepage.url,
        children: {
          radio: children[0],
          podcast: children[1],
        },
      };
    }
    if (learnHomepage) {
      page.learn = {
        id: learnHomepage.id,
        __typename: learnHomepage.__typename,
        url: learnHomepage.url,
      };
    }
    if (searchTopBlocks && searchTopBlocks.length > 0) {
      let data: ILinkIconFragmentFragment[] = [];
      searchTopBlocks.forEach(item => {
        if (item && item.content.__typename === 'LinkIcon') {
          let value = item.content as ILinkIconFragmentFragment;
          data.push(value);
        }
      });
      page.searchTopBlocks = data;
    }
    if (searchPageBlocks) {
      page.searchPageBlocks = searchPageBlocks;
    }
  }

  return page;
};

export const useRegionUrl = (): {
  regionUrl: string | undefined;
  currentRegion?: IRegionWebFragmentFragment;
  page: IRegionSubPage;
  loading: boolean;
} => {
  const member = useMember();
  const {regions} = useRegions();
  const activeRegion =
    member && member.region && findRegionsByDocumentUrl(member.region, regions);
  const currentRegion = activeRegion || regions[0];

  const page: IRegionSubPage = useMemo(
    () => getActiveRegionPage(),
    [currentRegion],
  );

  return {
    regionUrl: currentRegion ? currentRegion.url : undefined,
    currentRegion,
    page,
    loading: !(page && currentRegion),
  };
};
