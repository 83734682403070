import BlockSlider, {
  IBlockSliderItem,
  IBlockSliderProps,
} from 'design-system/src/Components/Card/HighlightedCarousel/BlockSlider/BlockSlider.web';
import React, {useMemo, useEffect} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {
  IMainContentListFragmentFragment,
  Maybe,
  useGetContentByIdLazyQuery,
  useGetContentByIdQuery,
} from '~Api/Graphql/gql/types.generated';
import {RouteTypename} from '~Navigation/type';
import {IAccessTypParam, IPaywallParam} from '../PaywallChecker/PaywallChecker';
import {useCategoryChildrenByIdQuery} from '~/Api/Graphql/gql/types.generated';
import {useFindProgression} from '~Hooks/useFindProgression';
import {useLiveEventProgression} from '~Hooks/useLiveEventProgression';
import {mapCategoryChildrenItems} from '~Umbraco/Utils/content/mapCategoryChildrenItems';
import usePaywal from '~Hooks/usePaywal';
import {CustomFastImageProps} from 'design-system/src/Components/CustomFastImage/CustomFastImageProps';
import {mapLiveEventsPage} from '~Umbraco/Utils/pages/mapLiveEventsPage';
import {mapLiveTVPage} from '~Umbraco/Utils/pages/mapLiveTVPage';
import CarouselLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/CarouselLoader';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
import {TagsProps} from 'design-system/src/Components/Tags/Tags';

export interface ICategorySliderItem extends IBlockSliderItem {
  url: string;
  id: string;
  __typename: RouteTypename;
  liveEventId?: string | number;
  sourceUrl?: string;
  vimeoHlsUrl?: string;
  accessData: {
    accessType?: IAccessTypParam;
    paywall?: IPaywallParam;
  };
  raw?: IMainContentListFragmentFragment;
  countriesRestriction?: Maybe<string>[];
  bubble?: CustomFastImageProps['source'];
  duration?: string;
  tag?: TagsProps;
  partenerLogo?: string;
}
export interface ICategorySliderProps
  extends Omit<IBlockSliderProps<ICategorySliderItem>, 'premiumText'> {
  id?: string;
  category?: {
    name: string;
    url: string;
    id: string;
    __typename: RouteTypename;
  };
}

const CategorySlider = (props: ICategorySliderProps) => {
  const {
    isLink,
    onSeeMore,
    seeMore,
    category,
    size,
    data: __oldData,
    id,
    ...rest
  } = props;
  const {t} = useTranslation('translation');
  const {findProgression} = useFindProgression();
  const {calculateProgression} = useLiveEventProgression();
  const currentLanguage = useCurrentLanguage();

  const [fetchContent, {data: contentData, loading}] =
    useGetContentByIdLazyQuery();

  useEffect(() => {
    if (id) {
      fetchContent({
        variables: {id, culture: currentLanguage},
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentLanguage]);

  const {data} = useCategoryChildrenByIdQuery({
    variables: {
      first: 6,
      id: category && category.id,
    },
    fetchPolicy: 'cache-first',
    skip: !(category && category.id) || (!!__oldData && !!__oldData.length),
  });
  const {canSeeContent} = usePaywal();

  const items: ICategorySliderItem[] = useMemo(() => {
    return category && data && data.category && data.category.children
      ? mapCategoryChildrenItems(
          {
            items: data.category.children.edges.map(edge => edge?.node) as any,
            __typename: 'ContentConnection',
          },
          findProgression,
          canSeeContent,
          calculateProgression,
          {
            showCover: size === 'top10' && category.__typename === 'RadioCateg',
          },
        )
      : [];
  }, [
    category,
    data,
    findProgression,
    calculateProgression,
    size,
    canSeeContent,
  ]);

  const pageSlider = useMemo(() => {
    if (contentData && contentData.content) {
      if (contentData.content.__typename === 'LiveTVPage') {
        const items: ICategorySliderItem[] = mapLiveTVPage(
          contentData.content,
          canSeeContent,
        );
        return items;
      } else if (contentData.content.__typename === 'LiveEventsPage') {
        const items: ICategorySliderItem[] = mapLiveEventsPage(
          contentData.content,
          canSeeContent,
          t,
        );
        return items;
      }
    }
    return [];
  }, [contentData, canSeeContent, mapLiveTVPage, mapLiveEventsPage]);

  return (
    <View style={{backgroundColor: '#111216'}}>
      {loading ? (
        <CarouselLoader />
      ) : (
        <BlockSlider
          seeMore={
            seeMore ||
            (onSeeMore && {label: t('See more'), onPress: onSeeMore}) ||
            undefined
          }
          isLink={isLink}
          size={size}
          data={
            pageSlider.length
              ? pageSlider
              : __oldData && __oldData.length
                ? __oldData
                : items
          }
          premiumText={t('premium') as string}
          {...rest}
        />
      )}
    </View>
  );
};

export default CategorySlider;
