/* eslint-disable react-native/no-inline-styles */
import React, {FC, Suspense, useEffect, useRef, useState} from 'react';
import ListenPlayer, {
  IAudioSource,
} from 'design-system/src/Widget/Player/ListenPlayer/ListenPlayer.web';
import {View} from 'react-native';
import ListenPlayList from 'design-system/src/Widget/Listen/ListenPlayList/ListenPlayList';
import Metrics from 'design-system/src/Theme/Metrics';
import {UseFloatingListen} from '~Providers/FloatingListenProvider/FloatingListenProvider';
import {useUpdateProgression} from '~Hooks/useUpdateProgression';
import {useFindProgression} from '~Hooks/useFindProgression';
import useUpdateBookmarks from '~Hooks/useUpdateBookmarks';
import {
  IPodcastItemFragmentFragment,
  usePodCastItemByIdLazyQuery,
  usePodCastByIdLazyQuery,
  IPodcastFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useLazyGetVimeoVideoQuery} from '~Api/TraceApi/traceApi';
import checkVimeoUrl from '~Umbraco/Utils/CheckVimeoUrl';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import {usePlayList} from '@src/Providers/PlayListProvider/PlayListProvider';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import appPodcastSlice from '@src/Store/Slices/AppSlice/podcast.slice';
import useShareContent from '@src/Hooks/useShareContent';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';
import useGetLinkToShare from '@src/utils/useGetLinkToShare';
import {AppRoutesEnum} from '@src/Constant/routes';
import useViewPort from 'design-system/src/Hooks/useViewPort';
import useRouteActive from '@src/layout/AppLayout/AppHeader/useRouteActive';

const ListenRowCardWeb = React.lazy(() =>
  import('design-system/src/Components/Card').then(module => ({
    default: module.ListenRowCardWeb,
  })),
);

const PodCastPlayerPage: FC = () => {
  const {params, isMini} = useAppSelector(state => state.appPodcast);

  const {id, items, selectedIndex, isPaused, currentTime: time} = params;

  const shareContent = useShareContent();
  const [isOpenDialogShare, setIsOpenDialogShare] = useState(false);

  const {t} = useTranslation('translation');

  const dispatch = useAppDispatch();

  const {state, setFloatingState} = UseFloatingListen()!;

  const {playlistState, setPlayList} = usePlayList()!;

  const {handleProgression} = useUpdateProgression();

  const {computeEpisodeProgression} = useFindProgression();

  const {updateBookMark, isBookmarked, findBookmarkItem} = useUpdateBookmarks();

  const [fetchPodCastItem] = usePodCastItemByIdLazyQuery({
    errorPolicy: 'all',
  });

  const [fetchPodCast, {loading}] = usePodCastByIdLazyQuery({
    errorPolicy: 'all',
  });

  const [fetchVimeoVideo] = useLazyGetVimeoVideoQuery();

  const {isMobile} = useBreakpoint();

  const [podcastItems, setPodcastItems] = useState<IAudioSource[] | undefined>(
    items,
  );
  const [category, setCategory] =
    useState<IPodcastFragmentFragment['parent']>(undefined);

  const refCurrentTime = useRef(time ?? 0.0);
  const [paused, setPaused] = useState(isPaused);
  const pausedStatus = useRef(isPaused);
  const [currentIndex, setCurrentIndex] = useState(selectedIndex ?? 0);
  const [linkToShare, setLinkToShare] = useState<string | null>(null);

  const {getLinkToShare} = useGetLinkToShare();
  const {viewportHeight} = useViewPort();

  const {isRouteActive} = useRouteActive();
  const isListenPage = isRouteActive([AppRoutesEnum.HOME_LISTEN]);

  useEffect(() => {
    if (!isListenPage && !isMini) {
      if (isMobile) {
        dispatch(appPodcastSlice.actions.closePlayer());
        dispatch(appPodcastSlice.actions.setMiniPlayer(false));
      } else {
        dispatch(appPodcastSlice.actions.setMiniPlayer(true));
      }
    }
  }, [isListenPage]);

  useEffect(() => {
    if (isMobile && isMini) {
      dispatch(appPodcastSlice.actions.setMiniPlayer(false));
    }
  }, [isMobile, isMini]);

  const setShowMiniPlayer = (show: boolean) => {
    if (isMobile) {
      dispatch(appPodcastSlice.actions.closePlayer());
      dispatch(appPodcastSlice.actions.setMiniPlayer(false));
      return;
    }
    dispatch(appPodcastSlice.actions.setMiniPlayer(show));
  };

  useEffect(() => {
    if (!items) {
      fetchPodCastItem({
        variables: {
          id,
        },
      }).then(({data: fetchedData}) => {
        if (fetchedData && fetchedData.podcastItem) {
          let node = fetchedData.podcastItem;
          if (node.parent?.__typename === 'Podcast') {
            let parentPod = node.parent;
            fetchPodCast({
              variables: {
                id: parentPod.id,
              },
            }).then(({data: resData}) => {
              if (resData && resData.podcast) {
                const {children, title: parentTitle, parent} = resData.podcast;
                setCategory(parent);
                if (children) {
                  const {items: _items} = children;
                  if (_items) {
                    let tempItems: IAudioSource[] = _items.map(
                      (item, index) => {
                        let podcastItem = item as IPodcastItemFragmentFragment;
                        if (podcastItem.id === node.id) {
                          // track.player.started({
                          //   id: podcastItem.id,
                          //   title: podcastItem.title ?? '',
                          //   typename: podcastItem.__typename,
                          //   category: category?.name || '',
                          //   length: podcastItem.length || '',
                          //   // episode: podcastItem.name,
                          // });
                          setCurrentIndex(index);
                        }
                        return {
                          id: podcastItem.id,
                          uri: async () => {
                            if (
                              podcastItem.vimeoHlsUrl &&
                              checkVimeoUrl(podcastItem.vimeoHlsUrl)
                            ) {
                              return podcastItem.vimeoHlsUrl;
                            }
                            const res = await fetchVimeoVideo(
                              podcastItem.vimeoId + '',
                            );
                            if (res && res.data && res.data.hls) {
                              return res.data.hls;
                            }
                            return '';
                          },
                          subtitle: parentTitle,
                          title: podcastItem.title,
                          poster: podcastItem.thumbnail?.url,
                          description: podcastItem.description,
                          length: podcastItem.length,
                          url: podcastItem.url,
                          __typename: podcastItem.__typename,
                          episode: podcastItem.name,
                        };
                      },
                    );
                    setPodcastItems(tempItems);
                    setPlayList({
                      ...{
                        playlist: tempItems,
                        parentId: parentPod.id,
                      },
                    });
                  }
                }
              }
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPodCast, setPodcastItems, items]);

  useEffect(() => {
    if (podcastItems) {
      state.isPaused = pausedStatus.current ?? false;
      state.currentTime = refCurrentTime.current;
      state.currentMedia = podcastItems[currentIndex];
      state.isRadio = false;
      state.id = currentIndex;
      setFloatingState({...state});
      if (podcastItems[currentIndex] && playlistState.parentId !== '') {
        handleProgression(
          podcastItems[currentIndex].id,
          refCurrentTime.current,
          'listen',
          'PodcastItem',
          0,
          (newWatched: any) => {
            handleProgression(
              playlistState.parentId,
              computeEpisodeProgression<IAudioSource>(podcastItems, newWatched),
              'listen',
              'Podcast',
              newWatched,
            );
          },
        );
      }

      if (!paused) {
        setPaused(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastItems, refCurrentTime, pausedStatus]);

  return (
    <div
      style={{
        position: 'fixed',
        height: !isMini ? viewportHeight : 'fit-content',
        width: '100vw',
        zIndex: 10000,
        left: 0,
        top: isMini ? undefined : 0,
        bottom: isMini ? 0 : undefined,
        backgroundColor: 'black',
      }}>
      {loading ? (
        <View
          style={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CustomActivityIndicator />
        </View>
      ) : podcastItems && podcastItems.length !== 0 ? (
        <>
          {/* @ts-ignore */}
          <ListenPlayer
            radioTitle={t('Podcast').toUpperCase() || ''}
            onGetDuration={duration => {
              if (podcastItems[currentIndex]) {
                handleProgression(
                  podcastItems[currentIndex].id,
                  duration,
                  'listen',
                  'PodcastItem',
                  0, // TODO
                );
              }
            }}
            onAudioLoad={() => {
              setPaused(false);
            }}
            initialTime={time}
            onClose={() => {
              dispatch(appPodcastSlice.actions.closePlayer());
            }}
            onShare={async () => {
              const el = items[selectedIndex];

              if (el.url) {
                shareContent.share({
                  __typename: el.__typename as any,
                  url: el.url,
                  message: el.title,
                  id: el.id,
                  episode: selectedIndex + 1,
                  category: category?.name || '',
                  cover: items[selectedIndex]?.poster || '',
                  description: items[selectedIndex]?.description || '',
                });
                const link = getLinkToShare({
                  param: {
                    id: el.id,
                    __typename: el.__typename,
                    episode: selectedIndex + 1,
                    selectedIndex,
                  } as any,
                  path: AppRoutesEnum.LISTEN_REDIRECTION,
                });
                setLinkToShare(link);
                setIsOpenDialogShare(true);
              }
            }}
            onPlayEnd={() => {
              if (podcastItems[currentIndex]) {
                handleProgression(
                  podcastItems[currentIndex].id,
                  refCurrentTime.current,
                  'listen',
                  'PodcastItem',
                  0,
                );
              }
              // track.player.finished({
              //   id: podcastItems[currentIndex]?.id ?? '',
              //   title: podcastItems[currentIndex]?.title ?? '',
              //   typename: podcastItems[currentIndex]?.__typename,
              //   category: category?.name || '',
              //   length: podcastItems[currentIndex]?.length || '',
              // });
            }}
            onFavoris={() => {
              podcastItems[currentIndex] && podcastItems[currentIndex].url;
              {
                const bookMarkData = findBookmarkItem(
                  podcastItems[currentIndex]?.id,
                );

                updateBookMark(
                  podcastItems[currentIndex]?.id,
                  podcastItems[currentIndex]?.url ?? '',
                  !bookMarkData,
                );
              }
            }}
            onNext={index => {
              setCurrentIndex(index);
              state.currentMedia = podcastItems[index];
              state.isRadio = false;
              state.id = index;
              setFloatingState({...state});
              if (podcastItems[currentIndex]) {
                handleProgression(
                  podcastItems[currentIndex].id,
                  refCurrentTime.current,
                  'listen',
                  'PodcastItem',
                  0, // TODO
                );
              }
            }}
            onPrevious={index => {
              setCurrentIndex(index);
              state.currentMedia = podcastItems[index];
              state.isRadio = false;
              state.id = index;
              setFloatingState({...state});
              if (podcastItems[currentIndex]) {
                handleProgression(
                  podcastItems[currentIndex].id,
                  refCurrentTime.current,
                  'listen',
                  'PodcastItem',
                  0, // TODO
                );
              }
            }}
            currentChannel={currentIndex}
            sourceList={podcastItems}
            variant="podcast"
            onAudioPlay={(item, _isPaused) => {
              state.currentMedia = item;
              state.isRadio = false;
              state.id = currentIndex;
              setFloatingState({...state});
              pausedStatus.current = _isPaused;
              setPaused(_isPaused);
              if (item) {
                if (_isPaused) {
                  // track.player.stopped({
                  //   id: item.id ?? '',
                  //   title: item.title ?? '',
                  //   typename: item.__typename,
                  //   category: category?.name || '',
                  //   length: item.length || '',
                  // });
                } else {
                  // track.player.started({
                  //   id: item.id ?? '',
                  //   title: item.title ?? '',
                  //   typename: item.__typename,
                  //   category: category?.name || '',
                  //   length: item.length || '',
                  //   // episode: item.name,
                  // });
                }
              }
            }}
            onTimeProgress={currentTime => {
              refCurrentTime.current = currentTime;
              // setCurrentTime(currentTime);
            }}
            isCurrentChannelLiked={isBookmarked(
              podcastItems[currentIndex]?.id || '',
            )}
            sideContent={
              <ListenPlayList
                title={t('All episodes') + ''}
                data={podcastItems}
                variant={isMobile ? 'secondary' : 'transparent'}
                onItemPress={(item, index) => {
                  if (currentIndex !== index) {
                    state.currentMedia = item;
                    state.id = index;
                    if (podcastItems[currentIndex]) {
                      handleProgression(
                        podcastItems[currentIndex].id,
                        refCurrentTime.current,
                        'listen',
                        'PodcastItem',
                        0, // TO DO
                      );
                    }
                    setFloatingState({...state});
                    setCurrentIndex(index);
                  }
                }}
                renderItem={(item, index) => {
                  let value = item;
                  return (
                    <View
                      key={index}
                      style={{
                        marginVertical: Metrics.verticalScale(6),
                        padding: Metrics.moderateScale(8),
                        borderRadius: Metrics.moderateScale(4),
                        backgroundColor:
                          currentIndex === index ? 'black' : 'transparent',
                      }}>
                      <Suspense>
                        <ListenRowCardWeb
                          title={value.title ?? ''}
                          length={`${value.length} min`}
                          cover={{uri: value.poster}}
                          infoColor={'white'}
                          type="xsmall"
                          flexDirection="row"
                        />
                      </Suspense>
                    </View>
                  );
                }}
              />
            }
            onPressMiniPlayer={() => {
              setShowMiniPlayer(!isMini);
            }}
            showMiniPlayer={isMini}
          />
        </>
      ) : (
        <View
          style={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ContentEmpty title={t('Error')} />
        </View>
      )}
      {!isMini && (
        // eslint-disable-next-line react/self-closing-comp
        <style
          dangerouslySetInnerHTML={{
            __html: 'body {overflow: hidden;}',
          }}></style>
      )}
      <ShareDialog
        isOpen={isOpenDialogShare}
        onClose={() => setIsOpenDialogShare(false)}
        link={linkToShare}
      />
    </div>
  );
};

export default PodCastPlayerPage;
