import useInterestTags from '@src/App/components/ModalAuthentication/SignUp/SignUpInterest/useInterestTags';
import useMusicGenre from '@src/App/components/ModalAuthentication/SignUp/SignUpInterest/useMusicGenre';
import UmbracoFormField from '@src/Umbraco/Components/UmbracoForm/UmbracoFormPage/UmbracoFieldset/UmbracoFormColumn/UmbracoFormField/UmbracoFormField';
import Typography from 'design-system/src/Components/Typography/Typography';
import React, {useEffect, useMemo} from 'react';
import {View} from 'react-native';
import {
  FormProvider,
  UseFormReturn,
  UseFormHandleSubmit,
  SubmitHandler,
} from 'react-hook-form';
import Button from 'design-system/src/Components/Button/Button';
import {IForm} from './ProfileMyProfileFieldEdit';
import documentLinkToUuid from '@src/Umbraco/Utils/documentLinkToUuid';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface ProfileMyProfileInteresetEditProps {
  title: string;
  defaultValue?: string;
  buttonLabel: string;
  fieldKey: 'interestsTags' | 'musicGenreTags';
  reactForm: UseFormReturn<IForm>;
  isLoading: boolean;
  loading: boolean;
  handleSubmit: UseFormHandleSubmit<IForm>;
  handleSubmitSendForm: SubmitHandler<IForm>;
  handleError: (error: any) => void;
}

const ProfileMyProfileInteresetEdit = (
  props: ProfileMyProfileInteresetEditProps,
) => {
  const {
    title,
    fieldKey,
    reactForm,
    isLoading,
    loading,
    buttonLabel,
    defaultValue: inDefaultValue,
    handleSubmit,
    handleSubmitSendForm,
    handleError,
  } = props;
  const {t} = useTranslation('auth');
  const {interestTags, findTagItem} = useInterestTags();
  const {musicGenre, findMusicGenreItem} = useMusicGenre();

  const interestPrevalue = useMemo(() => {
    return interestTags.reduce(
      (a, c) => ({
        ...a,
        [c.id]: c.name,
      }),
      {},
    );
  }, [interestTags]);

  const musicGenrePrevalue = useMemo(() => {
    return musicGenre.reduce(
      (a, c) => ({
        ...a,
        [c.id]: c.name,
      }),
      {},
    );
  }, [musicGenre]);

  const defaultValue = useMemo(() => {
    const tags =
      inDefaultValue
        ?.split(',')
        .map(r =>
          r.indexOf('umb://document/') >= 0
            ? documentLinkToUuid(r || '') || ''
            : r.trim(),
        )
        .filter(v => !!v) || [];

    if (fieldKey === 'interestsTags') {
      return tags
        ?.map(v => {
          const item = findTagItem(v);

          return item?.name;
        })
        .filter(v => !!v);
    } else if (fieldKey === 'musicGenreTags') {
      return tags
        ?.map(v => {
          const item = findMusicGenreItem(v);

          return item?.name;
        })
        .filter(v => !!v);
    }
  }, [interestPrevalue, musicGenrePrevalue, fieldKey]);

  useEffect(() => {
    if (defaultValue) {
      const currentValue = reactForm.getValues();
      if (
        JSON.stringify(currentValue['formField']) !==
        JSON.stringify(defaultValue)
      ) {
        reactForm.setValue('formField', defaultValue);
      }
    }
  }, [defaultValue]);

  const handleSubmitSendFormTags: SubmitHandler<IForm> = async formData => {
    if (fieldKey === 'interestsTags') {
      const interestsTags = formData['formField'].map((v: string) => {
        const item = findTagItem(v);

        return item?.id;
      });
      formData['formField'] = interestsTags;
    } else if (fieldKey === 'musicGenreTags') {
      const musicGenreTags = formData['formField'].map((v: string) => {
        const item = findMusicGenreItem(v);

        return item?.id;
      });
      formData['formField'] = musicGenreTags;
    }
    handleSubmitSendForm(formData);
  };

  return (
    <>
      <View style={{zIndex: 0}}>
        <Typography variant="h2" style={{marginBottom: 72}}>
          {t('My profile')}
        </Typography>
        <View
          style={{
            marginBottom: 4,
          }}>
          <FormProvider {...reactForm}>
            <UmbracoFormField
              field={{
                caption: title,
                alias: 'formField',
                required: true,

                requiredErrorMessage: t('Field required') || '',
                settings: {},
                type: 'checkboxList',
                defaultRules: {
                  // required: true,
                  validate: value => {
                    console.log('intersts', value);
                    if (Array.isArray(value)) {
                      return value.length >= 3
                        ? true
                        : t('Select at least 3') || '';
                    }
                    return t('Required') + ' ' + JSON.stringify(value);
                  },
                },
                preValues:
                  fieldKey === 'interestsTags'
                    ? Object.values(interestPrevalue)
                    : Object.values(musicGenrePrevalue),
              }}
              style={{width: '100%'}}
              index={0}
            />
          </FormProvider>
        </View>
        <View
          style={{
            zIndex: -1,
          }}>
          <Button
            variant="contained"
            size="medium"
            title={buttonLabel}
            fullWidth
            loading={isLoading || loading}
            onPress={handleSubmit(handleSubmitSendFormTags, handleError)}
          />
        </View>
      </View>
    </>
  );
};

export default ProfileMyProfileInteresetEdit;
