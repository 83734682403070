import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export const searchBarStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    marginLeft: Metrics.horizontalScale(11),
  },
  buttonText: {
    color: defaultTheme.primaryTitle,
    fontSize: Metrics.fontScale(16),
  },
});
