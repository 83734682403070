import useNavigation from '@src/Navigation/useNavigation';
import {useState} from 'react';
import {
  ICourseFragmentFragment,
  IJobTrainingFragmentFragment,
  useGetCourseDownloadLazyQuery,
  useGetJobDownloadLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';

export const useHandleUpdateLearnContent = () => {
  const [fetchCourse, {loading: courseLoading}] =
    useGetCourseDownloadLazyQuery();
  const [fetchJobTraining, {loading: jobLoading}] =
    useGetJobDownloadLazyQuery();

  const [currentItem, setCurrentItem] = useState<
    | {
        files: string[];
        data: ICourseFragmentFragment | IJobTrainingFragmentFragment;
        property: ILearnPropertyName | undefined;
      }
    | undefined
  >();
  const navigation = useNavigation();

  return {
    fetchCourse,
    fetchJobTraining,
    setCurrentItem,
    currentItem,
    courseLoading,
    jobLoading,
  };
};
