import {configureStore} from '@reduxjs/toolkit';
import {freeApi} from '@src/Api/CommonApi/commonApi';
import {traceApi} from '@src/Api/TraceApi/traceApi';
import {contentManagmentApi} from '@src/Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import {umbracoCdnApi} from '@src/Api/Umbraco/ContentManagmentApi/UmbracoCdnApi/umbracoCdnApi';
import rootReducer from './reducers';
// import {persistReducer, persistStore} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import courseUnitSlice from './Slices/CourseUnitSlice/CourseUnit.slice';

// const storeKey = 'root';

// const persistedReducer = persistReducer(
//   {
//     key: storeKey,
//     version: 1,
//     storage,
//     whitelist: [courseUnitSlice.name],
//   },
//   rootReducer,
// );

function configureAppStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(contentManagmentApi.middleware)
        .concat(traceApi.middleware)
        .concat(freeApi.middleware)
        .concat(umbracoCdnApi.middleware),
    ],
  });

  return store;
}
const store = configureAppStore();

// export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;

export default store;
