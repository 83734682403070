import {useSelecteAllWatched} from '@src/Store/selectors/useMember';
import {useStringExtension} from './useStringExtension';
import {useCallback} from 'react';

export const useFindProgression = () => {
  const allWatched = useSelecteAllWatched();
  // console.log(
  //   '🚀 ~ useFindProgression ~ allWatched:',
  //   allWatched['a308006d-b102-4de8-bad3-ebeab0261d8d'],
  // );

  const {formatTime} = useStringExtension();

  const findProgression = useCallback(
    (id: string) => {
      return allWatched[id] && allWatched[id].length !== 0
        ? allWatched[id].time / allWatched[id].length
        : 0;
    },
    [allWatched],
  );

  const calculateTime = useCallback(
    (duration: number, currentTime: number, showTimeRemaining?: boolean) => {
      if (showTimeRemaining) {
        const time = duration - currentTime;
        return `${formatTime(time, duration, true)}`;
      }

      return formatTime(currentTime, duration);
    },
    [formatTime],
  );

  const findCurrentTime = useCallback(
    (id: string) => {
      return allWatched[id] ? allWatched[id].time : 0;
    },
    [allWatched],
  );

  const findDuration = useCallback(
    (id: string) => {
      return allWatched[id] ? allWatched[id].length : 0;
    },
    [allWatched],
  );

  const computeEpisodeProgression = useCallback(
    <T extends any>(
      items: T[],
      allWatched_: Record<
        string,
        {
          parentId?: string | undefined;
          time: number;
        }
      >,
    ): number => {
      let sum = 0.0;
      if (allWatched_) {
        items.forEach(value => {
          let item = value as any;
          if (item && allWatched_[item.id]) {
            let time = allWatched_[item.id].time;
            sum += time ? time : 0.0;
          }
        });
      }
      return sum;
    },
    [],
  );

  return {
    findProgression,
    findCurrentTime,
    calculateTime,
    computeEpisodeProgression,
    formatTime,
    findDuration,
  };
};
