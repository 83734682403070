import React, {useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import useDefaultTheme from '../../Theme/useTheme';
import Icon from '../Icon/Icon';
import {DownloadButtonStyle} from './DownloadButton.styles';
import Svg, {Rect} from 'react-native-svg';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {darkTheme} from '@storybook/react-native';

export interface IDownloadButtonProps {
  progress?: number;
  onStop?: () => void;
  onDownload?: () => void;
}

const DownloadButton = (props: IDownloadButtonProps) => {
  const {progress, onStop, onDownload} = props;
  const theme = useDefaultTheme();
  const [progressValue, setProgressValue] = useState(0);
  const [stoped, setStoped] = useState(false);
  const hasProgression = typeof progress !== 'undefined';

  useEffect(() => {
    if (hasProgression && progressValue !== progress) {
      setProgressValue(progress);
    }
  }, [hasProgression, progress, progressValue]);

  const progressPercent: number = Math.max(0, Math.min(1, progress || 0)) * 100;

  const handleStop = () => {
    setStoped(true);
    onStop && onStop();
  };
  const handleStart = () => {
    setStoped(false);
    onDownload && onDownload();
  };

  return (
    <View style={DownloadButtonStyle.root}>
      {hasProgression && !stoped ? (
        progressPercent < 100 ? (
          <Pressable onPress={handleStop}>
            {/* <CircularProgressBase
              activeStrokeWidth={activeStrokeWidth}
              inActiveStrokeWidth={inActiveStrokeWidth}
              inActiveStrokeOpacity={inActiveStrokeOpacity}
              value={progressPercent}
              initialValue={progressPercent}
              radius={10}
              activeStrokeColor={theme.primary}
              inActiveStrokeColor={theme.gray}> */}
            {/* <Svg width="8" height="8" viewBox="0 0 8 8" fill="none">
              <Rect width="8" height="8" fill={theme.primary} />
            </Svg> */}
            {/* </CircularProgressBase> */}
            <div
              style={{
                width: 20,
                height: 20,
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CircularProgressbar
                value={progressPercent}
                styles={{
                  path: {
                    stroke: theme.primary,
                    strokeLinecap: 'round',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                  },
                  trail: {
                    stroke: theme.gray,
                    strokeLinecap: 'round',
                  },
                  text: {
                    fill: theme.primary,
                    fontSize: 10,
                  },
                  background: {
                    fill: theme.gray,
                  },
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  backgroundColor: theme.primary,
                  width: 8,
                  height: 8,
                }}
              />
            </div>
          </Pressable>
        ) : (
          <Icon name="radio-on" size="24" color={theme.primary} />
        )
      ) : (
        <Pressable onPress={handleStart}>
          <Icon name="download" size="24" />
        </Pressable>
      )}
    </View>
  );
};

export default DownloadButton;
