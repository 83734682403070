import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberDownloadedBody} from './interface/memberDataBody.interface';
import {IDownloadedResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/downloaded';
export const createMemberDownloadedEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberDownloadedById: builder.query<IDownloadedResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberDownloaded: builder.query<IDownloadedResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  updateMemberDownloaded: builder.query<
    IDownloadedResponse,
    {id?: string; data: IMemberDownloadedBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
  deleteMemberDownloaded: builder.query<any, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'DELETE',
    }),
  }),
});
