import {ISubscriptionProduct} from '../interface/subscription.interface';
import {
  SubscriptionBody,
  SubscriptionResponse,
} from '../interface/umbraco.interface';
import {TraceApiEndpointBuilder} from '../traceApi';

export const createSubscriptionEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getUserSubscription: builder.query<SubscriptionResponse, void>({
    query: () => ({
      url: '/me/subscription',
      method: 'GET',
    }),
  }),
  updateUserSubscription: builder.query<SubscriptionResponse, SubscriptionBody>(
    {
      query: body => ({
        url: '/me/subscription',
        method: 'PUT',
        body,
      }),
    },
  ),
  getAndroidAccessToken: builder.query<{token: string}, void>({
    query: () => ({
      url: '/store/android/token',
      method: 'GET',
    }),
  }),
  getCheckoutSession: builder.query<any, string>({
    query: (id: string) => ({
      url: `/store/stripe/checkout/${id}`,
      method: 'GET',
    }),
  }),
  getSubscriptionProducts: builder.query<ISubscriptionProduct[], void>({
    query: () => ({
      url: '/store/stripe/payment-link-products',
      method: 'GET',
    }),
  }),
  cancelStripeSubscription: builder.mutation<SubscriptionResponse, void>({
    query: () => ({
      url: '/store/stripe/subscription/cancel',
      method: 'POST',
    }),
  }),
});
