import {FetchPolicy} from '@apollo/client';
import {useState, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const expiration = dayjs.duration(24, 'hour');
const cacheKey = 'lastCacheRefresh';

const useCacheWithExpiration = (): FetchPolicy => {
  const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>('cache-only');

  useEffect(() => {
    AsyncStorage.getItem(cacheKey)
      .then(lastFetch => {
        if (
          lastFetch === null ||
          dayjs().diff(dayjs(lastFetch)) > expiration.asMilliseconds()
        ) {
          AsyncStorage.setItem(cacheKey, dayjs().toISOString()).catch(() =>
            console.warn(`Failed to save time of last fetch for ${cacheKey}`),
          );
          setFetchPolicy('network-only');
        } else {
          setFetchPolicy('cache-first');
        }
      })
      .catch(() => setFetchPolicy('network-only'));
  }, []);

  return fetchPolicy;
};

export default useCacheWithExpiration;
