import styled from "styled-components";
import { ISelectProps } from "./Select.web"; 

export const _SelectRoot = styled("div")<{}>(() => {
  return {
    cursor: "pointer",
    display: "inline-block",
    position: "relative",
    width: '100%'
  };
});
export const _SelectInputWrapper = styled("div")<{
  $size?: ISelectProps<string>["size"];
}>(({ $size = "medium" }) => {
  // const mapSize: Record<
  //   NonNullable<ISelectProps<any>["size"]>,
  //   { minHeight: number; padding: string }
  // > = {
  //   medium: { minHeight: 45, padding: "5px 16px 5px 20px" },
  //   small: { minHeight: 36, padding: "9px 12px 10px 16px" },
  // };
  // const { minHeight, padding } = mapSize[$size];
  return {
    // background: "#F4F3F1",
    // borderRadius: 64 as any,
    // minHeight: minHeight as any,
    // display: "flex",
    // alignItems: "center",
    // padding,
    // gap: 6 as any,
    userSelect: "none",
    // transition: "all 1.5s ease-in-out",
  };
});

export const _SelectNativeRoot = styled('div')<{}>(({}) => {
  // const { color } = useTheme();
  return {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    border: "1px solid #D8D8D8",
    borderRadius: 4,
    // color: color.greyText2,
    position: "relative",
    ["svg"]: {
      position: "absolute",
      right: 0,
      pointerEvents: "none",
    },
  };
});
export const _SelectNativeSelect = styled('select')<{}>(({}) => {
  return {
    // fontFamily: "inherit",
    display: "flex",
    flex: 1,
    border: '0px',
    padding: "13px 8px 13px 17px",
    cursor: "pointer",
    appearance: "none",
    background: "transparent",
    // color: color.greyText2,
    // ...typographyVariantStyled.h4,
  };
});

export const _SelectListWrapper = styled("div")<{
  $left?: number;
  $top?: number;
}>(({ $left = 0, $top = 0 }) => {
  return {
    background: "#F9F9F9",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: '24 px',
    position: "absolute",
    // width: "100%",
    transform: "translateY(8px)",
    maxHeight: 150 as any,
    overflow: "auto",
    padding: "20px 22px 20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: '7px',
    // position: "absolute",
    // width: 200,
    // transform: "translate(-100px, -100%)",
    left: $left as any,
    top: $top as any,
  };
});
