import React from 'react';
import DefaultMenuDrawerContent from './DefaultMenuDrawerContent';
import CustomDrawer from '@src/layout/AppLayout/AppDrawer/CustomDrawer';

export const DefaultMenuDrawer = (props: {
  open: boolean;
  onClose?: () => void;
  leftHeader?: React.ReactNode;
}) => {
  const {open, onClose, leftHeader} = props;

  return (
    <CustomDrawer
      child={<DefaultMenuDrawerContent closeDrawer={onClose} />}
      isOpen={open}
      onToggleDrawer={onClose}
      isFromLanding={true}
      leftHeader={leftHeader}
    />
  );
};

export default DefaultMenuDrawer;
