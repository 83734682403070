import React from 'react';
import GenericCard from '../../GenericCard/GenericCard';
import RowCard from '../../RowCard/RowCard';
import IconRoundPlaySvg from '../../../../assets/svg/ic_round_play.svg';
import IcDownloadSvg from '../../../../assets/svg/light-down.svg';
import {VodCardProps} from '../VodCardProps';
import {Pressable} from 'react-native';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import ShareLeadingCard from '../../ShareLeadingCard/ShareLeadingCard';
import VodCardAside from '../VodCardAside/VodCardAside';
import PostTypeText from '../../../Text/PostTypeText/PostTypeText';
import Metrics from '../../../../Theme/Metrics';

const SingleVodCard = (props: VodCardProps) => {
  const {
    cover,
    vodeType,
    progress,
    onPressPlayIcon,
    onPressShareIcon,
    onPressFavIcon,
  } = props;
  return (
    <RowCard
      left={
        <ShareLeadingCard
          Card={
            <GenericCard
              coverImage={cover}
              leadingHeader={!!vodeType && <PostTypeText text={vodeType} />}
              type={'xsmall'}
              contentStyle={{
                paddingBottom: Metrics.verticalScale(4),
              }}
              footer={<CustomProgressBar progress={progress} />}
              center={
                <Pressable onPress={onPressPlayIcon}>
                  <IconRoundPlaySvg />
                </Pressable>
              }
            />
          }
          onClickShareIc={onPressShareIcon ?? function (): void {}}
          onClickFavIc={onPressFavIcon ?? function (): void {}}
        />
      }
      right={<VodCardAside asideProps={props} TrailingIc={IcDownloadSvg} />}
    />
  );
};

export default SingleVodCard;
