import React, {createContext, useContext} from 'react';
import {WindowLocation} from '@reach/router';

interface AppRouterContextProps {
  isModal?: boolean;
  isDrawer?: boolean;
  location?: WindowLocation;
  backgroundLocation?: WindowLocation;
}

const AppRouterContext = createContext<AppRouterContextProps | undefined>(
  undefined,
);

export const AppRouterProvider = ({
  children,
  ...restProps
}: {children: React.ReactNode} & AppRouterContextProps) => {
  return (
    <AppRouterContext.Provider value={restProps}>
      {children}
    </AppRouterContext.Provider>
  );
};

export const useAppRouterContext = () => {
  const context = useContext(AppRouterContext);
  if (!context) {
    return {
      isModal: false,
      isDrawer: false,
    };
  }
  return context;
};
