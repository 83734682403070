/* eslint-disable react-native/no-inline-styles */
import useOutsideClick from '@src/Hooks/useOutsideClick';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import useViewPort, {
  useGetAbsoluteLeft,
} from 'design-system/src/Hooks/useViewPort';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {IconName} from 'design-system/src/assets/Icones';
import React, {useEffect, useRef, useState} from 'react';

interface IAppDropDownSelectProps<T extends object = object> {
  title?: string;
  items?: T[];
  style?: React.CSSProperties;
  onClickItem?(item: T): void;
  getLabel?(item: T): string;
  getValue?(item: T): string;
  value?: string;
  icon: IconName | React.ReactNode;
}

const AppDropDownSelect = <T extends object = object>({
  title,
  items,
  style,
  onClickItem,
  getLabel,
  getValue,
  value,
  icon,
}: IAppDropDownSelectProps<T>) => {
  const {isMobile} = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);

  const dropdownMenuRef = useRef(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(triggerRef, () => setIsOpen(false));

  const {viewportHeight} = useViewPort();
  const {leftPosition} = useGetAbsoluteLeft(triggerRef, [isOpen]);

  const handleItemClick = (element: T) => () => {
    if (onClickItem) {
      onClickItem(element);
    }
    setIsOpen(false);
  };

  return (
    <div
      ref={triggerRef}
      className="AppDropDownMenu"
      style={{
        position: 'relative',
        ...style,
      }}>
      <div
        style={{
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
          gap: 8,
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        // onMouseEnter={() => {
        //   setHover(true);
        // }}
        // onMouseLeave={() => setHover(false)}
      >
        {!!icon &&
          (!React.isValidElement(icon) ? (
            <Icon color="white" name={icon as IconName} />
          ) : (
            icon
          ))}
        <Typography children={title} color="white" variant="body2" />
      </div>
      {isOpen && (
        <div
          ref={dropdownMenuRef}
          style={{
            position: isMobile ? 'fixed' : 'absolute',
            width: '100vw',
            top: isMobile ? 0 : 40,
            left: isMobile ? 0 : leftPosition,
            zIndex: isMobile ? 1000 : 10,
            padding: isMobile ? 0 : '0 60px',
            boxSizing: 'border-box',
          }}>
          <div
            style={{
              width: '100%',
              height: isMobile ? viewportHeight : undefined,
              backgroundColor: 'rgba(27, 28, 33, 0.50)',
              backdropFilter: `blur(${isMobile ? 30 : 20}px)`,
              padding: isMobile ? '60px 0' : 40,
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'center' : undefined,
              justifyContent: isMobile ? 'space-between' : undefined,
            }}>
            {isMobile && <Typography variant="htitle">{title}</Typography>}
            {isMobile && (
              <style
                dangerouslySetInnerHTML={{
                  __html: 'body {overflow: hidden;}',
                }}
              />
            )}
            <div
              style={{
                gap: 11,
                display: isMobile ? 'inline-flex' : 'grid',
                flexDirection: isMobile ? 'column' : undefined,
                alignItems: isMobile ? 'center' : undefined,
                gridTemplateRows: 'repeat(5, 1fr)',
                gridTemplateColumns: 'repeat(4, 20%)',
                width: '100%',
                gridAutoFlow: 'column',
              }}>
              {items?.map((item, index) => {
                const isActive = getValue?.(item) === value;
                return (
                  <PressableCustom
                    key={`menu-${index}`}
                    onPress={handleItemClick(item)}>
                    {({hover: buttonHover}) => (
                      <Typography
                        variant="body2"
                        color={buttonHover || isActive ? 'primary' : undefined}>
                        {getLabel?.(item) || ''}
                      </Typography>
                    )}
                  </PressableCustom>
                );
              })}
            </div>
            {isMobile && (
              <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  height: 64,
                  width: 64,
                  borderRadius: 32,
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}>
                <Icon name="close" size="76" color="black" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppDropDownSelect;
