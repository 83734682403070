import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface AppListenState {
  params: {
    id: string;
    url: string;
    vimeoHlsUrl: string;
    __typename: string;
    source: string;
  };
  isOpenPlayer: boolean;
  isMini: boolean;
}

const initialState: AppListenState = {
  params: {
    id: '',
    url: '',
    vimeoHlsUrl: '',
    __typename: '',
    source: '',
  },
  isOpenPlayer: false,
  isMini: false,
};

const appListenSlice = createSlice({
  name: 'appListen',
  initialState,
  reducers: {
    setListenParams(state, action: PayloadAction<AppListenState['params']>) {
      state.params = action.payload;
      return state;
    },
    openPlayer(state) {
      state.isOpenPlayer = true;
      return state;
    },
    closePlayer(state) {
      state.isOpenPlayer = false;
      return state;
    },
    setMiniPlayer(state, action: PayloadAction<boolean>) {
      state.isMini = action.payload;
      return state;
    },
  },
});

export default appListenSlice;
