import React, {useState} from 'react';
import {createContext, PropsWithChildren, useContext} from 'react';
import {RouteTypename} from '~Navigation/type';

const RadioContext = createContext<
  | {
      radio: RadioState;
      setRadio: React.Dispatch<React.SetStateAction<RadioState>>;
    }
  | undefined
>(undefined);

export interface RadioState {
  url: string;
  id: string;
  __typename: RouteTypename;
}

const initialState: RadioState = {
  url: '',
  id: '',
  __typename: 'Unknown',
};

export const useRadio = () => {
  return useContext(RadioContext);
};

export const RadioProvider = (props: PropsWithChildren<any>) => {
  const {children} = props;
  const [radio, setRadio] = useState(initialState);

  return (
    <RadioContext.Provider value={{radio, setRadio}}>
      {children}
    </RadioContext.Provider>
  );
};
