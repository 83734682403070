import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const genericCardStyles = StyleSheet.create({
  cover: {
    borderRadius: Metrics.moderateScale(4),
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  center: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
