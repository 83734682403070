import React from 'react';
import {useAllRegionCategoryTagsLazyQuery} from '~Api/Graphql/gql/types.generated';

const useRegionCategoryTags = () => {
  return useAllRegionCategoryTagsLazyQuery({
    variables: {
      id: '13cede66-396a-45d4-b5ca-bd53e150822f',
    },
  });
};

export default useRegionCategoryTags;
