import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberShareBody} from './interface/memberDataBody.interface';

const courseEndpoint = '/me/course-share';
const jobEndpoint = '/me/job-share';

export const createMemberLearnShareEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberLearnShareById: builder.query<any, {isCourse: boolean; id: string}>({
    query: ({isCourse, id}) => ({
      url: (isCourse ? courseEndpoint : jobEndpoint) + `/${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberLearnShare: builder.query<any, {isCourse: boolean}>({
    query: ({isCourse}) => ({
      url: isCourse ? courseEndpoint : jobEndpoint,
      method: 'GET',
    }),
  }),
  updateMemberLearnShare: builder.query<
    any,
    {id?: string; data: IMemberShareBody; isCourse: boolean}
  >({
    query: ({id, data, isCourse}) => ({
      url: `${isCourse ? courseEndpoint : jobEndpoint}/${id || ''}`,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
});
