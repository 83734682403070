import React from 'react';
import {darkTheme} from 'design-system/src/styles/Themes';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useAppSelector} from '@src/Store/hooks';

interface IProfileHeaderProps {
  style?: React.CSSProperties;
}

const ProfileHeader = (props: IProfileHeaderProps) => {
  const {style} = props;
  const {isMobile} = useBreakpoint();
  const {cover} = useAppSelector(state => state.settings.profile);
  const backgroundImage =
    cover ||
    'https://media.umbraco.io/dev-trace-back-office/kr3hr0wq/frame-378.png';
  return (
    <div
      style={{
        ...style,
        backgroundColor: darkTheme.colors.backgroundBlack,
        width: '100%',
        // minHeight: isMobile ? '100vh' : 'calc(100vh - 20%)',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundImage: isMobile ? undefined : `url(${backgroundImage})`,
        backgroundSize: 'cover',
        position: 'relative',
        aspectRatio: 16 / 9,
        opacity: 0.15,
      }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 30.49%, rgba(17, 18, 22, 0.80) 75.48%, #111216 93.58%)',
          top: 0,
          left: 0,
        }}></div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 30.49%, rgba(17, 18, 22, 0.80) 75.48%, #111216 93.58%)',
          top: 0,
          left: 0,
        }}></div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 0.4,
          background:
            'linear-gradient(0deg, rgba(17, 18, 22, 0.00) 44.86%, rgba(17, 18, 22, 0.80) 71.63%, #111216 100%)',
          top: 0,
          left: 0,
        }}></div>
    </div>
  );
};

export default ProfileHeader;
