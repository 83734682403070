import React, {FC, Suspense} from 'react';
import {View} from 'react-native';

import {RouteComponentProps} from '@reach/router';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import HomeContentLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/HomeContentLoader';

const AppDynamicHomePage = React.lazy(
  () => import('../../Common/AppDynamicHomePage/AppDynamicHomePage'),
);

const SectionPage = React.lazy(
  () => import('@src/Umbraco/Pages/SectionPage/SectionPage'),
);

const AppHome: FC<RouteComponentProps> = () => {
  const {page, loading} = useRegionUrl();

  if (loading) {
    return (
      <div>
        <View style={{flex: 1}}>
          <HomeContentLoader />
        </View>
      </div>
    );
  }

  if (page.home) {
    const contentId: string = page.home.id;
    const contentType = page.home.__typename;
    return (
      <Suspense>
        <AppDynamicHomePage
          id={contentId}
          __typename={contentType}
          contentSource="watch"
        />
      </Suspense>
    );
  }

  return (
    <div>
      <Suspense>
        <SectionPage contentSource="watch" />
      </Suspense>
    </div>
  );
};

export default AppHome;
