import React, {useEffect, useState} from 'react';
import {Pressable, StyleSheet, View, StyleProp, ViewStyle} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import Typography from '../../../Typography/Typography';
export type ICheckBoxItemProps = {
  label: string;
  value: string | number;
  description?: string;
  isCorrect?: boolean;
  onChange?: (value: string | number, isChecked: boolean) => void;
  style?: StyleProp<ViewStyle>;
  checked?: boolean;
};
const CheckBoxItem = (props: ICheckBoxItemProps) => {
  const {checked, value, label, onChange, style} = props;
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  return (
    <Pressable
      onPress={() => {
        setIsChecked(currenValue => {
          const newValue = !currenValue;
          onChange && onChange(value, !isChecked);
          return newValue;
        });
      }}
      style={StyleSheet.flatten([
        style,
        {
          paddingHorizontal: Metrics.horizontalScale(18),
          // paddingTop: Metrics.verticalScale(14),
          // paddingBottom: Metrics.verticalScale(12),
          justifyContent: 'center',
          borderWidth: 1,
          height: Metrics.horizontalScale(48),
          borderRadius: Metrics.horizontalScale(48),
          //   marginBottom: Metrics.verticalScale(16),
          borderColor: isChecked ? '#EA6C13' : '#2F3139',
          backgroundColor: isChecked ? 'rgba(234, 108, 19, 0.13)' : '#16181E',
        },
      ])}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Typography variant="body2">{label}</Typography>
      </View>
    </Pressable>
  );
};

export default CheckBoxItem;
