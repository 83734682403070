import React, {ForwardedRef} from 'react';
import {View, Animated} from 'react-native';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import SectionPageContent, {
  ISectionPageContentRef,
} from './SectionPageContent/SectionPageContent';
import {ContentSource} from '~Umbraco/Components/ContextualContentSlider/ContextualContentSlider';

interface ISectionPageProps {
  id?: string;
  title?: string;
  animatedValue?: Animated.Value;
  contentSource: ContentSource;
}
const SectionPage = (
  props: ISectionPageProps,
  ref: ForwardedRef<ISectionPageContentRef>,
) => {
  const {id, title, contentSource, animatedValue} = props;
  const {t} = useTranslation('translation');

  return id ? (
    <SectionPageContent
      ref={ref}
      id={id}
      title={title}
      animatedValue={animatedValue}
      contentSource={contentSource}
    />
  ) : (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ContentEmpty title={t('Section not found')} />
    </View>
  );
};

export default React.memo(
  React.forwardRef<ISectionPageContentRef, ISectionPageProps>(SectionPage),
);
