import React from 'react';
import {View} from 'react-native';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Metrics from 'design-system/src/Theme/Metrics';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import LinearGradient from 'react-native-linear-gradient';
import AnimatedTexts from '../AnimatedTexts/AnimatedTexts';
import HighlightedTypography from '../HighlightedTypography/HighlightedTypography';
import Button from 'design-system/src/Components/Button/Button';
import {IPaywallSetting} from '~Hooks/usePaywal';
import {
  IChannelFragmentFragment,
  ILiveEventFragmentFragment,
  IVodItemFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

const PlayerPaywallHome = (props: {
  setting: IPaywallSetting;
  isLive: boolean;
  content:
    | IVodItemFragmentFragment
    | ILiveEventFragmentFragment
    | IChannelFragmentFragment
    | undefined;
  onChoosePlan: () => void;
}) => {
  const {setting, isLive, content, onChoosePlan} = props;
  const {
    currentPaywall,
    title,
    subtitle,
    coverDesktop,
    coverMobile,
    icon,
    animatedTitle,
    highlightedSubtitle,
    cancelMention,
    mentionFooter,
    labelCta,
    animationDuration = 600,
  } = setting;
  const {t} = useTranslation('paywall');
  const {isMobile} = useBreakpoint();

  return (
    <View
      style={{
        flex: 1,
        width: isMobile ? '100%' : '80%',
        maxWidth: isMobile ? '100%' : '80%',
        position: 'relative',
        // paddingHorizontal: Metrics.horizontalScale(76),
        paddingTop: isMobile ? 0 : Metrics.verticalScale(74),
        paddingBottom: isMobile ? 0 : Metrics.verticalScale(64),
      }}>
      <View
        style={{
          flex: 1,
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
          borderRadius: isMobile ? 0 : Metrics.moderateScale(16),
          overflow: isMobile ? 'scroll' : 'hidden',
          paddingHorizontal: isMobile ? 16 : undefined,
        }}>
        {/*  background image */}
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
          }}>
          <CustomFastImage
            source={{uri: isMobile ? coverMobile : coverDesktop}}
            resizeMode="cover"
            width={'100%'}
            height={'100%'}
            wrapperStyles={{
              flex: 1,
            }}
          />
        </View>

        {/* gradient background */}
        <LinearGradient
          style={{
            position: 'absolute',
            // top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            height: '100%',
            opacity: 0.8,
          }}
          colors={[
            'rgba(17, 18, 22, 0)',
            'rgba(17, 18, 22, 0.4)',
            'rgba(17, 18, 22, 0.7)',
            '#111216',
          ]}
          locations={[0, 0.2745, 0.5745, 1]}
          useAngle
          angle={180}></LinearGradient>

        {/* content */}
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
            zIndex: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            overflow: 'auto',
            paddingTop: isMobile ? 259 : Metrics.verticalScale(116),
            paddingBottom: isMobile ? 36 : 57,
            paddingHorizontal: isMobile ? 16 : 0,
          }}>
          <CustomFastImage
            source={{uri: icon}}
            resizeMode="cover"
            width={isMobile ? 30 : 48}
            height={isMobile ? 30 : 48}
            backgroundFallbackColor="transparent"
            style={{aspectRatio: 1}}
            wrapperStyles={{
              marginBottom: isMobile ? 34 : Metrics.verticalScale(24),
              overflow: 'visible',
            }}
          />
          <View
            style={{
              width: '100%',
              maxWidth: isMobile ? '100%' : '80%',
            }}>
            <Typography
              variant={isMobile ? 'h1' : 'big2'}
              // numberOfLines={2}
              style={{
                textAlign: 'center',
              }}>
              {title?.trim()}
            </Typography>
          </View>

          <AnimatedTexts
            texts={animatedTitle || []}
            duration={animationDuration}
            style={{
              marginBottom: isMobile ? 42 : Metrics.verticalScale(54),
            }}
          />

          <HighlightedTypography
            variant={isMobile ? 'h4' : 'h2'}
            highlightedText={highlightedSubtitle}
            style={
              isMobile
                ? {
                    marginBottom: Metrics.verticalScale(8),
                    textTransform: 'none',
                    fontSize: Metrics.fontScale(16),
                  }
                : {marginBottom: Metrics.verticalScale(14)}
            }
            highlightedStyle={
              isMobile && {
                textTransform: 'none',
                fontSize: Metrics.fontScale(16),
              }
            }
            children={subtitle || ''}
          />
          <Typography
            variant="body3"
            style={{
              opacity: 0.5,
              marginBottom: isMobile ? 34 : Metrics.verticalScale(49),
            }}>
            {cancelMention}
          </Typography>

          <View
            style={{
              marginBottom: isMobile ? 16 : 50,
            }}>
            <Button
              title={labelCta || t("Je m'abonne maintenant !")}
              variant="contained"
              onPress={onChoosePlan}
              size={isMobile ? 'small' : 'large'}
            />
          </View>

          <Typography
            variant="body3"
            style={{
              opacity: 0.5,
            }}>
            {mentionFooter}
          </Typography>
        </View>
      </View>
    </View>
  );
};

export default PlayerPaywallHome;
