import CarouselCard from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {GridView} from 'design-system/src/Components/GridView';
import React, {ReactNode, useCallback} from 'react';
import {ICategoryChildrenByIdQuery} from '~Api/Graphql/gql/types.generated';
import {ICategorySliderItem} from '~Umbraco/Components/CategorySlider/CategorySlider';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {getCategoryPageItems} from '~Umbraco/Utils/pages/mapCategoryPage';
import {useFindProgression} from '~Hooks/useFindProgression';
import {RouteTypename} from '~Navigation/type';
import {GridViewProps} from 'design-system/src/Components/GridView/GridView';
import {useDispatch} from 'react-redux';
import usePaywal from '~Hooks/usePaywal';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useAppNavigation from '@src/utils/useAppNavigation';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';

export interface ICategoryGridProps
  extends Omit<GridViewProps<ICategorySliderItem>, 'data' | 'renderItem'> {}

const useCategoryPageUtils = () => {
  const navigation = useAppNavigation();
  const {findProgression} = useFindProgression();
  const dispatch = useDispatch();
  const {canSeeContent} = usePaywal();
  const {t} = useTranslation('translation');
  const {t: l} = useTranslation(['learn']);
  const {isMobile} = useBreakpoint();

  const renderCategoryPage = useCallback(
    (
      category: ICategoryChildrenByIdQuery['category'],
      type?: RouteTypename,
      gridProps?: ICategoryGridProps,
      regionId?: string,
    ): ReactNode[] => {
      const {onItemPress, ...restGridProps} = gridProps || {};

      const pageBlocks: ReactNode[] = [];
      const items: ICategorySliderItem[] = getCategoryPageItems(
        category,
        findProgression,
        canSeeContent,
        t,
        l,
        undefined,
        regionId,
      );

      const handleClickItem = (item: ICategorySliderItem) => {
        const {url, title, __typename, id} = item;
        const factory = new RouteFactory(
          {url, __typename, id, source: 'category-page'},
          navigation,
          dispatch,
          title,
        );
        factory.navigate();
      };

      const isNews = type && type === 'NewsCateg';

      if (items.length === 0) {
        pageBlocks.push(
          <ContentEmpty key={'empty'} title={t('No item found')} />,
        );
      } else {
        pageBlocks.push(
          <GridView
            key={'grid'}
            data={items}
            noScroll={true}
            xPaddingWrap={0}
            disableBigItem={isNews}
            renderItem={(item, index?: number | undefined): React.ReactNode => {
              const {title, subtitle, cover, ...rest} = item;
              const modeIndex = (index || 0) % 10;
              const showBig = isMobile
                ? index === 0
                : modeIndex === 0 || modeIndex === 1;
              return (
                <CarouselCard
                  title={title}
                  subtitle={subtitle}
                  cover={cover}
                  disableHover={true}
                  fullWidth={true}
                  size={
                    isNews
                      ? 'news'
                      : showBig
                        ? 'big'
                        : isMobile
                          ? 'small'
                          : 'medium'
                  }
                  premiumText={t('premium')}
                  style={{width: '100%'}}
                  onPress={() => onItemPress?.(item) || handleClickItem?.(item)}
                  {...rest}
                />
              );
            }}
            onItemPress={onItemPress || handleClickItem}
            {...restGridProps}
          />,
        );
      }
      return pageBlocks;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {renderCategoryPage};
};

export default useCategoryPageUtils;
