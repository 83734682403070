/* eslint-disable react-native/no-inline-styles */
import React, {FC, useEffect} from 'react';
import VideoPlayer, {
  PlayerTypeEnum,
} from 'design-system/src/Widget/Player/VideoPlayer/VideoPlayer.web';
import useVideoPlayerLogic, {VideoPlayerParams} from './useVideoPlayerLogic';
import Typography from 'design-system/src/Components/Typography/Typography';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useParams from '@src/Hooks/useParams';
import PaywallChecker from '@src/Umbraco/Components/PaywallChecker/PaywallChecker';
import useViewPort from 'design-system/src/Hooks/useViewPort';
import useMember from '@src/Store/selectors/useMember';
import {EQuality} from '@src/Navigation/Profile/ProfileSetting/ProfileSetting';
import usePaywal from '@src/Hooks/usePaywal';
import useAppLocation from '@src/App/Route/AppRouterContext/useAppLocation';
import {IPaywallFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import PlayerPaywall from '@src/Umbraco/Components/PlayerPaywall/PlayerPaywall';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useAppVideoPlayerStorage from './useAppVideoPlayerStorage';
import {RouteTypename} from '@src/Navigation/type';

const AppVideoPlayer: FC = () => {
  const location = useAppLocation();
  const [videoPlayerArgs, setVideoPlayerArgs] = useAppVideoPlayerStorage();
  const locationParams: any = useParams();

  useEffect(() => {
    if (
      locationParams &&
      ((!!locationParams.liveEventId &&
        locationParams.liveEventId !== videoPlayerArgs.liveEventId) ||
        (!!locationParams.videoId &&
          locationParams.videoId !== videoPlayerArgs.videoId) ||
        (!!locationParams.videoUrl &&
          locationParams.videoUrl !== videoPlayerArgs.videoUrl) ||
        (!!locationParams.sourceUrl &&
          locationParams.sourceUrl !== videoPlayerArgs.sourceUrl))
    ) {
      setVideoPlayerArgs(locationParams);
    }
  }, [locationParams, videoPlayerArgs]);

  const params = {...videoPlayerArgs, ...locationParams};
  const contentType: RouteTypename = params.__typename;
  const paramsSource =
    contentType === 'LiveEvent' || contentType === 'Channel'
      ? 'livestream'
      : params?.source || 'vimeo';

  const member = useMember();
  const {t} = useTranslation('translation');
  const {
    liveEvent,
    isLoading,
    handleOnVideoPlay,
    refCurrentChannel,
    handleOnVideoLoad,
    handleOnGetDuration,
    handleOnBack,
    handleOnPlayEnd,
    handleOnTimeProgress,
    handleOnLanguageChange,
    handleOnSpeedChange,
    handleOnSkipChange,
    onNextEpisode,
    handleOnResolutionChange,
    currentTime,
    displayPaywall,
    paywallSetting,
    content,
    isLive,
    source,
    previewLoading,
  } = useVideoPlayerLogic({
    ...(params || {}),
    paywallData: params?.paywallData || {
      accessType: params?.accessType,
      paywall: params?.paywall,
    },
    source: paramsSource,
  } as VideoPlayerParams);
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();
  const paywallData = params?.paywallData;

  const currentIndex = refCurrentChannel.current || 0;
  const currentSource = liveEvent?.sourceList?.[currentIndex];

  const {viewportHeight} = useViewPort();

  const playerType: PlayerTypeEnum =
    paramsSource === 'livestream' ? 'live' : 'standard';
  const url = (currentSource as any)?.uri || '';
  const poster = (currentSource as any)?.poster || '';
  const accessType =
    (currentSource && currentSource.accessData?.accessType) ||
    paywallData?.accessType;

  const hasAccess: boolean = accessType ? canSeeContent(accessType) : true;
  const canPreview = getPremiumPreviewSettings(
    paywallData?.paywall as IPaywallFragmentFragment,
  )?.canPreview;

  useEffect(() => {
    // update submenu left title
    if (currentSource && location) {
      const title = currentSource?.description;
      location.state = {
        ...(location.state || {}),
        title: title,
        params: {
          ...((location.state as any)?.params || {}),
          title: title,
        },
      };
    }
  }, [currentSource, location]);

  if (!url && !isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: viewportHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography variant="h2">{t('No video found')}</Typography>
      </div>
    );
  }

  if (isLoading || previewLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: viewportHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CustomActivityIndicator />
      </div>
    );
  }

  if (!hasAccess && !canPreview) {
    return (
      <div>
        <PaywallChecker accessType={accessType}>
          <div></div>
        </PaywallChecker>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 49,
      }}>
      <VideoPlayer
        disableCast={!hasAccess}
        paywallContent={
          displayPaywall ? (
            <PlayerPaywall
              isLive={isLive || source === 'livestream'}
              setting={paywallSetting}
              content={content}
            />
          ) : null
        }
        displayPaywall={displayPaywall}
        sourceList={liveEvent?.sourceList || []}
        title={liveEvent?.title || ''}
        onNext={onNextEpisode}
        onGoBack={() => {
          handleOnBack();
        }}
        type={playerType}
        poster={poster}
        currentChannel={refCurrentChannel.current}
        onVideoLoad={handleOnVideoLoad}
        onGetDuration={handleOnGetDuration}
        onPlayEnd={handleOnPlayEnd}
        onTimeProgress={handleOnTimeProgress}
        handleOnLanguageChange={handleOnLanguageChange}
        handleOnSpeedChange={handleOnSpeedChange}
        handleOnSkipChange={handleOnSkipChange}
        handleOnResolutionChange={handleOnResolutionChange}
        initialTime={currentTime}
        onSetCurrentChannel={current => {
          refCurrentChannel.current = current;
        }}
        onVideoPlay={handleOnVideoPlay}
        memberVideoQuality={(member?.videoQuality || EQuality.AUTO) as EQuality}
      />
    </div>
  );
};

export default AppVideoPlayer;
