import { BottomSheetMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import React, { CSSProperties, useEffect, useMemo, useRef, useState, ReactNode } from 'react';
import {
  TextInput,
  Pressable,
  ViewStyle,
  StyleProp,
  Animated,
  View,
} from 'react-native';
// import CustomBottomSheet, {
//   CustomBottomSheetItem,
// } from '../../CustomBottomSheet/CustomBottomSheet';
// import RoundedTextInput from '../../TextInput/RoundedTextInput/RoundedTextInput';
import Typography from 'design-system/src/Components/Typography/Typography';
import Icon from 'design-system/src/Components/Icon/Icon';
import { SelectStyles } from './Select.styles';
import FloatingLabel from '../FloatingLabel/FloatingLabel';
import Dropdown from '../../../WebComponent/Dropdown/Dropdown';
import Option from './Option/Option';
import { _SelectInputWrapper, _SelectRoot } from './Select.styled';

export interface ISelectOption {
  label: string;
  value: string;
}

export interface ISelectProps<V extends string> {
  value?: V;
  onChange?: (value: V, item: ISelectOption) => void;
  label: string;
  pickerLabel?: string;
  options?: ISelectOption[];
  style?: CSSProperties;
  size?: "small" | "medium";
  disabled?: boolean;
}

const BLUR_ANIMATION_DURATION = 180;
const FOCUS_ANIMATION_DURATION = 150;

const Select = <V extends string>(props: ISelectProps<V>) => {
  const {
    label,
    pickerLabel = 'select',
    options = [],
    style,
    value: inValue,
    onChange,
    disabled
  } = props;

  const _animatedLabel = useRef(new Animated.Value(0)).current;
  const sheetRef = useRef<BottomSheetMethods>(null);
  const [value, setValue] = useState<string>(inValue || '');
  const textInputRef = useRef<TextInput>(null);
  useEffect(() => {
    if (inValue !== value) {
      setValue(inValue || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inValue]);

  useEffect(() => {
    if (value /* || focused */) {
      // minimize label
      Animated.timing(_animatedLabel, {
        toValue: 0,
        duration: BLUR_ANIMATION_DURATION * 1,
        useNativeDriver: true,
      }).start();
    } else {
      // restore label
      Animated.timing(_animatedLabel, {
        toValue: 1,
        duration: FOCUS_ANIMATION_DURATION * 1,
        useNativeDriver: true,
      }).start();
    }
  }, [_animatedLabel, value]);

  const handleShowSheet = () => {
    sheetRef.current?.snapToIndex(0);
  };

  // const handleItemClick = (element: CustomBottomSheetItem) => {
  //   setValue(element.item);
  //   onChange &&
  //     onChange(element.item, {
  //       label: element.label,
  //       value: element.item,
  //     });
  //   textInputRef.current?.blur();
  //   sheetRef.current?.close();
  // };

  const selectedLabel: string = useMemo(() => {
    if (value) {
      const find = options.find(el => el.value === value);
      if (find) {
        return find.label;
      }
    }
    return '';
  }, [value, options]);

  // ref
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // states
  const [selectedOption, setSelectedOption] = useState<ISelectOption>();
  const [show, setShow] = useState<boolean>(false);
  const [parentWidth, setParentWidth] =
    useState<CSSProperties["width"]>("auto");

  const inputWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleSelect = (option: ISelectOption) => () => {
    setSelectedOption(option);
    setShow(false);
    onChange && onChange(option.value as V, option);
  };

  const renderSelected = (): ReactNode => {
    return value && !selectedOption
      ? JSON.stringify(value)
      : selectedOption?.label;
  };

  const getWrapperWidth = () => {
    if (inputWrapperRef.current) {
      const rect = inputWrapperRef.current.getBoundingClientRect();
      if (rect.width) {
        setParentWidth(rect.width);
      }
    }
  };

  const handleOnVisibleChange = (visible: boolean) => {
    if (!disabled) {
      setShow(visible);
      getWrapperWidth();
    }
  };

  // ---------- effect -------
  useEffect(() => {
    getWrapperWidth();

    window.addEventListener("resize", getWrapperWidth);
    return () => {
      window.removeEventListener("resize", getWrapperWidth);
    };
  }, []);

  useEffect(() => {
    if (typeof value !== "undefined" && selectedOption?.value !== value) {
      const defaultSelectedOption = options.find(
        (option) => option.value === value
      );
      if (defaultSelectedOption) {
        setSelectedOption(defaultSelectedOption);
      }
    }
  }, [value, selectedOption]);

  return (
    <_SelectRoot style={style} ref={wrapperRef}>
      <Dropdown
        contentStyle={{
          padding: "20px 22px 20px 20px",
          minWidth: parentWidth,
          maxHeight: 150,
        }}
        popupVisible={show}
        trigger="click"
        placement={'bottomLeft'}
        content={options.map((option) => {
          const { value, label } = option;
          return (
            <Option
              key={JSON.stringify(value)}
              value={value}
              onClick={handleSelect(option)}
            >
              <Typography
                style={{
                  fontWeight:
                    selectedOption && selectedOption.value === value
                      ? "bold"
                      : undefined,
                  // 'white-space': "nowrap",
                }}
                // color={() => theme.white}
                variant="body2"
              >
                {label}
              </Typography>
            </Option>
          );
        })}
        onVisibleChange={handleOnVisibleChange}
      >
        <_SelectInputWrapper
          ref={inputWrapperRef}
          onClick={(e: any) => e.stopPropagation()}
        >
          <FloatingLabel
            hasValue={!!selectedLabel}
            hasFocus={false}
            label={label} 
            onPress={() => handleOnVisibleChange(!show)}
          >
            <View style={[SelectStyles.root, {  flex: 1 }]}>
              <Typography variant="body2" style={{ flex: 1 }} >
                {selectedLabel}
              </Typography>
              <Icon name="selectform" size="24" />
            </View>
          </FloatingLabel>
        </_SelectInputWrapper>
      </Dropdown>

      {/* <CustomBottomSheet
        title={label || pickerLabel}
        data={options.map(option => ({
          item: option.value,
          label: option.label,
          isActive: value === option.value,
        }))}
        onItemPress={handleItemClick}
        ref={sheetRef}
      /> */}
    </_SelectRoot>
  );
};

export default Select;
