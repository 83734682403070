import {IFaqItemFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import ProfileLink from 'design-system/src/Components/ProfileLink/ProfileLink.web';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {FC} from 'react';
import {View} from 'react-native';

interface ProfileHelpdeskTopicProps {
  faqs: IFaqItemFragmentFragment[];
  title: string;
  onClickOnTopic: (topic: IFaqItemFragmentFragment) => void;
}

const ProfileHelpdeskTopic: FC<ProfileHelpdeskTopicProps> = ({
  faqs,
  title,
  onClickOnTopic,
}) => {
  return (
    <View
      style={{
        flex: 1,
      }}>
      <Typography
        variant="h3"
        style={{
          marginTop: Metrics.verticalScale(36),
          marginBottom: Metrics.horizontalScale(37),
        }}>
        {title}
      </Typography>
      {faqs.map((d, index) => (
        <ProfileLink
          title={d.title || ''}
          withBorderTop={index === 0}
          key={`b-${index}`}
          onPress={() => {
            // navigation.navigate('ProfileHelpdeskAnswer', {
            //     type: 'answer',
            //     title: d.title || '',
            //     html: d.htmlContent || '<p></p>',
            //     showForm: d.showForm || false,
            // })
            onClickOnTopic(d);
          }}
        />
      ))}
    </View>
  );
};

export default ProfileHelpdeskTopic;
