import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import styled from 'styled-components';

export const _AppHeaderRoot = styled.div(() => {
  return {
    // padding: '40px 60px 13px',
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    top: 0,
    // left: 0,
    zIndex: 10,
    width: '100%',
    // [`@media ${responsiveMap.mobile}`]: {
    //     padding: '40px 16px 13px',
    // },
    height: 0,
    '.logo': {
      position: 'fixed',
      left: 60,
      top: 50,
      transform: 'translateY(-50%)',
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      [`@media ${responsiveMap.mobile}`]: {
        left: 16,
      },
    },
    '.menu-app-header': {
      position: 'fixed',
      zIndex: 10,
      top: 24,
      left: 0,
      width: '100%',
      height: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [`@media ${responsiveMap.mobile}`]: {
        top: 70,
      },
    },
    '.menu-action': {
      position: 'fixed',
      right: 60,
      top: 50,
      transform: 'translateY(-50%)',
      zIndex: 9,
      display: 'inline-flex',
      gap: 24,
      [`@media ${responsiveMap.mobile}`]: {
        right: 16,
      },
    },
    '.sub-menu-app-header': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 12,
      padding: '0 60px',
      position: 'relative',
      [`@media ${responsiveMap.mobile}`]: {
        padding: '0 16px',
        marginTop: 0,
      },
    },
    '.sub-menu-center': {
      display: 'inline-flex',
      height: 20,
      gap: 20,
      alignItems: 'center',
      background: 'rgba(17, 18, 22, 0.50)',
      borderRadius: '8px',
      position: 'relative',
      // boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
      // backdropFilter: 'blur(10px)',
      padding: '20px 10px',
      '&:before': {
        content: '""',
        position: 'absolute',
        borderRadius: '8px',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        backdropFilter: 'blur(10px)',
      },
      [`@media ${responsiveMap.mobile}`]: {
        gap: 14,
        background: 'transparent',
        boxShadow: 'none',
        backdropFilter: 'none',
        padding: '0',
        borderRadius: '0',
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    '.sub-menu-left': {
      position: 'absolute',
      left: 60,
      top: 0,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      gap: 8,
      height: 20,
      background: 'rgba(17, 18, 22, 0.50)',
      borderRadius: '8px',
      boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
      backdropFilter: 'blur(10px)',
      padding: '20px 10px',
      [`@media ${responsiveMap.mobile}`]: {
        left: 0,
        position: 'relative',
        background: 'transparent',
        boxShadow: 'none',
        backdropFilter: 'none',
        padding: '0',
        borderRadius: '0',
        paddingRight: 72,
      },
    },
    '.logo-container': {
      background: 'rgba(17, 18, 22, 0.50)',
      padding: '16px 32px',
      borderRadius: '8px',
      boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
      backdropFilter: 'blur(10px)',
      height: 56,
      cursor: 'pointer',
    },
  };
});
