import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRegionWebFragmentFragment} from '~Api/Graphql/gql/types.generated';

interface RegionState {
  regions: IRegionWebFragmentFragment[];
  activeRegion?: IRegionWebFragmentFragment;
}

const initialState = {
  regions: [],
} as RegionState;

// Then, handle actions in your reducers:
const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setRegions(state, action: PayloadAction<IRegionWebFragmentFragment[]>) {
      state.regions = action.payload;
      return state;
    },
    setActiveRegion(state, action: PayloadAction<IRegionWebFragmentFragment>) {
      state.activeRegion = action.payload;
      return state;
    },
  },
});

export default regionSlice;
