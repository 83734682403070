import React, {PropsWithChildren} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import {bottomNavigationStyles} from './BottomTabNavigation.styles';

type BottomTabNavigationProps = {
  backgroundColor?: string;
  paddingHorizontal?: number;
  style?: StyleProp<ViewStyle>;
};

const BottomTabNavigation: React.FC<
  PropsWithChildren<BottomTabNavigationProps>
> = ({backgroundColor, children, paddingHorizontal, style}) => {
  return (
    <View
      style={[
        {
          ...bottomNavigationStyles.container,
          backgroundColor: backgroundColor ?? defaultTheme.primaryNavBar,
          paddingHorizontal: paddingHorizontal ?? Metrics.horizontalScale(29),
        },
        style,
      ]}>
      {children}
    </View>
  );
};

export default BottomTabNavigation;
