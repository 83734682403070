import {FC, ReactElement, ReactNode, Suspense} from 'react';
import useLoadAfterRequired from './useLoadAfterRequired';
import React from 'react';

interface ISuspenseAfterInteractionProps {
  required?: boolean;
  fallback?: ReactNode;
  children: ReactNode;
}

const SuspenseAfterRequired: FC<ISuspenseAfterInteractionProps> = props => {
  const {fallback, children, required} = props;
  const hasInteraction = useLoadAfterRequired({required});
  return (
    hasInteraction ? <Suspense fallback>{children}</Suspense> : fallback || null
  ) as ReactElement;
};

export default SuspenseAfterRequired;
