import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useEffect, useMemo} from 'react';
import {View, ScrollView} from 'react-native';
import {useGetAllJobVacancyLazyQuery} from '~Api/Graphql/gql/types.generated';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {OpportunitiesData, OpportunityItem} from './components/OpportunityItem';
import {RouteTypename} from '~Navigation/type';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {useAppDispatch} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';

interface ItemData {
  __typename: RouteTypename;
  url: string;
  id: string;
  title?: string;
}
const JobVacancyScreen = () => {
  const {isMobile} = useBreakpoint();
  const [fetchJobVacancies, {loading, data: response}] =
    useGetAllJobVacancyLazyQuery();
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const {t} = useTranslation(['learn']);

  useEffect(() => {
    fetchJobVacancies();
  }, []);

  const jobVancancies = useMemo(() => {
    let _jobVancancies: OpportunitiesData<ItemData>[] = [];
    if (response && response.allJobVacancy && response.allJobVacancy.items) {
      response.allJobVacancy.items.forEach(item => {
        if (item && item.__typename === 'JobVacancy') {
          const {
            title,
            thumbnail,
            partners,
            location,
            contractType,
            certificate,
            shortDescription,
            __typename,
            url,
            id,
            link,
          } = item;
          let _certificates: Array<ItemData> = [];
          certificate?.forEach(item => {
            if (item && item.__typename === 'JobTraining') {
              const {__typename, id, url, title} = item;
              _certificates.push({
                __typename,
                url: url || '',
                id,
                title,
              });
            }
          });
          _jobVancancies.push({
            title: title || '',
            cover: thumbnail?.cropUrl || thumbnail?.url,
            partner:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].partnerName
                : undefined,
            location: location || '',
            contractType: contractType || '',
            certficates: _certificates,
            description: shortDescription || '',
            __typename,
            url,
            id,
            link,
          });
        }
      });
      return _jobVancancies;
    }
    return _jobVancancies;
  }, [response]);

  return (
    <View style={{flex: 1}}>
      <View>
        {loading ? (
          <CustomActivityIndicator style={{flex: 1}} />
        ) : (
          <>
            <div
              style={{
                paddingTop: 138,
                paddingBottom: 95,
                display: 'flex',
                width: '100%',
                justifyContent: isMobile ? 'flex-start' : 'center',
                padding: isMobile ? '120px 16px 30px' : '138px 60px 95px',
              }}>
              <Typography
                style={{
                  fontSize: isMobile ? 32 : 64,
                  lineHeight: isMobile ? 32 : 62,
                }}
                children={t('Job vacancies')}
                variant={'h2'}
              />
            </div>
            <ScrollView
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}>
              <View
                style={{
                  paddingHorizontal: Metrics.horizontalScale(
                    isMobile ? 16 : 60,
                  ),
                  paddingBottom: Metrics.verticalScale(isMobile ? 100 : 20),
                }}>
                <AppGrid nbColumns={isMobile ? 1 : 3} columnGap={12}>
                  {jobVancancies.map((job, index) => {
                    return (
                      <OpportunityItem<ItemData>
                        onPress={() => {
                          const {link, id, __typename} = job;
                          new RouteFactory(
                            {url: link || '', id, __typename},
                            navigation,
                            dispatch,
                          ).navigate();
                        }}
                        key={`${index}-op`}
                        onItemPress={item => {
                          const {url, id, __typename} = item;
                          new RouteFactory(
                            {url: url || '', id, __typename},
                            navigation,
                            dispatch,
                          ).navigate();
                        }}
                        {...job}
                      />
                    );
                  })}
                </AppGrid>
              </View>
            </ScrollView>
          </>
        )}
      </View>
    </View>
  );
};

export default JobVacancyScreen;
