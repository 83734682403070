import {StyleSheet} from 'react-native';
import Metrics, {isWeb, width} from '../../../Theme/Metrics';

export const tabBarHeaderStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: isWeb ? 375 : width,
    alignItems: 'center',
    paddingHorizontal: Metrics.verticalScale(10),
  },
  itemContainer: {display: 'flex', flexDirection: 'row', alignItems: 'center'},
  item: {
    marginHorizontal: Metrics.horizontalScale(9),
    minHeight: Metrics.verticalScale(35),
    justifyContent: 'center',
  },
  icon: {marginLeft: Metrics.horizontalScale(4)},
});
