import {RouteTypename} from '~Navigation/type';
import memberSlice, {WatchedItem} from '~Store/Slices/Member.slice';
import {useCallback} from 'react';
import {useLazyUpdateMemberWatchedQuery} from '~Api/TraceApi/traceApi';
import useMember, {useSelecteAllWatched} from '@src/Store/selectors/useMember';
import {useAppDispatch} from '@src/Store/hooks';

type ContentSource = 'watch' | 'listen' | 'learn';

export const useUpdateProgression = () => {
  const listWatched = useSelecteAllWatched();

  const member = useMember();

  const dispatch = useAppDispatch();

  const [updateMemberWatched] = useLazyUpdateMemberWatchedQuery();

  // const {clearProgress, saveProgress} = useCachedProgress();

  const handleProgression = useCallback(
    (
      id: string,
      watchedTimestamp: number,
      contentSource: ContentSource,
      __typename: RouteTypename,
      currentDuration: number,
      watched?: Record<
        string,
        {
          parentId?: string | undefined;
          time: number;
          length: number;
        }
      >,
      _?: (
        newWatched: Record<
          string,
          {
            parentId?: string | undefined;
            time: number;
            length: number;
          }
        >,
      ) => void,
    ) => {
      if (watchedTimestamp > 0 && id) {
        if (member) {
          // NetInfo.fetch().then(async state => {
          let itemsWatched: Record<
            string,
            {
              parentId?: string | undefined;
              time: number;
              length: number;
            }
          > = watched ?? listWatched;

          // if (state.isInternetReachable) {
          // const time = itemsWatched[id]?.time || 0;
          // const duration = itemsWatched[id]?.length || currentDuration || 1;
          const duration = currentDuration || 1;
          if (watchedTimestamp) {
            // NEED EXPLANATION
            updateMemberWatched({
              id: itemsWatched[id]?.parentId,
              data: {
                watchedTimestamp: watchedTimestamp || 1,
                contentId: id,
                contentSource,
                length: duration,
              },
            })
              .then(({data}) => {
                if (data) {
                  // clearProgress(id).then(() => {
                  let mediaId = data?._id;
                  let contentLink: any = {};
                  contentLink[id] = {
                    parentId: mediaId,
                    time: watchedTimestamp,
                    length: duration,
                    updatedAt: data._updateDate,
                  } as WatchedItem;
                  let newAllWatched: Record<string, WatchedItem> = {
                    ...itemsWatched,
                    ...contentLink,
                  };
                  dispatch(memberSlice.actions.setAllWatched(newAllWatched));
                  // callBack && callBack(newAllWatched);
                  // });
                }
              })
              .catch(error => {
                console.log('error', error.message);
              });
          }
          // }
          /* else {
              if (itemsWatched[id]) {
                let time = itemsWatched[id].time;
                if (time <= watchedTimestamp) {
                  saveProgress(id, watchedTimestamp, itemsWatched[id].length);
                }
              } else {
                saveProgress(id, watchedTimestamp, currentDuration);
              }
            } */
          // });
        }
      }
    },
    [
      // clearProgress,
      dispatch,
      listWatched,
      member,
      // saveProgress,
      updateMemberWatched,
    ],
  );

  return {handleProgression};
};
