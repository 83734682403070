import {StyleSheet} from 'react-native';

export const HeaderStyles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
  },
});
