// import {track} from '@amplitude/analytics-react-native';
// import {IToggleBookmarkParams} from '~Hooks/useUpdateBookmarks';
import {
  Identify,
  Revenue,
  identify,
  revenue,
  track,
} from '@amplitude/analytics-browser';
import {isTrackingEnabled} from '@src/Providers/DidomiInitProvider/isTrackingEnabled';
import {RouteSource, RouteTypename} from '~Navigation/type';
import {
  IContentTracker,
  ILearnPropertyName,
  IPlayerPropertyName,
} from './useTracker';
import {IToggleBookmarkParams} from '~Hooks/useUpdateBookmarks';
import dayjs from 'dayjs';

const parsePrice = (price: string): [number, string] => {
  const regex = /[+-]?\d+(\.\d+)?/g;

  const str = price.split(',').join('.');
  const floats =
    str.match(regex)?.map(function (v) {
      return parseFloat(v);
    }) || [];
  const value = floats[0] || 0;
  return [value, value ? str.replace(value + '', '') : '€'];
};

const learnPropertiesMap = (properties: ILearnPropertyName) => {
  const {
    courseName,
    courseId,
    certificateName,
    certificateId,
    partnerName,
    nuggetReference,
    time,
    nuggetType, // single choice, summary
    nuggetPlace,
    unitName,
    interestName,
    opportunityId,
    unitTimestamp,
  } = properties;
  return {
    course_name: courseName,
    course_id: courseId,
    certificate_name: certificateName,
    certificate_id: certificateId,
    partner_name: partnerName,
    nugget_id: nuggetReference,
    event_time: dayjs(time).format('YYYY-MM-DD HH:mm:ss'),
    nugget_type: nuggetType,
    nugget_place: nuggetPlace,
    unit_name: unitName,
    interest_name: interestName,
    opportunity_id: opportunityId,
    time_since_unit_timestamp: unitTimestamp,
  };
};
const playerPropertiesMap = (properties: IPlayerPropertyName) => {
  const {
    id,
    title,
    typename,
    length,
    category,
    eventTime,
    contentTimestamp,
    contentRegion,
    collectionTitle,
    seasonTitle,
    episodeTitle,
    interestName,
    source,
    originalLanguage,
    originalQuality,
    watched,
    duration,
    newQuality,
    newLanguage,
    restrictedCountries,
    newResolution,
    unitName,
    courseName,
    certificateName,
    partnerName,
    loadTime,
    musicGenre,
    skipNumber,
    backwardsNumber,
    pauseNumber,
    countryOfProduction,
  } = properties;
  return {
    content_category: category,
    content_type: mapContentType(typename),
    content_id: id,
    content_title: title,
    content_length: length,
    event_time: eventTime,
    time_since_content_start_time: dayjs().diff(
      dayjs(contentTimestamp),
      'seconds',
    ),
    culture: contentRegion,
    collection_title: collectionTitle,
    season: seasonTitle,
    episode_title: episodeTitle,
    content_start_time: contentTimestamp
      ? dayjs(contentTimestamp).format('YYYY-MM-DD HH:mm:ss')
      : '',
    interest_name: interestName,
    source_page: source,
    original_language: originalLanguage,
    original_quality: originalQuality,
    ...(watched !== undefined && {percentage_watched: watched}),
    content_event_timestamp: duration,
    new_quality: newQuality,
    new_language: newLanguage,
    new_resolution: newResolution,
    countries_restricted: restrictedCountries,
    unit_name: unitName,
    course_name: courseName,
    certificate_name: certificateName,
    partner_name: partnerName,
    load_time: loadTime || undefined,
    music_genre: musicGenre,
    skip_number: skipNumber,
    number_of_backward_press: backwardsNumber,
    number_of_pause_press: pauseNumber,
    country_of_production: countryOfProduction,
  };
};

const canSendEvent = async (): Promise<boolean> => {
  // return isTrackingEnabled();
  return true;
};

const mapContentProperty = (data: IContentTracker) => {
  const {title, id, categoryName, __typename, countryOfProduction} = data;
  return {
    content_type: mapContentType(__typename),
    content_category: categoryName,
    content_title: title,
    content_id: id,
    country_of_production: countryOfProduction,
  };
};

const mapContentType = (type: any) =>
  trackerTypenameMapper[type as any] || type;

export const trackerTypenameMapper: {
  [_ in any]?: string;
} = {
  News: 'Short',
};

export const tracker = {
  goToPaywall: async (
    properties: IContentTracker & {
      duration: number;
    },
  ) => {
    track('go_to_subscription', {
      ...mapContentProperty(properties),
      duration: properties.duration,
    });
  },
  pageView: (properties: {path: string; title?: string}) => {
    isTrackingEnabled() &&
      track({
        event_type: 'page_opened',
        event_properties: {
          page_name: properties.path,
        },
      });
  },
  contentLiked: async (data: IContentTracker) => {
    // track('App crash', data);
    if (await canSendEvent()) {
      track('content_liked', mapContentProperty(data));
    }
  },
  contentShared: async (data: IContentTracker) => {
    // track('App crash', data);
    if (await canSendEvent()) {
      track('content_shared', mapContentProperty(data));
    }
  },
  learn: {
    certificate: {
      liked: async (properties: ILearnPropertyName) => {
        if (await canSendEvent()) {
          track('bookmark_added_certificate', learnPropertiesMap(properties));
        }
      },
      shared: async (properties: ILearnPropertyName) => {
        if (await canSendEvent()) {
          track('certificate_shared', learnPropertiesMap(properties));
        }
      },
      finished: async (properties: ILearnPropertyName) => {
        if (await canSendEvent()) {
          track('certificate_finished', learnPropertiesMap(properties));
        }
      },
    },
    course: {
      liked: async (properties: ILearnPropertyName) => {
        if (await canSendEvent()) {
          track('course_liked', learnPropertiesMap(properties));
        }
      },
      shared: async (properties: ILearnPropertyName) => {
        if (await canSendEvent()) {
          track('course_shared', learnPropertiesMap(properties));
        }
      },
      finished: async (properties: ILearnPropertyName) => {
        if (await canSendEvent()) {
          track('course_finished', learnPropertiesMap(properties));
        }
      },
    },
    downloadStarted: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track(
          'course_content_download_started',
          learnPropertiesMap(properties),
        );
      }
    },
    downloadFinished: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track(
          'course_content_download_finished',
          learnPropertiesMap(properties),
        );
      }
    },
    downloadCleared: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track(
          'course_content_download_cleared',
          learnPropertiesMap(properties),
        );
      }
    },
    nuggetStarted: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('nugget_started', learnPropertiesMap(properties));
      }
    },
    nuggetError: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('nugget_error', learnPropertiesMap(properties));
      }
    },
    nuggetFinished: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('nugget_finished', learnPropertiesMap(properties));
      }
    },
    unitStarted: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('unit_started', learnPropertiesMap(properties));
      }
    },
    unitFinished: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('unit_finished', learnPropertiesMap(properties));
      }
    },
    courseStarted: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('course_started', learnPropertiesMap(properties));
      }
    },
    allUnitsFinished: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('all_units_finished', learnPropertiesMap(properties));
      }
    },
    courseFinished: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('course_finished', learnPropertiesMap(properties));
      }
    },
    certificateStarted: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('certificate_started', learnPropertiesMap(properties));
      }
    },
    certificateFinished: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('certificate_finished', learnPropertiesMap(properties));
      }
    },
    linkedInAdded: async (properties: ILearnPropertyName) => {
      //TODO
      if (await canSendEvent()) {
        track('linkedin_added', learnPropertiesMap(properties));
      }
    },
    acceptOpportunity: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('opportunity_accepted', learnPropertiesMap(properties));
      }
    },
    declineOpportunity: async (properties: ILearnPropertyName) => {
      if (await canSendEvent()) {
        track('opprtunity_declined', learnPropertiesMap(properties));
      }
    },
    personalityQuizStarted: async (personalityName: string) => {
      if (await canSendEvent()) {
        track('personality_quiz_started', {
          personality_name: personalityName,
        });
      }
    },
    personalityQuizCompleted: async (personalityName: string) => {
      if (await canSendEvent()) {
        track('personality_quiz_completed', {
          personality_name: personalityName,
        });
      }
    },
    personalityQuizResult: async (personalityName: string) => {
      if (await canSendEvent()) {
        track('personality_quiz_results', {
          personality_name: personalityName,
        });
      }
    },
    personalityQuizShared: async (personalityName: string) => {
      // TODO
      if (await canSendEvent()) {
        track('personality_quiz_shared', {
          personality_name: personalityName,
        });
      }
    },
    downloadCertification: async (properties: ILearnPropertyName) => {
      // TODO
      if (await canSendEvent()) {
        track('download_certification', learnPropertiesMap(properties));
      }
    },
    shareJobCertificate: async (properties: ILearnPropertyName) => {
      // TODO
      if (await canSendEvent()) {
        track('share_job_certificate', learnPropertiesMap(properties));
      }
    },
    shareCourseCertificate: async (properties: ILearnPropertyName) => {
      // TODO
      if (await canSendEvent()) {
        track('share_course_certificate', learnPropertiesMap(properties));
      }
    },
  },
  contentClick: (_: {url: string; typename: string}) => {},
  error: async (data: any) => {
    // track('App crash', data);
    if (await canSendEvent()) {
      track('error_encountered', {error_type: 'js error', name: data});
    }
  },
  errorEncountered: async (type: string, name: string) => {
    if (await canSendEvent()) {
      track('error_encountered', {error_type: type, name: name});
    }
  },
  identify: async (
    memberId: string,
    properties: {firstSubscriptionDate?: string; appOpeningChannel?: string},
  ) => {
    if (await canSendEvent()) {
      const _identify = new Identify();
      const keyMapper: Record<keyof typeof properties, string> = {
        firstSubscriptionDate: 'first_subscription_date',
        appOpeningChannel: 'app_opening_channel',
      };

      Object.entries(properties).forEach(([k, value]) => {
        _identify.set(keyMapper[k as keyof typeof properties] || k, value);
      });

      identify(_identify, {
        user_id: memberId,
      });
    }
  },

  general: {
    more: async () => {
      if (await canSendEvent()) {
        track('page_more_opened');
      }
    },
    jobHub: async () => {
      if (await canSendEvent()) {
        track('page_job_hubs_opened');
      }
    },
    jobVacancies: async () => {
      if (await canSendEvent()) {
        track('page_job_vacancies_opened');
      }
    },
    learnHome: async () => {
      if (await canSendEvent()) {
        track('page_learn_opened');
      }
    },
    partner: async () => {
      if (await canSendEvent()) {
        track('page_partners_opened');
      }
    },
    bannerClick: async (properties: {
      section_name: 'Watch' | 'Listen' | 'Learn' | 'Partner' | 'More';
    }) => {
      if (await canSendEvent()) {
        track('menu_clicked', properties);
      }
    },
    watchHome: async () => {
      if (await canSendEvent()) {
        track('page_watch_opened');
      }
    },
    vodHome: async () => {
      if (await canSendEvent()) {
        track('page_on_demand_opened');
      }
    },
    liveTv: async () => {
      if (await canSendEvent()) {
        track('page_live_tv_opened');
      }
    },
    liveEvent: async () => {
      if (await canSendEvent()) {
        track('live_event_opened');
      }
    },
    news: async () => {
      if (await canSendEvent()) {
        track('page_shorts_opened');
      }
    },
    tvGuide: async () => {
      if (await canSendEvent()) {
        track('page_tv_guide_opened');
      }
    },
    listenHome: async () => {
      if (await canSendEvent()) {
        track('page_listen_opened');
      }
    },
    radioHome: async () => {
      if (await canSendEvent()) {
        track('page_radio_opened');
      }
    },
    podcastHome: async () => {
      if (await canSendEvent()) {
        track('podcast_opened');
      }
    },
    async navigation(name: string) {
      if (await canSendEvent()) {
        const parsedName = name.toLowerCase().replace(/\s+/g, '_');
        track(parsedName);
      }
    },
    bookmark: async (
      data: IContentTracker & IToggleBookmarkParams,
      add: boolean = true,
    ) => {
      if ((await canSendEvent()) && data) {
        const {__typename, name, id, categoryName, raw} = data;
        const countryOfProduction =
          raw?.__typename === 'Collection'
            ? raw.countryOfProduction
            : undefined;
        if (name) {
          track(add ? 'bookmark_added' : 'bookmark_removed', {
            certificate_name:
              raw && raw.__typename === 'JobTraining' ? raw.name : '',
            course_name: raw && raw.__typename === 'Course' ? raw.name : '',
            content_title: name,
            content_id: id,
            content_type: mapContentType(__typename),
            content_category: categoryName,
            interest:
              raw &&
              (raw.__typename === 'Channel' ||
                raw.__typename === 'Collection' ||
                raw.__typename === 'News' ||
                raw.__typename === 'Radio' ||
                raw.__typename === 'Playlist' ||
                raw.__typename === 'VODItem' ||
                raw.__typename === 'LiveEvent') &&
              raw.interests
                ? raw.interests.map((item: any) => item?.name).join(',')
                : '',
            music_genre_name:
              raw &&
              (raw.__typename === 'Channel' ||
                raw.__typename === 'Collection' ||
                raw.__typename === 'News' ||
                raw.__typename === 'Radio' ||
                raw.__typename === 'Playlist' ||
                raw.__typename === 'VODItem' ||
                raw.__typename === 'LiveEvent') &&
              raw.musicGenre
                ? raw.musicGenre.map((item: any) => item?.name).join(',')
                : '',
            country_of_production: countryOfProduction,
          });
        }
      }
    },
    loggedOut: async (properties: {account_type: 'email' | string}) => {
      if (await canSendEvent()) {
        track('logged_out', {account_type: properties.account_type});
      }
    },
    loggedIn: async (properties: {account_type: 'email' | string}) => {
      if (await canSendEvent()) {
        track('logged_in', {account_type: properties.account_type});
      }
    },
    actionOnNotification: async (
      type: 'open' | 'remove',
      properties: {__typename: RouteTypename},
    ) => {
      if (await canSendEvent()) {
        const {__typename} = properties;
        let category: string = '';
        if (__typename === 'Collection') {
          category = 'New season';
        } else if (__typename === 'VODItem') {
          category = 'New episode';
        } else if (__typename === 'LiveEvent') {
          category = 'New event';
        }

        if (type === 'open') {
          track('notification_opened', {
            category: category,
          });
        } else if (type === 'remove') {
          track('notification_deleted', {
            category: category,
          });
        }
      }
    },
    async searchLaunched() {
      if (await canSendEvent()) {
        // track('Research launched');
      }
    },
    async searchCompleted(properties: {
      number_of_search_results: number;
      keywords: string;
    }) {
      if (await canSendEvent()) {
        track('search_started', {
          number_of_search_results: properties.number_of_search_results,
          keywords: properties.keywords,
        });
      }
    },
    async searchResultClick(properties: {
      search_rank: number;
      content_id: string;
      content_type: string;
      content_category: string;
      content_title: string;
      genre?: string;
      countryOfProduction?: Array<string | undefined>;
    }) {
      if (await canSendEvent()) {
        // track('Research result launched');
        track('search_result_clicked', {
          search_rank: properties.search_rank,
          content_id: properties.content_id,
          content_type: mapContentType(properties.content_type),
          genre: properties.genre,
          content_category: properties.content_category,
          content_title: properties.content_title,
          country_of_production: properties.countryOfProduction,
        });
      }
    },
    async contentCategoryViewed(properties: {
      __typename: RouteTypename;
      source?: string;
      // TODO
      countryOfProduction?: string;
    }) {
      if (await canSendEvent()) {
        const {__typename, countryOfProduction, ...rest} = properties;
        let content_type = '';
        switch (__typename) {
          case 'WatchCateg':
          case 'NewsCateg':
            content_type = 'watch';
            break;
          case 'ListenCateg':
          case 'RadioCateg':
          case 'PodcastCateg':
            content_type = 'listen';
            break;

          default:
            break;
        }
        track('content_category_viewed', {
          content_type: mapContentType(content_type),
          source: rest.source,
          country_of_production: countryOfProduction,
        });
      }
    },
    async contentGenreViewed(properties: {
      content_type: RouteTypename;
      genre?: string;
    }) {
      if (await canSendEvent()) {
        // remove
        // track('Content genre viewed', {
        //   'content type': mapContentType(properties.content_type),
        //   genre: properties.genre,
        // });
      }
    },
    async contentDetailPageViewed(properties: {
      content_type: RouteTypename;
      genre: string;
      content_title: string;
      content_category?: string;
      content_id: string;
      source: RouteSource;
      countryOfProduction?: Array<string | undefined>;
    }) {
      if (await canSendEvent()) {
        track('content_detail_page_viewed', {
          content_type: mapContentType(properties.content_type),
          content_title: properties.content_title,
          content_id: properties.content_id,
          content_category: properties.genre,
          source: properties.source,
          country_of_production: properties.countryOfProduction,
        });
      }
    },
    // contentShare(properties: {
    //   content_title: string;
    //   content_id: string;
    //   content_category: string;
    //   content_type: RouteTypename;
    //   episode?: string;
    // }) {
    //   track('content shared', properties);
    // },
    async cTAClicked(properties: {
      type: string;
      name: string;
      section?:
        | 'watch'
        | 'learn'
        | 'listen'
        | 'subscription'
        | 'profile'
        | 'onboarding'
        | string;
      page: string;
      blockType: string;
      blockName: string;
    }) {
      if (await canSendEvent()) {
        track('cta_clicked', {
          cta_type: mapContentType(properties.type),
          cta_name: properties.name,
          cta_section: properties.section,
          cta_page: properties.page,
          cta_block_type: properties.blockType,
          cta_block_name: properties.blockName,
        });
      }
    },
  },

  signup: {
    async createAccountStarted(properties: {type: string}) {
      if (await canSendEvent()) {
        const {type} = properties;
        track('create_account_started', {
          account_type: type,
        });
      }
    },
    async accountInfoSubmitted() {
      if (await canSendEvent()) {
        track('account_info_submitted');
      }
    },
    async accountVerified() {
      if (await canSendEvent()) {
        track('account_verified');
      }
    },
    async accountCreated() {
      if (await canSendEvent()) {
        track('account_created');
      }
    },
    async accountUpdated() {
      if (await canSendEvent()) {
        track('account_updated');
      }
    },
  },
  subscription: {
    renewed: async (properties: {
      planType: string;
      startDate: string;
      endDate: string;
      term: string;
      $revenu: string;
    }) => {
      if (await canSendEvent()) {
        const [$revenu, currency] = parsePrice(properties.$revenu);
        const eventPropertie = {
          // ...properties,
          current_subscription_start_date: properties.startDate,
          current_subscription_end_date: properties.endDate,
          subscription_term: properties.term,
          plan_type: properties.planType,
          local_currency: currency,
          // $revenue: $revenu,
          revenue: properties.$revenu,
        };
        track('subscription_renewed', eventPropertie);
        const event = new Revenue()
          .setProductId(properties.planType)
          .setRevenue($revenu)
          .setEventProperties({...eventPropertie});
        revenue(event);
      }
    },
    cancelled: async (properties: any) => {
      if (await canSendEvent()) {
        track('subscription_cancelled', properties);
      }
    },
    paused: async (properties: any) => {
      if (await canSendEvent()) {
        track('subscription_paused', properties);
      }
    },
    resumed: async (properties: any) => {
      if (await canSendEvent()) {
        track('subscription_resumed', properties);
      }
    },
    upgraded: async (properties: {
      currentPlanType: string;
      newPlanType: string;
      startDate: string;
      endDate: string;
      term: string;
      newTerm: string;
      revenue: string;
    }) => {
      if (await canSendEvent()) {
        const [$revenu, currency] = parsePrice(properties.revenue);
        const eventProperties = {
          date_subscription_start: properties.startDate,
          date_subscription_end: properties.endDate,
          subscription_term: properties.term,
          new_subscription_term: properties.newTerm,
          previous_subscription_term: properties.term,
          local_currency: currency,
          // $revenu: $revenu,
          revenu: properties.revenue,
        };
        track('subscription_term_updated', eventProperties);
        const event = new Revenue()
          .setProductId(properties.newPlanType)
          .setRevenue($revenu)
          .setEventProperties({...eventProperties});
        revenue(event);
      }
    },
    started: async (properties: {
      planType: string;
      startDate: string;
      endDate: string;
      term: string;
      $revenue: string;
    }) => {
      if (await canSendEvent()) {
        const [$revenu, currency] = parsePrice(properties.$revenue);
        const eventProperties = {
          date_subscription_start: properties.startDate,
          date_subscription_end: properties.endDate,
          subscription_term: properties.term,
          new_subscription_term: properties.term,
          plan_type: properties.planType,
          local_currency: currency,
          // $revenue: $revenu,
          revenu: properties.$revenue,
        };
        track('subscription_started', eventProperties);
        const event = new Revenue()
          .setProductId(properties.planType)
          .setRevenue($revenu)
          .setEventProperties({...eventProperties});
        revenue(event);
      }
    },
  },
  player: {
    started: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if ((await canSendEvent()) && properties.id) {
        track('content_started', playerPropertiesMap(properties));
      }
    },
    stopped: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if ((await canSendEvent()) && properties.id) {
        track('content_ended', playerPropertiesMap(properties));
      }
    },
    skipe: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if ((await canSendEvent()) && properties.id) {
        track('15s_skip', playerPropertiesMap(properties));
      }
    },
    rewind: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if ((await canSendEvent()) && properties.id) {
        track('15s_back', playerPropertiesMap(properties));
      }
    },
    ended: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if ((await canSendEvent()) && properties.id) {
        track('content_ended', playerPropertiesMap(properties));
      }
    },
    finished: async <T extends any>(properties: IPlayerPropertyName & T) => {
      // if (await canSendEvent()) {
      //   track('finished', playerPropertiesMap(properties));
      // }
    },
    paused: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if ((await canSendEvent()) && properties.id) {
        track('content_paused', playerPropertiesMap(properties));
      }
    },
    changeResolution: async <T extends any>(
      properties: IPlayerPropertyName & T,
    ) => {
      if ((await canSendEvent()) && properties.id) {
        track('content_resolution_changed', playerPropertiesMap(properties));
      }
    },
    changeLanguage: async <T extends any>(
      properties: IPlayerPropertyName & T,
    ) => {
      if (await canSendEvent()) {
        track('content_language_changed', playerPropertiesMap(properties));
      }
    },
    accelerated: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if (await canSendEvent()) {
        track('content_accelerated', playerPropertiesMap(properties));
      }
    },
    slowed: async <T extends any>(properties: IPlayerPropertyName & T) => {
      if (await canSendEvent()) {
        track('content_slowed', playerPropertiesMap(properties));
      }
    },
    loadTime: async <T extends any>(properties: IPlayerPropertyName & T) => {
      // removed
      // if (await canSendEvent()) {
      //   track('Content load time', playerPropertiesMap(properties));
      // }
    },
  },

  form: {
    formStepReached: async (properties: {
      id: string;
      name: string;
      step: number;
    }) => {
      if (await canSendEvent()) {
        const {id, name, step} = properties;
        track('form_step_reached', {
          form_reference: id,
          form_name: name,
          step_number: step,
        });
      }
    },
  },
  discover: {
    discoverSlideOpened: async (data: IContentTracker & {index: number}) => {
      if (await canSendEvent()) {
        const {__typename, name, title, index, countryOfProduction} = data;
        track('discover_slide_opened', {
          content_type: mapContentType(__typename),
          content_name: name || title,
          slide_number: index + 1,
          country_of_production: countryOfProduction,
        });
      }
    },
    discoverSlideClicked: async (data: IContentTracker & {index: number}) => {
      if (await canSendEvent()) {
        const {__typename, name, title, index, countryOfProduction} = data;
        track('discover_slide_clicked', {
          content_type: mapContentType(__typename),
          content_name: name || title,
          slide_number: index + 1,
          country_of_production: countryOfProduction,
        });
      }
    },
    discoverSlideLiked: async (data: IContentTracker, add?: boolean) => {
      if (await canSendEvent()) {
        const {__typename, name, title, countryOfProduction} = data;
        if (add) {
          track('discover_slide_liked', {
            content_type: mapContentType(__typename),
            content_name: name || title,
            country_of_production: countryOfProduction,
          });
        }
      }
    },
    discoverSlideShare: async (data: IContentTracker) => {
      if (await canSendEvent()) {
        const {__typename, name, title, countryOfProduction} = data;
        track('discover_slide_shared', {
          content_type: mapContentType(__typename),
          content_name: name || title,
          country_of_production: countryOfProduction,
        });
      }
    },
  },
  onboarding: {
    async interestSelected(data: {name: string; numberOfInterest: string}) {
      if (await canSendEvent()) {
        const {name, numberOfInterest} = data;
        track('interest_selected', {
          interest_name: name,
          number_of_interests: numberOfInterest,
        });
      }
    },
  },
};
