import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import React, {ReactNode, useEffect, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import PushQuizCard from 'design-system/src/Components/Card/PushQuizCard/PushQuizCard';
import Metrics from 'design-system/src/Theme/Metrics';
import {useQuizPageByIdQuery} from '~Api/Graphql/gql/types.generated';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useLazyGetAllMemberPersonalityTypeQuery} from '@src/Api/TraceApi/traceApi';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

export default function HomeQuizPage() {
  const params = useParams();

  const {navigate} = useAppNavigation();

  const {id} = params || {};
  const response = useQuizPageByIdQuery({
    variables: {
      id,
    },
  });

  const [fetchUserPersonality, {data: personalData, isLoading}] =
    useLazyGetAllMemberPersonalityTypeQuery();

  useEffect(() => {
    fetchUserPersonality();
  }, []);

  const loading = response.loading || isLoading;

  const {isMobile} = useBreakpoint();
  const pageContent = useMemo(() => {
    const {data} = response;
    let pageBlocks: ReactNode[] = [];
    if (data && data.quizPage && data.quizPage.__typename === 'QuizPage') {
      const {
        title,
        subtitle,
        cover,
        ctaLabel,
        id,
        url,
        personalitiesPush,
        methodologyPush,
      } = data.quizPage;
      pageBlocks.push(
        <ServiceHeader
          key="q-header"
          title={title || ''}
          gradiantVariant="bottom"
          description={subtitle || ''}
          buttonTitle={ctaLabel || ''}
          image={{
            uri:
              cover &&
              cover[0] &&
              cover[0].content &&
              cover[0].content.__typename === 'HeroVideoCover'
                ? cover[0].content.image?.url
                : undefined,
          }}
          onPressButton={() => {
            if (!!personalData && personalData.length) {
              navigate(AppRoutesEnum.QUIZ_RESULT, {
                state: {
                  params: {
                    id,
                    url,
                    quizId: '',
                    answers: personalData,
                    result: [],
                  },
                },
              });
              return;
            }
            navigate(AppRoutesEnum.QUIZ_QUESTION, {
              state: {
                params: {
                  id,
                  url,
                },
              },
            });
          }}
        />,
      );
      let _outputContent: ReactNode[] = [];
      if (
        personalitiesPush &&
        personalitiesPush[0] &&
        personalitiesPush[0].content &&
        personalitiesPush[0].content.__typename === 'PersonalityPush'
      ) {
        const {title, description, icon} = personalitiesPush[0].content;
        _outputContent.push(
          <View
            key={`perso-push`}
            style={{marginBottom: Metrics.verticalScale(8)}}>
            <PushQuizCard
              title={title || ''}
              description={description || ''}
              variant="blur"
              icon={() => (
                <CustomFastImage
                  backgroundFallbackColor="transparent"
                  width={Metrics.horizontalScale(72)}
                  height={Metrics.horizontalScale(72)}
                  source={{
                    uri: icon?.url,
                  }}
                />
              )}
              onPressArrowIc={() => {
                navigate(AppRoutesEnum.QUIZ_PERSONALITY, {
                  state: {
                    params: {
                      id,
                      url,
                    },
                  },
                });
              }}
              descTypo="ui3"
            />
          </View>,
        );
      }
      if (
        methodologyPush &&
        methodologyPush[0] &&
        methodologyPush[0].content &&
        methodologyPush[0].content.__typename === 'PersonalityPush'
      ) {
        const {title, description, icon} = methodologyPush[0].content;
        _outputContent.push(
          <View
            key={`metho-push`}
            style={{marginBottom: Metrics.verticalScale(8)}}>
            <PushQuizCard
              title={title || ''}
              description={description || ''}
              variant="blur"
              icon={() => (
                <CustomFastImage
                  backgroundFallbackColor="transparent"
                  width={Metrics.horizontalScale(72)}
                  height={Metrics.horizontalScale(72)}
                  source={{
                    uri: icon?.url,
                  }}
                />
              )}
              onPressArrowIc={() => {
                navigate(AppRoutesEnum.QUIZ_METHODOLOGY, {
                  state: {
                    params: {
                      id,
                      url,
                    },
                  },
                });
              }}
              descTypo="ui3"
            />
          </View>,
        );
      }
      _outputContent.length &&
        pageBlocks.push(
          <div
            key={'output'}
            style={{
              marginLeft: Metrics.horizontalScale(12),
              marginRight: Metrics.horizontalScale(12),
              marginTop: Metrics.verticalScale(35),
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: Metrics.horizontalScale(12),
              justifyContent: 'center',
            }}>
            {_outputContent}
          </div>,
        );
    }
    return pageBlocks;
  }, [response, isMobile, personalData]);
  const {t} = useTranslation(['learn']);

  return (
    <div>
      <DebugUmbracoId umbracoId={id} position="page" />
      {id ? (
        loading ? (
          <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
        ) : (
          pageContent
        )
      ) : (
        <ContentEmpty
          style={{flex: 1, justifyContent: 'center'}}
          title={t('Quiz page not found')}
        />
      )}
    </div>
  );
}
