import React from 'react';
import {
  StyleSheet,
  Pressable,
  PressableProps,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Images from '../../../../assets/Images';
import ChannelLogo from '../../../ChannelLogo/ChannelLogo';
import {
  gradChannelSize,
  gradientChannelCardStyles,
  gradVariantStyle,
} from './GradientChannelCard.styles';

export interface GradientChannelCardProps extends PressableProps {
  variant: keyof typeof gradVariantStyle;
  logo: keyof typeof Images.channel;
  size?: keyof typeof gradChannelSize;
}

const GradientChannelCard = (props: GradientChannelCardProps) => {
  const {variant, logo, size = 'large', ...restProps} = props;
  const variantStyle = gradVariantStyle[variant];
  const sizeStyle = gradChannelSize[size];

  return (
    <Pressable {...restProps}>
      <LinearGradient
        colors={[variantStyle.firstColor, variantStyle.secondColor]}
        locations={[0, 1]}
        style={StyleSheet.flatten([
          gradientChannelCardStyles.container,
          sizeStyle,
        ])}>
        <ChannelLogo source={Images.channel[logo]} />
      </LinearGradient>
    </Pressable>
  );
};

export default GradientChannelCard;
