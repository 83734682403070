import React from 'react';
import {View} from 'react-native';
import GenericCard from '../../GenericCard/GenericCard';
import RowCard from '../../RowCard/RowCard';
import {VodCardProps} from '../VodCardProps';
import IconRoundPlaySvg from '../../../../assets/svg/ic_round_play.svg';
import IcSettingSvg from '../../../../assets/svg/setting-icon.svg';
import VodCardAside from '../VodCardAside/VodCardAside';

const DownloadVodCard = (props: VodCardProps) => {
  return (
    <RowCard
      left={
        <GenericCard
          coverImage={props.cover}
          onPress={props.onPressPlayIcon}
          type={'xsmall'}
          center={
            <View>
              <IconRoundPlaySvg />
            </View>
          }
        />
      }
      right={<VodCardAside asideProps={props} TrailingIc={IcSettingSvg} />}
    />
  );
};

export default DownloadVodCard;
