import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const primaryRounded = {
  borderRadius: Metrics.moderateScale(180),
  paddingHorizontal: Metrics.horizontalScale(32),
  paddingVertical: Metrics.verticalScale(17),
};

export const secondaryRounded = {
  borderRadius: Metrics.moderateScale(48),
  paddingHorizontal: Metrics.horizontalScale(24),
  paddingVertical: Metrics.verticalScale(8),
};

export const buttonSizeStyles = StyleSheet.create({
  large: {
    borderRadius: Metrics.moderateScale(180),
    paddingHorizontal: Metrics.horizontalScale(32),
    paddingVertical: Metrics.verticalScale(19),
  },
  medium: {
    borderRadius: Metrics.moderateScale(180),
    paddingHorizontal: Metrics.horizontalScale(32),
    paddingVertical: Metrics.verticalScale(15),
  },
  small: {
    borderRadius: Metrics.moderateScale(48),
    paddingHorizontal: Metrics.horizontalScale(24),
    paddingVertical: Metrics.verticalScale(11),
  },
});

export const roundedButtonStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    flexGrow: 0,
    borderWidth: 1,
  },
  buttonContainer: {
    alignItems: 'flex-start',
    flex: 1,
  },
});
