import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const DiscoverActionButtonStyles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  count: {
    marginTop: Metrics.horizontalScale(4),
  },
});
