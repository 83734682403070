import React, {Suspense} from 'react';
import {IPageData} from '@src/Store/selectors/useRegionUrl';
import {ContentSource} from '@src/Umbraco/Components/ContextualContentSlider/ContextualContentSlider';

const ArtistServicesPage = React.lazy(
  () => import('@src/Umbraco/Pages/ArtistServicesPage/ArtistServicesPage'),
);
const SectionPage = React.lazy(
  () => import('@src/Umbraco/Pages/SectionPage/SectionPage'),
);
const ThankYouPage = React.lazy(
  () => import('@src/Umbraco/Pages/ThankYouPage/ThankYouPage'),
);

const AppDynamicHomePage = (
  props: IPageData & {contentSource?: ContentSource},
) => {
  const {id: contentId, __typename: contentType, contentSource} = props;

  if (contentType === 'SectionPage') {
    return (
      <div>
        <Suspense>
          <SectionPage
            id={contentId}
            contentSource={contentSource || 'watch'}
          />
        </Suspense>
      </div>
    );
  } else if (contentType === 'ThankYouPage') {
    return (
      <div>
        <Suspense>
          <ThankYouPage id={contentId} fullScreen={true} />
        </Suspense>
      </div>
    );
  } else if (contentType === 'ArtistServicesPage') {
    return (
      <div>
        <Suspense>
          <ArtistServicesPage id={contentId} />
        </Suspense>
      </div>
    );
  }

  return (
    <div>
      <Suspense>
        <SectionPage contentSource="watch" />
      </Suspense>
    </div>
  );
};

export default AppDynamicHomePage;
