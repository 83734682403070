import {FlatList, View, FlatListProps} from 'react-native';
import React from 'react';
import {IChannelFragmentFragment} from '~Api/Graphql/gql/types.generated';
import LiveTvItem from './LiveTvItem/LiveTvItem';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {liveTvScreenStyles} from '../LiveTvScreen.styles';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import CategoryTagFilter, {
  ICategoryTagFilterProps,
} from '@src/App/components/Common/CategoryTagFilter/CategoryTagFilter';

interface ILiveTvListProps {
  list: IChannelFragmentFragment[];
  loading?: boolean;
  tagFilter?: ICategoryTagFilterProps;
  isMobile?: boolean;
}
const renderItem: FlatListProps<IChannelFragmentFragment>['renderItem'] = ({
  item,
  index,
}) => {
  return <LiveTvItem key={'channel' + index} channel={item} />;
};

const keyExtractor: FlatListProps<IChannelFragmentFragment>['keyExtractor'] =
  item => `tvlist-item-${item?.id}`;

const LiveTvList = (props: ILiveTvListProps) => {
  const {list, loading, tagFilter, isMobile} = props;
  const {t} = useTranslation('translation');

  if (loading) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CustomActivityIndicator />
      </View>
    );
  }
  return (
    <View style={{flex: 1}}>
      <CategoryTagFilter {...tagFilter} />
      <FlatList
        style={[liveTvScreenStyles.scrollContainer, {flexGrow: 1}]}
        numColumns={isMobile ? 1 : 2}
        columnWrapperStyle={isMobile ? undefined : {gap: 96}}
        horizontal={false}
        initialNumToRender={4}
        data={list}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        maxToRenderPerBatch={5}
        ListEmptyComponent={<ContentEmpty title={t('No data')} />}
      />
    </View>
  );
};

export default React.memo(LiveTvList);
