import React, {useState, useCallback} from 'react';
import {Platform, StyleProp, View, ViewStyle} from 'react-native';
import {IconName} from '../../../assets/Icones';
import SubscribeCard from '../../../Components/Card/SubscribeCard/SubscribeCard';
import Metrics from '../../../Theme/Metrics';
import {choosePlanStyles} from './payWallChoosePlan.styles';
import {IMediaCover} from '../../../Components/Card/CarouselCard/CarouselCard';
import {CustomFastImageProps} from '../../../Components/CustomFastImage/CustomFastImageProps';
import CustomFastImage from '../../../Components/CustomFastImage/CustomFastImage';

export interface IItemPlan {
  subscriptionId: string;
  title: string;
  subtitle: string;
  price: string;
  period: 'm' | 'y' | 'w' | 'free' | 'more' | (string & {});
  icon: CustomFastImageProps['source'];
  percentageSave?: string;
  description: string;
  planId: string;
  offerToken?: string;
  successId: string;
  thankYouTitle: string;
  thankYouBody: string;
  thankYouCover?: IMediaCover;
  tagColor?: string;
  disabled?: boolean;
  isCurrent?: boolean;
  subscriptionLink?: string;
}

export const periodIcon: Record<IItemPlan['period'], IconName> = {
  m: 'months',
  w: 'year',
  y: 'year',
  free: 'free',
  more: 'more',
};

export interface PayWallChoosePlanProps {
  title: string;
  freeButtonTitle: string;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
  thirdButtonTitle: string;
  items: Array<IItemPlan>;
  onItemPress?: (item: IItemPlan) => void;
  onCancel?: () => void;
  onConfirm?: (item: IItemPlan) => void;
  onMore?: () => void;
  variant?: 'default' | 'paywall';
  horizontal?: boolean;
  contentWrapperStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
  orientation?: 'portrait' | 'landscape';
}

const PayWallChoosePlan = (props: PayWallChoosePlanProps) => {
  const {
    title,
    freeButtonTitle,
    primaryButtonTitle,
    secondaryButtonTitle,
    thirdButtonTitle,
    items,
    onCancel,
    onConfirm,
    onItemPress,
    onMore,
    variant = 'default',
    horizontal,
    contentWrapperStyle,
    loading,
    orientation = 'portrait',
  } = props;

  const isLandscape = orientation === 'landscape';
  const isWeb = Platform.OS === 'web';

  const [selectedPlan, setSelectedPlan] = useState<IItemPlan>();

  const handleConfirm = useCallback(() => {
    if (selectedPlan && !selectedPlan.disabled && onConfirm) {
      onConfirm(selectedPlan);
    }
  }, [selectedPlan, onConfirm]);

  const handleItemPress = useCallback(
    (item: IItemPlan) => {
      setSelectedPlan(item);
      if (onItemPress) {
        onItemPress(item);
      }
    },
    [onItemPress],
  );

  const itemStyle = isWeb
    ? {
        width: '100%',
        marginBottom: Metrics.verticalScale(16),
      }
    : {
        marginBottom: Metrics.verticalScale(16),
        width: isLandscape ? Metrics.horizontalScale(226) : '100%',
      };

  return (
    <View
      style={[
        choosePlanStyles.container,
        contentWrapperStyle,
        isLandscape && {
          flexDirection: 'row',
        },
        // @ts-ignore
        isWeb &&
          isLandscape && {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            columnGap: 100,
            maxWidth: 1295,
          },
      ]}>
      {/* <View style={choosePlanStyles.title}>
        <Typography children={title} variant="h3" />
        {onMore && (
          <TouchableOpacity onPress={onMore}>
            <Typography
              children={thirdButtonTitle}
              color={'primary'}
              variant="button3"
            />
          </TouchableOpacity>
        )}
      </View> */}
      {items.map((item, index) => {
        const {icon} = item;
        return (
          <View key={index} style={itemStyle}>
            <SubscribeCard
              variant={isLandscape ? 'center' : 'default'}
              loading={loading}
              title={item.title}
              description={item.subtitle}
              price={item.price}
              period={item.period}
              isActive={
                item.planId === selectedPlan?.planId ||
                (item.isCurrent && !selectedPlan)
              }
              icon={
                !icon
                  ? periodIcon[item.period]
                  : () => (
                      <CustomFastImage
                        width={isLandscape ? 48 : Metrics.horizontalScale(32)}
                        height={isLandscape ? 48 : Metrics.horizontalScale(32)}
                        source={icon}
                        backgroundFallbackColor="transparent"
                      />
                    )
              }
              percentageSave={item.percentageSave}
              onPress={() => handleItemPress(item)}
              onConfirm={() => {
                handleItemPress(item);
                handleConfirm();
              }}
              tagColor={item.tagColor}
              confirmButtonTitle={
                item.period === 'free' ? freeButtonTitle : primaryButtonTitle
              }
            />
          </View>
        );
      })}
    </View>
  );
};

export default PayWallChoosePlan;
