import React, {FC} from 'react';
import FooterItem, {IFooterItemProps} from './FooterItem/FooterItem';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useNavigation from '@src/Navigation/useNavigation';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Typography from 'design-system/src/Components/Typography/Typography';
import {Pressable} from 'react-native';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {darkTheme} from 'design-system/src/styles/Themes';
import GridItem from './GridItem';
import {IconName} from 'design-system/src/assets/Icones';
import Icon from 'design-system/src/Components/Icon/Icon';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import {getMobileOperatingSystem} from '@src/Umbraco/Utils/common';
import PressableCustom from '../PressableCustom/PressableCustom';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';

export interface IPageFooterProps {
  logo?: string;
  description?: string;
  menus?: IFooterItemProps[];
  legals?: ILegalFooter[];
  guestBurgerMenu?: ILegalFooter[];
  socialMedias?: ISocialMediaFooter[];
  iOSRedirectUrl?: string | null;
  androidRedirectURL?: string | null;
  huaweiRedirectUrl?: string | null;
}

export interface ILegalFooter {
  title?: string;
  onClick?: () => void;
}

export interface ISocialMediaFooter {
  title?: string;
  icon?: string;
  onClick?: () => void;
}

const PageFooter: FC<IPageFooterProps> = props => {
  const {
    menus,
    legals,
    description,
    logo,
    socialMedias,
    androidRedirectURL,
    iOSRedirectUrl,
    huaweiRedirectUrl,
  } = props;
  const {isMobile} = useBreakpoint();
  const {t} = useTranslation('translation');
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const osPlatform = getMobileOperatingSystem();

  const tracker = useTracker();

  return (
    <div
      style={{
        marginTop: 54,
        padding: '0 16px',
      }}>
      <Hr />
      <div
        style={{
          paddingTop: 32,
          paddingBottom: isMobile ? 0 : 77,
        }}>
        <div
          style={{
            backgroundImage: `url(${logo})`,
            width: 91,
            height: 16,
            backgroundRepeat: 'round',
          }}
        />
        <div
          style={{
            marginTop: 24,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
          }}>
          {!!description && (
            <GridItem flex={2}>
              <div style={{width: 281}}>
                <Typography
                  variant="body2"
                  style={{color: darkTheme.colors.fontGray}}>
                  {description}
                </Typography>
              </div>
            </GridItem>
          )}

          {menus?.map((item, index) => (
            <GridItem key={index} flex={1}>
              <FooterItem key={index} title={item.title} list={item.list} />
            </GridItem>
          ))}
        </div>
      </div>
      <Hr />
      <div
        style={{
          padding: '18px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div
          style={{
            display: 'inline-flex',
            gap: 32,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
          {socialMedias?.map((item, index) => (
            <Pressable key={index} onPress={item.onClick}>
              {!!item.icon && (
                <Icon name={item.icon?.toLowerCase() as IconName} />
              )}
            </Pressable>
          ))}
        </div>
        <Typography
          variant="ui2"
          style={{display: isMobile ? 'none' : undefined}}>
          © Trace+
        </Typography>
      </div>
      <Hr />
      <div
        style={{
          padding: isMobile ? 0 : '28px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
        }}>
        <div
          style={{
            display: 'inline-flex',
            gap: isMobile ? 6 : 24,
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : undefined,
            width: isMobile ? '100%' : undefined,
          }}>
          {legals?.map((item, index) => (
            <PressableCustom key={index} onPress={item.onClick}>
              {({hover}) => (
                <Typography
                  variant="ui3"
                  style={{
                    color: hover
                      ? darkTheme.colors.fifthGray
                      : darkTheme.colors.fontGray,
                  }}>
                  {item?.title}
                </Typography>
              )}
            </PressableCustom>
          ))}
        </div>
        {/* // TODO icon google play and app store */}
        <div
          style={{
            display: 'inline-flex',
            gap: 30,
            marginTop: isMobile ? 36 : 0,
            width: isMobile ? '100%' : undefined,
          }}>
          {osPlatform != 'Android' && osPlatform != 'HarmonyOS' && (
            <Pressable
              onPress={() => {
                tracker.general.cTAClicked({
                  type: 'button',
                  name: 'App Store',
                  page: 'footer',
                  blockType: 'button',
                  blockName: 'App Store',
                });
                new RouteFactory(
                  {
                    id: '',
                    __typename: 'ExternalLink',
                    url: iOSRedirectUrl || '',
                  },
                  navigation,
                  dispatch,
                ).navigate();
              }}>
              <Icon name="app-store" />
            </Pressable>
          )}
          {osPlatform != 'Android' && osPlatform != 'iOS' && (
            <Pressable
              onPress={() => {
                tracker.general.cTAClicked({
                  type: 'button',
                  name: 'Huawei App Gallery',
                  page: 'footer',
                  blockType: 'button',
                  blockName: 'Huawei App Gallery',
                });
                new RouteFactory(
                  {
                    id: '',
                    __typename: 'ExternalLink',
                    url: huaweiRedirectUrl || '',
                  },
                  navigation,
                  dispatch,
                ).navigate();
              }}>
              <Icon name="huawei" />
            </Pressable>
          )}
          {osPlatform != 'HarmonyOS' && osPlatform != 'iOS' && (
            <Pressable
              onPress={() => {
                tracker.general.cTAClicked({
                  type: 'button',
                  name: 'Google Play',
                  page: 'footer',
                  blockType: 'button',
                  blockName: 'Google Play',
                });
                new RouteFactory(
                  {
                    id: '',
                    __typename: 'ExternalLink',
                    url: androidRedirectURL || '',
                  },
                  navigation,
                  dispatch,
                ).navigate();
              }}>
              <Icon name="google-play" />
            </Pressable>
          )}
        </div>
      </div>
      <Hr />
      <div style={{marginTop: isMobile ? 30 : 0}}>
        {isMobile && <Typography variant="ui2">© Trace+</Typography>}
        <Pressable
          onPress={() => {
            tracker.general.cTAClicked({
              type: 'button',
              name: t('Site by Wizzmedia'),
              page: 'footer',
              blockType: 'button',
              blockName: 'Site by Wizzmedia',
            });
            new RouteFactory(
              {
                id: '',
                __typename: 'ExternalLink',
                url: 'https://wiz.fr',
              },
              navigation,
              dispatch,
            ).navigate();
          }}>
          <Typography
            variant="ui3"
            style={{
              color: darkTheme.colors.fontGray,
              marginTop: isMobile ? 12 : 20,
              marginBottom: 10,
            }}>
            {t('Site by Wizzmedia')}
          </Typography>
        </Pressable>
      </div>
    </div>
  );
};

export default PageFooter;

export const Hr = styled.hr(() => {
  return {
    border: '0.5px solid rgba(255, 255, 255, 0.1)',
    margin: 0,
    [`@media ${responsiveMap.mobile}`]: {
      display: 'none',
    },
  };
});
