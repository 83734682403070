import CategoryPage from '@src/Umbraco/Pages/CategoryPage/CategoryPage';
import React, {useEffect} from 'react';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import {RouteSource} from '@src/Navigation/type';
import useParams from '@src/Hooks/useParams';
import {useContentByUrlEndQuery} from '@src/Api/Graphql/gql/types.generated';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';

const CourseCategoryPage: React.FC = () => {
  const params = useParams();

  const tracker = useTracker();
  const {endUrl} = params || {};
  const {data, loading} = useContentByUrlEndQuery({
    variables: {
      urlEnd: endUrl,
    },
    skip: !endUrl,
  });
  const id = data?.allContent?.items?.[0]?.id;

  useEffect(() => {
    tracker.general.contentCategoryViewed({
      __typename: 'CourseCategory',
      source: (params?.source as RouteSource) || 'browse',
    });
  }, [params?.source]);

  return (
    <>
      {loading ? (
        <div
          style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,0.5)',
          }}>
          <CustomActivityIndicator />
        </div>
      ) : (
        <CategoryPage
          activeId={id as string}
          regionFilter={true}
          type="CourseCategory"
        />
      )}
    </>
  );
};

export default CourseCategoryPage;
