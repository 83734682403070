import React from 'react';
import {View, FlatList, ListRenderItem, FlatListProps} from 'react-native';
import {IChannelFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {liveTvScreenStyles} from '../LiveTvScreen.styles';
import TvGuideRow from './TvGuideRow/TvGuideRow';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';

import Metrics from 'design-system/src/Theme/Metrics';
import CategoryTagFilter, {
  ICategoryTagFilterProps,
} from '@src/App/components/Common/CategoryTagFilter/CategoryTagFilter';
interface ITvGuideTableProps {
  list: IChannelFragmentFragment[];
  loading?: boolean;
  tagFilter?: ICategoryTagFilterProps;
}

const renderItem: ListRenderItem<IChannelFragmentFragment> = ({item}) => {
  return <TvGuideRow key={item.id} item={item} />;
};

const getItemLayout: FlatListProps<IChannelFragmentFragment>['getItemLayout'] =
  (_, index) => ({
    length: Metrics.horizontalScale(96),
    offset: Metrics.horizontalScale(96) * index,
    index,
  });

const keyExtractor: FlatListProps<IChannelFragmentFragment>['keyExtractor'] =
  item => `tv-table-${item?.id}`;

const TvGuideTable = (props: ITvGuideTableProps) => {
  const {list, loading, tagFilter} = props;
  const {t} = useTranslation('translation');

  return loading ? (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <CustomActivityIndicator />
    </View>
  ) : (
    <View style={{flex: 1}}>
      <CategoryTagFilter {...tagFilter} />
      <FlatList
        style={[liveTvScreenStyles.scrollContainer, {flexGrow: 1}]}
        horizontal={false}
        initialNumToRender={4}
        data={list}
        renderItem={renderItem}
        getItemLayout={getItemLayout}
        keyExtractor={keyExtractor}
        maxToRenderPerBatch={5}
        ListEmptyComponent={<ContentEmpty title={t('No data')} />}
      />
    </View>
  );
};

export default TvGuideTable;
