import React, {FC} from 'react';
import {Pressable} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import CastButton from 'design-system/src/Widget/Player/VideoPlayer/Factory/Player/components/CastButton';

interface CustomModalHeaderProps {
  title?: string | React.ReactNode;
  onClose?: () => void;
  onGoBack?: () => void;
  children?: React.ReactNode;
  isCast?: boolean;
}

const itemHeight = 80;

const CustomModalHeader: FC<CustomModalHeaderProps> = ({
  title,
  onClose,
  onGoBack,
  children,
  isCast,
}) => {
  if (children) {
    return (
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}>
        {children}
      </div>
    );
  }

  const titleElement =
    typeof title === 'string' ? (
      <Typography variant="h4" color="white">
        {title}
      </Typography>
    ) : (
      title
    );

  return (
    <div
      style={{
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
      }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'relative',
          alignItems: 'center',
          height: 0,
          transform: 'translateY(40px)',
        }}>
        {!!onGoBack && (
          <div
            style={{
              padding: 25,
              position: 'absolute',
              left: 0,
              zIndex: 2,
              height: itemHeight,
            }}
            className="close-button">
            <Pressable onPress={() => onGoBack()}>
              <Icon name="retour" color="white" />
            </Pressable>
          </div>
        )}
        <div
          style={{
            height: itemHeight,
            display: 'inline-flex',
            alignItems: 'center',
          }}>
          {titleElement}
        </div>
        {!!onClose && !isCast && (
          <div
            style={{
              padding: 25,
              position: 'absolute',
              right: 0,
              zIndex: 2,
              height: itemHeight,
            }}
            className="close-button">
            <Pressable onPress={onClose}>
              <Icon name="close" color="white" />
            </Pressable>
          </div>
        )}
        {!!isCast && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              zIndex: 2,
              height: 24,
              marginRight: 25,
            }}>
            <CastButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomModalHeader;
