import {useAwardCategoryPageByIdQuery} from '@src/Api/Graphql/gql/types.generated';
import React, {ReactNode, useMemo} from 'react';
import useAwardCategoryPageUtils from './useAwardCategoryPageUtils';
import {View} from 'react-native';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import PageLoader from '@src/components/general/PageLoader/PageLoader';
import {useCurrentLanguage} from '@src/Store/Slices/LanguageSlice/Language.selector';

interface IAwardCategoryPageProps {
  id: string;
}

const AwardCategoryPage = (props: IAwardCategoryPageProps) => {
  const {id} = props;
  const currentLanguage = useCurrentLanguage();
  const {data, loading} = useAwardCategoryPageByIdQuery({
    variables: {
      id: id,
      culture: currentLanguage,
    },
    skip: !id,
  });
  const {renderPage} = useAwardCategoryPageUtils();
  const {isMobile} = useBreakpoint();

  const {pageContent}: {pageContent: ReactNode[]} = useMemo(() => {
    if (
      data &&
      data.awardCategoryPage &&
      data.awardCategoryPage.__typename === 'AwardCategoryPage'
    ) {
      return {
        pageContent: renderPage(data.awardCategoryPage),
      };
    }
    return {pageContent: []};
  }, [renderPage, data]);

  return (
    <View style={{flex: 1, paddingHorizontal: isMobile ? 16 : 63}}>
      {loading ? (
        <PageLoader />
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: isMobile ? 48 : 96,
          }}>
          {pageContent}
        </div>
      )}
    </View>
  );
};

export default AwardCategoryPage;
