import {StyleSheet, View} from 'react-native';
import React, {useEffect, useMemo, useState} from 'react';
import Metrics from 'design-system/src/Theme/Metrics';
import Typography from 'design-system/src/Components/Typography/Typography';
import {
  IUcVideoFragmentFragment,
  useJobTrainingByCourseQuery,
} from '~Api/Graphql/gql/types.generated';
import UnitPlayer from 'design-system/src/Widget/Player/UnitPlayer/UnitPlayer.web';
import {useLazyGetVimeoVideoQuery} from '~Api/TraceApi/traceApi';
import {
  ILearnPropertyName,
  IPlayerPropertyName,
} from '~Services/Amplitude/hooks/useTracker';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface IUcVideoProps {
  content: IUcVideoFragmentFragment;
  learnProperty?: ILearnPropertyName;
  aspectRatio?: number;
}

const UcVideo = (props: IUcVideoProps) => {
  const {
    content: {subtitle, title, liveEventId, sourceUrl, vimeoURL},
    learnProperty,
    aspectRatio,
  } = props;
  const [pause, setPause] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<string>();
  // const {isMobile} = useBreakpoint();

  const [fetchVimeoVideo, {data: videoInfo, isLoading}] =
    useLazyGetVimeoVideoQuery();
  const response = useJobTrainingByCourseQuery({
    variables: {
      id: learnProperty?.courseId,
    },
  });
  const loading = response.loading;
  const {t} = useTranslation(['learn']);
  const property: IPlayerPropertyName | undefined = useMemo(() => {
    let certificateName: string | undefined;
    const {data} = response;
    if (
      data &&
      data.allJobTraining &&
      data.allJobTraining.items &&
      data.allJobTraining.items[0] &&
      data.allJobTraining.items[0].__typename === 'JobTraining'
    ) {
      certificateName = data.allJobTraining.items[0].name;
    }
    if (learnProperty) {
      const {courseId, courseName, unitName, partnerName, interestName} =
        learnProperty;
      return {
        id: courseId || '',
        title: title || '',
        partnerName: partnerName || '',
        interestName: interestName || '',
        typename: 'Course',
        length: '',
        category: '',
        unitName,
        courseName,
        certificateName,
      } as IPlayerPropertyName;
    }
    return undefined;
  }, [learnProperty, response]);

  useEffect(() => {
    if (property && !loading) {
      tracker.player.started(property);
    }
  }, [property, loading]);

  useEffect(() => {
    if (sourceUrl) {
      setCurrentVideo(sourceUrl);
    } else if (vimeoURL) {
      setCurrentVideo(vimeoURL);
    } else if (typeof liveEventId === 'string') {
      setCurrentVideo(liveEventId);
    } else if (liveEventId) {
      fetchVimeoVideo(liveEventId + '').then(value => {
        const {data} = value;
        if (data) {
          setCurrentVideo(data.hls);
        }
      });
    }
  }, [fetchVimeoVideo, liveEventId, sourceUrl, vimeoURL]);

  return (
    <View>
      <View
        style={{
          position: 'relative',
          marginTop: Metrics.verticalScale(24),
        }}>
        {isLoading ? (
          <CustomActivityIndicator style={styles.container} />
        ) : !!currentVideo ? (
          <UnitPlayer
            aspectRatio={aspectRatio}
            paused={pause}
            width={Metrics.horizontalScale(327)}
            height={Metrics.verticalScale(392)}
            poster={videoInfo?.picture || ''}
            onVideoEnd={() => {
              if (property) {
                tracker.player.ended(property);
              }
            }}
            source={
              currentVideo
                ? {
                    uri: currentVideo,
                  }
                : undefined
            }
            onPlay={v => {
              if (v && property) {
                tracker.player.paused(property);
              }
              setPause(!v);
            }}
            messageError={t('unable to load video') as string}
          />
        ) : (
          <ContentEmpty title={t('unable to load video')} />
        )}
      </View>
      {!!title && (
        <Typography
          variant="h2"
          style={{textAlign: 'center', marginTop: Metrics.verticalScale(24)}}>
          {title}
        </Typography>
      )}
      {!!subtitle && (
        <Typography
          variant="ui2"
          style={{textAlign: 'center', marginTop: Metrics.verticalScale(7)}}>
          {subtitle}
        </Typography>
      )}
    </View>
  );
};

export default UcVideo;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    aspectRatio: 3 / 4,
  },
});
