import {useCountSavedContentMutation} from '~Api/Umbraco/ContentManagmentApi/UmbracoCdnApi/umbracoCdnApi';
import {useDispatch} from 'react-redux';
import memberSlice from '~Store/Slices/Member.slice';
import {useLazyCountSavedByIdQuery} from '~Api/TraceApi/traceApi';
import {useAppSelector} from '@src/Store/hooks';

const useBookmarkCount = () => {
  const bookmarkCount = useAppSelector(state => state.member.bookmarkCount);
  const [fetchCount] = useLazyCountSavedByIdQuery();
  const [] = useCountSavedContentMutation();
  const dispatch = useDispatch();

  return {
    fetchContentLike: async ({contentId}: {contentId: string}) => {
      try {
        const {data} = await fetchCount({id: contentId});
        if (data) {
          dispatch(
            memberSlice.actions.setBookmarkCount({
              contentId,
              count: data.total,
            }),
          );
        }
      } catch (error) {}
    },
    bookmarkCount,
  };
};

export default useBookmarkCount;
