import {useLazyGetVimeoPlayVideosQuery} from '@src/Api/TraceApi/traceApi';
import useCast from 'design-system/src/Widget/Player/VideoPlayer/Factory/Player/hooks/useCast';
import {useCallback, useEffect} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useCastPlayer from 'design-system/src/Widget/Player/VideoPlayer/Factory/Player/hooks/useCastPlayer';

const useCastOnPage = ({
  sourceUrl,
  vimeoId,
  title,
  poster,
  currentTime,
}: {
  sourceUrl?: string;
  vimeoId?: string;
  title?: string;
  poster?: string;
  currentTime?: number;
}) => {
  const {connected} = useCast();
  const [fetchUrl] = useLazyGetVimeoPlayVideosQuery();
  const {t} = useTranslation('translation');
  const {thumbnail, loadMedia} = useCastPlayer();

  const onLoadMedia = useCallback(
    async (vimeoIdOrUrl: string, type: 'vimeoId' | 'sourceUrl' = 'vimeoId') => {
      let url = '';
      let mediaType = '';
      if (type === 'sourceUrl') {
        url = vimeoIdOrUrl;
      } else {
        const response = await fetchUrl(vimeoIdOrUrl);
        if (response.data) {
          url = response.data.dash.link;
          mediaType = 'application/dash+xml';
        } else {
          toast.error(t('Video url not found'), {toastId: 'source-not-found'});
        }
      }
      if (!url) {
        toast.error(t('Video url not found'), {toastId: 'source-not-found'});
        return;
      }

      const mediaInfo = new window.chrome.cast.media.MediaInfo(1, mediaType);
      mediaInfo.contentUrl = url;
      mediaInfo.hlsSegmentFormat = 'ts';

      const metadata = new window.chrome.cast.media.MovieMediaMetadata();
      metadata.title = title;
      metadata.images = [new window.chrome.cast.Image(poster ?? thumbnail)];
      mediaInfo.metadata = metadata;

      const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
      request.currentTime = currentTime;
      loadMedia(request);
    },
    [fetchUrl, t],
  );

  useEffect(() => {
    if ((connected && vimeoId) || (connected && sourceUrl)) {
      onLoadMedia(
        sourceUrl || vimeoId || '',
        sourceUrl ? 'sourceUrl' : 'vimeoId',
      );
    }
  }, [connected, loadMedia, vimeoId, sourceUrl]);
};

export default useCastOnPage;
