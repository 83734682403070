import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {FC, Suspense, useCallback, useMemo, useState} from 'react';
import SearchBar from 'design-system/src/Components/TextInput/SearchBar/SearchBar.web';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import debounce from 'lodash/debounce';
import {View} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {getLinkPickerUrl} from '@src/Umbraco/Utils/element/getLinkPickerUrl';
import {IconName} from 'design-system/src/assets/Icones';
import {useDispatch} from 'react-redux';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import {useSectionSearch} from './useSectionSearch';
import useAppNavigation from '@src/utils/useAppNavigation';
import SuspenseAfterRequired from 'design-system/src/library/AfterRequired/SuspenseAfterRequired';

const SuggestionSearchResult = React.lazy(() =>
  import('./SuggestionSearch/SuggestionSearchResult').then(mod => ({
    default: mod.SuggestionSearchResult,
  })),
);

const CardHomeProfile = React.lazy(
  () =>
    import('design-system/src/Components/Card/CardHomeProfile/CardHomeProfile'),
);

const SuggestionSearchRecent = React.lazy(
  () => import('./SuggestionSearch/SuggestionSearchRecent'),
);

const AppSearch: FC = () => {
  const {isMobile} = useBreakpoint();
  const [isFocused, setIsFocused] = useState(false);
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [value, setValue] = useState<string>('');

  const rootNavigation = useAppNavigation();
  const dispatch = useDispatch();
  const {renderBlockPage} = useSectionSearch();

  const {
    page: {searchPageBlocks},
    currentRegion,
  } = useRegionUrl();

  const {control, resetField} = useForm<{search: string}>();

  const {t} = useTranslation(['search']);

  const {field} = useController<any>({
    control,
    defaultValue: '',
    name: 'search',
  });

  const handleChange = debounce(
    useCallback(
      (text: string) => {
        field.onChange(text);
        setValue(text);
      },
      [field],
    ),
    300,
  );

  const handleClear = () => {
    // Keyboard.dismiss();
    field.onChange('');
    setValue('');
    resetField('search');
    setIsWithCancel(false);
    setIsFocused(false);
  };

  const renderedTopBlock = useMemo(() => {
    return currentRegion?.searchTopBlocks?.map((item, index) => {
      if (item && item.content.__typename === 'LinkIcon') {
        const {icon, title: itemTitle} = item.content;
        const {url, __typename, id} = getLinkPickerUrl(item.content);

        return (
          <View
            style={{flexBasis: Metrics.horizontalScale(109)}}
            key={`hm-${index}`}>
            <Suspense>
              <CardHomeProfile
                title={itemTitle || ''}
                icon={(icon || '').trim().toLowerCase() as IconName}
                onPress={() => {
                  new RouteFactory(
                    {url, __typename, title: itemTitle, id, source: 'search'},
                    rootNavigation,
                    dispatch,
                  ).navigate();
                }}
              />
            </Suspense>
          </View>
        );
      }
      return null;
    });
  }, [currentRegion?.searchTopBlocks, dispatch, rootNavigation]);

  return (
    <div style={{marginTop: isMobile ? 90 : 100, boxSizing: 'border-box'}}>
      <View
        style={{
          paddingHorizontal: isMobile ? 16 : 0,
        }}>
        <SearchBar
          onFocus={() => {
            setIsFocused(true);
            setIsWithCancel(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 1000);
          }}
          placeholder={t('search.placeholder') + ''}
          value={field.value}
          control={control}
          name="search"
          onChangeText={handleChange}
          onPressCancel={handleClear}
          isWithCancel={false}
          cancelLabel={t('Cancel') || ''}
          isWithPrefixIcon={isMobile}
          style={{
            paddingHorizontal: isMobile ? 0 : 60 - 16,
          }}
        />
      </View>
      <View
        style={{
          paddingHorizontal: isMobile
            ? Metrics.horizontalScale(16)
            : Metrics.horizontalScale(60),
          marginTop: isMobile ? 0 : Metrics.verticalScale(48),
        }}>
        {value === '' && isWithCancel && (
          <Suspense>
            <SuggestionSearchRecent
              onPressItem={item => {
                field.onChange(item.text ?? '');
                setValue(item.text ?? '');
              }}
            />
          </Suspense>
        )}

        {!isFocused && !isWithCancel && value === '' && (
          <>
            {isMobile && (
              <View
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                  marginTop: Metrics.verticalScale(32),
                  marginBottom: Metrics.verticalScale(25),
                  paddingHorizontal: Metrics.horizontalScale(16),
                }}>
                {renderedTopBlock}
              </View>
            )}
            <View style={{paddingHorizontal: Metrics.horizontalScale(16)}}>
              {renderBlockPage && renderBlockPage(searchPageBlocks)}
            </View>
          </>
        )}
        <SuspenseAfterRequired required={!!value}>
          {value && <SuggestionSearchResult keyWord={value.trim()} />}
        </SuspenseAfterRequired>
      </View>
    </div>
  );
};

export default AppSearch;
