import useSetupMemberDatas from '@src/Hooks/useSetupMemberDatas';
import {useMemberSubscription} from '@src/Hooks/useMemberSubscription';
import React from 'react';

const AppLayoutConnectLogic = () => {
  useSetupMemberDatas();
  useMemberSubscription();
  return <></>;
};

export default AppLayoutConnectLogic;
