import useNavigation from '@src/Navigation/useNavigation';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import Button from 'design-system/src/Components/Button/Button';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {IconName} from 'design-system/src/assets/Icones';
import React from 'react';
import {View} from 'react-native';
import {useDispatch} from 'react-redux';
import CardList from '../CardList/CardList';

interface IAboutTraceProps {
  title?: string;
  description?: string;
  button?: {
    title?: string;
    icon?: string;
    linkTo?: string;
    id?: string;
  };
  cards?: {
    title: string;
    description: string;
    image: string;
  }[];
}

const AboutTrace = (props: IAboutTraceProps) => {
  const {title, description, button, cards} = props;
  const {isMobile} = useBreakpoint();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (!button) return;
    const {icon, id: idButton, linkTo, title} = button;
    new RouteFactory(
      {
        id: idButton || '',
        __typename: 'ExternalLink',
        url: linkTo || '',
      },
      navigation,
      dispatch,
    ).navigate();
  };

  return (
    <View
      style={{
        paddingTop: 96,
        paddingBottom: 96,
        alignItems: 'center',
      }}>
      {title && (
        <Typography
          variant={isMobile ? 'h1' : 'big2'}
          style={{marginBottom: 12}}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          variant="body1"
          style={{
            width: isMobile ? '80%' : '30%',
            textAlign: 'center',
          }}>
          {description}
        </Typography>
      )}
      {button && (
        <Button
          variant="outlined"
          title={button.title ?? ''}
          size="large"
          icon={
            button.icon ? (button.icon.toLowerCase() as IconName) : undefined
          }
          iconStyle={{}}
          onPress={handleOnClick}
          style={{
            marginTop: 42,
          }}
        />
      )}
      <CardList cards={cards} isNoPaddingBottom={true} />
    </View>
  );
};

export default AboutTrace;
