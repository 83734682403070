import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import CheckboxGroup, {ICheckboxGroupProps} from './CheckboxGroup';

export interface ICheckboxGroupControllerProps<
  TField extends FieldValues = FieldValues,
> extends Omit<ICheckboxGroupProps, 'name' | 'onChange'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
  valueMapper?: Record<string, string>;
}

export default function CheckboxGroupController<
  TField extends FieldValues = FieldValues,
>(props: ICheckboxGroupControllerProps<TField>) {
  const {name, rules, control, defaultValue, options, valueMapper, ...rest} =
    props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  const {field: fieldSecondValue} = useController<TField>({
    control,
    defaultValue,
    name: (name + 'Value') as Path<TField>,
  });

  const mapper: Record<string, string> =
    valueMapper || options.reduce((a, c) => ({...a, [c.value]: c.label}), {});

  const handleChange: ICheckboxGroupProps['onChange'] = value => {
    field.onChange(value);
    fieldSecondValue.onChange(value.map(v => mapper[v] || v));
  };

  return (
    <CheckboxGroup
      selected={field.value}
      onChange={handleChange}
      options={options}
      {...rest}
    />
  );
}
