export const deleteObjectKey = (
  obj: Record<string, any>,
  deleteKeys: string[],
) => {
  const currentKeys = Object.keys(obj);
  let newObj: Record<string, any> = {};
  for (const key of currentKeys) {
    if (!deleteKeys.includes(key)) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};
