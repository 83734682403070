import React from 'react';
import {Pressable, View} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import ChannelLogo from '../../ChannelLogo/ChannelLogo';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import Typography from '../../Typography/Typography';
import {logoCardStyles} from './LogoCard.styles';

type LogoCardProps = {
  logo?: CustomFastImageProps['source'];
  count?: number;
  onClick?: () => void;
};

const LogoCard: React.FC<LogoCardProps> = ({logo, count, onClick}) => {
  const controlledCount = Math.max(0, count || 0);
  return (
    <Pressable
      onPress={onClick}
      style={{
        ...logoCardStyles.container,
        ...(!!controlledCount && {
          borderWidth: 1,
          borderColor: defaultTheme.primary,
        }),
      }}>
      <ChannelLogo source={logo} />
      {!!controlledCount && (
        <View
          style={{
            ...logoCardStyles.badge,
            backgroundColor: defaultTheme.primary,
          }}>
          <Typography children={controlledCount} variant="button1" />
        </View>
      )}
    </Pressable>
  );
};

export default LogoCard;
