import {IItemPlan} from 'design-system/src/Widget/PayWall/PayWallChoosePlan/PayWallChoosePlan';
import {useEffect, useRef, useState, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Alert} from 'react-native';
import apiConfig from '~Configs/apiConfig';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import DateFactory from '~tools/DateFactory';

import {errorLog, isPlay} from '../tools/utils';
import {useHandleSubscription} from './useHandleSubscription';
import {
  ISubscriptionFragmentFragment,
  useAllSubscriptionLazyQuery,
} from '~Api/Graphql/gql/types.generated';
// import {mapSubscriptionItems} from '~Umbraco/Utils/mapSubscriptionItems';
import {mapHeroVideoCover} from '~Umbraco/Utils/mapHeroVideoCover';
// import usePaywal from './usePaywal';
import useNavigation from '@src/Navigation/useNavigation';
import {useSelecteSubscription} from '@src/Store/selectors/useMember';
import {useLazyGetSubscriptionProductsQuery} from '@src/Api/TraceApi/traceApi';

export interface ReceiptIOS {
  expires_date: string;
  expires_date_ms: string;
  expires_date_pst: string;
  is_in_intro_offer_period: string;
  is_trial_period: string;
  original_purchase_date: string;
  original_purchase_date_ms: string;
  original_purchase_date_pst: string;
  original_transaction_id: string;
  product_id: string;
  purchase_date: string;
  purchase_date_ms: string;
  purchase_date_pst: string;
  quantity: string;
  transaction_id: string;
  web_order_line_item_id: string;
  name?: string; // I haven't seen this in the TestFlight responses but the old app uses it so will add as an optional
}

export const useInAppPurchase = (needStore?: boolean) => {
  // const {isMemberFreemium} = usePaywal();

  const navigation = useNavigation();
  const memberSubscription = useSelecteSubscription();
  // const member = useMember();
  const tracker = useTracker();
  const {t} = useTranslation('subscription');
  const selectedSubscription = useRef<IItemPlan>();
  const [buyLoading, setBuyLoading] = useState(false);
  const {updateSubscription, updateToFreemiumSubscription} =
    useHandleSubscription();
  const [getSubscriptionProducts] = useLazyGetSubscriptionProductsQuery();
  const [loading, setLoading] = useState(true);
  const [fetchAllSubscription] = useAllSubscriptionLazyQuery();
  const [umbSubscriptions, setUmbSubscriptions] = useState<
    ISubscriptionFragmentFragment[]
  >([]);
  const [subscriptionPrice, setSubscriptionPrice] = useState<{
    [productId: string]: string;
  }>({});

  // const isReceiptValid = (receipt: ReceiptIOS) => {
  //   const expirationInMilliseconds = Number(receipt.expires_date_ms);
  //   const nowInMilliseconds = Date.now();
  //   return expirationInMilliseconds > nowInMilliseconds;
  // };

  // const findValidReceipt = useCallback(
  //   (receipt: any) =>
  //     (receipt.latest_receipt_info as ReceiptIOS[]).find(isReceiptValid) ||
  //     null,
  //   [],
  // );

  // const canPurchase = useCallback(async () => {
  //   if (isMemberFreemium()) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }, [isMemberFreemium]);

  const allSubscription = useMemo(() => {
    let itemsPlan: IItemPlan[] = [];
    // let period: 'm' | 'y' | 'w' = 'm';

    umbSubscriptions.forEach(currentSubsc => {
      const productId: string = currentSubsc.productID || '';
      let price: string = productId ? subscriptionPrice[productId] || '' : '';
      if (productId !== apiConfig.freemiumProductId) {
        const {tag} = currentSubsc;
        let disabled: boolean = false;
        const isYear = productId.includes('year');
        // if (isYear && period === 'm') {
        //   disabled = true;
        // }
        itemsPlan.push({
          title: currentSubsc.title ?? '',
          subtitle: currentSubsc.subtitle ?? '',
          description: currentSubsc.description ?? '',
          price: price,
          icon:
            currentSubsc.icon && currentSubsc.icon.url
              ? {uri: currentSubsc.icon.url}
              : undefined,
          period: isYear ? 'y' : 'm',
          planId: currentSubsc.productID || '',
          subscriptionId: currentSubsc.id,
          percentageSave:
            tag &&
            tag[0] &&
            tag[0].content &&
            tag[0].content.__typename === 'ColorTag'
              ? tag[0].content.value
              : undefined,
          successId: '',
          thankYouTitle: currentSubsc.thankYouTitle || '',
          thankYouBody: currentSubsc.thankYouBody || '',
          thankYouCover:
            currentSubsc.thankYouCover &&
            currentSubsc.thankYouCover[0] &&
            currentSubsc.thankYouCover[0].content &&
            currentSubsc.thankYouCover[0].content.__typename ===
              'HeroVideoCover'
              ? mapHeroVideoCover(currentSubsc.thankYouCover[0].content)
              : undefined,
          tagColor:
            tag &&
            tag[0] &&
            tag[0].content &&
            tag[0].content.__typename === 'ColorTag'
              ? tag[0].content.color
              : undefined,
          disabled:
            memberSubscription?.productId === currentSubsc.productID ||
            disabled,
          isCurrent: memberSubscription?.productId === currentSubsc.productID,
          subscriptionLink: isYear
            ? apiConfig.stripe.paymentLink.yearly
            : apiConfig.stripe.paymentLink.monthly,
        });
      }

      if (currentSubsc.productID === apiConfig.freemiumProductId) {
        let freeItem = currentSubsc;
        let disabled: boolean = false;
        if (
          memberSubscription?.productId.includes('month') ||
          memberSubscription?.productId.includes('year')
        ) {
          disabled = true;
        }
        const {tag} = freeItem;
        const free: IItemPlan = {
          description: freeItem?.description || '',
          title: freeItem?.title || '',
          subtitle: freeItem?.subtitle || '',
          icon:
            freeItem && freeItem.icon && freeItem.icon.url
              ? {uri: freeItem.icon.url}
              : undefined,
          period: 'free',
          subscriptionId: '',
          price: '',
          planId: freeItem?.productID || '',
          successId: '',
          percentageSave:
            tag &&
            tag[0] &&
            tag[0].content &&
            tag[0].content.__typename === 'ColorTag'
              ? tag[0].content.value
              : undefined,
          thankYouTitle: freeItem.thankYouTitle || '',
          thankYouBody: freeItem.thankYouBody || '',
          thankYouCover:
            freeItem.thankYouCover &&
            freeItem.thankYouCover[0] &&
            freeItem.thankYouCover[0].content &&
            freeItem.thankYouCover[0].content.__typename === 'HeroVideoCover'
              ? mapHeroVideoCover(freeItem.thankYouCover[0].content)
              : undefined,
          tagColor:
            tag &&
            tag[0] &&
            tag[0].content &&
            tag[0].content.__typename === 'ColorTag'
              ? tag[0].content.color
              : undefined,
          disabled:
            memberSubscription?.productId === freeItem.productID || disabled,
          isCurrent:
            needStore && memberSubscription?.productId === freeItem.productID,
        };
        itemsPlan.push(free);
      }
    });

    return itemsPlan;
  }, [subscriptionPrice, memberSubscription, umbSubscriptions, needStore]);

  const handleBuySubscription = async (subscription: IItemPlan) => {
    selectedSubscription.current = subscription;
    let offerToken = subscription.offerToken;
    if (memberSubscription) {
      if (isPlay && !offerToken) {
        console.warn(
          `There are no subscription Offers for selected product (Only requiered for Google Play purchases): ${subscription.planId}`,
        );
      }
      try {
        setBuyLoading(true);
        if (subscription.planId === apiConfig.freemiumProductId) {
          await updateToFreemiumSubscription();
          setBuyLoading(false);
          navigation.navigate('SuccessPlan', {
            state: {
              title: subscription.thankYouTitle,
              body: subscription.thankYouBody,
              cover: subscription.thankYouCover,
            },
          });
        } else {
          const productId = subscription.planId;

          // TODO purchase with stripe
          let purchaseResultInfo: any = {};
          if (purchaseResultInfo.inAppPurchaseData) {
            let decodeInAppPurchaseData = JSON.parse(
              purchaseResultInfo.inAppPurchaseData,
            );
            if (decodeInAppPurchaseData.purchaseState === 0) {
              // const consumeOwnedPurchaseReq = {
              //   purchaseToken: purchaseResultInfo.inAppPurchaseData.purchaseToken,
              // };
              // await HMSIapModule.consumeOwnedPurchase(consumeOwnedPurchaseReq);
              await updateSubscription(
                subscription.subscriptionId,
                new DateFactory(Date.now()).utcToISOFormat(),
                new DateFactory(
                  decodeInAppPurchaseData.expirationDate,
                ).utcToISOFormat(),
                productId,
                decodeInAppPurchaseData.orderId,
                decodeInAppPurchaseData.purchaseToken,
              );
              navigation.navigate('SuccessPlan', {
                state: {
                  title: subscription.thankYouTitle,
                  body: subscription.thankYouBody,
                  cover: subscription.thankYouCover,
                },
              });
            } else {
              console.log('prodcut not delivred');
            }
          }
          setBuyLoading(false);
        } /* else {
          let validated = true;
          if (Platform.OS === 'ios') {
            await clearTransactionIOS();
            validated = await canPurchase();
          }
          if (validated) {
            await requestSubscription({
              sku: subscription.planId,
              ...(offerToken && {
                subscriptionOffers: [{sku: subscription.planId, offerToken}],
              }),
            });
          } else {
            Alert.alert(t('Please use another apple ID'));
            await clearTransactionIOS();
          }
        } */
      } catch (error: any) {
        errorLog({message: 'handleBuySubscription', error});
        Alert.alert(t('Subscription error'));
        setBuyLoading(false);
        tracker.errorEncountered('subscription', 'Subscription error');
      }
    } else {
      setBuyLoading(false);
      Alert.alert(t('Unable to purchase'));
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.allSettled([getSubscriptionProducts(), fetchAllSubscription()])
      .then(([subscriptionProducts, allSubscription]) => {
        if (subscriptionProducts.status === 'fulfilled') {
          const _subscriptionPrice: {[productId: string]: string} = {};
          subscriptionProducts.value.data?.forEach(product => {
            _subscriptionPrice[product.productId || ''] =
              product.formatPrice || '';
          });

          setSubscriptionPrice(_subscriptionPrice);
        }
        if (allSubscription.status === 'fulfilled') {
          let value = allSubscription.value;
          if (
            value.data &&
            value.data.allSubscription &&
            value.data.allSubscription.items &&
            value.data.allSubscription.items[0] &&
            value.data.allSubscription.items[0].__typename === 'Subscription'
          ) {
            let allUmbSubscriptions: ISubscriptionFragmentFragment[] = [];
            let subscriptionSkus: string[] = [];
            value.data.allSubscription.items.map(_value => {
              if (_value?.productID) {
                if (_value?.productID !== apiConfig.freemiumProductId) {
                  subscriptionSkus.push(_value?.productID);
                }
                allUmbSubscriptions.push(_value);
              }
            });
            setUmbSubscriptions(allUmbSubscriptions);
          }
        }
      })
      .catch(error => {
        errorLog({message: 'handleGetSubscriptions Umbraco', error});
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getSubscriptionProducts, fetchAllSubscription]);

  return {
    handleGetSubscriptions: () => {
      true;
    },
    handleBuySubscription,
    selectedSubscription,
    // connected,
    subscriptions: [],
    buyLoading,
    loading,
    allSubscription,
  };
};
