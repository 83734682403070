/* eslint-disable react-native/no-inline-styles */
import useRegionCategoryTagsMenu from '@src/Hooks/useRegionCategoryTagsMenu';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {useState, useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import './CategoryTagFilter.style.scss';

export interface ICategoryTagFilterProps {
  active?: string;
  onChange?: (value: string) => void;
  style?: StyleProp<ViewStyle>;
  filterRegions?: (regionId: string) => boolean;
}

const CategoryTagFilter = (props: ICategoryTagFilterProps) => {
  const {active, onChange, filterRegions} = props;
  const [activeTag, setActiveTag] = useState<string>(active || '');
  const {list} = useRegionCategoryTagsMenu();
  const handleChange = (value: string) => {
    setActiveTag(value);
    onChange && onChange(value);
  };
  const {isMobile} = useBreakpoint();

  const tagList = useMemo(() => {
    return filterRegions ? list.filter(item => filterRegions(item.id)) : list;
  }, [list, filterRegions]);

  return (
    <div className="simple-scroll-container" style={{gap: isMobile ? 14 : 24}}>
      {tagList.map((item, index) => (
        <div
          className="simple-scroll-item"
          key={index}
          onClick={() => {
            handleChange(item.id);
          }}>
          <Typography
            variant="body2"
            color={activeTag === item.id ? 'orange' : 'white'}>
            {item.name}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default CategoryTagFilter;
