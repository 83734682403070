import React from 'react';
import {View} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import {selectSize} from '../../../../tools/CardSizeTools';
import VodCardAside from '../VodCardAside/VodCardAside';
import {VodCardProps} from '../VodCardProps';
import TopTenCard from './TopTenCard/TopTenCard';
import {topVodCardStyles} from './TopTenVodCard.style';

export interface TopTenVodCardProps extends VodCardProps {
  number: string;
}

const TopTenVodCard = (props: TopTenVodCardProps) => {
  const {number, cover} = props;
  const size = React.useCallback(selectSize, [props])(props, false);

  return (
    <View
      style={{
        width: Metrics.horizontalScale(size.width),
      }}>
      <View style={topVodCardStyles.card}>
        <TopTenCard size="large" number={number} source={cover} />
      </View>
      <VodCardAside asideProps={props} />
    </View>
  );
};

export default TopTenVodCard;
