import React from 'react';
import AppGrid from '../AppGrid';
import ItemPartner from './ItemPartner';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {IPartenerSliderItem} from '@src/Umbraco/Components/PartnerSlider/PartnerSlider';
import {View, Pressable} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import AutoScaleImage from 'design-system/src/Components/AutoScaleImage/AutoScaleImage';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';

interface IPartnersGridProps {
  data: IPartenerSliderItem[];
  nbColumns?: number;
  title?: string;
  seeMore?:
    | {
        label: string;
        onPress?: (() => void) | undefined;
      }
    | undefined;
}

const PartnersGrid = ({
  data,
  nbColumns,
  title,
  seeMore,
}: IPartnersGridProps) => {
  const {navigate} = useAppNavigation();

  const {isMobile} = useBreakpoint();
  const nbCols = nbColumns || (isMobile ? 3 : 6);
  // const totalItems = data.length;
  // const totalRows = Math.ceil(totalItems / nbColumns);
  // const startIndexLastRow = (totalRows - 1) * nbColumns;
  const renderItem = (item: IPartenerSliderItem) => {
    return (
      <View
        style={{
          width: Metrics.horizontalScale(118),
          height: Metrics.verticalScale(72),
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <AutoScaleImage
          backgroundFallbackColor="transparent"
          initWidth={60}
          initHeight={16}
          uri={item?.partnerLogo || ''}
          resizeMode="contain"
        />
      </View>
    );
  };

  const onPressItem = (item: IPartenerSliderItem) => {
    navigate(AppRoutesEnum.PARTNER_SINGLE, {
      state: {
        params: {
          id: item?.id || '',
          type: 'url',
          url: item?.url || '',
          __typename: 'Partner',
        },
      },
    });
  };

  return (
    <AppGrid nbColumns={nbCols} title={title} seeMore={seeMore}>
      {data.map((item, index) => (
        <ItemPartner
          key={index}
          isOnRight={(index + 1) % nbCols === 0}
          // isOnBottom={index >= startIndexLastRow}
          onPress={() => onPressItem(item)}>
          {renderItem(item)}
        </ItemPartner>
      ))}
    </AppGrid>
  );
};

export default PartnersGrid;
