import Modal from 'react-modal';
import React, {useState} from 'react';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import {Pressable} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Button from 'design-system/src/Components/Button/Button';

interface CancelSubscriptionConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onOk?: () => Promise<boolean>;
}

const CancelSubscriptionConfirmation = ({
  isOpen,
  onClose,
  onOk,
}: CancelSubscriptionConfirmationProps) => {
  const {t} = useTranslation('subscription');
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    try {
      setLoading(true);

      const success = await onOk?.();

      if (success) {
        onClose();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.body as HTMLElement}
      overlayElement={(d, contentElement) => (
        <div
          {...d}
          style={{
            ...d.style,
            background: 'rgba(17, 18, 22, 0.60)',
            backdropFilter: 'blur(10px)',
            zIndex: 11000,
          }}>
          {contentElement}
        </div>
      )}
      style={{
        content: {
          border: 'none',
          background: 'transparent',
          padding: 0,
          margin: 'auto',
          width: 'fit-content',
          height: 'fit-content',
          inset: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        overlay: {
          background: 'rgba(17, 18, 22, 0.60)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: defaultTheme.page.background,
          borderRadius: 8,
          padding: 20,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h3" style={{color: 'white'}}>
            {t('Do you want to cancel your subscription?')}
          </Typography>
          <Pressable onPress={onClose}>
            <Icon name="close" color="white" />
          </Pressable>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 40,
            padding: 20,
          }}>
          <Button
            variant="contained"
            size="small"
            title={t('Cancel')}
            fullWidth
            onPress={onClose}
            style={{
              backgroundColor: 'transparent',
            }}
            disabled={loading}
          />
          <Button
            variant="contained"
            size="small"
            title={t('Confirm')}
            fullWidth
            onPress={handleOk}
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionConfirmation;
