import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import Typography from '../../../Typography/Typography';
import {thumbnailTvGuideStyles} from './ThumbnailTvGuide.styles';

export type ThumbnailTvGuideProps = {
  title: string;
  type: string;
  length: string;
  start: string;
  progress?: number;
  style?: StyleProp<ViewStyle>;
};

const ThumbnailTvGuide = (props: ThumbnailTvGuideProps) => {
  const {title, type, length, start, progress, style} = props;

  return (
    <View style={[thumbnailTvGuideStyles.container, style]}>
      <View style={[thumbnailTvGuideStyles.body]}>
        <View style={{flexDirection: 'row'}}>
          <Typography
            style={{flex: 1}}
            children={start}
            color="gray"
            variant="ui3"
          />
          <Typography color="gray" variant="ui3">
            {' '}
            {length}
          </Typography>
        </View>
        <View>
          <Typography
            children={title}
            style={thumbnailTvGuideStyles.title}
            variant="h4"
            numberOfLines={2}
            ellipsizeMode="tail"
          />
        </View>
        <Typography
          style={{marginTop: 'auto', marginBottom: Metrics.verticalScale(13)}}
          children={type}
          color="gray"
          variant="ui3"
        />
      </View>
      <View style={thumbnailTvGuideStyles.progressBar}>
        <CustomProgressBar
          progress={progress}
          backgroundColor="#43444A"
          withRadius={false}
        />
      </View>
    </View>
  );
};

export default React.memo(ThumbnailTvGuide);
