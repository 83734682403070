import React, {useEffect, useState} from 'react';
import {
  Modal,
  View,
  Pressable,
  ScrollView,
  ModalProps,
  StatusBar,
} from 'react-native';
import {listModalStyles} from './ListModal.styles';
import Typography from '../../Typography/Typography';
import Icon from '../../Icon/Icon';
import CustomBlurView from '../../CustomBlurView/CustomBlurView';

export interface ListModalProps<T> extends ModalProps {
  visibility: boolean;
  title: string;
  items: T[];
  renderItemLabel: (item: T) => string;
  onClickItem?: (value: T) => void;
  onClose?: () => void;
}

const ListModal = <T extends object = object>(props: ListModalProps<T>) => {
  const {
    title,
    items,
    onClickItem,
    visibility,
    onClose,
    renderItemLabel,
    ...restProps
  } = props;
  const [show, setShow] = useState(visibility);

  useEffect(() => {
    if (visibility !== show) {
      setShow(visibility);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  const handleClose = () => {
    onClose && onClose();
    setShow(false);
  };
  const handleSelectItem = (item: T) => () => {
    onClickItem && onClickItem(item);
  };
  return (
    <Modal
      statusBarTranslucent={true}
      animationType="fade"
      transparent={true}
      visible={show}
      {...restProps}>
      <StatusBar translucent={true} backgroundColor="transparent" />
      <CustomBlurView
        blurAmount={45}
        style={listModalStyles.blurViewStyle}
        blurType="dark"
      />
      <View style={listModalStyles.body}>
        <View style={listModalStyles.title}>
          <Typography children={title} variant="h4" />
        </View>
        <ScrollView
          style={{width: '100%'}}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <View style={[listModalStyles.contentWrapper]}>
            {items.map((item, index) => {
              return (
                <Pressable
                  key={index}
                  style={listModalStyles.item}
                  onPress={handleSelectItem(item)}>
                  <Typography variant="h3">{renderItemLabel(item)}</Typography>
                </Pressable>
              );
            })}
          </View>
        </ScrollView>
        <Pressable style={[listModalStyles.buttonClose]} onPress={handleClose}>
          <Icon name="close" color="black" />
        </Pressable>
      </View>
    </Modal>
  );
};

export default ListModal;
