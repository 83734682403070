import {Alert, StyleSheet, Pressable, View} from 'react-native';
import React, {useEffect, useState} from 'react';
// import {useLazyUpdateMemberQuery} from '~Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import Metrics from 'design-system/src/Theme/Metrics';
import Button from 'design-system/src/Components/Button/Button';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import UmbracoFormField from '~Umbraco/Components/UmbracoForm/UmbracoFormPage/UmbracoFieldset/UmbracoFormColumn/UmbracoFormField/UmbracoFormField';
import Typography from 'design-system/src/Components/Typography/Typography';
import Icon from 'design-system/src/Components/Icon/Icon';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useChangeLanguage from '~Hooks/useChangeLanguage';
import {useLazyUpdateMemberQuery} from '~Api/TraceApi/traceApi';
import appSlice, {IProfilEditDrawerParams} from '@src/Store/Slices/AppSlice';
import {Member} from '@src/Api/Umbraco/interface/Member.interface';
import {useAppDispatch} from '@src/Store/hooks';
import {toast} from 'react-toastify';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {useRegions} from '@src/Store/selectors/useRegions';
import ProfileMyProfileInteresetEdit from './ProfileMyProfileInteresetEdit';

export interface IForm {
  formField: any;
}

const ProfileMyProfileFieldEdit = ({
  params,
  data,
}: {
  params: IProfilEditDrawerParams;
  data: Member;
}) => {
  const {field, headerTitle, select, defaultValue: paramsDefaultValue} = params;
  const reactForm = useForm<IForm>({
    defaultValues: {
      formField: data[field] || paramsDefaultValue || '',
    },
  });
  const {handleSubmit, reset} = reactForm;
  const [loading, setLoading] = useState(false);
  const [loadingValue, setLoadingValue] = useState<any>('');
  const {t} = useTranslation('profile');
  const [updateMember, {isLoading}] = useLazyUpdateMemberQuery();
  const {updateStoreLang} = useChangeLanguage();
  const {navigate} = useAppNavigation();
  const {regionOptions} = useRegions();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (field) {
      const defaultValue: any = data[field] || paramsDefaultValue || '';
      reset({
        formField: defaultValue,
      });
    }
  }, [data, field, paramsDefaultValue]);

  const handleSubmitSendForm: SubmitHandler<IForm> = async formData => {
    if (isLoading) return;
    try {
      setLoading(true);
      const {formField} = formData;
      setLoadingValue(formField);

      let body = {
        ...data,
        [field]: formField,
      };
      let updateLanguage: string | undefined = undefined;
      if (field === 'language') {
        const region = regionOptions.find(
          reg => reg.culture === formField || reg.culture === formField[0],
        )?.value;
        if (region) {
          body = {
            ...body,
            region,
          };
        }
      } else if (field === 'region') {
        const language = regionOptions.find(
          reg => reg.value === formField,
        )?.culture;

        if (language) {
          const isArray = Array.isArray(body.language);
          updateLanguage = language;
          body = {
            ...body,
            // @ts-ignore
            language: isArray ? [language] : language,
          };
        }
      }

      if (field === 'interestsTags' || field === 'musicGenreTags') {
        const mapSecondField = {
          interestsTags: 'interests',
          musicGenreTags: 'musicGenre',
        };
        body = {
          ...body,
          [mapSecondField[field]]: formField,
          [mapSecondField[field] + 'Value']: formField,
          [field]: formField
            .map((v: string) => `umb://document/${v.replaceAll('-', '')}`)
            .join(','),
        };
      }

      if (data) {
        const response = await updateMember({
          data: body,
        });
        if (response) {
          dispatch(
            appSlice.actions.setIsOpenProfilEditDrawer({
              isOpenProfilEditDrawer: false,
              data: undefined,
            }),
          );
          if (updateLanguage) {
            console.log('updateLanguage', updateLanguage);
            await updateStoreLang(updateLanguage);
            setTimeout(() => {
              navigate(AppRoutesEnum.HOME, {
                replace: true,
              });
            }, 400);
          }
          toast.success(t('Member updated successfully'));
        } else {
          throw new Error(t('Error updating member') as string);
        }
      }
    } catch (error) {
      toast.error(t('Error updating member, please try again later'));
    } finally {
      setLoading(false);
      setLoadingValue('');
    }
  };

  const handleError: SubmitErrorHandler<IForm> = err => {
    const list = Object.values(err);
    if (list[0] && list[0].message && typeof list[0].message === 'string') {
      toast.error(list[0].message || '');
    }
  };
  const currentSelectValue = Array.isArray(data[field])
    ? (data[field] as any)?.[0] || paramsDefaultValue
    : data[field] || paramsDefaultValue;

  const buttonLabel = t('Update the {{field}}', {field: headerTitle});

  if (field === 'interestsTags' || field === 'musicGenreTags') {
    return (
      <ProfileMyProfileInteresetEdit
        defaultValue={data[field] + '' || paramsDefaultValue + '' || ''}
        title={headerTitle}
        fieldKey={field}
        reactForm={reactForm}
        isLoading={isLoading}
        loading={loading}
        buttonLabel={buttonLabel}
        handleSubmit={handleSubmit}
        handleSubmitSendForm={handleSubmitSendForm}
        handleError={handleError}
      />
    );
  }

  return (
    <>
      {select ? (
        <View
          style={{
            backgroundColor: '#111216',
          }}>
          {select.map((item, index) => {
            const isActive: boolean = item.value === currentSelectValue;
            return (
              <View
                key={index}
                style={{
                  paddingHorizontal: Metrics.horizontalScale(16),
                  borderColor: 'rgba(115,115,115,0.3)',
                  borderBottomColor: 'rgba(115,115,115,0.3)',
                  borderBottomWidth: 1,
                  borderTopWidth: index === 0 ? 1 : 0,
                }}>
                <Pressable
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: Metrics.verticalScale(16),
                    paddingBottom: Metrics.verticalScale(19),
                  }}
                  disabled={isLoading || isActive}
                  onPress={async () => {
                    await handleSubmitSendForm({
                      formField: Array.isArray(data[field])
                        ? [item.value]
                        : item.value,
                    });
                  }}>
                  <Typography
                    variant="body2"
                    color={isActive ? 'primary' : 'white'}>
                    {item.label}
                  </Typography>
                  <View>
                    {isLoading ? (
                      loadingValue === item.value && (
                        <CustomActivityIndicator size={'small'} />
                      )
                    ) : (
                      <Icon
                        name="check"
                        size="16"
                        color={defaultTheme.primary}
                        style={{opacity: isActive ? 1 : 0}}
                      />
                    )}
                  </View>
                </Pressable>
              </View>
            );
          })}
        </View>
      ) : (
        <View style={{zIndex: 0}}>
          <Typography variant="h2" style={{marginBottom: 72}}>
            {t('My profile')}
          </Typography>
          <View
            style={{
              marginBottom: 4,
            }}>
            <FormProvider {...reactForm}>
              <UmbracoFormField
                field={{
                  caption: headerTitle,
                  alias: 'formField',
                  required: true,
                  requiredErrorMessage: t('Field required') || '',
                  settings: {
                    fieldType: field === 'phone' && 'tel',
                  },
                  type: field === 'dateOfBirth' ? 'date' : 'text',
                }}
                style={{width: '100%'}}
                index={0}
              />
            </FormProvider>
          </View>
          <View
            style={{
              zIndex: -1,
            }}>
            <Button
              variant="contained"
              size="medium"
              title={buttonLabel}
              fullWidth
              loading={isLoading || loading}
              onPress={handleSubmit(handleSubmitSendForm, handleError)}
            />
          </View>
        </View>
      )}
    </>
  );
};

export const ProfileMyProfileFieldEditScreenStyles = StyleSheet.create({
  content: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingBottom: Metrics.verticalScale(96),
    paddingHorizontal: Metrics.horizontalScale(48),
    minHeight: Metrics.window.height,
  },
});

export default ProfileMyProfileFieldEdit;
