import React, {useEffect, useState} from 'react';
import {graphql, HeadFC, PageProps} from 'gatsby';
import PageHead from '@src/components/general/PageHead/PageHead';
import TvAuthCode from '@src/components/page-content/TvAuthCode/TvAuthCode';
import CustomActivityIndicator from 'design-system/src/Components/CustomActivityIndicator/CustomActivityIndicator';

const TvAuth = (props: PageProps<Queries.TvAuthQuery>) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const isWeb = window.location.search.includes('web');

    setTimeout(() => {
      setLoading(false);
      // if (!isWeb) {
      //   window.location.href = window.location.href + '?web';
      // }
    }, 2000);
    // open deepLink
    // if (!isWeb) {
    //   window.location.href = 'https://www.google.com';
    // }
  }, []);

  return (
    <div style={{width: '100%', height: '100%'}}>
      {loading ? <CustomActivityIndicator /> : <TvAuthCode />}
    </div>
  );
};

TvAuth.layoutComponent = 'EmptyLayout';
export default TvAuth;

export const Head: HeadFC<Queries.TvAuthQuery> = props => {
  return (
    <PageHead title={'Give Access'} description={'Give Access'} {...props} />
  );
};

export const query = graphql`
  query TvAuth($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
