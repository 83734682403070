import {IAudioSource} from 'design-system/src/Widget/Player/ListenPlayer/ListenPlayer';

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export interface FloatingPlayerState {
  currentTime?: number;
  currentMedia?: IAudioSource;
  isRadio?: boolean;
  isPaused: boolean;
  id: number;
}

export const floatingPlayerInitialState: FloatingPlayerState = {
  currentTime: 0,
  currentMedia: undefined,
  isPaused: true,
  isRadio: false,
  id: 0,
};

const FloatingListenContext = createContext<
  | {
      state: FloatingPlayerState;
      setFloatingState: React.Dispatch<
        React.SetStateAction<FloatingPlayerState>
      >;
    }
  | undefined
>(undefined);

export const UseFloatingListen = () => {
  return useContext(FloatingListenContext);
};

export const FloatingListenProvider = (props: PropsWithChildren<any>) => {
  const {children} = props;
  const [floatingPlayerState, setFloatingPlayerState] =
    useState<FloatingPlayerState>(floatingPlayerInitialState);

  return (
    <FloatingListenContext.Provider
      value={{
        state: floatingPlayerState,
        setFloatingState: setFloatingPlayerState,
      }}>
      {children}
    </FloatingListenContext.Provider>
  );
};
