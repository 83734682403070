import {StyleSheet} from 'react-native';
import {darkTheme} from '../../../styles/Themes';
import Metrics from '../../../Theme/Metrics';

export const logoCardStyles = StyleSheet.create({
  container: {
    width: Metrics.horizontalScale(109),
    height: Metrics.verticalScale(72),
    borderRadius: Metrics.moderateScale(4),
    backgroundColor: darkTheme.colors.thirdBlack,
    alignItems: 'center',
    justifyContent: 'center'
  },
  badge: {
    top: Metrics.verticalScale(7),
    right: Metrics.horizontalScale(7),
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: Metrics.horizontalScale(20),
    height: Metrics.horizontalScale(20),
    borderRadius: Metrics.moderateScale(10),
  },
});
