import {useEffect, useRef, useState} from 'react';

export const useAudioPlayer = ({
  currentSource,
  initialTime,
}: {
  currentSource: (
    | number
    | {
        uri?: string | undefined;
        headers?:
          | {
              [key: string]: string;
            }
          | undefined;
        type?: string | undefined;
      }
  ) & {
    description: string;
  };
  initialTime?: number;
  isListen?: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [duration, setDuration] = useState<number>(0);
  const [loaded, setLoaded] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [playedSeconds, setPlayedSeconds] = useState<number>(0);
  const [isLoadedMetadata, setIsLoadedMetadata] = useState(false);

  // useEffect(() => {
  //   setIsLoadedMetadata(false);
  // }, [(currentSource as any)?.uri]);

  const handlePlayPause = () => {
    if (!isLoadedMetadata) {
      return;
    }
    setPlaying(!playing);
    if (videoRef.current) {
      playing ? videoRef.current.pause() : videoRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current?.currentTime || 0;
    setPlayedSeconds(currentTime);
    setPlayed(currentTime / duration);
  };

  const resetPlayer = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      handleTimeUpdate();
    }
  };

  const handleSliderChange = (value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setPlayed(newValue);
    if (videoRef.current) {
      videoRef.current.currentTime = newValue * duration;
    }
  };

  const skip = (amount: number) => {
    if (videoRef.current) {
      let newTime = (videoRef.current.currentTime || 0) + amount;
      newTime = Math.max(0, Math.min(newTime, duration));
      videoRef.current.currentTime = newTime;
      handleTimeUpdate();
    }
  };

  const handleLoadedMetadata = () => {
    videoRef.current?.play();
    setDuration(initialTime || 0);
    setIsLoadedMetadata(true);
  };

  //   const changeVideoQuality = (qualityIndex: number) => {
  //     if (hlsRef.current) {
  //         hlsRef.current.currentLevel = qualityIndex;
  //         setSelectedQuality(qualityIndex);
  //     }
  // };

  return {
    videoRef,
    setLoaded,
    loaded,
    duration,
    handlePlayPause,
    playing,
    resetPlayer,
    handleSliderChange,
    skip,
    played,
    playedSeconds,
    handleTimeUpdate,
    handleLoadedMetadata,
    isLoadedMetadata,
    setPlaying,
  };
};
