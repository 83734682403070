/* eslint-disable react-native/no-inline-styles */
import {View} from 'react-native';
import React, {ReactNode, useEffect, useMemo} from 'react';
import {
  INewsOrderByInput,
  useContentUrlByIdLazyQuery,
  useNewsCategoryByUrlLazyQuery,
  useNewsCategoryByUrlWithRegionLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import useCategoryPageUtils, {
  ICategoryGridProps,
} from '~Umbraco/Hooks/useCategoryPageUtils';
import {CategoryContentLoader} from 'design-system/src/Components/ContentLoader';
import {RouteTypename} from '~Navigation/type';
import {ICategoryPageContentProps} from '~Umbraco/Pages/CategoryPage/CategoryPageContent/CategoryPageContent';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';

export interface INewsCategoryContentProps
  extends ICategoryPageContentProps<INewsOrderByInput> {
  id?: string;
  type: RouteTypename;
  gridProps?: ICategoryGridProps;
  setAllCategoryData?: (data: any[]) => void;
}
const NewsCategoryContent = (props: INewsCategoryContentProps) => {
  const {id, type, orderBy, gridProps, regionId, setAllCategoryData} = props;
  const [fetchCategory, {data: currentCategoryData, loading}] =
    useNewsCategoryByUrlLazyQuery();
  const currentLanguage = useCurrentLanguage();
  const [
    fetchCategoryWithRegion,
    {data: currentCategoryDataByRegion, loading: loadingByRegion},
  ] = useNewsCategoryByUrlWithRegionLazyQuery();

  const [fetchDetails, {loading: loadingDetails}] =
    useContentUrlByIdLazyQuery();

  const {renderCategoryPage} = useCategoryPageUtils();

  useEffect(() => {
    if (id) {
      fetchDetails({
        variables: {
          id,
          culture: currentLanguage,
        },
        errorPolicy: 'all',
      }).then(response => {
        if (
          response &&
          response.data &&
          response.data.content &&
          response.data.content.url
        ) {
          if (regionId) {
            fetchCategoryWithRegion({
              variables: {
                categoryUrl: response.data.content.url,
                orderBy: orderBy,
                regionTag: regionId,
              },
              errorPolicy: 'all',
            });
          } else {
            fetchCategory({
              variables: {
                categoryUrl: response.data.content.url,
                orderBy: orderBy,
              },
              errorPolicy: 'all',
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategory, id, orderBy, regionId, currentLanguage]);

  useEffect(() => {
    const catData = regionId
      ? currentCategoryDataByRegion
      : currentCategoryData;
    if (!regionId && catData && catData.category && catData.category.edges) {
      setAllCategoryData && setAllCategoryData(catData.category.edges);
    }
  }, [currentCategoryData, regionId, currentCategoryDataByRegion]);

  const pageContent: ReactNode[] = (() => {
    if (!regionId) {
      if (
        currentCategoryData &&
        currentCategoryData.category &&
        currentCategoryData.category.edges
      ) {
        return renderCategoryPage(
          {
            children: {
              edges: currentCategoryData.category.edges as any,
              pageInfo: currentCategoryData.category.pageInfo as any,
            },
          },
          type,
          gridProps,
        );
      }
    } else if (
      currentCategoryDataByRegion &&
      currentCategoryDataByRegion.category &&
      currentCategoryDataByRegion.category.edges
    ) {
      return renderCategoryPage(
        {
          children: {
            edges: currentCategoryDataByRegion.category.edges as any,
            pageInfo: currentCategoryDataByRegion.category.pageInfo as any,
          },
        },
        type,
        gridProps,
      );
    }

    return [];
  })();

  return (
    <View>
      {loading || loadingByRegion || loadingDetails ? (
        <CategoryContentLoader hideSelector={true} />
      ) : (
        pageContent
      )}
    </View>
  );
};

export default NewsCategoryContent;
