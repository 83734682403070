import CustomProgressBar from 'design-system/src/Components/CustomProgressBar/CustomProgressBar';
import Typography from 'design-system/src/Components/Typography/Typography';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import React, {FC} from 'react';

interface IAppProgressBarProps {
  progress: number;
  stay?: string;
  stayColor?: string;
}

const AppProgressBar: FC<IAppProgressBarProps> = ({
  progress,
  stay,
  stayColor = defaultTheme.gray,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop: 15,
        boxSizing: 'border-box',
      }}>
      <div
        style={{
          width: '100%',
        }}>
        <CustomProgressBar progress={progress} />
      </div>
      {stay && (
        <Typography
          variant="ui3"
          color={() => stayColor}
          numberOfLines={1}
          style={{whiteSpace: 'nowrap', paddingLeft: 16}}>
          {stay}
        </Typography>
      )}
    </div>
  );
};

export default AppProgressBar;
