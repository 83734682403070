import React, {ReactNode} from 'react';
import {View} from 'react-native';
import Images from '../../../../assets/Images';
import Metrics from '../../../../Theme/Metrics';
import ChannelLogo from '../../../ChannelLogo/ChannelLogo';
import Typography from '../../../Typography/Typography';
import {tvGuideLeadingCardStylesWeb} from './TvGuideLeadingCard.styles';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';

export type TvGuideLeadingCardProps = {
  channelLogo: keyof typeof Images.channel | (() => ReactNode);
  channel: string;
  children?: React.ReactNode;
};

const TvGuideLeadingCard = (props: TvGuideLeadingCardProps) => {
  const {channelLogo, channel, children} = props;
  const {isMobile} = useBreakpoint();

  return (
    <View style={tvGuideLeadingCardStylesWeb(isMobile).container}>
      {children ? (
        children
      ) : (
        <>
          {typeof channelLogo === 'function' ? (
            channelLogo()
          ) : (
            <ChannelLogo source={Images.channel[channelLogo]} size="medium" />
          )}
        </>
      )}
    </View>
  );
};

export default TvGuideLeadingCard;
