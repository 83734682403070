import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export const roundedTextInputstyles = StyleSheet.create({
  prefixIcon: {
    position: 'absolute',
    left: Metrics.horizontalScale(14),
    width: Metrics.horizontalScale(24),
    height: Metrics.horizontalScale(24),
  },

  suffixIcon: {
    position: 'absolute',
    right: Metrics.horizontalScale(14),
    // top: 0,
    // bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: Metrics.horizontalScale(24),
    height: Metrics.horizontalScale(24),
  },

  input: {
    flex: 1,
    paddingRight: Metrics.horizontalScale(16),
    outlineStyle: 'none',
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  helperContainer: {
    flexDirection: 'row',
    marginTop: Metrics.verticalScale(14),
    marginBottom: Metrics.verticalScale(2),
  },
});

export const RoundedTextInputVariant = {
  default: {
    colors: {
      text: defaultTheme.primaryTitle,
      primary: defaultTheme.primaryTitle,
      background: defaultTheme.textInput.bg,
      focus: defaultTheme.gray,
      focusBackground: 'transparent',
      focusBorderColor: defaultTheme.textInput.primary,
      activeBorderColor: defaultTheme.input.activeBorderColor,
    },
    roundness: Metrics.moderateScale(48),
  },
  transparent: {
    colors: {
      text: defaultTheme.primaryTitle,
      primary: defaultTheme.primaryTitle,
      focus: defaultTheme.gray,
      background: 'transparent',
      focusBackground: 'transparent',
      focusBorderColor: undefined,
      activeBorderColor: defaultTheme.input.activeBorderColor,
    },
    roundness: Metrics.moderateScale(48),
  },
  filled: {
    colors: {
      text: defaultTheme.primaryTitle,
      primary: defaultTheme.primaryTitle,
      focus: defaultTheme.gray,
      background: 'transparent',
      focusBackground: 'transparent',
      focusBorderColor: undefined,
      activeBorderColor: defaultTheme.input.activeBorderColor,
    },
    roundness: Metrics.moderateScale(48),
  },
  gray: {
    colors: {
      text: defaultTheme.primaryTitle,
      primary: '#2F3035',
      focus: defaultTheme.gray,
      background: '#2F3035',
      focusBackground: '#2F3035',
      focusBorderColor: '#2F3035',
      activeBorderColor: '#2F3035',
    },
    roundness: Metrics.moderateScale(48),
  },
};
