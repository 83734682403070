import React, {ReactNode, useRef} from 'react';
import {Animated, FlexStyle, Pressable, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Metrics from '../../../Theme/Metrics';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import Button, {IButtonProps} from '../../Button/Button';
import CustomFastImage from '../../CustomFastImage/CustomFastImage.web';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import Tags, {TagVariant} from '../../Tags/Tags';
import Typography from '../../Typography/Typography';

export type ServiceHeaderProps = {
  image: CustomFastImageProps['source'];
  desktopImage?: CustomFastImageProps['desktopImage'];
  topSpace?: number;
  title: string;
  description?: string;
  buttonTitle?: string;
  buttonProps?: Omit<IButtonProps, 'title'>;
  onPressButton?(e: any): void;
  bottomExtra?: ReactNode;
  upperExtra?: ReactNode;
  subTitle?: string;
  partner?: {
    label: string;
    logo: ReactNode;
    displayCol?: boolean;
  };
  percent?: {
    type: TagVariant;
    value: string;
  };
  animatedValue?: Animated.Value;
  hideCover?: boolean;
  topLogo?: CustomFastImageProps['source'];
  onTopLogoClick?: () => void;
  gradiantVariant?: 'default' | 'bottom' | 'none';
  justifyContent?: FlexStyle['justifyContent'];
  aspectRatio?: number;
  disableOverlay?: boolean;
};

export default function ServiceHeader(props: ServiceHeaderProps) {
  const {
    image: defaultImage,
    title,
    description,
    buttonProps,
    buttonTitle,
    onPressButton,
    bottomExtra,
    upperExtra,
    partner,
    subTitle,
    percent,
    // animatedValue,
    topSpace,
    hideCover,
    topLogo,
    desktopImage,
    onTopLogoClick,
    gradiantVariant,
    justifyContent = 'flex-end',
    aspectRatio = 21 / 9,
    disableOverlay = false,
  } = props;
  const layoutRef = useRef<number | null>(null);
  const {isMobile, isMobileAndMd} = useBreakpoint();
  const image = isMobile
    ? defaultImage || desktopImage
    : desktopImage || defaultImage;

  // const imageHeight = Metrics.verticalScale(493);

  /* const animatedValueInte = animatedValue
    ? animatedValue.interpolate({
        inputRange: [0, imageHeight],
        outputRange: [0, imageHeight],
        // easing: v => (imageHeight ? Math.max(imageHeight - v, 0) : 0),
      })
    : 0; */

  return (
    <Animated.View
      style={{
        // paddingTop: topSpace || Metrics.verticalScale(301),
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: 22,
        aspectRatio: isMobileAndMd ? 3 / 4 : aspectRatio,
        justifyContent: justifyContent,
      }}
      onLayout={e => {
        if (!layoutRef.current) {
          layoutRef.current = e.nativeEvent.layout.height;
        }
      }}>
      {!!upperExtra && (
        <View
          style={{
            position: 'absolute',
            left: 0,
            width: '100%',
            zIndex: 20,
            top: Metrics.verticalScale(120),
            alignItems: 'center',
          }}>
          {upperExtra}
        </View>
      )}
      {!hideCover && (
        <Animated.View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
          }}>
          <CustomFastImage
            source={image}
            resizeMode="cover"
            width={'100%'}
            height={'100%'}
          />
        </Animated.View>
      )}
      {gradiantVariant === 'default' && !disableOverlay && (
        <>
          <View
            style={{
              opacity: 0.5,
              // @ts-ignore
              background:
                'radial-gradient(197.97% 126.75% at 48.75% 63.86%, rgba(17, 18, 22, 0.44) 15.73%, #111216 75.57%)',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}></View>
          <View
            style={{
              opacity: 0.5,
              // @ts-ignore
              background:
                'radial-gradient(108.91% 111.80% at 74.53% 49.61%, rgba(17, 18, 22, 0.44) 0%, #111216 75.57%)',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}></View>
          <LinearGradient
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '100%',
              opacity: 0.5,
            }}
            colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
            locations={[0, 0.5745, 0.7432]}
            useAngle
            angle={180}></LinearGradient>
        </>
      )}
      {gradiantVariant === 'bottom' && !disableOverlay && (
        <>
          <View
            style={{
              opacity: 0.3,
              // @ts-ignore
              background:
                'linear-gradient(0deg, rgba(17, 18, 22, 0.00) 30.29%, rgba(17, 18, 22, 0.80) 86.11%, #111216 99.75%)',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '50.2%',
              width: '100%',
            }}></View>
          <View
            style={{
              opacity: 1,
              // @ts-ignore
              background:
                'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 32.29%, rgba(17, 18, 22, 0.80) 76.11%, #111216 93.75%)',
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '72.8%',
              width: '100%',
            }}></View>
        </>
      )}
      {gradiantVariant === 'none' && !disableOverlay && (
        <View
          style={{
            opacity: 0.7,
            backgroundColor: 'rgba(0, 0, 0,1)',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
          }}></View>
      )}
      {/* logo */}
      {!!topLogo && (
        <Pressable
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            alignItems: 'center',
          }}
          onPress={onTopLogoClick}>
          <CustomFastImage
            source={topLogo}
            resizeMode="cover"
            width={'100%'}
            height={'100%'}
            wrapperStyles={{
              aspectRatio: 262 / 148,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              maxWidth: 246,
            }}
          />
        </Pressable>
      )}
      {/* Content */}
      <View
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          maxWidth: isMobile ? undefined : '50%',
          marginVertical: '0',
          marginHorizontal: 'auto',
        }}>
        <View
          style={{
            justifyContent: 'center',
            flexDirection: 'row',
            position: 'relative',
          }}>
          <View
            // @ts-ignore
            style={{
              flexShrink: 'unset',
            }}>
            <Typography
              variant="h1"
              style={{
                textAlign: 'center',
                maxWidth: Metrics.horizontalScale(886),
                fontSize: isMobile ? undefined : 64,
                lineHeight: isMobile ? undefined : 64,
                flexShrink: 1,
              }}>
              {title}
            </Typography>
            {!!percent && (
              <View
                style={{
                  position: 'absolute',
                  right: Metrics.horizontalScale(-50),
                  top: Metrics.verticalScale(-5),
                }}>
                <Tags text={percent.value} variant={percent.type} />
              </View>
            )}
          </View>
        </View>
        {!!subTitle && (
          <Typography
            variant="body2"
            color="gray"
            style={{
              marginTop: Metrics.verticalScale(3),
              marginBottom: Metrics.verticalScale(8),
              textAlign: 'center',
            }}>
            {subTitle}
          </Typography>
        )}
        {!!partner && (
          <View
            style={{
              flexDirection: isMobile
                ? partner.displayCol
                  ? 'column'
                  : 'row'
                : 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: Metrics.verticalScale(11),
              marginBottom: Metrics.verticalScale(24),
              // @ts-ignore
              gap: 10,
            }}>
            <Typography
              variant="ui3"
              color="gray"
              style={{
                marginRight: Metrics.horizontalScale(10),
                top: Metrics.verticalScale(4),
                position: 'relative',
              }}>
              {partner.label}
            </Typography>
            {partner.logo}
          </View>
        )}
        {!!description && (
          <Typography
            variant="body2"
            style={{
              textAlign: 'center',
              marginTop: Metrics.verticalScale(19),
              paddingBottom: Metrics.verticalScale(
                buttonTitle || bottomExtra ? 27 : 47,
              ),
            }}>
            {description}
          </Typography>
        )}
        {!!buttonTitle && (
          <View
            style={{
              alignItems: 'center',
              marginTop: !description ? Metrics.verticalScale(19) : undefined,
            }}>
            <Button
              size="small"
              title={buttonTitle}
              onPress={onPressButton}
              {...buttonProps}
            />
          </View>
        )}
        {bottomExtra}
      </View>
    </Animated.View>
  );
}
