import {Observable} from '@legendapp/state';
import {createContext, useContext} from 'react';
import {FieldErrors, FieldValues} from 'react-hook-form';
import {FormField} from '~Api/Umbraco/interface/UmbracoTypes';

interface UmbracoFormContext<F extends FieldValues = FieldValues> {
  errors?: Observable<FieldErrors<FieldValues>>;
  mergedForm?: Observable<F>;
  fieldFilter?: (alias: string) => boolean;
  fieldMap?: (field: FormField) => FormField;
  translationContext?: string;
}

export const UmbracoFormContext = createContext<UmbracoFormContext>({});

export const useUmbracoFormContext = () => useContext(UmbracoFormContext);
