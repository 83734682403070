import React, {useMemo} from 'react';
import {View} from 'react-native';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import IconWithText from '../../../IconWithText/IconWithText';
import Tags from '../../../Tags/Tags';
import {LearnCardProps} from '../LearnCardProps';
import {learnSingleCardStyles} from './LearnSingleCard.styles';
import Typography from '../../../Typography/Typography';
import defaultTheme from '../../../../Theme/defaultTheme';
import {StyleSheet} from 'react-native';
import CustomFastImage from '../../../CustomFastImage/CustomFastImage.web';
import LinearGradient from 'react-native-linear-gradient';

export interface LearnSingleCardProps extends LearnCardProps {}

const aspectRatio = 247 / 280;
const LearnSingleCard = (props: LearnSingleCardProps) => {
  const {tag, title, isDone, infoColor, duration, textDone} = props;
  const gradientColor: (string | number)[] = useMemo(() => {
    return isDone
      ? ['rgba(234, 108, 19, 0.8)', 'rgba(84, 105, 251, 0.8)']
      : ['rgba(24, 45, 189, 0.8)', 'rgba(84, 105, 251, 0.8)'];
  }, [isDone]);

  return (
    <View style={{width: '100%', aspectRatio}} testID="learn-single-card-root">
      <View style={{position: 'absolute', width: '100%', aspectRatio, left: 0}}>
        <CustomFastImage
          source={props.cover}
          width={'100%'}
          height={'100%'}
          style={[learnSingleCardStyles.radius, {width: '100%', aspectRatio}]}
        />
        <LinearGradient
          style={StyleSheet.flatten([
            {
              width: '100%',
              height: '100%',
              position: 'absolute',
              aspectRatio,
            },
            learnSingleCardStyles.radius,
          ])}
          useAngle
          angle={360}
          locations={[0, 1]}
          colors={gradientColor}
        />
      </View>
      <View
        style={StyleSheet.flatten([
          learnSingleCardStyles.container,
          {
            width: '100%',
            height: '100%',
          },
        ])}>
        <View style={learnSingleCardStyles.header}>
          {tag ? <Tags {...tag} /> : null}
        </View>
        <View>
          <Typography
            variant="h4"
            color="white"
            style={learnSingleCardStyles.title}>
            {title}
          </Typography>
          <View style={learnSingleCardStyles.subtitle}>
            {isDone ? (
              <IconWithText
                text={textDone || 'Done!'}
                icon="checkfilled"
                size="16"
                textColor={infoColor || defaultTheme.white}
              />
            ) : (
              duration && (
                <IconWithText
                  text={duration}
                  icon="clock"
                  size="16"
                  textColor={infoColor || defaultTheme.white}
                />
              )
            )}
          </View>
          <CustomProgressBar progress={props.progress} />
        </View>
      </View>
    </View>
  );
};

export default LearnSingleCard;
