import React, {ReactNode, useMemo} from 'react';
import {View, Pressable} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import useDefaultTheme from '../../../Theme/useTheme';
import {selectSize} from '../../../tools/CardSizeTools';
import CustomFastImage from '../../CustomFastImage/CustomFastImage.web';
import CustomProgressBar from '../../CustomProgressBar/CustomProgressBar';
import IconWithText from '../../IconWithText/IconWithText';
import Tags from '../../Tags/Tags';
import {ICarouselCardProps} from '../CarouselCard/CarouselCard';
import {CarouselCardStyles} from '../CarouselCard/CarouselCard.styles';
import CarouselCardInfo from '../CarouselCard/CarouselCardInfo/CarouselCardInfo';
import RowCard from '../RowCard/RowCard.web';
import TopTenCard from '../VodCard/TopTenVodCard/TopTenCard/TopTenCard';
import PremiumIcon from '../PremiumIcon';

const SearchCard = <T extends any>(props: ICarouselCardProps & T) => {
  const {
    title,
    subtitle,
    progress,
    overlayContent,
    cover,
    size,
    tag,
    logo,
    extraContent,
    children,
    number,
    isVertical = true,
    isFreemium,
    premiumText,
    onPress,
    fullWidth = false,
  } = props;
  const theme = useDefaultTheme();

  const hasProgress: boolean = typeof progress !== 'undefined';

  // size
  const cardSize = selectSize({type: size === 'top10' ? 'small' : size});
  const width: number | string = fullWidth
    ? '100%'
    : Metrics.horizontalScale(cardSize.width);
  // const height: number = Metrics.verticalScale(cardSize.height);
  const height: number =
    size === 'news' || size === 'xsmall-news'
      ? Metrics.horizontalScale(cardSize.width) / (4 / 5)
      : Metrics.horizontalScale(cardSize.width) / (16 / 9);

  const renderExtraContent: ReactNode[] = useMemo(() => {
    return (
      extraContent?.map((extra, index) => {
        switch (extra.type) {
          case 'live':
            return (
              <IconWithText
                key={index}
                text={extra.date.start + '  •  ' + extra.date.end}
                icon="clock"
                size="16"
                color="gray"
              />
            );
          case 'date':
            return (
              <IconWithText
                key={index}
                text={extra.date}
                size="16"
                icon="date"
                color="gray"
              />
            );
          case 'custom':
            return (
              <IconWithText
                key={index}
                text={extra.text}
                size="16"
                icon={extra.icon}
                color="gray"
              />
            );

          default:
            return null;
        }
      }) || []
    );
  }, [extraContent]);
  return (
    <Pressable onPress={onPress}>
      <RowCard
        left={
          <>
            {size !== 'top10' ? (
              <View
                style={[
                  {
                    width: width,
                    height: height,
                    backgroundColor: theme.gray,
                    marginBottom: isVertical
                      ? Metrics.verticalScale(title ? 11 : 0)
                      : undefined,
                    flexDirection: 'row',
                  },
                  CarouselCardStyles.radius,
                ]}>
                {cover && (
                  <View
                    style={[
                      CarouselCardStyles.coverWrapper,
                      {
                        width: width,
                        height: height,
                      },
                    ]}>
                    <CustomFastImage
                      style={[CarouselCardStyles.coverImage]}
                      width={width}
                      height={height}
                      source={cover}
                    />
                  </View>
                )}
                <View
                  style={[
                    CarouselCardStyles.cover,
                    {
                      width: width,
                      height: height,
                      padding: Metrics.horizontalScale(9),
                      paddingBottom: hasProgress
                        ? Metrics.horizontalScale(4)
                        : undefined,
                      zIndex: 4,
                    },
                  ]}>
                  <View style={CarouselCardStyles.header}>
                    <View>
                      {tag ? (
                        <Tags
                          {...tag}
                          style={{
                            marginBottom: Metrics.verticalScale(5),
                          }}
                        />
                      ) : (
                        <View />
                      )}
                    </View>
                    {logo ?? <View />}
                  </View>

                  {/* Progress bar */}
                  {typeof progress !== 'undefined' ? (
                    <CustomProgressBar progress={progress} />
                  ) : (
                    <View />
                  )}
                </View>
                {!!overlayContent && (
                  <View
                    style={{
                      ...CarouselCardStyles.center,
                      width: width,
                      height: height,
                    }}>
                    {overlayContent}
                  </View>
                )}
              </View>
            ) : (
              <View style={CarouselCardStyles.topTenWrapper}>
                <TopTenCard
                  size="large"
                  number={typeof number !== 'undefined' ? number + '' : ''}
                  source={cover}
                  premiumText={
                    isFreemium !== undefined && !isFreemium
                      ? premiumText
                      : undefined
                  }
                />
              </View>
            )}
            {size !== 'top10' && (
              <View
                style={{
                  width: width,
                  height: height,
                  position: 'absolute',
                }}>
                <View
                  style={{
                    position: 'absolute',
                    bottom: Metrics.horizontalScale(9),
                    right: Metrics.horizontalScale(9),
                  }}>
                  {isFreemium !== undefined && !isFreemium && <PremiumIcon />}
                </View>
              </View>
            )}
          </>
        }
        right={
          !!title && (
            <CarouselCardInfo title={title} subtitle={subtitle}>
              {renderExtraContent}
              {children}
            </CarouselCardInfo>
          )
        }
        rightMarginTop={Metrics.verticalScale(10)}
      />
    </Pressable>
  );
};

export default SearchCard;
