import {
  IAwardCategoryPageFragmentFragment,
  IAwardsArtistFragmentFragment,
} from '@src/Api/Graphql/gql/types.generated';
import Typography from 'design-system/src/Components/Typography/Typography';
import React, {useMemo} from 'react';
import {View, Pressable, ScrollView} from 'react-native';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import AwardArtistCard from './AwardArtistCard/AwardArtistCard';
import {Col, Row} from 'design-system/src/WebComponent/Grid';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import GradiantText from '../../GradiantText/GradiantText';
import Metrics from 'design-system/src/Theme/Metrics';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import useNavigation from '@src/Navigation/useNavigation';
import {RouteTypename} from '@src/Navigation/type';
import {useDispatch} from 'react-redux';
import {getLinkPickerUrl} from '@src/Umbraco/Utils/element/getLinkPickerUrl';

interface IAwardCategoryArtistListProps {
  list: IAwardsArtistFragmentFragment[];
  menu?: IAwardCategoryPageFragmentFragment['menu'];
  title: string[];
  subtitle?: string[];
  categoryTitle?: string;
  description?: string;
  categoryId: string;
}
interface IMenuItem {
  id: string;
  __typename: RouteTypename;
  url?: string;
  title: string;
  subtitle?: string;
}
const topSpace: string[] = ['0', '42.7%', '16.7%', '0%', '0%'];

function shuffle<T extends any = any>(_array: T[]): T[] {
  const array: T[] = [..._array];
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const AwardCategoryArtistList = (props: IAwardCategoryArtistListProps) => {
  const {list, title, subtitle = [], categoryId, menu, description} = props;
  const {t} = useTranslation(['awards']);
  const navigation = useNavigation();
  const {isMobile} = useBreakpoint();
  const dispatch = useDispatch();

  const handleMenuClick = (item: IMenuItem | undefined) => () => {
    if (item) {
      const {id, __typename, url} = item;

      new RouteFactory(
        {
          id: id || '',
          __typename: __typename as RouteTypename,
          url: url || '',
        },
        navigation,
        dispatch,
      ).navigate();
    }
  };

  const menuList: IMenuItem[] = useMemo(() => {
    const _list: IMenuItem[] = [];
    if (menu && menu.__typename === 'AwardsCategoryMenu' && menu.menu) {
      menu.menu.forEach(menuItem => {
        if (
          menuItem &&
          menuItem.content &&
          menuItem.content.__typename === 'LinkPicker'
        ) {
          const parsedLink = getLinkPickerUrl(menuItem.content);
          if (parsedLink.__typename !== 'Unknown') {
            _list.push({
              __typename: parsedLink.__typename,
              id: parsedLink.id,
              title: menuItem.content.title || parsedLink.name || '',
              subtitle: menuItem.content.subtitle,
              url: parsedLink.url,
            });
          }
        }
      });
    }
    return _list;
  }, [menu]);

  return (
    <View>
      {/* header */}
      <View style={{alignItems: 'center', marginBottom: isMobile ? 48 : 114}}>
        <View style={{marginBottom: 40, alignItems: 'center'}}>
          {title[0] && (
            <Typography
              variant={isMobile ? 'big3' : 'big2'}
              style={{textAlign: 'center'}}>
              {title[0]}
            </Typography>
          )}
          {title[1] && (
            <GradiantText
              variant={isMobile ? 'big3' : 'big2'}
              style={{marginTop: isMobile ? 0 : -10, textAlign: 'center'}}>
              {title[1]}
            </GradiantText>
          )}
        </View>
        <View style={{alignItems: 'center'}}>
          {subtitle[0] && (
            <GradiantText
              variant="h4"
              style={{marginBottom: !subtitle[1] ? 26 : 8}}>
              {subtitle[0]}
            </GradiantText>
          )}
          {subtitle[1] && (
            <Typography variant="h2" style={{marginBottom: 26}}>
              {subtitle[1]}
            </Typography>
          )}
          <Typography
            variant="body1"
            style={{maxWidth: 573, textAlign: 'center'}}>
            {description}
          </Typography>
        </View>
      </View>

      {menuList.length ? (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={{
            marginBottom: Metrics.horizontalScale(125 / 2),
          }}
          contentContainerStyle={{
            flexDirection: 'row',
            // marginBottom: Metrics.horizontalScale(125 / 2),
            marginTop: Metrics.horizontalScale(30),
            marginLeft: 'auto',
            marginRight: 'auto',
            alignItems: 'baseline',
          }}>
          {menuList.map((d, index) => {
            const color = d.id === categoryId ? 'primary' : 'gray';
            return (
              <Pressable
                style={{
                  marginHorizontal: Metrics.horizontalScale(16),
                  justifyContent: 'center',
                }}
                key={`menu-${d.id}`}
                onPress={handleMenuClick(d)}>
                <Typography
                  color={color}
                  style={{textAlign: 'center'}}
                  variant="h2">{`${d.title}`}</Typography>

                {d.subtitle ? (
                  <Typography
                    color={color}
                    style={{textAlign: 'center'}}
                    variant="ui1">
                    {d.subtitle}
                  </Typography>
                ) : null}
              </Pressable>
            );
          })}
        </ScrollView>
      ) : null}

      {/* artist list */}
      {list.length ? (
        <Row justify="start" gutter={isMobile ? [0, 58] : [12, 125]}>
          {shuffle(list).map((artist, index) => {
            // const paddingTop = topSpace[index % 5];
            // const showBreak = index > 3 && index % 5 === 4;
            return (
              <React.Fragment key={artist.id}>
                <Col md={4}>
                  {/* {!isMobile && <View style={{paddingTop}}></View>} */}
                  <AwardArtistCard artist={artist} />
                </Col>
                {/* {showBreak && (
                <div
                  className="break"
                  style={{
                    flexBasis: '100%',
                    height: 0,
                  }}></div>
              )} */}
              </React.Fragment>
            );
          })}
        </Row>
      ) : null}
    </View>
  );
};

export default AwardCategoryArtistList;
