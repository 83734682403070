import BlockWithBackground from '@src/App/components/Common/BlocWithBackground';
import Button, {IButtonProps} from 'design-system/src/Components/Button/Button';
import {
  ExtratContent,
  renderExtraContent,
} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import Typography from 'design-system/src/Components/Typography/Typography';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import Point from 'design-system/src/WebComponent/Carousel/Point/Point';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {FC} from 'react';
import AppProgressBar from './AppProgressBar';
import AppMiniViewCarousel from './AppMiniViewCarousel';
import {View} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import AutoScaleImage from 'design-system/src/Components/AutoScaleImage/AutoScaleImage';

export interface IItemAppCarouselProps {
  backgroundUrl?: string;
  desktopBackgroundUrl?: string;
  title?: string;
  subtitle?: string;
  tags?: string[] | string;
  tagsMarginBottom?: number;
  aspectRatio?: number | [mobile: number, desktop: number];
  buttonTitle?: string;
  buttonProps?: Omit<IButtonProps, 'title'>;
  onPressButton?(e: any): void;
  date?: string;
  extraContent?: ExtratContent[];
  progress?: number;
  episode?: string;
  stay?: string;
  isMini?: boolean;
  contentWidth?: number | string;
  partner?: {
    label?: string;
    logo?: string[];
  };
}

const ItemAppCarousel: FC<IItemAppCarouselProps> = props => {
  const {isMobile} = useBreakpoint();
  const {
    backgroundUrl,
    desktopBackgroundUrl,
    title,
    tags,
    subtitle,
    aspectRatio,
    buttonProps,
    buttonTitle,
    onPressButton,
    extraContent,
    date,
    progress,
    episode,
    stay,
    isMini,
    contentWidth = isMobile ? 300 : '50%',
    tagsMarginBottom = isMobile ? 10 : 19,
    partner,
  } = props;

  const tagsComponent = (typeof tags === 'string' ? [tags] : tags)?.map(
    (item, index) => {
      return (
        <React.Fragment key={index}>
          {index > 0 && <Point isActive={true} />}
          <Typography variant="body2">{item}</Typography>
        </React.Fragment>
      );
    },
  );

  return (
    <BlockWithBackground
      aspectRatio={aspectRatio}
      backgroundUrl={
        isMobile ? backgroundUrl : desktopBackgroundUrl || backgroundUrl
      }
      style={{
        position: 'relative',
      }}>
      {isMini && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(17, 18, 22, 0.20)',
            backdropFilter: 'blur(30px)',
          }}
        />
      )}
      <div
        style={{
          width: contentWidth,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: isMobile ? '10px 10px 16px 10px' : '0 0 16px 0',
          zIndex: 1,
        }}>
        {isMini && (
          <AppMiniViewCarousel
            backgroundUrl={backgroundUrl}
            progress={progress}
            stay={stay}
          />
        )}
        {title && (
          <Typography
            variant={isMobile ? 'h1' : undefined}
            style={{
              textTransform: 'uppercase',
              fontSize: isMobile ? 32 : 64,
              lineHeight: isMobile ? undefined : 62,
            }}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant="body2"
            style={{
              fontSize: 16,
              lineHeight: 21,
            }}>
            {subtitle}
          </Typography>
        )}
        {partner && partner.logo && (
          <View
            style={{
              flexDirection: partner.logo.length > 1 ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography
              variant="ui3"
              color="gray"
              style={{
                marginRight: Metrics.horizontalScale(10),
                position: 'relative',
              }}>
              {partner.label}
            </Typography>
            <View
              style={{
                flexDirection: 'row',
                maxWidth: Metrics.horizontalScale(308),
                flexWrap: 'wrap',
              }}>
              {partner.logo.map((logo, index) => (
                <AutoScaleImage
                  key={`prt-${index}`}
                  uri={logo}
                  initWidth={60}
                  initHeight={16}
                  backgroundFallbackColor="transparent"
                  resizeMode="contain"
                />
              ))}
            </View>
          </View>
        )}
        <div
          style={{
            display: 'inline-flex',
            gap: 8,
            alignItems: 'center',
            marginTop: 6,
            marginBottom: tagsMarginBottom,
          }}>
          {tagsComponent}
        </div>
        {!!date && (
          <Typography
            variant="h4"
            color={() => defaultTheme.primary}
            numberOfLines={1}
            style={{
              color: defaultTheme.primary,
              marginBottom: 9,
            }}>
            {date}
          </Typography>
        )}
        {renderExtraContent(extraContent)}
        {buttonTitle && (
          <Button
            title={buttonTitle}
            size="small"
            onPress={onPressButton}
            {...buttonProps}
          />
        )}
        {!!episode && (
          <Typography
            variant="ui3"
            color={() => defaultTheme.gray}
            numberOfLines={1}
            style={{
              marginTop: 15,
            }}>
            {episode}
          </Typography>
        )}
        {typeof progress == 'number' && (isMini ? isMobile : true) && (
          <AppProgressBar progress={progress} stay={stay} />
        )}
      </div>
    </BlockWithBackground>
  );
};

export default ItemAppCarousel;
