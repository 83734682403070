import React from 'react';
import IconWithText from '../../../IconWithText/IconWithText';
import CardAside from '../../CardAside/CardAside';
import {LearnCardProps} from '../LearnCardProps';
import IcSettingSvg from '../../../../assets/svg/setting-icon.svg';
import Metrics from '../../../../Theme/Metrics';
import defaultTheme from '../../../../Theme/defaultTheme';
import AutoScaleImage from '../../../AutoScaleImage/AutoScaleImage';
import {View} from 'react-native';

interface LearnCardAsideProps extends LearnCardProps {
  isTrailingIconCentered?: boolean;
}

const LearnCardAside = (props: LearnCardAsideProps) => {
  const {title, titleColor, isTrailingIconCentered = true} = props;
  const partenerLogos = Array.isArray(props.partenerLogo)
    ? props.partenerLogo
    : [props.partenerLogo];
  return (
    <CardAside
      title={title}
      subtitle={props.description}
      titleColor={titleColor}
      onPressTrailingIc={props.onPressTrailingIcon}
      TrailingIc={props.hasTrailingIcon ? IcSettingSvg : undefined}
      isFilledParent={props.asideExpanded}
      isTrailingIconCentered={isTrailingIconCentered}
      isLearn={true}>
      {props.duration && (
        <IconWithText
          text={props.duration}
          icon="clock"
          textColor={props.infoColor ?? defaultTheme.gray}
        />
      )}
      <View>
        {partenerLogos
          .filter(logo => logo !== undefined)
          .map((logo, index) => (
            <AutoScaleImage
              key={`partner-logo-${index}`}
              initWidth={48}
              initHeight={10}
              backgroundFallbackColor="transparent"
              resizeMode="contain"
              uri={logo}
            />
          ))}
      </View>
    </CardAside>
  );
};

export default LearnCardAside;
