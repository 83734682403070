import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React from 'react';

const useResponsiveSpacing = () => {
  const {mobile} = useBreakpoint();

  return {
    isMobile: mobile,
    gap: mobile ? 8 : 16,
    profile: {
      verticalgap: mobile ? 20 : 28,
    },
  };
};

export default useResponsiveSpacing;
