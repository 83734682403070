import React, {createContext} from 'react';
import {PageProps} from 'gatsby';

export type PageContextI18n = {
  lang: string;
  language: string;
  i18n?: {
    language: string;
    languages: string[];
    defaultLanguage: string;
    path: string;
    generateDefaultLanguagePage: boolean;
    routed: boolean;
  };
};
type PagePropsI18n = Partial<PageProps<any, PageContextI18n>>;

const PageContext = createContext<PagePropsI18n>({});

const PageContextProvider = PageContext.Provider;

const usePageContext = () => {
  return React.useContext<PagePropsI18n>(PageContext);
};

export {PageContextProvider, usePageContext};
export default PageContext;
