import {
  ILinkIconFragmentFragment,
  ILinkPickerFragmentFragment,
  IPushFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import {IUmbracoRouteParams} from '~Umbraco/Interface/Umbraco.interface';

export const getLinkPickerUrl = (
  linkPicker:
    | ILinkPickerFragmentFragment
    | Pick<ILinkIconFragmentFragment, 'link'>,
): IUmbracoRouteParams => {
  const {link} = linkPicker;
  if (link && link[0] && link[0].content.__typename === 'InternalLink') {
    if (link[0].content.link && !isElementRestricted(link[0].content.link)) {
      const {url, __typename, id, name} = link[0].content.link;
      return {url: url || '', __typename, id, name: name};
    }
  } else if (link && link[0] && link[0].content.__typename === 'ContentLink') {
    if (
      link[0].content.link &&
      link[0].content.link.__typename &&
      !isElementRestricted(link[0].content.link)
    ) {
      const {url, __typename, id, name} = link[0].content.link;
      return {url: url || '', __typename, id, name};
    }
  } else if (
    link &&
    link[0] &&
    link[0].content &&
    link[0].content.__typename === 'ExternalLink'
  ) {
    return {
      url: link[0].content.externalUrl || '',
      __typename: 'ExternalLink',
      id: '',
      name: link[0].content.title,
    };
  }
  return {url: '', __typename: 'Unknown', id: ''};
};

export const getLinkUrl = (
  link: IPushFragmentFragment['link'],
): IUmbracoRouteParams => {
  if (link && link[0] && link[0].content.__typename === 'InternalLink') {
    if (link[0].content.link && !isElementRestricted(link[0].content.link)) {
      const {url, __typename, id} = link[0].content.link;
      return {url: url || '', id, __typename};
    }
  } else if (link && link[0] && link[0].content.__typename === 'ContentLink') {
    if (
      link[0].content.link &&
      link[0].content.link.__typename &&
      !isElementRestricted(link[0].content.link)
    ) {
      const {url, __typename} = link[0].content.link;
      return {url: url || '', id: link[0].content.link.id || '', __typename};
    }
  } else if (
    link &&
    link[0] &&
    link[0].content &&
    link[0].content.__typename === 'ExternalLink'
  ) {
    return {
      url: link[0].content.externalUrl || '',
      __typename: 'ExternalLink',
      id: '',
    };
  }
  return {__typename: 'Unknown', id: '', url: ''};
};
