import React from 'react';
import {View} from 'react-native';
import {darkTheme} from '../../../../styles/Themes';
import Metrics from '../../../../Theme/Metrics';
import {selectSize} from '../../../../tools/CardSizeTools';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import PostTypeText from '../../../Text/PostTypeText/PostTypeText';
import GenericCard from '../../GenericCard/GenericCard';
import VodCardAside from '../VodCardAside/VodCardAside';
import {VodCardProps} from '../VodCardProps';

const VodHomeCard = (props: VodCardProps & {onPress?(): void}) => {
  const size = React.useCallback(selectSize, [props])(props, false);
  const {cover, vodTypeColor, vodeType, isWithProgressBar, progress, onPress} =
    props;

  return (
    <View
      style={{
        width: Metrics.horizontalScale(size.width),
      }}>
      <View style={{marginBottom: Metrics.verticalScale(11)}}>
        <GenericCard
          coverImage={cover}
          onPress={onPress}
          backgroundColor={darkTheme.colors.fifthGray}
          contentStyle={{
            paddingBottom: isWithProgressBar
              ? Metrics.verticalScale(4)
              : undefined,
          }}
          width={size.width}
          height={size.height}
          center={
            vodeType && (
              <PostTypeText textColor={vodTypeColor} text={vodeType} />
            )
          }
          footer={
            isWithProgressBar && <CustomProgressBar progress={progress} />
          }
        />
      </View>
      <VodCardAside asideProps={props} />
    </View>
  );
};

export default VodHomeCard;
