import Constants from './Constant';
import {Platform} from 'react-native';

export enum CardSizeType {
  small,
  medium,
  big,
}

export type CardSizeOptions<T = boolean> = {
  [key in keyof typeof CardSizeType]: T;
};

export type CardSize =
  | 'small'
  | 'small_w'
  | 'medium'
  | 'big'
  | 'xsmall'
  | 'xsmall_w'
  | 'channel'
  | 'news'
  | 'xsmall-news'
  | 'top10'
  | 'xbig';

export const selectSize: <
  T extends Partial<{
    width: number;
    height: number;
    /* The `type` variable is used to determine the size of the card. It is an optional property in the
    `argProps` argument of the `selectSize` function. If `type` is not provided, it defaults to
    `'big'`. The `type` can be one of the following values: `'small'`, `'medium'`, `'big'`,
    `'xsmall'`, `'channel'`, `'news'`, `'xsmall-news'`, `'top10'`, or `'xbig'`. Based on the value
    of `type`, the `selectSize` function calculates and returns the corresponding width and height
    values for the card size. */
    type?: CardSize;
  }>,
>(
  argProps: T,
  isLearnType?: boolean,
) => {width: number; height: number} = (argProps, isLearnType) => {
  const isWeb = Platform.OS === 'web';
  const {type = 'big', width, height} = argProps;
  let size = {width: 0, height: 0};
  if (type === 'small') {
    size = {
      // width: isWeb ? 254 : Constants.SMALL_WIDTH,
      width: Constants.SMALL_WIDTH,
      height: !isLearnType ? Constants.SMALL_HEIGHT : Constants.LEARN_SHEIGHT,
    };
  } else if (type === 'small_w') {
    size = {
      width: Constants.SMALL_WIDTH_W,
      height: !isLearnType ? Constants.SMALL_HEIGHT_W : Constants.LEARN_SHEIGHT,
    };
  } else if (type === 'big' || type === 'top10') {
    size = {
      width: isWeb ? 654 : Constants.BIG_WIDTH,
      height: Constants.BIG_HEIGHT,
    };
  } else if (type === 'xbig') {
    size = {
      width: Constants.XBIG_WIDTH,
      height: Constants.XBIG_HEIGHT,
    };
  } else if (type === 'medium') {
    size = {
      width: isWeb ? 321 : Constants.MEDIUM_WIDTH,
      height: !isLearnType ? Constants.MEDIUM_HEIGHT : Constants.LEARN_MHEIGHT,
    };
  } else if (type === 'xsmall' || type === 'xsmall-news') {
    size = {
      width: Constants.XSMALL_WIDTH,
      height: !isLearnType ? Constants.XSMALL_HEIGHT : Constants.LEARN_XSHEIGHT,
    };
  } else if (type === 'xsmall_w') {
    size = {
      width: Constants.XSMALL_WIDTH_W,
      height: Constants.XSMALL_HEIGHT_W,
    };
  } else if (type === 'channel') {
    size = {
      width: isWeb ? 210 : 168,
      height: 94,
    };
  } else if (type === 'news') {
    size = {
      width: isWeb ? 321 : Constants.SMALL_WIDTH,
      height: Constants.NEWS_HEIGHT,
    };
  } else {
    size = {
      width: isWeb ? 654 : Constants.BIG_WIDTH,
      height: Constants.BIG_HEIGHT,
    };
  }
  if (width) {
    size.width = width;
  }
  if (height) {
    size.height = height;
  }
  return size;
};
