import {usePasswordValidation} from '@src/Hooks/usePasswordValidation';
import PasswordController, {
  PasswordControllerProps,
} from 'design-system/src/Components/Form/PasswordController/PasswordController';
import React from 'react';
import {FieldValues} from 'react-hook-form';
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface IPasswordFieldProps<TField extends FieldValues = FieldValues>
  extends Omit<PasswordControllerProps<TField>, 'rules' | 'rulesHelpers'> {}

const PasswordField = (props: IPasswordFieldProps) => {
  const {rules: passwordRules} = usePasswordValidation();
  const {t} = useTranslation('form');

  return (
    <PasswordController
      {...props}
      // rules={rules}
      rules={{
        validate: value => {
          const regexList = passwordRules.map(
            element => new RegExp(element.regex || '.*'),
          );

          const result = regexList.every(ref => ref.test(value));
          return result ? true : t('Password validation error') || '';
        },
      }}
      rulesHelpers={passwordRules.map(item => {
        return {
          label: item.label || '',
          regex: new RegExp(item.regex || '.*'),
        };
      })}
    />
  );
};

export default PasswordField;
