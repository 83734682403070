import {IBubbleChartData} from 'design-system/src/Components/BubbleChart/BubbleChart';
import {
  IJobVacancyFragmentFragment,
  IOpportunityFragmentFragment,
} from '~Api/Graphql/gql/types.generated';

export class OpportunityOverview {
  public static getOppOverview(
    opportunities:
      | IOpportunityFragmentFragment[]
      | IJobVacancyFragmentFragment[],
  ): Array<IBubbleChartData> {
    let data: Array<IBubbleChartData & {id: string}> = [];
    opportunities.forEach(opportunity => {
      let {personalities, themes, interests} = opportunity;
      if (personalities) {
        personalities.forEach(item => {
          if (item && item.__typename === 'ContentTag') {
            const {name, color, id} = item;
            const findIndex = data.findIndex(item => item.id === id);
            if (findIndex !== -1) {
              data[findIndex] = {
                ...data[findIndex],
                value: data[findIndex].value + 1,
              };
            } else {
              data.push({
                name: name || '',
                color: color || '#FA6217',
                value: 1,
                id,
              });
            }
          }
        });
      }
      if (themes) {
        themes.forEach(item => {
          if (item && item.__typename === 'ContentTag') {
            const {name, color, id} = item;
            const findIndex = data.findIndex(item => item.id === id);
            if (findIndex !== -1) {
              data[findIndex] = {
                ...data[findIndex],
                value: data[findIndex].value + 1,
              };
            } else {
              data.push({
                name: name || '',
                color: color || '#FA6217',
                value: 1,
                id,
              });
            }
          }
        });
      }
      if (interests) {
        interests.forEach(item => {
          if (item && item.__typename === 'ContentTag') {
            const {name, color, id} = item;
            const findIndex = data.findIndex(item => item.id === id);
            if (findIndex !== -1) {
              data[findIndex] = {
                ...data[findIndex],
                value: data[findIndex].value + 1,
              };
            } else {
              data.push({
                name: name || '',
                color: color || '#FA6217',
                value: 1,
                id,
              });
            }
          }
        });
      }
    });
    return data;
  }
}
