import React, {useEffect, useState} from 'react';
import {View, TextInput} from 'react-native';
import {getCountryCallingCodeAsync as getCallingCode} from 'react-native-country-picker-modal/lib/CountryService';
import {CountryModalProvider} from 'react-native-country-picker-modal/lib/CountryModalProvider';
import {PhoneNumberPickerProps} from './PhoneNumberPickerProps';
import PhoneNumberPickerStyles from './PhoneNumberPicker.styles';
import FloatingLabel from '../FloatingLabel/FloatingLabel';
import {typographyVariantStyled} from '../../Typography/Typography.styles';
// @ts-ignore
// import 'react-country-dropdown/dist/index.css';
import CountryPicker from '../CountryPicker/CountryPicker.web';
import {PhoneNumberUtil, PhoneNumberFormat} from 'google-libphonenumber';

let instance: PhoneNumberUtil;

const preloadGoogleLibPhoneNumber = async function () {
  if (!instance) {
    console.log('preloadGoogleLibPhoneNumber PhoneNumberInput');
    const libphoneNumber = await import('google-libphonenumber');
    instance = libphoneNumber.PhoneNumberUtil.getInstance();
    return instance;
  }
  return instance;
};

const PhoneNumberPicker = (props: PhoneNumberPickerProps) => {
  const {
    onChangeFormattedText,
    onChangeText,
    placeholder,
    autoFocus,
    defaultCode,
    value,
    style,
  } = props;

  const [code, setCode] = useState<string | undefined>(
    defaultCode ? undefined : '33',
  );
  const [number, setNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState(defaultCode || 'FR');
  const [disabled, setDisabled] = useState<boolean | undefined>(
    props.disabled || false,
  );
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    const initData = async () => {
      let phoneUtil: PhoneNumberUtil | undefined =
        await preloadGoogleLibPhoneNumber();

      if (props.value) {
        try {
          const parsedNumber = phoneUtil.parse(props.value);
          const countryCode = phoneUtil.getRegionCodeForNumber(parsedNumber);
          const nationalNumber = phoneUtil.format(
            parsedNumber,
            PhoneNumberFormat.NATIONAL,
          );
          const callingCode = phoneUtil.getCountryCodeForRegion(
            countryCode || 'FR',
          );

          setCountryCode((countryCode || 'FR') as any);
          setCode(callingCode.toString());
          // This code removes all whitespace characters from the nationalNumber string,
          // removes any leading zero, and sets the resulting string as the new value of the number state.
          setNumber(nationalNumber.replace(/\s/g, '').replace(/^0+/, ''));
        } catch (e: any) {
          console.log('Exception parsing phone number:', e.message);
          setNumber('');
        }
      } else {
        setNumber('');
      }

      setDisabled(props.disabled || false);
    };

    initData();
  }, [props.value, props.disabled]);

  const onSelect = async (country: any) => {
    setCountryCode(country.code);
    const newCode = await getCallingCode(country.code);
    setCode(newCode);
    await formatAndValidatePhoneNumber(country.code, number);
  };

  const handleOnChangeText = async (text: string) => {
    setNumber(text);
    if (onChangeText) {
      onChangeText(text);
    }
    await formatAndValidatePhoneNumber(countryCode || 'FR', text);
  };

  const formatAndValidatePhoneNumber = async (
    _countryCode: string,
    number: string,
  ) => {
    if (instance) {
      try {
        const parsedNumber = instance.parse(number, _countryCode);

        const formattedNumber = instance.format(
          parsedNumber,
          PhoneNumberFormat.INTERNATIONAL,
        );

        setNumber(formattedNumber);
        if (onChangeFormattedText) {
          onChangeFormattedText(`${formattedNumber}`);
        }
      } catch (e: any) {
        console.log('Error formatting phone number:', e.message);
        if (onChangeFormattedText) {
          onChangeFormattedText('');
        }
      }
    }
  };

  const handleOnBlur = () => {
    setFocus(false);
  };

  return (
    <FloatingLabel
      hasValue={!!number}
      hasFocus={focus}
      disableLabel={false}
      label={!focus && !number ? '' : placeholder}
      style={[PhoneNumberPickerStyles.root, style]}>
      <CountryModalProvider>
        <View style={[PhoneNumberPickerStyles.container]}>
          <CountryPicker onSelect={onSelect} countryCode={countryCode} />
          {/* <Pressable 
            style={[
              PhoneNumberPickerStyles.flagButtonView,
              PhoneNumberPickerStyles.flagButtonExtraWidth,
            ]}
            disabled={disabled}
            onPress={() => setModalVisible(true)}>
            <CountryPicker 
              onSelect={onSelect}
              withEmoji
              withFilter
              withFlag
              // filterProps={filterProps}
              countryCode={countryCode}
              withCallingCode
              disableNativeModal={disabled}
              visible={modalVisible}
              theme={MY_THEME} 
              renderFlagButton={renderFlagButton}
              onClose={() => setModalVisible(false)}
              
              modalProps={{style:{
                width: 100,
                height: 100,
              }, }}
            />
            <Typography
              variant="body2"
              color="white">{`(+${code})`}</Typography>
            <Icon name="selectform" size="24" />
          </Pressable> */}

          <View style={[PhoneNumberPickerStyles.textContainer]}>
            <TextInput
              style={[
                PhoneNumberPickerStyles.numberText,
                {
                  fontSize: typographyVariantStyled.body2.fontSize,
                  fontFamily: typographyVariantStyled.body2.fontFamily,
                  letterSpacing: typographyVariantStyled.body2.letterSpacing,
                },
              ]}
              placeholderTextColor={'#FFFFFF'}
              placeholder={placeholder ? placeholder : 'Phone Number'}
              onChangeText={handleOnChangeText}
              defaultValue={value}
              value={number}
              editable={disabled ? false : true}
              selectionColor="#FFFFFF"
              keyboardAppearance={'dark'}
              keyboardType="number-pad"
              autoFocus={autoFocus}
              onBlur={handleOnBlur}
              onFocus={() => setFocus(true)}
              blurOnSubmit
            />
          </View>
        </View>
      </CountryModalProvider>
    </FloatingLabel>
  );
};

export default PhoneNumberPicker;
