import React from 'react';
import {View, StyleSheet} from 'react-native';
import {IconName} from '../../assets/Icones';
import Metrics from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import ContentLoader, {Rect} from 'react-content-loader/native';

export type ItemOverviewProps = {
  title: string;
  data: {
    multimedia?: string;
    badges?: string;
    videos?: string;
    quizzes?: string;
    hours?: string;
  };
  loading?: boolean;
};

const OverviewLoader = () => {
  const width = Metrics.horizontalScale(200);
  const height = Metrics.verticalScale(20);
  return (
    <ContentLoader
      style={StyleSheet.flatten([
        {
          backgroundColor: 'rgba(23, 24, 29, 0.7)',
          overflow: 'hidden',
          marginBottom: Metrics.verticalScale(20),
        },
      ])}
      height={height}
      width={width}
      speed={1}
      backgroundColor={'rgba(23, 24, 29, 0.7)'}
      foregroundColor={'#111216'}>
      <Rect
        x="0"
        y="0"
        rx="16"
        ry="16"
        width={`${width}`}
        height={`${height}`}
        fill={'#111216'}
      />
    </ContentLoader>
  );
};

export default function ItemOverview(props: ItemOverviewProps) {
  const {title, data, loading} = props;
  return (
    <View
      style={{
        marginBottom: Metrics.verticalScale(24),
      }}>
      <Typography
        variant="h3"
        style={{marginBottom: Metrics.verticalScale(21)}}>
        {title}
      </Typography>
      <View>
        {Object.keys(data).map(
          (o, index) =>
            data[o] !== undefined && (
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom:
                    index < Object.keys(data).length - 1
                      ? Metrics.verticalScale(11)
                      : 0,
                }}
                key={`io-${index}`}>
                <View style={{flexBasis: Metrics.horizontalScale(47)}}>
                  <Icon name={o as IconName} />
                </View>
                {!loading ? (
                  <Typography variant="body2">{data[o]}</Typography>
                ) : (
                  <OverviewLoader />
                )}
              </View>
            ),
        )}
      </View>
    </View>
  );
}
