import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const devicesStyles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: Metrics.horizontalScale(16),
  },
  title: {
    marginBottom: Metrics.verticalScale(21),
  },
  gridContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  item: {
    marginBottom: Metrics.verticalScale(16),
    width: Metrics.horizontalScale(150),
  },
});
