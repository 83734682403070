import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import {useWindowDimensions} from 'react-native';
import {
  TabView as RNTabView,
  SceneMap,
  Route,
  SceneRendererProps,
} from 'react-native-tab-view';
import CustomTabBar from './CustomTabBar/CustomTabBar';
import DefaultTabBar from './DefaultTabBar/DefaultTabBar';

export interface ITabItemType {
  key: string;
  label: string;
  component?: React.ComponentType<unknown>;
}

export interface ITabViewProps {
  activeKey?: string;
  currentIndex?: number;
  onChangeIndex?: (index: number) => void;
  items: ITabItemType[];
  customRenderScene: (
    props: SceneRendererProps & {route: Route},
  ) => React.ReactNode;
  variant?: 'custom' | 'default';
  swipeEnabled?: boolean;
  noHeader?: boolean;
}

const TabView = (props: ITabViewProps) => {
  const {
    items,
    customRenderScene,
    variant = 'custom',
    currentIndex,
    onChangeIndex,
    swipeEnabled = true,
    noHeader = false,
  } = props;
  const layout = useWindowDimensions();
  const [, startTransition] = useTransition();
  const divRef = useRef<HTMLDivElement>(null);

  const TabBar = variant === 'custom' ? CustomTabBar : DefaultTabBar;

  const [index, setIndex] = useState(currentIndex || 0);

  useEffect(() => {
    if (typeof currentIndex !== 'undefined' && currentIndex !== index) {
      setIndex(currentIndex);
    }
  }, [currentIndex, index]);

  const {
    routes,
    renderScene,
  }: {
    routes: Route[];
    renderScene: ReturnType<typeof SceneMap>;
  } = useMemo(() => {
    const output: Route[] = [];
    const sceneObjec: {
      [key: string]: React.ComponentType<unknown>;
    } = {};

    items.forEach(item => {
      output.push({
        key: item.key,
        title: item.label,
      });
      if (item.component) {
        sceneObjec[item.key] = item.component;
      }
    });
    return {routes: output, renderScene: SceneMap(sceneObjec)};
  }, [items]);

  const handleIndexChange = (itemIndex: number) => {
    startTransition(() => {
      setIndex(itemIndex);
    });
    onChangeIndex && onChangeIndex(itemIndex);
  };
  const [divWidth, setDivWidth] = useState(0);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          setDivWidth(entry.contentRect.width);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    const divElement = divRef.current;
    if (divElement) {
      resizeObserver.observe(divElement);
    }

    return () => {
      if (divElement) {
        resizeObserver.unobserve(divElement);
      }
    };
  }, []);

  return (
    <div ref={divRef} style={{width: '100%'}} className="tab-view">
      <RNTabView
        keyboardDismissMode="none"
        navigationState={{index, routes}}
        renderScene={customRenderScene ?? renderScene}
        onIndexChange={handleIndexChange}
        initialLayout={{width: divWidth}}
        renderTabBar={noHeader ? () => null : TabBar}
        swipeEnabled={swipeEnabled}
      />
    </div>
  );
};

export default TabView;
