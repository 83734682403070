import dayjs from 'dayjs';
import liveStreamSlice from '~Store/Slices/LiveStream.slice';
import {IEvent, IEventVideoResponse, ITokenResponse} from '../interface/livestream';
import {TraceApiEndpointBuilder} from '../traceApi';
import advancedFormat from 'dayjs/plugin/advancedFormat'; // load on demand

dayjs.extend(advancedFormat);


export const createLivestreamEndpoint = (builder: TraceApiEndpointBuilder) => ({
  getLiveStreamToken: builder.query<ITokenResponse, void>({
    forceRefetch: () => true,
    async queryFn(_, {getState}, __, baseQuery) {
      try {
        const rootState = getState() as any;

        const {timestamp, token} = rootState.liveStream;
        if (timestamp && token) {
          const timestampDiff = dayjs().diff(
            dayjs.unix(+timestamp / 1000),
            'minutes',
          );

          if (timestampDiff < 4) {
            return {
              data: {
                timestamp,
                token,
              },
              meta: '',
            };
          }
        }
        const response = await baseQuery({
          url: '/token',
          method: 'GET',
        });

        return {
          data: response.data as ITokenResponse,
          error: response.error as any,
          meta: response.meta,
        };
      } catch (err) {
        return {
          error: {status: '', data: ''},
          data: undefined,
          meta: undefined,
        };
      }
    },
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      // `onStart` side-effect
      try {
        const {data} = await queryFulfilled;
        dispatch(
          liveStreamSlice.actions.setListeStreamToken({
            ...data,
          }),
        );
      } catch (err) {
        // `onError` side-effect
      }
    },
  }),
  getEventById: builder.query<IEvent, string>({
    query: (eventId: string) => ({
      url: `/livestream/events/${eventId}`,
      method: 'GET',
    }),
  }),
  getEventVideo: builder.query<IEventVideoResponse, string>({
    query: (eventId: string) => ({
      url: `/livestream/events/${eventId}/video`,
      method: 'GET',
    }),
  }),
});
