import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const cardAsideStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leading: {
    alignSelf: 'flex-start',
    flex: 1,
  },
  title: {
    marginBottom: Metrics.verticalScale(5),
    marginTop: Metrics.verticalScale(5),
    maxWidth: Metrics.horizontalScale(170),
  },
  subtitle: {
    marginBottom: Metrics.verticalScale(6),
  },
  itemMargin: {
    marginBottom: Metrics.verticalScale(5),
  },
});
