import {GetServerDataProps, graphql, HeadFC} from 'gatsby';
import React from 'react';
import {PageProps} from 'gatsby';
import AwardCategoryPage from '@src/Umbraco/Pages/AwardPage/AwardCategoryPage/AwardCategoryPage';
import PageHead from '@src/components/general/PageHead/PageHead';
import {apolloStaticInstance} from '@src/Api/Graphql';
import {gql} from '@apollo/client';

const AwardCategory = (props: PageProps) => {
  const {
    params,
    // @ts-ignore
    id: mainId,
  } = props;
  const {id} = params || {};

  return (
    <div>
      <AwardCategoryPage id={id || mainId} />
    </div>
  );
};
AwardCategory.layoutComponent = 'AwardLayout';
AwardCategory.layoutProps = {};

export default AwardCategory;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export async function getServerData(props: GetServerDataProps) {
  try {
    const {params} = props;
    const categoryId: string = (params?.id as string) || '';
    if (categoryId) {
      const client = await (
        await apolloStaticInstance
      ).query<
        {
          awardCategoryPage: {
            __typename: string;
            id: string;
            urlRewrite: string;
            metaTitle: string;
            metaDescription: string;
          };
        },
        {id: string}
      >({
        query: gql`
          query AwardCategoryPageById($id: ID, $culture: String) {
            awardCategoryPage(id: $id, culture: $culture) {
              id
              urlRewrite
              metaTitle
              metaDescription
            }
          }
        `,
        variables: {
          id: categoryId,
        },
        fetchPolicy: 'network-only',
      });

      return {
        props: client.data,
      };
    }
    return {
      props: {},
    };
  } catch (error) {
    console.log('error', error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export const Head: HeadFC<Queries.AwardHomePageQuery, any> = headProps => {
  const {
    params: {id},
    serverData,
  } = headProps;
  const {awardCategoryPage} = (serverData as any) || {};
  return (
    <PageHead
      title={awardCategoryPage?.metaTitle}
      description={awardCategoryPage?.metaDescription}
      {...headProps}
    />
  );
};
