import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Metrics from 'design-system/src/Theme/Metrics';
import {TFunction} from 'i18next';
import React from 'react';
import dayjs from 'dayjs';
import {
  IElementFragment_ContentSlider_Fragment,
  ILiveEventFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import {IContentSliderItem} from '~Umbraco/Components/ContentSlider/ContentSlider';

export const mapContentItems = (
  contentItems: IElementFragment_ContentSlider_Fragment['contentItems'],
  findProgression: (id: string) => number,
  canSeeContent: (
    accessType?: ILiveEventFragmentFragment['accessType'],
  ) => boolean,
  calculateLiveEventProgress?: (startDate: any, endDate: any) => number,
  options?: {
    t: TFunction;
  },
): IContentSliderItem[] => {
  const {t} = options || {};
  const items: IContentSliderItem[] = [];
  contentItems?.forEach(item => {
    if (item && !isElementRestricted(item)) {
      if (item.__typename === 'Collection' && item.url && item.url !== '#') {
        const {
          id,
          title,
          name,
          subtitle,
          thumbnail,
          url = '',
          __typename,
        } = item;
        url &&
          items.push({
            id,
            title: title || name || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            isFreemium: canSeeContent(item.accessType),
          });
      } else if (
        item.__typename === 'VODItem' &&
        item.url &&
        item.url !== '#'
      ) {
        let {
          title,
          name,
          subtitle,
          thumbnail,
          url = '',
          __typename,
          id,
          length,
        } = item;
        const parentAccessType =
          item.parent && item.parent.__typename === 'Collection'
            ? item.accessType
            : undefined;
        url &&
          items.push({
            id,
            title: title || name || '',
            subtitle: subtitle || '',
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: findProgression(id),
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            isFreemium:
              canSeeContent(item.accessType) && canSeeContent(parentAccessType),
          });
      } else if (item.__typename === 'Radio' && item.url && item.url !== '#') {
        const {id, title, subtitle, thumbnail, url = '', __typename} = item;
        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
      } else if (
        item.__typename === 'Podcast' &&
        item.url &&
        item.url !== '#'
      ) {
        let {
          id,
          title,
          subtitle,
          thumbnail,
          url = '',
          __typename,
          length,
        } = item;
        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: findProgression(id),
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
      } else if (
        item.__typename === 'Playlist' &&
        item.url &&
        item.url !== '#'
      ) {
        const {id, title, subtitle, thumbnail, url = '', __typename} = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
      } else if (
        item.__typename === 'PodcastItem' &&
        item.url &&
        item.url !== '#'
      ) {
        const {
          id,
          name,
          thumbnail,
          shortDescription,
          url = '',
          __typename,
          length,
          // shortDescription,
          // thumbnail,
        } = item;
        url &&
          items.push({
            id,
            title: name || '',
            subtitle: shortDescription,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: findProgression(id),
            accessData: {},
          });
      } else if (
        item.__typename === 'LiveEvent' &&
        item.url &&
        item.url !== '#'
      ) {
        const {
          id,
          title,
          subtitle,
          thumbnail,
          url = '',
          __typename,
          startDate,
          endDate,
          channel,
        } = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            logo:
              channel && channel.__typename === 'Channel' && channel.logo ? (
                <CustomFastImage
                  source={{uri: channel.logo.url}}
                  width={Metrics.horizontalScale(48)}
                  height={Metrics.verticalScale(33.5)}
                />
              ) : undefined,
            tag:
              dayjs().isAfter(startDate) && dayjs().isBefore(endDate)
                ? {
                    variant: 'live',
                    text: t ? t('Live Tv') : 'Live Tv',
                  }
                : undefined,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: calculateLiveEventProgress
              ? calculateLiveEventProgress(startDate, endDate)
              : 0.0,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            isFreemium: canSeeContent(item.accessType),
          });
      } else if (item.__typename === 'News') {
        const {
          id,
          title,
          shortDescription,
          thumbnail,
          url = '',
          __typename,
        } = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: shortDescription,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: undefined,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
      }
    }
  });

  return items;
};
