import React, {Suspense, useEffect} from 'react';
import store from './Store';
import {Provider} from 'react-redux';
// import {PersistGate} from 'redux-persist/integration/react';
import {ApolloProvider} from '@apollo/client';
import InnerProvider from './Providers/InnerProvider/InnerProvider';
import {initAmplitude} from './Services/Amplitude/amplitudeInit';
import DidomiInitProvider from './Providers/DidomiInitProvider/DidomiInitProvider';
import PageLoader from './components/general/PageLoader/PageLoader';
import {useApiClient} from './Store/selectors/useApiClient';
import BrazeProvider from './Providers/BrazeProvider/BrazeProvider';

export type RunContext = 'ssr' | 'browser';

interface IAppProviderProps {
  children: React.ReactElement;
}

const AppProvider = (props: IAppProviderProps) => {
  const {children} = props;
  const {client} = useApiClient();

  useEffect(() => {
    initAmplitude();
  }, []);

  return (
    <Provider store={store}>
      <DidomiInitProvider />
      <BrazeProvider />
      {/* <PersistGate loading={<PageLoader />} persistor={persistor}> */}
      {client ? (
        <Suspense fallback={<PageLoader />}>
          <ApolloProvider client={client}>
            <InnerProvider>{children}</InnerProvider>
          </ApolloProvider>
        </Suspense>
      ) : (
        <PageLoader />
      )}
      {/* </PersistGate> */}
    </Provider>
  );
};

export default AppProvider;
