import React, {ReactNode, useEffect, useRef} from 'react';
import {Platform, StyleProp, View, ViewStyle} from 'react-native';
import Metrics from '../../Theme/Metrics';
import {IMediaCover} from '../../Components/Card/CarouselCard/CarouselCard';
import CustomFastImage from '../../Components/CustomFastImage/CustomFastImage';
import {useVideoPlayer} from '../Player/Hooks/useVideoPlayer';
import defaultTheme from '../../Theme/defaultTheme';
import LinearGradient from 'react-native-linear-gradient';
import {MediaCoverStyles} from './MediaCover.styles';
import useMediaCover from '../Discover/useMediaCover';

// TODO: Video player for web
let Video: any;
if (Platform.OS !== 'web') {
  Video = require('react-native-video').default;
} else {
  Video = () => <div>video player</div>;
}

export interface IMediaCoverProps {
  cover?: IMediaCover;
  isActive?: boolean;
  children?: ReactNode;
  height?: number;
  orientation?: 'portrait' | 'landscape';
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  absoluteChilren?: () => ReactNode;
  bottomGradientStyles?: StyleProp<ViewStyle>;
  bottomGradientLocation?: number[];
}

const MediaCover = (props: IMediaCoverProps) => {
  const {
    cover,
    isActive,
    children,
    height,
    style,
    absoluteChilren,
    contentContainerStyle,
    bottomGradientStyles,
    bottomGradientLocation,
    orientation = 'portrait',
  } = props;
  const videoRef = useRef<any>(undefined);
  const {media, fetchMedia} = useMediaCover(cover);
  const isWeb = Platform.OS === 'web';

  const isLandscape = orientation === 'landscape';
  const width = isWeb
    ? '100%'
    : isLandscape
      ? Metrics.window.height
      : Metrics.window.width;

  const {
    onLoad,
    onVideoLoadStart,
    onVideoBuffer,
    onReadyForDisplay,
    onProgress,
  } = useVideoPlayer(videoRef);

  const defaultHeight = isWeb ? '100%' : height || Metrics.verticalScale(502);

  useEffect(() => {
    fetchMedia();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cover]);

  return (
    <>
      <View
        style={[
          {position: 'relative'},
          MediaCoverStyles.root,
          {height: height},
          style,
        ]}>
        <View
          style={{
            position: 'absolute',
            flex: 1,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: defaultHeight,
            backgroundColor: defaultTheme.player.background,
          }}>
          {!!media &&
            (media.type === 'image' ? (
              <CustomFastImage
                source={media.uri}
                resizeMode="cover"
                width={Platform.OS === 'web' ? '100%' : width}
                height={'100%'}
                style={{flex: 1}}
              />
            ) : (
              media.type === 'video' && (
                <Video
                  ref={videoRef}
                  source={media.uri}
                  paused={!isActive}
                  repeat={true}
                  ignoreSilentSwitch="ignore"
                  fullscreen={false}
                  resizeMode={'cover'}
                  onProgress={onProgress}
                  onLoadStart={onVideoLoadStart}
                  onReadyForDisplay={onReadyForDisplay}
                  onLoad={onLoad}
                  // poster={poster}
                  pictureInPicture={true}
                  posterResizeMode="cover"
                  onBuffer={onVideoBuffer}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
              )
            ))}
        </View>
        <LinearGradient
          useAngle
          angle={360}
          locations={[0.4486, 0.7163, 1]}
          colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
          style={{
            opacity: 0.5,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            height: defaultHeight,
          }}
        />
        <LinearGradient
          locations={bottomGradientLocation || [0.45, 0.67, 0.85]}
          colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.7)', '#111216']}
          style={[
            {
              opacity: 1,
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: defaultHeight,
            },
            bottomGradientStyles,
          ]}
        />
        <View style={MediaCoverStyles.root}>
          <View style={[MediaCoverStyles.content, contentContainerStyle]}>
            {children}
          </View>
        </View>
      </View>
      {absoluteChilren && (
        <View
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            width: '100%',
          }}>
          {absoluteChilren()}
        </View>
      )}
    </>
  );
};

export default MediaCover;
