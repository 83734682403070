import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {IconName} from 'design-system/src/assets/Icones';
import {darkTheme} from 'design-system/src/styles/Themes';
import React from 'react';
import {View} from 'react-native';

export interface IServiceItem {
  title?: string;
  icon?: string;
  description?: string;
}
interface IServiceItemProps {
  item?: IServiceItem;
}

const ServiceItem = (props: IServiceItemProps) => {
  if (!props.item) return null;

  const {title, icon, description} = props.item;

  const {isMobile} = useBreakpoint();

  return (
    <View
      style={{
        width: isMobile ? '80%' : '50%',
        marginBottom: isMobile ? 34 : undefined,
        alignItems: isMobile ? 'center' : undefined,
      }}>
      {!!icon && (
        <Icon color={darkTheme.colors.primaryOrange} name={icon.toLowerCase() as IconName} />
      )}
      <Typography variant="h3" style={{marginTop: 10, marginBottom: 10}}>
        {title}
      </Typography>
      <View style={{width: '90%'}}>
        <Typography
          variant="body1"
          style={{
            color: darkTheme.colors.fontGray,
            textAlign: isMobile ? 'center' : undefined,
          }}>
          {description}
        </Typography>
      </View>
    </View>
  );
};

export default ServiceItem;
