interface Size {
  width: number;
  height: number;
}

export const learnSize: {
  small: Size;
  small_w: Size;
  medium: Size;
} = {
  small: {
    width: 168,
    height: 192,
  },
  small_w: {
    width: 247,
    height: 280,
  },
  medium: {
    width: 226,
    height: 256,
  },
};
