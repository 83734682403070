import {useMemo} from 'react';
import {useAwardContext} from './AwardLayout.context';

interface IAwardSetting {
  homepageId?: string;
  logo?: string;
  loading: boolean;
  votingForm: {
    form?: any;
    title?: string;
    label?: string;
    introduction?: string;
    cover?: string;
  };
}

const useAwardSetting = (): IAwardSetting => {
  const {settings, settings: {data, loading} = {}} = useAwardContext();
  const settingParse: Omit<IAwardSetting, 'loading'> = useMemo(() => {
    let _logo: string | undefined;
    let _homepageId: string | undefined;
    const _votingForm: IAwardSetting['votingForm'] = {};
    const page = data?.allAwardsHomepage?.items?.[0];
    if (page) {
      _logo = page.logo?.url;
      _votingForm.form = page.form;
      _votingForm.title = page.formTitle;
      _votingForm.label = page.formLabel;
      _votingForm.introduction = page.formIntroduction;
      _votingForm.cover = page.backgroundImage?.url;
      _homepageId = page.id;
    }

    return {
      logo: _logo,
      votingForm: _votingForm,
      homepageId: _homepageId,
    };
  }, [data]);

  return {
    loading: !!(!settings || loading),
    ...settingParse,
  };
};

export default useAwardSetting;
