import styled from 'styled-components';
// import {View} from 'react-native';

export const _MenuNavigationRoot = styled('div')<{}>(() => {
  return {
    borderRadius: '8px',
    background: 'rgba(17, 18, 22, 0.50)',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
    backdropFilter: 'blur(10px)',
    display: 'inline-flex',
    padding: '16px 32px',
    alignItems: 'flex-start',
    gap: 48,
  };
});
