import {StyleSheet} from 'react-native';
import defaultTheme from '../../../../Theme/defaultTheme';
import Metrics from '../../../../Theme/Metrics';

export const gradientIconCardVariantStyles = {
  active: {
    firstColor: defaultTheme.iconCard.primaryActive,
    secondColor: defaultTheme.iconCard.secondaryActive,
    firstPercentage: 0,
    secondPercentage: 1,
    deg: 39.29,
  },
  default: {
    firstColor: defaultTheme.iconCard.primaryDefault,
    secondColor: defaultTheme.iconCard.secondaryDefault,
    firstPercentage: 0.3897,
    secondPercentage: 1,
    deg: 313.01,
  },
};

export const gradientBorderStyles = StyleSheet.create({
  default: {
    borderWidth: 1,
    borderColor: defaultTheme.innerBorder,
  },
});

export const gradientIconCardSize = StyleSheet.create({
  large: {
    width: Metrics.horizontalScale(72),
    height: Metrics.horizontalScale(80),
  },
  medium: {
    width: Metrics.horizontalScale(72),
    height: Metrics.horizontalScale(72),
  },
});

export const gradientIconCardStyles = StyleSheet.create({
  container: {
    borderRadius: Metrics.moderateScale(4),
    alignItems: 'center',
    justifyContent: 'center',
  },
});
