import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export const navLiveTvCardStyles = StyleSheet.create({
  container: {
    width: Metrics.horizontalScale(167),
    height: Metrics.verticalScale(88),
    borderRadius: Metrics.moderateScale(4),
    paddingLeft: Metrics.horizontalScale(8),
    backgroundColor: defaultTheme.primaryNavBar,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  icon: {
    marginBottom: Metrics.verticalScale(8),
  },
});
