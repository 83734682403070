import React from 'react';
import {Router, RouterProps} from '@reach/router';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import {appRoutes} from './AppRoutes';

// const NotFound = (_: {default?: boolean}) => <p>Sorry, nothing here</p>;

const AppRouter = (
  props: RouterProps & Omit<React.HTMLProps<HTMLDivElement>, 'ref'>,
) => {
  return (
    <Router {...props} style={{height: '100%'}}>
      {Object.entries(appRoutes).map(([path, value]) => {
        return <PrivateRoute key={path} path={path} {...value} />;
      })}

      {/* <NotFound default={true} /> */}
    </Router>
  );
};

export default AppRouter;
