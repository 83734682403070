import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const genericPremiumBodyStyles = StyleSheet.create({
  container: {
    paddingVertical: Metrics.verticalScale(16),
    paddingRight: Metrics.horizontalScale(12),
    paddingLeft: Metrics.horizontalScale(16),
    borderRadius: Metrics.moderateScale(4),
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  aside: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: Metrics.horizontalScale(17),
  },
  description: {
    // color: defaultTheme.primaryInfo,
    marginTop: Metrics.verticalScale(7),
  },
  title: {},
});
