import padStart from 'lodash/padStart';

export const formatDuration = (duration: number, hideHour?: boolean) => {
  let times = [' h. ', ' m.', ' s.'];
  if (hideHour) {
    const formattedMinutes = Math.floor(duration / 60).toFixed(0);

    const formattedSeconds = Math.floor(duration % 60).toFixed(0);

    return [formattedMinutes, formattedSeconds]
      .map((item, index) => {
        if (item !== '0') {
          return item + times[index + 1];
        }
        return '-';
      })
      .filter(t => t !== '-')
      .join('');
  }
  const formattedHours = Math.floor(duration / 3600).toFixed(0);
  const formattedMinutes = (Math.floor(duration / 60) % 60).toFixed(0);
  const formattedSeconds = Math.floor(duration % 60).toFixed(0);
  return [formattedHours, formattedMinutes, formattedSeconds]
    .map((item, index) => {
      if (item !== '0') {
        return item + times[index];
      }
      return '-';
    })
    .filter(t => t !== '-')
    .join('');
};

export const useStringExtension = () => {
  const regex = /(<([^>]+)>)/gi;

  const formatFromHtml = (text: string) => {
    return text.replace(regex, '');
  };

  const formatTime = (time = 0, duration: number, hideHour?: boolean) => {
    time = Math.min(Math.max(time, 0), duration);

    if (hideHour) {
      const formattedMinutes = padStart(
        Math.floor(time / 60).toFixed(0),
        2,
        '0',
      );
      const formattedSeconds = padStart(
        Math.floor(time % 60).toFixed(0),
        2,
        '0',
      );

      return `${formattedMinutes}:${formattedSeconds}`;
    }

    const formattedHours = padStart(Math.floor(time / 3600).toFixed(0), 2, '0');
    const formattedMinutes = padStart(
      (Math.floor(time / 60) % 60).toFixed(0),
      2,
      '0',
    );
    const formattedSeconds = padStart(Math.floor(time % 60).toFixed(0), 2, '0');

    return `${formattedHours}:${formattedMinutes}$:${formattedSeconds}`;
  };

  return {formatFromHtml, formatTime};
};
