import {
  IFaqItemFragmentFragment,
  IHelpdeskCategFragmentFragment,
  IPushFragmentFragment,
  useHelpdeskPageByRegionIdLazyQuery,
} from '@src/Api/Graphql/gql/types.generated';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import {useEffect, useMemo} from 'react';

export type IHelpdeskCateg = IHelpdeskCategFragmentFragment & {
  faqs: IFaqItemFragmentFragment[];
};

export const useHelpdeskLogic = () => {
  const {currentRegion} = useRegionUrl();

  const regionId = currentRegion?.id;

  const [fetchHelpdesk, {data, loading}] = useHelpdeskPageByRegionIdLazyQuery();

  useEffect(() => {
    if (regionId) {
      fetchHelpdesk({
        variables: {
          regionId: regionId,
        },
      });
    }
  }, [regionId]);

  const {pushData, topicsData} = useMemo(() => {
    let pushData: IPushFragmentFragment[] = [];
    let topicsData: IHelpdeskCateg[] = [];
    const homepageData =
      data &&
      data.region &&
      data.region.children &&
      data.region.children.items &&
      data.region.children.items[0];

    if (
      !!homepageData &&
      homepageData.children &&
      homepageData.children.items &&
      homepageData.children.items[0] &&
      homepageData.children.items[0].__typename === 'HelpdeskPage'
    ) {
      const helpdeskPage = homepageData.children.items[0];
      if (helpdeskPage && helpdeskPage.push) {
        pushData = helpdeskPage.push
          .map(push => {
            return push?.content.__typename === 'Push' ? push.content : null;
          })
          .filter(push => push !== null) as IPushFragmentFragment[];
      }
      if (helpdeskPage && helpdeskPage.topics) {
        topicsData = helpdeskPage.topics
          .map(topic => {
            if (topic?.__typename === 'HelpdeskCateg') {
              const faq: IFaqItemFragmentFragment[] = [];
              if (topic.faqItems) {
                topic.faqItems.forEach(el => {
                  if (el && el.content && el.content.__typename === 'FaqItem') {
                    faq.push(el.content);
                  }
                });
              }
              return {
                ...topic,
                faqs: faq,
              } as IHelpdeskCateg;
            }
            return null;
          })
          .filter(topic => topic !== null) as IHelpdeskCateg[];
      }
    }

    return {
      pushData,
      topicsData,
    };
  }, [data]);

  return {
    loading,
    pushes: pushData,
    topics: topicsData,
  };
};
