import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const SelectStyles = StyleSheet.create({
  root: {
    paddingHorizontal: Metrics.horizontalScale(24),
    paddingRight: Metrics.horizontalScale(12),
    minHeight: Metrics.verticalScale(48),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
});
