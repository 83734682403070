import React, {useEffect, useState} from 'react';
import useLocalStorage from './LocalStorage/useLocalStorage';
import DebugUmbracoId from './DebugUmbracoId/DebugUmbracoId';

interface IDebugModeProviderProps {
  children: React.ReactNode;
}

export const DebugModeContext = React.createContext<{
  debugMode: boolean;
  currentPageId?: string;
  setCurrentPageId: React.Dispatch<React.SetStateAction<string | undefined>>;
  reload: () => void;
  forceReload: number;
}>({
  debugMode: false,
  currentPageId: undefined,
  setCurrentPageId: () => {},
  reload: () => {},
  forceReload: 0,
});

const DebugModeProvider = (props: IDebugModeProviderProps) => {
  const [debugMode, setDebugMode] = useLocalStorage('debugMode', 'false');
  const [currentPageId, setCurrentPageId] = useState<string | undefined>(
    undefined,
  );
  const [forceReload, setForceReload] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const enableDebugMode = urlParams.has('enableDebug');
    const disableDebugMode = urlParams.has('disableDebug');

    if (enableDebugMode) {
      setDebugMode('true');
    }

    if (disableDebugMode) {
      setDebugMode('false');
    }
  }, []);

  const debugModeValue = debugMode === 'true';

  return (
    <DebugModeContext.Provider
      value={{
        debugMode: debugModeValue,
        currentPageId,
        setCurrentPageId,
        reload: () => setForceReload(forceReload + 1),
        forceReload,
      }}>
      {props.children}
      {debugModeValue && (
        <DebugUmbracoId umbracoId={currentPageId} position="page-root" />
      )}
    </DebugModeContext.Provider>
  );
};

export default DebugModeProvider;
