import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {useAppSelector} from '../hooks';
import {IQuizResult} from '@src/App/components/pages/QuizPage/QuizQuestionPage/QuizQuestionPage';

interface QuizState {
  id?: string;
  quizResult: IQuizResult[];
  quizId?: string;
}

const initialState: QuizState = {
  quizResult: [],
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizResult: (state, action: PayloadAction<QuizState>) => {
      state = action.payload;
      return state;
    },
  },
});

export const useSelectQuizResult = () => {
  return useAppSelector(({quiz}) => quiz);
};

export default quizSlice;
