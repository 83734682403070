import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberMessageBody} from './interface/memberDataBody.interface';
import {IMessageResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/messages';
export const createMemberMessageEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMessageById: builder.query<IMessageResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberMessage: builder.query<IMessageResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  updateMemberMessage: builder.query<
    IMessageResponse,
    {id?: string; data: IMemberMessageBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
  deleteMemberMessage: builder.query<any, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
});
