import React, {ReactNode, useMemo} from 'react';
import {View, StyleSheet, Pressable} from 'react-native';
import {darkTheme} from '../../../../styles/Themes';
import Metrics from '../../../../Theme/Metrics';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import Tags from '../../../Tags/Tags';
import GenericCard from '../../GenericCard/GenericCard';
import {learnSize} from '../LearCard.config';
import LearnCardAside from '../LearnCardAside/LearnCardAside';
import {LearnCardProps} from '../LearnCardProps';
import LinearGradient from 'react-native-linear-gradient';
import {learnSingleCardStyles} from '../LearnSingleCard/LearnSingleCard.styles';
import IconWithText from '../../../IconWithText/IconWithText';
import defaultTheme from '../../../../Theme/defaultTheme';
import usePressableHover from '../../../PressableCustom/usePressableHover';

export interface ILearnHomeCardProps<R extends any = any>
  extends Omit<LearnCardProps, 'type'> {
  size?: 'small' | 'medium' | 'small_w';
  id?: string;
  url?: string;
  raw?: R;
  onPress: () => void;
}

const hoverStyle = {
  transform: [{scale: 1.2}],
  zIndex: 8,
  background: defaultTheme.page.background,
  // @ts-ignore
  transition: 'transform 0.2s',
  // boxShadow: `0 0 0px 4px rgba(17,18,22,1)`,
  boxSizing: 'content-box',
  border: `4px solid ${defaultTheme.page.background}`,
  borderRadius: 8,
  position: 'absolute',
};

const LearnHomeCard = <R extends any = any>(
  props: ILearnHomeCardProps<R>,
): ReactNode => {
  const {
    tag,
    size = 'small',
    isWithProgressBar,
    progress,
    onPress,
    isDone,
    textDone,
    infoColor,
    fullWidth,
    aspectRatio,
    activeHover,
  } = props;
  const gradientColor: (string | number)[] = useMemo(() => {
    return isDone
      ? ['rgba(234, 108, 19, 0.8)', 'rgba(84, 105, 251, 0.8)']
      : ['rgba(24, 45, 189, 0.8)', 'rgba(84, 105, 251, 0.8)'];
  }, [isDone]);
  const sizeValue = learnSize[size] ?? learnSize.medium;

  const {hover, onHoverIn, onHoverOut} = usePressableHover();

  const renderLearnHomeCard = (isBase?: boolean): ReactNode => {
    return (
      <>
        <Pressable
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          style={{
            width: !fullWidth
              ? Metrics.horizontalScale(sizeValue.width)
              : '100%',
            ...((activeHover && hover && !isBase && hoverStyle) || {}),
          }}
          onPress={onPress}>
          <View style={{marginBottom: Metrics.verticalScale(11)}}>
            <GenericCard
              coverImage={props.cover}
              backgroundColor={darkTheme.colors.fifthGray}
              contentStyle={{
                paddingBottom:
                  isWithProgressBar || progress
                    ? Metrics.verticalScale(4)
                    : undefined,
              }}
              width={sizeValue.width}
              customHeight={sizeValue.height}
              footer={
                (isWithProgressBar || progress !== undefined) &&
                !isDone && <CustomProgressBar progress={progress} />
              }
              // onPress={onPress}
              fullWidth={fullWidth}
              aspectRatio={aspectRatio}
            />
            {isDone && (
              <>
                <LinearGradient
                  style={StyleSheet.flatten([
                    {
                      width: Metrics.horizontalScale(sizeValue.width),
                      height: Metrics.verticalScale(sizeValue.height),
                      position: 'absolute',
                    },
                    learnSingleCardStyles.radius,
                  ])}
                  useAngle
                  angle={360}
                  locations={[0, 1]}
                  colors={gradientColor}
                />
                <IconWithText
                  style={{
                    position: 'absolute',
                    bottom: Metrics.horizontalScale(13),
                    left: Metrics.horizontalScale(9),
                  }}
                  text={textDone || 'Done!'}
                  icon="checkfilled"
                  size="16"
                  textColor={infoColor || defaultTheme.white}
                />
              </>
            )}
            {tag && (
              <Tags
                style={{
                  position: 'absolute',
                  top: Metrics.verticalScale(8),
                  left: Metrics.horizontalScale(9),
                }}
                {...tag}
              />
            )}
          </View>
          <LearnCardAside {...props} duration={undefined} />
        </Pressable>
        {activeHover && hover && !isBase && renderLearnHomeCard(true)}
      </>
    );
  };

  return renderLearnHomeCard();
};

export default LearnHomeCard;
