import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';

dayjs.extend(duration);

const useDurationFactory = () => {
  const parseDuration = (
    time: number,
    unit: duration.DurationUnitType = 'second',
  ) => {
    return dayjs
      .duration(time, unit)
      .format('H[h] m[m] s[s]')
      .replace(/\b0+[a-z]+\s*/gi, '')
      .trim();
  };
  return {
    parseDuration,
  };
};

export default useDurationFactory;
