import React, {useState, useRef, useEffect} from 'react';
import {useSwipeable} from 'react-swipeable';

interface CustomSwipeableProps {
  children: React.ReactNode;
}

const CustomSwipeable = ({children}: CustomSwipeableProps) => {
  const [translateX, setTranslateX] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxTranslate, setMaxTranslate] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setMaxTranslate(
        contentRef.current.scrollWidth - contentRef.current.clientWidth,
      );
    }
  }, [children]);

  const handlers = useSwipeable({
    onSwiping: event => {
      const newTranslate = Math.max(
        -maxTranslate,
        Math.min(0, translateX + event.deltaX),
      );
      setTranslateX(newTranslate);
    },
    onSwiped: () => {
      // Ajout d'une animation de retour si le défilement dépasse les limites
      const boundedTranslateX = Math.max(
        -maxTranslate,
        Math.min(0, translateX),
      );
      setTranslateX(boundedTranslateX);
    },
    preventScrollOnSwipe: false,
    trackMouse: true,
    trackTouch: true,
    delta: 10,
    swipeDuration: 500,
  });

  return (
    <div {...handlers} style={{overflow: 'hidden', userSelect: 'none'}}>
      <div
        ref={contentRef}
        style={{
          transform: `translateX(${translateX}px)`,
          transition: 'transform 3s ease-out',
          display: 'flex',
          flexDirection: 'row',
        }}>
        {children}
      </div>
    </div>
  );
};

export default CustomSwipeable;
