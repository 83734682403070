import React from 'react';
import {IMenuNavigationItem} from '../MenuNavigation';
import {Pressable} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Typography from 'design-system/src/Components/Typography/Typography';
import {darkTheme} from 'design-system/src/styles/Themes';
import usePressableHover from '@src/Hooks/usePressableHover';
import useDefaultTheme from 'design-system/src/Theme/useTheme';
import {useMatch} from '@reach/router';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const MenuNavigationItem = (props: IMenuNavigationItem) => {
  const {
    onClick,
    icon,
    isActive,
    iconLink,
    link,
    label,
    translate = true,
  } = props;
  const {t} = useTranslation('translation');
  const defaultTheme = useDefaultTheme();
  const {hover, onHoverIn, onHoverOut} = usePressableHover();
  const match = useMatch(link || '/');

  const color =
    isActive || (!!link && match)
      ? darkTheme.colors.primaryOrange
      : hover
        ? defaultTheme.iconCard.secondaryActive
        : undefined;

  return (
    <Pressable onPress={onClick} onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: 12,
        }}>
        {icon && <Icon name={icon} size="24" color={color} />}
        {iconLink && (
          <CustomFastImage width={24} height={24} source={{uri: iconLink}} />
        )}
        <Typography variant="body3" color={color ? () => color : undefined}>
          {/* i18next-extract-disable-next-line */}
          {translate ? t(label) : label}
        </Typography>
      </div>
    </Pressable>
  );
};

export default MenuNavigationItem;
