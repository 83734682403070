import React, {useEffect, useState} from 'react';
import {Pressable, StyleProp, ViewStyle, View} from 'react-native';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import {ITypographyProps} from '../Typography/Typography.props';
import Metrics from '../../Theme/Metrics';
import {typographyVariantStyled} from '../Typography/Typography.styles';

export type ICheckBoxPropsProps = {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  style?: StyleProp<ViewStyle>;
  variant?: 'default' | 'orange';
  shape?: 'default' | 'circle';
  size?: string;
  textVariant?: ITypographyProps['variant'];
};

const CheckBox = (props: ICheckBoxPropsProps) => {
  const {
    checked = false,
    onChange,
    label,
    style,
    variant = 'default',
    shape = 'default',
    textVariant = 'button2',
  } = props;
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange && onChange(!isChecked);
  };

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const cirlceStyle = {
    borderRadius: 10,
  };

  return (
    <Pressable
      style={[
        {flexDirection: 'row', alignItems: 'flex-start'},
        style,
        shape === 'circle' ? cirlceStyle : {},
      ]}
      onPress={handleChange}>
      <View
        style={{
          height: typographyVariantStyled[textVariant].lineHeight || undefined,
          justifyContent: 'center',
        }}>
        {shape === 'circle' ? (
          <Icon
            name={isChecked ? 'check-circle' : 'check-empty'}
            color={isChecked ? '#EA6C13' : '#737373'}
          />
        ) : isChecked ? (
          <Icon
            name="checkon"
            size="16"
            color={variant === 'orange' ? '#EA6C13' : ''}
          />
        ) : (
          <Icon name="checkempty" size="16" />
        )}
      </View>
      <Typography
        variant={textVariant}
        style={{marginLeft: Metrics.horizontalScale(13)}}>
        {label}
      </Typography>
    </Pressable>
  );
};

export default CheckBox;
