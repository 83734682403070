import Button from 'design-system/src/Components/Button/Button';
import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import Metrics from 'design-system/src/Theme/Metrics';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useMediaCover from 'design-system/src/Widget/Discover/useMediaCover';
import VideoPlayerVertical from 'design-system/src/Widget/Player/VideoPlayer/VideoPlayerVertical.web';
// import {useVideoPlayer} from 'design-system/src/Widget/Player/Hooks/useVideoPlayer.web';
import React, {useEffect, useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

export interface ITutorialWidget {
  title: string;
  subtitle: string;
  onPressButton?: () => void;
  mediaCover?: IMediaCover;
  isActive?: boolean;
  buttonTitle?: string;
  index: number;
}

const TutorialWidget = (props: ITutorialWidget) => {
  const {
    title,
    subtitle,
    mediaCover,
    isActive,
    onPressButton,
    buttonTitle,
    index,
  } = props;
  const {isMobile} = useBreakpoint();
  console.log('mediaCover', mediaCover);

  const videoRef = useRef<any>(undefined);
  const {media, loading: mediaLoading, fetchMedia} = useMediaCover(mediaCover);

  // const {
  // isLoading,
  // onLoad,
  // onVideoLoadStart,
  // onVideoBuffer,
  // onReadyForDisplay,
  // onProgress,
  // } = useVideoPlayer(videoRef);

  useEffect(() => {
    fetchMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMedia, mediaCover]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        // maxWidth: 375,
        height: '100%',
        margin: '0 auto',
        borderRadius: 18,
        overflow: 'hidden',
        ...tutorialStyles.root,
      }}>
      <View style={tutorialStyles.screenImage}>
        {mediaLoading ? (
          <CustomActivityIndicator
            style={[StyleSheet.absoluteFill, {zIndex: 1}]}
          />
        ) : (
          !!media &&
          (mediaCover && mediaCover.type === 'image' ? (
            <CustomFastImage
              source={mediaCover.uri}
              backgroundFallbackColor="rgba(0,0,0,0.6)"
              resizeMode={
                mediaCover.isMobile
                  ? 'cover'
                  : mediaCover.hasDesktop
                    ? 'cover'
                    : 'center'
              }
              width={'100%'}
              height={'100%'}
              style={{flex: 1}}
            />
          ) : (
            media.type === 'video' && (
              <>
                <CustomFastImage
                  source={media.poster}
                  backgroundFallbackColor="rgba(0,0,0,0.6)"
                  resizeMode={'cover'}
                  width={'100%'}
                  height={'100%'}
                  style={{flex: 1}}
                />
                {/* <Video
                  ref={videoRef}
                  source={media.uri}
                  paused={!isActive}
                  repeat={true}
                  ignoreSilentSwitch="ignore"
                  fullscreen={false}
                  resizeMode={'cover'}
                  onProgress={onProgress}
                  onLoadStart={onVideoLoadStart}
                  onReadyForDisplay={onReadyForDisplay}
                  onLoad={onLoad}
                  // poster={poster}
                  pictureInPicture={true}
                  posterResizeMode="cover"
                  onBuffer={onVideoBuffer}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                /> */}
                {/* {isLoading && (
                  <CustomActivityIndicator
                    style={[StyleSheet.absoluteFill, {zIndex: 1}]}
                  />
                )} */}
              </>
            )
          ))
        )}
      </View>
      <LinearGradient
        locations={[0, 0.1, 1]}
        // useAngle
        // angle={180}
        colors={['rgba(17, 18, 22, .08)', 'rgba(17, 18, 22, 1)', '#111216']}
        style={tutorialStyles.defailsWrapper}>
        {title && (
          <Typography
            variant="h1"
            style={{...tutorialStyles.title, fontSize: Metrics.fontScale(24)}}>
            {title}
          </Typography>
        )}
        <Typography
          variant="body2"
          style={{...tutorialStyles.subtitle, fontSize: Metrics.fontScale(14)}}
          color="gray">
          {subtitle}
        </Typography>
        <Button
          title={buttonTitle || ''}
          style={{marginVertical: Metrics.verticalScale(36)}}
          fullWidth={true}
          variant={'contained'}
          size="small"
          onPress={onPressButton}
        />
      </LinearGradient>
    </div>
  );
};

const tutorialStyles = StyleSheet.create({
  root: {
    flex: 1,
  },
  title: {
    marginBottom: Metrics.fontScale(10),
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
  },
  defailsWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: Metrics.horizontalScale(16),
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingTop: Metrics.verticalScale(40),
    // minHeight: Metrics.window.height - Metrics.window.width * 1.4,
  },
  screenImage: {
    width: '100%',
    height: '100%',
    // width: Metrics.window.width,
    // height: Metrics.window.width * 1.5,
    position: 'absolute',
  },
});

export default TutorialWidget;
