import {
  ICategoryChildrenByIdQuery,
  ILiveEventFragmentFragment,
  ICourseFragmentFragment,
  ICourseUnitFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {ICategorySliderItem} from '~Umbraco/Components/CategorySlider/CategorySlider';
import DateFactory from '~tools/DateFactory';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import {TFunction} from 'i18next';
import {formatDuration} from '~Hooks/useStringExtension';
import {OverviewService} from '~Umbraco/Utils/Overview/overview.service';

export const regionFilterFunc = (element?: any, id?: string): boolean => {
  if (
    id &&
    typeof element === 'object' &&
    element.regions &&
    Array.isArray(element.regions)
  ) {
    if (element.regions.length > 0) {
      return element.regions.find(
        (item: any) =>
          item && item.__typename === 'ContentTag' && item.id === id,
      );
    }
    return false;
  }
  return false;
};

const regionFilter = (element?: any, id?: string): boolean => {
  if (
    id &&
    typeof element === 'object' &&
    element.regions &&
    Array.isArray(element.regions)
  ) {
    if (element.regions.length > 0) {
      return element.regions.find(
        (item: any) =>
          item && item.__typename === 'ContentTag' && item.id === id,
      );
    }
    return false;
  }
  return true;
};

export const getCategoryPageItems = (
  category: ICategoryChildrenByIdQuery['category'],
  findProgression: (id: string) => number,
  canSeeContent: (
    accessType?: ILiveEventFragmentFragment['accessType'],
  ) => boolean,
  t: TFunction<'translation', undefined, 'translation'>,
  l: TFunction<'learn', undefined, 'learn'>,
  calculateLiveEventProgress?: (startDate: any, endDate: any) => number,
  regionId?: string,
  countProgression?: (
    items: ICourseFragmentFragment[] | ICourseUnitFragmentFragment[],
  ) => {
    counter: number;
    total: number;
  },
): ICategorySliderItem[] => {
  if (!category) {
    return [];
  }
  const {children} = category;

  const items: ICategorySliderItem[] = [];
  children.edges.forEach(edge => {
    if (edge && edge.node) {
      const item = edge.node;
      if (item && !isElementRestricted(item) && regionFilter(item, regionId)) {
        if (item.__typename === 'QuizzSurvey') {
          const {id, __typename, title, url} = item;
          items.push({
            cover: {
              uri: item.thumbnail?.optiUrl || item.thumbnail?.url,
            },
            title: title || '',
            tag: {
              text: l('QUIZ'),
              variant: 'quizz',
            },
            id,
            url: url || '',
            __typename,
            accessData: {
              accessType: undefined,
              paywall: undefined,
            },
          });
        } else if (item.__typename === 'QuizzSurveyCollection') {
          const {id, __typename, title, url} = item;
          items.push({
            cover: {
              uri: item.thumbnail?.optiUrl || item.thumbnail?.url,
            },
            title: title || '',
            tag: {
              text: l('COLLECTION'),
              variant: 'collection',
            },
            id,
            url: url || '',
            __typename,
            accessData: {
              accessType: undefined,
              paywall: undefined,
            },
          });
        } else if (item.__typename === 'Course') {
          const {title, id, url, linkedPartners, __typename, children} = item;
          const {length} = OverviewService.getCourseOverview(item);
          let _counter = 0;
          let _total: number | undefined;

          if (children && children.items && countProgression) {
            const {counter, total} = countProgression(
              children.items as ICourseUnitFragmentFragment[],
            );
            _total = total;
            _counter = counter;
          }
          items.push({
            cover: {
              uri: item.thumbnail?.cropUrl || item.thumbnail?.url,
            },
            title: title || '',
            // duration: length
            //   ? formatDuration(length, length < 3600)
            //   : undefined,
            tag: {
              text: t('course'),
              variant: 'course',
            },
            id,
            url: url || '',
            partenerLogo:
              linkedPartners &&
              linkedPartners[0] &&
              linkedPartners[0].__typename === 'Partner'
                ? linkedPartners[0].logo?.url
                : undefined,
            __typename,
            accessData: {
              accessType: undefined,
              paywall: undefined,
            },
            progress: _total ? _counter / _total : undefined,
          });
        } else if (item.__typename === 'Collection') {
          const {id, title, subtitle, thumbnail, url = '', __typename} = item;
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            isFreemium: canSeeContent(item.accessType),
          });
        } else if (item.__typename === 'VODItem') {
          const {
            id,
            title,
            subtitle,
            name,
            shortDescription,
            thumbnail,
            url = '',
            __typename,
            vimeoHlsUrl,
          } = item;

          const parentAccessType =
            item.parent && item.parent.__typename === 'Collection'
              ? item.accessType
              : undefined;

          items.push({
            id,
            title: title || name || '',
            subtitle: subtitle || shortDescription,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: findProgression(id),
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            vimeoHlsUrl,
            isFreemium:
              canSeeContent(item.accessType) && canSeeContent(parentAccessType),
          });
        } else if (item.__typename === 'Radio') {
          const {id, title, subtitle, thumbnail, url = '', __typename} = item;

          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
        } else if (item.__typename === 'Podcast') {
          const {
            id,
            title,
            subtitle,
            thumbnail,
            url = '',
            __typename,
            // length,
          } = item;

          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: findProgression(id),
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
        } else if (item.__typename === 'Playlist') {
          const {id, title, subtitle, thumbnail, url = '', __typename} = item;

          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
        } else if (item.__typename === 'PodcastItem') {
          const {
            id,
            title,
            shortDescription,
            thumbnail,
            url = '',
            __typename,
            // length,
            vimeoHlsUrl,
          } = item;

          items.push({
            id,
            title: title || '',
            subtitle: shortDescription,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            //TODO value of max duration if length not in min
            progress: findProgression(id),
            accessData: {},
            vimeoHlsUrl,
          });
        } else if (item.__typename === 'LiveEvent') {
          const {
            id,
            title,
            subtitle,
            thumbnail,
            url = '',
            __typename,
            startDate,
            endDate,
          } = item;

          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: calculateLiveEventProgress
              ? calculateLiveEventProgress(startDate, endDate)
              : 0.0,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            isFreemium: canSeeContent(item.accessType),
          });
        } else if (item.__typename === 'News') {
          const {
            id,
            title,

            thumbnail,
            url = '',
            __typename,
            startDate,
            endDate,
            vimeoHlsUrl,
          } = item;

          items.push({
            id,
            title: title || '',
            subtitle:
              (item.author &&
                item.author[0] &&
                item.author[0].content &&
                item.author[0].content.__typename === 'Author' &&
                item.author[0].content.authorName) ||
              '',
            extraContent: [
              {
                type: 'date',
                date: new DateFactory(item.pubDate).format(),
              },
            ],
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: calculateLiveEventProgress
              ? calculateLiveEventProgress(startDate, endDate)
              : 0.0,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            vimeoHlsUrl,
            raw: item,
          });
        } else {
          console.warn(
            '[mapCategoryPage] content type not handled ',
            item.__typename,
          );
        }
      }
    }
  });

  return items;
};
