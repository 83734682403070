import PageHead from '@src/components/general/PageHead/PageHead';
import useParams from '@src/Hooks/useParams';
import useSetupRegion from '@src/Hooks/useSetupRegion';
import {AuthContext} from '@src/Providers/AuthProvider/AuthProvider';
import {useAppDispatch} from '@src/Store/hooks';
import useDefaultPaywall from '@src/Store/selectors/useDefaultPaywall';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import PaywallSectionPage from '@src/Umbraco/Pages/PaywallSectionPage/PaywallSectionPage';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {HeadFC, PageProps} from 'gatsby';
import {graphql} from 'gatsby';
import React, {useEffect, useContext} from 'react';
import {View} from 'react-native';

const SubscriptionPage = (props: PageProps<Queries.TvAuthQuery>) => {
  const params = useParams();
  const {loading: loadingRegion} = useSetupRegion();
  const {isMobile} = useBreakpoint();
  const {isLoggedIn, isLoading} = useContext(AuthContext);
  const dispatch = useAppDispatch();

  const defaultPaywall = useDefaultPaywall();
  const {paywall} = params || {};
  const paywallId =
    paywall && paywall.__typename === 'Paywall'
      ? paywall.id
      : defaultPaywall?.id;

  useEffect(() => {
    if (!isLoading) {
      if (!isLoggedIn) {
        dispatch(
          appAuthSlice.actions.openAuthenticationModal({
            open: true,
            redirect: '/subscription',
          }),
        );
      } else {
        dispatch(
          appAuthSlice.actions.openAuthenticationModal({
            open: false,
            redirect: undefined,
          }),
        );
      }
    }
  }, [isLoading, isLoggedIn]);

  console.log('paywallId', paywallId);

  if (isLoading || loadingRegion) {
    return (
      <div style={{width: '100%', height: '100%', minHeight: '100vh'}}>
        <CustomActivityIndicator />
      </div>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        width: '100%',
        position: 'relative',
        // overflow: 'hidden',
      }}>
      <PaywallSectionPage
        variant="choosePlan"
        id={paywallId}
        orientation={isMobile ? 'portrait' : 'landscape'}
      />
    </View>
  );
};

SubscriptionPage.layoutComponent = 'EmptyLayout';
export default SubscriptionPage;

export const Head: HeadFC<Queries.TvAuthQuery> = props => {
  return (
    <PageHead title={'Subscription'} description={'Subscription'} {...props} />
  );
};

export const query = graphql`
  query Subscription($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
