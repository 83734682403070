import {RouteTypename} from '@src/Navigation/type';

export const getContentEndUrl = (url: string, typename?: RouteTypename) => {
  if (typename === 'CourseUnit') {
    const elements = url?.split('/').filter(element => element.trim() !== '');
    return elements?.slice(-2).join('/') || '';
  }
  return url?.split('/').filter(Boolean).pop() || '';
};

export const getEndUrls = (
  url?: string,
): {
  endUrl1?: string;
  endUrl2?: string;
  endUrl?: string;
} => {
  const urlSplitted = url?.split('/').filter(Boolean);
  const lastUrl = urlSplitted?.slice(-1)[0];
  const secondLastUrl = urlSplitted?.slice(-2)[0];
  return {endUrl1: secondLastUrl, endUrl2: lastUrl, endUrl: lastUrl};
};
