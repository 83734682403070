import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberOpportunityBody} from './interface/memberDataBody.interface';
import { IOpportunityResponse } from './interface/memberDataResponse.interface';

const endpoint = '/me/opportunity';
export const createMemberOpportunityEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberOpportunityById: builder.query<IOpportunityResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberOpportunity: builder.query<IOpportunityResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  updateMemberOpportunity: builder.query<
  IOpportunityResponse,
    {id?: string; data: IMemberOpportunityBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
  deleteMemberOpportunity: builder.query<IOpportunityResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'DELETE',
    }),
  }),
});
