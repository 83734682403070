import React, {FC} from 'react';
import {
  useSelectIsOpenProfileEditDrawer,
  useSelectProfileEditParams,
} from '@src/Store/Slices/AppSlice/useAppSliceSelector';
import {useAppDispatch} from '@src/Store/hooks';
import appSlice from '@src/Store/Slices/AppSlice';
import ProfileMyProfileFieldEdit from './ProfileMyProfileFieldEdit';
import useMember from '@src/Store/selectors/useMember';
import CustomDrawer from '@src/layout/AppLayout/AppDrawer/CustomDrawer';
interface ProfileMyProfileFieldEditDrawerProps {
  onClose: () => void;
}

export const ProfileMyProfileFieldEditDrawer: FC<
  ProfileMyProfileFieldEditDrawerProps
> = ({onClose}) => {
  const isOpen = useSelectIsOpenProfileEditDrawer();
  const dispatch = useAppDispatch();
  const member = useMember();
  const drawerParams = useSelectProfileEditParams();
  const toggleDrawer = () => {
    dispatch(
      appSlice.actions.setIsOpenProfilEditDrawer({
        isOpenProfilEditDrawer: false,
        data: undefined,
      }),
    );
  };

  const handleClose = () => {
    toggleDrawer();
    onClose();
  };

  if (drawerParams && member) {
    return (
      <CustomDrawer
        child={
          <ProfileMyProfileFieldEdit data={member} params={drawerParams} />
        }
        isOpen={isOpen || false}
        onToggleDrawer={handleClose}
        isWithArrowBack
        onBack={toggleDrawer}
      />
    );
  }
  return <></>;
};

export default ProfileMyProfileFieldEditDrawer;
