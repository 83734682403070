import {Member} from '~Api/Umbraco/interface/Member.interface';
import {IMemberDto} from '../interface/umbraco.interface';
import {TraceApiEndpointBuilder} from '../traceApi';
import {parsedCookieData} from '@src/Providers/DidomiInitProvider/parsedCookieData';

export interface IAuthCode {
  memberId?: string;
  code: string;
  token?: string;
}

export const createAuthEndpoint = (builder: TraceApiEndpointBuilder) => ({
  traceSignUp: builder.query<{token: string; member: Member}, IMemberDto>({
    query: body => {
      const {cookies} = parsedCookieData();
      return {
        url: '/auth/signup',
        method: 'POST',
        body: {
          ...body,
          cookies: {
            ...body.cookies,
            ...cookies,
          },
        },
        noAuth: true,
      };
    },
  }),
  checkMemberEmailExist: builder.query<Member, string>({
    query: username => ({
      url: `/auth/email-exist/${username}`,
      method: 'GET',
    }),
  }),
  tvAuthCode: builder.mutation<IAuthCode, {code: string}>({
    query: body => ({
      url: `/auth/code`,
      method: 'POST',
      body,
    }),
  }),
  resetPassword: builder.mutation<void, {email: string; lang: string}>({
    query: body => ({
      url: `/auth/reset-password`,
      method: 'POST',
      body,
    }),
  }),
});
