import React, {useMemo} from 'react';
import {
  IChannelFragmentFragment,
  useChannelByIdQuery,
} from '~Api/Graphql/gql/types.generated';

import ServiceFormChannelController, {
  ServiceFormChannelControllerProps,
} from 'design-system/src/Components/ServiceForm/ServiceFormChannelController';
import {FieldValues} from 'react-hook-form';

export interface LogoCheckBoxListProps<TField extends FieldValues = FieldValues>
  extends Omit<ServiceFormChannelControllerProps<TField>, 'data'> {
  ids: string[];
  allID: string[];
}

const LogoCheckBoxList = (props: LogoCheckBoxListProps) => {
  const {ids, title, lang, allID, ...rest} = props;

  const response = useChannelByIdQuery({
    variables: {
      id: allID,
    },
  });

  const {items} = useMemo(() => {
    const {data} = response;
    if (
      data &&
      data.allContent.items[0] &&
      data.allContent.items[0].__typename === 'Channel'
    ) {
      return {
        items: data.allContent.items.map((item, index) => {
          let value = item as IChannelFragmentFragment;
          return {
            logo: {
              uri: value.logo?.url,
            },
            title: value.title,
            url: value.url,
            id: ids[index],
          };
        }),
      };
    }
    return {
      items: [],
    };
  }, [ids, response]);

  return (
    <ServiceFormChannelController
      title={title}
      lang={lang}
      data={items}
      {...rest}
    />
  );
};

export default LogoCheckBoxList;
