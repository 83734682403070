import {StyleSheet} from 'react-native';
import Metrics from '../../../../Theme/Metrics';

export const tvGuideLeadingCardStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: Metrics.horizontalScale(100),
    height: Metrics.verticalScale(96),
    borderRadius: Metrics.moderateScale(4),
    backgroundColor: '#34353A',
  },
});

export const tvGuideLeadingCardStylesWeb = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      width: Metrics.horizontalScale(isMobile ? 100 : 163),
      height: Metrics.verticalScale(isMobile ? 96 : 104),
      borderRadius: Metrics.moderateScale(4),
      backgroundColor: '#34353A',
    },
  });
