import {IButton} from '@src/Umbraco/Hooks/useElementRenderer';
import {HeroSliderData} from './mapHeroSlide';
import {capitalize} from '../common';

export const mapButtonOnBuild = (button: Queries.Umbraco_Button): IButton => {
  let url;
  let id;
  let __typename = 'Unknown';

  const buttonContent = button as Queries.Umbraco_Button;
  const buttonProps: HeroSliderData['button'] | undefined = {
    variant:
      (buttonContent.variant as NonNullable<
        HeroSliderData['button']
      >['variant']) || 'outlined',
    icon: (buttonContent.icon || '').toLowerCase() as NonNullable<
      HeroSliderData['button']
    >['icon'],
    size:
      (buttonContent.size as NonNullable<HeroSliderData['button']>['size']) ||
      'small',
    title: buttonContent.title || '',
  };

  if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    (buttonContent.link_to[0].content.contentTypeAlias === 'internalLink' ||
      buttonContent.link_to[0].content.contentTypeAlias === 'contentLink')
  ) {
    const buttonLinkContent = buttonContent.link_to[0].content as
      | Queries.Umbraco_InternalLink
      | Queries.Umbraco_ContentLink;
    if (buttonLinkContent?.link?.contentTypeAlias === 'landingPage') {
      const urlRewrite = buttonLinkContent?.link?.urlRewrite || '';
      url = urlRewrite
        ? `/${urlRewrite}`.replace('//', '/')
        : buttonLinkContent?.link?.url || '';
    } else {
      url = buttonLinkContent?.link?.url || '';
    }
    id = buttonLinkContent?.link?.id || '';
    __typename =
      capitalize(buttonLinkContent.link?.contentTypeAlias) || 'Unknown';
  }

  if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    buttonContent.link_to[0].content.contentTypeAlias === 'externalLink'
  ) {
    const buttonLinkContent = buttonContent.link_to[0]
      .content as Queries.Umbraco_ExternalLink;
    url = buttonLinkContent?.externalUrl || '';
    id = '';
    __typename = capitalize(buttonLinkContent.contentTypeAlias) || 'Unknown';
  }

  return {
    buttonProps: {
      ...buttonProps,
      title: buttonProps.title || '',
    },
    url,
    id: id || '',
    __typename,
  };
};
