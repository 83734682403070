import React, {ElementType} from 'react';
import {ITypographyProps, typographyGetColor} from './Typography.props';
import {useThemeContext} from '../../Provider/ThemeProvider/ThemeContext';
import {styled} from 'styled-components';
import {
  typographySharedStyle,
  typographyVariantStyled,
} from './Typography.styles';

const _Text = styled('span')<{
  $variant: ITypographyProps['variant'];
  $color: ITypographyProps['color'];
  $numberOfLines?: ITypographyProps['numberOfLines'];
}>(({$variant = 'h1', $color = 'white', $numberOfLines}) => {
  const variantStyles = typographyVariantStyled[$variant];

  const numberOfLineStyle = $numberOfLines
    ? {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': $numberOfLines + '' /* number of lines to show */,
        'line-clamp': $numberOfLines + '',
        '-webkit-box-orient': 'vertical',
        paddingBottom: '0 !important',
      }
    : {};

  return {
    ...variantStyles,
    color: typeof $color === 'function' ? $color() : $color,
    ...typographySharedStyle.font,
    lineHeight: variantStyles.lineHeight
      ? variantStyles.lineHeight + 'px'
      : undefined,
    ...numberOfLineStyle,
  };
});

const Typography = (
  props: ITypographyProps & {as?: ElementType; className?: string},
) => {
  const {
    children,
    variant = 'h1',
    style,
    color = 'white',
    numberOfLines,
    ellipsizeMode,
    ...rest
  } = props;
  const {component} = useThemeContext();
  const {mapColor} = component?.typography || {};
  const textColor =
    mapColor?.(typographyGetColor(color)) || typographyGetColor(color);

  return (
    <_Text
      data-testid="typography-web"
      $variant={variant}
      $numberOfLines={numberOfLines}
      $color={textColor}
      style={
        typeof style === 'object'
          ? {
              ...style,
              // @ts-ignore
              lineHeight: style?.lineHeight
                ? // @ts-ignore
                  style.lineHeight + 'px'
                : undefined,
            }
          : undefined
      }
      {...rest}>
      {children}
    </_Text>
  );
};

export default Typography;
