import {useAppSelector} from '@src/Store/hooks';
import {useCallback, useEffect} from 'react';
import {
  IGetMainContentListByIdQuery,
  useGetMainContentListByIdLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import {MemberDataSource} from '~Store/Slices/Member.slice';

const useMemberBookmarks = (
  source: MemberDataSource,
): {
  refresh: () => Promise<void>;
  loading: boolean;
  data: IGetMainContentListByIdQuery | undefined;
} => {
  const {bookmarks = []} = useAppSelector(({member: memberState}) => ({
    bookmarks: memberState.saved,
  }));
  const [fetchContent, {loading, data}] = useGetMainContentListByIdLazyQuery();

  const handleFetchedData = useCallback(async () => {
    let ids: string[] = [];
    bookmarks.forEach(item => {
      if (item && item.contentSource === source && item.contentId) {
        ids.push(item.contentId);
      }
    });
    fetchContent({
      variables: {
        id: ids,
      },
    });
  }, [bookmarks, fetchContent, source]);

  useEffect(() => {
    handleFetchedData();
  }, [handleFetchedData]);

  return {loading, data, refresh: handleFetchedData};
};

export default useMemberBookmarks;
