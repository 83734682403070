import React from 'react';
import CardAside from '../../CardAside/CardAside';
import {LiveCardProps} from '../LiveCardProps';
import IcSettingSvg from '../../../../assets/svg/setting-icon.svg';
import IconWithText from '../../../IconWithText/IconWithText';
import {TextStyles} from '../../../../styles/TextStyles';
import Typography from '../../../Typography/Typography';
import defaultTheme from '../../../../Theme/defaultTheme';

export interface ILiveCardAsideProps {
  asideProps: LiveCardProps;
  descriptionProps?: {numberOfLines?: number};
  isLive?: boolean;
}

const LiveCardAside = ({
  asideProps,
  descriptionProps,
  isLive,
}: ILiveCardAsideProps) => {
  return (
    <CardAside
      title={asideProps.title}
      titleColor={asideProps.titleColor}
      subtitleColor={asideProps.descriptionColor}
      subtitle={asideProps.subtitle}
      TrailingIc={asideProps.hasTrailingIcon ? IcSettingSvg : undefined}
      isFilledParent={asideProps.asideExpanded}
      onPressTrailingIc={asideProps.onPressTrailingIc}
      infoColor={asideProps.infoColor}
      isTrailingIconCentered={asideProps.isTrailingIconCentered}
      isLive={isLive}>
      {asideProps.releaseDate && (
        <Typography
          children={asideProps.releaseDate?.toUpperCase()}
          color="primary"
          variant="h4"
        />
      )}
      {asideProps.startDate && asideProps.endDate && (
        <IconWithText
          text={asideProps.startDate + '  •  ' + asideProps.endDate}
          icon="clock"
          size="16"
          textColor={asideProps.infoColor ?? defaultTheme.white}
          textStyle={TextStyles.infoText}
        />
      )}
      {asideProps.description && (
        <Typography
          variant="ui3"
          numberOfLines={2}
          color={() => asideProps.descriptionColor ?? defaultTheme.white}
          {...descriptionProps}>
          {asideProps.description}
        </Typography>
      )}
    </CardAside>
  );
};

export default LiveCardAside;
