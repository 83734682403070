import apiConfig from '@src/Configs/apiConfig';

export const isTrackingEnabled = (): boolean => {
  if (typeof window !== 'undefined' && typeof window.Didomi !== 'undefined') {
    const {purposes} = window.Didomi.getUserConsentStatusForAll();
    return (
      !!purposes.enabled.length &&
      purposes.enabled.includes(apiConfig.amplitudePurpId)
    );
  }
  return false;
};
