import React, {ReactNode} from 'react';
import {Pressable, View} from 'react-native';

import GenericPremiumBody from '../GenericPremiumBody/GenericPremiumBody';
import GradientIconCard from '../GradientCard/GradientIconCard/GradientIconCard';
import {pushQuizStyles} from './PushQuizCard.styles';
import CardBlurIcon from '../CardBlurIcon/CardBlurIcon';
import {IconName} from '../../../assets/Icones';
import Icon from '../../Icon/Icon';
import Tags, {TagVariant} from '../../Tags/Tags';
import {ITypographyProps} from '../../Typography/Typography.props';

export type PushQuizCardProps = {
  variant?: 'default' | 'blur';
  title: string;
  subTitle?: string;
  description: string;
  percentage?: number;
  onPressArrowIc?: () => void;
  icon: IconName | (() => ReactNode);
  descTypo?: ITypographyProps['variant'];
  tagVariant?: TagVariant;
};

const PushQuizCard = (props: PushQuizCardProps) => {
  const {
    variant = 'default',
    title,
    description,
    percentage,
    onPressArrowIc,
    icon,
    descTypo,
    tagVariant = 'category',
    subTitle,
  } = props;
  return (
    <Pressable style={pushQuizStyles.container} onPress={onPressArrowIc}>
      {percentage !== undefined && (
        <View style={pushQuizStyles.tagContaier}>
          <Tags variant={tagVariant} text={`${percentage} %`} />
        </View>
      )}
      <GenericPremiumBody
        title={title}
        description={description}
        descriptionLine={2}
        descTypo={descTypo}
        subTitle={subTitle}
        leadingCard={
          <View>
            {typeof icon === 'function' ? (
              <GradientIconCard icon={icon} size="medium" />
            ) : variant === 'default' ? (
              <GradientIconCard icon={icon} size="medium" />
            ) : (
              <CardBlurIcon icon={icon} />
            )}
          </View>
        }
        trailing={
          <Pressable onPress={onPressArrowIc} style={pushQuizStyles.trailincIc}>
            <Icon name="arrow-right" />
          </Pressable>
        }
      />
    </Pressable>
  );
};

export default PushQuizCard;
