import React from 'react';
import {View} from 'react-native';
import {darkTheme} from '../../../../styles/Themes';
import {selectSize} from '../../../../tools/CardSizeTools';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import Tags from '../../../Tags/Tags';
import GenericCard from '../../GenericCard/GenericCard';
import {homeLiveCardStyles} from './HomeLiveCard.styles';
import {LiveCardProps} from '../LiveCardProps';
import LiveCardAside from '../LiveCardAside/LiveCardAside';
import Metrics from '../../../../Theme/Metrics';
import ChannelLogo from '../../../ChannelLogo/ChannelLogo';
import Images from '../../../../assets/Images';

const HomeLiveCard = (props: LiveCardProps) => {
  const size = React.useCallback(selectSize, [props])(props, false);

  return (
    <View
      style={{
        width: Metrics.horizontalScale(size.width),
      }}>
      <View style={homeLiveCardStyles.cover}>
        <GenericCard
          coverImage={props.cover}
          backgroundColor={darkTheme.colors.fifthGray}
          width={size.width}
          height={size.height}
          contentStyle={{
            paddingBottom: Metrics.verticalScale(4),
          }}
          leadingHeader={
            props.tagsText && <Tags text={props.tagsText} variant="live" />
          }
          trailingHeader={
            typeof props.channelLogo === 'function'
              ? props.channelLogo()
              : props.channelLogo && (
                  <ChannelLogo source={Images.channel[props.channelLogo]} />
                )
          }
          footer={
            props.progress && <CustomProgressBar progress={props.progress} />
          }
        />
      </View>
      <LiveCardAside asideProps={props} />
    </View>
  );
};

export default HomeLiveCard;
