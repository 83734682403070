import React from 'react';
import {StyleSheet, Pressable, View} from 'react-native';
import Typography from '../../../Components/Typography/Typography';
import {
  listenPlayListStyles,
  playListVariantStyles,
} from './ListenPlayList.styles';

export interface ListenPlayListProps<T> {
  data: T[];
  title?: string;
  subtitle?: string;
  description?: string;
  renderItem: (item: T, index: number) => React.ReactNode;
  onItemPress?: (item: T, index: number) => void;
  variant?: keyof typeof playListVariantStyles;
}

const ListenPlayList = <T extends any>(props: ListenPlayListProps<T>) => {
  const {
    title,
    subtitle,
    description,
    renderItem,
    data,
    onItemPress,
    variant = 'primary',
  } = props;

  const variantStyles = playListVariantStyles[variant];
  return (
    <View
      style={StyleSheet.flatten([
        listenPlayListStyles.container,
        variantStyles,
      ])}>
      {title && <Typography children={title} variant="h3" />}
      {description && (
        <Typography
          children={description}
          variant="body2"
          style={listenPlayListStyles.description}
        />
      )}
      {subtitle && <Typography children={subtitle} variant="h3" />}
      <View style={listenPlayListStyles.body}>
        {data.map((value, index) => {
          let item = value as any;
          return (
            <Pressable
              key={index}
              onPress={
                onItemPress &&
                (() => {
                  onItemPress(item, index);
                })
              }>
              {renderItem(item, index)}
            </Pressable>
          );
        })}
      </View>
    </View>
  );
};

export default ListenPlayList;
