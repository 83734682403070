import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React from 'react';
import {View, Pressable} from 'react-native';

export const ContentHeader = ({
  title,
  onMore,
  seeMore,
  noMargin,
}: {
  title: string;
  onMore?: () => void;
  seeMore: string;
  noMargin?: boolean;
}) => {
  return (
    <View
      style={{
        marginBottom: !noMargin ? Metrics.verticalScale(9) : undefined,
        marginRight: Metrics.horizontalScale(16),
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <Typography
        style={{
          paddingHorizontal: Metrics.horizontalScale(24),
        }}
        variant="h3">
        {title}
      </Typography>
      {onMore && (
        <Pressable style={{padding: 13, margin: -13}} onPress={onMore}>
          <Typography children={seeMore} color="primary" variant="button2" />
        </Pressable>
      )}
    </View>
  );
};
