import {MemberDataSource} from '@src/Store/Slices/Member.slice';
import {useAppDispatch} from '@src/Store/hooks';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import getAuthorName from '@src/Umbraco/Utils/element/getAuthorName';
import {CardBookmarkProps} from 'design-system/src/Components/Card/CardBookmark/CardBookmark.web';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {useMapCourseCateg} from '@src/Umbraco/Utils/content/mapCourseCategItems';
import {OverviewService} from '@src/Umbraco/Utils/Overview/overview.service';
import {IGetMainContentListByIdQuery} from '@src/Api/Graphql/gql/types.generated';
import useAppNavigation from '@src/utils/useAppNavigation';

export const useProfileBookmarksContent = (
  data: IGetMainContentListByIdQuery | undefined,
  source: MemberDataSource = 'watch',
) => {
  const {t} = useTranslation('profile');
  const dispatch = useAppDispatch();
  const {isMobile} = useBreakpoint();
  const navigation = useAppNavigation();
  const {mapLearnItem} = useMapCourseCateg();

  const list: ICardBookmarkProps[] = useMemo(() => {
    const _list: ICardBookmarkProps[] = [];
    data?.allContent.items.forEach(bookmark => {
      if (bookmark) {
        if (bookmark.__typename === 'News') {
          const {title, author, thumbnail, url, id, __typename} = bookmark;
          if (id && thumbnail && source === 'watch') {
            _list.push({
              cover: {uri: thumbnail?.url},
              title: title || '',
              description: getAuthorName(author),
              size: isMobile ? 'news' : 'newsW',
              onPress: () =>
                new RouteFactory(
                  {
                    url: url || '',
                    __typename,
                    id,
                  },
                  navigation,
                  dispatch,
                  title || '',
                ).navigate(),
              id,
              url,
              __typename,
            });
          }
        } else {
          switch (bookmark.__typename) {
            case 'Collection':
            case 'VODItem':
            case 'LiveEvent':
              {
                const {
                  title,
                  shortDescription,
                  subtitle,
                  url,
                  __typename,
                  thumbnail,
                  id,
                } = bookmark;
                if (id && thumbnail && source === 'watch') {
                  _list.push({
                    cover: {uri: thumbnail?.url},
                    title: title || '',
                    description: shortDescription || subtitle,
                    size: isMobile ? 'small' : 'smallW',
                    onPress: () =>
                      new RouteFactory(
                        {
                          url: url || '',
                          id: id,
                          __typename,
                        },
                        navigation,
                        dispatch,
                        title || '',
                      ).navigate(),
                    id,
                    url,
                    __typename,
                  });
                }
              }
              break;

            case 'PodcastItem':
              {
                const {
                  title,
                  id,
                  shortDescription,
                  url,
                  __typename,
                  thumbnail,
                } = bookmark;
                if (id && thumbnail && source === 'listen') {
                  _list.push({
                    cover: {uri: thumbnail?.url},
                    title: title || '',
                    description: shortDescription,
                    size: isMobile ? 'small' : 'smallW',
                    onPress: () =>
                      new RouteFactory(
                        {
                          url: url || '',
                          id: id,
                          __typename,
                        },
                        navigation,
                        dispatch,
                        title || '',
                      ).navigate(),
                    id,
                    url,
                    __typename,
                  });
                }
              }
              break;
            case 'Radio':
              {
                if (bookmark && bookmark.__typename === 'Radio') {
                  const {
                    id,
                    title,
                    subtitle,
                    thumbnail,
                    url = '',
                    __typename,
                  } = bookmark;
                  // const {title, subtitle, url, id, __typename, thumbnail} =
                  // bookmark.contentLink;
                  // console.log('here', )
                  if (id && __typename && source === 'listen') {
                    _list.push({
                      cover: {uri: thumbnail?.url},
                      title: title || '',
                      description: subtitle || '',
                      size: isMobile ? 'small' : 'smallW',
                      onPress: () =>
                        new RouteFactory(
                          {
                            url: url || '',
                            id: id,
                            __typename,
                          },
                          navigation,
                          dispatch,
                          title || '',
                        ).navigate(),
                      id,
                      url,
                      __typename,
                    });
                  }
                }
              }
              break;
            case 'Playlist':
              {
                if (bookmark.__typename === 'Playlist') {
                  const {
                    id,
                    title,
                    subtitle,
                    thumbnail,
                    url = '',
                    __typename,
                  } = bookmark;
                  // const {title, subtitle, url, id, __typename, thumbnail} =
                  // bookmark.contentLink;
                  // console.log('here', )
                  if (id && __typename && source === 'listen') {
                    _list.push({
                      cover: {uri: thumbnail?.url},
                      title: title || '',
                      description: subtitle || '',
                      size: isMobile ? 'small' : 'smallW',
                      onPress: () =>
                        new RouteFactory(
                          {
                            url: url || '',
                            id: id,
                            __typename,
                          },
                          navigation,
                          dispatch,
                          title || '',
                        ).navigate(),
                      id,
                      url,
                      __typename,
                    });
                  }
                }
              }
              break;
            case 'Podcast':
              {
                const {title, subtitle, url, id, __typename, thumbnail} =
                  bookmark;
                if (id && thumbnail && source === 'listen') {
                  _list.push({
                    cover: {uri: thumbnail?.url},
                    title: title || '',
                    description: subtitle || '',
                    size: isMobile ? 'small' : 'smallW',
                    onPress: () =>
                      new RouteFactory(
                        {
                          url: url || '',
                          id: id,
                          __typename,
                        },
                        navigation,
                        dispatch,
                        title || '',
                      ).navigate(),
                    id,
                    url,
                    __typename,
                  });
                }
              }
              break;
            case 'JobTraining':
              {
                const {title, cover, id, url, partners, length, __typename} =
                  bookmark;
                if (id && cover && source === 'learn') {
                  _list.push({
                    title: title || '',
                    size: isMobile ? 'small' : 'smallW',
                    duration: `${length} ${t('hours')}`,
                    cover: {
                      uri:
                        cover &&
                        cover[0] &&
                        cover[0].content &&
                        cover[0].content.__typename === 'HeroVideoCover'
                          ? cover[0].content.image?.url
                          : undefined,
                    },
                    tag: {
                      variant: 'job',
                      text: t('Job Training').toUpperCase(),
                    },
                    id,
                    url: url || '',
                    partenerLogo:
                      partners &&
                      partners[0] &&
                      partners[0].__typename === 'Partner'
                        ? partners[0].logo?.url
                        : undefined,
                    __typename: 'JobTraining',
                    onPress: () =>
                      new RouteFactory(
                        {
                          url: url || '',
                          id: id,
                          __typename,
                        },
                        navigation,
                        dispatch,
                        title || '',
                      ).navigate(),
                  });
                }
              }
              break;
            case 'Course':
              {
                const {
                  title,
                  thumbnail,
                  id,
                  url,
                  linkedPartners: partners,
                  __typename,
                } = bookmark;
                const mappedCourse = mapLearnItem(bookmark);
                const {duration} = OverviewService.getCourseOverview(bookmark);
                if (id && thumbnail && source === 'learn') {
                  _list.push({
                    isDone: mappedCourse?.isDone,
                    title: title || '',
                    duration,
                    cover: {
                      uri: thumbnail.url,
                    },
                    size: isMobile ? 'small' : 'smallW',
                    tag: {
                      variant: 'course',
                      text: t('course'),
                    },
                    id,
                    url: url || '',
                    partenerLogo:
                      partners &&
                      partners[0] &&
                      partners[0].__typename === 'Partner'
                        ? partners[0].logo?.url
                        : undefined,
                    __typename,
                    onPress: () =>
                      new RouteFactory(
                        {
                          url: url || '',
                          id: id,
                          __typename,
                        },
                        navigation,
                        dispatch,
                        title || '',
                      ).navigate(),
                  });
                }
              }
              break;
            default:
              // {
              //   const {
              //     title,
              //     name,
              //     subtitle,
              //     shortDescription,
              //     thumbnail,
              //     url,
              //     id,
              //     __typename,
              //   } = bookmark.contentLink as any;
              //   if (id && thumbnail) {
              //     _list.push({
              //       cover: {uri: thumbnail?.url},
              //       title: title || name || '',
              //       description: shortDescription || subtitle,
              //       size: 'small',
              //       onPress: () =>
              //         new RouteFactory(
              //           {
              //             url: url,
              //             id: id,
              //             __typename: __typename,
              //           },
              //           navigation,
              //           dispatch,
              //           title || '',
              //         ).navigate(),
              //     });
              //   }
              // }
              break;
          }
        }
      }
    });

    return _list;
  }, [data, dispatch, navigation, source]);

  return list;
};

export interface ICardBookmarkProps extends CardBookmarkProps {
  url?: string;
  id: string;
  __typename: RouteTypename;
  partenerLogo?: string;
  isDone?: boolean;
}
