import useParams from '@src/Hooks/useParams';
import useDefaultPaywall from '@src/Store/selectors/useDefaultPaywall';
import useMember from '@src/Store/selectors/useMember';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import PaywallSectionPage from '@src/Umbraco/Pages/PaywallSectionPage/PaywallSectionPage';
import React from 'react';
import {View} from 'react-native';

const ChoosePlanPage = () => {
  const member = useMember();
  const params = useParams();
  const {isMobile} = useBreakpoint();

  const defaultPaywall = useDefaultPaywall();
  const {paywall} = params || {};
  const paywallId =
    paywall && paywall.__typename === 'Paywall'
      ? paywall.id
      : defaultPaywall?.id;

  if (!member) {
    return <div>No member found</div>;
  }
  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}>
      <PaywallSectionPage
        variant="choosePlan"
        id={paywallId}
        orientation={isMobile ? 'portrait' : 'landscape'}
      />
    </View>
  );
};

export default ChoosePlanPage;
