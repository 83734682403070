// import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {IUmbracoRouteParams} from '~Umbraco/Interface/Umbraco.interface';
// import {getAppRoutePrefix} from './navigationUtils';
// import {compile} from 'path-to-regexp';
import {AppRoutesEnum} from '@src/Constant/routes';
// import queryString from 'query-string';
import {useCurrentLanguage} from '@src/Store/Slices/LanguageSlice/Language.selector';
import {languageMapper} from '@src/Services/i18n/languageMapper';

const umbracoShareLink = (url: string) => {
  return url.split('/').filter(Boolean).slice(2).join('/') + '/';
};
const useGetLinkToShare = () => {
  const currentLanguage = useCurrentLanguage();
  const lang = languageMapper(currentLanguage);

  const getLinkToShare = ({
    param,
    path,
  }: {
    path?: AppRoutesEnum;
    param: IUmbracoRouteParams;
  }) => {
    // const screen = path ?? new RouteFactory(param, {}).screen;

    // const to = screen.startsWith('/app/')
    //   ? screen.replace('/app/', '/')
    //   : screen;

    // const appRoutePrefix = getAppRoutePrefix();

    // const pathTo = compile(to + '')(param || {});

    // const finalRoute = queryString.stringifyUrl({
    //   url: `${appRoutePrefix}${pathTo}`,
    //   query: {
    //     ...param,
    //     id: pathTo.indexOf(param.id) >= 0 ? undefined : param.id,
    //   },
    // });

    // const formattedLink = finalRoute.replace('/app/app/', '/app/');

    const formattedLink = umbracoShareLink(param.url || '');

    const baseUrl = `https://${window.location.host}`;

    const fullUrl = `${baseUrl}/${lang || 'en'}/p/${formattedLink}`;
    console.log('fullUrl', {
      url: param.url,
      formattedLink,
      fullUrl,
    });

    return fullUrl;
  };

  return {getLinkToShare};
};

export default useGetLinkToShare;
