import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberObtainedJobBody} from './interface/memberDataBody.interface';
import {IObtainedJobResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/obtained-job';
export const createMemberObtainedJobEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberObtainedJobById: builder.query<IObtainedJobResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberObtainedJob: builder.query<{data: IObtainedJobResponse[]}, void>({
    query: () => ({
      url: endpoint,
      method: 'GET',
    }),
  }),
  updateMemberObtainedJob: builder.query<
    IObtainedJobResponse,
    {id?: string; data: IMemberObtainedJobBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
});
