import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import {CustomFastImageProps} from '../../Components/CustomFastImage/CustomFastImageProps';
import useBreakpoint from '../Grid/hooks/useBreakpoint';
import AcqCategoryItem, {
  IAcqCategoryItemSize,
} from './AcqCategoryItem/AcqCategoryItem.web';
import {
  _AcqCategorySliderInner,
  _AcqCategoryContainer,
  _AcqCategoryHeadingWrap,
} from './AcqCategoryWidget.styled';
import AcqCategoryWidgetContext from './AcqCategoryWidgetContext';

export interface IAcqCategoryWidgetProps<T extends any> {
  list: T[];
  getBackground?: (item: T) => CustomFastImageProps['source'];
  renderItem: (item: T, index: number, currentHover: number) => ReactNode;
  onClikItem?: (item: T) => void;
  currentIndex?: number;
  onChangeIndex?: (index: number) => void;
}

const AcqCategoryWidget = <T extends any>(
  props: IAcqCategoryWidgetProps<T>,
) => {
  const {
    list = [],
    renderItem,
    getBackground,
    onClikItem,
    currentIndex = 0,
    onChangeIndex,
  } = props;
  const [currentSlide, setCurrentSlide] = useState<number>(currentIndex || 0);
  const [currentHover, setCurrentHover] = useState<number>(currentIndex || 0);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {isMobile} = useBreakpoint();
  const aspectRatio = isMobile ? 287 / 328 : 429 / 490;

  const sizeOrder: IAcqCategoryItemSize[] = list.map((_, index) =>
    index === currentSlide ? 'large' : 'small',
  );

  const topPadding: string[] = list.map(() => '0');
  const totalCount: number = list.length;

  const gap: number = isMobile ? 14 : 12;
  const mapSize: Record<IAcqCategoryItemSize, number> = isMobile
    ? {
        large: 287,
        small: 224,
      }
    : {
        large: 429,
        small: 321,
      };

  const width: number | undefined =
    (list.length - 1) * mapSize.small + mapSize.large + 8 * (list.length - 1);
  const getPosition = (currentIndex: number): number => {
    if (currentIndex === 0) {
      return 0;
    }
    // mobile position calcul
    if (isMobile) {
      let totalOffset: number = 0;
      const leftSpacing =
        sizeOrder[currentIndex] &&
        mapSize[sizeOrder[currentIndex]] &&
        containerWidth
          ? (containerWidth - mapSize.large - 16 * 3) / 2
          : 16;

      for (let i = 0; i < currentIndex; i++) {
        totalOffset += mapSize.small || 0;
        if (i > 0) {
          totalOffset += gap;
        }
      }

      return totalOffset - leftSpacing;
    }

    // default position calcul
    const availablePosition = width - containerWidth;
    const offset = (availablePosition / totalCount) * currentIndex;
    if (offset >= containerWidth - mapSize.small / 2) {
      return offset + mapSize.small / 2;
    }
    return offset;
  };

  const isEndReached: boolean = containerWidth
    ? width - getPosition(currentSlide) < containerWidth
    : false;

  // handler@
  const handleNext = () => {
    const nextValue = Math.min(list.length - 1, currentSlide + 1);
    if (nextValue !== currentSlide) setCurrentSlide(nextValue);
    setCurrentHover(nextValue);
    onChangeIndex?.(nextValue);
  };
  const handlePrev = () => {
    const prevValue = Math.max(0, currentSlide - 1);
    if (prevValue !== currentSlide) setCurrentSlide(prevValue);
    setCurrentHover(prevValue);
    onChangeIndex?.(prevValue);
  };

  const containerHandler = useSwipeable({
    preventScrollOnSwipe: true,
    trackMouse: true,
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  const handleClickAcq = (_item: T) => () => {
    onClikItem?.(_item);
  };
  const handleHover = (index: number) => () => {
    setCurrentHover(index);
    setCurrentSlide(index);
    onChangeIndex?.(index);
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerOffset = containerRef.current.getBoundingClientRect();
      setContainerWidth(containerOffset.width);
    }

    const handleResize = () => {
      if (containerRef.current) {
        const containerOffset = containerRef.current.getBoundingClientRect();
        setContainerWidth(containerOffset.width);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AcqCategoryWidgetContext.Provider value={{mapSize}}>
      <_AcqCategoryContainer>
        <div className="acq-category-slide" ref={containerRef}>
          <div
            {...containerHandler}
            style={{
              overflow: 'hidden',
              userSelect: 'none',
              display: 'flex',
              justifyContent: width < containerWidth ? 'center' : undefined,
            }}>
            <_AcqCategorySliderInner
              className="slider-innner"
              gap={8}
              $position={
                isEndReached || currentSlide === totalCount - 1
                  ? width - containerWidth + 16 * 3
                  : currentSlide === 0
                    ? 0
                    : getPosition(currentSlide)
              }
              $width={width}
              style={{cursor: 'grab', minHeight: mapSize.large / aspectRatio}}>
              {list.map((item, index) => {
                const disabled = index !== currentHover;
                return (
                  <AcqCategoryItem
                    key={index}
                    image={getBackground?.(item)}
                    size={disabled ? 'small' : 'large'}
                    style={{paddingTop: topPadding[index] || 0, height: '100%'}}
                    disabled={disabled}
                    onClick={handleClickAcq(item)}
                    onMouseEnter={handleHover(index)}
                    aspectRatio={aspectRatio}>
                    {renderItem(item, index, currentHover)}
                  </AcqCategoryItem>
                );
              })}
            </_AcqCategorySliderInner>
          </div>
        </div>
      </_AcqCategoryContainer>
    </AcqCategoryWidgetContext.Provider>
  );
};

export default AcqCategoryWidget;
