import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IValidationElementFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import {IInterestFormPage} from '@src/Api/Umbraco/Hooks/useSignupForm';
import {Form} from '@src/Api/Umbraco/interface/UmbracoTypes';

interface SettingsState {
  onboarding: {
    cover?: string;
    webCover?: string;
    title?: string;
    subtitle?: string;
    cTALabel?: string;
  };
  profile: {
    cover?: string;
  };
  signup: {
    signupForm?: Form;
    additionnalStep: IInterestFormPage[];
  };
  password: {
    rules: IValidationElementFragmentFragment[];
  };
}

const initialState = {
  onboarding: {
    cover: '',
    webCover: '',
    title: '',
    subtitle: '',
    cTALabel: '',
  },
  profile: {},
  signup: {
    additionnalStep: [],
  },
  password: {
    rules: [],
  },
} as SettingsState;

// Then, handle actions in your reducers:
const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setOnboarding(state, action: PayloadAction<SettingsState['onboarding']>) {
      state.onboarding = action.payload;
      return state;
    },
    setProfile(state, action: PayloadAction<SettingsState['profile']>) {
      state.profile = action.payload;
      return state;
    },
    setSignup(state, action: PayloadAction<SettingsState['signup']>) {
      state.signup = action.payload;
      return state;
    },
    setPassword(state, action: PayloadAction<SettingsState['password']>) {
      state.password = action.payload;
      return state;
    },
  },
});

export default settingsSlice;
