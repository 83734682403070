import React, {useState} from 'react';
import {IMask, IMaskMixin} from 'react-imask';
import {type InputMaskElement} from 'imask';
import moment from 'moment';
import FloatingLabel from '../../FloatingLabel/FloatingLabel';
import styled from 'styled-components';
import {IDatePickerProps} from '../DatePicker.web';
import Metrics from '../../../../Theme/Metrics';
import defaultTheme from '../../../../Theme/defaultTheme';
import {typographyVariantStyled} from '../../../Typography/Typography.styles';

type IInputDateProps = IDatePickerProps &
  Partial<InputMaskElement> & {
    momentFormat?: string;
  };

const StyledInput = styled('input')<{}>(() => {
  const prefixIcon = false;
  const suffixIcon = false;
  return {
    flex: 1,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    // backgroundColor: 'red',
    paddingLeft: Metrics.horizontalScale(prefixIcon ? 48 : 24),
    paddingRight: Metrics.horizontalScale(suffixIcon ? 25 : 24),
    // height: height,
    minHeight: Metrics.verticalScale(48),
    color: defaultTheme.white,
    // textAlignVertical: multiline ? 'top' : 'center',
    fontSize: typographyVariantStyled.body2.fontSize,
    fontFamily: typographyVariantStyled.body2.fontFamily,
    letterSpacing: typographyVariantStyled.body2.letterSpacing,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    zIndex: 2,
  };
});

// @ts-ignore
const MaskedInput = IMaskMixin<IInputDateProps, any>(
  ({
    inputRef,
    // @ts-ignore
    momentFormat,
    ...props
  }) => {
    const [hasFocus, setHasFocus] = useState(false);
    return (
      <FloatingLabel
        hasFocus={hasFocus}
        hasValue={!!props.defaultValue}
        label={props.label}>
        <StyledInput
          {...props}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          // @ts-ignore
          ref={inputRef}
        />
      </FloatingLabel>
    );
  },
);

const InputDate = (props: IInputDateProps) => {
  const {momentFormat, onChange, ...rest} = props;
  const separator = momentFormat?.indexOf('/') !== -1 ? '/' : '-';
  return (
    <MaskedInput
      onChange={e => onChange?.(e.currentTarget.value)}
      {...rest}
      momentFormat={momentFormat}
      mask={Date}
      pattern={momentFormat}
      lazy={true}
      eager={true}
      min={new Date(1900, 0, 1)}
      max={new Date()}
      format={(date: string) => {
        const formattedDate = moment(date).format(momentFormat);
        if (formattedDate.length === 2 || formattedDate.length === 4) {
          return formattedDate + separator;
        }
        return formattedDate;
      }}
      parse={(str: string) => {
        return moment(str, momentFormat);
      }}
      blocks={{
        DD: {mask: IMask.MaskedRange, from: 1, to: 31},
        MM: {mask: IMask.MaskedRange, from: 1, to: 12},
        YYYY: {mask: IMask.MaskedRange, from: 1900, to: moment().year()},
      }}
    />
  );
};

export default InputDate;
