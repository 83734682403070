import {StyleSheet} from 'react-native';
import Metrics, {height, width} from '../../../Theme/Metrics';

export const listModalStyles = StyleSheet.create({
  body: {
    width: width,
    height: height,
    alignItems: 'center',
  },
  scrollBody: {
    height: height,
  },
  blurViewStyle: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  item: {
    paddingVertical: Metrics.verticalScale(16),
  },
  title: {
    marginTop: Metrics.verticalScale(66),
    marginBottom: Metrics.verticalScale(66),
  },
  buttonClose: {
    backgroundColor: '#fff',
    width: Metrics.horizontalScale(64),
    height: Metrics.horizontalScale(64),
    borderRadius: Metrics.moderateScale(64) / 2,
    marginTop: Metrics.verticalScale(56),
    marginBottom: Metrics.verticalScale(72),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    alignItems: 'center',
  },
});
