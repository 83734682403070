const Images = {
  bgHomeImage: require('./images/bg_home_image.png'),
  channel: {
    Africa: require('./images/channel/Africa.png'),
    Ayiti: require('./images/channel/Ayiti.png'),
    Brazuca: require('./images/channel/Brazuca.png'),
    Caribean: require('./images/channel/Caribean.png'),
    Gospel: require('./images/channel/Gospel.png'),
    Hits: require('./images/channel/Hits.png'),
    Jama: require('./images/channel/Jama.png'),
    Kitoko: require('./images/channel/Kitoko.png'),
    Latina: require('./images/channel/Latina.png'),
    Mboa: require('./images/channel/Mboa.png'),
    Musika: require('./images/channel/Musika.png'),
    Muzika: require('./images/channel/Muzika.png'),
    Mziki: require('./images/channel/Mziki.png'),
    Mziki2: require('./images/channel/Mziki2.png'),
    Naija: require('./images/channel/Naija.png'),
    Naija2: require('./images/channel/Naija2.png'),
    Sport: require('./images/channel/Sport.png'),
    SportStars: require('./images/channel/Sportstars.png'),
    Toca: require('./images/channel/Toca.png'),
    Urban: require('./images/channel/Urban.png'),
    Vanilla: require('./images/channel/Vanilla.png'),
    Vault: require('./images/channel/Vault.png'),
  },
  premiumBg: require('./images/background/PremiumBg.png'),
  quizBg: require('./images/background/QuizBg.png'),
  partners: {
    schneider: require('./images/partners/Schneider.png'),
  },
  learn: {
    poster1: require('./images/learn/learn-poster.jpg'),
    cover1: require('./images/learn/learn-cover.jpg'),
    cover2: require('./images/learn/learn-cover2.jpg'),
    cover3: require('./images/learn/learn-cover3.jpg'),
  },
  auth: {
    bg: require('./images/auth/auth-bg.jpg'),
  },
  subscription: {
    bg: require('./images/subscription/bg.png'),
  },
  form: {
    channel1: require('./images/forms/Channels-1.png'),
    channel2: require('./images/forms/Channels-2.png'),
    channel3: require('./images/forms/Channels-3.png'),
  },
  card: {
    bg: require('./images/card/bg.png'),
  },
};
export default Images;
