import React from 'react';
import {StyleSheet, Pressable, View} from 'react-native';
import {PlayButtonSvg} from '../../../assets/ImageSvg';
import {TextStyles} from '../../../styles/TextStyles';
import {darkTheme} from '../../../styles/Themes';
import Metrics from '../../../Theme/Metrics';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import CustomProgressBar from '../../CustomProgressBar/CustomProgressBar';
import DownloadButton from '../../DownloadButton/DownloadButton.web';
import Icon from '../../Icon/Icon';
import IconWithText from '../../IconWithText/IconWithText';
import {ReadMoreText} from '../../ReadMoreText';
import CardAside from '../CardAside/CardAside.web';
import GenericCard from '../GenericCard/GenericCard';
import {sharedCardStyles} from './SharedCard.styles';
import FavorisIcon, {
  FavorisProps,
} from '../../CheckBox/FavoirsIcon/FavorisIcon';
import PremiumIcon from '../PremiumIcon';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import RowCard from '../RowCard/RowCard.web';

export type SharedCardProps = {
  cover?: CustomFastImageProps['source'];
  title: string;
  length: string;
  description: string;
  progress?: number;
  downloadProgress?: number;
  onPressShareIcon?: () => void;
  onPressFavIcon?: FavorisProps['onPressFav'];
  onPressDownloadIcon?: () => void;
  onPressPlayIcon?: () => void;
  isLiked?: boolean;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  isFreemium?: boolean;
  premiumText?: string;
  contentType?: 'VodCollection' | 'PodcastSingle';
};

const SharedCard = ({
  cover,
  title,
  length,
  description,
  progress,
  onPressShareIcon,
  onPressFavIcon,
  onPressDownloadIcon,
  onPressPlayIcon,
  downloadProgress,
  isLiked,
  seeMoreLabel,
  seeLessLabel,
  isFreemium,
  contentType,
}: // premiumText,
SharedCardProps) => {
  const {isMobile} = useBreakpoint();
  const isVodCollection = true;
  const isPodcastSingle = false;
  // const isVodCollection = contentType === 'VodCollection';
  // const isPodcastSingle = contentType === 'PodcastSingle';
  return (
    <View>
      <RowCard
        flexDirection={isVodCollection ? 'row' : 'column'}
        left={
          <GenericCard
            onPress={onPressPlayIcon}
            coverImage={cover}
            type={isMobile ? 'small' : isVodCollection ? 'medium' : 'big'}
            contentStyle={{
              paddingBottom: Metrics.verticalScale(4),
            }}
            footer={
              <View>
                <View
                  style={{
                    position: 'absolute',
                    bottom: Metrics.horizontalScale(9),
                    right: Metrics.horizontalScale(9),
                  }}>
                  {isFreemium !== undefined && !isFreemium && <PremiumIcon />}
                </View>
                {progress !== 1 ? (
                  <CustomProgressBar progress={progress} />
                ) : (
                  <Icon
                    name="check-watch"
                    style={sharedCardStyles.checkWatch}
                  />
                )}
              </View>
            }
            center={
              <Pressable
                style={[
                  StyleSheet.absoluteFill,
                  {justifyContent: 'center', alignItems: 'center'},
                ]}
                onPress={onPressPlayIcon}>
                <PlayButtonSvg />
              </Pressable>
            }
          />
        }
        right={
          <CardAside
            title={title}
            TrailingIc={
              onPressDownloadIcon &&
              (() => (
                <DownloadButton
                  onDownload={() => {}}
                  onStop={() => {}}
                  progress={downloadProgress}
                />
              ))
            }
            onPressTrailingIc={onPressDownloadIcon}
            isFilledParent
            isTrailingIconCentered>
            <View>
              <IconWithText
                text={length}
                icon="clock"
                size="16"
                textColor={darkTheme.colors.secondaryGray}
                variant="ui3"
                textStyle={TextStyles.infoText}
              />
              {!isMobile && !isPodcastSingle && (
                <View
                  style={{
                    ...sharedCardStyles.descriptionContainer,
                    maxWidth: 496,
                  }}>
                  <ReadMoreText
                    numberOfLines={3}
                    text={description}
                    style={sharedCardStyles.description}
                    seeMoreLabel={seeMoreLabel}
                    seeLessLabel={seeLessLabel}
                  />
                </View>
              )}
              <View style={sharedCardStyles.icContainer}>
                <FavorisIcon
                  style={[sharedCardStyles.pressableZone]}
                  iconSize="16"
                  isLiked={isLiked}
                  onPressFav={onPressFavIcon}
                />
                <Pressable
                  style={[
                    sharedCardStyles.pressableZone,
                    {marginLeft: Metrics.verticalScale(12)},
                  ]}
                  onPress={onPressShareIcon}>
                  <Icon name="share" size="16" />
                </Pressable>
              </View>
            </View>
          </CardAside>
        }
      />
      {/* <Typography
        children={description}
        style={sharedCardStyles.description}
        variant="body3"
      /> */}
      {isMobile && (
        <View style={sharedCardStyles.descriptionContainer}>
          <ReadMoreText
            numberOfLines={3}
            text={description}
            style={sharedCardStyles.description}
            seeMoreLabel={seeMoreLabel}
            seeLessLabel={seeLessLabel}
          />
        </View>
      )}
    </View>
  );
};

export default SharedCard;
