import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const carouselHeaderStyles = StyleSheet.create({
  circleDiv: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    bottom: Metrics.verticalScale(22),
  },
  whiteCircle: {
    width: Metrics.horizontalScale(4),
    height: Metrics.horizontalScale(4),
    borderRadius: Metrics.moderateScale(2),
    marginHorizontal: Metrics.horizontalScale(8),
    backgroundColor: '#FFF',
  },
});
