import {AppRoutesEnum} from '@src/Constant/routes';
import useParams from '@src/Hooks/useParams';
import {useAppDispatch} from '@src/Store/hooks';
import appListenSlice from '@src/Store/Slices/AppSlice/listen.slice';
import appPodcastSlice from '@src/Store/Slices/AppSlice/podcast.slice';
import useAppNavigation from '@src/utils/useAppNavigation';
import React, {useEffect} from 'react';

const ListenRedirection = () => {
  const params = useParams();
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.__typename == 'Radio' || params.__typename == 'Playlist') {
      dispatch(appListenSlice.actions.setListenParams(params as any));
      dispatch(appListenSlice.actions.openPlayer());
    }
    if (params.__typename == 'Podcast') {
      dispatch(appListenSlice.actions.setListenParams(params as any));
      dispatch(appPodcastSlice.actions.openPlayer());
    }
    navigation.navigate(AppRoutesEnum.HOME, {
      replace: true,
    });
  }, []);

  return <></>;
};

export default ListenRedirection;
