import React, {FC, useEffect} from 'react';
import SignUpForm from './SignUpForm/SignUpForm';
import SignUpInterest from './SignUpInterest/SignUpInterest';
import FirstSignUp from './FirstSignUp/FirstSignUp';
import {useAppSelector} from '@src/Store/hooks';
import {useDispatch} from 'react-redux';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import useEvent from 'design-system/src/Hooks/useEvent';

const SignUp: FC = () => {
  const step = useAppSelector(state => state.appAuth.registerStep.page);
  const dispatch = useDispatch();
  const currentModal = useAppSelector(
    state => state.appAuth.openAuthenticationModal,
  );

  const openModal = useEvent(() => {
    if (currentModal?.open) return;
    dispatch(
      appAuthSlice.actions.openAuthenticationModal({
        open: true,
        redirect: currentModal?.redirect || '/app',
      }),
    );
  });

  useEffect(() => {
    openModal();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 418,
        position: 'relative',
      }}>
      {step == 'first' && <FirstSignUp />}
      {step === 'form' && <SignUpForm />}
      {step === 'interest' && <SignUpInterest />}
    </div>
  );
};

export default SignUp;
