/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useMemo, useState, useTransition} from 'react';
import {NavLiveTvCardWeb as NavLiveTvCard} from 'design-system/src/Components/Navigation';
import {
  IChannelFragmentFragment,
  // useLiveTvPageLazyQuery,
  useLiveTvPageQuery,
} from '~Api/Graphql/gql/types.generated';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import useTracker from '~Services/Amplitude/hooks/useTracker';
// import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import {useLocation} from '@reach/router';
import queryString from 'query-string';
import {navigate} from 'gatsby';
import {useAppSelector} from '@src/Store/hooks';
import SuspenseAfterRequired from 'design-system/src/library/AfterRequired/SuspenseAfterRequired';
import {HomeContentLoader} from 'design-system/src/Components/ContentLoader';

const TvGuideTable = React.lazy(() => import('./TvGuideTable/TvGuideTable'));

const LiveTvList = React.lazy(() => import('./LiveTvList/LiveTvList'));

const isGuideSelected = (query: string): boolean => {
  if (query) {
    const queriedTheme = queryString.parse(query);
    const {tvGuide} = queriedTheme;

    // Ensure a valid expected value is passed
    return !!tvGuide;
  }

  return false;
};

const LiveTvScreen = () => {
  const tracker = useTracker();
  const location = useLocation();
  const defaultITvGuide = !!location.search && isGuideSelected(location.search);
  const [isTVGuide, setIsTVGuide] = useState<boolean>(defaultITvGuide);
  const [activeTag, setActiveTag] = useState<string>();
  const [pending, startTransition] = useTransition();
  const {t} = useTranslation('translation');
  const {page, loading: loadingRegion} = useRegionUrl();
  const pageId: string | undefined = page.watch?.children.liveTv?.id;
  // const currentLanguage = useCurrentLanguage();

  const {isMobile} = useBreakpoint();

  const {
    appListen: {isOpenPlayer: isOpenRadioPlayer, isMini: isRadioMini},
    appPodcast: {isOpenPlayer: isOpenPodcastPlayer, isMini: isPodcastMini},
  } = useAppSelector(state => ({
    appListen: state.appListen,
    appPodcast: state.appPodcast,
  }));

  const {data, loading, variables} = useLiveTvPageQuery({
    variables: {
      id: pageId,
    },
    fetchPolicy: 'no-cache',
    skip: !pageId,
  });

  const list: IChannelFragmentFragment[] = useMemo(() => {
    const _list: IChannelFragmentFragment[] = [];
    if (data && data.liveTVPage && data.liveTVPage.liveTVContent) {
      data.liveTVPage.liveTVContent.forEach(item => {
        if (
          item &&
          item.__typename === 'Channel' &&
          !isElementRestricted(item)
        ) {
          if (
            (activeTag &&
              item.regions &&
              item.regions.find(
                reg =>
                  reg &&
                  reg.__typename === 'ContentTag' &&
                  reg.id === activeTag,
              )) ||
            !activeTag
          ) {
            _list.push(item);
          }
        }
      });
    }
    return _list;
  }, [data, activeTag]);

  const handleSitchTab = (value: boolean) => () => {
    const qs = queryString.parse(location.search);
    if (value === true) {
      tracker.general.tvGuide();
      qs.tvGuide = '1';
    } else {
      qs.tvGuide = null;
    }
    navigate(`${location.pathname}?${queryString.stringify(qs)}`, {
      state: {
        ...(location?.state || {}),
      },
    });
    startTransition(() => {
      setIsTVGuide(value);
    });
  };

  useEffect(() => {
    if (defaultITvGuide) {
      tracker.general.tvGuide();
    }
  }, [defaultITvGuide, tracker.general]);

  const {
    profile: {cover},
  } = useAppSelector(state => state.settings);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 1440,
        margin: '0 auto',
        position: 'relative',
      }}>
      {!isMobile && (
        <div
          style={{
            aspectRatio: 1440 / 642,
            position: 'absolute',
            zIndex: 0,
            backgroundImage: `url(${cover})`,
            backgroundSize: 'cover',
            width: '100%',
          }}>
          <div
            style={{
              background:
                'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 15.54%, rgba(17, 18, 22, 0.80) 57.45%, #111216 74.32%)',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      )}
      {loadingRegion ? (
        <HomeContentLoader />
      ) : pageId ? (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            paddingTop: isMobile ? 96 : 186,
            paddingBottom: isMobile
              ? 92
              : (isOpenRadioPlayer && isRadioMini) ||
                  (isOpenPodcastPlayer && isPodcastMini)
                ? 100
                : 20,
            paddingLeft: isMobile ? 16 : 60,
            paddingRight: isMobile ? 16 : 60,
            width: '100%',
            margin: '0 auto',
            maxWidth: 1440,
          }}>
          {!isMobile && <Typography variant="h1">live tv</Typography>}
          <div
            style={{
              margin: '24px 0',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8,
                justifyContent: isMobile ? 'space-between' : 'flex-start',
              }}>
              <NavLiveTvCard
                title={t('Channels')}
                icon={'livetv'}
                isActive={!isTVGuide}
                loading={pending}
                onPress={handleSitchTab(false)}
              />
              <NavLiveTvCard
                title={t('Tv Guide')}
                icon={'tvguide'}
                isActive={isTVGuide}
                loading={pending}
                onPress={handleSitchTab(true)}
              />
            </div>
          </div>
          <SuspenseAfterRequired required={isTVGuide}>
            {isTVGuide && (
              <TvGuideTable
                list={list}
                tagFilter={{
                  active: activeTag,
                  onChange: setActiveTag,
                }}
                loading={loading}
              />
            )}
          </SuspenseAfterRequired>

          <SuspenseAfterRequired required={!isTVGuide}>
            {!isTVGuide && (
              <LiveTvList
                list={list}
                tagFilter={{
                  active: activeTag,
                  onChange: setActiveTag,
                }}
                loading={loading}
                isMobile={isMobile}
              />
            )}
          </SuspenseAfterRequired>
        </div>
      ) : (
        <ContentEmpty title={t('Page error')} />
      )}
    </div>
  );
};

export default LiveTvScreen;
