/**
 * PlayerQuizSurveyScreen Component
 *
 * This component renders a quiz/survey player screen with video playback and interactive elements.
 * It manages the state of the current quiz/survey, video playback, bookmarks, and user interactions.
 */

import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {useGetQuizzSurveyByLazyQuery} from '~Api/Graphql/gql/types.generated';
import {useLazyGetVimeoVideoQuery} from '~Api/TraceApi/traceApi';
import useBookmarkCount from '~Hooks/useBookmarkCount';
import {useFindProgression} from '~Hooks/useFindProgression';
import useShareContent from '~Hooks/useShareContent';
import useUpdateBookmarks from '~Hooks/useUpdateBookmarks';
import {useUpdateProgression} from '~Hooks/useUpdateProgression';
import checkVimeoUrl from '~Umbraco/Utils/CheckVimeoUrl';
import {
  IQuizzEpisode,
  useMapQuizzCollection,
} from './Hooks/useMapQuizzCollection';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import {darkTheme} from 'design-system/src/styles/Themes';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import DiscoverActionButton from 'design-system/src/Widget/Discover/DiscoverActionButton/DiscoverActionButton';
import VideoPlayerVertical from 'design-system/src/Widget/Player/VideoPlayer/VideoPlayerVertical.web';
import Button from 'design-system/src/Components/Button/Button';
import Metrics from 'design-system/src/Theme/Metrics';
import useViewPort from 'design-system/src/Hooks/useViewPort';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

const PlayerQuizSurveyScreen = () => {
  // Hooks and state management
  const params = useParams();
  const navigation = useAppNavigation();
  const {t} = useTranslation('translation');
  const {isMobile} = useBreakpoint();
  const {collectionId} = params || {};
  const [firstLoading, setFirstLoading] = useState(true);

  // Custom hooks for data fetching and state management
  const {
    loading: parentLoading,
    quizzSurveyList,
    collectionData,
  } = useMapQuizzCollection(collectionId);
  const {isBookmarked, updateBookMark} = useUpdateBookmarks();
  const shareContent = useShareContent();
  const [fetchQuizzSurvey, {data, loading}] = useGetQuizzSurveyByLazyQuery();
  const [fetchVimeoVideo, {isLoading: loadingVideo}] =
    useLazyGetVimeoVideoQuery();
  const {fetchContentLike, bookmarkCount = {}} = useBookmarkCount();
  const {handleProgression} = useUpdateProgression();
  const {findCurrentTime} = useFindProgression();
  const {viewportHeight} = useViewPort();

  // Local state
  const [videoUrl, setVideoUrl] = useState<string>();
  const [currentId, setCurrentId] = useState<string | undefined>(params?.id);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenDialogShare, setIsOpenDialogShare] = useState<boolean>(false);

  // Refs for tracking playback progress
  const refCurrentTime = useRef(0.0);
  const refCurrentDuration = useRef<number>(0);

  // Effect to handle initial data fetching
  useEffect(() => {
    if (params?.id && !collectionId) {
      fetchQuizzSurvey({
        variables: {
          id: params.id,
        },
      });
    }
  }, [params?.id, collectionId]);

  // Callback to fetch video data
  const handleFetchVideo = useCallback(
    async (quizz: IQuizzEpisode) => {
      setIsLoading(true);
      try {
        if (quizz.sourceUrl) {
          setVideoUrl(quizz.sourceUrl);
        } else if (quizz.vimeoHlsUrl && checkVimeoUrl(quizz.vimeoHlsUrl)) {
          if (videoUrl !== quizz.vimeoHlsUrl) {
            setVideoUrl(quizz.vimeoHlsUrl);
          }
        } else {
          const {data: videoData} = await fetchVimeoVideo(
            quizz.liveEventId + '',
          );

          if (videoData && videoData.hls && videoUrl !== videoData.hls) {
            setVideoUrl(videoData.hls);
          }
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setIsLoading(false);
      }
    },
    [videoUrl, setVideoUrl],
  );

  // Memoized quiz/survey data
  const quizzSurveys = useMemo(() => {
    if (data && data.quizzSurvey) {
      const {
        id,
        url,
        __typename,
        thumbnail,
        liveEventId,
        sourceUrl,
        vimeoHlsUrl,
        shortDescription,
        ctaLabel,
        title,
        questions,
      } = data.quizzSurvey;
      return [
        {
          id,
          url,
          __typename,
          thumbnail,
          liveEventId,
          sourceUrl,
          vimeoHlsUrl,
          shortDescription,
          ctaLabel,
          title,
          hasQuestion: !!questions?.length,
        },
      ];
    }
    return quizzSurveyList || [];
  }, [data, quizzSurveyList]);

  const quizzSurvey = useMemo(() => {
    if (currentId) {
      return quizzSurveys.find(_item => _item.id === currentId);
    }
  }, [currentId, quizzSurveys]);

  // force displaying loading to avoid flash of content
  useEffect(() => {
    setTimeout(() => {
      setFirstLoading(false);
    }, 1000);
  }, []);

  // Effect to fetch video when quiz/survey changes
  useEffect(() => {
    if (quizzSurvey) {
      handleFetchVideo(quizzSurvey);
    }
  }, [quizzSurvey?.id]);

  // Bookmark count for the current quiz/survey
  const count: number =
    quizzSurvey && quizzSurvey.id ? bookmarkCount[quizzSurvey.id] : 0;

  // Effect to fetch bookmark count
  useEffect(() => {
    if (currentId && !bookmarkCount[currentId]) {
      fetchContentLike({
        contentId: currentId,
      });
    }
  }, [currentId]);

  // Callbacks for video player events
  const resetCurrentTimeState = useCallback(() => {
    refCurrentDuration.current = 0;
    refCurrentTime.current = 0;
  }, []);

  const handleOnPlayEnd = useCallback(() => {
    if (currentId && params) {
      const findIndex = quizzSurveys.findIndex(_item => _item.id === currentId);
      if (findIndex !== -1 && quizzSurveys[findIndex + 1]) {
        setCurrentId(quizzSurveys[findIndex + 1].id);
      }
      handleProgression(
        currentId,
        refCurrentTime.current,
        'learn',
        params.__typename,
        refCurrentDuration.current,
      );
      resetCurrentTimeState();
    }
  }, [
    currentId,
    quizzSurveys,
    params,
    handleProgression,
    resetCurrentTimeState,
  ]);

  const handleOnGetDuration = useCallback((duration: number) => {
    refCurrentDuration.current = duration;
  }, []);

  const handleOnTimeProgress = useCallback((_currentTime: number) => {
    refCurrentTime.current = _currentTime;
  }, []);

  // Memoized values
  const showButton = useMemo(() => {
    return (
      !!quizzSurvey?.hasQuestion ||
      !!collectionData?.quizzSurveyCollection?.questions?.length
    );
  }, [quizzSurvey, collectionData]);

  const isLiked = useMemo(
    () => !!isBookmarked(quizzSurvey?.id || ''),
    [quizzSurvey?.id],
  );

  // Callback for button press
  const handlePressButton = useCallback(() => {
    if (quizzSurvey && quizzSurvey.hasQuestion) {
      navigation.navigate(AppRoutesEnum.QUIZZ_SURVEY, {
        state: {
          params: {
            id: quizzSurvey.id,
            __typename: quizzSurvey.__typename,
          },
        },
      });
    } else if (
      collectionData &&
      !!collectionData.quizzSurveyCollection?.questions?.length
    ) {
      navigation.navigate(AppRoutesEnum.QUIZZ_SURVEY, {
        state: {
          params: {
            id: collectionData.quizzSurveyCollection.id,
            __typename: collectionData.quizzSurveyCollection.__typename,
          },
        },
      });
    }
  }, [navigation, quizzSurvey, collectionData]);

  // Render loading state
  if (loading || parentLoading || isLoading || loadingVideo || firstLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: viewportHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CustomActivityIndicator />
      </div>
    );
  }

  // Render error state
  if (!quizzSurvey) {
    return (
      <div
        style={{
          width: '100%',
          height: viewportHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ContentEmpty title={t('Error')} />
      </div>
    );
  }

  // Main render
  return (
    <div
      style={{
        backgroundColor: darkTheme.colors.backgroundBlack,
        width: isMobile ? '100vw' : '100%',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundImage: isMobile
          ? undefined
          : `url(${quizzSurvey?.thumbnail?.url || ''})`,
        backgroundSize: 'cover',
        position: isMobile ? 'fixed' : 'relative',
        minHeight: '100%',
        height: isMobile ? viewportHeight : '100%',
        zIndex: 1000,
      }}>
      <DebugUmbracoId umbracoId={currentId} position="page" />
      <div
        style={{
          width: '100%',
          height: '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backdropFilter: 'blur(40px)',
          background:
            'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 15.54%, rgba(17, 18, 22, 0.80) 57.45%, #111216 74.32%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: isMobile ? '100%' : 430,
            height: '100%',
            padding: !isMobile ? '52px 0' : 0,
          }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
            }}>
            <VideoPlayerVertical
              onTimeProgress={handleOnTimeProgress}
              onGetDuration={handleOnGetDuration}
              title={quizzSurvey?.title || ''}
              source={{
                uri: videoUrl,
              }}
              poster={quizzSurvey?.thumbnail?.url || ''}
              onPlayEnd={handleOnPlayEnd}
              initialTime={findCurrentTime(currentId || '')}
            />
          </div>
          <div
            style={{
              marginTop: 12,
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              position: isMobile ? 'absolute' : 'relative',
              left: 0,
              bottom: isMobile ? '60px' : 0,
              padding: isMobile ? '0 16px' : 0,
              alignItems: isMobile ? 'flex-end' : 'flex-start',
            }}>
            {/* Content information and CTA button */}
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h2">{quizzSurvey?.title || ''}</Typography>
              {!!quizzSurvey.shortDescription && (
                <Typography
                  variant="body3"
                  style={{
                    maxWidth: Metrics.horizontalScale(200),
                  }}>
                  {quizzSurvey.shortDescription}
                </Typography>
              )}
              <Button
                onPress={showButton ? handlePressButton : undefined}
                title={
                  quizzSurvey.ctaLabel || (t('start culture quizz') as string)
                }
                variant="outlined"
                size="small"
                style={{
                  height: Metrics.verticalScale(32),
                  paddingVertical: Metrics.verticalScale(5),
                  marginTop: 12,
                }}
              />
            </div>
            {/* Action buttons */}
            <div
              style={{
                display: 'flex',
                gap: 18,
                flexDirection: isMobile ? 'column' : 'row',
                height: '100%',
              }}>
              <DiscoverActionButton
                style={{marginBottom: 20}}
                icon={{name: isLiked ? 'favoris-fill' : 'favoris'}}
                isLikeType={true}
                isLiked={isLiked}
                onPressFav={(value: boolean) => {
                  if (quizzSurvey) {
                    quizzSurvey.id &&
                      quizzSurvey.url &&
                      updateBookMark(quizzSurvey.id, quizzSurvey.url, value, {
                        id: quizzSurvey.id,
                        url: quizzSurvey.url,
                        __typename: quizzSurvey.__typename,
                        name: quizzSurvey.title,
                        raw: quizzSurvey,
                      });
                  }
                }}
                count={count}
              />
              <DiscoverActionButton
                icon={{name: 'share'}}
                onPress={() => {
                  quizzSurvey &&
                    quizzSurvey.url &&
                    shareContent.share({
                      __typename: quizzSurvey.__typename,
                      url: quizzSurvey.url,
                      message: quizzSurvey.title,
                      id: quizzSurvey.id,
                      cover:
                        quizzSurvey.thumbnail && quizzSurvey.thumbnail.url
                          ? quizzSurvey.thumbnail.url
                          : '',
                      description: quizzSurvey.shortDescription || '',
                    });

                  setIsOpenDialogShare(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ShareDialog
        isOpen={isOpenDialogShare}
        onClose={() => setIsOpenDialogShare(false)}
      />
    </div>
  );
};

export default PlayerQuizSurveyScreen;
