import queryString from 'query-string';
import {useParams as useParamsReachRouter, useLocation} from '@reach/router';
import usePageSliceParams from '@src/Store/Slices/PageSlice/usePageSliceParams';
import useAppLocation from '@src/App/Route/AppRouterContext/useAppLocation';
import getMatchingRoute from '@src/App/Route/AppRouterContext/utils/getMatchingRoute';
import extractParamsFromLocation from '@src/App/Route/AppRouterContext/utils/extractParamsFromLocation';
import {useMemo} from 'react';

const useParams = (): {
  [key: string]: any;
  endUrl?: string;
} => {
  const params = useParamsReachRouter();
  const pageParams = usePageSliceParams();
  const locationReachRouter = useLocation();
  const appLocation = useAppLocation();
  const location = appLocation || locationReachRouter;
  const mergedParams: Record<string, any> = useMemo(() => {
    if (Object.keys(params || {}).length === 0) {
      const matchingRoute = getMatchingRoute(location.pathname);
      const paramsFromLocation = matchingRoute
        ? extractParamsFromLocation(location.pathname, matchingRoute)
        : {};
      return paramsFromLocation || {};
    }
    return params || {};
  }, [params, location]);

  const endUrlKeys = Object.keys(mergedParams || {}).filter(key =>
    key.startsWith('endUrl'),
  );
  const endUrl = decodeURIComponent(
    endUrlKeys.map(key => mergedParams[key]).join('/'),
  );

  const data: {
    [key: string]: any;
    endUrl?: string;
  } = {
    ...queryString.parse(location.search),
    ...(location.state as unknown as any)?.params,
    ...pageParams,
    ...mergedParams,
    endUrl,
  };

  return {
    ...data,
    endUrl: data.endUrl ? `/${data.endUrl}/`.replaceAll('//', '/') : undefined,
  };
};

export default useParams;
