import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import CardSearchItem from 'design-system/src/Components/Card/CardSearchItem/CardSearchItem.web';
import React, {ReactNode} from 'react';
import {View} from 'react-native';
import {
  IPaywallFragmentFragment,
  IRegionFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import usePaywal from '~Hooks/usePaywal';
import {IContentSliderItem} from '~Umbraco/Components/ContentSlider/ContentSlider';
import mapMultiCategoryItems from '~Umbraco/Utils/mapMultiCategoryItems';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import {useDispatch} from 'react-redux';
import useAppNavigation from '@src/utils/useAppNavigation';
import Carousel from 'design-system/src/Components/Carousel/Carousel';
import {AppRoutesEnum} from '@src/Constant/routes';

export const useSectionSearch = () => {
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();
  const navigation = useAppNavigation();
  const dispatch = useDispatch();

  const handleContentSliderItemClick = (item: IContentSliderItem) => () => {
    const {url, title, __typename, accessData, id} = item;
    const canSee = canSeeContent(accessData.accessType);
    const canPreview = getPremiumPreviewSettings(
      accessData.paywall as IPaywallFragmentFragment,
    )?.canPreview;
    if (canSee || canPreview) {
      new RouteFactory(
        {url, __typename, id, source: 'search'},
        navigation,
        dispatch,
        title,
      ).navigate();
    } else {
      navigation.navigate(AppRoutesEnum.PAYWALL, {
        state: {
          params: {
            headerTitle: title || '',
            paywall: accessData.paywall,
            id: 'default',
          },
        },
      });
    }
  };

  const renderBlockPage = (
    data: IRegionFragmentFragment['searchPageBlocks'],
  ) => {
    let pageBlocks: ReactNode[] = [];
    if (data) {
      data.map((item, index: number) => {
        if (item) {
          let content = item.content;
          if (content && content.__typename === 'MulticategoriesSlider') {
            const {title, items} = mapMultiCategoryItems(content);

            pageBlocks.push(
              <View key={`hs-${index}`}>
                <Typography variant="h3" style={{marginBottom: 13}}>
                  {title}
                </Typography>
                <Carousel
                  title={''}
                  data={items}
                  renderItem={({item: dc, index: ii}) => {
                    return (
                      <CardSearchItem
                        title={dc.title || ''}
                        backgroundColor={dc.backgroundColor}
                        key={`dchs-${ii}`}
                        cover={dc.cover}
                        onPress={handleContentSliderItemClick(dc)}
                      />
                    );
                  }}
                  initialNumToRender={5}
                  gap={12}
                />
              </View>,
            );
          } else if (content && content.__typename === 'PartnerSlider') {
            const {title, slider} = content;

            pageBlocks.push(
              <View key={`hs-${index}`}>
                <Typography variant="h3" style={{marginBottom: 13}}>
                  {title}
                </Typography>
                <Carousel
                  title={''}
                  data={
                    slider?.filter(e => !!e && e.__typename === 'Partner') || []
                  }
                  renderItem={({item: slide, index: ii}) => {
                    return (
                      <CardSearchItem
                        title={() => (
                          <CustomFastImage
                            resizeMode="contain"
                            width={Metrics.verticalScale(80)}
                            height={Metrics.verticalScale(20)}
                            source={{uri: slide?.logo?.url}}
                            backgroundFallbackColor="transparent"
                          />
                        )}
                        key={`dchs-${ii}`}
                      />
                    );
                  }}
                  initialNumToRender={5}
                  gap={12}
                />
              </View>,
            );
          }
        }
      });
    }
    return pageBlocks;
  };

  return {renderBlockPage};
};
