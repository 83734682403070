/* eslint-disable react-native/no-inline-styles */
import useOutsideClick from '@src/Hooks/useOutsideClick';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import {typographyVariantStyled} from 'design-system/src/Components/Typography/Typography.styles';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {FC, useEffect, useRef, useState} from 'react';
import useViewPort, {
  useGetAbsoluteLeft,
} from 'design-system/src/Hooks/useViewPort';

interface IAppDropDownMenuProps<T extends object = object> {
  title?: string;
  items?: IAppDropDownItem<T>[];
  titleVariant?: keyof typeof typographyVariantStyled;
  style?: React.CSSProperties;
  onClickItem?(item: T): void;
}

export type IAppDropDownItem<T extends object = object> = T & {
  title?: string;
  onPress?(): void;
};

const AppDropDownMenu = <T extends object = object>({
  title,
  items,
  titleVariant = 'h2',
  style,
  onClickItem,
}: IAppDropDownMenuProps<T>) => {
  const {isMobile} = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const dropdownMenuRef = useRef(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(triggerRef, () => setIsOpen(false));
  const {viewportHeight} = useViewPort();
  const {leftPosition} = useGetAbsoluteLeft(triggerRef, [isOpen]);

  const handleItemClick = (element: IAppDropDownItem<T>) => () => {
    if (element.onPress) {
      element.onPress();
    } else {
      onClickItem?.(element);
    }
    setIsOpen(false);
  };

  return (
    <div
      ref={triggerRef}
      className="AppDropDownMenu"
      style={{
        position: 'relative',
        ...style,
      }}>
      <div
        style={{
          display: 'inline-flex',
          gap: 8,
          alignItems: 'center',
          width: 'fit-content',
          cursor: 'pointer',
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => setHover(false)}>
        <Typography
          variant={titleVariant}
          color={hover || isOpen ? 'primary' : undefined}>
          {title}
        </Typography>
        <Icon
          name="caret-down"
          color={hover || isOpen ? '#EA6C13' : undefined}
        />
        {/* <span
          style={{
            fontSize: 12,
            cursor: 'pointer',
            color: hover ? 'primary' : undefined,
          }}>
          {isOpen ? '▲' : '▼'}
          <Icon name='arrow-down' color="white" />
        </span> */}
      </div>
      {isOpen && (
        <div
          ref={dropdownMenuRef}
          style={{
            position: isMobile ? 'fixed' : 'absolute',
            width: '100vw',
            top: isMobile ? 0 : 40,
            left: isMobile ? 0 : leftPosition,
            zIndex: 1000,
            padding: isMobile ? 0 : '0 60px',
            boxSizing: 'border-box',
          }}>
          <div
            style={{
              width: '100%',
              height: isMobile ? viewportHeight : undefined,
              backgroundColor: 'rgba(27, 28, 33, 0.50)',
              backdropFilter: `blur(${isMobile ? 30 : 20}px)`,
              padding: isMobile ? '60px 0' : 40,
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'center' : undefined,
              justifyContent: isMobile ? 'space-between' : undefined,
            }}>
            {isMobile && <Typography variant="htitle">{title}</Typography>}
            {isMobile && (
              <style
                dangerouslySetInnerHTML={{
                  __html: 'body {overflow: hidden;}',
                }}
              />
            )}
            <div
              style={{
                gap: 11,
                display: isMobile ? 'inline-flex' : 'grid',
                flexDirection: isMobile ? 'column' : undefined,
                alignItems: isMobile ? 'center' : undefined,
                gridTemplateRows: 'repeat(5, 1fr)',
                gridTemplateColumns: 'repeat(4, 20%)',
                width: '100%',
                gridAutoFlow: 'column',
              }}>
              {items?.map((item, index) => (
                <PressableCustom
                  key={`menu-${index}`}
                  onPress={handleItemClick(item)}>
                  {({hover}) => (
                    <Typography
                      variant="body2"
                      color={hover ? 'primary' : undefined}>
                      {item?.title}
                    </Typography>
                  )}
                </PressableCustom>
              ))}
            </div>
            {isMobile && (
              <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  height: 64,
                  width: 64,
                  borderRadius: 32,
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}>
                <Icon name="close" size="76" color="black" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppDropDownMenu;
