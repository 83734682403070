import {IPartenerSliderItem} from '@src/Umbraco/Components/PartnerSlider/PartnerSlider';
import {IPartnerSliderFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {RouteTypename} from '~Navigation/type';

export const mapPartnerSliderItems = (
  items: IPartnerSliderFragmentFragment['slider'],
): Array<IPartenerSliderItem> => {
  let list: Array<IPartenerSliderItem> = [];
  if (items) {
    items.forEach(item => {
      if (item && item.__typename === 'Partner' && item.url && item.url !== '#') {
        const {logo, id, url, __typename} = item;
        list.push({
          id,
          url: url || '',
          __typename,
          partnerLogo: logo && logo.url ? logo.url : '',
          accessData: {
            accessType: undefined,
            paywall: undefined,
          },
        });
      }
    });
  }
  return list;
};
