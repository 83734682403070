import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import Recaptcha, {RecaptchaProps} from 'react-recaptcha';

interface IUmbracoRecaptchaField<TField extends FieldValues = FieldValues>
  extends RecaptchaProps {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
  size?: RecaptchaProps['size'];
  theme?: RecaptchaProps['theme'];
}

const UmbracoRecaptchaField = <TField extends FieldValues = FieldValues>(
  props: IUmbracoRecaptchaField<TField>,
) => {
  const {name, rules, control, defaultValue, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  return (
    <Recaptcha
      {...rest}
      verifyCallback={response => {
        field.onChange(response);
      }}
      expiredCallback={() => {
        field.onChange(undefined);
      }}
    />
  );
};

export default UmbracoRecaptchaField;
