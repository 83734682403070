import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import ServiceCheckBoxList, {
  ServiceCheckBoxListProps,
} from './ServiceCheckBoxList';

export interface ServiceCheckBoxListControllerProps<
  TField extends FieldValues = FieldValues,
> extends Omit<ServiceCheckBoxListProps, 'value' | 'onChange'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
  valueMapper?: Record<string, string>;
}
const ServiceCheckBoxListController = <
  TField extends FieldValues = FieldValues,
>(
  props: ServiceCheckBoxListControllerProps<TField>,
) => {
  const {control, defaultValue, name, rules, valueMapper = {}, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  const {field: fieldSecondValue} = useController<TField>({
    control,
    defaultValue,
    name: (name + 'Value') as Path<TField>,
  });
  const handleChange: ServiceCheckBoxListProps['onChange'] = value => {
    field.onChange(value);
    fieldSecondValue.onChange(value.map(v => valueMapper[v] || v));
  };
  return (
    <ServiceCheckBoxList
      value={field.value}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default ServiceCheckBoxListController;
