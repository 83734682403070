import React from 'react';
import {View} from 'react-native';
import Typography from '../../Typography/Typography';
import IcLogoSvg from '../../../assets/svg/Trace.svg';
import Metrics from '../../../Theme/Metrics';

const TitleLogo = ({text}: {text: string}) => {
  return (
    <View style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
      <Typography children={text} variant="ui3" color="gray" />
      <IcLogoSvg style={{marginLeft: Metrics.horizontalScale(10)}}/>
    </View>
  );
};

export default TitleLogo;
