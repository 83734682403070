import {View} from 'react-native';
import React from 'react';
import Metrics from 'design-system/src/Theme/Metrics';
import TabView from 'design-system/src/Components/DataDisplay/TabView/TabView';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ProfileBookmarksContent from './ProfileBookmarksContent/ProfileBookmarksContent';
import {FavorisTabContentProps} from 'design-system/src/Components/FavorisTab/FavorisTabContent';
import ProfileWrapper from '../ProfileWrapper/ProfileWrapper';

const ProfileBookmarksScreen = () => {
  const {t} = useTranslation('profile');

  const favorisData: Array<{
    key: FavorisTabContentProps['type'];
    label: string;
  }> = [
    {
      key: 'watch',
      label: t('Watch'),
    },
    {
      key: 'listen',
      label: t('Listen'),
    },
    {
      key: 'learn',
      label: t('Learn'),
    },
  ];

  return (
    <ProfileWrapper
      title={t('Bookmarks') || ''}
      subtitle="Lorem ipsum dolor sit amet consectetur. Duis ac nunc tortor habitant. Nunc purus sagittis felis maecenas.">
      <View
        style={{
          marginTop: Metrics.horizontalScale(5),
          minHeight: Metrics.verticalScale(812),
        }}>
        <TabView
          activeKey=""
          items={favorisData}
          variant={'custom'}
          customRenderScene={({route}) => {
            if (route.key === 'listen') {
              return <ProfileBookmarksContent source="listen" />;
            } else if (route.key === 'learn') {
              return <ProfileBookmarksContent source="learn" />;
            }
            return <ProfileBookmarksContent />;
          }}
        />
      </View>
    </ProfileWrapper>
  );
};

export default ProfileBookmarksScreen;
