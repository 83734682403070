import React, {Suspense} from 'react';
import {HeadFC, PageProps, graphql} from 'gatsby';
import {PageContextI18n} from '@src/components/general/WrapPageElement/PageContext';
import useContentIdByEndUrl from '@src/Umbraco/Hooks/useContentIdByEndUrl';
import useAppNavigation from '@src/utils/useAppNavigation';
import {
  IMainContentListFragmentFragment,
  useMainContentByIdQuery,
} from '@src/Api/Graphql/gql/types.generated';
import mapMainContent from '~Umbraco/Utils/content/mapMainContent';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageFooter from '@src/components/general/PageFooter/PageFooter';
import useGetFooter from '@src/Hooks/useGetFooter';
import {getMobileOperatingSystem} from '@src/Umbraco/Utils/common';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {useDispatch} from 'react-redux';
import PageHead from '@src/components/general/PageHead/PageHead';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Metrics from 'design-system/src/Theme/Metrics';
import Button from 'design-system/src/Components/Button/Button';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import useIsLoggedIn from '@src/Providers/AuthProvider/useIsLoggedIn';

const SingleHeader = React.lazy(
  () => import('design-system/src/Widget/SingleHeader/SingleHeader.web'),
);
interface ServerData {
  props: {
    image: {
      message: string;
      status: string;
    };
  };
}

const iosAppStoreId = '6445990418';
const iosAppName = 'Trace Plus';
const androidPackage = 'com.trace.plus';
const androidAppName = 'Trace Plus';

const appStoreUrl = `https://apps.apple.com/app/${iosAppStoreId}`; // Replace with your app's App Store URL
const playStoreUrl = `https://play.google.com/store/apps/details?id=${androidPackage}`;

const DeepLinkPage = (
  props: PageProps<any, PageContextI18n, any, ServerData | undefined>,
) => {
  const {params} = props;
  const navigation = useAppNavigation();
  const {isLoggedIn} = useIsLoggedIn();
  const endUrl = params['*']
    ? ('/' + params['*'] + '/').replace('//', '/')
    : '';

  const {t} = useTranslation('translation');
  const {id, loading} = useContentIdByEndUrl({
    variables: {
      endUrl,
    },
  });

  const {data, loading: loadingContent} = useMainContentByIdQuery({
    variables: {
      id: id,
    },
    skip: !id || loading,
  });

  const isLoading = loading || loadingContent;
  const dispatch = useDispatch();

  const footerData = useGetFooter();

  const content = mapMainContent(
    data?.content as IMainContentListFragmentFragment,
    () => 0,
    () => true,
  );

  const title = content?.title;
  const description = content?.subtitle;
  const pageUrl = window.location.href;
  const image = typeof content?.cover !== 'number' ? content?.cover?.uri : '';
  const video = '';
  const appUlr = pageUrl.replace(/^https?/, 'traceplus');

  const osPlatform = getMobileOperatingSystem();

  const isMobileDevice =
    osPlatform === 'HarmonyOS' ||
    osPlatform === 'iOS' ||
    osPlatform === 'Android';

  // Store URLs with deep link parameters
  const getStoreUrlWithDeepLink = () => {
    const encodedDeepLink = encodeURIComponent(appUlr);

    if (osPlatform === 'iOS') {
      // Format: https://apps.apple.com/app/id{APP_ID}?mt=8&url={ENCODED_DEEP_LINK}
      return `${appStoreUrl}?url=${encodedDeepLink}`;
    } else if (osPlatform === 'Android') {
      // Format: https://play.google.com/store/apps/details?id={PACKAGE_NAME}&referrer={ENCODED_DEEP_LINK}
      return `${playStoreUrl}&referrer=${encodedDeepLink}`;
    } else if (osPlatform === 'HarmonyOS') {
      // Format may vary for Huawei AppGallery
      return `${footerData?.huaweiRedirectUrl}${footerData?.huaweiRedirectUrl?.includes('?') ? '&' : '?'}deeplink=${encodedDeepLink}`;
    }
    return '';
  };

  const handleGetApp = () => {
    const tryOpenApp = () => {
      const now = Date.now();
      const timeoutDuration = 2000; // Reduced timeout for better UX

      // Create hidden iframe for iOS to prevent browser switch
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      const cleanupAndRedirect = () => {
        document.body.removeChild(iframe);
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
        window.location.href = getStoreUrlWithDeepLink();
      };

      const handleVisibilityChange = () => {
        if (document.hidden && Date.now() - now < timeoutDuration) {
          // App opened successfully
          clearTimeout(fallbackTimeout);
          document.body.removeChild(iframe);
          document.removeEventListener(
            'visibilitychange',
            handleVisibilityChange,
          );
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Try opening the app
      if (osPlatform === 'iOS') {
        // iOS needs both universal link and custom scheme for best compatibility
        iframe.src = appUlr;
        window.location.href = appUlr;
      } else {
        // Android and HarmonyOS
        window.location.href = appUlr;
      }

      const fallbackTimeout = setTimeout(cleanupAndRedirect, timeoutDuration);
    };

    tryOpenApp();
  };

  const handleContinueToWeb = () => {
    if (content) {
      const route = new RouteFactory(
        {
          id: content.id || '',
          __typename: content.__typename || '',
          url: content.url || '',
        },
        navigation,
        dispatch,
      );

      if (!isLoggedIn) {
        const navigationUrl = route.getNavigationUrl();
        const redirectUrl = navigationUrl ? `/app${navigationUrl}` : '/app';
        
        // Open authentication modal with redirect
        dispatch(
          appAuthSlice.actions.openAuthenticationModal({
            open: true,
            redirect: redirectUrl,
          }),
        );
        return;
      }

      // Web navigation if logged in
      route.navigate();
    }
  };

  return (
    <div>
      {isLoading ? (
        <div style={{padding: 20}}>
          <CustomActivityIndicator />
        </div>
      ) : data?.content ? (
        <Suspense fallback={<div>Loading...</div>}>
          <SingleHeader
            variant="vod"
            text={description || ''}
            image={{
              uri: image,
            }}
            numberOfLines={30}
            title={title || ''}
            buttonTitle={
              isMobileDevice ? t('Get the app') || '' : t('Go to app') || ''
            }
            hideButton={true}
            // isSerie={true}
            size="large"
            extraContent={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 24,
                  padding: 20,
                  alignItems: 'center',
                }}>
                <Button
                  title={t('Continue to mobile app') || ''}
                  variant="contained"
                  onPress={handleGetApp}
                  style={{
                    paddingHorizontal: Metrics.horizontalScale(27),
                  }}
                />
                <Button
                  title={t('Continue to web') || ''}
                  variant="contained"
                  onPress={handleContinueToWeb}
                  style={{
                    paddingHorizontal: Metrics.horizontalScale(27),
                  }}
                />
              </div>
            }
          />
        </Suspense>
      ) : (
        <div style={{padding: 20}}>
          <ContentEmpty title={t('Content not available in your region')} />
        </div>
      )}

      <Suspense>
        <PageFooter {...footerData} />
      </Suspense>
      <h1 style={{display: 'none'}}>{title}</h1>
      <p style={{display: 'none'}}>{description}</p>
    </div>
  );
};

DeepLinkPage.layoutComponent = 'DefaultLayout';

export const query = graphql`
  query DeepLinkPage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    umbraco {
      allLandingPage(first: 1, culture: $language) {
        items {
          id
          __typename
          contentTypeAlias
          ...LandingPageFragment
        }
      }
    }
  }
`;

// export async function getServerData() {
//   const res = await fetch(`https://dog.ceo/api/breeds/image/random`);
//   const data = await res.json();

//   return {
//     props: {
//       image: data,
//     },
//   };
// }

export const Head: HeadFC<
  Queries.DeepLinkPageQuery,
  ServerData | undefined
> = headProps => {
  const {
    data: {
      umbraco: {allLandingPage},
    },
    location,
  } = headProps;
  const title = allLandingPage?.items?.[0]?.metaTitle || '';
  const description = allLandingPage?.items?.[0]?.metaDescription || '';
  const image = allLandingPage?.items?.[0]?.cover?.content?.image?.url || '';
  const link = `trace.plus${location.pathname}`.replace('//', '/');
  const pageUrl = `https://${link}`;

  const video = '';
  const appUlr = pageUrl.replace(/^https?/, 'traceplus');
  const iosAppStoreId = '6445990418';
  const iosAppName = 'Trace Plus';
  const androidPackage = 'com.trace.plus';
  const androidAppName = 'Trace Plus';
  return (
    <PageHead
      title={allLandingPage?.items?.[0]?.metaTitle || undefined}
      description={allLandingPage?.items?.[0]?.metaDescription || undefined}
      {...headProps}>
      <title key="title">{title}</title>
      <meta key="meta-description" name="description" content={description} />
      <meta key="meta-og-title" property="og:title" content={title} />
      <meta
        key="meta-og-description"
        property="og:description"
        content={description}
      />
      <meta key="meta-og-url" property="og:url" content={pageUrl} />
      <meta key="meta-og-image" property="og:image" content={image} />
      <meta key="meta-og-video" property="og:video" content={video} />
      <meta
        key="meta-og-video-secure"
        property="og:video:secure_url"
        content={video}
      />
      <meta
        key="meta-og-video-type"
        property="og:video:type"
        content="video/mp4"
      />
      <meta key="meta-al-ios-url" property="al:ios:url" content={appUlr}></meta>
      <meta
        key="meta-al-ios-store"
        property="al:ios:app_store_id"
        content={iosAppStoreId}></meta>
      <meta
        key="meta-al-ios-name"
        property="al:ios:app_name"
        content={iosAppName}></meta>
      <meta
        key="meta-al-android-url"
        property="al:android:url"
        content={appUlr}></meta>
      <meta
        key="meta-al-android-package"
        property="al:android:package"
        content={androidPackage}></meta>
      <meta
        key="meta-al-android-name"
        property="al:android:app_name"
        content={androidAppName}></meta>
    </PageHead>
  );
};

export default DeepLinkPage;
