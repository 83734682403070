import React, {useState, useEffect} from 'react';
import {View, StyleSheet, Platform, Text} from 'react-native';
import RoundedTextInput from '../TextInput/RoundedTextInput/RoundedTextInput';
import Metrics from '../../Theme/Metrics';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import defaultTheme from '../../Theme/defaultTheme';
import {typographyVariantStyled} from '../Typography/Typography.styles';

export interface MultiInputProps {
  length: number;
  onChange?: (value: string) => void;
  value?: string;
}

const MultiInput = ({length = 6, onChange, value = ''}: MultiInputProps) => {
  const [innerValue, setInnerValue] = useState('');
  const ref = useBlurOnFulfill({value: innerValue, cellCount: length});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: innerValue,
    setValue: setInnerValue,
  });

  useEffect(() => {
    if (value && value !== innerValue) {
      setInnerValue(value);
    }
  }, [value]);

  const handleChange = (text: string) => {
    setInnerValue(text);
    onChange?.(text);
  };

  return (
    <View style={styles.container}>
      <CodeField
        ref={ref}
        {...props}
        // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
        value={value}
        onChangeText={handleChange}
        cellCount={length}
        rootStyle={styles.codeFieldRoot}
        // keyboardType="number-pad"
        textContentType="oneTimeCode"
        autoComplete={
          Platform.select({
            android: 'sms-otp',
            default: 'one-time-code',
          }) as any
        }
        testID="my-code-input"
        renderCell={({index, symbol, isFocused}) => (
          <Text
            key={index}
            style={[styles.cell, isFocused && styles.focusCell]}
            onLayout={getCellOnLayoutHandler(index)}>
            {symbol || (isFocused ? <Cursor /> : null)}
          </Text>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
  },
  input: {
    width: Metrics.horizontalScale(54),
    textAlign: 'center',
  },
  codeFieldRoot: {marginTop: 20, gap: 8},
  cell: {
    width: Metrics.horizontalScale(54),
    height: Metrics.verticalScale(48),
    // lineHeight: 38,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: Metrics.moderateScale(48),
    borderWidth: 2,
    borderColor: '#00000030',
    textAlign: 'center',
    backgroundColor: defaultTheme.textInput.bg,
    color: defaultTheme.primaryTitle,
    fontSize: typographyVariantStyled.body2.fontSize,
    fontFamily: typographyVariantStyled.body2.fontFamily,
  },
  focusCell: {
    borderColor: 'rgba(234, 108, 19, 0.5)',
    backgroundColor: 'transparent',
  },
});

export default MultiInput;
