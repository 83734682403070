import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberNotifyBody} from './interface/memberDataBody.interface';
import {INotifyResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/notify';
export const createMemberNotifyEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberNotifyById: builder.query<INotifyResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberNotify: builder.query<INotifyResponse[], void>({
    query: () => ({
      url: endpoint,
      method: 'GET',
    }),
  }),
  updateMemberNotify: builder.query<
    INotifyResponse,
    {id?: string; data: IMemberNotifyBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
  deleteMemberNotify: builder.query<any, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'DELETE',
    }),
  }),
});
