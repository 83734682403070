import {IAudioSource} from 'design-system/src/Widget/Player/ListenPlayer/ListenPlayer';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

const PlayListContext = createContext<
  | {
      playlistState: PlayListState;
      setPlayList: React.Dispatch<React.SetStateAction<PlayListState>>;
    }
  | undefined
>(undefined);

export interface PlayListState {
  parentId: string;
  playlist: IAudioSource[];
}

const initialState: PlayListState = {
  parentId: '',
  playlist: [],
};

export const usePlayList = () => {
  return useContext(PlayListContext);
};

export const PlayListProvider = (props: PropsWithChildren<any>) => {
  const {children} = props;
  const [playlistState, setPlayList] = useState<PlayListState>(initialState);

  return (
    <PlayListContext.Provider value={{playlistState, setPlayList}}>
      {children}
    </PlayListContext.Provider>
  );
};
