import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {Form, FormField} from '~Api/Umbraco/interface/UmbracoTypes';
import {useObservable} from '@legendapp/state/react';
import {UmbracoFormContext} from './UmbracoFormContext';
import {FieldErrors, FieldValues} from 'react-hook-form';
import {
  Animated,
  ScrollViewProps,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import {useLazyPostFormsQuery} from '~Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import TabView from 'design-system/src/Components/DataDisplay/TabView/TabView.web';
import UmbracoFormPage from './UmbracoFormPage/UmbracoFormPage';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {toast} from 'react-toastify';

type CustomRender = (params: {
  moveNext: () => void;
  movePrev: () => void;
  currentStep: number;
  currentTitle: string;
  totalPage: number;
  isFinalPage?: boolean;
}) => ReactNode;
export interface IUmbracoFormProps {
  form: Form;
  style?: StyleProp<ViewStyle>;
  pageContainerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  pageRootStyle?: StyleProp<ViewStyle>;
  submitLabel?: string;
  onSubmit?: (form: FieldValues) => void | Promise<boolean>;
  onNext?: () => void;
  onPrev?: () => void;
  onSuccess?: () => void;
  renderHeader?: CustomRender;
  renderPageHeader?: CustomRender;
  hideTitle?: boolean;
  customNextLabel?: string;
  overrideSubmit?: (form: FieldValues) => Promise<boolean>;
  hiddenValues?: Record<string, string | boolean | number>;
  additionnalFormData?: Record<string, string | boolean | number>;
  isWithSummary?: boolean;
  onScroll?: ScrollViewProps['onScroll'];
  initialValue?: FieldValues;
  fieldFilter?: (alias: string) => boolean;
  fieldMap?: (field: FormField) => FormField;
  fixedSubmit?: boolean;
  autoValidate?: boolean;
  summarySubtitle?: string;
  disableSubmitTracking?: boolean;
}

const UmbracoForm = (props: IUmbracoFormProps) => {
  const {
    form,
    style,
    onSubmit,
    renderHeader,
    onSuccess,
    overrideSubmit,
    renderPageHeader,
    isWithSummary,
    onScroll,
    pageContainerStyle,
    contentContainerStyle,
    additionnalFormData,
    initialValue,
    fieldFilter,
    fieldMap,
    submitLabel: _submitLabel,
    fixedSubmit,
    autoValidate,
    onNext,
    onPrev,
    summarySubtitle,
    pageRootStyle,
    hideTitle,
    disableSubmitTracking,
  } = props;
  const {pages} = form;

  const {t} = useTranslation(['service']);
  const totalPage = (isWithSummary ? pages.length + 1 : pages.length) || 0;
  const [currentPage, setCurrentPage] = useState(0);
  const [postForm] = useLazyPostFormsQuery();
  const formErrors = useObservable<FieldErrors<FieldValues>>({});
  const mergedForm = useObservable<FieldValues>(initialValue);
  // const currentPage = useObservable<number>(0);
  // const totalPage = useObservable<number>(pages.length || 0);

  const currentTitle = useMemo(() => {
    if (!isWithSummary) {
      return pages[currentPage]?.caption ?? '-';
    }
    if (isWithSummary && currentPage < totalPage - 1) {
      return pages[currentPage]?.caption ?? '-';
    } else {
      return summarySubtitle || t('summaryTitle');
    }
  }, [currentPage, isWithSummary, pages, t, totalPage, summarySubtitle]);

  const {submitLabel: formSubmitLabel, nextLabel} = form;
  const submitLabel = _submitLabel || formSubmitLabel;

  useEffect(() => {
    return () => {
      formErrors.set({});
    };
  }, [formErrors]);

  const handleFormSumbit = async (): Promise<boolean> => {
    const formData = {
      ...mergedForm.get(),
      ...(additionnalFormData ? additionnalFormData : {}),
    };

    if (overrideSubmit) {
      const response = await overrideSubmit(formData);
      if (response) {
        onSuccess && onSuccess();
        formErrors.set({});
        return true;
      }
    } else {
      // map formData to match the new umbraco api format
      const formDataApi = Object.keys(formData).reduce(
        (acc, key) => {
          if (formData[key + 'Value']) {
            acc[key] = formData[key + 'Value'];
          }
          return acc;
        },
        {...formData} as Record<string, string | boolean | number>,
      );

      const response = await postForm({
        id: form._id,
        body: formDataApi,
      });
      if (onSubmit) {
        const res = await onSubmit(formData);
        if (res !== true) {
          return false;
        }
      }
      if (response.error) {
        toast.error(t('Error on submit'));
      } else {
        if (response) {
          onSuccess && onSuccess();
          formErrors.set({});
          return true;
        }
      }
    }

    return false;
  };

  const moveNext = async () => {
    if (currentPage === totalPage - 1) {
      const res = await handleFormSumbit();
      if (res) {
        formErrors.set({});
      }
      return res;
    } else {
      const newPage = Math.min(currentPage + 1, totalPage - 1);
      if (currentPage !== newPage) {
        setCurrentPage(Math.min(currentPage + 1, totalPage - 1));
        onNext && onNext();
      }
      return true;
    }
  };
  const movePrev = () => {
    const newPage = Math.max(currentPage - 1, 0);
    if (currentPage !== newPage) {
      setCurrentPage(Math.max(currentPage - 1, 0));
      onPrev && onPrev();
    }
  };

  return (
    <View
      style={[
        {
          flex: 1,
        },
        style,
      ]}>
      <UmbracoFormContext.Provider
        value={{
          // totalPage: totalPage,
          // currentPage: currentPage,
          mergedForm,
          errors: formErrors,
          fieldFilter,
          fieldMap,
          translationContext: form.name,
        }}>
        {renderHeader &&
          renderHeader({
            moveNext,
            movePrev,
            currentStep: currentPage,
            currentTitle: currentTitle,
            totalPage,
            isFinalPage: currentPage === totalPage - 1,
          })}
        {(isWithSummary && currentPage !== totalPage - 1) || !isWithSummary ? (
          <TabView
            activeKey={'0'}
            swipeEnabled={false}
            noHeader={true}
            currentIndex={currentPage}
            items={pages.map((page: any, index: number) => ({
              key: index + '',
              label: index + '',
              ...page,
            }))}
            customRenderScene={() => {
              const list = pages.map((page: any) => {
                const isLast = currentPage === totalPage - 1;

                return (
                  <UmbracoFormPage
                    style={pageRootStyle}
                    contentContainerStyle={contentContainerStyle}
                    onScroll={onScroll}
                    pageContainerStyle={pageContainerStyle}
                    disableSubmitTracking={disableSubmitTracking}
                    header={
                      <>
                        {renderPageHeader &&
                          renderPageHeader({
                            moveNext,
                            movePrev,
                            currentStep: currentPage,
                            currentTitle: currentTitle,
                            totalPage,
                            isFinalPage: isLast,
                          })}
                      </>
                    }
                    page={page}
                    currentPage={currentPage}
                    isLast={isLast}
                    submitLabel={submitLabel}
                    nextLabel={nextLabel}
                    moveNext={moveNext}
                    movePrev={movePrev}
                    fixedSubmit={fixedSubmit}
                    autoValidate={autoValidate}
                    hideTitle={hideTitle}
                  />
                );
              });
              return list[currentPage];
            }}
          />
        ) : undefined}

        {isWithSummary && currentPage === totalPage - 1 && (
          <Animated.ScrollView
            showsVerticalScrollIndicator={false}
            onScroll={onScroll}>
            {renderPageHeader &&
              renderPageHeader({
                moveNext,
                movePrev,
                currentStep: currentPage,
                currentTitle: currentTitle,
                totalPage,
                isFinalPage: currentPage === totalPage - 1,
              })}
            <div>Thankyou page</div>
          </Animated.ScrollView>
        )}
      </UmbracoFormContext.Provider>
    </View>
  );
};

export default React.memo(UmbracoForm);
