import React from 'react';
import {Image as RNImage, ImageProps} from 'react-native';

export interface IImageProps extends ImageProps {}

const Image = (props: IImageProps) => {
  return <RNImage {...props} />;
};

export default Image;
