import DefaultLayout from '@src/layout/DefaultLayout/DefaultLayout';
import ProfileWrapper from '@src/Navigation/Profile/ProfileWrapper/ProfileWrapper';
import HtmlRender from '@src/Umbraco/Components/Components/HtmlRender/HtmlRender';
import Metrics from 'design-system/src/Theme/Metrics';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import {View} from 'react-native';

const TextPage = (props: PageProps) => {
  const {params: {id} = {}, data} = props;

  const {body, title} = (data as any).umbraco
    .textPage as Queries.Umbraco_TextPage;

  return (
    <ProfileWrapper title={title} withSeparator={true}>
      <View style={{marginTop: Metrics.horizontalScale(42)}}>
        {body && <HtmlRender html={body} variant={'body2'} color="#A0A0A0" />}
      </View>
    </ProfileWrapper>
  );
};

TextPage.isModal = true;

export default TextPage;

export const query = graphql`
  query ($language: String!, $id: ID!, $lang: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    umbraco {
      textPage(id: $id, culture: $lang) {
        id
        title
        body
        __typename
      }
    }
  }
`;
