/* eslint-disable react-native/no-inline-styles */
import {View} from 'react-native';
import React from 'react';
import Metrics from 'design-system/src/Theme/Metrics';
import Typography from 'design-system/src/Components/Typography/Typography';
import {IUcLineBreakFragmentFragment} from '~Api/Graphql/gql/types.generated';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface IUcLineBreakProps {
  content: IUcLineBreakFragmentFragment;
}

const UcLineBreak = (props: IUcLineBreakProps) => {
  const {
    content: {text, lineBreakBody},
  } = props;
  const {isMobile} = useBreakpoint();
  return (
    <View
      style={{
        marginTop: Metrics.verticalScale(24),
        marginBottom: Metrics.verticalScale(23),
      }}>
      <View
        style={{
          position: 'relative',
          height: Metrics.verticalScale(18),
          justifyContent: 'center',
        }}>
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
          <Typography
            variant="h3"
            style={{
              width: Metrics.horizontalScale(22),
              textAlign: 'center',
              backgroundColor: '#111216',
            }}>
            {text}
          </Typography>
        </View>
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: 'rgba(151, 151, 151, 0.3)',
          }}
        />
      </View>
      <View style={{marginTop: Metrics.verticalScale(28)}}>
        <Typography
          variant="body2"
          style={{
            textAlign: isMobile ? 'left' : 'center',
          }}>
          {lineBreakBody}
        </Typography>
      </View>
    </View>
  );
};

export default UcLineBreak;
