import {SingleContentLoader} from 'design-system/src/Components/ContentLoader';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import React, {ReactNode, Suspense, useEffect, useMemo, useState} from 'react';
import {View} from 'react-native';
import {
  IPodcastFragmentFragment,
  usePodCastByIdLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import PaywallChecker from '~Umbraco/Components/PaywallChecker/PaywallChecker';
import usePodCastSinglePageUtils from '~Umbraco/Hooks/usePodCastSinglePageUtils';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import {RouteSource} from '@src/Navigation/type';
import useParams from '@src/Hooks/useParams';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import Metrics from 'design-system/src/Theme/Metrics';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';

const CategorySlider = React.lazy(
  () => import('~Umbraco/Components/CategorySlider/CategorySlider'),
);

const PodCastSinglePage = () => {
  const params = useParams();

  const tracker = useTracker();

  const {id, itemsSlider, title, template, category} = params || {};
  const {t} = useTranslation('translation');
  const [currentId, setCurrentId] = useState(id || '');
  const [fetchPodcast, response] = usePodCastByIdLazyQuery();
  const loading = response.loading;

  const {
    renderPodCastSinglePage: renderPage,
    isOpenDialogShare,
    setIsOpenDialogShare,
    linkToShare,
  } = usePodCastSinglePageUtils();

  const isMobile = useBreakpoint();

  useEffect(() => {
    const _id = (currentId || id) as string;

    if (_id) {
      fetchPodcast({
        variables: {
          id: _id,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });
    }
  }, [currentId]);

  const {
    pageContent,
    podcastData,
  }: {pageContent: ReactNode[]; podcastData?: IPodcastFragmentFragment} =
    useMemo(() => {
      const {data} = response;
      if (
        !response.loading &&
        data &&
        data.podcast &&
        data.podcast.__typename === 'Podcast'
      ) {
        let pageBlocks = renderPage(data.podcast);
        return {
          pageContent: pageBlocks,
          podcastData: data.podcast,
        };
      }
      return {
        pageContent: [],
      };
    }, [response.data]);

  function calculateWidth() {
    var viewportWidth = window.innerWidth; // Get the viewport width in pixels
    var result = viewportWidth - 0.2 * viewportWidth; // Subtract 20% of the viewport width
    return result; // Return the result in pixels
  }

  useEffect(() => {
    if (podcastData) {
      tracker.general.contentDetailPageViewed({
        content_id: podcastData.id,
        content_title: podcastData.title || '',
        content_type: podcastData.__typename,
        genre: podcastData.parent?.name || '',
        source: (params?.source as RouteSource) || 'browse',
      });
    }
  }, [podcastData, params?.source, tracker.general]);
  return (
    <div>
      <PaywallChecker
        accessType={podcastData?.accessType}
        paywall={podcastData?.paywall}>
        {!loading ? (
          <>
            {pageContent && pageContent.length > 0 ? (
              <>
                <View>{pageContent}</View>
                {Boolean(itemsSlider) && (
                  <View
                    style={{
                      marginTop: isMobile ? 26 : 56,
                      width: isMobile ? '100%' : calculateWidth(),
                    }}>
                    <View
                      style={{
                        marginBottom: Metrics.horizontalScale(24),
                      }}>
                      <Suspense>
                        <CategorySlider
                          key={'blockCategory'}
                          data={[itemsSlider]}
                          title={title || category.name || ''}
                          size={'small'}
                          onItemPress={item => {
                            setCurrentId(item.id);
                          }}
                        />
                      </Suspense>
                    </View>
                  </View>
                )}
              </>
            ) : (
              <ContentEmpty
                title={t('Error')}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                }}
              />
            )}
          </>
        ) : (
          <SingleContentLoader />
        )}
      </PaywallChecker>
      <ShareDialog
        key="share-dialog"
        isOpen={isOpenDialogShare}
        onClose={() => setIsOpenDialogShare(false)}
        link={linkToShare}
      />
      ,
    </div>
  );
};

export default PodCastSinglePage;
