import React, {ReactNode, useMemo} from 'react';
import {StyleSheet} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {IconName} from '../../../../assets/Icones';
import Icon from '../../../Icon/Icon';

import {
  gradientBorderStyles,
  gradientIconCardSize,
  gradientIconCardStyles,
  gradientIconCardVariantStyles,
} from './GradientIconCard.styles';
import Metrics from '../../../../Theme/Metrics';
import defaultTheme from '../../../../Theme/defaultTheme';

export type GradientIconCardProps = {
  variant?: keyof typeof gradientIconCardVariantStyles;
  icon: IconName | (() => ReactNode);
  size?: keyof typeof gradientIconCardSize;
};

const GradientIconCard = (props: GradientIconCardProps) => {
  const {variant = 'default', icon, size = 'medium'} = props;
  const variantStyle = gradientIconCardVariantStyles[variant];
  const sizeStyle = gradientIconCardSize[size];
  const borderStyle = gradientBorderStyles[variant];
  const restProps = useMemo(() => {
    return icon === 'more'
      ? {
          size: Metrics.horizontalScale(40),
          color: defaultTheme.primary,
          autoResize: true,
        }
      : {};
  }, [icon]);
  return (
    <LinearGradient
      style={StyleSheet.flatten([
        gradientIconCardStyles.container,
        sizeStyle,
        borderStyle,
      ])}
      colors={[variantStyle.firstColor, variantStyle.secondColor]}
      locations={[variantStyle.firstPercentage, variantStyle.secondPercentage]}
      useAngle={true}
      angle={variantStyle.deg}
      angleCenter={{x: 0.5, y: 0.5}}>
      {typeof icon === 'function' ? (
        icon()
      ) : (
        <Icon name={icon} {...restProps} />
      )}
    </LinearGradient>
  );
};

export default GradientIconCard;
