import React, {useMemo} from 'react';
import {TouchableOpacity, StyleSheet, View} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import Tags from '../../../Tags/Tags';
import GenericCard from '../../GenericCard/GenericCard';
import RowCard from '../../RowCard/RowCard.web';
import LearnCardAside from '../LearnCardAside/LearnCardAside';
import {LearnCardProps} from '../LearnCardProps';
import LinearGradient from 'react-native-linear-gradient';
import defaultTheme from '../../../../Theme/defaultTheme';
import IconWithText from '../../../IconWithText/IconWithText';
import {learnSingleCardStyles} from '../LearnSingleCard/LearnSingleCard.styles';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';
import {PlayButtonSvg} from '../../../../assets/ImageSvg';
import Icon from '../../../Icon/Icon';
import {sharedCardStyles} from '../../SharedCard/SharedCard.styles';

const LearnRowCard = (props: LearnCardProps & {onPress?(): void}) => {
  const {
    tag,
    isWithProgressBar,
    progress,
    onPress,
    isDone,
    textDone,
    infoColor,
    showPlay,
    flexDirection,
  } = props;
  const {isMobile} = useBreakpoint();
  const aspectRatio = isMobile ? 109 / 124 : 248 / 280;
  const gradientColor: (string | number)[] = useMemo(() => {
    return isDone
      ? ['rgba(234, 108, 19, 0.8)', 'rgba(84, 105, 251, 0.8)']
      : ['rgba(24, 45, 189, 0.8)', 'rgba(84, 105, 251, 0.8)'];
  }, [isDone]);

  return (
    <TouchableOpacity onPress={onPress} testID="learn-row-card">
      <RowCard
        flexDirection={flexDirection}
        left={
          <View style={{width: '100%', position: 'relative', flex: 1}}>
            <GenericCard
              onPress={onPress}
              fullWidth={true}
              aspectRatio={aspectRatio}
              coverImage={props.cover}
              contentStyle={{
                paddingBottom: isWithProgressBar
                  ? Metrics.verticalScale(4)
                  : undefined,
              }}
              center={
                showPlay && (
                  <View
                    style={[
                      StyleSheet.absoluteFill,
                      {justifyContent: 'center', alignItems: 'center'},
                    ]}>
                    <PlayButtonSvg />
                  </View>
                )
              }
              footer={
                showPlay ? (
                  progress !== 1 ? (
                    <CustomProgressBar progress={progress} />
                  ) : (
                    <Icon
                      name="check-watch"
                      style={sharedCardStyles.checkWatch}
                    />
                  )
                ) : (
                  progress !== 0 &&
                  !isDone && <CustomProgressBar progress={props.progress} />
                )
              }
            />
            {isDone && (
              <>
                <LinearGradient
                  style={StyleSheet.flatten([
                    {
                      width: '100%',
                      aspectRatio,
                      position: 'absolute',
                    },
                    learnSingleCardStyles.radius,
                  ])}
                  useAngle
                  angle={360}
                  locations={[0, 1]}
                  colors={gradientColor}
                />
                <IconWithText
                  style={{
                    position: 'absolute',
                    bottom: Metrics.horizontalScale(13),
                    left: Metrics.horizontalScale(9),
                  }}
                  text={textDone || 'Done!'}
                  icon="checkfilled"
                  size="16"
                  textColor={infoColor || defaultTheme.white}
                />
              </>
            )}
            {tag && (
              <Tags
                style={{
                  position: 'absolute',
                  top: Metrics.verticalScale(8),
                  left: Metrics.horizontalScale(9),
                }}
                {...tag}
              />
            )}
          </View>
        }
        right={
          <LearnCardAside
            {...props}
            asideExpanded={true}
            isTrailingIconCentered={false}
          />
        }
        rightFlex={isMobile ? 1 : undefined}
      />
    </TouchableOpacity>
  );
};

export default LearnRowCard;
