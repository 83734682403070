import CustomModal from '@src/components/general/CustomModal/CustomModal';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import Icon from 'design-system/src/Components/Icon/Icon';
import React, {FC} from 'react';
import {Pressable} from 'react-native';
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions';

const ModalTermAndCondition: FC = () => {
  const {openTermsAndConditionModal} = useAppSelector(state => state.appAuth);
  const dispatch = useAppDispatch();

  return (
    <CustomModal
      isOpen={!!openTermsAndConditionModal}
      onClose={() => {
        dispatch(appAuthSlice.actions.setOpenTermsAndConditionModal(false));
      }}
      zIndex={300}
      header={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            padding: 20,
            width: '100%',
          }}>
          <Pressable
            onPress={() => {
              dispatch(
                appAuthSlice.actions.setOpenTermsAndConditionModal(false),
              );
            }}>
            <Icon name="close" size="76" />
          </Pressable>
        </div>
      }>
      <div
        style={{
          backgroundColor: '#111216',
          width: '100%',
          height: '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          position: 'relative',
        }}>
        <div>
          <TermsAndConditions />
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalTermAndCondition;
