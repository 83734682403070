import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import {radioButtonStyles} from 'design-system/src/Components/RadioButton/RadioButton';
import {RadioButtonItem} from 'design-system/src/Components/RadioButton/RadioButtonItem/RadioButtonItem';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import {IconName} from 'design-system/src/assets/Icones';
import React, {useCallback, useMemo, useState} from 'react';
import {Pressable, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import ContentLoader, {Rect} from 'react-content-loader/native';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface ITapToSelectProps {
  g: RadioButtonItem;
  answer?: RadioButtonItem;
  onChange?: (value: string[]) => void;
  onChangeAnswer?: (value: string[], newValue?: string, id?: string) => void;
  showResult?: boolean;
  isAnswerSelected?: boolean;
  index: number;
  showAnswerOption?: boolean;
  showOption?: boolean;
}

interface IGapItem {
  borderColor: string;
  backgroundColor: string;
  icon: IconName;
  iconColor: string;
}

interface IGapRadioButtonItemProps {
  g: RadioButtonItem;
  handleChecked: (data: RadioButtonItem) => void;
  itemInfo: IGapItem;
  isSelected?: boolean;
  itemStyle: StyleProp<ViewStyle>;
  index: number;
  showOption?: boolean;
}

const GapRadioButtonItem = (props: IGapRadioButtonItemProps) => {
  const {g, handleChecked, itemInfo, isSelected, itemStyle, index, showOption} =
    props;
  const [imageLoading, setImageLoading] = useState<boolean>();
  const {t} = useTranslation(['learn']);

  const {
    title,
    desc,
  }: {
    title: string;
    desc?: string;
  } = useMemo(() => {
    if (g.label.length >= 20 || showOption) {
      return {
        title: (t('Option') + ` ${index + 1}`) as string,
        desc: g.label,
      };
    }
    return {
      title: g.label,
      desc: undefined,
    };
  }, [g, t, showOption]);

  return (
    <Pressable
      onPress={() => {
        if (!imageLoading) {
          handleChecked(g);
        }
      }}
      style={StyleSheet.flatten([
        {
          borderWidth: g.image ? undefined : 1,
          borderRadius: Metrics.horizontalScale(4),
          marginBottom: Metrics.verticalScale(16),
          borderColor: isSelected ? '#EA6C13' : itemInfo.borderColor,
          backgroundColor: !g.image ? itemInfo.backgroundColor : 'transparent',
        },
        g.image
          ? {
              width: Metrics.horizontalScale(160),
              minHeight: Metrics.horizontalScale(160),
            }
          : itemStyle,
      ])}>
      {!!g.image && (
        <CustomFastImage
          style={StyleSheet.flatten([
            radioButtonStyles.image,
            radioButtonStyles.content,
            {
              borderWidth: imageLoading ? undefined : 1,
              borderRadius: Metrics.horizontalScale(4),
              borderColor: itemInfo.borderColor,
            },
          ])}
          source={{uri: g.image}}
          onLoadStart={() => {
            setImageLoading(true);
          }}
          onLoadEnd={() => {
            setImageLoading(false);
          }}
          hideLoading
        />
      )}
      <View
        style={{
          paddingHorizontal: Metrics.horizontalScale(16),
          paddingVertical: Metrics.verticalScale(12),
        }}>
        <View style={{flexDirection: 'row'}}>
          <View
            style={{
              borderWidth: 1,
              borderColor: isSelected ? '#EA6C13' : itemInfo.borderColor,
              width: Metrics.horizontalScale(24),
              height: Metrics.horizontalScale(24),
              borderRadius: Metrics.horizontalScale(12),
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              color={() => itemInfo.borderColor}
              children={g.index}
              variant="body1"
            />
          </View>
          {/* <Icon name={itemInfo.icon} color={itemInfo.iconColor} /> */}
          {!g.image && (
            <Typography
              style={{marginLeft: Metrics.horizontalScale(10), flexShrink: 1}}
              variant="body2">
              {title}
            </Typography>
          )}
        </View>
        {!g.image && !!desc && (
          <Typography
            variant="ui3"
            color="gray"
            style={{
              marginTop: Metrics.verticalScale(10),
            }}>
            {desc}
          </Typography>
        )}
      </View>
      {imageLoading && (
        <ContentLoader
          height={radioButtonStyles.content.height}
          width={radioButtonStyles.content.width}
          style={{
            borderWidth: 1,
            borderColor: '#111216',
            position: 'absolute',
          }}
          speed={1}
          backgroundColor={'rgba(23, 24, 29, 0.7)'}
          foregroundColor={'#111216'}>
          <Rect
            x="0"
            y="0"
            rx="5"
            ry="5"
            height={radioButtonStyles.content.width}
            width={radioButtonStyles.content.height}
          />
        </ContentLoader>
      )}
    </Pressable>
  );
};
const TapToSelect = (props: ITapToSelectProps) => {
  const {
    g,
    onChange,
    showResult,
    answer,
    onChangeAnswer,
    isAnswerSelected,
    index,
    showOption,
    showAnswerOption,
  } = props;
  const handleChecked = useCallback(
    (data: RadioButtonItem) => {
      onChange && onChange([data.value]);
    },
    [onChange],
  );

  const handleSelectAnswer = useCallback(
    (data: RadioButtonItem) => {
      onChangeAnswer && onChangeAnswer([data.value], undefined, data.id);
    },
    [onChangeAnswer],
  );

  const infoBuilder = useCallback(
    (item: RadioButtonItem) => {
      const info: IGapItem = {
        borderColor: '#2F3139',
        backgroundColor: '#16181E',
        icon: 'check-empty',
        iconColor: '#737373',
      };
      if (item.isAnswered) {
        if (showResult) {
          if (item.isCorrect) {
            info.borderColor = !item.select
              ? 'rgba(5, 201, 107, 0.8)'
              : '#EA6C13';
            info.backgroundColor = 'rgba(5, 201, 107, 0.2)';
            info.icon = 'check-circle';
            info.iconColor = 'rgba(5, 201, 107, 0.8)';
          } else {
            info.borderColor = !item.select ? '#BE030A' : '#EA6C13';
            info.backgroundColor = 'rgba(255, 30, 30, 0.13)';
            info.icon = 'invalide';
          }
        } else {
          info.borderColor = !item.select ? '#EA6C13' : 'white';
          info.backgroundColor = 'rgba(234, 108, 19, 0.13)';
          info.icon = 'check-circle';
          info.iconColor = '#EA6C13';
        }
      } else if (item.select) {
        info.borderColor = '#EA6C13';
        info.backgroundColor = 'rgba(234, 108, 19, 0.13)';
        info.iconColor = '#EA6C13';
        info.icon = 'check-empty';
      }
      return info;
    },
    [showResult],
  );

  const {
    itemInfo,
    answerInfo,
  }: {
    itemInfo: IGapItem;
    answerInfo: IGapItem | undefined;
  } = useMemo(() => {
    return {
      itemInfo: infoBuilder(g),
      answerInfo: answer ? infoBuilder(answer) : undefined,
    };
  }, [g, g.select, showResult, infoBuilder, answer]);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <GapRadioButtonItem
        showOption={showOption}
        index={index}
        g={g}
        handleChecked={handleChecked}
        itemInfo={itemInfo}
        itemStyle={{
          marginRight: Metrics.horizontalScale(4),
          flex: 1,
        }}
      />
      {answer && answerInfo && (
        <GapRadioButtonItem
          showOption={showAnswerOption}
          index={index}
          g={answer}
          handleChecked={handleSelectAnswer}
          itemInfo={answerInfo}
          itemStyle={{
            marginLeft: Metrics.horizontalScale(4),
            flex: 1,
          }}
          isSelected={isAnswerSelected}
        />
      )}
    </View>
  );
};

export default TapToSelect;
