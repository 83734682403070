import React, {useEffect, useMemo} from 'react';
import UnitResult, {UnitItem} from './components/UnitResult';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import {View} from 'react-native';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import HtmlRender from '@src/Umbraco/Components/HtmlRender/HtmlRender';
import {IExamResult, IExamResultItem} from '@src/Api/TraceApi/interface/exam';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

export interface IExamResultProps {
  data: IExamResult;
  positiveResult?: {
    title: string;
    body: string;
  };
  negativeResult?: {
    title: string;
    body: string;
  };
  gradingPolicy?: number;
  delay?: number;
  onCourseFinished?: () => void;
}
type IUnitResultData = Pick<
  IExamResultItem,
  'correct' | 'total' | 'title' | 'unitTitle'
>;
export const mapUnitResult = (
  items: Array<IUnitResultData>,
): Array<UnitItem> => {
  return items.map(e => ({
    score: e.correct,
    total: e.total,
    name: e.title || '',
  }));
};

const ExamResult = (props: IExamResultProps) => {
  const {
    data,
    positiveResult,
    negativeResult,
    gradingPolicy = 0,
    delay,
    onCourseFinished,
  } = props;
  const {result: percentage, stat} = data;
  const mapData = mapUnitResult(stat);
  const {t} = useTranslation(['learn']);
  const result =
    Math.round(percentage) < gradingPolicy ? negativeResult : positiveResult;
  const isPositiveResult: boolean = useMemo(() => {
    return Math.round(percentage) > gradingPolicy;
  }, [percentage, gradingPolicy]);

  const {isMobile} = useBreakpoint();

  useEffect(() => {
    if (isPositiveResult) {
      onCourseFinished && onCourseFinished();
    }
  }, [isPositiveResult]);

  const examResult = useMemo(() => {
    if (result) {
      return (
        <HtmlRender
          html={result.body || ''}
          variant="body3"
          textAlign={'center'}
          style={{
            marginTop: Metrics.verticalScale(13),
            paddingHorizontal: Metrics.verticalScale(10),
          }}
        />
      );
    }
    return undefined;
  }, [result]);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        marginBottom: Metrics.verticalScale(20),
      }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: isMobile ? 'flex-start' : 'center',
          flex: 1,
        }}>
        <Typography variant="big" color="orange">
          {percentage.toFixed()}
        </Typography>
        <Typography color="orange">%</Typography>
      </View>
      <View
        style={{
          flex: 1,
          alignItems: 'flex-start',
        }}>
        <View
          style={{
            width: isMobile ? '100%' : Metrics.horizontalScale(417),
          }}>
          <Typography
            style={{marginTop: Metrics.verticalScale(21)}}
            variant="h2">
            {result && result.title}
          </Typography>
          {examResult}
          <View style={{marginTop: Metrics.verticalScale(43)}}>
            {mapData.map((d, index) => (
              <UnitResult
                score={d.score}
                total={d.total}
                name={d.name}
                hasMarg={index === 0}
                key={`res3-${index}`}
              />
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

export default ExamResult;
