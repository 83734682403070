import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {deleteObjectKey} from '@src/tools/utils/objectExtension';
import {useAppSelector} from '@src/Store/hooks';
import {IGapfillNuggetProps} from '@src/App/components/pages/LearnHomePage/UnitScreen/UnitPages/CourseNugget/GapfillNugget/GapfillNugget';
import {IChoiceNuggetProps} from '@src/App/components/pages/LearnHomePage/UnitScreen/UnitPages/CourseNugget/ChoiceNugget/ChoiceNugget';

export type NuggetDataProgress =
  | IChoiceNuggetProps['value']
  | IGapfillNuggetProps['value'];
export type UnitProgressionData = {
  [_nuggetsId: string]: NuggetDataProgress;
};
interface CourseUnitState {
  unitProgression?: {
    [_unitId: string]: UnitProgressionData;
  };
  courseTestProgression?: {
    [_testId: string]: UnitProgressionData;
  };
  examInfo: {
    [_testId: string]: {
      attempt: number;
      lastAttempt: string;
    };
  };
}

const initialState = {
  unitProgression: {},
  courseTestProgression: {},
  examInfo: {},
} as CourseUnitState;

const courseUnitSlice = createSlice({
  name: 'courseUnit',
  initialState,
  reducers: {
    saveExamInfo(
      state,
      action: PayloadAction<{
        examId: string;
        lastAttempt: string;
        attempt: number;
      }>,
    ) {
      const {examId, lastAttempt, attempt} = action.payload;
      if (!state.examInfo) {
        state.examInfo = {};
      }
      state.examInfo[examId] = {
        lastAttempt,
        attempt,
      };
      return state;
    },
    removeExamInfo(
      state,
      action: PayloadAction<{
        examId: string;
      }>,
    ) {
      const {examId} = action.payload;
      if (state.examInfo) {
        let obj = deleteObjectKey(state.examInfo, [examId]);
        state.examInfo = obj;
      }
      return state;
    },
    saveUnitNuggetAnswer(
      state,
      action: PayloadAction<{
        unitId: string;
        nuggetId?: string;
        data?: NuggetDataProgress;
      }>,
    ) {
      const {unitId, nuggetId, data} = action.payload;
      if (!state.unitProgression) {
        state.unitProgression = {};
      }
      if (!state.unitProgression[unitId]) {
        state.unitProgression[unitId] = {};
      }
      if (nuggetId && data) state.unitProgression[unitId][nuggetId] = data;
      return state;
    },
    setUnitAnswer(
      state,
      action: PayloadAction<{
        unitId: string;
        data: UnitProgressionData;
      }>,
    ) {
      const {unitId, data} = action.payload;
      if (!state.unitProgression) {
        state.unitProgression = {};
      }
      state.unitProgression[unitId] = data;
      return state;
    },
    setTestAnswer(
      state,
      action: PayloadAction<{
        testId: string;
        data: UnitProgressionData;
      }>,
    ) {
      const {testId, data} = action.payload;
      if(!state.courseTestProgression) {
        state.courseTestProgression = {}
      }
      state.courseTestProgression[testId] = data;
      return state;
    },
    saveCourseTestNuggetAnswer(
      state,
      action: PayloadAction<{
        testId: string;
        nuggetId: string;
        data: NuggetDataProgress;
      }>,
    ) {
      const {testId, nuggetId, data} = action.payload;
      if (!state.courseTestProgression) {
        state.courseTestProgression = {};
      }
      if (!state.courseTestProgression[testId]) {
        state.courseTestProgression[testId] = {};
      }
      state.courseTestProgression[testId][nuggetId] = data;
      return state;
    },
    resetCourseTestNuggetAnswer(
      state,
      action: PayloadAction<{
        testId: string;
      }>,
    ) {
      const {testId} = action.payload;
      if (!state.courseTestProgression) {
        state.courseTestProgression = {};
      }
      state.courseTestProgression[testId] = {};
      return state;
    },
    resetState(state) {
      state = {...initialState};
      return state;
    },
  },
});

export const useSelectExamInfo = (examId: string) => {
  return useAppSelector(state => (state.courseUnit.examInfo || {})[examId]);
};

export default courseUnitSlice;
