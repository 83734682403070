import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import TracePlay from '../../assets/svg/logo/trace-play.svg';
import Metrics from '../../Theme/Metrics';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';

export type ContentEmptyProps = {
  title: string;
  content?: string;
  buttonTitle?: string;
  onPress?: () => void;
  hideButton?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function ContentEmpty(props: ContentEmptyProps) {
  const {title, content, buttonTitle, style, onPress, hideButton} = props;

  return (
    <View
      style={[
        {
          alignItems: 'center',
        },
        style,
      ]}>
      <TracePlay style={{marginTop: Metrics.verticalScale(91 - 16)}} />
      <Typography
        style={{
          marginTop: Metrics.verticalScale(54),
          textAlign: 'center',
        }}
        variant="h3">
        {title}
      </Typography>
      {content && (
        <Typography
          style={{
            marginTop: Metrics.verticalScale(14),
            textAlign: 'center',
            maxWidth: Metrics.horizontalScale(258),
          }}
          variant="body3"
          color="gray">
          {content}
        </Typography>
      )}
      {buttonTitle && !hideButton && (
        <Button
          onPress={onPress}
          title={buttonTitle}
          style={{
            marginTop: Metrics.verticalScale(24),
          }}
          variant="outlined"
          size="xsmall"
        />
      )}
    </View>
  );
}
