import useNavigation from '@src/Navigation/useNavigation';
import Metrics from 'design-system/src/Theme/Metrics';
import {useEffect, useRef, useState} from 'react';
import {Animated} from 'react-native';
import {useSharedValue} from 'react-native-reanimated';

const useScrollAnimation = (props?: {
  rangeEnd?: number;
  rangeStart?: number;
}) => {
  const {rangeEnd = 480} = props || {};
  const navigation = useNavigation();
  const AnimatedHeaderValue = useRef(new Animated.Value(0)).current;
  const AnimatedShowSubMenu = useRef(new Animated.Value(1)).current;
  const lastContentOffset = useSharedValue(0);
  const [showSubMenu, setShowSubMenu] = useState(true);

  const subMenuHeight = Metrics.verticalScale(32);
  const sharedRange = [0, rangeEnd / 6, rangeEnd];

  const animatedAppBarBgOpacity = AnimatedHeaderValue.interpolate({
    inputRange: sharedRange,
    outputRange: [0, 1, 1],
    extrapolate: 'clamp',
  });

  const animatedSubMenuTop = AnimatedShowSubMenu.interpolate({
    inputRange: [0, 1],
    outputRange: [-subMenuHeight, 0],
  });
  const animatedSubMenuOpacity = AnimatedShowSubMenu.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });
  // TO DO
  // useEffect(() => {
  //   navigation.setOptions({
  //     headerStyle: {
  //       transform: [
  //         {
  //           translateY: animatedSubMenuTop,
  //         },
  //       ],
  //       opacity: animatedSubMenuOpacity,
  //     },
  //     headerBackgroundContainerStyle: {
  //       opacity: animatedAppBarBgOpacity,
  //     },
  //     headerBackTitleStyle: {
  //       transform: [
  //         {
  //           translateY: animatedSubMenuTop,
  //         },
  //       ],
  //     },
  //   });
  // }, [
  //   AnimatedHeaderValue,
  //   animatedAppBarBgOpacity,
  //   animatedSubMenuOpacity,
  //   animatedSubMenuTop,
  //   navigation,
  //   showSubMenu,
  // ]);

  useEffect(() => {
    if (showSubMenu) {
      Animated.timing(AnimatedShowSubMenu, {
        toValue: 1,
        duration: 700,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(AnimatedShowSubMenu, {
        toValue: 0,
        duration: 700,
        useNativeDriver: false,
      }).start();
    }
  }, [AnimatedShowSubMenu, showSubMenu]);

  useEffect(() => {
    const listenerId = AnimatedHeaderValue.addListener(data => {
      if (data.value >= 0) {
        if (data.value > lastContentOffset.value) {
          if (showSubMenu) {
            setShowSubMenu(false);
          }
        } else if (data.value < lastContentOffset.value) {
          if (!showSubMenu) {
            setShowSubMenu(true);
          }
        }

        lastContentOffset.value = data.value;
      }
    });
    return () => {
      AnimatedHeaderValue.removeListener(listenerId);
    };
  }, [
    AnimatedHeaderValue,
    AnimatedShowSubMenu,
    lastContentOffset,
    showSubMenu,
  ]);

  return {
    AnimatedHeaderValue,
  };
};

export default useScrollAnimation;
