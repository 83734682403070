import React from 'react';
import {Pressable, StyleProp, View, ViewStyle} from 'react-native';
import {FastImageProps} from 'react-native-fast-image';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';
import RowCard from '../RowCard/RowCard.web';
import AutoScaleImage from '../../AutoScaleImage/AutoScaleImage';

export type CardMessageProps = {
  cover: FastImageProps['source'];
  title: string;
  description?: string;
  date?: string;
  onPress?(): void;
  border?: boolean;
  coverType?: 'rounded' | 'square';
  logoPartner?: Array<FastImageProps['source']>;
  style?: StyleProp<ViewStyle>;
  partner?: string;
  flexDirection?: 'row' | 'column';
};

export default function CardMessage(props: CardMessageProps) {
  const {
    cover,
    title,
    description,
    date,
    onPress,
    border = true,
    coverType = 'rounded',
    logoPartner,
    style,
    partner,
    flexDirection,
  } = props;
  return (
    <Pressable
      onPress={onPress}
      style={[
        {
          paddingBottom: Metrics.horizontalScale(17),
          paddingTop: Metrics.horizontalScale(12),
          borderBottomWidth: border ? 1 : 0,
          borderBottomColor: 'rgba(115, 115, 115, 0.5)',
        },
        style,
      ]}>
      <RowCard
        flexDirection={flexDirection}
        left={
          <AutoScaleImage
            uri={cover && typeof cover === 'object' ? cover.uri || '' : ''}
            initWidth={Metrics.horizontalScale(80)}
            initHeight={Metrics.horizontalScale(80)}
            style={{
              borderRadius: Metrics.horizontalScale(
                coverType === 'rounded' ? 40 : 4,
              ),
            }}
            backgroundFallbackColor="#1B1C21"
          />
        }
        rightFlex={1}
        right={
          <View
            style={{marginTop: !description ? Metrics.horizontalScale(16) : 0}}>
            <Typography variant="h4">{title}</Typography>
            {description && (
              <Typography
                numberOfLines={3}
                ellipsizeMode="tail"
                variant="ui3"
                color="gray"
                style={{
                  marginTop: Metrics.horizontalScale(5),
                  marginBottom: Metrics.horizontalScale(5),
                }}>
                {description}
              </Typography>
            )}
            {date && (
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: !description ? Metrics.horizontalScale(11) : 0,
                }}>
                <Icon name="horaire" />
                <Typography variant="ui3"> {date}</Typography>
              </View>
            )}
            {partner && (
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: !description ? Metrics.horizontalScale(11) : 0,
                }}>
                <Typography variant="ui3"> {partner}</Typography>
              </View>
            )}
            {logoPartner && (
              <View
                style={{
                  marginTop: Metrics.verticalScale(17),
                }}>
                {logoPartner.map((partner, index) => {
                  return (
                    <AutoScaleImage
                      key={'p' + index}
                      uri={
                        typeof partner === 'object' && partner.uri
                          ? partner.uri
                          : ''
                      }
                      initWidth={Metrics.horizontalScale(35)}
                      initHeight={Metrics.verticalScale(10)}
                      style={{marginRight: Metrics.horizontalScale(8)}}
                      backgroundFallbackColor="transparent"
                      resizeMode="contain"
                    />
                  );
                })}
              </View>
            )}
          </View>
        }
      />
    </Pressable>
  );
}
