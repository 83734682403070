import {IRegionWebFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {useAppSelector} from '../hooks';
import {cultureRegionMapper} from '@src/tools/cultureRegionMapper';

interface Option {
  label: string;
  value: string;
  culture: string;
}

export const findRegionsByDocumentUrl = (
  url: string,
  regions: IRegionWebFragmentFragment[],
) => {
  return regions.find(
    r => url === `umb://document/${(r?.id + '').replace(/-/g, '')}`,
  );
};

export const useRegions = (): {
  regions: IRegionWebFragmentFragment[];
  regionOptions: Array<Option>;
  findRegionsByDocumentUrl: (
    url: string,
  ) => IRegionWebFragmentFragment | undefined;
} => {
  const regions = useAppSelector(({region}) => region.regions);
  const _findRegionsByDocumentUrl = (url: string) => {
    return findRegionsByDocumentUrl(url, regions);
  };

  return {
    regions,
    findRegionsByDocumentUrl: _findRegionsByDocumentUrl,
    regionOptions: regions.map<Option>(region => ({
      label: region.name || '',
      value: `umb://document/${(region?.id + '').replace(/-/g, '')}`,
      culture: region.defaultLanguage
        ? cultureRegionMapper(region.defaultLanguage)
        : '',
    })),
  };
};
