import { IHeroVideoCoverFragmentFragment } from '@src/Api/Graphql/gql/types.generated';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';


const useGetResponsiveCover = () => {
  const {isMobile} = useBreakpoint();
  
  const getResponsiveImage = (image: IHeroVideoCoverFragmentFragment) => {
    if (isMobile) {
      return image.image?.mobileCover || image.image?.url;
    }
    return image.desktopImage?.url;
  }
   
  return {
    getResponsiveImage
  }
};
 

export default useGetResponsiveCover;
