import React from 'react';
import BottomTabNavigationItem, {
  BottomNavigationItemProps,
} from '../BottomTabNavigationItem';

const BottomTabNavItemMenu = (
  props: Omit<BottomNavigationItemProps, 'icon'>,
) => {
  const {title = 'Menu', ...rest} = props;
  return <BottomTabNavigationItem icon="more" title={title} {...rest} />;
};

export default BottomTabNavItemMenu;
