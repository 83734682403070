import {useState, useCallback} from 'react';

const usePressableHover = () => {
  const [hover, setHover] = useState(false);
  const onHoverIn = useCallback(() => setHover(true), []);
  const onHoverOut = useCallback(() => setHover(false), []);
  return {hover, onHoverIn, onHoverOut};
};

export default usePressableHover;
