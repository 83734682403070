import ThumbnailTvGuide from 'design-system/src/Components/Card/LiveCard/ThumbnailTvGuide/ThumbnailTvGuide';
import Metrics from 'design-system/src/Theme/Metrics';
import React from 'react';
import {StyleProp, ViewStyle, Pressable} from 'react-native';
import {ITvGuideItem} from '~Models/TvModel';
import DateFactory from '~tools/DateFactory';
import dayjs from 'dayjs';
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface ITVGuideElementProps {
  data: ITvGuideItem;
  index: number;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

const TVGuideElement = (props: ITVGuideElementProps) => {
  const {data, style, index, onPress} = props;
  const {t} = useTranslation('translation');
  const progress = data.duration
    ? dayjs().diff(data.start, 'minutes') / data.duration
    : 0;
  return (
    <Pressable style={style} onPress={onPress}>
      <ThumbnailTvGuide
        style={{
          width: Metrics.horizontalScale(170),
        }}
        key={index}
        title={data.title || ''}
        start={new DateFactory(data.start).time()}
        length={t('{{minute}} min', {
          replace: {
            minute: data.duration,
          },
        })}
        type={data.type}
        progress={progress}
      />
    </Pressable>
  );
};

export default React.memo(TVGuideElement);
