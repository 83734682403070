import usePaywal from '@src/Hooks/usePaywal';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import React, {useEffect, useState} from 'react';
import PaywallConfirmPage from '@src/Umbraco/Pages/PaywallSectionPage/PaywallConfirmPage/PaywallConfirmPage';
import CustomActivityIndicator from 'design-system/src/Components/CustomActivityIndicator/CustomActivityIndicator';
import {
  IItemPlan,
  periodIcon,
} from 'design-system/src/Widget/PayWall/PayWallChoosePlan/PayWallChoosePlan';
import SubscribeCard from 'design-system/src/Components/Card/SubscribeCard/SubscribeCard';
import apiConfig from '@src/Configs/apiConfig';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import SubscriptionOther from 'design-system/src/Components/Subscription/SubscriptionOther/SubscriptionOther';
import Images from 'design-system/src/assets/Images';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import Metrics from 'design-system/src/Theme/Metrics';
import {View, Pressable} from 'react-native';
import {toast} from 'react-toastify';
// import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import {useRedirectCancelUrlSettingLazyQuery} from '@src/Api/Graphql/gql/types.generated';
import memberSlice, {EDeviceType} from '@src/Store/Slices/Member.slice';
import CancelSubscriptionConfirmation from './CancelSubscriptionConfirmation';
import {useCancelStripeSubscriptionMutation} from '@src/Api/TraceApi/traceApi';
import {useDispatch} from 'react-redux';
import Button from 'design-system/src/Components/Button/Button';
import PromoCodeTag from './PromoCodeTag';
import PromoCodeImage from './PromoCodeImage';
import {usePromoCodeContent} from '@src/Navigation/PromoCodePage/usePromoCodeContent';

const ProfileSubscription = () => {
  const {t} = useTranslation(['profile', 'subscription']);
  const [cancelStripeSubscription] = useCancelStripeSubscriptionMutation();
  const [
    isCancelSubscriptionConfirmationOpen,
    setIsCancelSubscriptionConfirmationOpen,
  ] = useState(false);
  // const tracker = useTracker();
  const {
    isMemberFreemium,
    memberSubscription,
    memberPromoCode,
    isValidPromocode,
  } = usePaywal();
  const dispatch = useDispatch();
  const {
    page: {push},
  } = useRegionUrl();
  const isFree = isMemberFreemium();
  const navigation = useAppNavigation();
  const [fetchRedirectUrl, {data: redirectData, loading: redirectLoading}] =
    useRedirectCancelUrlSettingLazyQuery();
  const {androidCancelSubUrl, iOSCancelSubUrl, huaweiCancelSubUrl} =
    (redirectData && redirectData.allSettings.items[0]) || {};

  const {subscriptionTitle, subscriptionDescription} = usePromoCodeContent();

  useEffect(() => {
    fetchRedirectUrl();
  }, []);

  const handleCancelSubscription = () => {
    const deviceType = memberSubscription?.deviceType;
    if (
      deviceType === EDeviceType.ANDROID ||
      deviceType === EDeviceType.HUAWEI
    ) {
      window.open(androidCancelSubUrl, '_blank');
    } else if (deviceType === EDeviceType.IOS) {
      window.open(iOSCancelSubUrl, '_blank');
    } else if (deviceType === EDeviceType.WEB) {
      setIsCancelSubscriptionConfirmationOpen(true);
    }
  };

  const handleConfirmCancelSubscriptionOk = async () => {
    try {
      const response = await cancelStripeSubscription();

      if ('data' in response) {
        const {data} = response;
        dispatch(
          memberSlice.actions.setMemberSubscription({
            memberId: data.memberId,
            subscriptionStart: data.subscriptionStart.toString(),
            id: data._id,
            deviceType: data.deviceType,
            subscriptionType: data.subscriptionTypeId,
            productId: data.externalID,
            cancelRequested: data.cancelRequested,
          }),
        );
        toast.success(t('Subscription cancelled successfully'));
        setIsCancelSubscriptionConfirmationOpen(false);
        return true;
      }
      setIsCancelSubscriptionConfirmationOpen(false);
      return false;
    } catch (error) {
      console.log(error);
      toast.error(t('Failed to cancel subscription'));
      setIsCancelSubscriptionConfirmationOpen(false);
      return false;
    }
  };

  return (
    <View style={{paddingHorizontal: 0}}>
      <Typography
        variant="h2"
        style={{
          marginBottom: 24,
        }}>
        {t('Subscription')}
      </Typography>
      <PaywallConfirmPage needStore={false}>
        {({allSubscription, loading}) => {
          if (loading) {
            return (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CustomActivityIndicator />
              </View>
            );
          }
          let currentSubscription:
            | Pick<
                IItemPlan,
                | 'title'
                | 'icon'
                | 'subtitle'
                | 'price'
                | 'description'
                | 'period'
                | 'percentageSave'
              >
            | undefined;
          let premiumSubscription: IItemPlan | undefined;
          let freeSubscription: IItemPlan | undefined;

          const isCancelRequested = memberSubscription?.cancelRequested;

          if (isValidPromocode && memberPromoCode) {
            currentSubscription = {
              title: subscriptionTitle || '',
              subtitle: subscriptionDescription || '',
              price: '',
              description: '',
              icon: undefined,
              period: 'more',
            };
          } else {
            allSubscription.forEach(item => {
              if (
                memberSubscription &&
                item.planId === memberSubscription.productId
              ) {
                currentSubscription = item;
              } else if (
                !memberSubscription &&
                item.planId === apiConfig.freemiumProductId
              ) {
                currentSubscription = item;
              }
              if (
                !premiumSubscription &&
                item.planId !== apiConfig.freemiumProductId
              ) {
                premiumSubscription = item;
              }
              if (
                !freeSubscription &&
                item.planId === apiConfig.freemiumProductId
              ) {
                freeSubscription = item;
              }
            });
          }

          return (
            <>
              {currentSubscription ? (
                <>
                  <SubscribeCard
                    variant="current"
                    title={currentSubscription.title}
                    description={currentSubscription.subtitle}
                    price={currentSubscription.price}
                    period={currentSubscription.period}
                    icon={
                      !currentSubscription.icon
                        ? periodIcon[currentSubscription.period]
                        : () => (
                            <CustomFastImage
                              width={32}
                              height={32}
                              source={currentSubscription?.icon}
                              backgroundFallbackColor="transparent"
                            />
                          )
                    }
                    percentageSave={currentSubscription.percentageSave}
                    footerRender={
                      isValidPromocode &&
                      memberPromoCode && (
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: Metrics.verticalScale(16),
                          }}>
                          <PromoCodeTag text={memberPromoCode.code} />
                          <Typography variant="body3">
                            {t('{{days}} days remaining', {
                              days: Math.ceil(
                                (new Date(
                                  memberPromoCode.subscriptionEnd,
                                ).getTime() -
                                  new Date().getTime()) /
                                  (1000 * 60 * 60 * 24),
                              ),
                              ns: 'subscription',
                            })}
                          </Typography>
                        </View>
                      )
                    }
                  />

                  {!isFree && (
                    <Button
                      fullWidth
                      loading={redirectLoading}
                      containerStyle={{
                        marginTop: Metrics.verticalScale(36),
                      }}
                      variant={isCancelRequested ? 'disabled' : 'outlined'}
                      disabled={isCancelRequested}
                      title={
                        isCancelRequested
                          ? t('Cancel requested', {ns: 'subscription'})
                          : t('cancel my subscription', {ns: 'subscription'})
                      }
                      onPress={handleCancelSubscription}
                    />
                  )}
                </>
              ) : (
                freeSubscription && (
                  <SubscribeCard
                    variant="current"
                    title={freeSubscription.title}
                    description={freeSubscription.subtitle}
                    price={freeSubscription.price}
                    period={freeSubscription.period}
                    icon={periodIcon[freeSubscription.period]}
                    percentageSave={freeSubscription.percentageSave}
                  />
                )
              )}

              {isValidPromocode ? (
                <View style={{marginTop: 120}}>
                  <PromoCodeImage />
                </View>
              ) : (
                <>
                  {push && (isFree || push.isPremium) && (
                    <View style={{marginTop: 23}}>
                      <SubscriptionOther
                        title={push.topTitle || ''}
                        subtitle={push.title || ''}
                        description={push.description || ''}
                        image={
                          push.background
                            ? {uri: push.background.url}
                            : Images.subscription.bg
                        }
                        onPress={() => {
                          navigation.navigate(AppRoutesEnum.CHOOSE_PLAN);
                        }}
                        icon={
                          push.icon
                            ? {
                                uri: push.icon.url,
                              }
                            : undefined
                        }
                      />
                    </View>
                  )}

                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 16,
                    }}>
                    <Pressable
                      style={{
                        justifyContent: 'center',
                        maxWidth: Metrics.horizontalScale(200),
                      }}
                      onPress={() => {
                        navigation.navigate(AppRoutesEnum.TERMS_AND_CONDITION, {
                          state: {
                            params: {type: 'termsConditions'},
                          },
                        });
                      }}>
                      <Typography variant="body3" color="gray">
                        {t('Terms and conditions')}
                      </Typography>
                    </Pressable>
                    <PressableCustom
                      style={{
                        alignSelf: 'flex-end',
                      }}
                      onPress={() => {
                        navigation.navigate(AppRoutesEnum.PROMOCODE);
                      }}>
                      {() => {
                        return (
                          <>
                            <Typography
                              style={{
                                textAlign: 'right',
                              }}
                              variant="button3"
                              color={'gray'}>
                              {t('You have a promo code?')}
                            </Typography>
                            <Typography
                              style={{
                                textAlign: 'right',
                              }}
                              variant="button3"
                              color={'primary'}>
                              {t('Click here to redeem')}
                            </Typography>
                          </>
                        );
                      }}
                    </PressableCustom>
                  </View>
                </>
              )}
            </>
          );
        }}
      </PaywallConfirmPage>

      <CancelSubscriptionConfirmation
        isOpen={isCancelSubscriptionConfirmationOpen}
        onClose={() => setIsCancelSubscriptionConfirmationOpen(false)}
        onOk={handleConfirmCancelSubscriptionOk}
      />
    </View>
  );
};

export default ProfileSubscription;
