import {createSlice, nanoid, PayloadAction} from '@reduxjs/toolkit';
import {ReactNode} from 'react';

export interface INotification {
  id: string;
  title: ReactNode;
  message: ReactNode;
  duration?: number;
}

interface SnackbarState {
  notifications: INotification[];
}

const initialState = {
  notifications: [],
} as SnackbarState;

// Then, handle actions in your reducers:
const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Omit<INotification, 'id'>>) {
      state.notifications = [
        ...state.notifications,
        {...action.payload, id: nanoid()},
      ];
      return state;
    },
    removeNotification(state, action: PayloadAction<string>) {
      state.notifications = state.notifications.filter(
        item => item.id !== action.payload,
      );
      return state;
    },
  },
});

export default snackbarSlice;
