import useParams from '@src/Hooks/useParams';
import {useAppSelector} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {TraceLogo} from 'design-system/src/assets/ImageSvg';
import React from 'react';
import {useLocation} from '@reach/router';
import {AppRoutesEnum} from '@src/Constant/routes';

const AppSubMenuLeft = () => {
  const {onGoBack, isMobile, title, isEmpty} = useSubMenuLeft();
  const appNavigation = useAppNavigation();

  if (isEmpty) {
    if (isMobile) {
      return (
        <span
          style={{
            cursor: 'pointer',
          }}
          onClick={() => appNavigation.navigate(AppRoutesEnum.HOME)}>
          <TraceLogo width={91} height={16} />
        </span>
      );
    }
    return null;
  }
  return (
    <div className="sub-menu-left" onClick={onGoBack}>
      <Icon name="retour" />
      {!!title && <Typography variant="body2">{title}</Typography>}
    </div>
  );
};

export default AppSubMenuLeft;

export const useSubMenuLeft = () => {
  const {leftSubMenu} = useAppSelector(state => state.app);
  const params = useParams();
  const currentTitle = params?.title;
  const {isMobile} = useBreakpoint();
  const {goBack} = useAppNavigation();
  const location = useLocation();
  const canGoBack = (location.state as any)?.canGoBack;

  const isEmpty = !(canGoBack || leftSubMenu || currentTitle);

  return {
    onGoBack: () => goBack(),
    isMobile,
    title: decodeURIComponent(currentTitle || leftSubMenu?.title || ''),
    isEmpty,
  };
};
