import React, {FC} from 'react';
// import BlockSlider from 'design-system/src/Components/Card/HighlightedCarousel/BlockSlider/BlockSlider';
// import {liveEventData} from '../home/appHomeMock';
import CategoryPage from '@src/Umbraco/Pages/CategoryPage/CategoryPage';
import useParams from '@src/Hooks/useParams';

const AppCategorieWatch: FC = () => {
  const {id} = useParams();
  return (
    <CategoryPage
      type="WatchCateg"
      activeId={typeof id === 'string' ? id : undefined}
    />
  );
};

export default AppCategorieWatch;
