/* eslint-disable react-native/no-inline-styles */
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import React from 'react';
import styled from 'styled-components';
import {View} from 'react-native';
import clsx from 'clsx';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import Metrics from 'design-system/src/Theme/Metrics';

export type ECoursePageTemplate = 'overview' | 'media' | 'text' | 'image';

interface IExamOverviewWrapperProps {
  template?: ECoursePageTemplate;
  children?: React.ReactNode;
  imageCover?: string;
  title?: string;
  subtitle?: string;
}

const _Root = styled.div(() => {
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };
});

const _Main = styled.div(() => {
  return {
    '&.template-media': {
      width: '100%',
      maxWidth: '407px',
      margin: '0 auto',
    },

    '&.template-text': {
      maxWidth: '407px',
      margin: '0 auto',
    },
    flex: 1,
  };
});
const _Header = styled.div<{isMobile: boolean}>(({isMobile}) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: isMobile
      ? Metrics.horizontalScale(24)
      : Metrics.horizontalScale(60),
    paddingRight: isMobile
      ? Metrics.horizontalScale(24)
      : Metrics.horizontalScale(60),
    paddingTop: Metrics.verticalScale(80),
  };
});

const ExamOverviewWrapper = (props: IExamOverviewWrapperProps) => {
  const {template, children, imageCover, title, subtitle} = props;
  const {isMobile} = useBreakpoint();
  const templateClass = `template-${isMobile ? '' : template}`;

  const aspectRatio = isMobile ? 3 / 4 : 16 / 9;

  return (
    <_Root>
      {imageCover && (
        <>
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
              aspectRatio: aspectRatio,
            }}>
            <CustomFastImage
              source={{
                uri: imageCover,
              }}
              resizeMode="cover"
              width={'100%'}
              height={'100%'}
              // aspectRatio={aspectRatio}
            />
          </View>
          <View
            style={{
              position: 'absolute',
              top: 0,
              overflow: 'hidden',
              width: '100%',
              aspectRatio: aspectRatio,
            }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                opacity: 0.5,
                background:
                  'linear-gradient(360deg, rgba(17, 18, 22, 0) 0%, rgba(17, 18, 22, 0.8) 71.63%, #111216 100%)',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '100%',
                width: '100%',
                background:
                  'linear-gradient(180deg, rgba(17, 18, 22, 0) 0%, rgba(17, 18, 22, 0.8) 57.45%, #111216 74.32%)',
              }}
            />
          </View>
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: !isMobile ? 'row' : 'column',
        }}>
        <_Header
          className={clsx('course-page-header', templateClass)}
          isMobile={isMobile}>
          {!!title && (
            <Typography
              variant="h1"
              style={{
                marginTop: 40,
                zIndex: 1,
                maxWidth: 426,
                fontSize: isMobile ? 32 : 48,
                lineHeight: isMobile ? 32 : 48,
              }}>
              {title}
            </Typography>
          )}
          {!!subtitle && (
            <Typography
              variant="body3"
              style={{
                marginTop: 18,
                maxWidth: 226,
                zIndex: 1,
              }}>
              {subtitle || ''}
            </Typography>
          )}
        </_Header>
        <_Main className={clsx('course-page-main', templateClass)}>
          {children}
        </_Main>
      </div>
    </_Root>
  );
};

export default ExamOverviewWrapper;
