import Button from 'design-system/src/Components/Button/Button';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import Metrics from 'design-system/src/Theme/Metrics';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {FlatList, ListRenderItem, Pressable, View} from 'react-native';
import {RouteTypename} from '~Navigation/type';
import React from 'react';

export interface OpportunitiesData<T> {
  title: string;
  cover?: string;
  partner?: string;
  location: string;
  contractType: string;
  certficates: T[];
  description: string;
  onItemPress?: (item: T) => void;
  onPress?: () => void;
  __typename: RouteTypename;
  id: string;
  url?: string;
  link?: string;
}

export const OpportunityItem = <T extends {title?: string}>(
  props: OpportunitiesData<T>,
) => {
  const {t} = useTranslation(['learn']);

  const {
    title,
    contractType,
    location,
    cover,
    partner,
    description,
    certficates,
    onItemPress,
    onPress,
  } = props;

  /**
   * Renders an individual item within a list of opportunities.
   *
   * @param {Object} renderData - The data for the item to be rendered.
   * @param {T} renderData.item - The item data.
   * @param {number} renderData.index - The index of the item in the list.
   * @returns {JSX.Element} The rendered item component.
   */
  const renderItem: ListRenderItem<T> = renderData => {
    const {item, index} = renderData;
    return (
      <Button
        title={item.title || ''}
        size="xsmall"
        variant={'filled'}
        style={{
          marginLeft: index !== 0 ? 4 : 0,
          marginRight: index !== certficates.length - 1 ? 4 : 0,
          maxWidth: Metrics.horizontalScale(134),
          overflow: 'hidden',
        }}
        onPress={() => {
          onItemPress && onItemPress(item);
        }}
      />
    );
  };

  return (
    <Pressable
      onPress={onPress}
      style={{
        paddingHorizontal: Metrics.horizontalScale(16),
        paddingTop: Metrics.verticalScale(16),
        paddingBottom: Metrics.verticalScale(16),
        backgroundColor: '#1B1C21',
        borderRadius: Metrics.moderateScale(8),
        marginVertical: Metrics.verticalScale(8),
      }}>
      <View
        style={{
          flexDirection: 'row',
        }}>
        <CustomFastImage
          width={Metrics.horizontalScale(80)}
          height={Metrics.horizontalScale(80)}
          source={{
            uri: cover,
          }}
          style={{
            borderRadius: Metrics.horizontalScale(4),
          }}
        />
        <Typography
          children={title || ''}
          variant="h3"
          style={{
            marginLeft: Metrics.horizontalScale(24),
            flex: 1,
          }}
        />
      </View>
      <Typography
        children={description}
        variant="body3"
        color={() => '#A0A0A0'}
        style={{
          marginTop: Metrics.verticalScale(18),
        }}
      />
      {(contractType || location) && (
        <View
          style={{
            marginTop: Metrics.verticalScale(23),
            marginBottom: Metrics.verticalScale(36),
            flexDirection: 'row',
          }}>
          {contractType && (
            <View
              style={{
                width: Metrics.horizontalScale(140),
              }}>
              <Icon name={'message'} color={'white'} />
              <Typography
                variant="ui3"
                color={'white'}
                style={{
                  marginTop: Metrics.verticalScale(8),
                }}>
                {t('Contrat type') as string}
              </Typography>
              <Typography
                variant="ui3"
                color={'gray'}
                style={{
                  marginTop: Metrics.verticalScale(2),
                }}>
                {contractType}
              </Typography>
            </View>
          )}
          {location && (
            <View
              style={{
                width: Metrics.horizontalScale(140),
                borderLeftWidth: 1,
                borderLeftColor: '#2F3139',
                paddingHorizontal: Metrics.horizontalScale(14),
              }}>
              <Icon name={'location'} color={'white'} />
              <Typography
                variant="ui3"
                color={'white'}
                style={{
                  marginTop: Metrics.verticalScale(8),
                }}>
                {t('Location') as string}
              </Typography>
              <Typography
                variant="ui3"
                color={'gray'}
                style={{
                  marginTop: Metrics.verticalScale(2),
                }}>
                {location}
              </Typography>
            </View>
          )}
        </View>
      )}
      {certficates.length !== 0 && (
        <>
          <Typography
            children={t('Relevent job training certificates')}
            variant="body3"
          />
          <FlatList
            style={{marginTop: Metrics.verticalScale(18), marginBottom: 23}}
            data={certficates}
            keyExtractor={(_, index) => index + ''}
            renderItem={renderItem}
            horizontal
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          />
        </>
      )}
    </Pressable>
  );
};
