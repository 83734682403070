import React, {useMemo, useCallback, useEffect, useState} from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Pressable,
  View,
  ViewStyle,
  Easing,
  Animated,
  Platform,
} from 'react-native';

import {IconName, IconSize} from '../../assets/Icones';
import defaultTheme from '../../Theme/defaultTheme';
import Icon from '../Icon/Icon';
import {
  buttonSizeStyles,
  buttonStyles,
  buttonVariantHoverStyles,
  buttonVariantStyles,
} from './Button.styles';
import {typographyVariantStyled} from '../Typography/Typography.styles';
import {useThemeContext} from '../../Provider/ThemeProvider/ThemeContext';
import {useTrackingContext} from '../../Provider/TrackingProvider/TrackingContext';
import {isWeb} from '../../Theme/Metrics';

export interface IButtonProps
  extends Omit<React.ComponentProps<typeof Pressable>, 'onPress'> {
  variant?: keyof typeof buttonVariantStyles;
  size?: keyof typeof buttonSizeStyles;
  icon?: IconName;
  iconSize?: IconSize;
  isLoading?: boolean;
  title: string;
  buttonColor?: string;
  containerStyle?: StyleProp<ViewStyle>;
  fullWidth?: boolean;
  customStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
  borderColor?: string;
  onPress?: (e?: any) => void;
  trackingData?: object;
  disableTracking?: boolean;
  iconColor?: string;
}

const Button = (props: IButtonProps) => {
  const {
    variant = 'outlined',
    size = 'large',
    icon,
    iconSize = '16',
    title,
    style,
    buttonColor,
    containerStyle,
    fullWidth,
    customStyle,
    loading,
    disabled,
    isLoading,
    iconStyle = {},
    borderColor,
    onPress: onTap,
    trackingData = {},
    disableTracking,
    iconColor,
    ...restProps
  } = props;
  const {component} = useThemeContext();
  const {onButtonPress} = useTrackingContext();
  const providedStyle = component?.button[variant]?.customStyle;
  const sizeStyle = buttonSizeStyles[size];
  const [rippleScaled, setRippleScaled] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(120);
  const [hover, setHover] = useState(false);
  const {color, ...variantStyle} = hover
    ? buttonVariantHoverStyles[variant]
    : buttonVariantStyles[variant];
  const textColor: string = rippleScaled ? 'white' : buttonColor ?? color;
  const buttonText: string = (title || '').toUpperCase();
  if (borderColor) {
    variantStyle.borderColor = borderColor;
  }

  const maxOpacity = 1;

  const scaleValue = useMemo(() => new Animated.Value(0.01), []);
  const opacityValue = useMemo(() => new Animated.Value(maxOpacity), []);
  const animatedText = scaleValue.interpolate({
    inputRange: [0.01, 10],
    outputRange: [buttonColor ?? color, 'white'],
    easing: Easing.bezier(0.0, 0.0, 0.2, 1),
  });

  useEffect(() => {
    const listenerId = scaleValue.addListener(data => {
      if (data.value > 1) {
        if (!rippleScaled) {
          setRippleScaled(true);
        }
      } else {
        if (rippleScaled) {
          setRippleScaled(false);
        }
      }
    });
    return () => {
      scaleValue.removeListener(listenerId);
    };
  }, [rippleScaled, scaleValue]);
  const handleClick = () => {
    !disableTracking &&
      onButtonPress?.({
        title,
        ...trackingData,
      });
    onTap?.();
  };
  const onPressedIn = () => {
    Animated.timing(scaleValue, {
      toValue: 10,
      duration: ((buttonWidth / 2) * 525) / 34,
      useNativeDriver: false,
      easing: Easing.bezier(0.0, 0.0, 0.2, 1),
    }).start(finished => {
      if (finished) {
        Platform.OS !== 'web' && handleClick();
      }
    });
  };
  const onPressedOut = () => {
    Animated.timing(opacityValue, {
      toValue: 0,
      useNativeDriver: false,
    }).start(() => {
      scaleValue.setValue(0.01);
      opacityValue.setValue(maxOpacity);
    });
  };

  const renderRippleView = useCallback(() => {
    const rippleSize = buttonWidth / 2;

    return (
      <Animated.View
        style={{
          position: 'absolute',
          top: 20,
          left: 0,
          width: rippleSize,
          height: rippleSize,
          borderRadius: rippleSize / 2,
          transform: [{scale: scaleValue}],
          opacity: opacityValue,
          backgroundColor:
            Platform.OS === 'web' ? 'rgba(0,0,0,0.3)' : defaultTheme.primary,
        }}
      />
    );
  }, [opacityValue, scaleValue, buttonWidth]);
  return (
    <View
      style={[
        buttonStyles.buttonContainer,
        fullWidth && buttonStyles.fullWidth,
        containerStyle,
      ]}
      onLayout={event => {
        if (
          event.nativeEvent.layout.width &&
          buttonWidth !== event.nativeEvent.layout.width
        ) {
          setButtonWidth(event.nativeEvent.layout.width);
        }
      }}>
      <Pressable
        style={[
          buttonStyles.button,
          sizeStyle,
          variantStyle,
          fullWidth && {
            alignSelf: undefined,
            width: Platform.OS === 'web' ? '100%' : undefined,
          },
          customStyle ?? sizeStyle,
          customStyle ?? variantStyle,
          style,
          loading && {overflow: 'hidden', position: 'relative', opacity: 0.5},
          {overflow: 'hidden'},
          providedStyle ? providedStyle : {},
        ]}
        disabled={disabled || loading}
        {...restProps}
        onPress={Platform.OS === 'web' ? handleClick : undefined}
        onPressIn={onPressedIn}
        onPressOut={onPressedOut}
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}>
        {renderRippleView()}
        {hover && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}
          />
        )}
        {!!icon && (
          <Icon
            name={icon}
            size={iconSize}
            color={isWeb ? iconColor ?? color : textColor}
            style={
              isWeb
                ? {
                    ...buttonStyles.iconLeft,
                    ...(typeof iconStyle === 'object'
                      ? iconStyle
                      : ({} as any)),
                  }
                : [buttonStyles.iconLeft, iconStyle]
            }
          />
        )}
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Animated.Text
            numberOfLines={1}
            style={StyleSheet.flatten([
              {color: animatedText},
              typographyVariantStyled.button1,
            ])}>
            {/* <Typography variant="button1" color={() => textColor}> */}
            {buttonText}
            {/* </Typography> */}
          </Animated.Text>
          {isLoading && (
            <ActivityIndicator
              style={{marginLeft: 6}}
              color="white"></ActivityIndicator>
          )}
        </View>
      </Pressable>
      {loading && (
        <ActivityIndicator
          color={textColor}
          style={[StyleSheet.absoluteFill]}
        />
      )}
    </View>
  );
};

export default Button;
