import Metrics from 'design-system/src/Theme/Metrics';
import React, {PropsWithChildren} from 'react';
import {View} from 'react-native';

export type PaddingContainerProps = {
  edgeHoriz?: number;
  edgeVert?: number;
  bottom?: number;
  top?: number;
  left?: number;
  right?: number;
};

const PaddingContainer = (props: PropsWithChildren<PaddingContainerProps>) => {
  const {edgeHoriz, edgeVert, children, bottom, top, left, right} = props;
  return (
    <View
      style={{
        paddingHorizontal: edgeHoriz !== undefined
          ? Metrics.horizontalScale(edgeHoriz)
          : Metrics.horizontalScale(16),
        paddingVertical: edgeVert && Metrics.verticalScale(edgeVert),
        paddingBottom: bottom && Metrics.verticalScale(bottom),
        paddingTop: top && Metrics.verticalScale(top),
        paddingLeft: left && Metrics.horizontalScale(left),
        paddingRight: right && Metrics.horizontalScale(right),
      }}>
      {children}
    </View>
  );
};

export default PaddingContainer;
