import React from 'react';
import {Pressable, PressableProps, StyleSheet} from 'react-native';
import {IconName} from '../../../assets/Icones';
import defaultTheme from '../../../Theme/defaultTheme';
import {CustomActivityIndicator} from '../../CustomActivityIndicator';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';
import {navLiveTvCardStyles} from './NavLiveTvCard.styles';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';

export interface NavLiveTvCardProps extends PressableProps {
  title: string;
  icon: IconName;
  isActive?: boolean;
  loading?: boolean;
}

const NavLiveTvCard = (props: NavLiveTvCardProps) => {
  const {title, icon, isActive, loading, ...restProps} = props;
  const color: string = isActive
    ? defaultTheme.primary
    : defaultTheme.typography.color.white;

  const {isMobile} = useBreakpoint();

  return (
    <Pressable
      style={[
        navLiveTvCardStyles.container,
        isMobile && {
          width: '100%',
          flex: 1,
        },
      ]}
      {...restProps}>
      <Icon name={icon} color={color} style={navLiveTvCardStyles.icon} />
      <Typography children={title} color={() => color} variant="body2" />
      {loading && (
        <CustomActivityIndicator
          style={[
            StyleSheet.absoluteFill,
            {backgroundColor: 'rgba(255, 255, 255, 0.12)'},
          ]}
        />
      )}
    </Pressable>
  );
};

export default NavLiveTvCard;
