import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberTestProgressionBody} from './interface/memberDataBody.interface';
import {ICourseTestResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/course-test-progression';
export const createMemberCourseTestProgressionEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberCourseTestProgressionById: builder.query<
    ICourseTestResponse,
    {id: string}
  >({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  updateMemberCourseTestProgression: builder.query<
    ICourseTestResponse,
    {id?: string; data: IMemberTestProgressionBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
});
