import useMember, {useMemberDatas} from '@src/Store/selectors/useMember';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import memberSlice, {IMemberUnitProgression} from '~Store/Slices/Member.slice';
import useMemberWatched from './useMemberWatched';
import {
  useLazyGetAllMemberOpportunityQuery,
  useLazyGetAllMemberSavedQuery,
  useLazyGetAllMemberSearchQuery,
  useLazyGetAllUnitProgressionQuery,
} from '~Api/TraceApi/traceApi';
import {usePromoCode} from '@src/Navigation/PromoCodePage/usePromocode';
// import useMemberNotify from './useMemberNotify';
// import useMemberMessage from './useMemberMessage';
import {useMemberObtainedJob} from './useMemberObtainedJob';
import {useMemberCourseStatus} from './useMemberCourseStatus';

const useSetupMemberDatas = () => {
  const member = useMember();
  const memberDatas = useMemberDatas();
  const dispatch = useDispatch();
  // TODO: member message
  // const {fetch: fetchMessage} = useMemberMessage();
  const [fetchSaved] = useLazyGetAllMemberSavedQuery();
  const {fetchWatched} = useMemberWatched();
  const [fetchSearch] = useLazyGetAllMemberSearchQuery();
  // TODO: member notify
  // const {fetch: fetchNotify} = useMemberNotify();
  const [fetchUnitProgression] = useLazyGetAllUnitProgressionQuery();
  const [fetchMemberOpportunites] = useLazyGetAllMemberOpportunityQuery();
  // TODO: member obtained job
  const {handleFetchObtainedJob} = useMemberObtainedJob();
  const {fetchCourseStatus} = useMemberCourseStatus();
  const {handleGetPromocode} = usePromoCode();
  useEffect(() => {
    if (member) {
      //   notify
      // fetchNotify();
      //   message
      // fetchMessage();
      //   saved
      fetchSaved().then(({data}) => {
        if (data) {
          dispatch(memberSlice.actions.setSaved(data));
        }
      });

      //   watched
      fetchWatched();

      fetchSearch().then(({data}) => {
        if (data) {
          dispatch(memberSlice.actions.setMemberSearch(data));
        }
      });

      fetchUnitProgression().then(({data}) => {
        if (data) {
          const list: IMemberUnitProgression[] = [];
          data.map(item => {
            if (item) {
              const {unitId, courseId, finished} = item;
              if (finished) {
                list.push({
                  name: unitId || '',
                  courseId: courseId || '',
                });
              }
            }
          });
          dispatch(memberSlice.actions.setUnitsProgression(list));
        }
      });
      // opportunities
      fetchMemberOpportunites().then(({data}) => {
        if (data) {
          dispatch(memberSlice.actions.setOpportunities(data));
        }
      });
      handleFetchObtainedJob();
      handleGetPromocode();
      fetchCourseStatus();
    }
  }, [
    // fetchNotify,
    member,
    // fetchMessage,
    fetchSaved,
    fetchWatched,
    dispatch,
    fetchSearch,
    memberDatas,
    handleFetchObtainedJob,
    fetchUnitProgression,
    fetchMemberOpportunites,
  ]);

  return;
};

export default useSetupMemberDatas;
