import {useCallback, useEffect, useMemo} from 'react';
import {
  IQuizzSurveyCollectionFragmentFragment,
  useQuizzSurveyCollectionByIdLazyQuery,
} from '~Api/Graphql/gql/types.generated';

export interface IQuizzEpisode {
  id: string;
  __typename: 'QuizzSurvey';
  title?: string;
  shortDescription?: string;
  thumbnail: IQuizzSurveyCollectionFragmentFragment['thumbnail'];
  url?: string;
  ctaLabel?: string;
  liveEventId?: number;
  sourceUrl?: string;
  vimeoHlsUrl?: string;
  hasQuestion?: boolean;
}

export const useMapQuizzCollection = (collectionId?: string) => {
  const [fetchCollection, {data: collectionData, loading}] =
    useQuizzSurveyCollectionByIdLazyQuery();
  useEffect(() => {
    if (collectionId) {
      fetchCollection({
        variables: {
          id: collectionId,
        },
      });
    }
  }, [collectionId]);
  const mapQuizzCollection = useCallback(
    (collection: IQuizzSurveyCollectionFragmentFragment) => {
      let _list: IQuizzEpisode[] = [];
      collection.children.items.forEach(_item => {
        if (_item && _item.__typename === 'QuizzSurvey') {
          const {
            id,
            url,
            __typename,
            thumbnail,
            liveEventId,
            sourceUrl,
            vimeoHlsUrl,
            shortDescription,
            ctaLabel,
            title,
            questions,
          } = _item;
          _list.push({
            id,
            url,
            __typename,
            thumbnail,
            liveEventId,
            sourceUrl,
            vimeoHlsUrl,
            shortDescription,
            ctaLabel,
            title,
            hasQuestion: !!questions?.length,
          });
        }
      });
      return _list;
    },
    [],
  );

  const quizzSurveyList = useMemo(() => {
    if (collectionData && collectionData.quizzSurveyCollection) {
      return mapQuizzCollection(collectionData.quizzSurveyCollection);
    }
  }, [collectionData]);

  return {mapQuizzCollection, loading, quizzSurveyList, collectionData};
};
