import {ViewStyle, StyleProp} from 'react-native';
import React from 'react';
import Button, {IButtonProps} from 'design-system/src/Components/Button/Button';
import Metrics from 'design-system/src/Theme/Metrics';

interface ISocialItemButtonProps
  extends Omit<IButtonProps, 'container' | 'style' | 'variant' | 'size'> {
  style?: StyleProp<ViewStyle>;
}

const SocialItemButton = (props: ISocialItemButtonProps) => {
  const {style, ...rest} = props;
  return (
    <Button
      buttonColor="#FFFFFF"
      containerStyle={[
        {
          width: '100%',
        },
        style,
      ]}
      iconColor="#FFFFFF"
      style={{
        width: '100%',
      }}
      variant="outlined"
      borderColor="#FFFFFF33"
      size="medium"
      {...rest}
    />
  );
};

export default SocialItemButton;
