import {View} from 'react-native';
import React from 'react';
import Metrics from 'design-system/src/Theme/Metrics';
import {IUcImageFragmentFragment} from '~Api/Graphql/gql/types.generated';
import Typography from 'design-system/src/Components/Typography/Typography';
import AutoScaleImage from 'design-system/src/Components/AutoScaleImage/AutoScaleImage';

// Define the interface for UcImage component props
interface IUcImageProps {
  content: IUcImageFragmentFragment;
}

// Define the UcImage functional component
const UcImage = (props: IUcImageProps) => {
  // Destructure the content prop to get image and text
  const {
    content: {image, text},
  } = props;
  return (
    // Render a View component with marginTop and alignItems styles
    <View style={{marginTop: Metrics.verticalScale(24), alignItems: 'center'}}>
      {/* Conditionally render the Typography component if text exists */}
      {!!text && (
        <Typography
          variant="body2"
          children={text}
          style={{
            marginTop: Metrics.verticalScale(32),
            marginBottom: Metrics.verticalScale(32),
          }}
        />
      )}
      {/* Render the AutoScaleImage component with the provided props */}
      <AutoScaleImage
        uri={image?.url || ''}
        initWidth={Metrics.horizontalScale(300)}
        initHeight={Metrics.verticalScale(327 * (9 / 16))}
        hasHeight
        style={{
          borderRadius: Metrics.horizontalScale(4),
        }}
        resizeMode="contain"
      />
    </View>
  );
};

// Export the UcImage component as the default export
export default UcImage;
