import React, {FC, useEffect, useState} from 'react';
import {IHelpdeskCateg, useHelpdeskLogic} from './ProfileHelpdeskLogic';
import {View, Pressable} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import usePushUtils from '@src/Umbraco/Hooks/usePushUtils';
import ProfileLink from 'design-system/src/Components/ProfileLink/ProfileLink.web';
import ProfileHelpdeskTopic from './ProfileHelpdeskTopic/ProfileHelpdeskTopic';
import {IFaqItemFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import ProfileHelpdeskAnswer from './ProfileHelpdeskAnswer/ProfileHelpdeskAnswer';
import Icon from 'design-system/src/Components/Icon/Icon';
import {useAppDispatch} from '@src/Store/hooks';
import appSlice from '@src/Store/Slices/AppSlice';

const ProfileHelpdesk: FC = () => {
  const {pushes, topics, loading} = useHelpdeskLogic();
  const {t} = useTranslation('helpdesk');
  const {renderPush} = usePushUtils();
  const [topicSelected, setTopicSelected] = useState<IHelpdeskCateg | null>(
    null,
  );
  const [step, setStep] = useState(0);
  const [faqSelected, setFaqSelected] =
    useState<IFaqItemFragmentFragment | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      appSlice.actions.setDrawerGoBack(
        step > 0
          ? () => {
              setStep(step - 1);
            }
          : undefined,
      ),
    );
    return () => {
      dispatch(appSlice.actions.setDrawerGoBack(undefined));
    };
  }, [step]);

  const renderHelpdeskScreen = () => {
    return (
      <View style={[loading && {flex: 1}]}>
        <Typography variant="h2">{t('Need help')}</Typography>
        {loading && (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <CustomActivityIndicator />
          </View>
        )}
        {!loading && (
          <>
            <View
              style={{
                marginTop: Metrics.verticalScale(36),
                marginBottom: Metrics.horizontalScale(24),
              }}>
              {pushes.map((push, index) => (
                <React.Fragment key={`push-${index}`}>
                  {renderPush(push, {
                    containerStyle: {padding: 0},
                    imageStyle: {
                      height: Metrics.verticalScale(56),
                      marginTop: Metrics.verticalScale(10),
                      alignSelf: 'center',
                    },
                  })}
                </React.Fragment>
              ))}
            </View>
            {topics.map((topic, index) => (
              <ProfileLink
                key={`b-${index}`}
                title={topic.name || ''}
                withBorderTop={index === 0}
                onPress={() => {
                  setTopicSelected(topic);
                  setStep(step + 1);
                }}
              />
            ))}
          </>
        )}
      </View>
    );
  };

  return (
    <div
      style={{
        position: 'relative',
      }}>
      {step !== 0 && (
        <Pressable
          style={{
            position: 'absolute',
            top: '-54px',
            left: 0,
            zIndex: 99999,
          }}
          onPress={() => {
            setStep(step - 1);
          }}>
          <Icon name="retour" size={'24'} />
        </Pressable>
      )}
      {step === 0 && renderHelpdeskScreen()}
      {step === 1 && topicSelected && (
        <ProfileHelpdeskTopic
          faqs={topicSelected.faqs}
          title={topicSelected.name ?? ''}
          onClickOnTopic={faq => {
            setFaqSelected(faq);
            setStep(step + 1);
          }}
        />
      )}
      {step === 2 && faqSelected && <ProfileHelpdeskAnswer {...faqSelected} />}
    </div>
  );
};

export default ProfileHelpdesk;
