import React, {PropsWithChildren} from 'react';
import {StyleSheet, Pressable, PressableProps} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Icon from '../../Icon/Icon';
import {iconWithTextStyles} from '../../IconWithText/IconWithText.styles';
import Typography, {typographyColor} from '../../Typography/Typography';

interface IconTextButtonProps extends PressableProps {
  text: string;
  textColor?: keyof typeof typographyColor | (() => string);
  iconColor?: string;
  icon: IconName | React.ReactNode;
}
export const IconTextButton: React.FC<
  PropsWithChildren<IconTextButtonProps>
> = props => {
  const {text, textColor, iconColor, icon, style, ...restProps} = props;
  return (
    <Pressable
      style={StyleSheet.flatten([iconWithTextStyles.container, style])}
      {...restProps}>
      {!React.isValidElement(icon) ? (
        <Icon color={iconColor} name={icon as IconName} />
      ) : (
        icon
      )}
      <Typography
        children={text}
        style={StyleSheet.flatten([iconWithTextStyles.text])}
        color={textColor}
        variant="body2"
      />
    </Pressable>
  );
};

export default IconTextButton;
