import BlockSlider, {
  IBlockSliderItem,
  IBlockSliderProps,
} from 'design-system/src/Components/Card/HighlightedCarousel/BlockSlider/BlockSlider.web';
import React, {FC} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {
  IMainContentListFragmentFragment,
  Maybe,
} from '~Api/Graphql/gql/types.generated';
import {RouteTypename} from '~Navigation/type';
import {IAccessTypParam, IPaywallParam} from '../PaywallChecker/PaywallChecker';

export interface IContentSliderItem extends IBlockSliderItem {
  url: string;
  id: string;
  __typename: RouteTypename;
  countriesRestriction?: Maybe<string>[];
  accessData: {
    accessType?: IAccessTypParam;
    paywall?: IPaywallParam;
  };
  raw?: IMainContentListFragmentFragment;
}
export interface IContentSliderProps
  extends Omit<IBlockSliderProps<IContentSliderItem>, 'premiumText'> {}

const ContentSlider: FC<IContentSliderProps> = props => {
  const {onSeeMore, seeMore, ...rest} = props;
  const {t} = useTranslation('translation');

  return (
    <View style={{backgroundColor: '#111216'}}>
      <BlockSlider
        seeMore={
          seeMore ||
          (onSeeMore && {label: t('See more'), onPress: onSeeMore}) ||
          undefined
        }
        premiumText={t('premium') + ''}
        {...rest}
      />
    </View>
  );
};

export default ContentSlider;
