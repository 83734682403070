import React from 'react';
import FastImage, {FastImageProps} from 'react-native-fast-image';
import Image, {IImageProps} from '../Image/Image';
import {avatarCircleStyles} from './AvatarCircle.styles';

interface AvatarCirclePorps extends Omit<IImageProps, 'source' | 'style'> {
  source: IImageProps['source'];
}

const AvatarCircle = (props: AvatarCirclePorps) => {
  const {source} = props;

  return <Image style={avatarCircleStyles.container} source={source} />;
};

export default AvatarCircle;
