import CategoryPage from '@src/Umbraco/Pages/CategoryPage/CategoryPage';
import React, {useEffect} from 'react';
import {useLocation} from '@reach/router';
import queryString from 'query-string';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import {RouteSource} from '@src/Navigation/type';

const RadioCategoryPage: React.FC = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const tracker = useTracker();
  const {id} = params || {};

  useEffect(() => {
    tracker.general.contentCategoryViewed({
      __typename: 'RadioCateg',
      source: (params?.source as RouteSource) || 'browse',
    });
  }, [params?.source]);

  return (
    <CategoryPage
      activeId={id as string}
      regionFilter={true}
      type="RadioCateg"
    />
  );
};

export default RadioCategoryPage;
