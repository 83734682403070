import {ISectionPageTopMenuFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {getLinkPickerUrl} from './element/getLinkPickerUrl';

export const mapTopMenuLink = (
  topMenu: ISectionPageTopMenuFragmentFragment['topMenu'],
) => {
  let children: Array<{title?: string; url?: string}> = [];
  let itemsTitle = '';

  if (topMenu) {
    topMenu.forEach(menu => {
      if (menu && menu.content.__typename === 'LinkPicker') {
        const {title = '', link} = menu.content;
        const {url} = getLinkPickerUrl(menu.content);

        if (
          link &&
          link[0] &&
          link[0].content.__typename !== 'ContentLink' &&
          url
        ) {
          children.push({
            url,
            title,
          });
        }
      } else if (menu && menu.content.__typename === 'ExpandMenu') {
        itemsTitle = menu.content.title ?? '-';
        menu.content.links?.forEach(link => {
          if (link && link.content.__typename === 'LinkPicker') {
            const {title = ''} = link.content;
            const {url} = getLinkPickerUrl(link.content);
            if (url) {
              children.push({
                url,
                title,
              });
            }
          }
        });
      }
    });
    return {
      title: itemsTitle,
      children,
    };
  }
  return [];
};
