import PartnersGrid from '@src/App/components/Common/AppGrid/PartnersGrid/PartnersGrid';
import useParams from '@src/Hooks/useParams';
import {IPartenerSliderItem} from '@src/Umbraco/Components/PartnerSlider/PartnerSlider';
import useAppNavigation from '@src/utils/useAppNavigation';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Metrics from 'design-system/src/Theme/Metrics';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {
  IHeroVideoCoverFragmentFragment,
  usePartnersPageByIdQuery,
} from '~Api/Graphql/gql/types.generated';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

interface PartnerItem {
  logo: string;
  url: string;
  id: string;
}

export default function PartnersPage() {
  const params = useParams();

  const {navigate} = useAppNavigation();

  const {isMobile} = useBreakpoint();

  const {id} = params || {};
  const [t] = useTranslation(['learn']);
  const response = usePartnersPageByIdQuery({
    variables: {
      id,
    },
  });

  const {
    allPartners,
    title,
    subtitle,
    cover,
  }: {
    allPartners: Array<IPartenerSliderItem>;
    title?: string;
    subtitle?: string;
    cover?: string;
  } = useMemo(() => {
    const {data} = response;
    let partners: Array<IPartenerSliderItem> = [];
    if (
      data &&
      data.partnersPage &&
      data.partnersPage.__typename === 'PartnersPage'
    ) {
      const {title, subtitle, list, cover} = data.partnersPage;
      let coverUrl = cover
        ? (cover[0]?.content as IHeroVideoCoverFragmentFragment)
        : undefined;
      if (list && list[0] && list[0].__typename === 'Partner') {
        list.map(item => {
          if (
            item &&
            item.__typename === 'Partner' &&
            item.logo &&
            item.logo.url
          ) {
            const {logo, id, url, __typename} = item;
            partners.push({
              id,
              url: url || '',
              __typename,
              partnerLogo: logo && logo.url ? logo.url : '',
              accessData: {
                accessType: undefined,
                paywall: undefined,
              },
            });
          }
        });
      }

      return {
        title,
        subtitle,
        cover: coverUrl?.image?.url,
        allPartners: partners,
      };
    }

    return {allPartners: partners};
  }, [response]);
  const loading = response.loading;

  return (
    <div>
      <DebugUmbracoId umbracoId={id} position="page" />
      {loading ? (
        <div
          style={{
            height: '100%',
            display: '100%',
          }}>
          <CustomActivityIndicator
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </div>
      ) : (
        <>
          <ServiceHeader
            image={{
              uri: cover,
            }}
            title={title || ''}
            description={subtitle}
            gradiantVariant="bottom"
          />
          <View
            style={{
              paddingBottom: Metrics.verticalScale(isMobile ? 0 : 55),
              paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
            }}>
            {allPartners.length !== 0 ? (
              <PartnersGrid data={allPartners} />
            ) : (
              <ContentEmpty title={t('No avalaible Parteners')} />
            )}
          </View>
        </>
      )}
    </div>
  );
}
