import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React from 'react';
import {View} from 'react-native';

export interface UnitItem {
  score: number;
  total: number;
  name: string;
  hasMarg?: boolean;
}
const UnitResult = (props: UnitItem) => {
  const {score, total, name, hasMarg} = props;
  return (
    <View
      style={{
        paddingTop: Metrics.verticalScale(10),
        paddingBottom: Metrics.verticalScale(12),
        flexDirection: 'row',
        borderTopWidth: hasMarg ? 1 : 0,
        borderBottomWidth: 1,
        borderColor: 'rgba(151, 151, 151, 0.3)',
        paddingHorizontal: Metrics.horizontalScale(7),
      }}>
      <View
        style={{
          flexDirection: 'row',
          flexBasis: Metrics.horizontalScale(55),
        }}>
        <Typography variant="h2">{score}</Typography>
        <Typography
          variant="body3"
          color="gray"
          style={{marginLeft: Metrics.horizontalScale(9)}}>
          /{total}
        </Typography>
      </View>
      <View
        style={{
          justifyContent: 'center',
          flex: 1,
        }}>
        <Typography variant="body3">{name}</Typography>
      </View>
    </View>
  );
};

export default UnitResult;
