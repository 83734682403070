import React from 'react';
import {View} from 'react-native';
import useBreakpoint from '../../Grid/hooks/useBreakpoint';
import ImageWithBackgroundShadow from 'design-system/src/WebComponent/ImgeWithBackgroundShadow/ImageWithBackgroundShadow.web';

interface IGallerieContainerProps {
  galeries?: {url?: string}[];
}

const GallerieContainer = (props: IGallerieContainerProps) => {
  const {galeries} = props;

  const {isMobile} = useBreakpoint();
  if (!galeries || galeries.length == 0) return null;

  const firstGaleries = galeries!.slice(galeries!.length / 2, galeries!.length);
  const secondGaleries = galeries!.slice(0, galeries!.length / 2);

  return (
    <View>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          animation: 'horizontalScroll 10s infinite',
          width: '100%',
        }}>
        {firstGaleries.map((item, index) => (
          <ImageWithBackgroundShadow
            key={index}
            opacity={0.4}
            blurFilter={30}
            image={item.url ?? ''}
            containerStyle={{
              width: isMobile ? 195 : 321,
              height: isMobile ? 112 : 184,
              borderRadius: 4,
              marginRight: 10,
            }}
            imageStyle={{
              borderRadius: 4,
              marginRight: 10,
            }}
          />
        ))}
      </div>
      {secondGaleries.length > 0 && (
        <>
          <div style={{marginTop: 10}} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              animation: 'horizontalScrollInverse 10s infinite',
              width: '100%',
            }}>
            {secondGaleries.map((item, index) => (
              <ImageWithBackgroundShadow
                key={index}
                opacity={0.4}
                blurFilter={30}
                image={item.url ?? ''}
                containerStyle={{
                  width: isMobile ? 195 : 321,
                  height: isMobile ? 112 : 184,
                  borderRadius: 4,
                  marginRight: 10,
                }}
                imageStyle={{
                  borderRadius: 4,
                  marginRight: 10,
                }}
              />
            ))}
          </div>
        </>
      )}
    </View>
  );
};

export default GallerieContainer;
