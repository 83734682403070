import Icon from 'design-system/src/Components/Icon/Icon';
import Metrics from 'design-system/src/Theme/Metrics';
import useDefaultTheme from 'design-system/src/Theme/useTheme';
import React from 'react';
import {Pressable, StyleSheet, ViewStyle, StyleProp, View} from 'react-native';
import {IconName} from 'design-system/src/assets/Icones';
import Typography from 'design-system/src/Components/Typography/Typography';

interface IHeaderActionButtonProps {
  active?: boolean;
  color?: string;
  onPress?: () => void;
  icon: IconName;
  style?: StyleProp<ViewStyle>;
  badgeCount?: number;
}

const HeaderActionButton = (props: IHeaderActionButtonProps) => {
  const {active, color, icon, style, badgeCount, onPress} = props;
  const theme = useDefaultTheme();
  const textColor = color || theme.white;
  return (
    <Pressable
      onPress={onPress}
      style={[
        HeaderActionButtonStyles.root,
        active && {
          backgroundColor: theme.primaryNavBar,
        },
        style,
      ]}>
      <Icon
        name={icon}
        size="24"
        color={active ? theme.primary : textColor}
        style={HeaderActionButtonStyles.icon}
      />
      {!!badgeCount && (
        <View
          style={{
            position: 'absolute',
            right: Metrics.horizontalScale(-8),
            top: Metrics.verticalScale(-4),
            width: Metrics.horizontalScale(20),
            height: Metrics.horizontalScale(20),
            backgroundColor: theme.primary,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: Metrics.horizontalScale(10),
          }}>
          <Typography variant="button1">
            {badgeCount > 9 ? '+9' : badgeCount}
          </Typography>
        </View>
      )}
    </Pressable>
  );
};

const HeaderActionButtonStyles = StyleSheet.create({
  root: {
    width: Metrics.verticalScale(36),
    height: Metrics.verticalScale(36),
    borderRadius: Metrics.verticalScale(18),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  icon: {
    marginTop: -2,
  },
});

export default HeaderActionButton;
