import React, {ReactNode} from 'react';
import {
  IAwardCategoryPageFragmentFragment,
  IAwardsArtistFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {View, Pressable} from 'react-native';
import VideoBlock from 'design-system/src/Components/VideoBlock/VideoBlock.web';
import AwardCategoryArtistList from './AwardCategoryArtistList/AwardCategoryArtistList';
import useAwardSetting from '@src/layout/AwardLayout/useAwardSetting';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import AwardCategoryBand from './AwardCategoryBand/AwardCategoryBand';
import useElementRenderer from '@src/Umbraco/Hooks/useElementRenderer';
import useNavigation from '@src/Navigation/useNavigation';

const useArtistServicesPageUtils = () => {
  const {logo} = useAwardSetting();
  const {renderBlock} = useElementRenderer();
  const navigation = useNavigation();

  const renderPage = (
    artistServicesPage: IAwardCategoryPageFragmentFragment,
  ) => {
    const {
      footerBlocks: pageBlocksData,
      title = [],
      description,
      cover,
      categTitle,
      subtitle = [],
      children,
      menu,
      id,
    } = artistServicesPage;

    const pageBlocks: ReactNode[] = [];
    const firstBlock: ReactNode[] = [];

    // Logo
    firstBlock.push(
      <Pressable
        key="logo"
        style={{
          width: '100%',
          alignItems: 'center',
        }}
        onPress={() => navigation.navigate('/awards')}>
        <CustomFastImage
          source={{
            uri: logo,
          }}
          resizeMode="cover"
          width={'100%'}
          height={'100%'}
          wrapperStyles={{
            aspectRatio: 262 / 148,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            maxWidth: 246,
          }}
          backgroundFallbackColor="transparent"
        />
      </Pressable>,
    );

    if (cover && cover[0]?.content.__typename === 'HeroVideoCover') {
      const {image, desktopImage, vimeoHlsUrl, vimeoVideoId} = cover[0].content;
      firstBlock.push(
        <VideoBlock
          key={'video-cover'}
          src={vimeoHlsUrl || ''}
          vimeoId={vimeoVideoId + ''}
          width="100%"
          height="auto"
          poster={image?.url}
          posterDesktop={desktopImage?.url}
        />,
      );
    }

    // add first block
    pageBlocks.push(
      <View
        key="first-block"
        style={{
          paddingTop: 19,
        }}>
        {firstBlock}
      </View>,
    );

    // artist
    if (children && children.items) {
      const _list: IAwardsArtistFragmentFragment[] = [];
      children.items.forEach(artist => {
        if (artist?.__typename === 'AwardsArtist') {
          _list.push(artist);
        }
      });
      pageBlocks.push(
        <AwardCategoryArtistList
          key={'artist-list'}
          menu={menu}
          list={_list}
          subtitle={subtitle as string[]}
          title={title.map<string>(i => i + '')}
          categoryTitle={categTitle}
          categoryId={id}
          description={description}
        />,
      );
    }

    pageBlocksData && pageBlocks.push(...renderBlock(pageBlocksData));

    pageBlocks.push(
      <AwardCategoryBand
        key="left-band"
        position="left"
        name={categTitle || ''}
      />,
    );
    pageBlocks.push(
      <AwardCategoryBand
        key="right-band"
        position="right"
        name={categTitle || ''}
      />,
    );

    return pageBlocks;
  };

  return {renderPage};
};

export default useArtistServicesPageUtils;
