import appSlice from '@src/Store/Slices/AppSlice';
import {useAppSelector} from '@src/Store/hooks';
import ThankYouPage from '@src/Umbraco/Pages/ThankYouPage/ThankYouPage';
import PageModal from '@src/components/general/PageModal/PageModal';
import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';

const ThankYouModal = () => {
  const dispatch = useDispatch();
  const thankYouData = useAppSelector(({app}) => app.thankYouModal);
  const isOpen = useMemo(() => !!thankYouData, [thankYouData]);
  const handleClose = useCallback(() => {
    dispatch(appSlice.actions.closeThankYouModal());
  }, [dispatch]);

  return (
    <PageModal isOpen={isOpen} zIndex={1000} onClose={handleClose}>
      <ThankYouPage
        fullScreen={true}
        data={thankYouData}
        id={thankYouData?.id}
      />
    </PageModal>
  );
};

export default ThankYouModal;
