import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import BarLoader from '../ComponentLoader/BarLoader/BarLoader';
import CircleLoader from '../ComponentLoader/CircleLoader/CircleLoader';
import CarouselLoader from '../HomeContentLoader/CarouselLoader';
import CustomHeaderLoader from '../HomeContentLoader/CustomHeaderLoader';

const SingleContentLoader = () => {
  return (
    <View
      style={Platform.select({
        web: {
          maxWidth: 920,
          width: '100%',
          marginHorizontal: 'auto',
          marginVertical: 0,
          overflow: 'hidden',
        },
      })}>
      <CustomHeaderLoader />
      <View style={styles.barContainer}>
        <BarLoader
          customHeight={Metrics.verticalScale(16)}
          customWidth={Metrics.horizontalScale(343)}
          style={styles.textBar}
        />
        <BarLoader
          customHeight={Metrics.verticalScale(16)}
          customWidth={Metrics.horizontalScale(284)}
          style={styles.textBar}
        />
        <BarLoader
          customHeight={Metrics.verticalScale(16)}
          customWidth={Metrics.horizontalScale(234)}
          style={styles.textBar}
        />
      </View>
      <View style={styles.circleContainer}>
        <CircleLoader />
        <CircleLoader />
        <CircleLoader />
        <CircleLoader />
      </View>
      <View
        style={{
          marginTop: Metrics.verticalScale(70),
        }}>
        <CarouselLoader />
        <CarouselLoader />
      </View>
    </View>
  );
};

export default SingleContentLoader;

const styles = StyleSheet.create({
  textBar: {
    marginBottom: Metrics.verticalScale(7),
  },
  barContainer: {
    paddingHorizontal: Metrics.horizontalScale(16),
    marginTop: Metrics.verticalScale(54),
    marginBottom: Metrics.verticalScale(32),
  },
  circleContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: Metrics.horizontalScale(47),
    justifyContent: 'space-between',
  },
});
