import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {FC} from 'react';
import styled from 'styled-components';

interface IBlockWithBackgroundProps {
  backgroundUrl?: string;
  children?: React.ReactNode;
  aspectRatio?: number | [mobile: number, desktop: number];
  style?: React.CSSProperties;
}

const WrapperStyled = styled('div')<{
  $aspectRatio: IBlockWithBackgroundProps['aspectRatio'];
}>(({$aspectRatio}) => {
  const aspectRatioData: [number | string, number | string] =
    typeof $aspectRatio === 'number'
      ? [$aspectRatio, $aspectRatio]
      : $aspectRatio || ['auto', 'auto'];
  return {
    aspectRatio: aspectRatioData[1],
    backgroundSize: 'cover',
    [`@media ${responsiveMap.mobile}`]: {
      aspectRatio: aspectRatioData[0],
    },
  };
});

const BlockWithBackground: FC<IBlockWithBackgroundProps> = ({
  aspectRatio,
  backgroundUrl,
  children,
  style,
}) => {
  const {isMobile} = useBreakpoint();
  return (
    <WrapperStyled
      $aspectRatio={aspectRatio}
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          background: isMobile
            ? 'linear-gradient(rgba(17, 18, 22, 0) 73.54%, rgba(17, 18, 22, 0.8) 90.45%, rgb(17, 18, 22) 98.32%)'
            : 'linear-gradient(rgba(17, 18, 22, 0) 73%, rgba(17, 18, 22, 0.73) 86%, rgb(17, 18, 22) 96%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          ...style,
        }}>
        {children}
      </div>
    </WrapperStyled>
  );
};

export default BlockWithBackground;
