import {ISearchResponse} from '@src/Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {useUpdateMemberSearch} from '@src/Hooks/useUpdateMemberSearch';
import {useSelecteAllSearch} from '@src/Store/selectors/useMember';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';

interface SuggestionSearchRecentProps {
  onPressItem?: (item: ISearchResponse) => void;
}

export default function SuggestionSearchRecent(
  props: SuggestionSearchRecentProps,
) {
  const {onPressItem} = props;
  const {t} = useTranslation(['search']);
  const listSearch = useSelecteAllSearch();

  let listSearchFiltered = useMemo(() => {
    if (listSearch && listSearch.length) {
      return [...listSearch].reverse().slice(0, 5);
    }
    return [];
  }, [listSearch]);

  const {deleteMemberSearch} = useUpdateMemberSearch();

  return (
    <View
      style={{
        paddingTop: Metrics.verticalScale(0),
      }}>
      <Typography variant="ui3" color="gray">
        {t('search.recent')}
      </Typography>
      <View style={{paddingTop: Metrics.verticalScale(4)}}>
        {listSearchFiltered.map((itemSearch, index) => (
          <div
            key={`ssr-${index}`}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: Metrics.verticalScale(3),
              // paddingBottom: Metrics.verticalScale(19),
              // paddingTop: Metrics.verticalScale(16),
              borderBottomWidth: 1,
              borderBottomColor: 'rgba(115, 115, 115, 0.5)',
              display: 'flex',
            }}>
            <PressableCustom
              style={{
                minHeight: 48,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'center',
                flex: 1,
                // cursor: 'pointer',
              }}
              onPress={() => {
                onPressItem && onPressItem(itemSearch as any);
              }}>
              {({hover}) => (
                <Typography variant="body2" color={hover ? 'gray' : undefined}>
                  {itemSearch.text}
                </Typography>
              )}
            </PressableCustom>
            <div
              style={{
                height: 48,
                width: 48,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                cursor: 'pointer',
              }}
              onClick={e => {
                e.stopPropagation();
                deleteMemberSearch(itemSearch._id);
              }}>
              <Icon name="radio-off" size="16" color="#737373" />
            </div>
          </div>
        ))}
      </View>
    </View>
  );
}
