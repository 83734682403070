import Button from 'design-system/src/Components/Button/Button';
import TextInputController from 'design-system/src/Components/Form/TextInputController/TextInputController';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Pressable} from 'react-native';
import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
import {useAppDispatch} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {toast} from 'react-toastify';
import appSlice from '@src/Store/Slices/AppSlice';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import useNavigation from '@src/Navigation/useNavigation';

interface ISignInForm {
  email: string;
  password: string;
}

interface ISignInFormProps {
  onSuccess?: () => void;
}

// ato ny solution

const SignInForm = (props: ISignInFormProps) => {
  const {onSuccess} = props;
  const {t} = useTranslation('auth');
  const {control, handleSubmit} = useForm<ISignInForm>();
  const tracker = useTracker();
  const rootNavigation = useNavigation();

  const {signIn} = useFireBaseAuthentication();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleSignIn = async (data: ISignInForm) => {
    setLoading(true);
    const response = await signIn(data);

    if (response.status === 'error') {
      toast.error(response.errorMessage);
      setLoading(false);
      return;
    } else if (response.status === 'success') {
      dispatch(appAuthSlice.actions.resetAuth());
      tracker.general.loggedIn({account_type: 'email'});
      dispatch(appSlice.actions.setAccountType('email'));
      onSuccess?.();
    }
    setLoading(false);
  };

  const handleError = (error: any) => {
    console.log(error);
  };

  const handleActivateAccount = () => {
    dispatch(appAuthSlice.actions.setAuthType('activateAccount'));
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <TextInputController
        control={control}
        name="email"
        label={t('Email') + ''}
        rules={{
          required: t('Email is required') + '',
        }}
        placeholder={t('your@email.com') || ''}
        // placeholderTextColor={'#fff'}
        style={{marginBottom: Metrics.verticalScale(30)}}
        variant="transparent"
      />
      <TextInputController
        label={t('Password') + ''}
        control={control}
        name="password"
        rules={{
          required: t('Password is required') + '',
        }}
        style={{marginBottom: Metrics.verticalScale(32)}}
        variant="transparent"
        secureTextEntry={true}
      />
      <Button
        variant="contained"
        size="small"
        title={t('Login')}
        fullWidth
        loading={loading}
        onPress={handleSubmit(handleSignIn, handleError)}
      />
      <div style={{marginTop: 34, display: 'flex', zIndex: 2}}>
        <Typography variant="body3">{t('New member ?')}</Typography>
        <div style={{marginLeft: 6, cursor: 'pointer'}}>
          <Pressable
            onPress={() =>
              dispatch(appAuthSlice.actions.setAuthType('register'))
            }>
            <Typography variant="body3" color="primary">
              {t('auth.register.text')}
            </Typography>
          </Pressable>
        </div>
      </div>
      <div style={{marginTop: 14}}>
        <Pressable
          onPress={() =>
            dispatch(appAuthSlice.actions.setAuthType('forgotPassword'))
          }>
          <Typography variant="body3">{t('Forgot your password?')}</Typography>
        </Pressable>
      </div>

      <div style={{marginTop: 34}}>
        <Pressable
          style={{
            alignItems: 'center',
          }}
          onPress={handleActivateAccount}>
          <Typography variant="button3" color={'white'}>
            {t('Coming from Trace Academia?')}
          </Typography>
          <Typography variant="button3" color={() => '#2f82ff'}>
            {t('Activate your account')}
          </Typography>
        </Pressable>
      </div>
    </div>
  );
};

export default SignInForm;
