import {QueryHookOptions} from '@apollo/client';
import {
  IContentIdByEndUrlQuery,
  IContentIdByEndUrlQueryVariables,
  useContentIdByEndUrlQuery,
} from '@src/Api/Graphql/gql/types.generated';

const useContentIdByEndUrl = (
  options: QueryHookOptions<
    IContentIdByEndUrlQuery,
    IContentIdByEndUrlQueryVariables
  >,
) => {
  const {data: contentData, loading} = useContentIdByEndUrlQuery(options);

  const getIdFromContentData = (
    contentData: IContentIdByEndUrlQuery | undefined,
  ) => {
    if (contentData?.en?.items?.[0]?.id) {
      return contentData.en.items[0].id;
    } else if (contentData?.fr?.items?.[0]?.id) {
      return contentData.fr.items[0].id;
    } else if (contentData?.pt?.items?.[0]?.id) {
      return contentData.pt.items[0].id;
    }
    return null;
  };

  const id = getIdFromContentData(contentData);
  return {id: id || undefined, loading};
};

export default useContentIdByEndUrl;
