// import {useNavigation} from '@react-navigation/core';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {ICourseUnitFragmentFragment} from '~Api/Graphql/gql/types.generated';
import TestScreenContainer from '../../TestScreen/TestScreenContainer';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface IUnitOverViewProps {
  onNext?: () => void;
  unit: ICourseUnitFragmentFragment;
}

export default function UnitOverView(props: IUnitOverViewProps) {
  const {onNext, unit} = props;
  const {t} = useTranslation('unit');
  const {isMobile} = useBreakpoint();

  const {
    title,
    description,
    totalVideo,
    totalQuizz,
  }: {
    title: string;
    description: string;
    totalVideo: number;
    totalQuizz: number;
  } = useMemo(() => {
    let _totalQuizz = 0;
    let _totalVideo = 0;
    unit.pages?.forEach(page => {
      if (page && page.content) {
        if (page.content.__typename === 'CoursePage') {
          page.content.blocks?.forEach(block => {
            if (block?.content?.__typename === 'UcVideo') {
              _totalVideo += 1;
            }
          });
        } else if (page.content.__typename === 'CourseNugget') {
          _totalQuizz += page.content.nuggets?.length || 0;
        }
      }
    });
    return {
      title: unit.title || '',
      description: unit.description || '',
      totalVideo: _totalVideo,
      totalQuizz: _totalQuizz,
    };
  }, [unit]);

  return (
    <TestScreenContainer
      buttonTitle={t('Start') as string}
      onPressButton={onNext}
      containerStyle={
        {
          // width: '100%',
          // maxWidth: 621 + 48,
          // marginHorizontal: 'auto',
        }
      }
      //   onPressButton={() => navigation.navigate('Step2Screen')}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography
          variant={isMobile ? undefined : 'big2'}
          style={{maxWidth: isMobile ? 285 : 621, textAlign: 'center'}}>
          {title}
        </Typography>
        <Typography
          variant="h3"
          children={t('Overview')}
          style={{
            marginTop: Metrics.verticalScale(32),
            marginBottom: description ? Metrics.verticalScale(13) : undefined,
            textAlign: 'center',
          }}
        />
        <Typography
          color={() => 'gray'}
          variant="body2"
          style={{
            maxWidth: isMobile ? undefined : 596,
            textAlign: 'center',
          }}>
          {description}
        </Typography>
        {totalVideo !== 0 && totalQuizz !== 0 && (
          <Typography
            variant="h4"
            style={{
              marginTop: Metrics.verticalScale(50),
              textAlign: 'center',
            }}>
            {t('tasks to complete')}
          </Typography>
        )}
      </View>

      {/* TODO: Tasks to complete */}
      <View
        style={{
          flexDirection: 'row',
          marginTop: Metrics.verticalScale(29),
          justifyContent: 'center',
        }}>
        {totalVideo !== 0 && (
          <View
            style={{
              flexBasis: Metrics.horizontalScale((375 - 48) / 2),
              alignItems: 'center',
              borderRightWidth: totalQuizz ? 1 : undefined,
              borderRightColor: totalQuizz
                ? 'rgba(255, 255, 255, 0.2)'
                : undefined,
            }}>
            <Icon name="videos" size="48" />
            <Typography
              variant="body2"
              style={{marginTop: Metrics.verticalScale(10)}}>
              {t('{{count}} Videos', {count: totalVideo})}
            </Typography>
          </View>
        )}
        {totalQuizz !== 0 && (
          <View
            style={{
              flexBasis: Metrics.horizontalScale((375 - 48) / 2),
              alignItems: 'center',
            }}>
            <Icon name="quizzes" size="48" />
            <Typography
              variant="body2"
              style={{marginTop: Metrics.verticalScale(10)}}>
              {t('{{count}} Quizzes', {count: totalQuizz})}
            </Typography>
          </View>
        )}
      </View>
    </TestScreenContainer>
  );
}
