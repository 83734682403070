import React from 'react';
import {Image, View} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Images from '../../../assets/Images';
import Metrics from '../../../Theme/Metrics';
import Icon from '../../Icon/Icon';
export type CardBlurIconProps = {
  icon: IconName;
};
const CardBlurIcon = (props: CardBlurIconProps) => {
  return (
    <View style={{alignItems: 'center', justifyContent: 'center'}}>
      <Image
        style={{
          width: Metrics.horizontalScale(72),
          height: Metrics.horizontalScale(72),
        }}
        source={Images.quizBg}
      />
      <Icon
        name={props.icon}
        style={{
          position: 'absolute',
        }}
      />
    </View>
  );
};

export default CardBlurIcon;
