import React, {useMemo} from 'react';
import {StyleProp, TextStyle, View, ViewStyle} from 'react-native';
import Icon, {IIconProps} from '../Icon/Icon';
import Typography from '../Typography/Typography.web';
import {tagsStyles, tagVariantStyles} from './Tags.styles';
import {GradianTagProps} from './Tags';
import LinearGradient from 'react-native-linear-gradient';

export type TagVariant = keyof typeof tagVariantStyles;

export interface TagsProps {
  text: string;
  variant?: TagVariant;
  icon?: Omit<IIconProps, 'color'>;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  hideEllipse?: boolean;
}

const Tags = (props: TagsProps) => {
  const {text, variant = 'live', icon, style, hideEllipse, textStyle} = props;
  const {color, backgroundColor} =
    tagVariantStyles[variant] || tagVariantStyles['course'];
  const tagsText: string = (text || '').toUpperCase();
  const gradianProps: GradianTagProps | undefined = useMemo(() => {
    if (variant === 'quizz') {
      return {
        colors: ['#33A0BD', '#593C90'],
        locations: [0, 0.94],
        angle: 71.91,
      };
    } else if (variant === 'collection') {
      return {
        colors: ['#1C5C7A', '#2581AC'],
        locations: [0, 1],
        angle: 47.51,
      };
    }
  }, [variant]);

  const content = useMemo(() => {
    return (
      <>
        {!hideEllipse ? (
          icon ? (
            <Icon {...icon} color={color} />
          ) : (
            <View
              style={{
                ...tagsStyles.ellipse,
                backgroundColor: color,
              }}
            />
          )
        ) : undefined}
        <Typography
          variant="ui2"
          style={{
            ...tagsStyles.text,
            ...(textStyle as any),
            color: color,
          }}>
          {tagsText}
        </Typography>
      </>
    );
  }, [hideEllipse, color, tagsText, textStyle, tagsStyles]);

  return (
    <View style={[tagsStyles.tagsContainer, style]}>
      {!!gradianProps ? (
        <LinearGradient style={tagsStyles.container} useAngle {...gradianProps}>
          {content}
        </LinearGradient>
      ) : (
        <View
          style={{
            ...tagsStyles.container,
            backgroundColor,
          }}>
          {content}
        </View>
      )}
    </View>
  );
};

export default Tags;
