// import {Alert} from 'react-native';
import {memberTransformer} from '~Api/Umbraco/ContentManagmentApi/endpoints/members.endpoint';
import {Member} from '~Api/Umbraco/interface/Member.interface';
import memberSlice from '~Store/Slices/Member.slice';
import {IMemberDto} from '../interface/umbraco.interface';
import {TraceApiEndpointBuilder} from '../traceApi';
import {tracker} from '~Services/Amplitude/hooks/tracker';

export const createUmbracoEndpoint = (builder: TraceApiEndpointBuilder) => ({
  createMember: builder.query<IMemberDto, IMemberDto>({
    query: body => ({
      url: '/umbraco/member',
      method: 'POST',
      body,
    }),
  }),
  removeMember: builder.query<IMemberDto, void>({
    query: () => ({
      url: '/umbraco/member/',
      method: 'DELETE',
    }),
  }),
  putUmbracoMember: builder.query<Member, {username: string; data: Member}>({
    forceRefetch({currentArg, previousArg}) {
      return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
    },
    query: ({username, data}) => ({
      url: `/umbraco/member/${username}`,
      method: 'PUT',
      body: {...data, memberDatasId: undefined},
    }),
    transformResponse(response: Member) {
      return memberTransformer(response);
    },
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      try {
        const {data} = await queryFulfilled;
        dispatch(memberSlice.actions.setMember(data));
      } catch (err) {
        tracker.errorEncountered('umbraco', 'update member');
      }
    },
  }),
});
