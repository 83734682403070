import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {darkTheme} from 'design-system/src/styles/Themes';
import React from 'react';
import {View} from 'react-native';
// @ts-ignore
import backgroundCard from '../../../../../images/cardBg.png';

export interface ICardItem {
  title?: string;
  description?: string;
  image?: string;
}

interface ICardItemProps {
  item?: ICardItem;
  isTakeFirst?: boolean;
}

const CardItem = (props: ICardItemProps) => {
  if (!props.item) return null;

  const {title, description, image} = props.item;

  const {isTakeFirst} = props;
  const {isMobile} = useBreakpoint();

  return (
    <div
      style={{
        paddingTop: 49,
        paddingLeft: 36,
        borderRadius: 8,
        backgroundColor: '#1B1C21',
        gridColumn: isTakeFirst && !isMobile ? 'span 2' : 'span 1',
        display: 'flex',
        flexDirection: isTakeFirst && !isMobile ? 'row' : 'column',
        justifyContent: 'space-between',
        backgroundImage:
          isTakeFirst && !isMobile ? `url(${backgroundCard})` : undefined,
        backgroundSize: 'cover',
      }}>
      <View
        style={{
          width: isTakeFirst && !isMobile ? '40%' : isMobile ? '90%' : '80%',
          // flex: 1,
        }}>
        {!!title && (
          <Typography variant="h3" style={{marginBottom: 15}}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            variant="body3"
            style={{color: darkTheme.colors.fontGray}}>
            {description}
          </Typography>
        )}
      </View>
      <View
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: isTakeFirst && !isMobile ? undefined : 44,
          width: isTakeFirst && !isMobile ? '50%' : '100%',
        }}>
        {!!image && (
          <CustomFastImage
            source={{uri: image}}
            width="100%"
            height="100%"
            isNotSetContainerHeight={true}
            wrapperStyles={{
              aspectRatio: isTakeFirst ? 1 : 396 / 303,
              width: '100%',
              borderTopLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
          />
        )}
      </View>
    </div>
  );
};

export default CardItem;
