import {ReactNode} from 'react';
import {
  IChoiceNuggetFragmentFragment,
  IGapfillNuggetFragmentFragment,
  ISummaryNuggetFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import ChoiceNugget from '../UnitScreen/UnitPages/CourseNugget/ChoiceNugget/ChoiceNugget';
import UcAudio from '../UnitScreen/UnitPages/CoursePage/UcAudio/UcAudio';
import UcImage from '../UnitScreen/UnitPages/CoursePage/UcImage/UcImage';
import UcVideo from '../UnitScreen/UnitPages/CoursePage/UcVideo/UcVideo';
import {RadioButtonItem} from 'design-system/src/Components/RadioButton/RadioButtonItem/RadioButtonItem';
import {NuggetDataProgress} from '~Store/Slices/CourseUnitSlice/CourseUnit.slice';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import GapfillNugget, {
  GapfillNuggetData,
  GapfillValueType,
} from '../UnitScreen/UnitPages/CourseNugget/GapfillNugget/GapfillNugget';
import SummaryNugget from '../UnitScreen/UnitPages/CourseNugget/SummaryNugget/SummaryNugget';
import React from 'react';

interface IMapNuggetProps {
  nuggetProgression: NuggetDataProgress;
  showResult?: boolean;
  property?: ILearnPropertyName;
  onChange?: (isCorrect?: boolean) => void;
  handleChange: (
    nuggetId: string,
  ) => (data: NuggetDataProgress, isCorrect?: boolean) => void;
}

export const mapNugget = (
  item:
    | IChoiceNuggetFragmentFragment
    | IGapfillNuggetFragmentFragment
    | ISummaryNuggetFragmentFragment,
  index: number,
  props: IMapNuggetProps,
) => {
  const {showResult, nuggetProgression, property, handleChange, onChange} =
    props;
  if (item.__typename === 'ChoiceNugget') {
    const options: RadioButtonItem[] = [];
    let correctCount = 0;
    item.options?.forEach(option => {
      if (
        option &&
        option.content &&
        option.content.__typename === 'ChoiceNuggerAnswer'
      ) {
        const {
          value,
          description,
          isCorrect,
          feedback,
          image: _image,
        } = option.content;
        const _value = _image?.cropUrl || _image?.url || value || '';
        options.push({
          label: _value || '',
          value: _value || '',
          description: description,
          isCorrect: isCorrect || false,
          feedback: feedback || '',
          image: _image?.cropUrl || _image?.url,
        });
        if (isCorrect) {
          correctCount += 1;
        }
      }
    });

    let _title = '';
    let _description = '';
    let _renderBlock: ReactNode[] = [];
    if (item.question) {
      item.question.forEach((_question, index) => {
        if (_question && _question.content) {
          const _content = _question.content;
          if (_content.__typename === 'Wysiwyg') {
            _title = _content.title || '';
            _description = _content.body || '';
          } else if (_content.__typename === 'UcAudio') {
            _renderBlock.push(
              <UcAudio key={`${index}-uc`} content={_content} />,
            );
          } else if (_content.__typename === 'UcImage') {
            _renderBlock.push(
              <UcImage key={`${index}-uc`} content={_content} />,
            );
          } else if (_content.__typename === 'UcVideo') {
            _renderBlock.push(
              <UcVideo key={`${index}-uc`} content={_content} />,
            );
          }
        }
      });
    }

    return (
      <ChoiceNugget
        renderBlock={_renderBlock}
        showResult={showResult}
        key={index}
        display={
          correctCount === 1 || options.length === correctCount
            ? 'mode-1'
            : 'mode-2'
        }
        data={options}
        description={_description}
        title={_title}
        value={
          Array.isArray(nuggetProgression)
            ? (nuggetProgression as string[])
            : []
        }
        isMultiple={correctCount > 1}
        genericFeedback={item.genericFeedback}
        positiveFeedback={item.positiveFeedback}
        negativeFeedback={item.negativeFeedback}
        onChange={handleChange(item.id)}
        onCheckCorrect={onChange}
        raw={item}
        property={property}
      />
    );
  } else if (item.__typename === 'GapfillNugget') {
    const {
      title: _title = '',
      body: _description = '',
      gapFillText = '',
      options,
    } = item;
    const choice: GapfillNuggetData['choice'] = {};
    let imageReferences: Record<string, string | undefined> = {};
    if (options) {
      options.forEach(option => {
        if (
          option &&
          option.content &&
          (option.content.__typename === 'GapFillNuggetAnswer' ||
            option.content.__typename === 'GapFillNuggetImageAnswer') &&
          !!option.content.reference
        ) {
          let _refOptions: RadioButtonItem[] = [];
          const _ref = option.content.reference;
          if (option.content.values) {
            option.content.values.forEach(value => {
              if (
                value &&
                value.content &&
                value.content.__typename === 'NuggetsTextAnswerOption'
              ) {
                const _value = value?.content?.value || '';
                _refOptions.push({
                  label: _value,
                  value: _value,
                  isCorrect: value.content.isCorrect,
                  image:
                    value.content.image?.cropUrl || value.content.image?.url,
                  id: `${_ref.trim()}-${_value}`,
                  ref: _ref.trim(),
                });
              }
            });
          }
          choice[option.content.reference.trim()] = _refOptions;
          if (
            option.content.__typename === 'GapFillNuggetImageAnswer' &&
            (option.content.referenceImage?.cropUrl ||
              option.content.referenceImage?.url)
          ) {
            imageReferences[_ref.trim()] =
              option.content.referenceImage?.cropUrl ||
              option.content.referenceImage?.url;
          }
        }
      });
    }
    return (
      <GapfillNugget
        onCheckCorrect={onChange}
        key={index}
        title={_title}
        showResult={showResult}
        description={_description}
        value={
          typeof nuggetProgression === 'object'
            ? (nuggetProgression as GapfillValueType)
            : {}
        }
        onChange={handleChange(item.id)}
        data={{
          text: gapFillText,
          choice: choice,
          imageReferences,
        }}
        raw={item}
        property={property}
        id={item.id}
      />
    );
  }
  const {title: _title = ''} = item;
  return (
    <SummaryNugget
      key={index}
      onCheckCorrect={onChange}
      raw={item}
      onChange={handleChange(item.id)}
      title={_title}
      value={
        typeof nuggetProgression === 'object'
          ? (nuggetProgression as GapfillValueType)
          : {}
      }
      showResult={showResult}
      property={property}
    />
  );
};
