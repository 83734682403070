import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import React from 'react';
import styled from 'styled-components';

const UnitScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
`;

export const UnitScreenLoaderContainer = (props: {
  loading?: boolean;
  children: React.ReactNode;
}) => {
  const {loading, children} = props;
  return (
    <>
      {loading && (
        <UnitScreenContainer>
          <CustomActivityIndicator />
        </UnitScreenContainer>
      )}
      {children}
    </>
  );
};
