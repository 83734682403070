import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import SectionPage from '@src/Umbraco/Pages/SectionPage/SectionPage';
import React from 'react';

const PodCastHomePage: React.FC = () => {
  const {page} = useRegionUrl();
  return (
    <div>
      <SectionPage
        id={page.listen?.children.podcast?.id}
        contentSource="listen"
      />
    </div>
  );
};

export default PodCastHomePage;
