import { useEffect } from 'react';

interface UseMediaSessionProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  title: string;
  poster?: string;
  description?: string;
  isLive?: boolean;
  duration?: number;
  playedSeconds: number;
  playbackRate: number;
  setPlaying: (playing: boolean) => void;
  onVideoPlay?: (isPaused: boolean) => void;
  skip?: (seconds: number) => void;
  handleOnSkipChange?: (isSkip: boolean) => void;
  onNext?: (index: number) => void;
  channelUp?: (callback?: (index: number) => void) => void;
  currentMedia?: number;
  sourceListLength?: number;
}

export const useMediaSession = ({
  videoRef,
  title,
  poster,
  description,
  isLive,
  duration,
  playedSeconds,
  playbackRate,
  setPlaying,
  onVideoPlay,
  skip,
  handleOnSkipChange,
  onNext,
  channelUp,
  currentMedia,
  sourceListLength,
}: UseMediaSessionProps) => {
  // Set up video element metadata
  useEffect(() => {
    if (videoRef.current) {
      // Set video element metadata
      videoRef.current.title = description ?? title;
      // Set content-type for iOS
      videoRef.current.setAttribute('content-type', 'video/mp4');
      // Set additional metadata attributes for iOS
      videoRef.current.setAttribute('artwork', poster || '');
      videoRef.current.setAttribute('artist', 'Trace+');
      videoRef.current.setAttribute('album', description ?? title);
    }
  }, [videoRef, title, description, poster]);

  // Set up media session metadata
  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: description ?? title,
        artist: 'Trace+',
        album: description ?? title,
        artwork: poster ? [
          { src: poster, sizes: '512x512', type: 'image/jpeg' },
          { src: poster, sizes: '256x256', type: 'image/jpeg' },
          { src: poster, sizes: '96x96', type: 'image/jpeg' }
        ] : undefined
      });

      // Play handler
      navigator.mediaSession.setActionHandler('play', () => {
        if (videoRef.current?.paused) {
          videoRef.current?.play();
          setPlaying(true);
          onVideoPlay && onVideoPlay(false);
        }
      });

      // Pause handler
      navigator.mediaSession.setActionHandler('pause', () => {
        if (!videoRef.current?.paused) {
          videoRef.current?.pause();
          setPlaying(false);
          onVideoPlay && onVideoPlay(true);
        }
      });

      // Only set up seeking and track change handlers for non-live content
      if (!isLive && skip) {
        navigator.mediaSession.setActionHandler('seekbackward', () => {
          if (videoRef.current) {
            skip(-15);
            handleOnSkipChange && handleOnSkipChange(false);
          }
        });

        navigator.mediaSession.setActionHandler('seekforward', () => {
          if (videoRef.current) {
            skip(15);
            handleOnSkipChange && handleOnSkipChange(true);
          }
        });

        if (channelUp && typeof currentMedia === 'number' && typeof sourceListLength === 'number') {
          navigator.mediaSession.setActionHandler('previoustrack', () => {
            if (currentMedia > 0) {
              channelUp(onNext);
            }
          });

          navigator.mediaSession.setActionHandler('nexttrack', () => {
            if (currentMedia < sourceListLength - 1) {
              channelUp(onNext);
            }
          });
        }
      }
    }

    return () => {
      if ('mediaSession' in navigator) {
        // Clear handlers when component unmounts
        navigator.mediaSession.setActionHandler('play', null);
        navigator.mediaSession.setActionHandler('pause', null);
        navigator.mediaSession.setActionHandler('seekbackward', null);
        navigator.mediaSession.setActionHandler('seekforward', null);
        navigator.mediaSession.setActionHandler('previoustrack', null);
        navigator.mediaSession.setActionHandler('nexttrack', null);
      }
    };
  }, [description, title, poster, isLive, currentMedia, sourceListLength]);

  // Update position state
  useEffect(() => {
    if ('mediaSession' in navigator && !isLive) {
      try {
        // Only set position state if we have valid duration and position values
        if (duration && duration > 0 && playedSeconds >= 0 && playedSeconds <= duration) {
          navigator.mediaSession.setPositionState({
            duration: duration,
            playbackRate: playbackRate,
            position: Math.min(playedSeconds, duration) // Ensure position never exceeds duration
          });
        }
      } catch (error) {
        console.warn('Error updating media session position state:', error);
      }
    }
  }, [playedSeconds, duration, playbackRate, isLive]);
}; 