import {StyleSheet} from 'react-native';

export const DownloadButtonStyle = StyleSheet.create({
  root: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
});
