import {useAppSelector} from '@src/Store/hooks';

export const useSelectIsProfilHomeDrawerOpen = () => {
  return useAppSelector(({app}) => app.isProfilHomeDrawerOpen);
};

export const useSelectIsMyProfileDrawerOpen = () => {
  return useAppSelector(({app}) => app.isProfileMyProfileDrawerOpen);
};

export const useSelectIsOpenProfileEditDrawer = () => {
  return useAppSelector(
    ({app}) => app.profilEditParams?.isOpenProfilEditDrawer,
  );
};

export const useSelectProfileEditParams = () => {
  return useAppSelector(({app}) => app.profilEditParams?.data);
};

export const useSelectIsProfileSettingDrawerOpen = () => {
  return useAppSelector(({app}) => app.isProfileSettingDrawerOpen);
};

export const useSelectIsMenuDrawerOpen = () => {
  return useAppSelector(({app}) => app.isOpenMenuDrawer);
};
