import {IButtonFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import {RouteTypename} from '@src/Navigation/type';
import useNavigation from '@src/Navigation/useNavigation';
import {getLinkUrl} from '@src/Umbraco/Utils/element/getLinkPickerUrl';
import mapButton from '@src/Umbraco/Utils/element/mapButton';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import ServicePromote, {
  IBlockItemTitleSlider,
  IBlockTitleSlider,
} from 'design-system/src/Components/ServicePromote/ServicePromote.web';
import React from 'react';
import {useDispatch} from 'react-redux';

const CTASlider = (props: {content: Queries.CTASliderFragmentFragment}) => {
  const {slides} = props.content;
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const handleClickButtonItem = (item: IBlockItemTitleSlider) => {
    if (item && item.button) {
      new RouteFactory(
        {
          id: item.button.id || '',
          __typename: item.button.__typename as RouteTypename,
          url: item.button.url || '',
        },
        navigation,
        dispatch,
      ).navigate();
    }
  };

  if (slides && slides[0]) {
    let itemSliders: Array<IBlockTitleSlider> = [];

    slides.forEach(itemSlide => {
      if (itemSlide && itemSlide.content.contentTypeAlias === 'CTASlide') {
        let item =
          itemSlide?.content as unknown as Queries.CTASlideFragmentFragment;
        let _cover;
        if (item.cover && item.cover[0]) {
          _cover = item.cover[0]
            .content as Queries.HeroVideoCoverFragmentFragment;
        }
        let _button;
        if (item.button && item.button[0]) {
          _button = mapButton({
            ...item.button[0].content,
            __typename: 'Button',
          } as IButtonFragmentFragment);
        }
        itemSliders.push({
          slideTitle: item.title ?? '',
          slides: [
            {
              title: item.title ?? '',
              description: item.description ?? '',
              image: {
                uri: _cover?.image?.url ?? '',
              },
              // TODO:
              button: _button as any,
            },
          ],
        });
      }
    });

    if (itemSliders.length > 0) {
      return (
        <div
          style={{
            padding: '96px 16px',
          }}>
          <ServicePromote
            title={''}
            items={itemSliders}
            onButtonItemPress={handleClickButtonItem}
          />
        </div>
      );
    }
  }
  return null;
};

export default CTASlider;
