import { StyleSheet } from "react-native";
import Metrics from "../../../Theme/Metrics";


export const cardIconTextStyles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        marginLeft: Metrics.horizontalScale(16),
    }
})