import React from 'react';
import {Platform, Pressable, View} from 'react-native';
import Metrics, {height} from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import {ScrollView} from 'react-native-gesture-handler';

export type FeedbackProps = {
  type: 'success' | 'error';
  description: string;
  onClose?(): void;
  hideColor?: boolean;
};

export default function Feedback(props: FeedbackProps) {
  const {type, description, hideColor, onClose} = props;
  return (
    <View
      style={{
        maxHeight: height / 4,
        width: '100%',
        paddingHorizontal: Metrics.horizontalScale(15),
        paddingVertical: Metrics.verticalScale(14),
        backgroundColor: !hideColor
          ? type === 'success'
            ? '#013B28'
            : '#420608'
          : '#16181E',
        borderWidth: 1,
        borderColor: !hideColor
          ? type === 'success'
            ? 'rgba(5, 201, 107, 0.8)'
            : '#BE030A'
          : '#2F3139',
        borderRadius: Metrics.horizontalScale(4),
        flexDirection: 'row',
        position: 'relative',
      }}>
      <>
        <View style={{width: 24}}>
          <Icon
            name={type === 'success' || hideColor ? 'thumbup' : 'thumbdown'}
            color="#fff"
          />
        </View>
        <ScrollView
          style={{marginLeft: Metrics.horizontalScale(20)}}
          persistentScrollbar={true}>
          <Typography
            variant="body2"
            style={{
              maxWidth:
                Platform.OS === 'web'
                  ? undefined
                  : Metrics.horizontalScale(223),
            }}>
            {description}
          </Typography>
        </ScrollView>
        <Pressable
          onPress={onClose}
          style={{
            position: 'absolute',
            top: Metrics.verticalScale(10),
            right: Metrics.horizontalScale(7),
            width: Metrics.horizontalScale(20),
            height: Metrics.horizontalScale(20),
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}>
          <Icon name="close" size="16" />
        </Pressable>
      </>
    </View>
  );
}
