import {Animated} from 'react-native';
import {useEffect, useMemo} from 'react';

const useHeaderAnimation = (props?: {threshold?: number}) => {
  const {threshold = 100} = props || {};
  const opacity = useMemo(() => new Animated.Value(0), []); // Initial opacity is 0

  const inputRange = [0, threshold];
  const outputRange = [0, 1]; // Fully opaque at 0 scroll, fully transparent at threshold

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= threshold) {
        opacity.setValue(window.scrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [opacity, threshold]);

  const interpolatedOpacity = opacity.interpolate({
    inputRange,
    outputRange,
    extrapolate: 'clamp',
  });

  return {
    interpolatedOpacity,
  };
};

export default useHeaderAnimation;
