import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import RoundedTextInput, {
  RoundedTextInputProps,
} from '../../TextInput/RoundedTextInput/RoundedTextInput';

export interface TextInputControllerProps<
  TField extends FieldValues = FieldValues,
> extends Omit<RoundedTextInputProps, 'name'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
}

export default function TextInputController<
  TField extends FieldValues = FieldValues,
>(props: TextInputControllerProps<TField>) {
  const {name, label, rules, control, defaultValue, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  return (
    <RoundedTextInput
      value={field.value}
      onChangeText={field.onChange}
      label={label}
      placeholderTextColor={'#fff'}
      {...rest}
    />
  );
}
