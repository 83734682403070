import CarouselLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/CarouselLoader';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useEffect, useMemo} from 'react';
import {View} from 'react-native';
import {useQuizzSurveyRelatedContentLazyQuery} from '~Api/Graphql/gql/types.generated';
import CourseSlider from '~Umbraco/Components/CourseSlider/CourseSlider';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
import {useMapQuizzSurvey} from '~Umbraco/Utils/content/mapQuizzSurvey';
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface IRelatedQuizzProps {
  id: string;
  first?: number;
}
const RelatedQuizz = (props: IRelatedQuizzProps) => {
  const {id, first = 10} = props;
  const [fetchRelatedContent, {data: relatedContent, loading}] =
    useQuizzSurveyRelatedContentLazyQuery();
  const {mapQuizzSurvey} = useMapQuizzSurvey();
  const {t} = useTranslation('translation');

  useEffect(() => {
    if (id) {
      fetchRelatedContent({
        variables: {
          id: id,
          first: first,
        },
      });
    }
  }, [fetchRelatedContent, first, id]);

  const {handleSeeMoreCategorySlider, handleClickCourseSlideItem} =
    useSectionPageUtils();

  const content: ReactNode = useMemo(() => {
    if (
      relatedContent &&
      relatedContent.quizzSurveyCollection &&
      relatedContent.quizzSurveyCollection.parent &&
      relatedContent.quizzSurveyCollection.parent.__typename ===
        'QuizzSurveyCategory'
    ) {
      const _list = mapQuizzSurvey(
        relatedContent.quizzSurveyCollection.parent.children.items,
      );
      const {id: categoryId, __typename, name, url} =
        relatedContent.quizzSurveyCollection.parent;
      return (
        <CourseSlider
          data={_list.filter(el => el.id !== id)}
          title={t('Related content') || ''}
          onItemPress={handleClickCourseSlideItem}
          size={'small'}
          isLink
          onSeeMore={handleSeeMoreCategorySlider({
            url: url || '',
            id: categoryId || '',
            __typename: __typename,
            name: name || '',
          })}
        />
      );
    }
    return undefined;
  }, [
    id,
    relatedContent,
    mapQuizzSurvey,
    handleClickCourseSlideItem,
    handleSeeMoreCategorySlider,
  ]);
  return (
    <View
      style={{
        marginBottom: Metrics.horizontalScale(24),
      }}>
      {loading ? <CarouselLoader /> : content}
    </View>
  );
};

export default RelatedQuizz;
