import React, {FC} from 'react';

interface IPointProps {
  isActive?: boolean;
  onClick?: () => void;
}

const Point: FC<IPointProps> = props => {
  const {isActive} = props;

  return (
    <div
      style={{
        backgroundColor: isActive ? '#FFFFFF' : '#A0A0A0',
        width: 4,
        height: 4,
        borderRadius: 2,
      }}
      onClick={props.onClick}
    />
  );
};

export default Point;
