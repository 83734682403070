import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import MultiInput, {MultiInputProps} from '../../MultiInput/MultiInput';

export interface MultiInputControllerProps<
  TField extends FieldValues = FieldValues,
> extends Omit<MultiInputProps, 'name'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
}

export default function MultiInputController<
  TField extends FieldValues = FieldValues,
>(props: MultiInputControllerProps<TField>) {
  const {name, rules, control, defaultValue, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  return (
    <MultiInput
      value={field.value}
      {...rest}
      onChange={value => {
        field.onChange(value as PathValue<TField, Path<TField>>);
      }}
    />
  );
}
