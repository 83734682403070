import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {useEffect, useState} from 'react';
import {Alert} from 'react-native';
import {initApolloInstance} from '~Api/Graphql';

export const useApiClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    initApolloInstance()
      .then(_client => {
        if (_client) {
          setClient(_client);
        }
      })
      .catch(error => {
        console.log('error client int', error);
        Alert.alert(
          "Une erreur est survenue pendant l'initialisation.\nVeuillez relancer l'application",
        );
      });
  }, []);
  return {client};
};
