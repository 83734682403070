import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useState, useEffect, useMemo, ReactNode} from 'react';
import {ScrollView, View} from 'react-native';
import {RadioButtonItem} from 'design-system/src/Components/RadioButton/RadioButtonItem/RadioButtonItem';
import RadioButton from 'design-system/src/Components/RadioButton/RadioButton';
import Feedback from 'design-system/src/Components/Feedback/Feedback';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  IChoiceNuggetFragmentFragment,
  ICourseFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import HtmlRender from '@src/Umbraco/Components/HtmlRender/HtmlRender';
import CourseNuggetWrapper from '../CourseNuggetWrapper';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

export type RevealAnswerItem = {
  label: string;
  reveal: boolean;
  isCorrect?: boolean;
  isSelected?: boolean;
};

export interface IChoiceNuggetProps {
  display?: 'mode-1' | 'mode-2';
  data: RadioButtonItem[];
  title?: string;
  description?: string;
  showResult?: boolean;
  genericFeedback?: boolean;
  positiveFeedback?: string;
  negativeFeedback?: string;
  value?: string[];
  onChange?: (data: string[], isCorrect?: boolean) => void;
  onCheckCorrect?: (isCorrect?: boolean) => void;
  raw: IChoiceNuggetFragmentFragment;
  property?: ILearnPropertyName;
  isMultiple?: boolean;
  renderBlock?: ReactNode;
}

export default function ChoiceNugget(props: IChoiceNuggetProps) {
  const {
    display = 'mode-1',
    data,
    title,
    description,
    genericFeedback,
    positiveFeedback,
    negativeFeedback,
    showResult = false,
    value = [],
    raw,
    onChange,
    onCheckCorrect,
    property: learnProperty,
    isMultiple,
    renderBlock,
  } = props;

  const [answerFeedback, setAnswerFeedback] = useState('');
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [hideFeedback, setHideFeedback] = useState<boolean>(false);
  const [isCorrect, setIsCorret] = useState<boolean>();
  const {t} = useTranslation('unit');
  const {isMobile} = useBreakpoint();
  const allDataCorrect: RadioButtonItem[] = data.filter(
    item => !!item.isCorrect,
  );
  const property: ILearnPropertyName = useMemo(() => {
    const {parent: courseUnit} = raw;
    let course: Partial<ICourseFragmentFragment> | undefined = undefined;
    if (
      courseUnit &&
      courseUnit.__typename === 'CourseUnit' &&
      courseUnit.parent &&
      courseUnit.parent.__typename === 'Course'
    ) {
      course = courseUnit.parent;
    }
    return {
      courseName: course?.name,
      courseId: course?.id,
      unitName: courseUnit?.__typename === 'CourseUnit' ? courseUnit.name : '',
      partnerName:
        course?.linkedPartners &&
        course.linkedPartners[0] &&
        course.linkedPartners[0].__typename === 'Partner'
          ? course.linkedPartners[0].partnerName
          : '',
      nuggetReference: raw.externalId,
      nuggetType: raw.__typename,
      // nuggetPlace: //TODO,
      certificateName: learnProperty?.certificateName,
      certificateId: learnProperty?.certificateId,
      time: new Date(),
    };
  }, [raw]);
  useEffect(() => {
    tracker.learn.nuggetStarted(property);
  }, []);

  const isAllCorrect = useMemo(() => {
    return allDataCorrect.length === data.length;
  }, [allDataCorrect, data]);

  const handleChange = (
    _value: string[],
    isCorrect: boolean,
    newValue?: string,
    isCheckValue?: boolean,
  ) => {
    setHideFeedback(false);
    setAnswerFeedback('');
    setSelectedData(_value);
    onChange && onChange(_value, isCorrect);
    if (!isCheckValue) {
      if (isMultiple && !isAllCorrect && newValue) {
        const firstSelected = data.find(item => item.value === newValue);
        if (firstSelected) {
          setIsCorret(firstSelected.isCorrect);
          if (firstSelected.feedback) {
            setAnswerFeedback(firstSelected.feedback || '');
          } else if (genericFeedback) {
            const _feedback = firstSelected.isCorrect
              ? positiveFeedback
              : negativeFeedback;
            setAnswerFeedback(_feedback || '');
          }
        }
      } else if (_value[0] && (!isMultiple || isAllCorrect)) {
        const firstSelected = data.find(item => item.value === _value[0]);
        if (firstSelected) {
          setIsCorret(firstSelected.isCorrect);
          if (firstSelected.feedback) {
            setAnswerFeedback(firstSelected.feedback || '');
          } else if (genericFeedback) {
            const _feedback = firstSelected.isCorrect
              ? positiveFeedback
              : negativeFeedback;
            setAnswerFeedback(_feedback || '');
          }
        }
      }
    }
  };

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(selectedData)) {
      const _value: string[] = value.filter(
        _item => !!data.find(_d => _d.value === _item),
      );
      setSelectedData(_value);
      const isCorrect = allDataCorrect.every(
        item =>
          _value.includes(item.value) &&
          _value.length === allDataCorrect.length,
      );
      const correct = isCorrect || !showResult || isAllCorrect;
      onCheckCorrect && onCheckCorrect(correct);
      handleChange(_value, correct, undefined, isMultiple && !isAllCorrect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const isSelected: boolean = !!selectedData.length;

  const showFeedBack = useMemo(() => {
    return answerFeedback !== '' && showResult && isSelected && !hideFeedback;
  }, [showResult, isSelected, isAllCorrect, answerFeedback, hideFeedback]);

  return (
    <>
      <View style={{flex: 1}}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <CourseNuggetWrapper
            title={
              title?.replace(
                /{{correct}}/g,
                allDataCorrect.length.toString(),
              ) ||
              t('Select the answer you most agree with') ||
              undefined
            }
            description={description}
            detailsRender={renderBlock}
            optionsRender={
              <View
                style={{
                  marginTop: Metrics.verticalScale(32),
                }}>
                <RadioButton
                  display={'column'}
                  multiple={display === 'mode-2'}
                  value={selectedData}
                  useChoice={true}
                  showResult={showResult}
                  onChange={(_value, newValue) => {
                    const isCorrect = allDataCorrect.every(
                      item =>
                        _value.includes(item.value) &&
                        _value.length === allDataCorrect.length,
                    );
                    if (!isCorrect) {
                      tracker.learn.nuggetError({
                        ...property,
                        time: new Date(),
                      });
                    }
                    handleChange(
                      _value,
                      isCorrect || !showResult || isAllCorrect,
                      newValue,
                    );
                  }}
                  groups={data}
                  renderDescription={desc => (
                    <Typography
                      variant="ui3"
                      color="gray"
                      style={{
                        marginTop: Metrics.verticalScale(10),
                      }}>
                      {desc}
                    </Typography>
                  )}
                />
              </View>
            }
          />
        </ScrollView>
        <View style={{height: 73}} />
      </View>

      <View
        // @ts-ignore
        style={{
          alignItems: 'center',
          position: isMobile ? 'absolute' : 'sticky',
          zIndex: 1,
          width: '100%',
          bottom: isMobile ? undefined : 73,
          top: isMobile ? 0 : undefined,
        }}>
        {showFeedBack && (
          <Feedback
            type={isCorrect ? 'success' : 'error'}
            onClose={() => {
              setHideFeedback(true);
            }}
            hideColor={isAllCorrect}
            description={
              answerFeedback ||
              (isCorrect ? positiveFeedback : negativeFeedback) ||
              ''
            }
          />
        )}
      </View>
    </>
  );
}
