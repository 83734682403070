import React from 'react';
import {StyleSheet, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import Typography from '../../Typography/Typography';
import {summaryCardStyles} from './SummaryCard.styles';

export interface SummaryCardProps {
  source: CustomFastImageProps['source'];
  title: string;
  description: string;
}
const SummaryCard = (props: SummaryCardProps) => {
  const {title, description, source} = props;

  return (
    <View style={summaryCardStyles.container}>
      <CustomFastImage
        source={source}
        width="100%"
        height={Metrics.verticalScale(240)}
        radius={Metrics.moderateScale(4)}
      />
      <Typography
        children={title}
        variant="h2"
        style={StyleSheet.flatten([
          summaryCardStyles.title,
          summaryCardStyles.text,
        ])}
      />
      <Typography
        children={description}
        variant="body3"
        style={summaryCardStyles.text}
      />
    </View>
  );
};

export default SummaryCard;
