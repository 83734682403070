import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';


export const pushQuizStyles = StyleSheet.create({
  container: {
    paddingVertical: Metrics.verticalScale(16),
    paddingRight: Metrics.horizontalScale(12),
    paddingLeft: Metrics.horizontalScale(16),
    borderRadius: Metrics.moderateScale(4),
    backgroundColor: defaultTheme.primaryNavBar,
  },
  trailincIc: {
    display: 'flex',
    flex: 1, 
    justifyContent: 'center',
  },
  tagContaier: {
    position: 'absolute',
    right: Metrics.horizontalScale(8),
    top: Metrics.verticalScale(8),
  }
});
