import useParams from '@src/Hooks/useParams';
import PushQuizCard from 'design-system/src/Components/Card/PushQuizCard/PushQuizCard';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useEffect, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {useGetAllPersonalityPagesByUrlEndQuery} from '~Api/Graphql/gql/types.generated';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import useAppNavigation from '@src/utils/useAppNavigation';
import {useAppDispatch} from '@src/Store/hooks';
import {AppRoutesEnum} from '@src/Constant/routes';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useLazyGetAllMemberPersonalityTypeQuery} from '@src/Api/TraceApi/traceApi';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

export default function QuizPersonalityPage() {
  const params = useParams();

  const {endUrl} = params || {};
  const {t} = useTranslation(['learn']);
  const response = useGetAllPersonalityPagesByUrlEndQuery({
    variables: {
      endUrl,
    },
    skip: !endUrl,
  });
  const quizzPage = response.data?.allQuizPage?.items[0];

  const [fetchUserPersonality, {data: personalData, isLoading}] =
    useLazyGetAllMemberPersonalityTypeQuery();

  const loading = response.loading || isLoading;
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const {isMobile} = useBreakpoint();

  useEffect(() => {
    fetchUserPersonality();
  }, []);

  const pageBlocks: ReactNode[] = useMemo(() => {
    const {data} = response;
    let _pageBlocks: ReactNode[] = [];
    if (quizzPage) {
      const {
        personalitiesTitle,
        personalitiesCTALabel,
        personalitiesCover,
        personalitiesSubtitle,
        quizz,
      } = quizzPage;

      let _quizz =
        quizz && quizz[0] && quizz[0].__typename === 'PersonalityQuiz1'
          ? quizz[0]
          : undefined;

      _pageBlocks.push(
        <ServiceHeader
          key={'p-head'}
          title={personalitiesTitle || ''}
          description={personalitiesSubtitle || ''}
          buttonTitle={personalitiesCTALabel}
          gradiantVariant="bottom"
          image={{
            uri:
              personalitiesCover &&
              personalitiesCover[0] &&
              personalitiesCover[0].content &&
              personalitiesCover[0].content.__typename === 'HeroVideoCover'
                ? personalitiesCover[0].content.image?.url
                : undefined,
          }}
          onPressButton={() => {
            if (!!personalData && _quizz) {
              navigation.navigate(AppRoutesEnum.QUIZ_RESULT, {
                state: {
                  params: {
                    quizId: (_quizz as any).id,
                    answers: personalData,
                    result: [],
                    id: quizzPage?.id,
                    url: quizzPage?.url,
                  },
                },
              });
              return;
            }

            navigation.navigate(AppRoutesEnum.QUIZ_QUESTION, {
              state: {
                params: {
                  id: quizzPage.id || '',
                  url: quizzPage.url || '',
                },
              },
            });
          }}
        />,
      );
      if (_quizz?.children) {
        let _allPush: ReactNode[] = [];
        _quizz.children.items?.forEach((item, index) => {
          if (item && item.__typename === 'PersonalityPages') {
            const {icon, title, subtitle, id, url, __typename} = item;
            _allPush.push(
              <View
                style={{marginBottom: Metrics.verticalScale(8)}}
                key={`qps-${index}`}>
                <PushQuizCard
                  title={title || ''}
                  description={subtitle || ''}
                  icon={() => (
                    <CustomFastImage
                      backgroundFallbackColor="transparent"
                      width={Metrics.horizontalScale(32)}
                      height={Metrics.horizontalScale(32)}
                      source={{
                        uri: icon?.url,
                      }}
                    />
                  )}
                  onPressArrowIc={() => {
                    new RouteFactory(
                      {
                        id,
                        url: url || '',
                        __typename,
                      },
                      navigation,
                      dispatch,
                    ).navigate();
                  }}
                  descTypo="ui3"
                />
              </View>,
            );
          }
        });
        if (_allPush.length) {
          _pageBlocks.push(
            <View
              key={'list'}
              style={{
                paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
                paddingBottom: Metrics.verticalScale(30),
              }}>
              <Typography
                variant="h3"
                style={{
                  marginTop: Metrics.verticalScale(33),
                  marginBottom: Metrics.verticalScale(13),
                }}>
                {t('Personality types')}
              </Typography>
              <AppGrid nbColumns={isMobile ? 1 : 2} columnGap={12}>
                {_allPush}
              </AppGrid>
            </View>,
          );
        }
      }
    }
    return _pageBlocks;
  }, [response, t, quizzPage, isMobile, personalData]);

  return (
    <div>
      <DebugUmbracoId umbracoId={quizzPage?.id} position="page" />
      {endUrl ? (
        loading ? (
          <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
        ) : (
          pageBlocks
        )
      ) : (
        <ContentEmpty
          style={{flex: 1, justifyContent: 'center'}}
          title={t('Section not found')}
        />
      )}
    </div>
  );
}
