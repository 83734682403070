import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {FastImageProps, ImageStyle} from 'react-native-fast-image';
import LinearGradient from 'react-native-linear-gradient';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage.web';
import Typography from '../../Typography/Typography';
import BlockSlider, {
  IBlockSliderItem,
  IBlockSliderProps,
} from './BlockSlider/BlockSlider.web';
import {HighlightedCarouselStyles} from './HighlightedCarousel.styles.web';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';

export interface HighlightedCarouselProps<
  T extends IBlockSliderItem = IBlockSliderItem,
> extends Omit<IBlockSliderProps<T>, 'tag' | 'isLink'> {
  tag: string;
  slideTitle: string;
  description: string;
  cover?: FastImageProps['source'];
  coverDesktop?: FastImageProps['source'];
  imageWrapperStyle?: StyleProp<ImageStyle>;
  style?: StyleProp<ViewStyle>;
}

const HighlightedCarousel = <T extends IBlockSliderItem = IBlockSliderItem>(
  props: HighlightedCarouselProps<T>,
) => {
  const {tag, title, description, cover, coverDesktop, style, ...sliderProps} =
    props;

  const {isMobile} = useBreakpoint();

  let vwInPixels = window.innerWidth;
  const aspectRatio = isMobile ? 375 / 408 : 1440 / 642;

  window.addEventListener('resize', () => {
    vwInPixels = window.innerWidth;
  });

  const gradientColor = HighlightedCarouselStyles.gradient.color;
  const height = vwInPixels * (isMobile ? 0.919 : 0.5625);

  return (
    <View
      style={[
        {
          width: '100%',
          aspectRatio: aspectRatio,
        },
        style,
      ]}
      testID="highlighted-carousel">
      <CustomFastImage
        source={isMobile ? cover : coverDesktop}
        style={{
          width: '100%',
          aspectRatio: aspectRatio,
        }}
        // width={vwInPixels}
        // height={height}
      >
        <LinearGradient
          style={{...HighlightedCarouselStyles.container, width: vwInPixels}}
          colors={[gradientColor, gradientColor]}>
          <View
            style={{
              width: '100%',
              paddingLeft: isMobile ? 16 : '30%',
              display: 'flex',
              justifyContent: isMobile ? 'flex-start' : 'center',
              marginBottom: isMobile ? 0 : 70,
            }}>
            <View style={{width: 285}}>
              <Typography
                children={tag.toUpperCase()}
                variant="ui2"
                style={HighlightedCarouselStyles.tag}
              />
              <Typography
                children={title}
                style={
                  isMobile
                    ? HighlightedCarouselStyles.titleMobile
                    : HighlightedCarouselStyles.titleDesktop
                }
              />
              <Typography
                children={description}
                variant={isMobile ? 'body3' : 'body2'}
              />
            </View>
          </View>
          <BlockSlider
            title={''}
            {...sliderProps}
            containerStyle={{
              paddingTop: 0,
              paddingBottom: isMobile
                ? Metrics.verticalScale(17)
                : Metrics.verticalScale(40),
            }}
            contentWrapperStyle={{paddingTop: 0}}
            mb={0}
          />
        </LinearGradient>
      </CustomFastImage>
    </View>
  );
};

export default HighlightedCarousel;
