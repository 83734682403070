import {IButtonProps} from 'design-system/src/Components/Button/Button';
import {
  IButtonFragmentFragment,
  ITextSlide1FragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import {RouteTypename} from '~Navigation/type';

export interface ILinkTo {
  url: string;
  name: string;
  __typename: RouteTypename;
  id: string;
  sourceUrl?: string;
  liveEventId?: string;
}

export type IMapButtonResult = IButtonProps & {
  linkTo?: ILinkTo;
};

export const mapLinkTo = (
  link_to:
    | IButtonFragmentFragment['link_to']
    | ITextSlide1FragmentFragment['linkTo'],
) => {
  let linkTo: ILinkTo | undefined;
  if (link_to && link_to[0]) {
    if (
      link_to[0].content.__typename === 'InternalLink' ||
      link_to[0].content.__typename === 'ContentLink'
    ) {
      if (
        link_to[0].content.__typename === 'ContentLink' &&
        link_to[0].content.link &&
        isElementRestricted(link_to[0].content.link)
      ) {
        return undefined;
      }
      linkTo = {
        url: link_to[0].content.link?.url || '',
        name: link_to[0].content.link?.name || '',
        id: link_to[0].content.link?.id || '',
        __typename: link_to[0].content.link?.__typename || 'Unknown',
      };
    } else if (link_to[0].content.__typename === 'ExternalLink') {
      linkTo = {
        url: link_to[0].content.externalUrl || '',
        name: '',
        id: '',
        __typename: 'ExternalLink',
      };
    }
  }

  if (linkTo?.__typename === 'Unknown') {
    return undefined;
  }
  return linkTo;
};

const mapButton = (
  button: IButtonFragmentFragment,
): (IMapButtonResult | undefined) | undefined => {
  const {link_to} = button;
  let linkTo: ILinkTo | undefined;
  if (link_to && link_to[0]) {
    if (
      link_to[0].content.__typename === 'InternalLink' ||
      link_to[0].content.__typename === 'ContentLink'
    ) {
      if (
        link_to[0].content.__typename === 'ContentLink' &&
        link_to[0].content.link &&
        isElementRestricted(link_to[0].content.link)
      ) {
        return undefined;
      }
      linkTo = {
        url: link_to[0].content.link?.url || '',
        name: link_to[0].content.link?.name || '',
        id: link_to[0].content.link?.id || '',
        __typename: link_to[0].content.link?.__typename || 'Unknown',
        sourceUrl: link_to[0].content.link?.sourceUrl || '',
        liveEventId: link_to[0].content.link?.liveEventId || '',
      };
    } else if (link_to[0].content.__typename === 'ExternalLink') {
      linkTo = {
        url: link_to[0].content.externalUrl || '',
        name: '',
        id: '',
        __typename: 'ExternalLink',
      };
    }
  }

  if (linkTo?.__typename === 'Unknown') {
    return undefined;
  }
  return {
    variant: (button.variant as IButtonProps['variant']) || 'outlined',
    icon: (button.icon || '').toLowerCase() as IButtonProps['icon'],
    size: (button.size as IButtonProps['size']) || 'small',
    title: button.title || '',
    linkTo,
  };
};

export default mapButton;
