import {View} from 'react-native';
import React, {ReactNode, useEffect, useMemo} from 'react';
import {
  ICategoryChildrenByIdQueryVariables,
  useCategoryChildrenByIdLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import useCategoryPageUtils, {
  ICategoryGridProps,
} from '~Umbraco/Hooks/useCategoryPageUtils';
import {CategoryContentLoader} from 'design-system/src/Components/ContentLoader';
import {RouteTypename} from '~Navigation/type';

export interface ICategoryPageContentProps<
  T extends any = ICategoryChildrenByIdQueryVariables['orderBy'],
> {
  id?: string;
  type: RouteTypename;
  gridProps?: ICategoryGridProps;
  orderBy?: T;
  regionId?: string;
  setAllCategoryData?: (data: any[]) => void;
}
const CategoryPageContent = (props: ICategoryPageContentProps) => {
  const {id, type, orderBy, gridProps, regionId, setAllCategoryData} = props;
  const [fetchCategory, {data: currentCategoryData, loading}] =
    useCategoryChildrenByIdLazyQuery();

  const {renderCategoryPage} = useCategoryPageUtils();

  useEffect(() => {
    if (id) {
      fetchCategory({
        variables: {
          id: id,
          orderBy: orderBy,
        },
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orderBy]);

  useEffect(() => {
    if (currentCategoryData?.category?.children?.edges) {
      setAllCategoryData &&
        setAllCategoryData(currentCategoryData.category.children.edges);
    }
  }, [currentCategoryData]);

  const pageContent: ReactNode[] = useMemo(() => {
    if (
      currentCategoryData &&
      currentCategoryData.category &&
      currentCategoryData.category.children &&
      currentCategoryData.category.children.edges
    ) {
      return renderCategoryPage(
        currentCategoryData.category,
        type,
        gridProps,
        regionId,
      );
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategoryData, type, gridProps, regionId]);

  return (
    <View>
      {loading ? <CategoryContentLoader hideSelector={true} /> : pageContent}
    </View>
  );
};

export default React.memo(CategoryPageContent);
