import {useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  useAllRegionWebLazyQuery,
  IRegionFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import regionSlice from '~Store/Slices/Region.slice';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';

const useSetupRegion = (skip?: boolean) => {
  const dispatch = useDispatch();
  const currentLanguage = useCurrentLanguage();
  const [fetchAllRegion, {loading}] = useAllRegionWebLazyQuery();

  const fetch = useCallback(
    (lang?: string) => {
      return new Promise((resolve, reject) => {
        fetchAllRegion({
          variables: lang
            ? {
                culture: lang,
              }
            : {},
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        })
          .then(response => {
            if (response) {
              const {data} = response;
              if (data && data.allRegion && data.allRegion.items) {
                const regions: IRegionFragmentFragment[] = [];
                data.allRegion.items.forEach(item => {
                  if (item) {
                    regions.push(item);
                  }
                });
                dispatch(regionSlice.actions.setRegions(regions));
              }
            }
            resolve(true);
          })
          .catch(() => reject());
      });
    },
    [dispatch, fetchAllRegion],
  );

  useEffect(() => {
    if (!skip && !!currentLanguage) {
      fetch(currentLanguage);
    }
  }, [fetch, skip, currentLanguage]);

  return {loading, fetch};
};

export default useSetupRegion;
