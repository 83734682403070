import React from 'react';
import {_ContainerRoot} from './Container.styled';
import clsx from 'clsx';

export interface ContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  bg?: string;
  mt?: React.CSSProperties['marginTop'];
  mb?: React.CSSProperties['marginBottom'];
  as?: React.ComponentType<any>;
  [p: string]: any;
}

const Container = (props: ContainerProps) => {
  const {children, bg, style, className, mt, mb, as} = props;

  return (
    <_ContainerRoot
      className={clsx('trace-container', className)}
      style={style}
      $bg={bg}
      $mt={mt}
      $mb={mb}
      as={as}>
      {children}
    </_ContainerRoot>
  );
};

export default Container;
