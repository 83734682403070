import {useMemo} from 'react';
import {useCourseOverviewByIdQuery} from '~Api/Graphql/gql/types.generated';
import {ICourseOverview} from '~Umbraco/Utils/Overview/overview.interface';
import {OverviewService} from '~Umbraco/Utils/Overview/overview.service';

export const useGetCourseOverview = (
  id: string,
): {
  loading: boolean;
  overview: ICourseOverview | undefined;
} => {
  const response = useCourseOverviewByIdQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  const overview: ICourseOverview | undefined = useMemo(() => {
    const {data} = response;
    if (data && data.course) {
      return OverviewService.getCourseOverview(data.course);
    }
  }, [response]);

  return {
    loading: response.loading,
    overview,
  };
};
