import React, {FC} from 'react';
import Icon from '../../../../Components/Icon/Icon';
import {Pressable} from 'react-native';
import Typography from '../../../../Components/Typography/Typography.web';
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface PlayerParametersProps {
  onSetOpenParameters: () => void;
  speedParameter: number;
  onSetSpeedParameter: (speed: number) => void;
  audioTracks: IItemPlayerParameter[];
  subtitles: IItemPlayerParameter[];
  videoQualities: IItemPlayerParameter[];
  videoQuality: number;
  audioTrack: number;
  subtitle: number;
  onChangeAudioTrack: (index: number) => void;
  onChangeSubtitle: (index: number) => void;
  handleOnLanguageChange?: (value: string) => void;
  onChangeVideoQuality: (index: number) => void;
}

const PlayerParameters: FC<PlayerParametersProps> = ({
  onSetOpenParameters,
  speedParameter,
  onSetSpeedParameter,
  audioTracks,
  subtitles,
  audioTrack,
  subtitle,
  onChangeAudioTrack,
  onChangeSubtitle,
  handleOnLanguageChange,
  videoQualities,
  onChangeVideoQuality,
  videoQuality,
}) => {
  const {t} = useTranslation(['videoPlayer']);
  const speedParameters: IItemPlayerParameter[] = [
    {
      label: 'x 0,5',
      value: 0.5,
    },
    {
      label: 'x 0,75',
      value: 0.75,
    },
    {
      label: t('normal'),
      value: 1,
    },
    {
      label: 'x 1,25',
      value: 1.25,
    },
    {
      label: 'x 1,5',
      value: 1.5,
    },
  ];
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        top: 0,
        width: 380,
        backgroundColor: '#000000CC',
        height: '100%',
      }}>
      <div
        style={{
          padding: '32px 56px',
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          position: 'relative',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'sticky',
            top: 0,
            right: 0,
          }}>
          <Pressable onPress={onSetOpenParameters}>
            <Icon name="close" />
          </Pressable>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 46,
          }}>
          <ParameterItem
            title={t('resolution')}
            items={videoQualities}
            selectedValue={videoQuality}
            onSetValue={onChangeVideoQuality}
          />
          <ParameterItem
            title={t('audio')}
            items={audioTracks}
            selectedValue={audioTrack}
            onSetValue={onChangeAudioTrack}
            handleOnLanguageChange={handleOnLanguageChange}
          />
          <ParameterItem
            title={t('subtitles')}
            items={subtitles}
            selectedValue={subtitle}
            onSetValue={onChangeSubtitle}
            handleOnLanguageChange={handleOnLanguageChange}
          />
          <ParameterItem
            title={t('playback speed')}
            items={speedParameters}
            selectedValue={speedParameter}
            onSetValue={onSetSpeedParameter}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerParameters;

interface ParameterItemProps {
  title: string;
  items: IItemPlayerParameter[];
  selectedValue: number;
  onSetValue: (value: number) => void;
  handleOnLanguageChange?: (value: string) => void;
}

const ParameterItem: FC<ParameterItemProps> = ({
  title,
  items,
  selectedValue,
  onSetValue,
  handleOnLanguageChange,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 18,
      }}>
      <Typography variant="h4">{title}</Typography>
      {items.map(item => (
        <div
          key={item.value}
          style={{
            display: 'flex',
            gap: 14,
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() => {
            onSetValue(item.value);
            handleOnLanguageChange && handleOnLanguageChange(item.label);
          }}>
          <Typography
            variant="ui1"
            color={selectedValue == item.value ? 'primary' : 'white'}>
            {item.label}
          </Typography>
          {selectedValue == item.value && <Icon name="check" color="#EA6C13" />}
        </div>
      ))}
    </div>
  );
};

export interface IItemPlayerParameter {
  label: string;
  value: number;
}


