import {useState, useCallback, MutableRefObject, useEffect} from 'react';
import {
  useAddPremiumPreviewMutation,
  useLazyGetPremiumPreviewQuery,
} from '~Api/TraceApi/traceApi';
import usePaywal, {IPaywallSetting} from '~Hooks/usePaywal';
import memberSlice from '~Store/Slices/Member.slice';
import DateFactory from '~tools/DateFactory';
import {useAppSelector} from '@src/Store/hooks';
import {useDispatch} from 'react-redux';
import {IVideoSourceData} from './useVideoPlayerLogic';
import useEvent from '@src/Hooks/useEvent';

export const useVideoPremiumPreview = (props: {
  currentId: string;
  canSee: boolean;
  canPreview: boolean;
  payWallId: string;
  setCurrentTime: (time: number) => void;
  isLive?: boolean;
  paywallSetting: IPaywallSetting;
  progressLiveRef: MutableRefObject<number>;
  liveEvent: IVideoSourceData | undefined;
}) => {
  const {
    currentId,
    canSee,
    canPreview,
    payWallId,
    setCurrentTime,
    paywallSetting,
    isLive,
    progressLiveRef,
    liveEvent,
  } = props;
  // preview finished
  const [displayPaywall, setDisplayPaywall] = useState<boolean>();

  // add premium preview information

  const [updatePremiumPreview] = useAddPremiumPreviewMutation();
  const dispatch = useDispatch();
  const handlePreviewProgress = useEvent(async (currentTime: number) => {
    if (!canSee && canPreview && currentTime > 0) {
      dispatch(
        memberSlice.actions.setPremiumPreview({
          contentId: currentId,
          progress: currentTime,
          playAt: new Date().toISOString(),
        }),
      );

      updatePremiumPreview({
        contentId: currentId,
        paywallId: payWallId,
        progress: currentTime,
      });
    }
  });

  // get preview information

  const [fetchPremiumPreview] = useLazyGetPremiumPreviewQuery();
  const [previewLoading, setPreviewLoading] = useState<boolean>();
  const allPreview = useAppSelector(state => state.member.allPremiumPreview);
  const {canSeeContent} = usePaywal();
  const handleFetchPreview = useEvent(async () => {
    const findItem = liveEvent?.sourceList.find(e => e.id === currentId);
    const _canSee = findItem
      ? canSeeContent(findItem.accessData?.accessType)
      : canSee;
    if (!_canSee && canPreview) {
      setPreviewLoading(true);
      const {data} = await fetchPremiumPreview({
        contentId: currentId,
      });
      const {previewDurationTv, previewDurationVod} = paywallSetting;
      let _playAt: Date | string | undefined;
      let _progress: number = 0;
      if (data) {
        const {playedAt, progress} = data;
        _playAt = playedAt;
        _progress = progress || 0;
      } else if (
        allPreview?.[currentId] &&
        typeof allPreview[currentId] === 'object'
      ) {
        const {progress, playAt} = allPreview[currentId];
        _playAt = playAt;
        _progress = progress || 0;
      }
      const invalidDate = _playAt
        ? new DateFactory(_playAt).diffDate('day') > 0
        : true;
      if (invalidDate) {
        setDisplayPaywall(false);
        setCurrentTime(0);
      } else {
        const canDisplay =
          (isLive && _progress >= previewDurationTv) ||
          (!isLive && _progress >= previewDurationVod);
        setCurrentTime(_progress);
        setDisplayPaywall(canDisplay);
        if (isLive && _playAt) {
          // live was played _progress seconds ago
          progressLiveRef.current = Date.now() - _progress * 1000;
        }
      }
      setPreviewLoading(false);
    }
  });

  return {
    previewLoading,
    handleFetchPreview,
    handlePreviewProgress,
    displayPaywall,
    setDisplayPaywall,
  };
};
