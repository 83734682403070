import {StyleSheet} from 'react-native';
import { IconName } from '../../../assets/Icones';
import {darkTheme} from '../../../styles/Themes';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export type optionVariant = 'invalide' | 'valide' | 'default';

export const optionIconVariant: {
  [option in optionVariant]: {
    iconName: IconName;
    color: string;
  };
} = {
  invalide: {
    iconName: 'invalide',
    color: defaultTheme.option.invalide,
  },
  valide: {
    iconName: 'radio-on',
    color: defaultTheme.primary,
  },
  default: {
    iconName: 'check-empty',
    color: defaultTheme.option.default,
  },
};

export const optionCardVariantStyles = StyleSheet.create({
  invalide: {
    borderColor: defaultTheme.option.invalide,
    backgroundColor: defaultTheme.option.invalideBackground,
  },
  valide: {
    borderColor: defaultTheme.primary,
    backgroundColor: defaultTheme.option.valideBackground,
  },
  default: {
    borderColor: defaultTheme.option.default,
    backgroundColor: defaultTheme.option.defaultBackground,
  },
});

export const optionCardStyles = StyleSheet.create({
  container: {
    paddingLeft: Metrics.horizontalScale(16),
    paddingRight: Metrics.horizontalScale(26),
    paddingVertical: Metrics.verticalScale(14),
    borderWidth: 1,
    borderRadius: Metrics.moderateScale(8),
    borderColor: darkTheme.colors.sixthGray,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: Metrics.verticalScale(10),
  },
  title: {
    marginLeft: Metrics.horizontalScale(10),
    color: defaultTheme.primaryTitle,
  },
});
