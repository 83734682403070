import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ISubscriptionFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {useAppSelector} from '../hooks';

interface SubscriptionState {
  subscriptions: ISubscriptionFragmentFragment[];
}

const initialState = {
  subscriptions: [],
} as SubscriptionState;

// Then, handle actions in your reducers:
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptions(
      state,
      action: PayloadAction<ISubscriptionFragmentFragment[]>,
    ) {
      state.subscriptions = action.payload;
      return state;
    },
  },
});

export const useSelectAllSubscriptions = () => {
  return useAppSelector(state => state.subscription.subscriptions);
};

export default subscriptionSlice;
