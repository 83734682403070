import {RadioButtonItem} from 'design-system/src/Components/RadioButton/RadioButtonItem/RadioButtonItem';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native';
import {ISummaryNuggetFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {GapfillValueType} from '../GapfillNugget/GapfillNugget';
import RadioButton, {
  radioButtonStyles,
} from 'design-system/src/Components/RadioButton/RadioButton';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import Feedback from 'design-system/src/Components/Feedback/Feedback';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import ContentLoader, {Rect} from 'react-content-loader/native';
import {deleteObjectKey} from '@src/tools/utils/objectExtension';

export interface ISummaryNuggetProps {
  raw: ISummaryNuggetFragmentFragment;
  onChange?: (data: GapfillValueType) => void;
  showResult?: boolean;
  title?: string;
  value: GapfillValueType;
  property?: ILearnPropertyName;
  onCheckCorrect?: (isCorrect?: boolean) => void;
}
type SumaryNuggetData = Record<string, RadioButtonItem[]>;

interface ISummaryProps {
  isCorrect: boolean;
  label: string;
  showResult?: boolean;
  image?: string;
}

const Summary = (props: ISummaryProps) => {
  const {isCorrect, showResult, label, image} = props;
  const [imageLoading, setImageLoading] = useState<boolean>();

  const infoStyle = useMemo(() => {
    const info: {
      borderColor: string;
      backgroundColor: string;
    } = {
      borderColor: '#EA6C13',
      backgroundColor: 'rgba(234, 108, 19, 0.13)',
    };
    if (showResult) {
      if (isCorrect) {
        info.borderColor = 'rgba(5, 201, 107, 0.8)';
        info.backgroundColor = 'rgba(5, 201, 107, 0.2)';
      } else {
        info.borderColor = '#BE030A';
        info.backgroundColor = 'rgba(255, 30, 30, 0.13)';
      }
    }
    return info;
  }, [isCorrect, showResult]);
  return (
    <View
      style={StyleSheet.flatten([
        {
          borderWidth: image ? undefined : 1,
          borderRadius: Metrics.horizontalScale(4),
          marginBottom: Metrics.verticalScale(16),
          borderColor: infoStyle.borderColor,
          backgroundColor: !image ? infoStyle.backgroundColor : 'transparent',
        },
        image
          ? {
              width: Metrics.horizontalScale(160),
              height: Metrics.horizontalScale(160),
            }
          : {
              paddingHorizontal: Metrics.horizontalScale(16),
              paddingVertical: Metrics.verticalScale(12),
            },
      ])}>
      {!!image && (
        <CustomFastImage
          style={StyleSheet.flatten([
            {
              width: Metrics.horizontalScale(160),
              height: Metrics.horizontalScale(160),
              position: 'absolute',
            },
            {
              borderWidth: imageLoading ? undefined : 1,
              borderRadius: Metrics.horizontalScale(4),
              borderColor: infoStyle.borderColor,
            },
          ])}
          source={{uri: image}}
          onLoadStart={() => {
            setImageLoading(true);
          }}
          onLoadEnd={() => {
            setImageLoading(false);
          }}
          hideLoading
        />
      )}
      {!image && (
        <Typography
          style={{marginLeft: Metrics.horizontalScale(10), flexShrink: 1}}
          variant="body2">
          {label}
        </Typography>
      )}
      {imageLoading && (
        <ContentLoader
          height={radioButtonStyles.content.height}
          width={radioButtonStyles.content.width}
          style={{
            borderWidth: 1,
            borderColor: '#111216',
            position: 'absolute',
          }}
          speed={1}
          backgroundColor={'rgba(23, 24, 29, 0.7)'}
          foregroundColor={'#111216'}>
          <Rect
            x="0"
            y="0"
            rx="5"
            ry="5"
            height={radioButtonStyles.content.width}
            width={radioButtonStyles.content.height}
          />
        </ContentLoader>
      )}
    </View>
  );
};

const SummaryNugget = (props: ISummaryNuggetProps) => {
  const {raw, onChange, showResult, title, value, onCheckCorrect, property} =
    props;
  const [currentSelectedQuestion, setCurrentSelectedQuestion] =
    useState<number>(0);
  const [selectedAnswer, setSelectedAnswer] = useState<GapfillValueType>({});
  const [answerFeedback, setAnswerFeedback] = useState<
    | {
        isCorrect: boolean;
        label: string;
      }
    | undefined
  >(undefined);
  const {t} = useTranslation('unit');
  const [hideFeedback, setHideFeedback] = useState<boolean>(false);
  const handleSelected = (reference: number) => () => {
    setAnswerFeedback(undefined);
    setCurrentSelectedQuestion(reference);
  };
  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(selectedAnswer)) {
      let deleteKeys = Object.keys(value).filter(_key => !choices[_key]);
      let obj = deleteObjectKey(value, deleteKeys);
      setSelectedAnswer(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const {
    genericFeedback,
    negativeFeedback,
    positiveFeedback,
    choices,
  }: {
    genericFeedback?: boolean;
    negativeFeedback?: string;
    positiveFeedback?: string;
    choices: SumaryNuggetData;
  } = useMemo(() => {
    const {negativeFeedback, positiveFeedback, statements, genericFeedback} =
      raw;
    const choices: SumaryNuggetData = {};
    statements?.forEach((statement, parentIndex) => {
      if (
        statement &&
        statement.content &&
        statement.content.__typename === 'SummaryNuggetStatement'
      ) {
        const {options} = statement.content;
        const refOptions: RadioButtonItem[] = [];
        options?.forEach((option, index) => {
          if (
            option &&
            option.content &&
            option.content.__typename === 'ChoiceNuggerAnswer'
          ) {
            const {
              value,
              description,
              isCorrect,
              feedback,
              image: _image,
            } = option.content;
            refOptions.push({
              label: value || '',
              value: value || '',
              description: description,
              isCorrect: isCorrect || false,
              feedback: feedback || '',
              image: _image?.cropUrl || _image?.url,
              id: `${parentIndex}-${index}`,
            });
          }
        });
        if (refOptions.length) {
          choices[parentIndex.toString()] = refOptions;
        }
      }
      return undefined;
    });
    return {
      genericFeedback,
      negativeFeedback,
      positiveFeedback,
      choices,
    };
  }, [raw]);
  const handleAnswerChange = useCallback(
    (_value: string[], newValue?: string, _id?: string) => {
      if (_value[0]) {
        setHideFeedback(false);
        const key = currentSelectedQuestion.toString();
        setSelectedAnswer(_currentAnswer => {
          const _newData: GapfillValueType = {
            ..._currentAnswer,
            [key]: {
              value: _value[0],
              id: _id || '',
            },
          };
          onChange && onChange(_newData);
          return _newData;
        });
        const questionAnswer = choices[key].find(
          _item => _item.value === _value[0],
        );
        const isCorrect =
          !!(questionAnswer && questionAnswer.isCorrect) || !showResult;
        if (questionAnswer) {
          let _feedback: string | undefined = questionAnswer.feedback;
          if (!_feedback && genericFeedback) {
            _feedback = isCorrect ? positiveFeedback : negativeFeedback;
          }
          if (_feedback) {
            setAnswerFeedback({
              isCorrect,
              label: _feedback,
            });
          } else {
            setAnswerFeedback(undefined);
          }
        }
        if (isCorrect) {
          const keys = Object.keys(choices).length;
          setCurrentSelectedQuestion(_currenctQuestion => {
            const key = currentSelectedQuestion + 1;
            if (!selectedAnswer[key] && key < keys) {
              return key;
            }
            return _currenctQuestion;
          });
        }
      }
    },
    [
      currentSelectedQuestion,
      setAnswerFeedback,
      onChange,
      setSelectedAnswer,
      setHideFeedback,
      choices,
      selectedAnswer,
      positiveFeedback,
      negativeFeedback,
      showResult,
      genericFeedback,
    ],
  );
  const allDataCorrect: Record<string, RadioButtonItem> = useMemo(() => {
    let dataCorrect: Record<string, RadioButtonItem> = {};
    Object.keys(choices).forEach(key => {
      const isCorrectItem = choices[key].find(item => item.isCorrect);
      if (isCorrectItem) {
        dataCorrect[key] = isCorrectItem;
      }
    });
    return dataCorrect;
  }, [choices]);
  useEffect(() => {
    const isCorrect = Object.keys(allDataCorrect).every(
      key =>
        selectedAnswer[key] &&
        allDataCorrect[key].id === selectedAnswer[key]?.id,
    );
    if (!isCorrect && selectedAnswer[currentSelectedQuestion.toString()]) {
      tracker.learn.nuggetError({...property, time: new Date()});
    }
    onCheckCorrect && onCheckCorrect(isCorrect || !showResult);
  }, [selectedAnswer]);
  const content = useMemo(() => {
    let blocks: ReactNode[] = [];
    Object.keys(choices).forEach((key, index) => {
      if (currentSelectedQuestion === index) {
        blocks.push(
          <View
            key={`sum-${index}`}
            style={{marginVertical: Metrics.verticalScale(24), width: '100%'}}>
            <Typography
              style={{
                textAlign: 'center',
                marginTop: 24,
                marginBottom: 24,
              }}
              variant="h3">
              {t('Select the correct statement')}
            </Typography>
            <RadioButton
              value={
                selectedAnswer[currentSelectedQuestion.toString()]
                  ? [selectedAnswer[currentSelectedQuestion.toString()].value]
                  : []
              }
              useChoice
              useCustomValue={false}
              showResult={showResult}
              onChange={handleAnswerChange}
              groups={choices[key]}
              renderDescription={desc => (
                <Typography
                  variant="ui3"
                  color="gray"
                  style={{
                    marginTop: Metrics.verticalScale(10),
                  }}>
                  {desc}
                </Typography>
              )}
            />
          </View>,
        );
      } else if (selectedAnswer[key]) {
        const questionAnswer = choices[key].find(
          _item => _item.id === selectedAnswer[key]?.id,
        );
        const _isCorrect: boolean = !!(
          questionAnswer && questionAnswer.isCorrect
        );
        blocks.push(
          <TouchableOpacity
            style={{width: '100%'}}
            key={`sum-${index}`}
            onPress={handleSelected(index)}>
            <Summary
              isCorrect={_isCorrect}
              label={selectedAnswer[key]?.value}
              showResult={showResult}
              image={questionAnswer?.image}
            />
          </TouchableOpacity>,
        );
      }
    });
    return blocks;
  }, [choices, currentSelectedQuestion, handleAnswerChange, selectedAnswer]);

  const showFeedBack = useMemo(() => {
    return showResult && !hideFeedback;
  }, [showResult, hideFeedback]);
  return (
    <>
      <View
        style={{
          alignItems: 'center',
          position: 'absolute',
          zIndex: 1,
          width: '100%',
        }}>
        {showFeedBack && !!answerFeedback && (
          <Feedback
            type={answerFeedback.isCorrect ? 'success' : 'error'}
            onClose={() => setHideFeedback(true)}
            description={answerFeedback.label}
          />
        )}
      </View>
      <View style={{flex: 1}}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              alignItems: 'center',
            }}>
            <Typography
              style={{
                textAlign: 'center',
                maxWidth: Metrics.horizontalScale(250),
                paddingTop: Metrics.verticalScale(24),
              }}
              variant="h2">
              {title || t('Selection summary')}
            </Typography>
          </View>
          <View
            style={{
              marginTop: Metrics.verticalScale(32),
              alignItems: 'center',
            }}>
            {content}
          </View>
          <View style={{height: Metrics.verticalScale(140)}} />
        </ScrollView>
      </View>
    </>
  );
};

export default SummaryNugget;
