import React from 'react';
import {StyleSheet, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import Typography from '../../Typography/Typography';

export type CardTipsProps = {
  image: string;
  title: string;
  description: string;
  vertical?: boolean;
};

export default function CardTips(props: CardTipsProps) {
  const {image, title, description, vertical = false} = props;

  const textAlign = vertical ? 'center' : undefined;
  return (
    <View
      style={{
        paddingTop: Metrics.verticalScale(18),
        paddingBottom: Metrics.verticalScale(16),
        paddingRight: vertical
          ? Metrics.horizontalScale(16)
          : Metrics.horizontalScale(20),
        paddingLeft: vertical
          ? Metrics.horizontalScale(16)
          : Metrics.horizontalScale(24),
        position: 'relative',
        borderRadius: Metrics.horizontalScale(4),
        overflow: 'hidden',
        borderWidth: 1,
        borderColor: '#FF7235C5',
      }}>
      <View
        style={{
          position: 'relative',
          zIndex: 10,
          flexDirection: vertical ? 'column' : 'row',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}>
        <View>
          <CustomFastImage
            source={{uri: image}}
            width={Metrics.horizontalScale(50)}
            height={Metrics.horizontalScale(50)}
            backgroundFallbackColor="transparent"
          />
        </View>
        <View
          style={{
            marginLeft: vertical ? 0 : Metrics.horizontalScale(25),
          }}>
          <Typography variant="h3" style={{textAlign: textAlign}}>
            {title}
          </Typography>
          <Typography
            variant="body3"
            style={{
              maxWidth: vertical ? undefined : Metrics.horizontalScale(208),
              marginTop: Metrics.verticalScale(8),
              textAlign,
            }}>
            {description}
          </Typography>
        </View>
      </View>
      <LinearGradient
        style={[StyleSheet.absoluteFill]}
        locations={[0.114, 1]}
        colors={['rgba(251, 133, 25, 0.3)', 'rgba(255, 170, 91, 0.129)']}
        useAngle={true}
        angle={242.31}
      />
    </View>
  );
}
