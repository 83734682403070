import AppDropDownTopMenu from '@src/App/components/Common/AppDropDownTopMenu/AppDropDownTopMenu';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import {ITabBarHeaderItem} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import Typography from 'design-system/src/Components/Typography/Typography';
import {typographyColor} from 'design-system/src/Components/Typography/Typography.props';
import React, {FC} from 'react';

interface SubMenuItemProps {
  title: string;
  onClick?: () => void;
  children?: ITabBarHeaderItem['children'];
  color?: keyof typeof typographyColor;
}

const SubMenuItem: FC<SubMenuItemProps> = props => {
  const {title, onClick, children, color} = props;

  return (
    <div style={{cursor: 'pointer'}}>
      {children ? (
        <AppDropDownTopMenu
          titleVariant="button1"
          title={title}
          items={children.map(item => ({
            title: item.title,
            onPress: item.onClick,
          }))}
        />
      ) : (
        <PressableCustom onPress={onClick}>
          {({hover}) => (
            <Typography variant="button1" color={hover ? 'primary' : color}>
              {title}
            </Typography>
          )}
        </PressableCustom>
      )}
    </div>
  );
};

export default SubMenuItem;
