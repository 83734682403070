import React, {useEffect, useState} from 'react';
import {ViewStyle, StyleProp} from 'react-native';
import InputDate from './InputDate/InputDate';

export interface IDatePickerProps {
  defaultValue?: string;
  defaultDate?: Date;
  value?: string;
  onChange?: (value: string) => void;
  label: string;
  style?: StyleProp<ViewStyle>;
  dateFormat?: string;
}

const DatePicker = (props: IDatePickerProps) => {
  const {value: inValue, onChange, dateFormat = 'YYYY-MM-DD', ...rest} = props;

  const [value, setValue] = useState<string>(inValue || '');
  useEffect(() => {
    if (inValue !== value) {
      setValue(inValue || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inValue, value]);

  const handleChange = (selectedDate: string) => {
    setValue(selectedDate || '');
    onChange && onChange(selectedDate || '');
  };

  return (
    <InputDate
      {...rest}
      defaultValue={value}
      momentFormat={dateFormat}
      onChange={handleChange}
    />
  );
};

export default DatePicker;
