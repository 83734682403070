import {View} from 'react-native';
import React, {useMemo, ReactNode} from 'react';
import {ICoursePageFragmentFragment} from '~Api/Graphql/gql/types.generated';
import Metrics from 'design-system/src/Theme/Metrics';
import UcVideo from './UcVideo/UcVideo';
import UcLineBreak from './UcLineBreak/UcLineBreak';
import UcImage from './UcImage/UcImage';
import WysiwygBlock from '~Umbraco/Components/Learn/WysiwygBlock/WysiwygBlock';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import UcAudio from './UcAudio/UcAudio';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import CoursePageWrapper, {ECoursePageTemplate} from './CoursePage.Wrapper';
import ExamOverviewWrapper from './ExamOverview.Wrapper';

export interface ICoursePageProps {
  content?: ICoursePageFragmentFragment;
  hasPadding?: boolean;
  learnProperty?: ILearnPropertyName;
  courseTitle?: string;
  overview?: {
    pageContent?: any;
    subtitle?: string;
    cover?: string;
  };
  isConclusion?: boolean;
  isExamenOverview?: boolean;
}

interface IData {
  renderBlocks: ReactNode;
  imageCover?: string;
  contentSubtitle?: string;
  template: ECoursePageTemplate;
}

const CoursePage = (props: ICoursePageProps) => {
  const {
    content,
    hasPadding,
    learnProperty,
    overview,
    courseTitle,
    isConclusion,
    isExamenOverview,
  } = props;
  const {navMessage, title, subtitle, blocks, cover} = content || {};

  const {t} = useTranslation(['learn']);
  const {isMobile} = useBreakpoint();

  const pageTitle = useMemo(() => {
    const _title = title || courseTitle;
    return _title ? _title : isConclusion ? t('conclusion') : undefined;
  }, [isConclusion, title, courseTitle, t]);

  const data: IData = useMemo(() => {
    if (overview) {
      const {
        pageContent,
        subtitle: overviewSubtitle,
        cover: overviewCover,
      } = overview;
      return {
        renderBlocks: (
          <WysiwygBlock
            title={''}
            body={pageContent?.replace(/{{course}}/g, courseTitle || '') || ''}
          />
        ),
        imageCover: overviewCover,
        contentSubtitle: overviewSubtitle?.replace(
          /{{course}}/g,
          courseTitle || '',
        ),
        template: 'overview',
      };
    } else if (blocks) {
      let template: ECoursePageTemplate | undefined;
      const _renderBlocks = blocks.map((block, index) => {
        if (block && block.content) {
          if (block.content.__typename === 'UcVideo') {
            if (!template) {
              template = 'media';
            }
            return (
              <UcVideo
                key={index}
                content={block.content}
                learnProperty={learnProperty}
                aspectRatio={isMobile ? 3 / 4 : 407 / 488}
              />
            );
          } else if (block.content.__typename === 'UcLineBreak') {
            if (!template) {
              template = 'text';
            }
            // TO TEST
            return <UcLineBreak key={index} content={block.content} />;
          } else if (block.content.__typename === 'UcImage') {
            if (!template) {
              template = 'image';
            }
            return <UcImage key={index} content={block.content} />;
          } else if (block.content.__typename === 'Wysiwyg') {
            if (!template) {
              template = 'text';
            }
            return (
              <WysiwygBlock
                key={index}
                title={''}
                body={block.content.body || ''}
              />
            );
          } else if (block.content.__typename === 'UcAudio') {
            if (!template) {
              template = 'media';
            }
            // TO TEST
            return <UcAudio key={index} content={block.content} />;
          }
        }
        return null;
      });
      return {
        renderBlocks: _renderBlocks,
        imageCover:
          cover &&
          cover[0] &&
          cover[0].content &&
          cover[0].content.__typename === 'HeroVideoCover'
            ? cover[0].content.image?.url
            : undefined,
        template: template || 'text',
      };
    }
    return {
      renderBlocks: null,
      imageCover:
        cover &&
        cover[0] &&
        cover[0].content &&
        cover[0].content.__typename === 'HeroVideoCover'
          ? cover[0].content.image?.url
          : undefined,
      template: 'overview',
    };
  }, [blocks, cover, overview, courseTitle, isMobile, learnProperty]);
  // const fadeInOpacity = useSharedValue(1);
  // useEffect(() => {
  //   fadeInOpacity.value = withTiming(0, {
  //     duration: 7000,
  //     easing: Easing.linear,
  //   });
  // }, []);
  // const animatedStyle = useAnimatedStyle(() => {
  //   return {
  //     opacity: fadeInOpacity.value,
  //   };
  // });

  const {renderBlocks, imageCover, contentSubtitle, template} = data;
  return (
    <View testID="course-page">
      {isExamenOverview ? (
        <ExamOverviewWrapper
          imageCover={imageCover}
          template={template}
          title={pageTitle}
          subtitle={contentSubtitle || subtitle}>
          <View
            style={
              hasPadding && {
                paddingTop: Metrics.verticalScale(isMobile ? 10 : 108),
                marginHorizontal: Metrics.horizontalScale(isMobile ? 24 : 60),
                maxWidth: Metrics.horizontalScale(332),
              }
            }>
            <View testID="block">{renderBlocks}</View>
          </View>
        </ExamOverviewWrapper>
      ) : (
        <CoursePageWrapper
          imageCover={imageCover}
          template={template}
          title={pageTitle}
          subtitle={contentSubtitle || subtitle}
          messages={
            navMessage && navMessage.__typename === 'NavMessage'
              ? navMessage
              : undefined
          }>
          <View
            style={
              hasPadding && {
                paddingTop: Metrics.verticalScale(108),
                paddingHorizontal: Metrics.horizontalScale(24),
              }
            }>
            <View testID="block">{renderBlocks}</View>
          </View>
        </CoursePageWrapper>
      )}
    </View>
  );
};

export default CoursePage;
