import _ from 'lodash';
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {StyleProp, StyleSheet, Pressable, View, ViewStyle} from 'react-native';
import {IconName} from '../../assets/Icones';
import Metrics from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import {RadioButtonItem} from './RadioButtonItem/RadioButtonItem';
import CustomFastImage from '../CustomFastImage/CustomFastImage';
import ContentLoader, {Rect} from 'react-content-loader/native';

export type RadioButtonProps = {
  groups: RadioButtonItem[];
  onChecked?(data: RadioButtonItem): void;
  itemStyle?: StyleProp<ViewStyle>;
  renderDescription?(description: string): void;
  useChoice?: boolean;
  display?: 'row' | 'column';
  showResult?: boolean;
  multiple?: boolean;
  value?: string[];
  onChange?: (value: string[], newValue?: string, id?: string) => void;
  useCustomValue?: boolean;
  useCheckValue?: boolean;
  isLoading?: boolean;
};

export default function RadioButton(props: RadioButtonProps) {
  const {
    groups = [],
    onChecked,
    itemStyle,
    renderDescription,
    useChoice,
    display = 'column',
    multiple = false,
    onChange,
    value = [],
    showResult,
    useCustomValue,
    useCheckValue,
    isLoading,
  } = props;
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  useEffect(() => {
    if (
      value &&
      !_.isEqual(value, selectedValue) &&
      (onChange || useCheckValue)
    ) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [imageLoading, setImageLoading] = useState<boolean>();

  const handleChecked = useCallback(
    (index: number, data: RadioButtonItem) => {
      if (!imageLoading) {
        setSelectedValue(currentList => {
          let newList: string[] = [];
          let newValue: string | undefined = undefined;
          if (multiple) {
            if (currentList.includes(data.value)) {
              newList = currentList.filter(item => item !== data.value);
            } else {
              newValue = data.value;
              newList = [...currentList, data.value];
            }
          } else {
            newList = [data.value];
          }
          onChange && onChange(newList, newValue, data.id);
          return newList;
        });
        onChecked && onChecked(data);
      }
    },
    [multiple, onChange, onChecked, imageLoading],
  );

  const isDisplayRow = useMemo(() => {
    return !!groups.find(_item => _item.image !== undefined);
  }, [groups]);

  return (
    <View
      style={StyleSheet.flatten([
        isDisplayRow && {
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        },
        {
          alignItems: 'center',
        },
      ])}>
      {groups.map((g, index) => {
        const isSelected = selectedValue.includes(g.value);
        const isCorrect = g.isCorrect;
        const info: {
          borderColor: string;
          backgroundColor: string;
          icon: IconName;
          iconColor: string;
        } = {
          borderColor: '#2F3139',
          backgroundColor: '#16181E',
          icon: 'check-empty',
          iconColor: '#737373',
        };
        if (g.isAnswered) {
          if (showResult) {
            if (isCorrect) {
              info.borderColor = !g.select
                ? 'rgba(5, 201, 107, 0.8)'
                : '#EA6C13';
              info.backgroundColor = 'rgba(5, 201, 107, 0.2)';
              info.icon = 'check-circle';
              info.iconColor = 'rgba(5, 201, 107, 0.8)';
            } else {
              info.borderColor = !g.select ? '#BE030A' : '#EA6C13';
              info.backgroundColor = 'rgba(255, 30, 30, 0.13)';
              info.icon = 'invalide';
            }
          } else {
            info.borderColor = '#EA6C13';
            info.backgroundColor = 'rgba(234, 108, 19, 0.13)';
            info.icon = 'check-circle';
            info.iconColor = '#EA6C13';
          }
        } else if (g.select) {
          info.borderColor = '#EA6C13';
          info.backgroundColor = 'rgba(234, 108, 19, 0.13)';
          info.iconColor = '#EA6C13';
          info.icon = 'check-empty';
        } else if (!useCustomValue) {
          if (isSelected && showResult) {
            if (isCorrect || !useChoice) {
              info.borderColor = 'rgba(5, 201, 107, 0.8)';
              info.backgroundColor = 'rgba(5, 201, 107, 0.2)';
              info.icon = 'check-circle';
              info.iconColor = 'rgba(5, 201, 107, 0.8)';
            } else {
              info.borderColor = '#BE030A';
              info.backgroundColor = 'rgba(255, 30, 30, 0.13)';
              info.icon = 'invalide';
            }
          } else {
            if (isSelected) {
              info.borderColor = '#EA6C13';
              info.backgroundColor = 'rgba(234, 108, 19, 0.13)';
              info.icon = 'check-circle';
              info.iconColor = '#EA6C13';
            } else {
              info.borderColor = '#2F3139';
              info.backgroundColor = '#16181E';
              info.icon = 'check-empty';
              info.iconColor = '#737373';
            }
          }
        }
        return (
          <Pressable
            key={g.value + `${index}`}
            onPress={() => handleChecked(index, g)}
            style={StyleSheet.flatten([
              {
                borderWidth: g.image ? undefined : 1,
                borderRadius: Metrics.horizontalScale(4),
                marginBottom: Metrics.verticalScale(16),
                borderColor: info.borderColor,
                backgroundColor: !g.image
                  ? info.backgroundColor
                  : 'transparent',
              },
              !isDisplayRow ? {width: '100%'} : radioButtonStyles.content,
              itemStyle,
            ])}>
            {!!g.image && (
              <CustomFastImage
                hideLoading={true}
                style={StyleSheet.flatten([
                  radioButtonStyles.image,
                  radioButtonStyles.content,
                  {
                    borderWidth: imageLoading ? undefined : 1,
                    borderRadius: Metrics.horizontalScale(4),
                    borderColor: info.borderColor,
                  },
                ])}
                source={{uri: g.image}}
                onLoadStart={() => {
                  setImageLoading(true);
                }}
                onLoadEnd={() => {
                  setImageLoading(false);
                }}
              />
            )}
            <View
              style={{
                paddingHorizontal: Metrics.horizontalScale(16),
                paddingVertical: Metrics.verticalScale(12),
              }}>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <View style={{width: 24}}>
                  <Icon name={info.icon} color={info.iconColor} />
                </View>
                {!g.image && (
                  <Typography
                    style={{
                      marginLeft: Metrics.horizontalScale(10),
                      flexShrink: 1,
                    }}
                    variant="body2">
                    {g.label}
                  </Typography>
                )}
              </View>
              {!!g.description && !g.image && (
                <>{renderDescription && renderDescription(g.description)}</>
              )}
            </View>
            {imageLoading && (
              <ContentLoader
                height={radioButtonStyles.content.height}
                width={radioButtonStyles.content.width}
                style={{
                  borderWidth: 1,
                  borderColor: '#111216',
                  position: 'absolute',
                }}
                speed={1}
                backgroundColor={'rgba(23, 24, 29, 0.7)'}
                foregroundColor={'#111216'}>
                <Rect
                  x="0"
                  y="0"
                  rx="5"
                  ry="5"
                  height={radioButtonStyles.content.width}
                  width={radioButtonStyles.content.height}
                />
              </ContentLoader>
            )}
          </Pressable>
        );
      })}
    </View>
  );
}

export const radioButtonStyles = StyleSheet.create({
  image: {
    position: 'absolute',
    borderRadius: Metrics.horizontalScale(4),
  },
  content: {
    width: Metrics.horizontalScale(160),
    height: Metrics.horizontalScale(160),
  },
});
