import {useApolloClient} from '@apollo/client';
import FIREBASE_ERROR_CODE from '@src/Api/Constants/firebase.error';
import {
  useResetPasswordMutation,
  useLazyRemoveMemberQuery,
  useLazyTraceSignUpQuery,
  useLazyCheckMemberEmailExistQuery,
} from '@src/Api/TraceApi/traceApi';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import {
  appleProvider,
  auth,
  facebookProvider,
  googleProvider,
} from '@src/firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  UserCredential,
  signInWithCustomToken,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {toast} from 'react-toastify';
import {navigate} from 'gatsby';
import {useAppDispatch} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {useCurrentLanguage} from '@src/Store/Slices/LanguageSlice/Language.selector';
import {languageMapper} from '@src/tools/languageMapper';
import memberSlice from '@src/Store/Slices/Member.slice';
import useIsLoggedIn from '@src/Providers/AuthProvider/useIsLoggedIn';

export interface IAuthForm {
  email: string;
  password: string;
}

export interface IFirebaseAuthenticationResponse {
  status: 'success' | 'error';
  user: UserCredential['user'] | null;
  errorMessage: string | null;
}

const useFireBaseAuthentication = () => {
  const client = useApolloClient();
  const {t} = useTranslation('auth');
  const [traceSignUp] = useLazyTraceSignUpQuery();
  const currentLang = useCurrentLanguage();
  const tracker = useTracker();
  const [removeMember] = useLazyRemoveMemberQuery();
  const [resetPassword] = useResetPasswordMutation();
  const dispatch = useAppDispatch();
  const [checkMemberEmailExist] = useLazyCheckMemberEmailExistQuery();
  const {checkAuth} = useIsLoggedIn();

  const isMemberEmailExist = async (email: string) => {
    try {
      const {data} = await checkMemberEmailExist(email);
      return !!data;
    } catch (error) {
      return false;
    }
  };

  const isEmailExist = async (email: string) => {
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      console.log('methods', methods);
      return methods.length > 0;
    } catch (error) {
      console.log('error', error);
    }
  };

  const signUp = async (
    formData: any,
  ): Promise<IFirebaseAuthenticationResponse> => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'success',
      user: null,
    };

    const singUpResponse = await traceSignUp(formData);

    if (singUpResponse.error) {
      response.status = 'error';
      const error = singUpResponse.error as any;
      console.log('error', error);
      if (error.data && error.data.message) {
        if (Array.isArray(error.data.message)) {
          response.errorMessage = error.data.message.join('\n');
        } else {
          response.errorMessage = error.data.message;
        }
      } else {
        response.errorMessage = t('Sign up failed !');
      }
    } else {
      if (singUpResponse && singUpResponse.data && singUpResponse.data.token) {
        dispatch(appAuthSlice.actions.setAuthType('login'));
        try {
          const userCredential = await signInWithCustomToken(
            auth,
            singUpResponse.data.token,
          );
          const user = userCredential.user;
          response.status = 'success';
          response.errorMessage = null;
          response.user = user;

          await checkAuth(response.user);
        } catch (error) {
          console.log('error', error);
          response.status = 'error';
          response.errorMessage = t('Sign up failed !');
        }
      } else {
        response.status = 'error';
        response.errorMessage = t('Sign up failed !');
      }
    }
    return response;
  };

  const signUpWithMail = async (formData: IAuthForm) => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'success',
      user: null,
    };
    await createUserWithEmailAndPassword(
      auth,
      formData.email,
      formData.password,
    )
      .then(userCredential => {
        const user = userCredential.user;
        response.status = 'success';
        response.errorMessage = null;
        response.user = user;
      })
      .catch(error => {
        console.log('error', error);
        response.status = 'error';
        response.errorMessage = t('Sign up failed !');
      });
    return response;
  };

  const signIn = async (
    formData: IAuthForm,
  ): Promise<IFirebaseAuthenticationResponse> => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'success',
      user: null,
    };

    await signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then(userCredential => {
        const user = userCredential.user;
        response.status = 'success';
        response.errorMessage = null;
        response.user = user;
      })
      .catch(error => {
        response.status = 'error';
        if (error.code === FIREBASE_ERROR_CODE.userNotFound) {
          response.errorMessage = t('User not found !');
        } else if (error.code === FIREBASE_ERROR_CODE.wrongPassword) {
          response.errorMessage = t('Wrong password !');
        } else {
          response.errorMessage = t('Sign in failed !');
        }
      });

    const isMemberExist = await isMemberEmailExist(formData.email);

    if (!isMemberExist) {
      response.status = 'error';
      response.errorMessage = t('Member not found');
    }

    return response;
  };

  const signInWithGoogle = async (
    signUp?: boolean,
  ): Promise<IFirebaseAuthenticationResponse> => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'error',
      user: null,
    };
    try {
      const result = await signInWithPopup(auth, googleProvider);
      if (result) {
        const credential = GoogleAuthProvider.credentialFromResult(result);

        if (credential) {
          const user = result.user;
          response.status = 'success';
          response.errorMessage = null;
          response.user = user;

          const isMemberExist = user.email
            ? await isMemberEmailExist(user.email ?? '')
            : false;

          if (!signUp) {
            if (!isMemberExist) {
              response.status = 'error';
              response.errorMessage = t('Member not found');
            }
          } else {
            if (isMemberExist) {
              response.status = 'error';
              response.errorMessage = t('Member already exist');
            }
          }
        }
      } else {
        response.status = 'error';
        response.errorMessage = t('Google auth error');
      }
    } catch (error) {
      // const credential = GoogleAuthProvider.credentialFromError(error);
      console.log('Google auth error', error);
      response.status = 'error';
      response.errorMessage = t('Google auth error');
    }
    return response;
  };

  const signInWithFacebook = async (
    signUp?: boolean,
  ): Promise<IFirebaseAuthenticationResponse> => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'error',
      user: null,
    };
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      if (result) {
        const credential = FacebookAuthProvider.credentialFromResult(result);

        if (credential) {
          const user = result.user;
          response.status = 'success';
          response.errorMessage = null;
          response.user = user;

          const isMemberExist = user.email
            ? await isMemberEmailExist(user.email ?? '')
            : false;

          if (!signUp) {
            if (!isMemberExist) {
              response.status = 'error';
              response.errorMessage = t('Member not found');
            }
          } else {
            if (isMemberExist) {
              response.status = 'error';
              response.errorMessage = t('Member already exist');
            }
          }
        }
      } else {
        response.status = 'error';
        response.errorMessage = t('facebook auth error');
      }
    } catch (error) {
      // const credential = GoogleAuthProvider.credentialFromError(error);
      console.log('facebook auth error', error);
      response.status = 'error';
      response.errorMessage = t('facebook auth error');
    }

    return response;
  };

  const signInWithApple = async (
    signUp?: boolean,
  ): Promise<IFirebaseAuthenticationResponse> => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'error',
      user: null,
    };
    appleProvider.addScope('email');
    appleProvider.addScope('name');

    try {
      const result = await signInWithPopup(auth, appleProvider);

      const credential = OAuthProvider.credentialFromResult(result);

      if (credential) {
        const user = result.user;
        response.status = 'success';
        response.errorMessage = null;
        response.user = user;

        const isMemberExist = user.email
          ? await isMemberEmailExist(user.email ?? '')
          : false;

        if (!signUp) {
          if (!isMemberExist) {
            response.status = 'error';
            response.errorMessage = t('Member not found');
          }
        } else {
          if (isMemberExist) {
            response.status = 'error';
            response.errorMessage = t('Member already exist');
          }
        }
      } else {
        response.status = 'error';
        response.errorMessage = t('Apple auth error');
      }
    } catch (error) {
      // const credential = OAuthProvider.credentialFromError(error);

      console.log('apple auth error', error);
      response.status = 'error';
      response.errorMessage = t('Apple auth error');
    }

    return response;
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      localStorage.setItem('ft', '');
      googleProvider.setCustomParameters({prompt: 'select_account'});
      facebookProvider.setCustomParameters({prompt: 'select_account'});
      appleProvider.setCustomParameters({prompt: 'select_account'});
      dispatch(memberSlice.actions.setMember(undefined));

      const currentLocation = window.location.href;

      if (currentLocation?.includes('/app')) {
        try {
          try {
            client.stop();
            await client.clearStore();
            await client.resetStore();
          } catch (error) {
            console.log('🚀 ~ signOut ~ resetStore error:', error);
          }
          dispatch(
            appAuthSlice.actions.openAuthenticationModal({
              open: true,
              redirect: `${currentLocation}`,
            }),
          );
          navigate(`/`, {
            replace: true,
          });
        } catch (error) {
          console.log('🚀 ~ signOut ~ error:', error);
        }
      }
    } catch (error) {
      console.log('🚀 ~ signOut ~ error:', error);
      // toast.error(t('Sign out failed !'));
    }
  };

  const isConnected = async () => {
    return !!auth.currentUser;
  };

  const traceForgotPassword = async (email: string) => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'success',
      user: null,
    };

    await resetPassword({email, lang: languageMapper(currentLang)})
      .unwrap()
      .then(() => {
        response.status = 'success';
        response.errorMessage = null;
      })
      .catch(error => {
        response.status = 'error';

        if (error.status === 404) {
          response.errorMessage = t('Email not found !');
        } else {
          response.errorMessage = t('Reset password failed !');
        }
      });
    return response;
  };

  const forgotPassword = async (
    email: string,
  ): Promise<IFirebaseAuthenticationResponse> => {
    const response: IFirebaseAuthenticationResponse = {
      errorMessage: null,
      status: 'success',
      user: null,
    };
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        response.status = 'success';
        response.errorMessage = null;
      })
      .catch(error => {
        response.status = 'error';

        if (error.code === FIREBASE_ERROR_CODE.userNotFound) {
          response.errorMessage = t('User not found !');
        } else {
          response.errorMessage = t('Forgot password failed !');
        }
      });

    return response;
  };

  const deleteCurrentUser = async (formData: IAuthForm) => {
    try {
      // Check if user is logged in
      const currentUser = auth.currentUser;
      if (!currentUser) {
        toast.error(t('No user is currently logged in'));
        return undefined;
      }
  
      // Check authentication providers
      const providers = currentUser.providerData.map(provider => provider.providerId);
      
      // Check if using email/password or social auth
      const isEmailProvider = providers.includes('password') && providers.length === 1;
      const isGoogleProvider = providers.includes('google.com');
      const isFacebookProvider = providers.includes('facebook.com');
      const isAppleProvider = providers.includes('apple.com');
      
      if (isEmailProvider) {
        // Handle email/password authentication deletion
        const { email, password } = formData;
        await signInWithEmailAndPassword(auth, email, password)
          .then(async userCredential => {
            if (userCredential.user) {
              await removeMember();
              await auth.currentUser?.delete();
              await signOut();
            } else {
              toast.error(t('Password error'));
            }
          })
          .catch(error => {
            console.log('error', error);
            if (error.code === FIREBASE_ERROR_CODE.userNotFound) {
              toast.error(t('User not found !'));
            } else if (error.code === FIREBASE_ERROR_CODE.wrongPassword) {
              toast.error(t('Wrong password !'));
            } else {
              toast.error(t('Something went wrong !'));
            }
          });
      } else if (isGoogleProvider || isFacebookProvider || isAppleProvider) {
        // For social providers, we need to reauthenticate first
        try {
          let provider;
          if (isGoogleProvider) {
            provider = googleProvider;
          } else if (isFacebookProvider) {
            provider = facebookProvider;
          } else if (isAppleProvider) {
            provider = appleProvider;
          }
          
          if (provider) {
            // Reauthenticate with the appropriate provider
            const result = await signInWithPopup(auth, provider);
            if (result && result.user) {
              await removeMember();
              await auth.currentUser?.delete();
              await signOut();
            }
          } else {
            toast.error(t('Provider not supported for deletion'));
          }
        } catch (error) {
          console.log('error on social auth deletion', error);
          toast.error(t('Failed to delete social account'));
        }
      } else {
        toast.error(t('Unknown authentication provider'));
      }
  
      return undefined;
    } catch (error) {
      tracker.errorEncountered('user', 'Error on user remove');
      throw error;
    }
  };

  return {
    signUp,
    signIn,
    signOut,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
    isEmailExist,
    signUpWithMail,
    isConnected,
    forgotPassword,
    deleteCurrentUser,
    traceForgotPassword,
  };
};

export default useFireBaseAuthentication;
