import {Pressable, ScrollView, View} from 'react-native';
import React, {ReactNode, useMemo} from 'react';
import {
  IPaywallFragmentFragment,
  usePaywalByIdQuery,
} from '~Api/Graphql/gql/types.generated';
import {mapHeroVideoCover} from '~Umbraco/Utils/mapHeroVideoCover';
import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import PayWallHeaderTitle from 'design-system/src/Widget/PayWall/PayWallHeader/PayWallHeaderTitle/PayWallHeaderTitle';
import {
  // ILinkTo,
  IMapButtonResult,
} from '~Umbraco/Utils/element/mapButton';
import Metrics from 'design-system/src/Theme/Metrics';
import Typography from 'design-system/src/Components/Typography/Typography';
import {IUmbracoRouteParams} from '~Umbraco/Interface/Umbraco.interface';

import AdvantageList from './AdvantageList/AdvantageList';
import ServiceList from './ServiceList/ServiceList';
import MediaCover from 'design-system/src/Widget/MediaCover/MediaCover';
import PaywallConfirmPage from '../PaywallConfirmPage/PaywallConfirmPage';
import {useDispatch} from 'react-redux';
import useDefaultPaywall from '@src/Store/selectors/useDefaultPaywall';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import styled from 'styled-components';
import {responsiveMobileBreakpoint} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {IPaywallOrientation} from '../PaywallSection.type';

interface IPaywallHomePageProps {
  id?: string;
  onSelectPlan?: () => void;
  variant?: 'paywall' | 'choosePlan';
  orientation?: IPaywallOrientation;
}

const BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 60px;

  @media ${responsiveMobileBreakpoint} {
    padding: 0;
  }

  & > * {
    width: 100%;
  }

  & > *:nth-child(2),
  & > *:nth-child(3) {
    width: 50%;

    @media ${responsiveMobileBreakpoint} {
      width: 100%;
    }
  }
`;

const PaywallHomePage = (props: IPaywallHomePageProps) => {
  const {
    id,
    onSelectPlan,
    variant = 'paywall',
    orientation = 'portrait',
  } = props;
  const isChoosePlan = variant === 'choosePlan';
  const isLandscape = orientation === 'landscape';
  const navigation = useAppNavigation();
  const {t} = useTranslation('profile');
  const defaultPaywall = useDefaultPaywall();
  const {data} = usePaywalByIdQuery({
    variables: {id: id},
    skip: !id,
    errorPolicy: 'all',
  });
  const {isMobile} = useBreakpoint();

  const dispatch = useDispatch();

  const paywallResponse: IPaywallFragmentFragment | undefined =
    (data && data.paywall) || defaultPaywall;

  const paywallData: {
    mediaCover?: IMediaCover;
    title?: string;
    subtitle?: string;
    buttons?: IMapButtonResult[];
    mentionLegalLabel?: string;
    mentionLink?: IUmbracoRouteParams;
    renderBlocks?: ReactNode[];
  } = useMemo(() => {
    let _mediaCover: IMediaCover | undefined;
    const buttons: IMapButtonResult[] = [];
    const renderBlocks: ReactNode[] = [];
    if (paywallResponse) {
      const {cover, title, subtitle, pageBlocks} = paywallResponse;
      if (
        cover &&
        cover[0] &&
        cover[0].content &&
        cover[0].content.__typename === 'HeroVideoCover'
      ) {
        _mediaCover = mapHeroVideoCover(cover[0].content, isMobile);
      }
      // if (cTAs) {
      //   cTAs.forEach(cta => {
      //     if (cta && cta.content.__typename === 'Button') {
      //       const ctaData = mapButton(cta.content);
      //       ctaData && buttons.push(ctaData);
      //     }
      //   });
      // }

      // render blocks
      if (pageBlocks) {
        pageBlocks
          .filter(block => {
            if (isChoosePlan) {
              return (
                block &&
                block.content &&
                block.content.__typename === 'SubscriptionsList'
              );
            }
            return true;
          })
          .forEach((block, blockIndex) => {
            if (block && block.content) {
              if (block.content.__typename === 'AdvantagesList') {
                renderBlocks.push(
                  <AdvantageList
                    key={blockIndex}
                    avantages={block.content.advantages || []}
                    template={block.content.template as any}
                    style={{marginBottom: Metrics.verticalScale(16)}}
                  />,
                );
              } else if (block.content.__typename === 'ServicesList') {
                renderBlocks.push(
                  <ServiceList
                    key={blockIndex}
                    services={block.content.services || []}
                    title={block.content.title}
                    style={{
                      paddingTop: Metrics.verticalScale(27),
                      paddingBottom: Metrics.verticalScale(24),
                      marginBottom: Metrics.verticalScale(16),
                    }}
                  />,
                );
              } else if (block.content.__typename === 'SubscriptionsList') {
                const listProduct: string[] = [];
                block.content.subscriptionsList?.forEach(_el => {
                  if (
                    _el &&
                    _el.__typename === 'Subscription' &&
                    _el.productID
                  ) {
                    listProduct.push(_el.productID);
                  }
                });

                renderBlocks.push(
                  <View key={blockIndex} style={{}}>
                    <PaywallConfirmPage
                      variant="paywall"
                      orientation={orientation}
                      listFilter={i => listProduct.includes(i.planId)}
                      productIdOrder={listProduct}
                      needStore={true}
                      horizontal={!isMobile}
                      contentWrapperStyle={
                        isMobile
                          ? undefined
                          : {
                              paddingHorizontal: 0,
                            }
                      }
                    />
                    <View
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: isMobile
                          ? Metrics.verticalScale(19)
                          : 86 - 16,
                        marginBottom: isChoosePlan
                          ? 0
                          : isMobile
                            ? Metrics.verticalScale(68)
                            : Metrics.verticalScale(68),
                        paddingHorizontal: isMobile
                          ? 74
                          : Metrics.horizontalScale(60),
                      }}>
                      <Pressable
                        style={{
                          marginTop: Metrics.verticalScale(7),
                        }}
                        onPress={() => {
                          navigation.navigate(
                            AppRoutesEnum.TERMS_AND_CONDITION,
                            {
                              state: {
                                params: {type: 'termsConditions'},
                              },
                            },
                          );
                        }}>
                        <Typography
                          children={t('Terms and conditions')}
                          variant="ui3"
                          color="gray"
                        />
                      </Pressable>
                    </View>
                  </View>,
                );
              }
            }
          });
      }
      return {
        mediaCover: _mediaCover,
        title,
        subtitle,
        buttons,
        renderBlocks,
      };
    }
    return {};
  }, [paywallResponse, isMobile, isChoosePlan]);
  const {mediaCover, title, subtitle, renderBlocks} = paywallData;

  return (
    <View style={{minHeight: '100vh'}}>
      <MediaCover
        orientation={orientation}
        isActive={true}
        cover={mediaCover}
        bottomGradientLocation={isMobile ? [0, 0.47, 0.85] : [0, 0.47, 1]}
        bottomGradientStyles={{
          bottom: Metrics.verticalScale(0),
          height: '100%',
        }}
        contentContainerStyle={{paddingTop: Metrics.verticalScale(0)}}>
        <ScrollView
          testID="paywall-home-page-scroll-view"
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingTop: isLandscape ? (isMobile ? 45 : 107) : 178,
            paddingBottom: isMobile ? 16 : 0,
          }}
          style={{
            overflow: isMobile ? 'hidden' : undefined,
          }}>
          <View
            style={{
              width: isMobile ? '100%' : '50%',
              marginTop: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            <PayWallHeaderTitle
              title={title}
              description={subtitle}
              style={{marginBottom: isMobile ? 0 : 16}}
            />
          </View>
          {/* Page blocks */}
          <BlockContainer>{renderBlocks}</BlockContainer>
        </ScrollView>
      </MediaCover>
    </View>
  );
};

export default PaywallHomePage;
