import React, {useState} from 'react';
import SettingContent, {
  SettingContentProps,
} from 'design-system/src/Components/SettingContent/SettingContent';
import {View} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
// import {useLazyUpdateMemberQuery} from '~Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {useDispatch} from 'react-redux';
import memberSlice from '~Store/Slices/Member.slice';
import {
  useLanguages,
  useCurrentLanguage,
} from '~Store/Slices/LanguageSlice/Language.selector';
import {useLazyUpdateMemberQuery} from '~Api/TraceApi/traceApi';
import useMember from '@src/Store/selectors/useMember';
import {useRegions} from '@src/Store/selectors/useRegions';
import appSlice from '@src/Store/Slices/AppSlice';
import {Member} from '@src/Api/Umbraco/interface/Member.interface';

declare global {
  interface Window {
    Didomi: any;
    didomiRemoteConfig?: {
      notices?: Array<{
        config?: {regulation?: {name?: 'none' | 'gdpr' | 'ccpa'}};
      }>;
    };
  }
}
export enum EQuality {
  AUTO = 'auto',
  HD = 'HD',
  STANDARD = 'Standard',
}

const ProfileSetting = () => {
  const member = useMember();
  const dispatch = useDispatch();
  const {t} = useTranslation('profile');
  const currentLanguage = useCurrentLanguage();

  const isGdprApplicable =
    window.didomiRemoteConfig?.notices?.[0]?.config?.regulation?.name !==
    'none';

  const {regionOptions, findRegionsByDocumentUrl} = useRegions();
  const [loadingKey, setLoadingKey] = useState<string>(' ');
  const [updateMember] = useLazyUpdateMemberQuery();
  const languages = useLanguages();

  const qualityOptions = [
    {
      label: t('standard'),
      value: EQuality.STANDARD,
    },
    {
      label: t('HD'),
      value: EQuality.HD,
    },
    {
      label: t('auto'),
      value: EQuality.AUTO,
    },
  ];

  const handleSelect = (selectData: {
    fieldKey: keyof Member;
    label: string;
    defaultValue?: string | number;
    options: Array<{label: string; value: string | number}>;
  }) => {
    const {fieldKey, label, options, defaultValue} = selectData;
    if (member) {
      dispatch(
        appSlice.actions.setIsOpenProfilEditDrawer({
          isOpenProfilEditDrawer: true,
          data: {
            field: fieldKey,
            headerTitle: label,
            select: options,
            defaultValue,
          },
        }),
      );
    }
  };

  const handlePreferences = async () => {
    const link = document.createElement('a');
    link.href = 'javascript:Didomi.preferences.show()';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSwitch = (key: string) => (value: boolean) => {
    if (member) {
      const parseValue = (i: boolean) => (i ? '1' : '0');
      setLoadingKey(key);
      dispatch(
        memberSlice.actions.setMember({
          ...member,
          [key]: parseValue(value),
        }),
      );
      updateMember({
        data: {
          ...member,
          [key]: value,
        },
      })
        .catch(() => {
          dispatch(
            memberSlice.actions.setMember({
              ...member,
              [key]: parseValue(!value),
            }),
          );
        })
        .finally(() => setLoadingKey(''));
    }
  };

  const languageDefault = (member?.language ||
    currentLanguage ||
    'en-US') as string;
  const dataSetting: SettingContentProps[] = [
    {
      title: t('Language and Region'),
      settings: [
        {
          type: 'text',
          key: 'language',
          title: t('Language'),
          disabled: true,
          // value: currentLanguage || '',
          value: languageDefault,
          onPress: ({key, title}) => {
            member &&
              handleSelect({
                fieldKey: key as keyof Member,
                label: title,
                options: languages?.map(lang => ({
                  value: lang.isoCode,
                  label: lang.cultureName,
                })),
                defaultValue: languageDefault,
              });
          },
        },
        {
          type: 'text',
          key: 'region',
          title: t('Region'),
          disabled: !member,
          value:
            (member &&
              member.region &&
              findRegionsByDocumentUrl(member.region)?.name) ||
            '',
          onPress: ({key, title}) =>
            member &&
            handleSelect({
              fieldKey: key as keyof Member,
              label: title,
              options: regionOptions,
            }),
        },
      ],
    },
    {
      title: t('Streaming'),
      settings: [
        {
          type: 'text',
          key: 'videoQuality',
          title: t('Video quality'),
          disabled: !member,
          value: ((member && member.videoQuality) || EQuality.AUTO) as string,
          onPress: ({key, title}) =>
            member &&
            handleSelect({
              fieldKey: key as keyof Member,
              label: title,
              options: qualityOptions,
              defaultValue: EQuality.AUTO,
            }),
        },
        {
          type: 'text',
          key: 'audioQuality',
          title: t('Audio quality'),
          disabled: !member,
          value: ((member && member.audioQuality) || EQuality.AUTO) as string,
          onPress: ({key, title}) =>
            member &&
            handleSelect({
              fieldKey: key as keyof Member,
              label: title,
              options: qualityOptions,
              defaultValue: EQuality.AUTO,
            }),
        },
      ],
    },
    /* {
          title: t('Download'),
          settings: [
            {
              type: 'switch',
              key: 'WifiOnly',
              title: t('Wifi only'),
              value: !!+member.WifiOnly,
              onChange: handleSwitch('WifiOnly'),
            },
            {
              type: 'text',
              key: 'downloadQuality',
              title: t('Download quality'),
              value: member.downloadQuality?.join(' '),
              onPress: ({key, title}) =>
                handleSelect({
                  fieldKey: key,
                  label: title,
                  options: qualityOptions,
                }),
            },
            {
              type: 'text',
              key: 'delete',
              title: t('Delete all downloaded files'),
              disabled: true,
            },
          ],
        }, */
    {
      title: t('Preferences'),
      settings: [
        {
          type: 'switch',
          key: 'allowNotifications',
          title: t('Allow notifications'),
          value: member && !!+(member.allowNotifications || ''),
          onChange: handleSwitch('allowNotifications'),
        },
        {
          type: 'text',
          key: 'allowCookies',
          title: t('Allow cookies'),
          disabled: !member || !isGdprApplicable,
          onPress: handlePreferences,
        },
      ],
    },
  ];
  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        backgroundColor: '#111216',
      }}>
      <Typography variant="h2">{t('Setting and preferences')}</Typography>
      <View style={{marginTop: Metrics.verticalScale(29)}}>
        {dataSetting.map((d, index) => (
          <SettingContent {...d} key={`st-${index}`} loadingKey={loadingKey} />
        ))}
      </View>
    </View>
  );
};

export default ProfileSetting;
