import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useEffect, useState} from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  Animated,
  ScrollViewProps,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import {FormPage} from '~Api/Umbraco/interface/UmbracoTypes';
import {useUmbracoFormContext} from '../UmbracoFormContext';
import UmbracoFieldset from './UmbracoFieldset/UmbracoFieldset';
import Button from 'design-system/src/Components/Button/Button';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import LinearGradient from 'react-native-linear-gradient';
import debounce from 'lodash/debounce';
import {useSelector} from '@legendapp/state/react';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface IFormPageProps {
  page: FormPage;
  hideTitle?: boolean;
  currentPage: number;
  submitLabel?: string;
  nextLabel?: string;
  moveNext: () => Promise<boolean>;
  movePrev?: () => void;
  // onSubmit: (pageData: F) => void;
  isLast?: boolean;
  fixedSubmit?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  pageContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  header?: ReactNode;
  onScroll?: ScrollViewProps['onScroll'];
  autoValidate?: boolean;
  disableSubmitTracking?: boolean;
}
const UmbracoFormPage = (props: IFormPageProps) => {
  const {
    page: {caption, fieldsets},
    hideTitle,
    submitLabel,
    nextLabel,
    moveNext,
    currentPage,
    isLast = true,
    fixedSubmit,
    contentContainerStyle,
    header,
    onScroll,
    pageContainerStyle,
    autoValidate,
    style,
    disableSubmitTracking,
  } = props;
  const {t} = useTranslation('translation');
  const {isMobile} = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const reactForm = useForm({mode: autoValidate ? 'onChange' : 'onSubmit'});
  const {handleSubmit, formState} = reactForm;
  const {isValid} = formState;
  const {errors: formErrors, mergedForm} = useUmbracoFormContext();
  const allError = useSelector(() => formErrors && formErrors.get());
  const buttonLabel = isLast
    ? submitLabel || t('Submit')
    : nextLabel || t('Next');

  useEffect(() => {
    if (mergedForm) {
      console.log('mergedForm', mergedForm.get());
      const mainForm = mergedForm.get();
      reactForm.reset(mainForm);
    } else {
      reactForm.reset();
    }
    return () => {
      if (formErrors) {
        formErrors.set({});
      }
    };
  }, [formErrors, reactForm, currentPage, mergedForm]);

  const handleError: SubmitErrorHandler<FieldValues> = errors => {
    if (formErrors) {
      formErrors.set(errors);
    }
    setLoading(false);
  };

  const handleFormSumbit: SubmitHandler<FieldValues> = async formData => {
    setLoading(true);
    if (mergedForm) {
      const currentForm = mergedForm.get();
      // Save page form data to main form
      mergedForm.set({
        ...currentForm,
        ...formData,
        _buttonLabel: buttonLabel,
      });
    }
    const response = await moveNext();
    if (response) {
      formErrors && formErrors.set({});
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const submit = debounce(handleSubmit(handleFormSumbit, handleError), 500);
  const footerHeight = Metrics.verticalScale(140);
  const toalFieldSet = fieldsets.length;

  const button = (
    <>
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: 'transparent',
          width: '100%',
          paddingHorizontal: isMobile ? 24 : 0,
        }}>
        <Button
          title={buttonLabel + ''}
          style={{marginVertical: Metrics.verticalScale(14)}}
          containerStyle={{width: '100%'}}
          fullWidth={true}
          variant={
            !autoValidate ? 'contained' : isValid ? 'contained' : 'disabled'
          }
          disabled={autoValidate ? !isValid : undefined}
          size="small"
          onPress={submit}
          loading={loading}
          disableTracking={isLast ? disableSubmitTracking : undefined}
        />
      </View>
      {!!Object.keys(allError || {}).length && (
        <View>
          <Typography
            variant="ui3"
            style={{marginTop: Metrics.verticalScale(0)}}
            color={() => '#BE030A'}>
            {t('Please fix the highlighted fields in the form to continue')}
          </Typography>
        </View>
      )}
    </>
  );

  return (
    <View style={[{position: 'relative', flex: 1}, style]}>
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={60}
        contentContainerStyle={[
          contentContainerStyle,
          fixedSubmit && {paddingBottom: footerHeight / 1.3},
        ]}
        onScroll={onScroll}>
        {header}

        <View
          style={[
            {
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              paddingHorizontal: isMobile ? 24 : 0,
            },
            pageContainerStyle,
          ]}>
          <FormProvider {...reactForm}>
            {!hideTitle && (
              <Typography
                variant="body2"
                style={{marginBottom: Metrics.verticalScale(44)}}>
                {caption}
              </Typography>
            )}
            <View style={{flex: 1, alignSelf: 'stretch', paddingTop: 24, zIndex: 1}}>
              {fieldsets.map((fieldset, index) => {
                return (
                  <UmbracoFieldset
                    fieldset={fieldset}
                    key={index}
                    index={toalFieldSet - index}
                  />
                );
              })}
            </View>

            {!fixedSubmit && button}
          </FormProvider>
        </View>
      </Animated.ScrollView>

      {fixedSubmit && (
        <LinearGradient
          colors={['rgba(17, 18, 22, 0)', '#111216']}
          locations={[0.0656, 0.2673]}
          angle={178.4}
          style={{
            paddingHorizontal: Metrics.horizontalScale(24),
            position: 'absolute',
            bottom: 0,
            zIndex: 6,
            flex: 1,
            width: '100%',
            height: footerHeight,
            paddingBottom: Metrics.verticalScale(36),
            backgroundColor: 'trasparent',
            justifyContent: 'flex-end',
          }}>
          {button}
        </LinearGradient>
      )}
    </View>
  );
};

export default React.memo(UmbracoFormPage);
