import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const bottomTabNavigationItemStyles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  text: {
    marginTop: Metrics.verticalScale(8),
  },
});
