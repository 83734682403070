import React, {useMemo, useState} from 'react';
import {FlatList, ListRenderItem, StyleSheet, View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import Button, {IButtonProps} from '../Button/Button';
import {CustomFastImageProps} from '../CustomFastImage/CustomFastImageProps';
import CustomHeader from '../Header/CustomHeader/CustomHeader.web';
import {carouselHeaderStyles} from '../Header/CarouselHeader/CarouselHeader.styles';
import ServiceTitle from '../ServiceTitle/ServiceTitle';
import Typography from '../Typography/Typography';
import Carousel from '../../WebComponent/Carousel/Carousel.web';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

export interface IBlockItemTitleSlider {
  title: string;
  description?: string;
  image?: CustomFastImageProps['source'];
  overTitle?: string;
  icon?: CustomFastImageProps['source'];
  button?: {
    url?: string;
    id: string;
    __typename: string;
    buttonProps?: IButtonProps;
  };
}
export interface IBlockTitleSlider {
  slideTitle: string;
  slideSubtitle?: string;
  slides: Array<IBlockItemTitleSlider>;
}

export type ServicePromoteProps = {
  title?: string;
  items: Array<IBlockTitleSlider>;
  onButtonItemPress?: (item: IBlockItemTitleSlider) => void;
};

export default function ServicePromote(props: ServicePromoteProps) {
  const {title, items, onButtonItemPress} = props;

  const [currentSlider, setCurrentSlider] = useState(0);
  const {isMobile} = useBreakpoint();

  const carouselRef = React.createRef<any>();

  const {slides} = useMemo(() => items[currentSlider], [currentSlider, items]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const renderItem: ListRenderItem<IBlockTitleSlider> = renderData => {
    const {index, item} = renderData;
    return (
      <Button
        title={item.slideTitle}
        size="xsmall"
        variant={currentSlider === index ? 'contained' : 'filled'}
        style={{marginLeft: 4, marginRight: 4}}
        onPress={() => {
          setCurrentSlider(index);
          carouselRef.current?.scrollTo({index: 0, animated: true});
          setCurrentIndex(0);
        }}
      />
    );
  };

  return (
    <div
      style={{
        marginTop: 44,
        maxWidth: 1098,
        width: '100%',
        margin: '0px auto',
      }}>
      {title && <ServiceTitle title={title} />}
      <FlatList
        style={{marginTop: 21, marginBottom: 23}}
        contentContainerStyle={{
          paddingHorizontal: 16,
          ...(isMobile
            ? {}
            : {
                width: '100%',
                justifyContent: 'center',
              }),
        }}
        data={items}
        renderItem={renderItem}
        horizontal
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      />
      <Carousel
        items={slides}
        onSnapToItem={index => {
          setCurrentIndex(index);
        }}
        render={item => {
          const {image, title: _title, description, button} = item;
          return (
            <View>
              <CustomHeader
                hideButtonIcon={true}
                contentBottom={16}
                image={image}
                title={_title}
                buttonProps={{
                  ...button?.buttonProps,
                  variant: 'contained',
                  size: 'small',
                }}
                buttonTitle={button?.buttonProps?.title || ''}
                // imageHeight={Metrics.window.width * (16 / 9)}
                onPressButton={() => {
                  onButtonItemPress && onButtonItemPress(item);
                  // TODO Go to formulaire
                }}>
                {description && (
                  <Typography
                    variant="body3"
                    style={{
                      maxWidth: Metrics.horizontalScale(417),
                      textAlign: 'center',
                    }}>
                    {description}
                  </Typography>
                )}
              </CustomHeader>
            </View>
          );
        }}
        title={''}
      />
      {items[currentSlider].slides.length > 1 && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginVertical: Metrics.horizontalScale(35),
          }}>
          {items[currentSlider].slides.map((_, index) => {
            return (
              <View
                key={index}
                style={[
                  carouselHeaderStyles.whiteCircle,
                  StyleSheet.create({
                    temp: {
                      opacity: index === currentIndex ? 1 : 0.5,
                    },
                  }).temp,
                ]}
              />
            );
          })}
        </View>
      )}
    </div>
  );
}
