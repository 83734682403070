import {IExamProgress, IExamResult} from '../interface/exam';
import {TraceApiEndpointBuilder} from '../traceApi';

export const createExamEndpoint = (builder: TraceApiEndpointBuilder) => ({
  computeExamProgress: builder.query<IExamResult, IExamProgress>({
    query: body => ({
      url: `/course-exam?culture=${body.culture}`,
      method: 'POST',
      body,
    }),
  }),
});
