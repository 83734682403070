import React from 'react';
import {View} from 'react-native';
import Typography from '../../Components/Typography/Typography';
import useBreakpoint from '../Grid/hooks/useBreakpoint';
import GallerieContainer from './GallerieContainer/GallerieContainer';

interface IImageGallerieProps {
  overTitle?: string;
  title?: string;
  description?: string;
  galeries?: {url?: string}[];
}

const ImageGallerie = (props: IImageGallerieProps) => {
  const {overTitle, title, description, galeries} = props;
  const {isMobile} = useBreakpoint();

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: isMobile ? 86 : 96,
        overflow: 'hidden',
        paddingBottom: isMobile ? 77 : 96,
      }}>
      {!!overTitle ||
        !!title ||
        (!!description && (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: isMobile ? 77 : 130,
              maxWidth: isMobile ? '90%' : '50%',
            }}>
            {!!overTitle && (
              <Typography
                variant="ui2"
                style={{marginBottom: isMobile ? 19 : 40}}>
                {overTitle}
              </Typography>
            )}
            {!!title && (
              <Typography
                variant={isMobile ? 'h1' : 'big2'}
                style={{marginBottom: isMobile ? 11 : 36, textAlign: 'center'}}>
                {title}
              </Typography>
            )}
            {!!description && (
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                style={{textAlign: 'center'}}>
                {description}
              </Typography>
            )}
          </View>
        ))}
      <GallerieContainer galeries={galeries} />
    </View>
  );
};

export default ImageGallerie;
