import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberWatchedBody} from './interface/memberDataBody.interface';
import {IWatchedResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/watched';
export const createMemberWatchedEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberWatchedById: builder.query<IWatchedResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberWatched: builder.query<IWatchedResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  updateMemberWatched: builder.query<
    IWatchedResponse,
    {id?: string; data: IMemberWatchedBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
});
