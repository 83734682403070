import {StyleSheet, Dimensions} from 'react-native';
import Metrics from '../../../Theme/Metrics';
const {width: viewportWidth} = Dimensions.get('window');
function wp(percentage: number) {
  const value = (percentage * viewportWidth) / 100;
  return Metrics.horizontalScale(Math.round(value));
}

const PhoneNumberPickerStyles = StyleSheet.create({
  container: {
    width: wp(80),
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    height: Metrics.verticalScale(48),
  },
  flagButtonView: {
    // width: wp(20),
    height: '100%',
    minWidth: 32,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flagButtonExtraWidth: {
    // width: wp(23),
  },
  shadow: {
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: {
      width: 1,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
  },
  dropDownImage: {
    height: 14,
    width: 12,
  },
  textContainer: {
    paddingHorizontal: wp(1),
    flex: 1,
  },
  codeText: {
    fontSize: Metrics.fontScale(16),
    marginRight: 10,
    fontWeight: '500',
    color: '#FFFFFF',
  },
  numberText: {
    fontSize: Metrics.fontScale(16),
    color: '#FFFFFF',
    flex: 1,
  },
  root: {
    paddingHorizontal: Metrics.horizontalScale(24),
  },
});

export default PhoneNumberPickerStyles;
