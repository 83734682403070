import useParams from '@src/Hooks/useParams';
import ThankYouPage from '@src/Umbraco/Pages/ThankYouPage/ThankYouPage';
import React from 'react';

const AppThankYouPage = () => {
  const {id} = useParams();
  return (
    <div>
      <ThankYouPage id={id} fullScreen />
    </div>
  );
};

export default AppThankYouPage;
