import React, {useEffect, useState} from 'react';
import {Pressable, PressableProps} from 'react-native';
import Icon, {IIconProps} from '../../../Components/Icon/Icon';
import Typography from '../../../Components/Typography/Typography';
import useDefaultTheme from '../../../Theme/useTheme';
import {DiscoverActionButtonStyles} from './DiscoverActionButton.styles';
import FavorisIcon, {
  FavorisProps,
} from '../../../Components/CheckBox/FavoirsIcon/FavorisIcon';

interface IDiscoverActionButtonProps extends PressableProps {
  icon: IIconProps;
  count?: number;
  hideCount?: boolean;
  onPressFav?: FavorisProps['onPressFav'];
  isLikeType?: boolean;
  isLiked?: boolean;
}

const DiscoverActionButton = (props: IDiscoverActionButtonProps) => {
  const {
    icon,
    style,
    count = 0,
    isLikeType,
    onPressFav,
    isLiked,
    hideCount,
    ...rest
  } = props;
  const theme = useDefaultTheme();
  const [likeCount, setLikeCount] = useState(count);

  useEffect(() => {
    if (count !== likeCount) {
      setLikeCount(count);
    }
  }, [count, likeCount]);

  const {
    name = 'favoris',
    size = '36',
    color = theme.white,
    ...iconProps
  } = icon;

  return (
    <Pressable style={[DiscoverActionButtonStyles.root, style]} {...rest}>
      {isLikeType ? (
        <FavorisIcon
          isLiked={isLiked}
          onPressFav={value => {
            if (value) {
              setLikeCount(prev => prev + 1);
            } else {
              setLikeCount(prev => prev - 1);
            }
            onPressFav && onPressFav(value);
          }}
        />
      ) : (
        <Icon name={name} size={size} color={color} {...iconProps} />
      )}
      {typeof count !== 'undefined' && !hideCount && (
        <Typography
          style={DiscoverActionButtonStyles.count}
          variant="ui1"
          color={() => color}>
          {likeCount}
        </Typography>
      )}
    </Pressable>
  );
};

export default DiscoverActionButton;
