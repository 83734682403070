import React, {ReactNode} from 'react';
import ProfileHeader from './ProfileHeader';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {View} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography';
import {darkTheme} from 'design-system/src/styles/Themes';

interface IProfileWrapperProps {
  children: ReactNode;
  title?: string | null;
  subtitle?: string;
  withSeparator?: boolean;
}

const ProfileWrapper = (props: IProfileWrapperProps) => {
  const {children, title, subtitle, withSeparator} = props;
  const {isMobile} = useBreakpoint();
  return (
    <div
      style={{
        position: 'relative',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: '#111216',
      }}>
      <ProfileHeader />
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}>
        <View
          style={{
            padding: isMobile ? 16 : 60,
            marginTop: 118,
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: isMobile ? 'flex-start' : 'center',
              flexDirection: 'column',
              marginBottom: 6,
              maxWidth: 450,
              margin: isMobile ? undefined : '0 auto',
              textAlign: 'center',
            }}>
            <Typography variant={isMobile ? 'h2' : 'big2'}>{title}</Typography>
          </div>
          {withSeparator && !isMobile && (
            <hr
              style={{
                color: darkTheme.colors.fontGray,
                opacity: 0.5,
                marginTop: 62,
                width: '100%',
              }}
            />
          )}
          {children}
        </View>
      </div>
    </div>
  );
};

export default ProfileWrapper;
