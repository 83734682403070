import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import MenuNavigation from '../../MenuNavigation/MenuNavigation';
import React, {useEffect, useState} from 'react';
import {IconName} from 'design-system/src/assets/Icones';

interface IFloatingMenuProps {
  data: Queries.LandingPageQuery['umbraco']['allLandingPage']['items'][0];
}

const FloatingMenu = (props: IFloatingMenuProps) => {
  const {isMobile} = useBreakpoint();

  const [activeSection, setActiveSection] = useState('');

  const {data} = props;

  const pageBlocs = (
    data?.pageBlocks
      ?.filter(item => !!item)
      .map(item => {
        if (!item) return null;
        const {content} = item;
        if (!content) return null;
        if (content.contentTypeAlias === 'heroServices') {
          return content as Queries.Umbraco_HeroServices;
        }
        return null;
      }) as Queries.Umbraco_HeroServices[]
  ).filter(item => item !== null && item.showInMenu);

  const checkActiveSection = () => {
    const sections = pageBlocs.map(item => item.navigationTitle || '');
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          setActiveSection(sectionId);
          break;
        } else {
          setActiveSection('');
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkActiveSection);
    return () => {
      window.removeEventListener('scroll', checkActiveSection);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({behavior: 'smooth'});
    }
  };

  if (isMobile || !pageBlocs || pageBlocs.length === 0) return null;

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 10,
        top: 24,
        left: 0,
        width: '100%',
        height: 0,
        textAlign: 'center',
      }}>
      <MenuNavigation
        list={pageBlocs.map(item => ({
          label: item.navigationTitle || '',
          icon: item.navigationIcon as IconName,
          onClick: () => scrollToSection(item.navigationTitle || ''),
          isActive: activeSection === item.navigationTitle,
        }))}
      />
    </div>
  );
};

export default FloatingMenu;
