import React from 'react';
import {StyleSheet, View} from 'react-native';

const CustomBlurView = () => {
  return <View style={styles.blur} />;
};

export default CustomBlurView;

const styles = StyleSheet.create({
  blur: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
});
