export const darkTheme = {
  colors: {
    primaryOrange: '#EA6C13',
    white: 'white',
    smokeWhite: '#F0F0F0',
    black: 'black',
    primaryBlack: '#181818',
    secondaryBlack: '#1B1C21',
    thirdBlack: '#2E2E2E',
    primaryGray: '#4D4D4D',
    secondaryGray: '#737373',
    thirdGray: '#BFBFBF',
    fourthGray: '#EEEEEE',
    fifthGray: '#4A4A4A',
    sixthGray: '#B1B1B1',
    seventhGray: '#F5F5F5',
    fontGray: '#A0A0A0',
    backgroundBlack: '#111216',
    backgroundGrey: '#2F3035',
  },
};

export const lightTheme = {
  colors: {
    primaryOrange: '#EA6C13',
    white: 'white',
    smokeWhite: '#F0F0F0',
    black: 'black',
    primaryBlack: '#181818',
    secondaryBlack: '#1B1C21',
    thirdBlack: '#2E2E2E',
    primaryGray: '#4D4D4D',
    secondaryGray: '#737373',
    thirdGray: '#BDBDBD',
    fourthGray: '#EEEEEE',
    fifthGray: '#4A4A4A',
    sixthGray: '#B1B1B1',
    seventhGray: '#F5F5F5',
    fontGray: '#A0A0A0',
    backgroundBlack: '#111216',
    backgroundGrey: '#2F3035',
  },
};
