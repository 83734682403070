import React from 'react';
import CardItem, {ICardItem} from './CardItem/CardItem';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface ICardListProps {
  cards?: ICardItem[];
  isTakeFirst?: boolean;
  isNoPaddingBottom?: boolean;
  desktopPadding?: number;
}

const CardList = (props: ICardListProps) => {
  const {cards, isTakeFirst, isNoPaddingBottom, desktopPadding = 36} = props;
  const {isMobile} = useBreakpoint();

  if (!cards || cards.length === 0) return null;

  return (
    <div
      style={{
        paddingTop: 48,
        paddingBottom: isNoPaddingBottom ? undefined : 48,
        paddingLeft: isMobile ? 16 : desktopPadding,
        paddingRight: isMobile ? 16 : desktopPadding,
        display: 'grid',
        gap: 16,
        gridTemplateColumns: isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)',
      }}>
      {cards.map((item, index) => (
        <CardItem
          key={index}
          item={item}
          isTakeFirst={index === 0 && isTakeFirst}
        />
      ))}
    </div>
  );
};

export default CardList;
