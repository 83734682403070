import {useTvAuthCodeMutation} from '@src/Api/TraceApi/traceApi';
import useNavigation from '@src/Navigation/useNavigation';
import {AuthContext} from '@src/Providers/AuthProvider/AuthProvider';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {useSnackbarNotification} from '@src/Store/Slices/SnackbarSlice/SnackBar.selector';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import Button from 'design-system/src/Components/Button/Button';
import CustomActivityIndicator from 'design-system/src/Components/CustomActivityIndicator/CustomActivityIndicator';
import Typography from 'design-system/src/Components/Typography/Typography';
import FlexBox from 'design-system/src/WebComponent/FlexBox/FlexBox.web';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import React, {useContext, useEffect, useState} from 'react';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import MultiInputController from 'design-system/src/Components/Form/MultiInputController/MultiInputController';
import {toast} from 'react-toastify';

interface ITVAuthForm {
  code: string;
}

const TvAuthCode = () => {
  const {control, handleSubmit} = useForm<ITVAuthForm>();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tvAuthCode] = useTvAuthCodeMutation();
  const {isMobile} = useBreakpoint();
  const navigation = useNavigation();
  const {cover, webCover} = useAppSelector(state => state.settings.onboarding);
  const background = isMobile ? cover : webCover;
  const {isLoggedIn, isLoading} = useContext(AuthContext);
  const dispatch = useAppDispatch();

  const snackbar = useSnackbarNotification();

  const handleSubmitSendCode: SubmitHandler<ITVAuthForm> = async data => {
    const {code} = data;
    try {
      setLoading(true);
      const response = await tvAuthCode({code: code.toUpperCase()});

      // @ts-ignore
      if (response.data) {
        toast.success(t('Authentication success!'));
        setTimeout(() => {
          navigation.navigate('/');
        }, 8000);
      } else {
        toast.error(t('Authentication error! Please try again.'));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(t('Authentication error! Please try again.'));
    } finally {
      setLoading(false);
    }
  };

  const handleError: SubmitErrorHandler<ITVAuthForm> = err => {
    const list = Object.values(err);
    if (list[0] && list[0].message) {
      if (typeof list[0].message === 'string') {
        snackbar.add({
          message: list[0].message,
          title: t('Error'),
          duration: 8000,
        });
      } else {
        // if (list[0].message.message) {
        //   Alert.alert(list[0].message.message);
        // }
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (!isLoggedIn) {
        dispatch(
          appAuthSlice.actions.openAuthenticationModal({
            open: true,
            redirect: '/tv-auth',
          }),
        );
      } else {
        dispatch(
          appAuthSlice.actions.openAuthenticationModal({
            open: false,
            redirect: undefined,
          }),
        );
      }
    }
  }, [isLoading, isLoggedIn]);

  if (isLoading) {
    return (
      <div style={{width: '100%', height: '100%', minHeight: '100vh'}}>
        <CustomActivityIndicator />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: '#111216',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        position: 'relative',
      }}>
      <div
        style={{
          backgroundColor: '#111216',
          width: '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          position: 'absolute',
          zIndex: 1,
          aspectRatio: isMobile ? 3 / 4 : 1142 / 538,
          backgroundPosition: 'center',
        }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 44.86%, rgba(17, 18, 22, 0.80) 71.63%, #111216 100%)',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}></div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',

            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            zIndex: 2,
            backgroundColor: '#00000066',
          }}></div>
      </div>

      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 49,
          paddingTop: 90,
          overflow: 'scroll',
          position: 'relative',
          top: 0,
          left: 0,
          zIndex: 3,
        }}>
        <div
          style={{
            width: '100%',
            maxWidth: 418,
            padding: '0 16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Typography
            variant="h1"
            style={{
              textTransform: 'uppercase',
              fontSize: 48,
              lineHeight: 56,
              marginBottom: 25,
              textAlign: 'center',
            }}>
            {t('Authorize authentification')}
          </Typography>
          <FlexBox
            direction="column"
            gap="16px"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <MultiInputController<ITVAuthForm>
              name="code"
              length={6}
              control={control}
              rules={{
                required: true,
              }}
            />
            <div style={{marginTop: 32}}>
              <Button
                variant="contained"
                size="medium"
                title={t('Authorize')}
                fullWidth
                loading={loading}
                onPress={handleSubmit(handleSubmitSendCode, handleError)}
              />
            </div>
          </FlexBox>
        </div>
      </div>
    </div>
  );
};

export default TvAuthCode;
