import React, {FC, Suspense, useContext} from 'react';
import {RouteComponentProps} from '@reach/router';
import {AuthContext} from '@src/Providers/AuthProvider/AuthProvider';
// import useAppNavigation from '@src/utils/useAppNavigation';
// import {AppRoutesEnum} from '@src/Constant/routes';

export interface IPrivateRouteProps extends RouteComponentProps {
  component: FC<RouteComponentProps>;
}

const PrivateRoute = ({
  component: Component,
  location,
  ...rest
}: IPrivateRouteProps) => {
  const {isLoggedIn, isLoading} = useContext(AuthContext);
  // const navigation = useAppNavigation();

  if (!isLoggedIn && !isLoading) {
    // If the user is not logged in, redirect to the login page.
    // const isApp =
    //   location?.pathname.endsWith('/app') ||
    //   location?.pathname.endsWith('/app/');
    // if (!isApp) {
    //   navigation.navigate(AppRoutesEnum.HOME);
    // }
    return null;
  }
  return (
    <Suspense fallback={null}>
      <Component {...rest} />
    </Suspense>
  );
};

export default PrivateRoute;
