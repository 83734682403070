import useParams from '@src/Hooks/useParams';
import {useAppDispatch} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import PushQuizCard from 'design-system/src/Components/Card/PushQuizCard/PushQuizCard';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import CarouselLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/CarouselLoader';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {
  ICourseCategoryFragmentFragment,
  useAllCourseByPersonalityLazyQuery,
  useGetPersonalityPageQuery,
} from '~Api/Graphql/gql/types.generated';
import CourseSlider from '~Umbraco/Components/CourseSlider/CourseSlider';
import WysiwygBlock from '~Umbraco/Components/Learn/WysiwygBlock/WysiwygBlock';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {useMapCourseCateg} from '~Umbraco/Utils/content/mapCourseCategItems';
import UcImage from '../../LearnHomePage/UnitScreen/UnitPages/CoursePage/UcImage/UcImage';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

const PersonalityPage = () => {
  const params = useParams();
  const {t} = useTranslation(['learn']);

  const {id} = params || {};
  const response = useGetPersonalityPageQuery({
    variables: {
      id,
    },
  });
  const [fetchCourses, {data: dataCourses, loading: courseLoading}] =
    useAllCourseByPersonalityLazyQuery();
  const loading = response.loading;
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const {handleClickCourseSlideItem} = useSectionPageUtils();
  const {mapCourseCategItems} = useMapCourseCateg();
  const {isMobile} = useBreakpoint();

  function calculateWidth() {
    var viewportWidth = window.innerWidth; // Get the viewport width in pixels
    var result = viewportWidth - 0.2 * viewportWidth; // Subtract 20% of the viewport width
    return result; // Return the result in pixels
  }

  const {pageBlocks, serviceHeader} = useMemo(() => {
    const {data} = response;
    let _pageBlocks: ReactNode[] = [];
    let serviceHeader: ReactNode = <div></div>;
    if (data && data.personalityPages) {
      const {title, subtitle, introduction, body, cover, linkedTag, parent} =
        data.personalityPages;
      if (linkedTag && linkedTag[0]) {
        fetchCourses({
          variables: {
            id: linkedTag[0].id,
          },
        });
      }
      serviceHeader = (
        <ServiceHeader
          key={'p-header'}
          title={title || ''}
          description={introduction || ''}
          subTitle={subtitle || ''}
          gradiantVariant="bottom"
          image={{
            uri:
              cover &&
              cover[0] &&
              cover[0].content &&
              cover[0].content.__typename === 'HeroVideoCover'
                ? cover[0].content.image?.url
                : undefined,
          }}
        />
      );

      if (body) {
        let outputContent: ReactNode[] = [];
        body.forEach((item, index) => {
          if (item && item.content) {
            if (item.content.__typename === 'UcImage') {
              outputContent.push(
                <UcImage key={index} content={item.content} />,
              );
            } else if (item.content.__typename === 'Wysiwyg') {
              outputContent.push(
                <WysiwygBlock
                  key={index}
                  title={item.content.title || ''}
                  body={item.content.body || ''}
                  bodyColor="white"
                />,
              );
            }
          }
        });
        if (outputContent.length) {
          _pageBlocks.push(...outputContent);
        }
      }
      if (
        parent &&
        parent.__typename === 'PersonalityQuiz1' &&
        parent.children &&
        parent.children.items
      ) {
        let listPush: ReactNode[] = [];
        const items = parent.children.items;
        for (let item of items) {
          if (
            item &&
            item.__typename === 'PersonalityPages' &&
            item.id !== id
          ) {
            const {icon, title, subtitle, id, url, __typename} = item;
            listPush.push(
              <View
                key={`${id}-push`}
                style={{
                  marginBottom: Metrics.verticalScale(8),
                }}>
                <PushQuizCard
                  title={title || ''}
                  description={subtitle || ''}
                  variant="blur"
                  icon={() => (
                    <CustomFastImage
                      backgroundFallbackColor="transparent"
                      width={Metrics.horizontalScale(32)}
                      height={Metrics.horizontalScale(32)}
                      source={{
                        uri: icon?.url,
                      }}
                    />
                  )}
                  onPressArrowIc={() => {
                    new RouteFactory(
                      {
                        id,
                        url: url || '',
                        __typename,
                      },
                      navigation,
                      dispatch,
                    ).navigate();
                  }}
                  descTypo="ui3"
                />
              </View>,
            );
          }
          if (listPush.length === 2) {
            break;
          }
        }
        if (listPush.length) {
          _pageBlocks.push(
            <View
              key={'other'}
              style={{
                marginVertical: Metrics.verticalScale(42),
              }}>
              <Typography
                variant="h3"
                style={{marginBottom: Metrics.verticalScale(14)}}>
                {t('Others')}
              </Typography>
              {listPush}
            </View>,
          );
        }
      }
    }
    return {pageBlocks: _pageBlocks, serviceHeader, isMobile};
  }, [response]);

  const courseSlider = useMemo(() => {
    let _courseSlider: ReactNode = undefined;
    if (dataCourses && dataCourses.allCourse && dataCourses.allCourse.items) {
      const items = mapCourseCategItems(
        dataCourses.allCourse
          .items as ICourseCategoryFragmentFragment['children']['items'],
      );
      _courseSlider = (
        <CourseSlider
          key={'slider'}
          data={items}
          title={t('just for you')}
          onItemPress={handleClickCourseSlideItem}
        />
      );
    }
    return _courseSlider;
  }, [dataCourses, mapCourseCategItems]);

  const {column1, column2} = useMemo(() => {
    if (!isMobile) {
      return pageBlocks.reduce(
        (
          acc: {column1: ReactNode[]; column2: ReactNode[]},
          block,
          currentIndex,
        ) => {
          if (currentIndex === 0 || currentIndex === pageBlocks.length - 1) {
            acc.column1.push(block);
          } else {
            acc.column2.push(block);
          }
          return acc;
        },
        {column1: [], column2: []},
      );
    }
    return {
      column1: [],
      column2: [],
    };
  }, [pageBlocks, isMobile]);

  return (
    <div>
      <DebugUmbracoId umbracoId={id} position="page" />
      {id ? (
        loading ? (
          <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
        ) : (
          <>
            {serviceHeader}
            {isMobile ? (
              <div
                style={{
                  padding: '0 16px',
                }}>
                {pageBlocks}
              </div>
            ) : (
              <div
                style={{
                  padding: '0 60px',
                }}>
                <AppGrid nbColumns={2}>
                  <div
                    style={{
                      width: isMobile ? '100%' : 417,
                    }}>
                    {column1}
                  </div>
                  <div>{column2}</div>
                </AppGrid>
              </div>
            )}
            <View
              style={{
                marginBottom: Metrics.horizontalScale(24),
                width: isMobile ? '100%' : calculateWidth(),
              }}>
              {courseLoading ? <CarouselLoader /> : courseSlider}
            </View>
          </>
        )
      ) : (
        <ContentEmpty
          style={{flex: 1, justifyContent: 'center'}}
          title={t('Section not found')}
        />
      )}
    </div>
  );
};

export default PersonalityPage;
