import React, {ReactNode} from 'react';
import {StyleProp, Pressable, ViewStyle} from 'react-native';
import {IconName} from '../../../assets/Icones';
import useDefaultTheme from '../../../Theme/useTheme';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';
import {typographyVariantStyled} from '../../Typography/Typography.styles';
import {bottomTabNavigationItemStyles} from './BottomTabNavigationItem.styles';

export interface BottomNavigationItemProps {
  onPress?: () => void;
  icon: IconName;
  title?: string;
  isActive?: boolean;
  buttonTextVariant?: keyof typeof typographyVariantStyled;
  style?: StyleProp<ViewStyle>;
  customIcon?: () => ReactNode;
}
const BottomTabNavigationItem = ({
  icon,
  title = 'nav',
  onPress,
  isActive,
  buttonTextVariant = 'ui1',
  style,
  customIcon,
}: BottomNavigationItemProps) => {
  const theme = useDefaultTheme();
  const finalColor = isActive ? theme.primary : theme.white;
  return (
    <Pressable
      onPress={onPress}
      style={[bottomTabNavigationItemStyles.container, style]}>
      {customIcon ? (
        customIcon()
      ) : (
        <Icon name={icon} autoResize={true} size={'24'} color={finalColor} />
      )}
      <Typography
        variant={buttonTextVariant}
        numberOfLines={3}
        color={() => (isActive ? finalColor : theme.gray)}
        style={{
          ...bottomTabNavigationItemStyles.text,
          textAlign: 'center',
        }}>
        {title}
      </Typography>
    </Pressable>
  );
};

export default BottomTabNavigationItem;
