import {IPaywallFragmentFragment} from '~Api/Graphql/gql/types.generated';
import useSelectedRegion from './useSelectedRegion';

function useDefaultPaywall(): IPaywallFragmentFragment | undefined {
  const region = useSelectedRegion();
  if (
    region &&
    region.defaultPaywall &&
    region.defaultPaywall.__typename === 'Paywall'
  ) {
    return region.defaultPaywall;
  }
  return;
}

export default useDefaultPaywall;
