const Constants = {
  BITMOVIN_LICENCE_KEY: '2973bb83-7287-44dc-84dd-e0f2afaf3533',
  XSMALL_WIDTH: 109,
  XSMALL_HEIGHT: 80,
  XSMALL_WIDTH_W: 254,
  XSMALL_HEIGHT_W: 142,
  SMALL_WIDTH: 168,
  SMALL_HEIGHT: 120,
  SMALL_WIDTH_W: 306,
  SMALL_HEIGHT_W: 169,
  MEDIUM_WIDTH: 226,
  MEDIUM_HEIGHT: 160,
  BIG_WIDTH: 343,
  BIG_HEIGHT: 240,
  LEARN_SHEIGHT: 192,
  LEARN_MHEIGHT: 256,
  LEARN_XSHEIGHT: 124,
  NEWS_HEIGHT: 210,
  TOP_WIDTH: 168,
  TOP_HEIGHT: 94.5,
  XBIG_WIDTH: 655,
  XBIG_HEIGHT: 366,
};
export default Constants;
