import React from 'react';
import {_MenuNavigationRoot} from './MenuNavigation.styled';
import {IconName} from 'design-system/src/assets/Icones';
import MenuNavigationItem from './MenuNavigationItem/MenuNavigationItem';

export interface IMenuNavigationItem {
  icon?: IconName;
  label: string;
  link?: string;
  iconLink?: string;
  onClick?: () => void;
  isActive?: boolean;
  translate?: boolean;
}

export interface IMenuNavigationProps {
  list: IMenuNavigationItem[];
}

const MenuNavigation = (props: IMenuNavigationProps) => {
  const {list} = props;
  return (
    <_MenuNavigationRoot>
      {list.map((item, index) => {
        return <MenuNavigationItem key={index} {...item} />;
      })}
    </_MenuNavigationRoot>
  );
};

export default MenuNavigation;
