import React, {useMemo} from 'react';
import {View, Pressable} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import CardProfileMainMenu, {
  CardProfileMainMenuProps,
} from 'design-system/src/Components/Card/CardHomeProfile/CardProfileMainMenu';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ProfileMenu, {
  ProfileMenuProps,
} from 'design-system/src/Components/ProfileMenu/ProfileMenu.web';
import {useAppDispatch} from '@src/Store/hooks';
import useMember from '@src/Store/selectors/useMember';
import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
import memberSlice from '@src/Store/Slices/Member.slice';
import {AppRoutesEnum} from '@src/Constant/routes';
import useAppNavigation from '@src/utils/useAppNavigation';
import useResponsiveSpacing from '@src/Hooks/useResponsiveSpacing';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import {useCgvQuery} from '@src/Api/Graphql/gql/types.generated';

export type CgvData = {
  title: string;
  subtitle?: string;
  body: string;
};

export type CgvType = 'termsConditions' | 'privacyPolicy';

export const ProfileHome = () => {
  const {t} = useTranslation('profile');
  const appNavigation = useAppNavigation();
  const {gap, profile, isMobile} = useResponsiveSpacing();

  const {data} = useCgvQuery();

  const {
    termCondition,
    privacyPolicy,
  }: {termCondition?: CgvData; privacyPolicy?: CgvData} = useMemo(() => {
    let _termsConditions: CgvData | undefined;
    let _privacyPolicies: CgvData | undefined;
    if (
      data &&
      data.allSettings &&
      data.allSettings.items &&
      data.allSettings.items[0]
    ) {
      const {termsConditions, privacyPolicies} = data.allSettings.items[0];
      if (
        termsConditions &&
        termsConditions[0] &&
        termsConditions[0].__typename === 'TextPage'
      ) {
        const {title, body} = termsConditions[0];
        _termsConditions = {
          title: title || '',
          body: body || '',
        };
      }
      if (
        privacyPolicies &&
        privacyPolicies[0] &&
        privacyPolicies[0].__typename === 'TextPage'
      ) {
        const {title, body} = privacyPolicies[0];
        _privacyPolicies = {
          title: title || '',
          body: body || '',
        };
      }
    }
    return {
      termCondition: _termsConditions,
      privacyPolicy: _privacyPolicies,
    };
  }, [data]);

  const member = useMember();
  const userName = useMemo(() => {
    return member ? `${member.firstName} ${member.lastName}` : '';
  }, [member]);
  const dispatch = useAppDispatch();
  const {signOut} = useFireBaseAuthentication();

  const mainMenuData: CardProfileMainMenuProps[] = [
    {
      title: t('Helpdesk'),
      icon: 'help',
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.PROFILE_HELPDESK);
      },
    },
    {
      title: t('Achievment'),
      icon: 'achievment',
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.PROFILE_ACHIEVEMENT, {
          state: {canGoBack: true},
        });
      },
    },
    {
      title: t('Notification'),
      icon: 'notification',
      count: 0,
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.PROFILE_NOTIFICATION, {
          state: {canGoBack: true},
        });
      },
    },
  ];

  const secondaryMenu: ProfileMenuProps[] = [
    /* {
          title: t('Personality quizz'),
          icon: 'personnality',
          onPress: () => navigation.navigate('ProfileQuizz'),
        }, */
    {
      title: t('My profile'),
      icon: 'profil',
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.PROFILE_MY_PROFILE);
      },
    },
    {
      title: t('Subscription'),
      icon: 'abonnement',
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.PROFILE_SUBSCRIPTION);
      },
    },
    {
      title: t('Settings and preferences'),
      icon: 'parametre',
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.PROFILE_SETTINGS);
      },
    },
    {
      title: t('Bookmarks'),
      icon: 'favoris',
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.BOOKMARKS, {
          state: {canGoBack: true},
        });
      },
    },
    {
      title: t('Downloads'),
      icon: 'telechargement',
      disabled: true,
      onPress: () => {},
    },
    {
      title: t('Opportunity'),
      icon: 'opportunity',
      onPress: () => {
        appNavigation.navigate(AppRoutesEnum.PROFILE_OPPORTUNITIES);
      },
    },
    // {
    //   title: t('Application tutorial'),
    //   icon: 'help',
    //   onPress: () => {
    //     appNavigation.navigate(AppRoutesEnum.TUTORIAL);
    //   },
    // },
  ];

  const handleSignOut = async () => {
    await signOut();
    dispatch(memberSlice.actions.setFirebaseUser(undefined));
  };
  return (
    <View
      style={{
        height: '100%',
        backgroundColor: '#111216',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <View>
        <Typography variant="h2">{userName}</Typography>
        <View
          style={{
            flexDirection: 'row',
            // justifyContent: 'space-between',
            // @ts-ignore
            gap: gap,
            marginTop: profile.verticalgap,
          }}>
          {mainMenuData.map((m, index) => (
            <View
              style={{minWidth: Metrics.horizontalScale(109), flex: 1}}
              key={`hm-${index}`}>
              <CardProfileMainMenu {...m} />
            </View>
          ))}
        </View>
        <View style={{marginTop: (isMobile ? 41 : 39) - 12}}>
          {secondaryMenu.map((s, index) =>
            s.hide ? null : (
              <View key={`sm-${index}`}>
                <ProfileMenu {...s} customStyle={[s.customStyle]} />
              </View>
            ),
          )}
        </View>
      </View>
      <View
        // @ts-ignore
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: Metrics.verticalScale(isMobile ? 22 : 62),
          paddingTop: Metrics.verticalScale(12),
          position: 'sticky',
          bottom: -12,
          background: defaultTheme.page.background,
          alignItems: 'flex-end',
        }}>
        <View
          style={{
            gap: Metrics.horizontalScale(12),
          }}>
          {termCondition && (
            <Pressable
              style={{
                justifyContent: 'center',
                maxWidth: Metrics.horizontalScale(200),
              }}
              onPress={() => {
                appNavigation.navigate(AppRoutesEnum.TERMS_AND_CONDITION, {
                  state: {canGoBack: true, params: {type: 'termsConditions'}},
                });
              }}>
              <Typography variant="body3" color="gray">
                {termCondition.title}
              </Typography>
            </Pressable>
          )}
          {privacyPolicy && (
            <Pressable
              style={{
                justifyContent: 'center',
                maxWidth: Metrics.horizontalScale(200),
              }}
              onPress={() => {
                appNavigation.navigate(AppRoutesEnum.TERMS_AND_CONDITION, {
                  state: {
                    canGoBack: true,
                    params: {
                      type: 'privacyPolicy',
                    },
                  },
                });
              }}>
              <Typography variant="body3" color="gray">
                {privacyPolicy.title}
              </Typography>
            </Pressable>
          )}
        </View>
        <Pressable
          style={{
            // height: Metrics.horizontalScale(48),
            justifyContent: 'center',
          }}
          onPress={handleSignOut}>
          <Typography variant="body3">{t('Sign out')}</Typography>
        </Pressable>
      </View>
    </View>
  );
};

export default ProfileHome;
