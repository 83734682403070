/* eslint-disable react-native/no-inline-styles */
import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import CustomProgressBar from '../../Components/CustomProgressBar/CustomProgressBar';
import {CustomHeaderWeb} from '../../Components/Header';
import {CustomHeaderProps} from '../../Components/Header/CustomHeader/CustomHeader.props';
import TitleCategorie from '../../Components/Header/TitleCategorie/TitleCategorie';
import {BottomTabNavigation} from '../../Components/Navigation';
import BottomTabNavigationItem from '../../Components/Navigation/BottomTabNavigationItem/BottomTabNavigationItem';
import {ReadMoreText} from '../../Components/ReadMoreText';
import Typography from '../../Components/Typography/Typography';
import Metrics from '../../Theme/Metrics';
import {singleHeaderStyles} from './SingleHeader.web.style';
import DownloadButton from '../../Components/DownloadButton/DownloadButton';
import {bottomTabNavigationItemStyles} from '../../Components/Navigation/BottomTabNavigationItem/BottomTabNavigationItem.styles';
import useDefaultTheme from '../../Theme/useTheme';
import FavorisIcon, {
  FavorisProps,
} from '../../Components/CheckBox/FavoirsIcon/FavorisIcon';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

export interface SingleHeaderProps extends Omit<CustomHeaderProps, 'children'> {
  text: string;
  listCategory?: string[] | string;
  isTrailer?: boolean;
  isLiked?: boolean;
  onClickFav?: FavorisProps['onPressFav'];
  onClickShare?: () => void;
  onClickDown?: () => void;
  onClickPlay?: () => void;
  isSerie?: boolean;
  episode?: string;
  progress?: number;
  length?: string;
  favoriLabel?: string;
  shareLabel?: string;
  downloadLabel?: string;
  trailerLabel?: string;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  numberOfLines?: number;
  onStopDownload?: () => Promise<boolean>;
  isDownloaded?: boolean;
  downloadProgress?: number;
  isPodcastSingle?: boolean;
  extraContent?: React.ReactNode;
}

const SingleHeader = (props: SingleHeaderProps) => {
  const {
    image,
    title,
    buttonTitle,
    text,
    listCategory,
    isTrailer,
    onClickDown,
    onClickFav,
    onClickPlay,
    onClickShare,
    isSerie,
    episode,
    progress,
    length,
    onPressButton,
    isLiked,
    favoriLabel = 'Favoris',
    shareLabel = 'Share',
    downloadLabel = 'Download',
    trailerLabel = 'Trailer',
    seeMoreLabel,
    seeLessLabel,
    numberOfLines = 3,
    variant,
    onStopDownload,
    isDownloaded,
    downloadProgress,
    isPodcastSingle,
    extraContent,
    ...rest
  } = props;

  const theme = useDefaultTheme();

  const {isMobile} = useBreakpoint();

  const contentProgress = useCallback(() => {
    const width = isMobile ? 375 : 473;
    return (
      <View
        style={{
          ...singleHeaderStyles.progressContainer,
          width,
        }}>
        <View
          style={{
            marginRight: Metrics.horizontalScale(10),
            width: width - Metrics.horizontalScale(100),
          }}>
          <CustomProgressBar showEmptyProgress={true} progress={progress} />
        </View>
        {length && (
          <Typography
            children={length}
            variant="ui1"
            color="gray"
            style={{
              textWrap: 'nowrap',
            }}
          />
        )}
      </View>
    );
  }, [progress, length, isMobile]);

  return (
    <View>
      <CustomHeaderWeb
        {...rest}
        image={image}
        title={title}
        variant={variant}
        buttonProps={{
          variant: 'outlined',
          size: 'small',
          icon: 'play',
        }}
        buttonTitle={buttonTitle}
        onPressButton={onPressButton}
        size="large"
        contentProgress={
          variant === 'centered' && !isMobile && contentProgress()
        }
        extraContent={
          <>
            {extraContent}
            {isSerie && (
              <View
                style={{
                  alignItems: 'center',
                  paddingTop: Metrics.verticalScale(16),
                }}>
                <Typography children={episode} variant="ui1" color="gray" />
                {(variant !== 'centered' || isMobile) && contentProgress()}
              </View>
            )}
          </>
        }
        footer={
          <View
            style={{
              marginTop: isSerie
                ? Metrics.verticalScale(2)
                : Metrics.verticalScale(isMobile ? 20 : 40),
              flexDirection: isMobile || isPodcastSingle ? 'column' : 'row',
              justifyContent:
                isMobile || isPodcastSingle ? 'flex-start' : 'space-between',
            }}>
            <View
              style={{
                paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
                marginBottom: Metrics.verticalScale(12),
                alignItems:
                  isPodcastSingle && !isMobile ? 'center' : 'flex-start',
              }}>
              <ReadMoreText
                numberOfLines={numberOfLines}
                text={text}
                style={{
                  textAlign: isPodcastSingle && !isMobile ? 'center' : 'left',
                  width: isMobile ? undefined : 463,
                }}
                seeMoreLabel={seeMoreLabel}
                seeLessLabel={seeLessLabel}
              />
            </View>
            <BottomTabNavigation
              backgroundColor="transparent"
              paddingHorizontal={Metrics.horizontalScale(isMobile ? 16 : 60)}
              style={{
                justifyContent: 'center',
                gap: 40,
                padding: 0,
                marginTop: isMobile ? 26 : 0,
              }}>
              {onClickFav && (
                <BottomTabNavigationItem
                  buttonTextVariant="ui3"
                  title={favoriLabel}
                  icon={isLiked ? 'favoris-fill' : 'favoris'}
                  customIcon={() => (
                    <FavorisIcon
                      isLiked={isLiked}
                      onPressFav={onClickFav}
                      iconSize="24"
                    />
                  )}
                />
              )}
              {onClickShare && (
                <BottomTabNavigationItem
                  buttonTextVariant="ui3"
                  title={shareLabel}
                  onPress={onClickShare}
                  icon={'share'}
                />
              )}
              {onClickDown && (
                // <BottomTabNavigationItem
                //   buttonTextVariant="ui3"
                //   title={downloadLabel}
                //   onPress={onClickDown}
                //   icon={'download'}
                //   style={{marginHorizontal: Metrics.verticalScale(20)}}
                // />
                <View
                  style={StyleSheet.flatten([
                    {marginHorizontal: Metrics.verticalScale(20)},
                    bottomTabNavigationItemStyles.container,
                  ])}>
                  <DownloadButton
                    onDownload={onClickDown}
                    onStop={onStopDownload}
                    progress={isDownloaded ? 1 : downloadProgress}
                  />
                  <Typography
                    variant={'ui3'}
                    color={() => theme.gray}
                    style={{
                      ...bottomTabNavigationItemStyles.text,
                    }}>
                    {downloadLabel}
                  </Typography>
                </View>
              )}
              {onClickPlay && (
                <BottomTabNavigationItem
                  buttonTextVariant="ui3"
                  title={trailerLabel}
                  onPress={onClickPlay}
                  icon={'play'}
                />
              )}
            </BottomTabNavigation>
          </View>
        }>
        {!!listCategory && <TitleCategorie listCategorie={listCategory} />}
      </CustomHeaderWeb>
    </View>
  );
};

export default SingleHeader;
