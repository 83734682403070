import Typography from 'design-system/src/Components/Typography/Typography.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import Animated, {
  useAnimatedProps,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import Svg, {
  Circle,
  Defs,
  LinearGradient,
  CircleProps,
  Stop,
} from 'react-native-svg';

export interface CustomCircularProgressBase extends CircleProps {
  activeStrokeColor: string;
  secondStrokeColor: string;
  size: number;
  height: number;
  currentProgress: number;
  textHeight: number;
}

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

const CustomCircularProgressBase = (props: CustomCircularProgressBase) => {
  const {
    cy,
    stroke,
    strokeWidth,
    size,
    height,
    currentProgress,
    textHeight,
    secondStrokeColor,
    activeStrokeColor,
  } = props;
  const radius = (size - Number(strokeWidth)) / 2;
  const CIRCLE_LENGTH = Number(radius) * (2 * Math.PI);

  const progress = useSharedValue(0);

  useEffect(() => {
    progress.value = withTiming(currentProgress / 100, {duration: 2000});
  }, [currentProgress]);

  const animatedProps = useAnimatedProps(() => {
    return {
      strokeDashoffset: CIRCLE_LENGTH * (1 - progress.value),
    };
  });

  const progressText = useDerivedValue(() => {
    return `${Math.floor(progress.value * 100)}`;
  });

  return (
    <>
      <Svg style={{position: 'absolute'}} width={size} height={height}>
        <Defs key={'gradient'}>
          <LinearGradient
            id={'gradient'}
            x1={'0'}
            y1={'0%'}
            x2={'100%'}
            y2={'0%'}>
            <Stop offset={'0%'} stopColor={activeStrokeColor} />
            <Stop offset={'100%'} stopColor={secondStrokeColor} />
          </LinearGradient>
        </Defs>
        <Circle
          cx={size / 2}
          cy={cy}
          stroke={stroke}
          strokeWidth={strokeWidth}
          r={radius}
          fill={'transparent'}
        />
        <AnimatedCircle
          cx={size / 2}
          cy={cy}
          stroke={'url(#gradient)'}
          strokeWidth={strokeWidth}
          strokeDasharray={CIRCLE_LENGTH}
          r={radius}
          animatedProps={animatedProps}
          strokeLinecap={'round'}
          fill={'transparent'}
          transform={`rotate(-90 ${size / 2} ${height / 2})`}
        />
      </Svg>
      <View
        style={{
          height: textHeight,
          marginBottom: Metrics.horizontalScale(3),
          justifyContent: 'center',
          marginLeft: Metrics.horizontalScale(10),
        }}>
        <Typography children={currentProgress + '%'} variant="ui2" />
      </View>
    </>
  );
};

export default CustomCircularProgressBase;
