import React from 'react';
import {ProgressBar} from 'react-native-paper';
import defaultTheme from '../../Theme/defaultTheme';
import Metrics from '../../Theme/Metrics';

export type CustomProgressBarProps = {
  progressBarColor?: string;
  progress?: number;
  animatedValue?: number;
  withRadius?: boolean;
  height?: number;
  backgroundColor?: string;
  showEmptyProgress?: boolean;
};
const CustomProgressBar: React.FC<CustomProgressBarProps> = ({
  progressBarColor,
  progress,
  withRadius = true,
  height = 4,
  backgroundColor = 'rgba(255, 255, 255,0.5)',
  animatedValue,
  showEmptyProgress = false,
}) => {
  const progression: number | undefined =
    (typeof progress === 'number' &&
      progress > 0 &&
      Math.min(Math.max(progress, 0), 1)) ||
    undefined;
  return (
    <ProgressBar
      style={{
        ...(withRadius && {borderRadius: Metrics.moderateScale(8)}),
        width: '100% !important',
        height: Metrics.verticalScale(height),
        backgroundColor,
        opacity: progression || showEmptyProgress ? 1 : 0,
      }}
      color={progressBarColor ?? defaultTheme.primary}
      progress={progression}
      animatedValue={animatedValue}
    />
  );
};

export default CustomProgressBar;
