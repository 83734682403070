import { createContext, Context } from "react";
import { IAcqCategoryItemSize } from "./AcqCategoryItem/AcqCategoryItem.web";

export interface AcqCategoryWidgetContextState {
  mapSize: Record<IAcqCategoryItemSize, number>;
}

const AcqCategoryWidgetContext: Context<AcqCategoryWidgetContextState> =
  createContext({
    mapSize: {
      large: 396,
      small: 345,
    },
  });

export default AcqCategoryWidgetContext;
