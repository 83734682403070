import React, {useEffect, useRef} from 'react';
import {Animated, View, StyleProp, ViewStyle} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {typographyVariantStyled} from 'design-system/src/Components/Typography/Typography.styles';

const AnimatedTexts = (props: {
  texts: string[];
  style?: StyleProp<ViewStyle>;
  duration?: number;
}) => {
  const {texts, style, duration = 600} = props;
  const {isMobile} = useBreakpoint();
  const scrollY = useRef(new Animated.Value(0)).current;
  const textHeight = isMobile
    ? typographyVariantStyled.h1.fontSize
    : typographyVariantStyled.big2.fontSize;

  useEffect(() => {
    const animation = Animated.loop(
      Animated.sequence(
        texts
          .map((_, index) =>
            Animated.sequence([
              Animated.timing(scrollY, {
                toValue: -textHeight * index,
                duration: 0,
                useNativeDriver: true,
              }),
              Animated.delay(duration), // slide duration
              Animated.timing(scrollY, {
                toValue: -textHeight * (index + 1),
                duration: 450, // transition duration
                useNativeDriver: true,
              }),
            ]),
          )
          .concat(
            Animated.timing(scrollY, {
              toValue: 0,
              duration: 0,
              useNativeDriver: true,
            }),
          ),
      ),
    );
    animation.start();

    return () => animation.stop();
  }, [scrollY, texts.length, textHeight, duration]);

  return (
    <View
      style={[
        {
          height: textHeight,
          position: 'relative',
          overflow: 'hidden',
          flexDirection: 'column',
        },
        style,
      ]}>
      <Animated.View
        style={{
          transform: [{translateY: scrollY}],
        }}>
        {texts.concat(texts[0]).map((text, index) => (
          <Typography
            key={index}
            variant={isMobile ? 'h1' : 'big2'}
            color="orange"
            style={{textAlign: 'center', lineHeight: textHeight}}>
            {text}
          </Typography>
        ))}
      </Animated.View>
    </View>
  );
};

export default AnimatedTexts;
