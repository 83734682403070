import React from 'react';
import {StyleSheet, Pressable, PressableProps} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Metrics from '../../../Theme/Metrics';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';
import {typographyVariantStyled} from '../../Typography/Typography.styles';

export interface DropDownButtonProps extends PressableProps {
  text: string;
  variant?: keyof typeof typographyVariantStyled;
  icon?: IconName;
  noIcon?: boolean;
}

const DropDownButton = (props: DropDownButtonProps) => {
  const {
    variant = 'button1',
    text,
    icon = 'select',
    noIcon,
    style,
    ...restProps
  } = props;

  return (
    <Pressable
      style={StyleSheet.flatten([dropDownBtnStyles.container, style])}
      {...restProps}>
      <Typography numberOfLines={1} children={text} variant={variant} />
      {!noIcon && <Icon name={icon} size="16" style={dropDownBtnStyles.icon} />}
    </Pressable>
  );
};

export default DropDownButton;

const dropDownBtnStyles = StyleSheet.create({
  container: {display: 'flex', flexDirection: 'row', alignItems: 'center'},
  icon: {marginLeft: Metrics.horizontalScale(6)},
});
