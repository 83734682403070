import Typography from 'design-system/src/Components/Typography/Typography';
import {Container} from 'design-system/src/WebComponent/Grid';
import React from 'react';
import {View} from 'react-native';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {ILearnHomeCardProps} from 'design-system/src/Components/Card/LearnCard/LearnHomeCard/LearnHomeCard';
import {ICourseUnitFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import GridView from 'design-system/src/Components/GridView/GridView.web';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import Button from 'design-system/src/Components/Button/Button';
import {ICourseTestResponse} from '@src/Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import LearnSingleCard from 'design-system/src/Components/Card/LearnCard/LearnSingleCard/LearnSingleCard';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import styled from 'styled-components';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';

interface ICourseUnitListProps {
  courseUnits: ILearnHomeCardProps<ICourseUnitFragmentFragment>[];
  courseExamId?: string;
  courseExamUrl?: string;
  buttonExamTitle: string;
  isExamSuccess?: boolean;
  examProgress?: ICourseTestResponse;
  gradingPolicy: number;
  courseId: string;
  onItemClick: (
    item: ILearnHomeCardProps<ICourseUnitFragmentFragment>,
    index: number,
  ) => void;
}

// @ts-ignore
const Title = styled(Typography)<{}>(() => {
  return {
    marginBottom: 21,
    [`@media ${responsiveMap.mobile}`]: {
      marginBottom: 13,
    },
  };
});
const CouseUnitList = (props: ICourseUnitListProps) => {
  const {
    courseUnits,
    courseExamId,
    courseExamUrl,
    buttonExamTitle = 'go',
    isExamSuccess,
    examProgress,
    gradingPolicy,
    courseId,
    onItemClick,
  } = props;
  const {t} = useTranslation('learn');
  const navigation = useAppNavigation();

  const handleItemClick =
    (item: ILearnHomeCardProps<ICourseUnitFragmentFragment>, index: number) =>
    () => {
      onItemClick?.(item, index);
    };
  return (
    <Container key={'list'}>
      <View style={{marginTop: 10}}>
        <Title variant="h3">{t('Badge available on course completion')}</Title>
        <GridView
          data={courseUnits}
          noScroll
          xPaddingWrap={4}
          yPadding={4}
          disableBigItem={true}
          renderItem={(item, index) => {
            return (
              <PressableCustom onPress={handleItemClick(item, index)}>
                <LearnSingleCard
                  textDone={t('Done') as string}
                  {...item}
                  isWithProgressBar
                />
              </PressableCustom>
            );
          }}
        />
      </View>
      {courseExamId && (
        <View
          style={{
            paddingHorizontal: 16,
            marginTop: 40,
            paddingBottom: 40,
          }}>
          <Button
            title={buttonExamTitle}
            variant={isExamSuccess ? 'outlined' : 'contained'}
            style={{width: '100%'}}
            size="medium"
            onPress={() => {
              if (isExamSuccess) {
                if (examProgress && examProgress.result) {
                  const {totalQuestion, correct, percent} = examProgress.result;
                  navigation.navigate(AppRoutesEnum.EXAM_RESULT_OVERVIEW, {
                    state: {
                      params: {
                        data: {
                          correct,
                          result: percent,
                          stat: examProgress.stat,
                          totalQuestion,
                        },
                        gradingPolicy,
                        id: 'default-id',
                      },
                    },
                  });
                }
              } else {
                navigation.navigate(AppRoutesEnum.EXAM, {
                  state: {
                    params: {
                      id: courseExamId || '',
                      url: courseExamUrl,
                      courseId,
                    },
                  },
                });
              }
            }}
          />
        </View>
      )}
    </Container>
  );
};

export default CouseUnitList;
