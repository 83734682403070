import {useAppDispatch} from '@src/Store/hooks';
import useMember, {useSelecteAllSearch} from '@src/Store/selectors/useMember';
import {ISearchResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {
  useLazyCreateMemberSearchQuery,
  useLazyDeleteMemberSearchQuery,
} from '~Api/TraceApi/traceApi';
import memberSlice from '~Store/Slices/Member.slice';

export const useUpdateMemberSearch = () => {
  const listSearch = useSelecteAllSearch();
  const member = useMember();
  const dispatch = useAppDispatch();

  const [createSearch, {isLoading: createLoading}] =
    useLazyCreateMemberSearchQuery();
  const [deleteSearch, {isLoading: deleteLoading}] =
    useLazyDeleteMemberSearchQuery();

  const isInSearch = (text: string) => {
    return listSearch.find((e: ISearchResponse) => {
      return e.text?.toLowerCase().trim() === text.toLowerCase().trim();
    });
  };

  const createMemberSearch = async (text: string) => {
    if (text !== '' && !isInSearch(text) && member) {
      const response = await createSearch({
        data: {text},
      });
      console.log('response.data search', response.data);
      if (!response.error) {
        if (response.data) {
          dispatch(
            memberSlice.actions.setMemberSearch([
              ...listSearch,
              {
                ...response.data,
              },
            ]),
          );
        }
      } else {
        console.log('error when create search', response.error);
      }
    }
  };

  const deleteMemberSearch = async (id: string) => {
    const response = await deleteSearch({id});
    if (!response.error) {
      let newList = listSearch.filter(search => search._id !== id);
      dispatch(memberSlice.actions.setMemberSearch(newList));
    }
  };

  return {
    createMemberSearch,
    deleteMemberSearch,
    loading: createLoading || deleteLoading,
    isInSearch,
  };
};
