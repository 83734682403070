import React, {useEffect, useState} from 'react';
import {GestureResponderEvent, Pressable, PressableProps} from 'react-native';
import Svg, {Circle, Path, Rect} from 'react-native-svg';
import Metrics from '../../../Theme/Metrics';

export interface IPlayButtonProps extends PressableProps {
  isPlaying?: boolean;
  size?: number;
}

const PlayButton = (props: IPlayButtonProps) => {
  const {isPlaying, size = 76, onPress, ...rest} = props;
  const [playing, setPlaying] = useState(isPlaying);
  useEffect(() => {
    if (isPlaying !== playing) {
      setPlaying(isPlaying);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const onPlayPause = (event: GestureResponderEvent) => {
    setPlaying(current => !current);
    onPress && onPress(event);
  };

  return (
    <Pressable onPress={onPlayPause} {...rest}>
      {!playing ? (
        <Svg
          width={Metrics.verticalScale(size)}
          height={Metrics.verticalScale(size)}
          viewBox="0 0 76 76"
          fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76ZM32 30.7232V47.2768C32 48.0446 32.8295 48.526 33.4961 48.1451L47.9806 39.8682C48.6524 39.4843 48.6524 38.5157 47.9806 38.1318L33.4961 29.8549C32.8295 29.474 32 29.9554 32 30.7232Z"
            fill="white"
          />
        </Svg>
      ) : (
        <Svg
          width={Metrics.verticalScale(size)}
          height={Metrics.verticalScale(size)}
          viewBox="0 0 76 76"
          fill="none">
          <Circle cx="38" cy="38" r="38" fill="white" />
          <Rect x="31" y="30" width="5" height="18" rx="1" fill="#21282C" />
          <Rect x="40" y="30" width="5" height="18" rx="1" fill="#21282C" />
        </Svg>
      )}
    </Pressable>
  );
};

export default PlayButton;
