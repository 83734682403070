import React from 'react';
import TrackingProvider from 'design-system/src/Provider/TrackingProvider/TrackingProvider';

import useTracker from '../../Services/Amplitude/hooks/useTracker';

const AppTrackingProvider = (props: {children: React.ReactNode}) => {
  const {children} = props;
  const tracker = useTracker();

  return (
    <TrackingProvider
      events={{
        onButtonPress: ({title}) => {
          tracker.general.cTAClicked({
            type: 'button',
            name: title,
            page: '',
            blockType: '',
            blockName: '',
          });
        },
      }}>
      {children as any}
    </TrackingProvider>
  );
};

export default AppTrackingProvider;
