import useParams from '@src/Hooks/useParams';
import PushQuizCard from 'design-system/src/Components/Card/PushQuizCard/PushQuizCard';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {
  ICourseCategoryFragmentFragment,
  useMethodologyQuizByUrlEndQuery,
} from '~Api/Graphql/gql/types.generated';
import CourseSlider from '~Umbraco/Components/CourseSlider/CourseSlider';
import WysiwygBlock from '~Umbraco/Components/Learn/WysiwygBlock/WysiwygBlock';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
import {useMapCourseCateg} from '~Umbraco/Utils/content/mapCourseCategItems';
import UcImage from '../../LearnHomePage/UnitScreen/UnitPages/CoursePage/UcImage/UcImage';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

export default function QuizMethodologyPage() {
  const params = useParams();
  const navigation = useAppNavigation();

  const {endUrl} = params || {};
  const {t} = useTranslation('translation');
  const response = useMethodologyQuizByUrlEndQuery({
    variables: {
      endUrl,
    },
    skip: !endUrl,
  });
  const quizzPage = response.data?.allQuizPage.items[0];
  const {mapCourseCategItems} = useMapCourseCateg();
  const loading = response.loading;
  const {handleClickCourseSlideItem} = useSectionPageUtils();
  const {isMobile} = useBreakpoint();

  const {courseSlider, pageBlocks, serviceHeader} = useMemo(() => {
    let _pageBlocks: ReactNode[] = [];
    let serviceHeader: ReactNode = <div></div>;
    let courseSlider: ReactNode = <div></div>;
    if (quizzPage) {
      const {
        methodTitle,
        methodIntro,
        methodCover,
        methodBody,
        methodHighlightedCourses,
        personalitiesPush,
      } = quizzPage;
      serviceHeader = (
        <ServiceHeader
          key={'header'}
          title={methodTitle || ''}
          description={methodIntro || ''}
          gradiantVariant="bottom"
          image={{
            uri:
              methodCover &&
              methodCover[0] &&
              methodCover[0].content &&
              methodCover[0].content.__typename === 'HeroVideoCover'
                ? methodCover[0].content.image?.url
                : undefined,
          }}
        />
      );

      let outputContent: ReactNode[] = [];
      if (methodBody) {
        methodBody.forEach((item, index) => {
          if (item && item.content) {
            if (item.content.__typename === 'UcImage') {
              outputContent.push(
                <UcImage key={index} content={item.content} />,
              );
            } else if (item.content.__typename === 'Wysiwyg') {
              outputContent.push(
                <WysiwygBlock
                  key={index}
                  title={item.content.title || ''}
                  body={item.content.body || ''}
                  bodyColor="white"
                />,
              );
            }
          }
        });
      }
      if (
        personalitiesPush &&
        personalitiesPush[0] &&
        personalitiesPush[0].content &&
        personalitiesPush[0].content.__typename === 'PersonalityPush'
      ) {
        const {title, description, icon} = personalitiesPush[0].content;
        outputContent.push(
          <View
            key={`perso-push`}
            style={{
              marginVertical: Metrics.verticalScale(40),
              width: isMobile ? '100%' : 430,
            }}>
            <PushQuizCard
              title={title || ''}
              description={description || ''}
              variant="blur"
              icon={() => (
                <CustomFastImage
                  backgroundFallbackColor="transparent"
                  width={Metrics.horizontalScale(72)}
                  height={Metrics.horizontalScale(72)}
                  source={{
                    uri: icon?.url,
                  }}
                />
              )}
              onPressArrowIc={() => {
                navigation.navigate(AppRoutesEnum.QUIZ_PERSONALITY, {
                  state: {
                    params: {
                      id: quizzPage.id || '',
                      url: quizzPage.url || '',
                    },
                  },
                });
              }}
              descTypo="ui3"
            />
          </View>,
        );
      }
      if (outputContent.length) {
        _pageBlocks.push(...outputContent);
      }
      if (
        methodHighlightedCourses &&
        methodHighlightedCourses.children &&
        methodHighlightedCourses.children.items
      ) {
        const items = mapCourseCategItems(
          methodHighlightedCourses.children
            .items as ICourseCategoryFragmentFragment['children']['items'],
        );
        if (items.length) {
          courseSlider = (
            <CourseSlider
              key={'slider'}
              data={items}
              title={t('just for you')}
              onItemPress={handleClickCourseSlideItem}
            />
          );
        }
      }
    }
    return {pageBlocks: _pageBlocks, serviceHeader, courseSlider};
  }, [quizzPage, mapCourseCategItems, isMobile]);

  const {column1, column2} = useMemo(() => {
    if (!isMobile) {
      return pageBlocks.reduce(
        (
          acc: {column1: ReactNode[]; column2: ReactNode[]},
          block,
          currentIndex,
        ) => {
          if (currentIndex === 0 || currentIndex === pageBlocks.length - 1) {
            acc.column1.push(block);
          } else {
            acc.column2.push(block);
          }
          return acc;
        },
        {column1: [], column2: []},
      );
    }
    return {
      column1: [],
      column2: [],
    };
  }, [pageBlocks, isMobile]);

  return (
    <div>
      <DebugUmbracoId umbracoId={quizzPage?.id} position="page" />
      {endUrl ? (
        loading ? (
          <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
        ) : (
          <>
            {serviceHeader}
            <View
              style={{
                paddingHorizontal: isMobile
                  ? Metrics.horizontalScale(16)
                  : Metrics.horizontalScale(60),
              }}>
              {isMobile ? (
                pageBlocks
              ) : (
                <AppGrid nbColumns={2} columnGap={Metrics.horizontalScale(85)}>
                  <div>{column1}</div>
                  <div>{column2}</div>
                </AppGrid>
              )}
            </View>
            {courseSlider}
          </>
        )
      ) : (
        <ContentEmpty
          style={{flex: 1, justifyContent: 'center'}}
          title={t('Section not found')}
        />
      )}
    </div>
  );
}
