import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {PageContextI18n} from '@src/components/general/WrapPageElement/PageContext';

interface PageState {
  currentPageContext: PageContextI18n;
  params: Record<string, any>;
}

const initialState: PageState = {
  currentPageContext: {} as unknown as PageContextI18n,
  params: {},
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setCurrentPageContext(state, action: PayloadAction<PageContextI18n>) {
      state.currentPageContext = action.payload;
    },
    setParams(state, action: PayloadAction<Record<string, any>>) {
      state.params = action.payload;
    },
  },
});

export default pageSlice;
