import React from 'react';
import CustomModal, {ICustomModalProps} from '../CustomModal/CustomModal';
import useHeaderOptions from '@src/Store/Slices/AppSlice/hooks/useHeaderOptions';
import SuspenseAfterRequired from 'design-system/src/library/AfterRequired/SuspenseAfterRequired';
import {useAppSelector} from '@src/Store/hooks';
import PageModalGradient from './PageModalGradient/PageModalGradient';
import ModalWrapper from './ModalWrapper/ModalWrapper';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

const Header = React.lazy(
  () => import('@src/App/components/Common/Header/Header'),
);

export interface IPageModalProps
  extends Omit<
    ICustomModalProps,
    'closable' | 'backgroundImage' | 'extraContent'
  > {}
const PageModal = (props: IPageModalProps) => {
  const {children, ...rest} = props;
  const headerProps = useHeaderOptions();
  const {isMobile} = useBreakpoint();
  const defaultCover = useAppSelector(state => state.settings.profile.cover);
  const modalBackground = useAppSelector(state => state.app.modalBackground);

  const backgroundImage =
    typeof modalBackground === 'string'
      ? modalBackground
      : modalBackground
        ? defaultCover
        : undefined;

  return (
    <CustomModal
      {...rest}
      extraContent={<PageModalGradient />}
      header={
        headerProps && Object.keys(headerProps).length > 0 ? (
          <SuspenseAfterRequired required={!!headerProps}>
            <Header />
          </SuspenseAfterRequired>
        ) : null
      }
      backgroundImage={backgroundImage}
      closable={!headerProps}
      children={
        backgroundImage && !isMobile ? (
          <ModalWrapper backgroundImage={backgroundImage}>
            {children}
          </ModalWrapper>
        ) : (
          <div
            style={{
              width: isMobile ? '100vw' : undefined,
            }}>
            {children}
          </div>
        )
      }
    />
  );
};

export default PageModal;
