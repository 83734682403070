import useNavigation from '@src/Navigation/useNavigation';
import {ITabBarHeaderItem} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {ISectionPageTopMenuFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {getLinkPickerUrl} from '~Umbraco/Utils/element/getLinkPickerUrl';
import {mapTopMenuLink} from '~Umbraco/Utils/mapTopMenuLink';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {TabBarHeaderProps} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import useAppNavigation from '@src/utils/useAppNavigation';

type HeaderSubMenu = TabBarHeaderProps;

const useGetTopMenu = () => {
  const navigation = useAppNavigation();
  const dispatch = useDispatch();

  // top menu
  const getTopMenu = useCallback(
    (
      topMenu: ISectionPageTopMenuFragmentFragment['topMenu'],
    ): NonNullable<HeaderSubMenu>['items'] | undefined => {
      let items = mapTopMenuLink(topMenu);
      let _output: NonNullable<HeaderSubMenu>['items'] = [];

      topMenu?.forEach(menu => {
        if (menu && menu.content.__typename === 'LinkPicker') {
          const {title = ''} = menu.content;

          const {url, __typename, id} = getLinkPickerUrl(menu.content);
          if (url) {
            _output.push({
              title,
              onClick: async () => {
                new RouteFactory(
                  {url, __typename, title, items, id},
                  navigation,
                  dispatch,
                ).navigate();
              },
            });
          }
        }
        if (menu && menu.content.__typename === 'ExternalLink') {
          const {title = '', externalUrl: url, __typename} = menu.content;

          if (url) {
            _output.push({
              title,
              onClick: async () => {
                new RouteFactory(
                  {url: url || '', __typename, title, items, id: ''},
                  navigation,
                  dispatch,
                ).navigate();
              },
            });
          }
        }

        if (menu && menu.content.__typename === 'ExpandMenu') {
          let children: ITabBarHeaderItem[] = [];
          menu.content.links?.forEach(link => {
            if (link && link.content.__typename === 'LinkPicker') {
              const {title = ''} = link.content;
              const {url, id, __typename} = getLinkPickerUrl(link.content);
              if (url) {
                children.push({
                  title,
                  onClick: () =>
                    new RouteFactory(
                      {url, __typename, id, title, items},
                      navigation,
                      dispatch,
                    ).navigate(),
                });
              }
            }
          });
          _output.push({
            title: menu.content.title || '',
            children: children,
          });
        }
      });
      return _output;
    },
    [dispatch, navigation],
  );

  return {getTopMenu};
};

export default useGetTopMenu;
