import {
  IGetMainContentListByIdQuery,
  useGetMainContentListByIdLazyQuery,
} from '@src/Api/Graphql/gql/types.generated';
import {useAppSelector} from '@src/Store/hooks';
import {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
// import {
//   IGetMainContentListByIdQuery,
//   useGetMainContentListByIdLazyQuery,
// } from '~Api/Graphql/gql/types.generated';
import {INotifyResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {
  useLazyGetAllMemberNotifyQuery,
  useLazyUpdateMemberNotifyQuery,
  useLazyDeleteMemberNotifyQuery,
  useLazyGetMemberNotifyByIdQuery,
} from '~Api/TraceApi/traceApi';
import memberSlice, {INotifyData} from '~Store/Slices/Member.slice';

const useMemberNotify = (): {
  fetch: () => Promise<void>;
  refresh: () => Promise<void>;
  addNotify: (contentId: any) => Promise<boolean>;
  removeNotify: (contentId: string) => Promise<boolean>;
  isNotified: (contentId: string) => boolean;
  toggleNotify: (contentId: any) => Promise<boolean>;
  markAsRead: (contentId: string) => void;
  loading: boolean;
  isRemoving: boolean;
  isCreating: boolean;
  notifiedContent: string[];
  data: INotifyData[] | undefined;
} => {
  const {notify = [], member} = useAppSelector(({member: memberState}) => ({
    notify: memberState.notify,
    member: memberState.member,
  }));
  const notifiedContent = notify.map(el =>
    el && el.contentId ? el.contentId : '',
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchContent, {loading: getContentLoading}] =
    useGetMainContentListByIdLazyQuery();
  useLazyGetAllMemberNotifyQuery();
  const [updateNotify, {isLoading: isCreating}] =
    useLazyUpdateMemberNotifyQuery();
  const [deleteNotify, {isLoading: isRemoving}] =
    useLazyDeleteMemberNotifyQuery();
  const [getNotify] = useLazyGetMemberNotifyByIdQuery();
  const [getAllNotify, {isLoading: notifyLoading}] =
    useLazyGetAllMemberNotifyQuery();
  const loading = notifyLoading || getContentLoading;

  const handleFetchedData = useCallback(
    (fetchedData: IGetMainContentListByIdQuery, notify: INotifyResponse[]) => {
      const list: INotifyData[] = [];
      if (
        fetchedData &&
        fetchedData.allContent &&
        fetchedData.allContent.items
      ) {
        fetchedData.allContent.items.map(item => {
          if (item) {
            const findData = notify.find(_item => _item.contentId === item.id);
            if (findData) {
              list.push({
                ...findData,
                content: item,
              });
            }
          }
        });
        dispatch(memberSlice.actions.setNotify(list));
      }
      return list;
    },
    [dispatch],
  );

  const fetch = useCallback(async () => {
    const {data} = await getAllNotify();
    if (data) {
      const ids = data.map(item => item.contentId).filter(_item => _item);
      const {data: contentData} = await fetchContent({
        variables: {
          id: ids,
        },
      });
      if (contentData) handleFetchedData(contentData, data);
    }
  }, [getAllNotify, fetchContent, handleFetchedData]);

  const refresh = (timeout: number = 500) => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        fetch()
          .then(() => resolve())
          .catch(reject);
      }, timeout);
    });
  };

  const markAsRead = async (contentId: string) => {
    if (contentId) {
      const response = await getNotify({id: contentId});
      if (response.data) {
        await updateNotify({
          id: response.data._id,
          data: {
            contentId,
            read: true,
            contentSource: 'watch',
          },
        });
        refresh(500);
      }
    }
  };

  const addNotify = async (content: any): Promise<boolean> => {
    setIsLoading(true);
    const tempId = 'notify-tempID-' + content.id;
    dispatch(
      memberSlice.actions.addItem({
        type: 'notify',
        data: {
          _createDate: new Date().toISOString(),
          _updateDate: new Date().toISOString(),
          content: content as any,
          contentSource: 'watch',
          _id: tempId,
          contentId: content?.id || '',
          read: false,
          memberId: member?._id || '',
        },
      }),
    );
    try {
      const response = await updateNotify({
        data: {
          contentId: content.id,
          read: false,
          contentSource: 'watch',
        },
      });
      if (!response.error) {
        if (response.data) {
          dispatch(
            memberSlice.actions.updateItem({
              type: 'notify',
              id: tempId,
              data: {
                _id: response.data._id,
              },
            }),
          );
        }
        // await refresh();
        return true;
      } else {
        dispatch(
          memberSlice.actions.removeItem({
            type: 'notify',
            id: tempId,
          }),
        );
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
    return false;
  };

  const removeNotify = async (contentId: string): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await deleteNotify({id: contentId});
      // await refresh();
      if (!response.error) {
        dispatch(
          memberSlice.actions.removeItem({
            type: 'notify',
            id: contentId,
          }),
        );
        return true;
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
    return false;
  };

  const findNotifyItem = (contentId: string) => {
    return notify.find(el => el?.contentId === contentId);
  };

  const isNotified = (contentId: string): boolean => {
    return !!findNotifyItem(contentId);
  };

  const toggleNotify = async (content: any): Promise<boolean> => {
    const exist = notifiedContent.includes(content.id);

    if (exist) {
      const notifyData = findNotifyItem(content.id);
      if (notifyData) {
        dispatch(
          memberSlice.actions.removeItem({
            type: 'notify',
            id: notifyData._id,
          }),
        );
        return await removeNotify(notifyData._id);
      }
      return false;
    } else {
      return await addNotify(content);
    }
  };

  return {
    notifiedContent,
    markAsRead,
    refresh,
    loading,
    data: notify,
    fetch,
    isRemoving: isLoading || isRemoving,
    isCreating: isLoading || isCreating,
    addNotify,
    removeNotify,
    isNotified,
    toggleNotify,
  };
};

export default useMemberNotify;
