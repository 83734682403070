import {RouteTypename} from '@src/Navigation/type';
import useNavigation from '@src/Navigation/useNavigation';
import {getLinkUrl} from '@src/Umbraco/Utils/element/getLinkPickerUrl';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import ServiceChannel, {
  IItemServiceChannel,
} from 'design-system/src/Components/ServiceChannel/ServiceChannel.web';
import React from 'react';
import {useDispatch} from 'react-redux';

const LogoSlider = (props: {content: Queries.LogoSliderFragmentFragment}) => {
  let {slides, title: _title} = props.content;
  const navigation = useNavigation();
  const dispatch = useDispatch();
  let listChannel: Array<
    IItemServiceChannel & {
      __typename: RouteTypename;
      id: string;
      url: string;
    }
  > = [];

  const handleClickLogoSliderItem = (
    item: IItemServiceChannel & {
      __typename: RouteTypename;
      url: string;
      id: string;
    },
  ) => {
    const {url, id, __typename} = item;
    const factory = new RouteFactory(
      {url, id, __typename},
      navigation,
      dispatch,
    );
    factory.navigate();
  };

  if (
    slides &&
    slides[0] &&
    slides[0].content.contentTypeAlias === 'logoLink'
  ) {
    slides.forEach(item => {
      if (item && item.content.contentTypeAlias === 'logoLink') {
        let content = item?.content as Queries.LogoLinkFragmentFragment;
        // TODO: remove any
        const contentLink = content?.link && getLinkUrl(content?.link as any);
        listChannel.push({
          logo: {
            uri: content?.logo?.url || '',
          },
          url: contentLink?.url || '',
          __typename: contentLink?.__typename!,
          id: contentLink?.id || '',
        });
      }
    });
  }
  if (listChannel.length > 0) {
    return (
      <div
        style={{
          padding: '96px 16px',
        }}>
        <ServiceChannel
          title={_title ?? '-'}
          items={listChannel}
          onItemPress={item => {
            handleClickLogoSliderItem(item);
          }}
        />
      </div>
    );
  }
  return null;
};

export default LogoSlider;
