import React from 'react';
import BottomTabNavigationItem, {
  BottomNavigationItemProps,
} from '../BottomTabNavigationItem';

const BottomTabNavItemListen = (
  props: Omit<BottomNavigationItemProps, 'icon'>,
) => {
  const {title = 'Listen', ...rest} = props;
  return <BottomTabNavigationItem icon="listen" title={title} {...rest} />;
};
export default BottomTabNavItemListen;
