import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const CarouselCardStyles = StyleSheet.create({
  coverWrapper: {
    position: 'absolute',
    justifyContent: 'center',
    alignContent: 'center',
  },
  cover: {
    borderRadius: Metrics.moderateScale(4),
    justifyContent: 'space-between',
  },
  coverImage: {
    borderRadius: Metrics.moderateScale(4),
    justifyContent: 'center',
    alignContent: 'center',
    resizeMode: 'cover',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  center: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radius: {
    borderRadius: Metrics.moderateScale(4),
    overflow: 'hidden',
  },
  topTenWrapper: {
    height: Metrics.verticalScale(106),
  },
});
