import {View} from 'react-native';
import React, {useMemo} from 'react';
import {useAllContentByListIdQuery} from '~Api/Graphql/gql/types.generated';
import CheckboxGroupController, {
  ICheckboxGroupControllerProps,
} from 'design-system/src/Components/Form/CheckboxGroup/CheckboxGroupController';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Metrics from 'design-system/src/Theme/Metrics';

interface ICheckBoxGroupListMapperProps
  extends Omit<ICheckboxGroupControllerProps, 'options'> {
  preValues: Record<string, string>;
}

const CheckBoxGroupListMapper = (props: ICheckBoxGroupListMapperProps) => {
  const {preValues = {}, ...rest} = props;
  const {data, loading} = useAllContentByListIdQuery({
    variables: {
      id: Object.values(preValues),
    },
  });

  const {options} = useMemo(() => {
    const _options: ICheckboxGroupControllerProps['options'] = [];
    if (data && data.allContent && data.allContent.items) {
      data.allContent.items.forEach(item => {
        if (item && item.name) {
          _options.push({
            label: item.name,
            value: item.id,
          });
        }
      });
    }
    return {
      options: _options,
    };
  }, [data]);

  if (!options.length) {
    return null;
  }

  if (loading) {
    return (
      <View
        style={[
          {
            marginBottom: Metrics.verticalScale(20),
          },
        ]}>
        <CustomActivityIndicator />
      </View>
    );
  }
  return <CheckboxGroupController options={options} {...rest} />;
};

export default CheckBoxGroupListMapper;
