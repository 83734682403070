import {navigate} from 'gatsby';
import {NavigateOptions, WindowLocation, globalHistory} from '@reach/router';
import store from '@src/Store';
import {isModalRoute} from '@src/App/Route/Utils/isModalRoute';
import {isDrawerRoute} from '@src/App/Route/Utils/isDrawerRoute';
import {AppRoutesEnum} from '@src/Constant/routes';
import {compile} from 'path-to-regexp';
import queryString from 'query-string';
import appSlice from '@src/Store/Slices/AppSlice';
import {IThankYouPagePassedData} from '@src/Umbraco/Pages/ThankYouPage/ThankYouPage';
import {size} from 'design-system/src/WebComponent/Grid/_utils/size';
import appListenSlice from '@src/Store/Slices/AppSlice/listen.slice';
import appPodcastSlice from '@src/Store/Slices/AppSlice/podcast.slice';
import {
  getContentEndUrl,
  getEndUrls,
} from '@src/Umbraco/Utils/getContentEndUrl';

export interface NavigateFn {
  (
    to: AppRoutesEnum,
    options?: NavigateOptions<{
      canGoBack?: boolean;
      type?: 'drawer' | 'modal';
      oldLocation?: WindowLocation<unknown>;
      params?: Record<string, any>;
      headerTitle?: string;
      queryWithId?: boolean;
    }>,
  ): Promise<void>;
}

export const getUrlEnd = (fullUrl?: string): string => {
  // from umbraco url
  return fullUrl?.split('/').filter(Boolean).pop() || '';
};

export const getAppRoutePrefix = () => {
  const {i18n, language} = store.getState().page.currentPageContext;

  if (i18n) {
    const pathLang =
      language === i18n.defaultLanguage &&
      !i18n.path.startsWith(i18n.defaultLanguage)
        ? ''
        : `/${language}`;
    return `${pathLang}/app`;
  }
  return '/app';
};

const isMobile = () => {
  return window.innerWidth <= size.mobile;
};

export const appNavigate: NavigateFn = (path, options) => {
  const to = path.startsWith('/app/') ? path.replace('/app/', '/') : path;

  if (to === AppRoutesEnum.RADIO_PLAYER) {
    store.dispatch(
      appListenSlice.actions.setListenParams(
        options?.state?.params ?? ({} as any),
      ),
    );
    store.dispatch(appPodcastSlice.actions.closePlayer());
    store.dispatch(appListenSlice.actions.openPlayer());
    return Promise.resolve();
  }

  if (to === AppRoutesEnum.PODCAST_PLAYER) {
    store.dispatch(
      appListenSlice.actions.setListenParams(
        options?.state?.params ?? ({} as any),
      ),
    );
    store.dispatch(appListenSlice.actions.closePlayer());
    store.dispatch(appPodcastSlice.actions.openPlayer());
    return Promise.resolve();
  }

  if (
    to === AppRoutesEnum.VIDEO_PLAYER ||
    to === AppRoutesEnum.NEWS_SINGLE ||
    to === AppRoutesEnum.PLAYER_QUIZ_SURVEY ||
    to === AppRoutesEnum.COURSE_UNIT
  ) {
    store.dispatch(appListenSlice.actions.closePlayer());
    store.dispatch(appPodcastSlice.actions.closePlayer());
  }

  if (to === AppRoutesEnum.THANK_YOU_MODAL) {
    store.dispatch(
      appSlice.actions.openThankYouModal(
        options?.state?.params as IThankYouPagePassedData,
      ),
    );
    return Promise.resolve();
  } else {
    const appRoutePrefix = getAppRoutePrefix();
    const {queryWithId} = options?.state || {};
    const type = options?.state?.type;
    const params = options?.state?.params || {};
    const contentUrl = options?.state?.params?.url;
    const isModal = type === 'modal' || isModalRoute(to + '', isMobile());
    const isDrawer = type === 'drawer' || isDrawerRoute(to + '');
    const {endUrl1, endUrl2, endUrl: endUrlFromUrl} = getEndUrls(contentUrl);
    const initialEndUrl = options?.state?.params?.endUrl;
    const endUrls = initialEndUrl
      ?.split('/')
      .filter(Boolean)
      .reduce(
        (acc: Record<string, string>, curr: string, index: number) => {
          acc[`endUrl${index + 1}`] = curr;
          return acc;
        },
        {} as Record<string, string>,
      );

    const endUrl =
      initialEndUrl ||
      (contentUrl
        ? getContentEndUrl(contentUrl || '', options?.state?.params?.__typename)
        : '');
    const pathTo = compile(to + '')({
      ...options?.state?.params,
      endUrl: endUrl || endUrlFromUrl,
      endUrl1,
      endUrl2,
      ...endUrls,
    });
    const finalRoute = queryString.stringifyUrl({
      url: `${appRoutePrefix}${pathTo}`,
      query: queryWithId
        ? {
            id: pathTo.indexOf(params.id) >= 0 ? undefined : params.id,
          }
        : undefined,
    });
    const isReplace = options?.replace;
    const formatedLink = finalRoute.replace('/app/app/', '/app/');
    const oldLocation = isReplace
      ? undefined
      : isModal || isDrawer
        ? globalHistory.location
        : undefined;

    return navigate(formatedLink, {
      ...options,
      state: {
        ...options?.state,
        title: params.title,
        oldLocation: oldLocation,
      },
    });
  }
};

export const appNavigateBack = () => {
  const {oldLocation} = globalHistory.location.state;
  if (oldLocation) {
    return navigate(oldLocation.pathname, {
      state: {
        ...oldLocation.state,
        oldLocation: undefined,
      },
    });
  }
  return navigate(-1);
};
