import Button from 'design-system/src/Components/Button/Button';
import React from 'react';
import {View} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import ReadMoreText from 'design-system/src/Components/ReadMoreText/ReadMoreText.web';
import ItemOverview, {
  ItemOverviewProps,
} from 'design-system/src/Components/ItemOverview/ItemOverview';
import {Container} from 'design-system/src/WebComponent/Grid';
import styled from 'styled-components';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface ILearnOverviewProps {
  courseId?: string;
  title?: string;
  description?: string;
  overviewData?: ItemOverviewProps;
  actionButton?: {
    title: string;
    onPress: () => void;
  };
}

// @ts-ignore
const Wrapper = styled('div')<{}>(() => {
  return {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 32,
    [`@media ${responsiveMap.mobile}`]: {
      gridTemplateColumns: '1fr',
    },
  };
});
// @ts-ignore
const DescriptionWrapper = styled('div')<{}>(() => {
  return {
    display: 'flex',
    gap: 31,
    flexDirection: 'column-reverse',
    maxWidth: '80%',
    [`@media ${responsiveMap.mobile}`]: {
      flexDirection: 'column',
      gap: 48,
      maxWidth: '100%',
    },
  };
});

const LearnOverview = (props: ILearnOverviewProps) => {
  const {title, description, overviewData, actionButton} = props;
  const {isMobile} = useBreakpoint();

  return (
    <Container>
      <Wrapper
        style={{
          marginBottom: 46,
          marginTop: 41,
        }}>
        <View>{overviewData && <ItemOverview {...overviewData} />}</View>
        <View>
          <DescriptionWrapper>
            {actionButton && (
              <View
                style={{
                  alignItems: isMobile ? undefined : 'flex-start',
                }}>
                <Button
                  style={{width: '100%'}}
                  title={actionButton.title}
                  variant="filled"
                  onPress={actionButton.onPress}
                />
              </View>
            )}
            {description && (
              <View>
                <Typography
                  variant="h3"
                  style={{marginBottom: isMobile ? 13 : 22}}>
                  {title}
                </Typography>
                <ReadMoreText text={description} />
              </View>
            )}
          </DescriptionWrapper>
        </View>
      </Wrapper>
    </Container>
  );
};

export default LearnOverview;
