import React, {ReactNode} from 'react';
import IcLogoSvg from '../../../assets/svg/logo/TraceLogo.svg';

import {StyleProp, StyleSheet, Pressable, ViewStyle} from 'react-native';

import GenericAppBar, {GenericAppBarProps} from '../GenericAppBar/AppBar.web';
import Metrics from '../../../Theme/Metrics';
import Icon, {IIconProps} from '../../Icon/Icon';
import useDefaultTheme from '../../../Theme/useTheme';
import Typography from '../../Typography/Typography';
import {IconName} from '../../../assets/Icones';
// import {CustomActivityIndicator} from '../../CustomActivityIndicator';

export interface CustomAppBarProps extends Partial<GenericAppBarProps> {
  backgroundColor?: string;
  onPressLogo?: () => void;
  onPressSearchIcon?: () => void;
  onPressAccountIcon?: () => void;
  onPressCastIcon?: () => void;
  onPressArrowBack?: () => void;
  isWithLogo?: boolean;
  isWithCast?: boolean;
  style?: StyleProp<ViewStyle>;
  back?: {
    title: string;
    icon?: IconName;
  };
  headerTitle?: string;
  hideAction?: boolean;
  extraAction?: ReactNode;
  leftAction?: ReactNode;
  noBack?: boolean;
  middleContent?: ReactNode;
  showBack?: boolean;
  isCastLoading?: boolean;
  castConnect?: boolean;
}

const CustomAppBar = (props: CustomAppBarProps) => {
  const {
    backgroundColor,
    isWithLogo,
    onPressLogo,
    onPressArrowBack,
    isWithCast,
    onPressCastIcon,
    style,
    back,
    headerTitle,
    title,
    isCenterTitle,
    hideAction,
    extraAction,
    leftAction,
    noBack,
    middleContent,
    showBack,
    isCastLoading,
    castConnect,
  } = props;

  const theme = useDefaultTheme();

  const textColor = theme.white;

  let showCast = isWithCast;
  // let showCast = false;

  const arrowBack = (iconBack?: IIconProps['name']) => (
    <Pressable
      style={[
        CustomAppBarStyles.backTouchable,
        {
          height: Metrics.horizontalScale(48),
          minWidth: Metrics.horizontalScale(48),
        },
      ]}
      onPress={onPressArrowBack}>
      <Icon name={iconBack || 'retour'} size="24" color={textColor} />
      <Typography
        variant="body2"
        ellipsizeMode="tail"
        numberOfLines={1}
        style={{
          marginLeft: Metrics.horizontalScale(10),
          maxWidth: Metrics.window.width / 2.2,
        }}
        color={() => textColor}>
        {headerTitle || ''}
      </Typography>
    </Pressable>
  );

  return (
    <GenericAppBar
      style={style}
      backgroundColor={backgroundColor}
      title={
        typeof title === 'string' ? (
          <Typography
            children={title}
            variant={isCenterTitle ? 'htitle' : 'body2'}
            style={
              isCenterTitle && {
                marginTop: Metrics.verticalScale(4),
              }
            }
            numberOfLines={1}
          />
        ) : (
          title
        )
      }
      isCenterTitle={isCenterTitle}
      middleContent={middleContent}
      leading={
        showBack ? (
          arrowBack()
        ) : !back ? (
          <>
            {isWithLogo && (
              <Pressable onPress={onPressLogo}>
                <IcLogoSvg
                  width={Metrics.horizontalScale(91)}
                  height={Metrics.verticalScale(16)}
                />
              </Pressable>
            )}
          </>
        ) : (
          !noBack && arrowBack(back.icon)
        )
      }>
      {showCast ? (
        <></>
      ) : (
        // Platform.OS === 'ios' ? (
        //   <View style={{flexDirection: 'row'}}>
        //     <AirPlayButton
        //       activeTintColor="grey"
        //       tintColor="white"
        //       prioritizesVideoDevices={false}
        //       style={{width: 24, height: 24}}
        //     />
        //     {/* <View style={{marginLeft: Metrics.horizontalScale(15)}}>
        //       {!isCastLoading ? (
        //         <Pressable onPress={onPressCastIcon}>
        //           <Icon
        //             name={'cast'}
        //             size="24"
        //             color={castConnect ? 'gray' : textColor}
        //           />
        //         </Pressable>
        //       ) : (
        //         <CustomActivityIndicator />
        //       )}
        //     </View> */}
        //   </View>
        // ) : !isCastLoading ? (
        //   <Pressable onPress={onPressCastIcon}>
        //     <Icon
        //       name={'cast'}
        //       size="24"
        //       color={castConnect ? 'gray' : textColor}
        //     />
        //   </Pressable>
        // ) : (
        //   <CustomActivityIndicator />
        // )
        !hideAction && leftAction
      )}
      {extraAction}
    </GenericAppBar>
  );
};

const CustomAppBarStyles = StyleSheet.create({
  backTouchable: {flexDirection: 'row', alignItems: 'center'},
});

export default CustomAppBar;
