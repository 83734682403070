import {
  IAwardsArtistFragmentFragment,
  IButton,
  IButtonFragmentFragment,
} from '@src/Api/Graphql/gql/types.generated';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import VideoBlock from 'design-system/src/Components/VideoBlock/VideoBlock.web';
import React, {ReactNode, useMemo} from 'react';
import {View} from 'react-native';
import ReadMoreText from 'design-system/src/Components/ReadMoreText/ReadMoreText.web';
import Button from 'design-system/src/Components/Button/Button';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import useNavigation from '@src/Navigation/useNavigation';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {RouteTypename} from '@src/Navigation/type';
import mapButtonData, {
  IButtonData,
} from '@src/Umbraco/Utils/element/mapButtonData';
import useGetResponsiveCover from '@src/Umbraco/Hooks/useGetResponsiveCover';
interface IAwardArtistCardProps {
  artist: IAwardsArtistFragmentFragment;
}
const AwardArtistCard = (props: IAwardArtistCardProps) => {
  const {
    artist: {cover, title, subtitle, description = '', id, exportID, button},
  } = props;
  const {t} = useTranslation('awards');
  const {getResponsiveImage} = useGetResponsiveCover();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const aspectRatio = 430 / 537;
  const renderBlocks: ReactNode[] = [];
  if (cover && cover[0]?.content.__typename === 'HeroVideoCover') {
    const {image, desktopImage, vimeoHlsUrl, vimeoVideoId} = cover[0].content;
    renderBlocks.push(
      <VideoBlock
        key={'video-cover'}
        src={vimeoHlsUrl || ''}
        vimeoId={vimeoVideoId + ''}
        width="100%"
        height="auto"
        poster={getResponsiveImage(cover[0].content)}
        posterDesktop={desktopImage?.url}
        aspectRatio={aspectRatio}
      />,
    );
  }

  const handleVote = () => {
    new RouteFactory(
      {url: '', id: id, __typename: 'AwardVoting'},
      navigation,
      dispatch,
    ).navigate();
  };

  const buttonRender: ReactNode | undefined = useMemo(() => {
    if (button && button[0] && button[0].content.__typename === 'Button') {
      let buttonContent = button[0].content as IButtonFragmentFragment;
      const buttonData = mapButtonData(buttonContent);
      if (buttonData) {
        const {buttonProps: {title} = {}, url, id} = buttonData;

        if (url || id) {
          const handleButtonHeader =
            (item: IButtonData | undefined) => async () => {
              if (item) {
                const {id, __typename, url} = item;
                if (item.__typename === 'FormPage') {
                  navigation.navigate('ServiceForm', {
                    id: item.id,
                    __typename: item.__typename as RouteTypename,
                    type: 'url',
                  } as any);
                } else {
                  new RouteFactory(
                    {
                      id: id || '',
                      __typename: __typename as RouteTypename,
                      url: url || '',
                    },
                    navigation,
                    dispatch,
                  ).navigate();
                }
              }
            };
          return (
            <Button
              title={title || ''}
              style={{marginTop: 19}}
              variant="contained"
              size="small"
              onPress={handleButtonHeader(buttonData)}
            />
          );
        }
        return (
          <Button
            title={title || t('vote for me') + ''}
            style={{marginTop: 19}}
            variant="contained"
            size="small"
            onPress={handleVote}
          />
        );
      }
    }
    return undefined;
  }, []);
  return (
    <View>
      {renderBlocks}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 28,
          gap: 6,
        }}>
        <Typography variant="h2">{title}</Typography>
        {subtitle && <Typography variant="extra">{subtitle}</Typography>}
        <ReadMoreText
          style={{maxWidth: 361}}
          endTextColor={() => '#CB9538'}
          text={description}
          color="white"
          width={361}
          numberOfLines={3}
          seeMoreLabel={t('More') + ''}
          seeLessLabel={t('Less') + ''}
        />
      </div>
      {buttonRender || (
        <Button
          title={t('vote for me') + ''}
          style={{marginTop: 19}}
          variant="contained"
          size="small"
          onPress={handleVote}
        />
      )}
    </View>
  );
};

export default AwardArtistCard;
