import useDefaultPaywall from '@src/Store/selectors/useDefaultPaywall';
import PaywallSectionPage from '@src/Umbraco/Pages/PaywallSectionPage/PaywallSectionPage';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React from 'react';

const PaywallPage = (props: any) => {
  const defaultPaywall = useDefaultPaywall();
  const paywallId = props.id || defaultPaywall?.id;
  const {isMobile} = useBreakpoint();
  return (
    <PaywallSectionPage
      id={paywallId}
      orientation={isMobile ? 'portrait' : 'landscape'}
    />
  );
};

export default PaywallPage;
