import React from 'react';
import {View} from 'react-native';
import GenericCard from '../../GenericCard/GenericCard';
import RowCard from '../../RowCard/RowCard';
import ListenCardAside from '../ListenCardAside/ListenCardAside';
import {ListenCardProps} from '../ListenCardProps';
import IconRoundPlaySvg from '../../../../assets/svg/ic_round_play.svg';
// import IcSettingSvg from '../../../../assets/svg/setting-icon.svg';

const DownloadListenCard = (props: ListenCardProps) => {
  return (
    <RowCard
      left={
        <GenericCard
          coverImage={props.cover}
          type={'xsmall'}
          onPress={props.onPressPlayIcon}
          center={
            <View>
              <IconRoundPlaySvg />
            </View>
          }
        />
      }
      right={<ListenCardAside asideProps={props} />}
    />
  );
};

export default DownloadListenCard;
