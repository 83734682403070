import React, {useMemo, useState} from 'react';
import {
  Pressable,
  View,
  Animated,
  Dimensions,
  ViewStyle,
  StyleProp,
} from 'react-native';
import {
  Route,
  SceneRendererProps,
  NavigationState,
} from 'react-native-tab-view';
import Typography from '../../../Typography/Typography';
import {CustomTabBarStyles} from './CustomTabBar.styles';

const TAB_BAR_WIDTH = Dimensions.get('window').width;

export type CustomTabBarProps = SceneRendererProps & {
  navigationState: NavigationState<Route>;
  style?: StyleProp<ViewStyle>;
};

const CustomTabBar: React.FC<CustomTabBarProps> = ({
  navigationState,
  jumpTo,
  position,
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(TAB_BAR_WIDTH);
  const itemWidth = useMemo(
    () => containerWidth / navigationState.routes.length,
    [containerWidth, navigationState.routes.length],
  );
  const left = position.interpolate({
    inputRange: [0, 1],
    outputRange: [0, itemWidth],
  });

  return (
    <View
      style={CustomTabBarStyles.root}
      onLayout={event => {
        const {width} = event.nativeEvent.layout;
        if (width) {
          setContainerWidth(width);
        }
      }}>
      <View style={CustomTabBarStyles.tabBar}>
        {navigationState.routes.map((route, index) => {
          const isActive = index === navigationState.index;
          const tabStyle = isActive
            ? CustomTabBarStyles.activeTab
            : CustomTabBarStyles.tab;

          return (
            <Pressable
              key={route.key}
              style={[CustomTabBarStyles.tabItem, tabStyle]}
              onPress={() => jumpTo(route.key)}>
              <Typography
                variant="body2"
                color={isActive ? 'primary' : 'white'}
                style={CustomTabBarStyles.tabItemText}>
                {route.title}
              </Typography>
            </Pressable>
          );
        })}
      </View>
      <Animated.View
        style={[
          CustomTabBarStyles.tabBarActiveIndicator,
          {
            width: itemWidth,
            transform: [
              {
                translateX: left,
              },
            ],
          },
        ]}
      />
    </View>
  );
};

export default CustomTabBar;
