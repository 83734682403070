import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FlatList,
  ListRenderItem,
  StyleProp,
  StyleSheet,
  TextStyle,
  Pressable,
  View,
  ViewStyle,
  Platform,
  FlatListProps,
} from 'react-native';
import Metrics from '../../Theme/Metrics';
import {carouselStyles} from './Carousel.styles';
import Typography from '../Typography/Typography';
import {ITypographyProps} from '../Typography/Typography.props';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';
import Icon from '../Icon/Icon';
import PressableCustom from '../PressableCustom/PressableCustom';
import defaultTheme from '../../Theme/defaultTheme';

export interface CarouselProps<T> extends FlatListProps<T> {
  data: T[];
  title: string;
  titleColor?: ITypographyProps['color'];
  renderItem: ListRenderItem<T>;
  onItemPress?: (item: T, index?: number) => void;
  seeMore?: {
    label: string;
    onPress?: () => void;
  };
  styleTitle?: StyleProp<TextStyle>;
  contentWrapperStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  initialNumToRender?: number;

  gap?: number;
  itemSize?: number;
}

const Carousel = <T extends any>(props: CarouselProps<T>) => {
  const {
    data,
    title,
    titleColor = 'white',
    renderItem: defaultRenderItem,
    onItemPress,
    seeMore,
    styleTitle,
    contentWrapperStyle,
    containerStyle,
    initialNumToRender,
    gap,
    itemSize,
    ...rest
  } = props;
  const {mobile} = useBreakpoint();
  const listRef = useRef<FlatList | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [parentWidth, setParentWidth] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState(0);
  const borderSpacing = mobile ? 16 : 60;
  const listPaddingVertical = mobile ? 10 : 45;

  useLayoutEffect(() => {
    if (itemSize) {
      listRef.current?.scrollToIndex({index: 1}); // Scroll to top on layout change (optional)
    }
  }, []);

  const horizontalGap = Platform.OS === 'web' ? 0 : 16;
  const listGap: number = gap || 8;
  // const navHeight = carouselHeight - 45 * 2;
  const dataLength = data.length;
  const headerHeight = 21 + 13; // 21 is the height of the title, 13 is the margin bottom

  const [visibleIndex, setVisibleIndex] = useState(0);

  const itemsPerPage = useMemo(
    () =>
      parentWidth ? Math.floor(parentWidth / ((itemSize || 0) + listGap)) : 1,
    [parentWidth, itemSize, listGap],
  );

  const handleScroll = useCallback(
    (event: any) => {
      const offset = event.nativeEvent.contentOffset.x;
      const newIndex = Math.floor(offset / ((itemSize || 0) + listGap));
      setVisibleIndex(newIndex);
    },
    [visibleIndex, itemSize, gap],
  );

  const onNavigate = useCallback(
    (prev?: boolean) => {
      const newIndex = prev
        ? Math.max(0, visibleIndex - itemsPerPage)
        : Math.min(data.length - 1, visibleIndex + itemsPerPage);

      setVisibleIndex(newIndex);
      listRef.current?.scrollToIndex({
        index: newIndex,
        animated: true,
      });
    },
    [visibleIndex, itemsPerPage, data.length],
  );

  const handleItemPress = (item: T, index?: number) => () => {
    onItemPress && onItemPress(item, index);
  };

  const renderItem: ListRenderItem<T> = renderData => {
    const {index, item} = renderData;
    return (
      <Pressable
        style={StyleSheet.flatten({
          marginLeft:
            index === 0
              ? Metrics.horizontalScale(horizontalGap)
              : Metrics.horizontalScale(listGap),
          marginRight:
            data.length === index + 1
              ? Metrics.horizontalScale(horizontalGap)
              : undefined,
        })}
        onPress={handleItemPress(item, index)}>
        {defaultRenderItem(renderData)}
      </Pressable>
    );
  };
  return (
    <View
      style={[
        {
          paddingVertical: mobile
            ? Metrics.verticalScale(10)
            : Metrics.verticalScale(19),
        },
        containerStyle,
      ]}
      testID="carousel-root"
      onLayout={e => {
        setParentWidth(e.nativeEvent.layout.width);
      }}>
      <View
        style={StyleSheet.flatten([
          carouselStyles.header,
          {
            paddingHorizontal:
              Platform.OS === 'web'
                ? 0
                : Metrics.horizontalScale(horizontalGap),
            paddingRight:
              Platform.OS === 'web' ? (mobile ? 16 : 60) : undefined,
            zIndex: 5,
          },
          contentWrapperStyle,
        ])}>
        {Boolean(title) && (
          <Typography variant="h3" color={titleColor} style={styleTitle}>
            {title}
          </Typography>
        )}
        {seeMore && (
          <Pressable
            style={{padding: 13, margin: -13}}
            onPress={seeMore.onPress}>
            <Typography
              children={seeMore.label}
              color="primary"
              variant="button2"
            />
          </Pressable>
        )}
      </View>
      <FlatList
        data={data}
        ref={listRef}
        renderItem={renderItem}
        onLayout={e => {
          if (carouselHeight < 10) {
            setCarouselHeight(e.nativeEvent.layout.height);
          }
        }}
        initialNumToRender={initialNumToRender}
        keyExtractor={(_, index) => index + ''}
        horizontal
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        getItemLayout={
          itemSize
            ? (_data, index) => {
                const marginLeft =
                  index === 0
                    ? Metrics.horizontalScale(horizontalGap)
                    : Metrics.horizontalScale(listGap);
                const marginRight =
                  data.length === index + 1
                    ? Metrics.horizontalScale(horizontalGap)
                    : undefined;
                const totalSize = marginLeft + itemSize + (marginRight || 0);
                return {
                  length: totalSize,
                  offset: totalSize * index,
                  index,
                };
              }
            : undefined
        }
        style={Platform.select({
          web: {
            paddingVertical: listPaddingVertical,
            marginVertical: -listPaddingVertical,
            paddingLeft: borderSpacing,
            marginLeft: -borderSpacing,
            paddingRight: borderSpacing,
          },
        })}
        testID="carousel-flatlist"
        onScroll={handleScroll}
        {...rest}
      />

      {!!dataLength && itemSize && (
        <>
          {visibleIndex > 0 && (
            <PressableCustom
              style={{
                backgroundColor: 'rgba(20,20,20, 0.5)',
                height: '100%',
                width: borderSpacing,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                left: 0,
                zIndex: 50,
                marginLeft: -borderSpacing,
                paddingBottom: listPaddingVertical,
                paddingTop: headerHeight,
              }}
              onPress={() => onNavigate(true)}
              testID="carousel-previous-button">
              {({hover}) => (
                <Icon
                  name="arrow-left"
                  style={hover ? {transform: [{scale: 1.2}]} : undefined}
                  color={hover ? defaultTheme.primary : undefined}
                />
              )}
            </PressableCustom>
          )}
          {visibleIndex < dataLength - itemsPerPage && (
            <PressableCustom
              style={{
                backgroundColor: 'rgba(20,20,20, 0.5)',
                height: '100%',
                width: borderSpacing,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                right: 0,
                zIndex: 50,
                marginLeft: borderSpacing,
                paddingBottom: listPaddingVertical,
                paddingTop: headerHeight,
              }}
              onPress={() => onNavigate()}
              testID="carousel-next-button">
              {({hover}) => (
                <Icon
                  name="arrow-right"
                  style={hover ? {transform: [{scale: 1.2}]} : undefined}
                  color={hover ? defaultTheme.primary : undefined}
                />
              )}
            </PressableCustom>
          )}
        </>
      )}
    </View>
  );
};

export default Carousel;
