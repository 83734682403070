import React from 'react';
import ContentLoader, {Rect} from 'react-content-loader/native';
import {Platform, StyleSheet} from 'react-native';
import Metrics, {width} from '../../../Theme/Metrics';

const TextInputLoader = () => {
  const customHeight = Platform.OS === 'web' ? 50 : Metrics.verticalScale(50);
  const customWidth = '100%';
  // const customWidth = Metrics.horizontalScale(width - 66 );

  return (
    <ContentLoader
      style={StyleSheet.flatten([
        {
          backgroundColor: 'rgba(23, 24, 29, 0.7)',
          borderRadius: Metrics.verticalScale(customHeight * 0.48),
          overflow: 'hidden',
          marginBottom: Metrics.verticalScale(20),
          width: '100%',
        },
      ])}
      height={customHeight}
      width={customWidth}
      speed={1}
      backgroundColor={'rgba(23, 24, 29, 0.7)'}
      foregroundColor={'#111216'}>
      <Rect
        x="0"
        y="0"
        rx="16"
        ry="16"
        width={`${customWidth}`}
        height={`${customHeight}`}
        fill={'#111216'}
      />
    </ContentLoader>
  );
};

export default TextInputLoader;
