import React, {useEffect, useState} from 'react';
import {View, Pressable} from 'react-native';
import {List} from 'react-native-paper';
import Metrics from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import {AdditionalServiceData} from './Step/Step4';

export interface ServiceFormAdditionalProps extends AdditionalServiceData {
  onChange?: (isChecked: boolean) => void;
  isSelected?: boolean;
  disableChange?: boolean;
}

export default function ServiceFormAdditional(
  props: ServiceFormAdditionalProps,
) {
  const {title, description, onChange, isSelected, disableChange} = props;

  const [checked, setChecked] = useState<boolean | undefined>(isSelected);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (checked !== isSelected) {
      setChecked(isSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  const handleCheck = () => {
    setChecked(!checked);
  };

  return (
    <List.Accordion
      title={<Typography variant="h4">{title}</Typography>}
      theme={{colors: {background: 'transparent'}}}
      pointerEvents="auto"
      onPress={() => setExpanded(!expanded)}
      expanded={expanded}
      left={() => (
        <Pressable
          onPress={() => {
            if(!disableChange) {
              onChange && onChange(!checked);
              handleCheck();
            }
          }}
          style={{paddingLeft: 24}}>
          <Icon
            name={checked ? 'check-circle' : 'check-empty'}
            color={checked ? '#EA6C13' : '#737373'}
          />
        </Pressable>
      )}
      right={_props => (
        <Icon
          name={_props.isExpanded ? 'caret-up' : 'caret-down'}
          style={{marginRight: 10}}
        />
      )}
      style={{
        borderBottomWidth: !expanded ? 1 : 0,
        borderBottomColor: checked ? '#EA6C13' : '#2F3139',
        borderTopWidth: 1,
        borderTopColor: checked ? '#EA6C13' : '#2F3139',
        borderLeftWidth: 1,
        borderLeftColor: checked ? '#EA6C13' : '#2F3139',
        borderRightWidth: 1,
        borderRightColor: checked ? '#EA6C13' : '#2F3139',
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: checked ? 'rgba(234, 108, 19, 0.13)' : '#16181E',
        borderTopLeftRadius: 4,
        marginTop: 8,
        borderTopRightRadius: 4,
        paddingTop: Metrics.horizontalScale(19),
        paddingBottom: Metrics.horizontalScale(19),
      }}>
      <View
        style={{
          paddingLeft: Metrics.horizontalScale(24),
          paddingRight: Metrics.horizontalScale(24),
          paddingBottom: Metrics.verticalScale(31),
          borderBottomWidth: 1,
          borderBottomColor: checked ? '#EA6C13' : '#2F3139',
          borderLeftWidth: 1,
          borderLeftColor: checked ? '#EA6C13' : '#2F3139',
          borderRightWidth: 1,
          borderRightColor: checked ? '#EA6C13' : '#2F3139',
          backgroundColor: checked ? 'rgba(234, 108, 19, 0.13)' : '#16181E',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}>
        <Typography variant="body2">{description}</Typography>
      </View>
    </List.Accordion>
  );
}
