import {graphql, HeadFC, PageProps} from 'gatsby';
import React from 'react';
import AwardHomePage from '@src/Umbraco/Pages/AwardPage/AwardHomePage';
import useAwardSetting from '@src/layout/AwardLayout/useAwardSetting';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageLoader from '@src/components/general/PageLoader/PageLoader';
import PageHead from '@src/components/general/PageHead/PageHead';

const AwardsPage = (props: PageProps) => {
  const {homepageId, loading} = useAwardSetting();
  const {t} = useTranslation('translation');
  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : homepageId ? (
        <AwardHomePage id={homepageId} />
      ) : (
        <ContentEmpty title={t('Not found')} />
      )}
    </div>
  );
};

AwardsPage.layoutComponent = 'AwardLayout';

export default AwardsPage;

export const query = graphql`
  query AwardHomePage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    umbraco {
      allAwardsHomepage(first: 1) {
        items {
          ...AwardSettingsFragment
        }
      }
    }
  }

  fragment AwardSettingsFragment on Umbraco_AwardsHomepage {
    id
    urlRewrite
    metaTitle
    metaDescription
    __typename
  }
`;

export const Head: HeadFC<Queries.AwardHomePageQuery, any> = headProps => {
  const {
    data: {
      umbraco: {allAwardsHomepage},
    },
  } = headProps;
  return (
    <PageHead
      title={allAwardsHomepage?.items?.[0]?.metaTitle || undefined}
      description={allAwardsHomepage?.items?.[0]?.metaDescription || undefined}
      {...headProps}
    />
  );
};
