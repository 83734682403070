import {StyleSheet} from 'react-native';
import Metrics from '../../Theme/Metrics';

export const MediaCoverStyles = StyleSheet.create({
  root: {
    flex: 1,
    // paddingBottom: Metrics.horizontalScale(80),
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    paddingTop: Metrics.verticalScale(301),
    // paddingBottom: Metrics.verticalScale(31),
  },
});
