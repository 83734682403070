/* eslint-disable react-native/no-inline-styles */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PlayerSlider from './PlayerSlider/PlayerSlider.web';
import PlayerHeader from './PlayerHeader/PlayerHeader.web';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import {darkTheme} from '../../../styles/Themes';
import {IVideoSource} from './VideoPlayer';
import {useVideoPlayer} from '../Hooks/useVideoPlayer.web';
import PlayButton from '../../../Components/Player/PlayButton/PlayButton';
import {useChannel} from '../Hooks/useChannel.web';
import {parseHlsResponse} from '../Hooks/useVideoPlayer';
import {CustomActivityIndicator} from '../../../Components/CustomActivityIndicator';
import screenfull from 'screenfull';
import {OnLoadData} from 'react-native-video';
import {useMediaSession} from '../Hooks/useMediaSession';

interface VideoPlayerVerticalProps {
  title: string;
  borderRadius?: number;
  onGoBack?: () => void;
  poster?: string;
  source: IVideoSource;
  onVideoLoad?: (
    data: OnLoadData,
    originalQuality?: number,
    loadTime?: number,
  ) => void;
  onGetDuration?: (duration: number) => void;
  onPlayEnd?: () => void;
  onVideoPlay?: (isPaused: boolean) => void;
  onTimeProgress?: (time: number) => void;
  adTagUrl?: string;
  initialTime?: number;
}

const VideoPlayerVertical: FC<VideoPlayerVerticalProps> = ({
  title,
  borderRadius,
  onGoBack,
  poster,
  source,
  onVideoLoad,
  onGetDuration,
  onVideoPlay,
  onTimeProgress,
  onPlayEnd,
  initialTime,
  // adTagUrl,
}) => {
  const {isMobile} = useBreakpoint();

  const [isMouseIdle, setIsMouseIdle] = useState(true);

  const timeLoadStart = useRef<number>();

  const hoverTimeoutRef = useRef<HTMLDivElement | null>(null);

  const {currentSource} = useChannel<IVideoSource>([source], 0);

  const videoContainerRef = useRef<HTMLDivElement | null>(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const {
    videoRef,
    loaded,
    setLoaded,
    duration,
    playbackRate,
    playing,
    handlePlayPause,
    handleSliderChange,
    played,
    playedSeconds,
    resetPlayer,
    handleTimeUpdate,
    handleLoadedMetadata,
    isLoadedMetadata,
    setPlaying,
  } = useVideoPlayer({currentSource, initialTime});

  const isHideControls = useMemo(() => isMouseIdle, [isMouseIdle]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackRate]);

  useEffect(() => {
    const timeoutRef: any = hoverTimeoutRef.current;
    // Clear the timeout when the component unmounts
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, []);

  useMediaSession({
    videoRef,
    title,
    poster,
    description: currentSource?.description,
    isLive: false,
    duration,
    playedSeconds,
    playbackRate,
    setPlaying,
    onVideoPlay,
    skip: () => {},
    handleOnSkipChange: () => {},
    onNext: () => {},
    channelUp: () => {},
    currentMedia: 0,
    sourceListLength: 1,
  });

  const handleProgress = () => {
    const video = videoRef.current;
    if (video && video.buffered.length > 0) {
      const end = video.buffered.end(video.buffered.length - 1);
      onTimeProgress && onTimeProgress(end);
      const percentage = (end / video.duration) * 100;
      setLoaded(percentage);
    }
    // handleOnCustomProgress
  };

  const handleMouseActivity = () => {
    setIsMouseIdle(false);
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current as any);
    }
    hoverTimeoutRef.current = setTimeout(
      () => {
        setIsMouseIdle(true);
      },
      isMobile ? 5000 : 1000,
    ) as any; // Set idle status after 5 seconds of inactivity
  };

  const handleLoad = useCallback(
    (data: any) => {
      const timeStart = timeLoadStart.current || 0;
      const loadTime = Math.round((Date.now() - timeStart) / 1000);
      // onLoad(data);
      if (typeof source === 'object' && source.uri) {
        fetch(source.uri as string)
          .then(async response => {
            let value = await response.text();
            let dataParsed = parseHlsResponse(value);
            if (dataParsed[0]) {
              onVideoLoad && onVideoLoad(data, dataParsed[0].height, loadTime);
            } else {
              onVideoLoad && onVideoLoad(data, undefined, loadTime);
            }
          })
          .catch(error => {
            console.log('error loadedData', error);
          });
      } else {
        onVideoLoad && onVideoLoad(data, undefined, loadTime);
      }
      onGetDuration && onGetDuration(data.duration);
    },
    [onGetDuration, onVideoLoad, source],
  );

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && videoContainerRef.current) {
      screenfull.toggle(videoContainerRef.current);
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <div
      ref={videoContainerRef}
      style={{
        width: '100%',
        height: '100%',
      }}>
      <video
        ref={videoRef}
        poster={poster}
        // controls
        onTimeUpdate={handleTimeUpdate}
        onPlay={() => {
          onVideoPlay && onVideoPlay(true);
          if (!playing) {
            setPlaying(true);
          }
        }}
        onPause={() => {
          if (playing) {
            setPlaying(false);
          }
        }}
        onLoadedMetadata={handleLoadedMetadata}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: borderRadius,
          backgroundColor: darkTheme.colors.backgroundBlack,
        }}
        onProgress={handleProgress}
        onLoadStart={() => {
          //    handleOnVideoLoad,
          timeLoadStart.current = Date.now();
        }}
        onEnded={() => {
          onPlayEnd && onPlayEnd();
        }}
        onLoad={handleLoad}
        autoPlay={true}
        x-webkit-airplay="allow"
      />

      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}>
        <div
          // onClick={handlePlayPause}
          ref={hoverTimeoutRef}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseActivity}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: isMobile ? '0 16px' : '0 24px',
            position: 'relative',
            cursor: isMouseIdle ? 'none' : 'default',
          }}>
          <PlayerHeader
            title={source?.description ?? title}
            isLive={false}
            onGoBack={onGoBack}
            isHide={isHideControls}
            poster={poster}
            currentMedia={0}
            isVertical={true}
            playedSeconds={played}
            uri={source.uri as string}
          />
          {!isHideControls && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 0,
                  flex: 1,
                  alignItems: 'center',
                }}>
                {isLoadedMetadata ? (
                  <PlayButton
                    isPlaying={playing}
                    onPress={handlePlayPause}
                    // style={videoControlPlayerStyles.playButton}
                  />
                ) : (
                  <CustomActivityIndicator size="large" />
                )}
              </div>
              <PlayerSlider
                duration={duration}
                onSliderChange={handleSliderChange}
                onResetPlayer={resetPlayer}
                played={played}
                playedSeconds={playedSeconds}
                loaded={loaded}
                isVerticale={true}
                isWithNext={false}
                sourceList={[]}
                toggleFullscreen={toggleFullscreen}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerVertical;
