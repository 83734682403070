import React, {useEffect, useState} from 'react';
import {PressableProps} from 'react-native';
import Icon, {IIconProps} from '../../Icon/Icon';
import defaultTheme from '../../../Theme/defaultTheme';
import PressableCustom from '../../PressableCustom/PressableCustom';

export interface FavorisProps extends Omit<PressableProps, 'onPress'> {
  isLiked?: boolean;
  onPressFav?: (value: boolean) => void;
  iconSize?: IIconProps['size'];
  onHoverChange?: (hover: boolean) => void;
  onValueChange?: (value: boolean) => void;
}

const FavorisIcon = (props: FavorisProps) => {
  const {
    isLiked = false,
    style,
    onPressFav,
    iconSize = '36',
    onHoverChange,
    onValueChange,
  } = props;
  const [isChecked, setIsChecked] = useState<boolean>(isLiked);

  const handleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onValueChange?.(newValue);
    onPressFav && onPressFav(newValue);
  };

  useEffect(() => {
    if (isLiked !== isChecked) {
      setIsChecked(isLiked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiked]);
  return (
    <PressableCustom
      style={style}
      onPress={handleChange}
      onHoverChange={onHoverChange}>
      {({hover}) => (
        <Icon
          color={hover ? defaultTheme.primary : undefined}
          name={isChecked ? 'favoris-fill' : 'favoris'}
          size={iconSize}
        />
      )}
    </PressableCustom>
  );
};

export default FavorisIcon;
