import apiConfig from '@src/Configs/apiConfig';
import {purposes, vendors} from './cookies';

declare global {
  interface Window {
    Didomi: any;
  }
}

export const parsedCookieData = () => {
  let userConsent: Record<string, boolean> = {};
  let cookies: Record<string, boolean> = {};

  if (typeof window !== 'undefined') {
    const userStatus = window.Didomi.getUserStatus();
    let isAmplitudeEnabled: boolean = false;
    let isBrazeEnabled = false;
    Object.keys(purposes).forEach((key: string) => {
      const isEnabled = !!userStatus?.purposes?.global?.enabled?.find(
        (purp: string) => purp === purposes[key],
      );
      if (isEnabled) {
        cookies[key] = true;
      } else {
        cookies[key] = false;
      }
    });
    Object.keys(vendors).forEach((key: string) => {
      const isEnabled = !!userStatus?.vendors?.global?.enabled?.find(
        (vend: string) => vend === vendors[key],
      );
      if (isEnabled) {
        cookies[key] = true;
      } else {
        cookies[key] = false;
      }
    });
    userStatus?.vendors?.consent?.enabled?.forEach((vendorId: string) => {
      if (vendorId === apiConfig.brazeId) {
        isBrazeEnabled = true;
      }
      if (vendorId === apiConfig.amplitudeVendorId) {
        isAmplitudeEnabled = true;
      }
      userConsent[vendorId] = true;
    });
    userStatus?.vendors?.global?.enabled?.forEach((vendorId: string) => {
      if (vendorId === apiConfig.brazeId) {
        isBrazeEnabled = true;
      }
      if (vendorId === apiConfig.amplitudeVendorId) {
        isAmplitudeEnabled = true;
      }
      userConsent[vendorId] = true;
    });
    userStatus?.purposes?.global?.enabled?.forEach((purposeId: string) => {
      userConsent[purposeId] = true;
      if (purposeId === apiConfig.brazeId) {
        isBrazeEnabled = true;
      }
      if (purposeId === apiConfig.amplitudePurpId) {
        isAmplitudeEnabled = true;
      }
    });
  }

  return {
    cookies,
    userConsent,
  };
};
