import React, {ReactNode} from 'react';
import DefaultLayout from '../DefaultLayout/DefaultLayout';

const EmptyLayout: React.FC<{children: ReactNode}> = props => {
  const {children} = props;
  return (
    <div className="empty-layout">
      <DefaultLayout contentOnly={true}>{children}</DefaultLayout>
    </div>
  );
};

EmptyLayout.displayName = 'EmptyLayout';
export default EmptyLayout;
