import {graphql, HeadFC} from 'gatsby';
import React from 'react';
import {PageProps} from 'gatsby';
import AwardCategoryPage from '@src/Umbraco/Pages/AwardPage/AwardCategoryPage/AwardCategoryPage';
import PageHead from '@src/components/general/PageHead/PageHead';

const AwardCategoryPageTemplate = (props: PageProps) => {
  const {
    params,
    // @ts-ignore
    pageContext: {id: mainId},
  } = props;
  const {id} = params;

  return (
    <div>
      <AwardCategoryPage id={id || mainId} />
    </div>
  );
};
AwardCategoryPageTemplate.layoutComponent = 'AwardLayout';
AwardCategoryPageTemplate.layoutProps = {};

export default AwardCategoryPageTemplate;

export const query = graphql`
  query ($language: String!, $id: ID) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    umbraco {
      awardCategoryPage(id: $id) {
        id
        urlRewrite
        metaTitle
        metaDescription
      }
    }
  }
`;

export const Head: HeadFC<Queries.AwardHomePageQuery, any> = headProps => {
  return <PageHead {...headProps} />;
};
