import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberSearchBody} from './interface/memberDataBody.interface';
import { ISearchResponse } from './interface/memberDataResponse.interface';

const endpoint = '/me/search';
export const createMemberSearchEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getAllMemberSearch: builder.query<ISearchResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  createMemberSearch: builder.query<ISearchResponse, {data: IMemberSearchBody}>({
    query: ({data}) => ({
      url: endpoint,
      method: 'POST',
      body: data,
    }),
  }),
  deleteMemberSearch: builder.query<any, {id: string}>({
    query: ({id}) => ({
      url: endpoint + `/${id}`,
      method: 'DELETE',
    }),
  }),
});
