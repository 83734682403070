import React, {useEffect, useState} from 'react';
import {View, Pressable} from 'react-native';
import Metrics from '../../Theme/Metrics';
import CustomFastImage from '../CustomFastImage/CustomFastImage';
import Typography from '../Typography/Typography';
import {ServiceFormChannelData} from './ServiceFormChannel';

export interface ServiceFormChannelItemsProps extends ServiceFormChannelData {
  selectedNumber?: number;
  onChange?: (isSelected: boolean) => void;
  isChecked?: boolean;
}

export default function ServiceFormChannelItems(
  props: ServiceFormChannelItemsProps,
) {
  const {logo, selectedNumber = 0, onChange, isChecked} = props;

  const [selected, setSelected] = useState<boolean | undefined>(isChecked);

  useEffect(() => {
    if (selected !== isChecked) {
      setSelected(isChecked);
    }
  }, [isChecked]);

  const handleSelected = () => {
    setSelected(!selected);
  };

  return (
    <Pressable
      onPress={() => {
        onChange && onChange(!selected);
        handleSelected();
      }}>
      <View
        style={{
          alignItems: 'center',
          paddingTop: Metrics.horizontalScale(14),
          paddingBottom: Metrics.horizontalScale(14),
          backgroundColor: '#1B1C21',
          borderWidth: selected ? 1 : 0,
          borderColor: selected ? 'rgba(234, 108, 19, 0.5)' : 'transparent',
        }}>
        <View style={{position: 'relative'}}>
          {selected && (
            <View
              style={{
                backgroundColor: '#EA6C13',
                width: Metrics.horizontalScale(20),
                height: Metrics.verticalScale(20),
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: -10,
                right: -10,
                zIndex: 10,
              }}>
              <Typography variant="button1">{selectedNumber + 1}</Typography>
            </View>
          )}
          <CustomFastImage
            source={logo}
            width={Metrics.horizontalScale(64)}
            height={Metrics.verticalScale(44.7)}
          />
        </View>
      </View>
    </Pressable>
  );
}
