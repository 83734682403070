import React from 'react';
import {View} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';

export type HelpDeskProps = {
  title: string;
  icon?: IconName;
  description: string;
  image: string;
};

export default function HelpDesk(props: HelpDeskProps) {
  const {icon = 'badge-help', title, description, image} = props;
  return (
    <View
      style={{position: 'relative', minHeight: Metrics.horizontalScale(112)}}>
      <View
        style={{
          position: 'relative',
          zIndex: 2,
          flexDirection: 'row',
          paddingTop: Metrics.horizontalScale(25),
          paddingBottom: Metrics.horizontalScale(25),
        }}>
        <View
          style={{
            flexBasis: Metrics.verticalScale(81),
            alignItems: 'center',
          }}>
          <Icon name={icon} />
        </View>
        <View
          style={{
            flexBasis: Metrics.verticalScale(343 - 81),
            marginTop: Metrics.horizontalScale(-3),
          }}>
          <Typography variant="h3">{title}</Typography>
          <Typography
            variant="body3"
            style={{marginTop: Metrics.horizontalScale(7)}}>
            {description}
          </Typography>
        </View>
      </View>
      <View style={{position: 'absolute', top: 0, left: 0}}>
        <CustomFastImage
          source={{uri: image}}
          width={Metrics.verticalScale(343)}
          height={Metrics.horizontalScale(112)}
        />
      </View>
    </View>
  );
}
