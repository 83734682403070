import React, {ReactNode} from 'react';
import {Pressable, View} from 'react-native';
import Icon from '../../Icon/Icon';
import GenericPremiumBody from '../GenericPremiumBody/GenericPremiumBody';
import {premiumStyles} from './PremiumCard.styles';
import Typography from '../../Typography/Typography';
import {IVariantContainerProps, PremiumVariant} from './PremiumVariant';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

export interface PremiumCardProps {
  variant?: keyof typeof PremiumVariant;
  headerTitle: string;
  title: string;
  description: string;
  cover?: IVariantContainerProps['source'];
  coverDesktop?: IVariantContainerProps['source'];
  onPress?: () => void;
  customIcon?: () => ReactNode;
  hideHeader?: boolean;
  responsiveRatio?: boolean;
}

const PremiumCard = (props: PremiumCardProps) => {
  const {
    title,
    headerTitle,
    description,
    variant = 'primary',
    onPress,
    cover,
    coverDesktop,
    customIcon,
    hideHeader,
    // responsiveRatio,
  } = props;
  const {
    icon: IconCard,
    container: Container,
    color: descColor,
  } = PremiumVariant[variant];
  // const desktopRatio = 1444 / 153;

  const {mobile} = useBreakpoint();

  return (
    <Container
      source={mobile ? cover : coverDesktop}
      // style={[
      //   responsiveRatio
      //     ? {
      //         aspectRatio: mobile ? undefined : desktopRatio,
      //       }
      //     : {},
      // ]}
    >
      {!hideHeader && (
        <View style={premiumStyles.header}>
          <Typography variant="h4">{headerTitle}</Typography>
          <Pressable onPress={onPress}>
            <Icon name="arrow-right" />
          </Pressable>
        </View>
      )}
      <GenericPremiumBody
        title={title}
        isAlignCenter={false}
        description={description}
        descriptionColor={descColor}
        leadingCard={<View>{customIcon ? customIcon() : <IconCard />}</View>}
      />
    </Container>
  );
};

export default PremiumCard;
