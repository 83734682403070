import React from 'react';
import ContentLoader, {Circle} from 'react-content-loader/native';
import Metrics from '../../../../Theme/Metrics';

const CircleLoader = () => {
  let size = Math.round(Metrics.horizontalScale(40));

  return (
    <ContentLoader
      height={size}
      width={size}
      speed={1}
      style={{
        backgroundColor: 'rgba(23, 24, 29, 0.7)',
        borderRadius: Metrics.verticalScale(size / 2),
        overflow: 'hidden',
      }}
      backgroundColor={'rgba(23, 24, 29, 0.7)'}
      foregroundColor={'#111216'}>
      <Circle cx={`${size / 2}`} cy={`${size / 2}`} r={`${size / 2}`} />
    </ContentLoader>
  );
};

export default CircleLoader;
