import {StyleSheet} from 'react-native';
import Metrics from '../../Theme/Metrics';

export const switchStyles = StyleSheet.create({
  toggleContainer: {
    width: Metrics.horizontalScale(52),
    padding: Metrics.horizontalScale(2),
    borderRadius: Metrics.moderateScale(24),
    justifyContent: 'center',
  },
  toggleWheelStyle: {
    width: Metrics.horizontalScale(26),
    height: Metrics.horizontalScale(26),
    backgroundColor: 'white',
    borderRadius: Metrics.horizontalScale(26) / 2,
  },
});
