import React, {ReactNode, Suspense} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createPortal} from 'react-dom';
const DefaultHeader = React.lazy(() => import('./DefaultHeader/DefaultHeader'));

interface IDefaultLayoutProps {
  children: ReactNode;
  contentOnly?: boolean;
}

const DefaultLayout: React.FC<IDefaultLayoutProps> = props => {
  const {children, contentOnly} = props;

  return (
    <div
      className="default-layout"
      style={{
        maxWidth: 1920,
        margin: '0 auto',
      }}>
      <Suspense>
        <DefaultHeader style={contentOnly ? {display: 'none'} : {}} />
      </Suspense>
      <main>{children}</main>
      {createPortal(
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{zIndex: 99999}}
        />,
        document.body,
        'default-tostify-container',
      )}
    </div>
  );
};

export default DefaultLayout;
