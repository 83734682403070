import React from 'react';
import PaywallHomePage from './PaywallHomePage/PaywallHomePage';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {IPaywallOrientation} from './PaywallSection.type';

interface IPaywallSectionPagerops {
  id?: string;
  variant?: 'paywall' | 'choosePlan';
  orientation?: IPaywallOrientation;
}

const PaywallSectionPage = (props: IPaywallSectionPagerops) => {
  const {id, variant = 'paywall', orientation = 'portrait'} = props;
  const navigation = useAppNavigation();
  return (
    // <View style={{flex: 1}}>
    <PaywallHomePage
      variant={variant}
      orientation={orientation}
      id={id}
      onSelectPlan={() => navigation.navigate(AppRoutesEnum.CHOOSE_PLAN)}
    />
    // </View>
  );
};

export default PaywallSectionPage;
