import Icon from 'design-system/src/Components/Icon/Icon';
import React, {FC, Suspense, useEffect} from 'react';
import {Pressable} from 'react-native';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
// import {appNavigate} from '@src/utils/navigationUtils';
// import {AppRoutesEnum} from '@src/Constant/routes';
import CustomModal from '@src/components/general/CustomModal/CustomModal';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useSetupRegion from '@src/Hooks/useSetupRegion';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ActivateAccount from './ActivateAccount/ActivateAccount';

const ForgotPassword = React.lazy(
  () => import('./ForgotPassword/ForgotPassword'),
);
const SignIn = React.lazy(() => import('./SignIn/SignIn'));
const SignUp = React.lazy(() => import('./SignUp/SignUp'));

interface IModalLoginInscriptionProps {
  isOpen: boolean;
  onClose?: () => void;
}

const ModalAuthentication: FC<IModalLoginInscriptionProps> = props => {
  const {isOpen, onClose} = props;

  const step = useAppSelector(state => state.appAuth);
  const {cover, webCover} = useAppSelector(state => state.settings.onboarding);
  const dispatch = useAppDispatch();
  const {isMobile} = useBreakpoint();
  const {isConnected} = useFireBaseAuthentication();
  const {fetch} = useSetupRegion();

  useEffect(() => {
    fetch();
    isConnected().then(res => {
      if (res) {
        onClose?.();
        dispatch(appAuthSlice.actions.resetAuth());
      }
    });
  }, []);

  const background = isMobile ? cover : webCover;
  const isCanGoBack =
    step.authentificationType === 'register' &&
    step.registerStep.page !== 'first';

  const handleGoBack = () => {
    const page = step.registerStep.page;
    const stepNumber = step.registerStep.step;
    if (page === 'interest') {
      if (stepNumber > 0) {
        dispatch(
          appAuthSlice.actions.setAuthStep({
            page: 'interest',
            step: stepNumber - 1,
          }),
        );
      } else {
        dispatch(
          appAuthSlice.actions.setAuthStep({
            page: 'form',
            step: 0,
          }),
        );
      }
    } else {
      dispatch(
        appAuthSlice.actions.setAuthStep({
          page: 'first',
          step: 0,
        }),
      );
    }
  };

  const component = () => {
    switch (step.authentificationType) {
      case 'register':
        return (
          <Suspense fallback={<CustomActivityIndicator />}>
            <SignUp />
          </Suspense>
        );
      case 'forgotPassword':
        return (
          <Suspense fallback={<CustomActivityIndicator />}>
            <ForgotPassword />
          </Suspense>
        );
      case 'activateAccount':
        return (
          <Suspense fallback={<CustomActivityIndicator />}>
            <ActivateAccount />
          </Suspense>
        );
      case 'login':
      default:
        return (
          <Suspense fallback={<CustomActivityIndicator />}>
            <SignIn />
          </Suspense>
        );
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={200}
      varToCheckChangeScreen={`${step.authentificationType} ${step.registerStep.page} ${step.registerStep.step}`}
      header={
        <div
          style={{
            display: 'flex',
            justifyContent: isCanGoBack ? 'space-between' : 'flex-end',
            padding: 20,
            width: '100%',
          }}>
          {isCanGoBack && (
            <Pressable onPress={handleGoBack}>
              <Icon name="retour" size="76" />
            </Pressable>
          )}
          <Pressable onPress={onClose}>
            <Icon name="close" size="76" />
          </Pressable>
        </div>
      }>
      <div
        style={{
          backgroundColor: '#111216',
          width: '100%',
          height: '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          position: 'relative',
        }}>
        <div
          style={{
            backgroundColor: '#111216',
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            position: 'absolute',
            zIndex: 0,
            aspectRatio: isMobile ? 3 / 4 : 1142 / 538,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 44.86%, rgba(17, 18, 22, 0.80) 71.63%, #111216 100%)',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}></div>
        </div>

        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',

            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            zIndex: 0,
            backgroundColor: '#00000066',
          }}></div>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: 49,
            paddingTop: 90,
          }}>
          {component()}
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalAuthentication;
