/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import GenericCard from '../../GenericCard/GenericCard';
import {LiveCardProps} from '../LiveCardProps';
import RowCard from '../../RowCard/RowCard.web';
import LiveCardAside, {
  ILiveCardAsideProps,
} from '../LiveCardAside/LiveCardAside';
import ChannelLogo from '../../../ChannelLogo/ChannelLogo';
import Images from '../../../../assets/Images';
import {TraceWhiteLogo} from '../../../../assets/ImageSvg';
import {TouchableOpacity, View} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import PremiumIcon from '../../PremiumIcon';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';

const SearchLiveCard: React.FC<
  LiveCardProps & {
    descriptionProps?: ILiveCardAsideProps['descriptionProps'];
    fullWidth?: boolean;
    aspectRatio?: number;
  }
> = props => {
  const {descriptionProps, isFreemium, premiumText, fullWidth, aspectRatio} =
    props;
  const {isMobile} = useBreakpoint();
  return (
    <TouchableOpacity onPress={props.onPress}>
      <RowCard
        left={
          <View style={{flex: !isMobile ? 1 : undefined}}>
            <GenericCard
              coverImage={props.cover}
              type="xsmall"
              fullWidth={fullWidth}
              aspectRatio={aspectRatio}
              footer={
                <View
                  style={{
                    position: 'absolute',
                    bottom: Metrics.horizontalScale(9),
                    right: Metrics.horizontalScale(9),
                  }}>
                  {isFreemium !== undefined && !isFreemium && <PremiumIcon />}
                </View>
              }
              center={
                props.channelLogo ? (
                  typeof props.channelLogo === 'function' ? (
                    props.channelLogo()
                  ) : (
                    <ChannelLogo source={Images.channel[props.channelLogo]} />
                  )
                ) : (
                  !props.cover && <TraceWhiteLogo />
                )
              }
            />
          </View>
        }
        right={
          <LiveCardAside
            asideProps={props}
            descriptionProps={descriptionProps}
          />
        }
        rightFlex={isMobile ? 1 : undefined}
      />
    </TouchableOpacity>
  );
};

export default SearchLiveCard;
