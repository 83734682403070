import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import {GridView} from '../../GridView';
import BarLoader from '../ComponentLoader/BarLoader/BarLoader';
import CardLoader from '../ComponentLoader/CardLoader/CardLoader';

const CategoryContentLoader = ({hideSelector}: {hideSelector?: boolean}) => {
  return (
    <View
      style={Platform.select({
        web: {
          maxWidth: 920,
          width: '100%',
          marginHorizontal: 'auto',
          marginVertical: 0,
          overflow: 'hidden',
        },
      })}>
      {!hideSelector && (
        <View style={categoryLoaderStyles.selector}>
          <BarLoader type="large" />
        </View>
      )}
      <GridView
        data={[{}, {}, {}, {}, {}]}
        renderItem={(item, index?: number | undefined): React.ReactNode => {
          return (
            <CardLoader
              type={index === 0 ? 'big' : 'small'}
              infoSize={index === 0 ? 'medium' : 'small'}
            />
          );
        }}
      />
    </View>
  );
};

export default CategoryContentLoader;

const categoryLoaderStyles = StyleSheet.create({
  selector: {
    marginTop: Metrics.verticalScale(20),
    marginBottom: Metrics.verticalScale(18),
    paddingHorizontal: Metrics.horizontalScale(16),
  },
});
