import React from 'react';
import {View} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import Typography from '../../Typography/Typography';

export type TitleCategorieProps = {
  listCategorie: string[] | string;
};

const TitleCategorie = ({listCategorie}: TitleCategorieProps) => {
  const list: string[] =
    typeof listCategorie === 'string' ? [listCategorie] : listCategorie;
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
      {list.map((categorie, index) => {
        if (index !== listCategorie.length - 1) {
          return (
            <View
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography children={categorie} variant="body2" />
              <View
                style={{
                  marginHorizontal: Metrics.horizontalScale(8),
                  width: Metrics.horizontalScale(4),
                  height: Metrics.horizontalScale(4),
                  borderRadius: Metrics.moderateScale(2),
                  backgroundColor: defaultTheme.typography.color.white,
                }}
              />
            </View>
          );
        }
        return <Typography key={index} children={categorie} variant="body2" />;
      })}
    </View>
  );
};

export default TitleCategorie;
