import CardMessage, {
  CardMessageProps,
} from 'design-system/src/Components/Card/CardMessage/CardMessage.web';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useCallback, useMemo} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  useAllCourseByPartnerQuery,
  useAllOpportunityByPartnerQuery,
  usePartnerByUrlEndQuery,
  usePartnerRelatedContentQuery,
} from '~Api/Graphql/gql/types.generated';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CourseSlider, {
  ICourseSliderItem,
} from '~Umbraco/Components/CourseSlider/CourseSlider';
import {RouteTypename} from '~Navigation/type';
import PartnerSlider, {
  IPartenerSliderItem,
} from '~Umbraco/Components/PartnerSlider/PartnerSlider';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
import AutoScaleImage from 'design-system/src/Components/AutoScaleImage/AutoScaleImage';
import {formatDuration} from '~Hooks/useStringExtension';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import PartnersGrid from '@src/App/components/Common/AppGrid/PartnersGrid/PartnersGrid';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';
import {LearnHomeCard} from 'design-system/src/Components/Card';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';
dayjs.extend(relativeTime);

export interface OpportunityProps extends CardMessageProps {
  id: string;
  __typename: RouteTypename;
  url: string;
}

export default function PartnerSingle() {
  const params = useParams();
  const {navigate} = useAppNavigation();
  const {endUrl} = params || {};
  const {t} = useTranslation(['learn']);
  const response = usePartnerByUrlEndQuery({
    variables: {
      endUrl,
    },
    skip: !endUrl,
  });
  const partner = response.data?.allPartner?.items[0];
  const id = partner?.id;

  const {isMobile} = useBreakpoint();

  const opportunitiesResponse = useAllOpportunityByPartnerQuery({
    variables: {
      id,
    },
    skip: !id,
  });
  const coursesResponse = useAllCourseByPartnerQuery({
    variables: {
      id,
    },
    skip: !id,
  });
  const relatedContent = usePartnerRelatedContentQuery({
    variables: {
      id,
      first: 10,
    },
    skip: !id,
  });

  const {handlePartnerSliderItemClick} = useSectionPageUtils();

  const loading = response.loading;
  const backgroundLoading =
    opportunitiesResponse.loading ||
    coursesResponse.loading ||
    relatedContent.loading;

  const {courses}: {courses: Array<ICourseSliderItem>} = useMemo(() => {
    const {data} = coursesResponse;
    let tempCourses: Array<ICourseSliderItem> = [];
    if (
      data &&
      data.allCourse &&
      data.allCourse.items &&
      data.allCourse.items[0] &&
      data.allCourse.items[0].__typename === 'Course'
    ) {
      data.allCourse.items.map(item => {
        if (item && item.__typename === 'Course') {
          const {title, id, url, linkedPartners, __typename, length} = item;
          const duration = length
            ? formatDuration(length, length < 3600)
            : undefined;
          tempCourses.push({
            cover: {
              uri: item.thumbnail?.cropUrl || item.thumbnail?.url,
            },
            title: title || '',
            duration,
            tag: {
              text: t('course'),
              variant: 'course',
            },
            id,
            url: url || '',
            partenerLogo:
              linkedPartners &&
              linkedPartners[0] &&
              linkedPartners[0].__typename === 'Partner'
                ? linkedPartners[0].logo?.url
                : undefined,
            size: 'small',
            __typename: __typename,
          });
        }
      });
    }
    return {
      courses: tempCourses,
    };
  }, [coursesResponse, navigate]);

  const {dataOpportunity}: {dataOpportunity: OpportunityProps[]} =
    useMemo(() => {
      let opportunities: OpportunityProps[] = [];
      const {data} = opportunitiesResponse;
      if (
        data &&
        data.allOpportunity &&
        data.allOpportunity.items &&
        data.allOpportunity.items[0] &&
        data.allOpportunity.items[0].__typename === 'Opportunity'
      ) {
        data.allOpportunity.items.map(item => {
          if (item) {
            const {
              title,
              shortDescription,
              thumbnail,
              startDate,
              id,
              __typename,
              url,
            } = item;
            opportunities.push({
              title: title || '',
              cover: {
                uri: thumbnail?.url,
              },
              description: shortDescription || '',
              date: dayjs(startDate).fromNow(),
              id: id,
              url: url || '',
              __typename,
            });
          }
        });
      }

      return {dataOpportunity: opportunities};
    }, [opportunitiesResponse]);

  const partners: IPartenerSliderItem[] | undefined = useMemo(() => {
    const {data} = relatedContent;
    if (
      data &&
      data.partner &&
      data.partner.parent &&
      data.partner.parent.__typename === 'Partners' &&
      data.partner.parent.children
    ) {
      let list: Array<IPartenerSliderItem> = [];
      data.partner.parent.children.items.forEach(item => {
        if (item && item.__typename === 'Partner') {
          const {logo, id, url, __typename} = item;
          list.push({
            id,
            url: url || '',
            __typename,
            partnerLogo: logo && logo.url ? logo.url : '',
            accessData: {
              accessType: undefined,
              paywall: undefined,
            },
          });
        }
      });
      return list;
    }
  }, [relatedContent]);

  const {
    title,
    description,
    shortDescription,
    partnerUrl,
    partnerName,
    logoUrl,
    cover,
  }: {
    title?: string;
    description?: string;
    partnerUrl?: string;
    partnerName?: string;
    logoUrl?: string;
    cover?: string;
    shortDescription?: string;
  } = useMemo(() => {
    if (partner) {
      const {
        title,
        shortDescription,
        partnerUrl,
        partnerName,
        logo,
        cover,
        description,
      } = partner;
      return {
        title,
        shortDescription,
        partnerUrl,
        partnerName,
        logoUrl: logo?.url,
        cover: cover?.url,
        description,
      };
    }
    return {};
  }, [response]);

  const handlePressUrl = useCallback(
    (url: string) => {
      new RouteFactory(
        {
          url,
          title: '',
          __typename: 'ExternalLink',
          id: '',
        },
        navigate,
      ).navigate();
    },
    [navigate],
  );

  return (
    <div style={{width: '100%', height: '100%'}}>
      <DebugUmbracoId umbracoId={id} position="page" />
      {backgroundLoading && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,0.5)',
          }}>
          <CustomActivityIndicator
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </div>
      )}
      {loading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CustomActivityIndicator
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </div>
      ) : (
        <>
          <ServiceHeader
            image={{
              uri: cover,
            }}
            title={title || ''}
            description={shortDescription || ''}
            bottomExtra={
              <TouchableOpacity
                onPress={() => {
                  if (partnerUrl) handlePressUrl(partnerUrl);
                }}>
                <Typography
                  variant="body3"
                  color="gray"
                  style={{textAlign: 'center'}}>
                  {partnerUrl}
                </Typography>
              </TouchableOpacity>
            }
            upperExtra={
              <AutoScaleImage
                uri={logoUrl || ''}
                resizeMode="contain"
                initWidth={70}
                initHeight={16}
                backgroundFallbackColor="transparent"
              />
            }
          />
          <View
            style={{
              marginTop: Metrics.verticalScale(45),
            }}>
            <View
              style={{
                paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
              }}>
              <Typography
                variant="h3"
                style={{marginBottom: Metrics.verticalScale(13)}}>
                {t('About')}
              </Typography>
              <Typography variant="body2" color="gray">
                {description}
              </Typography>
            </View>
            {dataOpportunity.length !== 0 && (
              <View
                style={{
                  marginTop: Metrics.verticalScale(49),
                  paddingHorizontal: Metrics.horizontalScale(
                    isMobile ? 16 : 60,
                  ),
                }}>
                <Typography
                  variant="h3"
                  style={{marginBottom: Metrics.verticalScale(4)}}>
                  {t('Opportunities')}
                </Typography>
                <AppGrid
                  nbColumns={isMobile ? 1 : 2}
                  columnGap={isMobile ? 0 : 13}
                  rowGap={15}>
                  {dataOpportunity.map((d, index) => (
                    <CardMessage
                      flexDirection="row"
                      onPress={() => {
                        navigate(AppRoutesEnum.OPPORTUNITY_SINGLE, {
                          state: {
                            params: {
                              id: d?.id || '',
                              type: 'url',
                              url: d?.url || '',
                              __typename: 'Opportunity',
                            },
                          },
                        });
                      }}
                      {...d}
                      key={`ps-${index}`}
                      coverType="square"
                    />
                  ))}
                </AppGrid>
              </View>
            )}
            {courses.length !== 0 && (
              <View style={{marginTop: Metrics.verticalScale(30)}}>
                {isMobile ? (
                  <CourseSlider
                    data={courses}
                    title={t('Course support')}
                    onItemPress={item => {
                      navigate(AppRoutesEnum.COURSE_SINGLE, {
                        state: {
                          params: {
                            id: item?.id || '',
                            type: 'url',
                            url: item?.url || '',
                            __typename: 'Course',
                          },
                        },
                      });
                    }}
                  />
                ) : (
                  <div
                    style={{
                      padding: isMobile ? '0 16px' : '0 60px',
                    }}>
                    <AppGrid
                      nbColumns={4}
                      columnGap={12}
                      rowGap={48}
                      title={t('Course support')}>
                      {courses.map(item => (
                        <div
                          key={item.id}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                          }}>
                          <LearnHomeCard
                            onPress={() => {
                              navigate(AppRoutesEnum.COURSE_SINGLE, {
                                state: {
                                  params: {
                                    id: item?.id || '',
                                    type: 'url',
                                    url: item?.url || '',
                                    __typename: 'Course',
                                  },
                                },
                              });
                            }}
                            {...item}
                            fullWidth={true}
                            aspectRatio={247 / 376}
                          />
                        </div>
                      ))}
                    </AppGrid>
                  </div>
                )}
              </View>
            )}
            {partners &&
              partners.length !== 0 &&
              (isMobile ? (
                <View
                  style={{
                    marginTop: Metrics.verticalScale(30),
                  }}>
                  <PartnerSlider
                    title={t('Search by partner') || ''}
                    data={partners}
                    onItemPress={handlePartnerSliderItemClick}
                    // isLink={true}
                    // seeMore={{
                    //   label: t('See all'),
                    //   onPress: () => {
                    //     navigate(AppRoutesEnum.PARTNERS_LANDING, {
                    //       state: {
                    //         params: {
                    //           id: '',
                    //           url: '',
                    //           __typename: 'PartnersPage',
                    //         },
                    //       },
                    //     });
                    //   },
                    // }}
                  />
                </View>
              ) : (
                <View
                  style={{
                    marginTop: Metrics.verticalScale(30),
                    paddingBottom: Metrics.verticalScale(20),
                    paddingHorizontal: Metrics.horizontalScale(
                      isMobile ? 16 : 60,
                    ),
                  }}>
                  <PartnersGrid
                    title={t('Search by partner') || ''}
                    data={partners}
                    nbColumns={4}
                  />
                </View>
              ))}
          </View>
        </>
      )}
    </div>
  );
}
