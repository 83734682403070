import React from 'react';
import {View} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {usePromoCodeContent} from '@src/Navigation/PromoCodePage/usePromoCodeContent';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Typography from 'design-system/src/Components/Typography/Typography';
import HighlightedTypography from '@src/Umbraco/Components/PlayerPaywall/HighlightedTypography/HighlightedTypography';
import Button from 'design-system/src/Components/Button/Button';

const VariantPromoCodeSuccess = () => {
  const {isMobile} = useBreakpoint();
  const navigation = useAppNavigation();
  const promoCodeContent = usePromoCodeContent();

  const {thankYouVariant} = promoCodeContent;

  const {
    variantTitle: title,
    variantHighlightedTitle: highlightedTitle,
    variantDescription: body,
    variantCover,
    variantCoverMobile,
    variantCtaLabel: labelCta,
    variantSurtitle: surtitle,
    variantHighlightedMention: highlightedMention,
    variantMention: mention,
  } = thankYouVariant || {};

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        aspectRatio: isMobile ? undefined : 16 / 9,
        height: isMobile ? '100vh' : undefined,
      }}>
      <View
        style={{
          flex: 1,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            maxWidth: '100%',
            // position: 'relative',
            borderRadius: isMobile ? 0 : Metrics.moderateScale(16),
            overflow: isMobile ? 'scroll' : 'hidden',
            paddingHorizontal: isMobile ? 16 : undefined,
          }}>
          {/*  background image */}
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
            }}>
            <CustomFastImage
              source={{uri: isMobile ? variantCoverMobile : variantCover}}
              resizeMode="cover"
              width={'100%'}
              height={'100%'}
              wrapperStyles={{
                flex: 1,
              }}
            />
          </View>

          {/* gradient background */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: '100%',
              width: '100%',

              // borderRadius: 8,
              // border: '1px solid #000',
              background: `radial-gradient(115.28% 90.25% at 95.73% 7.12%, rgba(17, 18, 22, 0.40) 0%, #111216 100%) no-repeat`,
            }}></div>
          {/* <LinearGradient
            style={{
              position: 'absolute',
              // top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              height: '100%',
              opacity: 0.8,
            }}
            colors={[
              'rgba(17, 18, 22, 0)',
              'rgba(17, 18, 22, 0.4)',
              'rgba(17, 18, 22, 0.7)',
              '#111216',
            ]}
            locations={[0, 0.2745, 0.5745, 1]}
            useAngle
            angle={180}></LinearGradient> */}

          {/* content */}
          <View
            style={{
              width: '100%',
              zIndex: 1,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: '100%',
            }}>
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                width: isMobile ? '100%' : '50%',
                height: '100%',
                marginHorizontal: isMobile ? 0 : 'auto',
                // overflow: 'auto',
                paddingTop: isMobile ? 259 : Metrics.verticalScale(116),
                paddingBottom: isMobile ? 36 : 57,
                paddingHorizontal: isMobile ? 16 : 0,
              }}>
              <View
                style={{
                  width: '100%',
                  maxWidth: isMobile ? '100%' : '70%',
                  marginBottom: 24,
                }}>
                <Typography
                  variant={isMobile ? 'h1' : 'formTitle'}
                  color="orange"
                  style={{
                    textAlign: 'center',
                  }}>
                  {surtitle}
                </Typography>
                <HighlightedTypography
                  variant={isMobile ? 'h1' : 'formTitle'}
                  highlightedText={highlightedTitle}
                  style={{
                    textAlign: 'center',
                  }}
                  highlightedStyle={{
                    textAlign: 'center',
                  }}
                  children={title || ''}
                />
              </View>
              <Typography
                variant="body2"
                style={{
                  marginBottom: isMobile ? 24 : 42,
                  textAlign: 'center',
                }}>
                {body}
              </Typography>

              <View
                style={{
                  marginBottom: 48,
                }}>
                <Button
                  title={labelCta || ''}
                  variant="contained"
                  onPress={() => {
                    navigation.navigate(AppRoutesEnum.HOME);
                  }}
                  size={isMobile ? 'small' : 'medium'}
                />
              </View>

              <Typography
                variant="body2"
                color="orange"
                style={{
                  marginBottom: 4,
                }}>
                {highlightedMention}
              </Typography>

              <Typography
                variant="body3"
                style={{
                  opacity: 0.5,
                }}>
                {mention}
              </Typography>
            </View>
          </View>
        </View>
      </View>
    </div>
  );
};

export default VariantPromoCodeSuccess;
