import React, {useMemo, useEffect, useState} from 'react';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import {useFindProgression} from '@src/Hooks/useFindProgression';
import {useLiveEventProgression} from '@src/Hooks/useLiveEventProgression';
import {RouteTypename} from '~Navigation/type';
import mapMainContent from '~Umbraco/Utils/content/mapMainContent';
import ContentSlider, {
  IContentSliderItem,
  IContentSliderProps,
} from '../ContentSlider/ContentSlider';
import usePaywal from '~Hooks/usePaywal';
// import {useGetLearnProgression} from '@src/Screen/AppScreen/LearnScreen/Hooks/useGetLearnProgression';
// import CourseSlider, {ICourseSliderItem} from '../CourseSlider/CourseSlider';
import CarouselLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/CarouselLoader';
import {useGetMainContentListByIdLazyQuery} from '~Api/Graphql/gql/types.generated';
import {WatchedItem} from '~Store/Slices/Member.slice';
import moment from 'moment';
import useMemberWatched from '@src/Hooks/useMemberWatched';

export enum EContextualContentSliderType {
  CONTINUE_WATCHING = 'Continue watching',
}

export type ContentSource = 'listen' | 'learn' | 'watch';
type ICourseSliderItem = any;

interface IContextualContentSliderProps
  extends Omit<IContentSliderProps, 'data'> {
  type?: EContextualContentSliderType;
  contentSource: ContentSource;
  handleClickCourseSlideItem?: (item: ICourseSliderItem) => void;
}

const ContextualContentSlider = (props: IContextualContentSliderProps) => {
  const {type, contentSource, ...rest} = props;
  const continueWatchingTypenames: RouteTypename[] = useMemo(
    () =>
      contentSource === 'listen' ? ['PodcastItem'] : ['Collection', 'VODItem'],
    [contentSource],
  );

  const {data, fetchWatched} = useMemberWatched();
  const {findProgression} = useFindProgression();
  const {calculateProgression} = useLiveEventProgression();
  const {canSeeContent} = usePaywal();
  // TODO: get learn progression
  // const {handleGetProgression, loading} = useGetLearnProgression();
  const [coursesSlider, setCoursesSlider] = useState<ICourseSliderItem[]>([]);
  const [fetchContent, {data: contentData}] =
    useGetMainContentListByIdLazyQuery();

  useEffect(() => {
    if (contentSource === 'learn') {
      // handleGetProgression().then((value: ICourseSliderItem[]) => {
      //   setCoursesSlider(value);
      // });
    } else {
      fetchWatched();
    }
  }, [fetchWatched, contentSource]);

  const dataList: Record<string, WatchedItem> = useMemo(() => {
    let _list: Record<string, WatchedItem> = {};
    if (data && type === EContextualContentSliderType.CONTINUE_WATCHING) {
      _list = {...data};
      // data.forEach(current => {
      //   const itemId = current.contentId;
      //   // const isExist = _list[itemId];

      //   // if (isExist) {
      //   //   if (
      //   //     _list[itemId].watchedTimestamp &&
      //   //     current.watchedTimestamp &&
      //   //     current.watchedTimestamp > _list[itemId].watchedTimestamp!
      //   //   ) {
      //   //     _list[itemId] = current;
      //   //   }
      //   // } else {
      //   // }
      //   _list[itemId] = current;
      // });
    }
    return _list;
  }, [data, type]);

  useEffect(() => {
    const ids = Object.keys(dataList);
    if (ids.length) {
      fetchContent({
        variables: {
          id: ids,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [dataList, fetchContent, data, type]);

  const list: IContentSliderItem[] = useMemo(() => {
    const _list: IContentSliderItem[] = [];
    if (
      contentData &&
      contentData.allContent &&
      contentData.allContent.items &&
      type === EContextualContentSliderType.CONTINUE_WATCHING
    ) {
      contentData.allContent.items.forEach(item => {
        if (item && item.__typename && !isElementRestricted(item)) {
          const findData = dataList[item?.id];
          if (findData && continueWatchingTypenames.includes(item.__typename)) {
            const {time: watchedTimestamp, length} = findData;
            if (watchedTimestamp && length && watchedTimestamp < length) {
              const _rest = length - watchedTimestamp / 1000;
              const element = mapMainContent(
                item,
                findProgression,
                canSeeContent,
                calculateProgression,
              );
              if (element && element.progress && _rest > 3) {
                let findIndex = _list.find(e => e.id === element.id);
                if (!findIndex) {
                  if (!element.metadata) {
                    element.metadata = {} as any;
                  }
                  element.metadata = {
                    ...element.metadata,
                    progressionUpdatedAt:
                      findData.updatedAt ||
                      findData.metadata?._updateDate ||
                      new Date().toISOString(),
                  };
                  _list.push(element);
                }
              }
            }
          }
        }
      });
    }
    return _list.sort((a, b) => {
      const aP = moment(
        a.metadata?.progressionUpdatedAt || new Date().toISOString(),
      ).unix();
      const bP = moment(
        b.metadata?.progressionUpdatedAt || new Date().toISOString(),
      ).unix();
      const diff = aP - bP;
      if (diff > 0) {
        return -1;
      } else if (diff < 0) {
        return 1;
      }
      return 0;
    });
  }, [
    contentData,
    type,
    dataList,
    continueWatchingTypenames,
    findProgression,
    canSeeContent,
    calculateProgression,
  ]);

  if (contentSource === 'learn') {
    // const {title, handleClickCourseSlideItem} = rest;
    // if (loading) {
    //   return <CarouselLoader />;
    // } else if (coursesSlider.length) {
    //   return (
    //     <CourseSlider
    //       data={coursesSlider}
    //       title={title || ''}
    //       onItemPress={handleClickCourseSlideItem}
    //       size={'small'}
    //     />
    //   );
    // }
  }
  if (!list.length) {
    return null;
  }

  return <ContentSlider data={list} {...rest} />;
};

export default ContextualContentSlider;
