import React from 'react';
import {Pressable, StyleSheet, ViewStyle} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import {CardSize, selectSize} from '../../../tools/CardSizeTools';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import AutoScaleImage from '../../AutoScaleImage/AutoScaleImage';
import usePressableHover from '../../PressableCustom/usePressableHover';

export interface PartnerCardProps {
  size?: CardSize;
  partenerLogo?: CustomFastImageProps['source'];
  activeHover?: boolean;
  onPress?: () => void;
}

const hoverStyle: ViewStyle = {
  transform: [{scale: 1.2}],
  zIndex: 8,
  backgroundColor: defaultTheme.page.background,
  // @ts-ignore
  transition: 'transform 0.2s',
  // boxShadow: `0 0 0px 4px rgba(17,18,22,1)`,
  boxSizing: 'content-box',
  border: `4px solid ${defaultTheme.page.background}`,
  borderRadius: 8,
  position: 'absolute',
};

const PartnerCard = (props: PartnerCardProps) => {
  const {size, partenerLogo, activeHover, onPress} = props;

  const cardSize = selectSize({type: size ?? 'xsmall'});
  const width: number = Metrics.horizontalScale(cardSize.width);
  const height: number = Metrics.verticalScale(cardSize.height);

  const {hover, onHoverIn, onHoverOut} = usePressableHover();

  const renderPartnerCard = (isBase?: boolean): React.ReactNode => {
    return (
      <>
        <Pressable
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          style={StyleSheet.flatten([
            styles.container,
            {
              width,
              height,
              backgroundColor: 'transparent',
              ...((activeHover && hover && !isBase && hoverStyle) || {}),
            },
          ])}
          onPress={onPress}>
          <AutoScaleImage
            resizeMode="contain"
            initWidth={60}
            initHeight={16}
            uri={
              typeof partenerLogo === 'object' && partenerLogo.uri
                ? partenerLogo.uri
                : ''
            }
            backgroundFallbackColor={'transparent'}
            style={{
              borderRadius: Metrics.moderateScale(4),
              position: 'absolute',
            }}
          />
        </Pressable>
        {activeHover && hover && !isBase && renderPartnerCard(true)}
      </>
    );
  };

  return <>{renderPartnerCard()}</>;
};

export default PartnerCard;

const styles = StyleSheet.create({
  container: {
    backgroundColor: defaultTheme.primaryNavBar,
    borderRadius: Metrics.moderateScale(4),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
