import dayjs from 'dayjs';

export const useLiveEventProgression = () => {
  const calculateProgression = (startDate: any, endDate: any) => {
    const duration: number =
      startDate && endDate ? Math.abs(dayjs(startDate).diff(endDate)) : 0;
    const progressionDuration: number = startDate
      ? Math.abs(dayjs().diff(endDate))
      : 0;
    return Math.max(0, Math.min(progressionDuration / duration, 1));
  };

  return {calculateProgression};
};
