import React from 'react';
import {View, Pressable} from 'react-native';
import Metrics from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

type PressableProps = React.ComponentProps<typeof Pressable>;

export interface ProfileLinkProps extends PressableProps {
  title: string;
  withBorderTop?: boolean;
}

export default function ProfileLink(props: ProfileLinkProps) {
  const {title, withBorderTop, ...rest} = props;
  const {isMobile} = useBreakpoint();

  return (
    <View
      style={{
        borderTopWidth: withBorderTop ? 1 : 0,
        borderTopColor: isMobile ? 'rgba(115, 115, 115, 0.5)' : 'transparent',
        borderBottomWidth: 1,
        borderBottomColor: isMobile
          ? 'rgba(115, 115, 115, 0.5)'
          : 'transparent',
      }}>
      <Pressable
        style={{
          paddingBottom: Metrics.horizontalScale(19),
          paddingTop: Metrics.horizontalScale(16),
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        {...rest}>
        <Typography variant="body2">{title}</Typography>
        <Icon name="arrow-right" size={'16'} />
      </Pressable>
    </View>
  );
}
