import useDefaultPaywall from '@src/Store/selectors/useDefaultPaywall';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React from 'react';
import {View} from 'react-native';
import PaywallSectionPage from '~Umbraco/Pages/PaywallSectionPage/PaywallSectionPage';

const PlayerPaywallChoosePlan = (props: {paywallId?: string}) => {
  const {paywallId: paywallIdProps} = props;
  const defaultPaywall = useDefaultPaywall();
  const paywallId = paywallIdProps || defaultPaywall?.id;
  const {isMobile} = useBreakpoint();
  return (
    <View style={{width: '100%', height: '100%'}}>
      <PaywallSectionPage
        orientation={isMobile ? 'portrait' : 'landscape'}
        variant="choosePlan"
        id={paywallId}
      />
    </View>
  );
};

export default PlayerPaywallChoosePlan;
