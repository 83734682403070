import React from 'react';
import {View} from 'react-native';
import {FormFieldset} from '~Api/Umbraco/interface/UmbracoTypes';
import UmbracoFormColumn from './UmbracoFormColumn/UmbracoFormColumn';

interface UmbracoFieldset {
  fieldset: FormFieldset;
  index: number;
}

const UmbracoFieldset = (props: UmbracoFieldset) => {
  const {
    fieldset: {columns},
    index,
  } = props;
  const total = columns.length;

  return (
    <View style={{position: 'relative', zIndex: index}}>
      {columns.map((column, index) => {
        return (
          <UmbracoFormColumn
            column={column}
            key={index}
            index={total - index}
          />
        );
      })}
    </View>
  );
};

export default React.memo(UmbracoFieldset);
