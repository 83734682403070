import BottomTabNavigationItem from 'design-system/src/Components/Navigation/BottomTabNavigationItem/BottomTabNavigationItem';
import {darkTheme} from 'design-system/src/styles/Themes';
import React from 'react';
import {appMenuItems} from '../AppHeader/AppHeader';
import {IconName} from 'design-system/src/assets/Icones';
import useRouteActive from '../AppHeader/useRouteActive';
import {useAppSelector} from '@src/Store/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const bottomBarHeight = 92;

const AppBottomBar = () => {
  const {isRouteActive} = useRouteActive();
  const {t} = useTranslation('menu');
  const show = useAppSelector(state => state.tabBar.mount);
  // const show = true;

  return (
    <div style={{height: bottomBarHeight, display: !show ? 'none' : undefined}}>
      <div
        style={{
          height: bottomBarHeight,
          display: 'inline-flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          position: 'fixed',
          zIndex: 9,
          bottom: 0,
          width: '100%',
          backgroundColor: darkTheme.colors.secondaryBlack,
          paddingTop: 14,
        }}>
        {appMenuItems.map((item, index) => (
          <BottomTabNavigationItem
            key={index}
            icon={item.icon as IconName}
            /* i18next-extract-disable-next-line */
            title={t(item.label) || ''}
            onPress={item.onClick}
            isActive={isRouteActive(item.route)}
          />
        ))}
      </div>
    </div>
  );
};

export default AppBottomBar;
