import {StyleSheet} from 'react-native';
import Metrics from '../../../../Theme/Metrics';

export const learnSingleCardStyles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: Metrics.horizontalScale(8),
    borderRadius: Metrics.moderateScale(4),
  },
  title: {
    marginBottom: Metrics.verticalScale(5),
  },
  subtitle: {
    marginBottom: Metrics.verticalScale(21),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  radius: {
    borderRadius: Metrics.moderateScale(4),
  },
});
