import React from 'react';
import {View} from 'react-native';
import Typography from '../Typography/Typography';

export type StatisticProps = {
  value: number | string;
  label: string;
};

export default function Statistic(props: StatisticProps) {
  const {value, label} = props;

  return (
    <View>
      <Typography style={{fontSize: 48, lineHeight: 48}}>{value}</Typography>
      <Typography variant="body3" color={'gray'} style={{marginTop: 7}}>
        {label}
      </Typography>
    </View>
  );
}
