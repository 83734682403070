import React, {CSSProperties, useCallback, useMemo} from 'react';
import {
  // StyleProp,
  View,
  // ViewStyle,
  // Alert,
  // Platform,
  // Pressable,
} from 'react-native';
import {FormField} from '~Api/Umbraco/interface/UmbracoTypes';
import {UseControllerProps, useFormContext} from 'react-hook-form';
// import FilePickerController, { IFileImported, } from 'design-system/src/Components/Form/FilePickerController/FilePickerController';
import Metrics from 'design-system/src/Theme/Metrics';

import {ISelectOption} from 'design-system/src/Components/Form/Select/Select';

import {useUmbracoFormContext} from '~Umbraco/Components/UmbracoForm/UmbracoFormContext';
import Typography from 'design-system/src/Components/Typography/Typography';
import {useSelector} from '@legendapp/state/react';
// import useImagePicker from '~Hooks/useImagePicker';
// import DocumentPicker from 'react-native-document-picker';
// import {Asset, ImagePickerResponse} from 'react-native-image-picker';
// import {useUploadFile} from '~Hooks/useUploadFile';
// import apiConfig from '~Configs/apiConfig';
// import {useLanguages} from '~Store/Slices/LanguageSlice/Language.selector';
// import PasswordController from 'design-system/src/Components/Form/PasswordController/PasswordController';
// import {usePasswordValidation} from '~Hooks/usePasswordValidation';
import {TextInputLoader} from 'design-system/src/Components/ContentLoader';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import apiConfig from '@src/Configs/apiConfig';
import UmbracoFormFieldSuspense from './UmbracoFormFieldSuspense';
import {Pressable} from 'react-native';
import {useAppDispatch} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import ModalTermAndCondition from '@src/App/components/ModalAuthentication/ModalTermAndCondition/ModalTermAndCondition';

const TextInputController = React.lazy(
  () =>
    import(
      'design-system/src/Components/Form/TextInputController/TextInputController'
    ),
);
const PhoneNumberPickerController = React.lazy(
  () =>
    import(
      'design-system/src/Components/Form/PhoneNumberPicker/PhoneNumberPickerController'
    ),
);
const SelectController = React.lazy(
  () => import('design-system/src/Components/Form/Select/SelectController.web'),
);
const DatePickerController = React.lazy(
  () =>
    import(
      'design-system/src/Components/Form/DatePicker/DatePickerController.web'
    ),
);
const CheckBoxController = React.lazy(
  () =>
    import(
      'design-system/src/Components/Form/CheckboxController/CheckboxController'
    ),
);
const ServiceFormDesc = React.lazy(
  () => import('design-system/src/Components/ServiceForm/ServiceFormDesc'),
);
const LogoCheckBoxList = React.lazy(
  () => import('~Umbraco/Components/Form/LogoCheckBoxList/LogoCheckBoxList'),
);
const ServiceCheckBoxList = React.lazy(
  () =>
    import('~Umbraco/Components/Form/ServiceCheckBoxList/ServiceCheckBoxList'),
);
const UmbracoRecaptchaField = React.lazy(
  () =>
    import(
      '@src/Umbraco/Components/Form/UmbracoRecaptchaField/UmbracoRecaptchaField'
    ),
);
const CheckboxGroupController = React.lazy(
  () =>
    import(
      'design-system/src/Components/Form/CheckboxGroup/CheckboxGroupController'
    ),
);
const CheckBoxGroupListMapper = React.lazy(
  () =>
    import(
      '~Umbraco/Components/Form/CheckBoxGroupListMapper/CheckBoxGroupListMapper'
    ),
);

const LanguageSelector = React.lazy(
  () => import('./LanguageSelector/LanguageSelector'),
);
const RegionSelector = React.lazy(
  () => import('./RegionSelector/RegionSelector'),
);
const PasswordField = React.lazy(() => import('./PasswordField/PasswordField'));

interface IUmbracoFormFieldProps {
  field: FormField;
  style?: CSSProperties;
  index: number;
}
const separator = '~|~';

const UmbracoFormField = (props: IUmbracoFormFieldProps) => {
  const {field, style, index} = props;
  const {control} = useFormContext();
  const {t} = useTranslation('form');
  const dispatch = useAppDispatch();

  // const {rules: passwordRules, loading: passwordLoading} = usePasswordValidation();
  // const {regionOptions, loading: regionLoading} = useRegions();
  // const navigation = useNavigation();
  // const languages = useLanguages();
  const {
    translationContext = 'default-caption',
    errors,
    fieldFilter,
    fieldMap,
  } = useUmbracoFormContext();
  const dynamicTranslation = useTranslation(translationContext);
  const allError = useSelector(() => errors && errors.get());
  // const {actionSheetSelectImages} = useImagePicker();
  // const {uploadFile} = useUploadFile();
  // const [uploadImageLoading, setUploadImLoading] = useState(false);

  const loading = false;
  // const loading = false;
  const mapPreValues = useCallback(
    (list: string[]): ISelectOption[] =>
      list.map<ISelectOption>(item => {
        const [value, label] = item.split(separator);
        return {
          label: dynamicTranslation.t(label) || value,
          value: label || value,
        };
      }),
    [dynamicTranslation],
  );
  const mapPreValuesObject = useCallback(
    (list: object): ISelectOption[] =>
      Object.entries(list).map<ISelectOption>(([value, label]) => {
        return {label: dynamicTranslation.t(label || value), value: label};
      }),
    [dynamicTranslation],
  );

  const renderedField = useMemo(() => {
    const {
      type,
      alias,
      caption,
      required,
      requiredErrorMessage,
      helpText,
      settings,
      preValues,
      defaultRules,
    } = fieldMap ? fieldMap(field) : field;

    let placeholder: string = '';
    const rules: UseControllerProps['rules'] = defaultRules || {};
    let isPhone = false;
    let acceptCopy: string = '';
    let label: string = caption;

    if (fieldFilter) {
      if (!fieldFilter(alias)) {
        return null;
      }
    }

    if (required) {
      rules.required = dynamicTranslation.t(requiredErrorMessage || '') || true;
      label += ' *';
    }

    if (settings) {
      if (settings.pattern) {
        rules.pattern = {
          value: new RegExp(settings.pattern),
          message: dynamicTranslation.t(settings.patternInvalidErrorMessage),
        };
      } else if (settings.fieldType === 'email') {
        rules.pattern = {
          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
          message: t('Email not valid'),
        };
      }
      if (settings.placeholder) {
        placeholder = dynamicTranslation.t(settings.placeholder);
      }
      if (settings.fieldType === 'tel') {
        isPhone = true;
      }
      if (settings.acceptCopy) {
        acceptCopy = dynamicTranslation.t(settings.acceptCopy);
        if (required) {
          acceptCopy += ' *';
        }
      }
    }

    switch (type) {
      case 'titleAndDescription':
        if (settings) {
          return (
            <ServiceFormDesc
              title={dynamicTranslation.t(settings.caption)}
              description={dynamicTranslation.t(settings.bodyText) || ''}
            />
          );
        }
        break;
      case 'checkboxList':
        if (preValues) {
          let ids = Object.keys(preValues);
          let allID = Object.values(preValues);
          if (alias === 'chooseMedia') {
            // TODO => lang
            return (
              <LogoCheckBoxList
                control={control}
                ids={ids}
                title={label}
                name={alias}
                rules={rules}
                allID={allID}
              />
            );
          } else if (alias === 'chooseServices') {
            return (
              <ServiceCheckBoxList
                ids={ids}
                name={alias}
                rules={rules}
                allID={allID}
                control={control}
              />
            );
          } else {
            if (Array.isArray(preValues)) {
              return (
                <CheckboxGroupController
                  options={mapPreValues(preValues)}
                  name={alias}
                  rules={rules}
                  control={control}
                />
              );
            }
            return (
              <CheckBoxGroupListMapper
                preValues={preValues || {}}
                name={alias}
                rules={rules}
                control={control}
              />
            );
          }
        }

        break;
      case 'textarea':
        if (alias === 'uploadFile') {
          // TODO: uploadfile
          return null;
          // return (
          //   <FilePickerController
          //     name={alias}
          //     control={control}
          //     label={label}
          //     rules={rules}
          //     placeholder={placeholder}
          //     helperText={helpText}
          //     pickerText={t('Choose File') + ''}
          //     rulesText={t('Accepted file format') + ':' + t(' .wav')}
          //     prefixIcon="link"
          //     onPickFile={async (onUploaded: Function) => {
          //       DocumentPicker.pick({
          //         allowMultiSelection: true,
          //         type: [Platform.OS === 'ios' ? 'public.audio' : 'audio/*'],
          //       })
          //         .then(async (result: any) => {
          //           if (result !== undefined) {
          //             let fileResponse: IFileImported[] = result?.map(
          //               (asset: any) => {
          //                 console.log('asset =>', asset);
          //                 const file: IFileImported = {
          //                   name: asset.name ?? '',
          //                   type: asset.type ?? '',
          //                   uri: asset.uri
          //                     ? Platform.OS === 'ios'
          //                       ? asset.uri?.replace('file://', '')
          //                       : asset.uri
          //                     : '',
          //                 };
          //                 return file;
          //               },
          //             );
          //             console.log(
          //               'fileResponse[0].type',
          //               fileResponse[0].name?.split('.'),
          //             );
          //             if (fileResponse[0] && fileResponse[0].name) {
          //               let nameArr = fileResponse[0].name.split('.');
          //               if (
          //                 nameArr.length > 0 &&
          //                 nameArr[nameArr.length - 1].toLowerCase() === 'wav'
          //               ) {
          //                 let res = await uploadFile(fileResponse);
          //                 onUploaded(
          //                   res && res.data
          //                     ? apiConfig.umbBoUrl + `${res.data.umbracoFile}`
          //                     : '',
          //                 );
          //               } else {
          //                 Alert.alert('Invalid File');
          //                 onUploaded();
          //               }
          //             } else {
          //               onUploaded();
          //             }
          //           }
          //         })
          //         .catch((error: any) => {
          //           if (!DocumentPicker.isCancel(error)) {
          //             console.log('document file error :', error);
          //           }
          //           onUploaded();
          //         });
          //     }}
          //   />
          // );
        } else if (alias === 'uploadImage') {
          // TODO: upload image
          return null;
          // return (
          //   <FilePickerController
          //     name={alias}
          //     control={control}
          //     label={label}
          //     rules={rules}
          //     placeholder={placeholder}
          //     helperText={helpText}
          //     pickerText={t('Choose File') + ''}
          //     isLoading={uploadImageLoading}
          //     rulesText={
          //       t(
          //         'Files must be less than 5MB. Accepted file format{{separator}} png, jpg',
          //         {separator: ':'},
          //       ) + ''
          //     }
          //     prefixIcon="picture"
          //     onPickFile={async (onUploaded: Function) => {
          //       const callbackFunction = async function (
          //         result: ImagePickerResponse,
          //       ) {
          //         if (result.assets !== undefined) {
          //           if (
          //             result.assets[0].fileSize !== null &&
          //             result.assets[0].fileSize !== undefined
          //           ) {
          //             const fileSizeMegaBite =
          //               result.assets[0].fileSize * Math.pow(10, -6);
          //             if (Math.round(fileSizeMegaBite) > 5) {
          //               Alert.alert('Warning', 'File size not accepted');
          //             } else {
          //               let fileResponse: IFileImported[] = result.assets?.map(
          //                 (asset: Asset) => {
          //                   const file: IFileImported = {
          //                     name: asset.fileName ?? '',
          //                     type: asset.type ?? '',
          //                     uri: asset.uri
          //                       ? Platform.OS === 'ios'
          //                         ? asset.uri?.replace('file://', '')
          //                         : asset.uri
          //                       : '',
          //                     base64: asset.base64,
          //                     asset: asset,
          //                   };
          //                   return file;
          //                 },
          //               );
          //               setUploadImLoading(true);
          //               let res = await uploadFile(fileResponse, true);
          //               onUploaded(
          //                 res && res.data
          //                   ? apiConfig.umbBoUrl + `${res.data.umbracoFile.src}`
          //                   : '',
          //               );
          //               setUploadImLoading(false);
          //             }
          //           }
          //         }
          //       };
          //       actionSheetSelectImages(callbackFunction);
          //     }}
          //   />
          // );
        } else {
          return (
            <TextInputController
              name={alias}
              control={control}
              label={label}
              rules={rules}
              placeholder={placeholder}
              helperText={helpText}
              multiline={true}
            />
          );
        }
      case 'hidden':
        return <input name={alias} type="hidden" />;
      case 'password':
        return (
          <PasswordField
            name={alias}
            control={control}
            label={label}
            placeholder={placeholder}
            secureTextEntry
            helperText={helpText}
          />
        );
      case 'select':
        if (alias === 'region') {
          return (
            <RegionSelector
              name={alias}
              control={control}
              label={label}
              rules={rules}
            />
          );
        } else if (alias === 'language') {
          return (
            <LanguageSelector
              name={alias}
              control={control}
              label={label}
              rules={rules}
            />
          );
        }
        return (
          <SelectController
            name={alias}
            control={control}
            label={label}
            rules={rules}
            options={
              Array.isArray(preValues)
                ? mapPreValues(preValues)
                : mapPreValuesObject(
                    typeof preValues === 'object' ? preValues : {},
                  )
            }
          />
        );
      case 'radio':
        return (
          <SelectController
            name={alias}
            control={control}
            label={label}
            rules={rules}
            options={mapPreValues(preValues || [])}
          />
        );
      case 'date':
        // TODO: datepicker
        return (
          <DatePickerController
            name={alias}
            control={control}
            label={`${label} ${t('date.format.label', '(YYYY-MM-DD)')}`}
            defaultDate={new Date('2000-01-01')}
            dateFormat={t('YYYY-MM-DD', 'YYYY-MM-DD') || 'YYYY-MM-DD'}
            rules={rules}
          />
        );
      case 'dataConsent':
      case 'checkbox':
        return (
          <>
            <CheckBoxController
              name={alias}
              control={control}
              label={acceptCopy || caption}
              rules={rules}
              variant="orange"
              style={
                type === 'dataConsent' && {marginTop: Metrics.verticalScale(20)}
              }
            />
            {alias === 'termsConsent' && (
              <>
                <Pressable
                  style={{
                    height: Metrics.horizontalScale(48),
                    // paddingLeft: Metrics.horizontalScale(28),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    dispatch(
                      appAuthSlice.actions.setOpenTermsAndConditionModal(true),
                    );
                  }}>
                  <Typography variant="body3" color="gray">
                    {t('Terms and conditions')}
                  </Typography>
                </Pressable>
                <ModalTermAndCondition />
              </>
            )}
          </>
        );

      case 'recaptcha2':
        return (
          <UmbracoRecaptchaField
            sitekey={apiConfig.recaptchSiteKey}
            size={settings.size}
            theme={settings.theme}
            render="explicit"
            name={alias}
            control={control}
            rules={rules}
          />
        );
      case 'text':
      default:
        if (alias === 'email') {
          return (
            <TextInputController
              name={alias}
              control={control}
              label={label}
              rules={rules}
              placeholder={placeholder}
              helperText={helpText}
            />
          );
        }
        return isPhone ? (
          <PhoneNumberPickerController
            name={alias}
            control={control}
            placeholder={label}
            rules={rules}
            isWeb={true}
          />
        ) : (
          <TextInputController
            name={alias}
            control={control}
            label={label}
            rules={rules}
            placeholder={placeholder}
            helperText={helpText}
          />
        );
    }
  }, [
    fieldMap,
    field,
    fieldFilter,
    control,
    // passwordRules,
    t,
    // uploadFile,
    // uploadImageLoading,
    // actionSheetSelectImages,
    // regionOptions,
    // navigation,
  ]);

  const errorMessage: string = useMemo(() => {
    if (allError) {
      const alias = field.alias;
      const fieldError = allError[alias];
      if (fieldError) {
        return typeof fieldError.message === 'string'
          ? dynamicTranslation.t(fieldError.message)
          : 'error';
      }
    }

    return '';
  }, [field, allError]);

  if (loading) {
    return (
      <View style={{width: '100%'}}>
        <TextInputLoader />
      </View>
    );
  }

  if (!renderedField) {
    return null;
  }
  return (
    <div
      style={{
        marginBottom: field.type === 'hidden' ? 0 : Metrics.verticalScale(20),
        position: 'relative',
        zIndex: index,
        ...style,
      }}>
      <UmbracoFormFieldSuspense>{renderedField}</UmbracoFormFieldSuspense>
      {errorMessage && (
        <Typography
          variant="ui3"
          style={{marginTop: Metrics.verticalScale(10)}}
          color={() => '#BE030A'}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default React.memo(UmbracoFormField);
