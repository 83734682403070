// import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ICourseStatusResponse,
  IDownloadedResponse,
  IMessageResponse,
  INotifyResponse,
  IObtainedJobResponse,
  IOpportunityResponse,
  ISavedResponse,
  ISearchResponse,
  IWatchedResponse,
} from '@src/Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {IPromocodeResponse} from '@src/Api/TraceApi/endpoints/memberDataEndpoint/memberPromocode.endpoint';
import {
  IMainContentListFragmentFragment,
  IMemberDatasFragmentFragment,
  IMemberWatchedFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {Member} from '~Api/Umbraco/interface/Member.interface';

export type MemberDataSource = 'watch' | 'learn' | 'listen';

export enum EDeviceType {
  IOS = 'iOS',
  ANDROID = 'Android',
  HUAWEI = 'Huawei',
  WEB = 'Web',
}

export interface IMemberSubscription {
  memberId?: string;
  subscriptionStart?: string;
  subscriptionEnd?: string;
  id: string;
  deviceType?: EDeviceType;
  subscriptionType?: string;
  productId: string;
  cancelRequested?: boolean;
}

export interface IMemberUnitProgression {
  name: string;
  courseId: string;
}
export type INotifyData = INotifyResponse & {
  content: IMainContentListFragmentFragment;
};

export type IDownloadedData = IDownloadedResponse & {
  content: IMainContentListFragmentFragment;
};

export type WatchedItem = {
  parentId?: string;
  time: number;
  length: number;
  metadata?: IWatchedResponse;
  contentSource: IWatchedResponse['contentSource'];
  updatedAt?: string;
};

interface MemberState {
  member?: Member;
  memberDatas?: IMemberDatasFragmentFragment;
  saved: ISavedResponse[];
  notify: INotifyData[];
  watched: IMemberWatchedFragmentFragment[];
  allWatched: Record<string, WatchedItem>;
  allSearch: ISearchResponse[];
  messages?: IMessageResponse[];
  opportunities: IOpportunityResponse[];
  bookmarks: Record<MemberDataSource, ISavedResponse[]>;
  downloaded: Record<MemberDataSource, IDownloadedData[]>;
  memberSubscription?: IMemberSubscription;
  bookmarkCount: Record<string, number>;
  firebaseUser?: string;
  unitsProgression?: IMemberUnitProgression[];
  obtainedJob?: IObtainedJobResponse[];
  coursesStatus?: ICourseStatusResponse[];
  memberPromoCode?: IPromocodeResponse;
  allPremiumPreview?: Record<string, {
    progress: number;
    playAt: string;
  }>;
}

const initialState = {
  member: undefined,
  opportunities: [],
  allSearch: [],
  notify: [],
  bookmarks: {
    learn: [],
    listen: [],
    watch: [],
  },
  downloaded: {
    learn: [],
    listen: [],
    watch: [],
  },
  watched: [],
  allWatched: {},
  memberSubscription: undefined,
  memberPromoCode: undefined,
  bookmarkCount: {},
  unitsProgression: [],
  saved: [],
  obtainedJob: [],
} as MemberState;

// Then, handle actions in your reducers:
const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setPremiumPreview: (
      state,
      action: PayloadAction<{contentId: string; progress: number; playAt: string}>,
    ) => {
      const {contentId, progress} = action.payload;
      state.allPremiumPreview = state.allPremiumPreview
        ? {
            ...state.allPremiumPreview,
            [contentId]: {
              progress,
              playAt: action.payload.playAt,
            },
          }
        : {
            [contentId]: {
              progress,
              playAt: action.payload.playAt,
            },
          };
      return state;
    },
    setMemberPromocode: (
      state,
      action: PayloadAction<IPromocodeResponse | undefined>,
    ) => {
      state.memberPromoCode = action.payload;
      return state;
    },
    setCoursesStatus: (
      state,
      action: PayloadAction<ICourseStatusResponse[]>,
    ) => {
      state.coursesStatus = action.payload;
    },
    setObtainedJob: (state, action: PayloadAction<IObtainedJobResponse[]>) => {
      state.obtainedJob = action.payload;
    },
    resetUser(state) {
      state = {...initialState};
      return state;
    },
    setFirebaseUser: (state, action: PayloadAction<string | undefined>) => {
      state.firebaseUser = action.payload;
      return state;
    },
    setMemberSubscription: (
      state,
      action: PayloadAction<IMemberSubscription | undefined>,
    ) => {
      state.memberSubscription = action.payload;
      return state;
    },
    setMemberSearch: (state, action: PayloadAction<ISearchResponse[]>) => {
      state.allSearch = action.payload;
      return state;
    },
    setMember(state, action: PayloadAction<Member | undefined>) {
      state.member = action.payload;
      return state;
    },
    setMemberDatas(
      state,
      action: PayloadAction<IMemberDatasFragmentFragment | undefined>,
    ) {
      state.memberDatas = action.payload;
      return state;
    },
    setUnitsProgression(
      state,
      action: PayloadAction<IMemberUnitProgression[]>,
    ) {
      state.unitsProgression = action.payload;
      return state;
    },
    addUnitProgression(state, action: PayloadAction<IMemberUnitProgression>) {
      const data = action.payload;
      const findProgression = state.unitsProgression?.find(
        item => item.name === data.name,
      );
      if (!findProgression && state.unitsProgression) {
        state.unitsProgression = [...state.unitsProgression, data];
      }
      return state;
    },
    setSaved(state, action: PayloadAction<ISavedResponse[]>) {
      state.saved = action.payload;
      return state;
    },
    addSaved(state, action: PayloadAction<ISavedResponse>) {
      if (!state.saved) {
        state.saved = [];
      }
      state.saved.push(action.payload);
      return state;
    },
    removeSaved(state, action: PayloadAction<{contentId?: string}>) {
      const {contentId} = action.payload;
      state.saved = state.saved.filter(item => item.contentId !== contentId);
      return state;
    },
    setNotify(state, action: PayloadAction<MemberState['notify']>) {
      state.notify = action.payload;
      return state;
    },
    setAllWatched(state, action: PayloadAction<Record<string, WatchedItem>>) {
      state.allWatched = action.payload;
      return state;
    },
    setMessages(state, action: PayloadAction<IMessageResponse[]>) {
      state.messages = action.payload;
      return state;
    },
    setOpportunities(state, action: PayloadAction<IOpportunityResponse[]>) {
      state.opportunities = action.payload;
      return state;
    },
    addOpportunity(state, action: PayloadAction<IOpportunityResponse>) {
      if (!state.opportunities) {
        state.opportunities = [];
      }
      state.opportunities.push(action.payload);
      return state;
    },
    removeOpportunity(state, action: PayloadAction<string>) {
      if (!state.opportunities) {
        state.opportunities = [];
      }
      state.opportunities = state.opportunities.filter(
        _item => _item._id !== action.payload,
      );
      return state;
    },
    setBookmarks(
      state,
      action: PayloadAction<{
        source: MemberDataSource;
        data: ISavedResponse[];
      }>,
    ) {
      const {source, data} = action.payload;
      if (!state.bookmarks) {
        state.bookmarks = {
          learn: [],
          listen: [],
          watch: [],
        };
        state.bookmarks[source] = data;
      } else {
        state.bookmarks[source] = data;
      }
      return state;
    },
    setDownloaded(
      state,
      action: PayloadAction<{
        source: MemberDataSource;
        data: IDownloadedData[];
      }>,
    ) {
      const {source, data} = action.payload;
      if (!state.downloaded) {
        state.downloaded = {
          learn: [],
          listen: [],
          watch: [],
        };
        state.downloaded[source] = data;
      } else {
        state.downloaded[source] = data;
      }
      return state;
    },
    removeSourceItem(
      state,
      action: PayloadAction<{
        type: keyof Pick<MemberState, 'bookmarks' | 'downloaded'>;
        id: string;
        source: MemberDataSource | MemberDataSource[] | '*';
      }>,
    ) {
      const {type, id, source} = action.payload;
      const stateItem = state[type] || [];
      const allKeys = Object.keys(stateItem) as MemberDataSource[];
      const list: MemberDataSource[] =
        typeof source === 'string'
          ? source === '*'
            ? allKeys
            : [source]
          : source;

      list.forEach(sourceItem => {
        // @ts-ignore
        state[type][sourceItem] = state[type][sourceItem].filter(
          (item: IDownloadedData | ISavedResponse) => {
            return item && item._id ? item._id !== id : false;
          },
        );
      });
      return state;
    },
    updateSourceItem(
      state,
      action: PayloadAction<{
        type: 'bookmarks';
        id: string;
        source: MemberDataSource | MemberDataSource[] | '*';
        data: Partial<ISavedResponse>;
      }>,
    ) {
      const {type, id, source, data} = action.payload;
      const stateItem = state[type] || [];
      const allKeys = Object.keys(stateItem) as MemberDataSource[];
      const list: MemberDataSource[] =
        typeof source === 'string'
          ? source === '*'
            ? allKeys
            : [source]
          : source;

      list.forEach(sourceItem => {
        // @ts-ignore
        state[type][sourceItem] = state[type][sourceItem].map((item: any) => {
          return item && item.id && item.id === id ? {...item, ...data} : item;
        });
      });

      return state;
    },
    updateItem(
      state,
      action: PayloadAction<{
        id: string;
        type: 'notify';
        data: Partial<INotifyData>;
      }>,
    ) {
      const {type, id, data} = action.payload;
      // @ts-ignore
      state[type] = state[type].map((item: INotifyData) => {
        return item && item._id && item._id === id ? {...item, ...data} : item;
      });

      return state;
    },
    addSourceItem(
      state,
      action: PayloadAction<
        | {
            type: 'bookmarks';
            source: MemberDataSource;
            data: ISavedResponse;
          }
        | {
            type: 'downloaded';
            source: MemberDataSource;
            data: IDownloadedData;
          }
      >,
    ) {
      const {type, data, source} = action.payload;
      switch (type) {
        case 'bookmarks':
          if (!state[type]) {
            state.bookmarks = {
              learn: [],
              watch: [],
              listen: [],
            };
            state.bookmarks[source] = [data];
          } else if (state.bookmarks) {
            state.bookmarks[source].push(data);
          }
          break;
        case 'downloaded':
          if (!state[type]) {
            state.downloaded = {
              learn: [],
              watch: [],
              listen: [],
            };
            state.downloaded[source] = [data];
          } else if (state.downloaded) {
            state.downloaded[source].push(data);
          }
          break;

        default:
          break;
      }

      return state;
    },
    addItem(
      state,
      action: PayloadAction<{
        type: 'notify';
        data: INotifyData;
      }>,
    ) {
      const {type, data} = action.payload;
      switch (type) {
        case 'notify':
          if (!state.notify) {
            state.notify = [data];
          } else {
            state.notify.push(data);
          }
          break;

        default:
          break;
      }

      return state;
    },
    removeItem(
      state,
      action: PayloadAction<{
        type: keyof Pick<MemberState, 'messages' | 'notify' | 'saved'>;
        id: string;
      }>,
    ) {
      const {type, id} = action.payload;
      switch (type) {
        case 'messages':
          return {
            ...state,
            [type]: (state[type] || []).filter((item: IMessageResponse) => {
              return item && item._id !== id;
            }),
          };
        case 'notify':
          state.notify = state.notify?.filter((item: INotifyData) => {
            return item && item._id !== id;
          });
          return state;
        case 'saved':
          return {
            ...state,
            [type]: (state[type] || []).filter((item: any) => {
              return item && item.id !== id;
            }),
          };
        default:
          return state;
      }
    },
    setBookmarkCount(
      state,
      action: PayloadAction<{contentId: string; count: number}>,
    ) {
      const {contentId, count} = action.payload;
      if (!state.bookmarkCount) {
        state.bookmarkCount = {};
      }
      state.bookmarkCount[contentId] = count;
      return state;
    },
    addBookmarkCount(
      state,
      action: PayloadAction<{contentId: string; add: number}>,
    ) {
      const {contentId, add} = action.payload;
      if (!state.bookmarkCount) {
        state.bookmarkCount = {};
      }
      state.bookmarkCount[contentId] = Math.max(
        (state.bookmarkCount[contentId] ?? 0) + add,
        0,
      );
      return state;
    },
  },
});

export default memberSlice;
