import {View} from 'react-native';
import React from 'react';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import useGetFooter from '@src/Hooks/useGetFooter';

const DefaultMenuDrawerContent = ({
  closeDrawer,
}: {
  closeDrawer?: () => void;
}) => {
  const footerData = useGetFooter();
  const {isMobile} = useBreakpoint();
  const data = footerData?.guestBurgerMenu || [];
  const dataLength = data.length;
  return (
    <View>
      <View
        style={{
          marginTop: isMobile ? 8 : 48,
        }}>
        {data.map((category, index) => (
          <PressableCustom
            key={`cm-${index}`}
            style={{
              paddingTop: isMobile ? Metrics.verticalScale(13) : 6,
              paddingHorizontal: Metrics.horizontalScale(16),
              paddingBottom: isMobile ? Metrics.verticalScale(14) : 6,
              backgroundColor: isMobile ? '#1B1C21' : 'transparent',
              borderTopLeftRadius: index === 0 ? 4 : 0,
              borderTopRightRadius: index === 0 ? 4 : 0,
              borderBottomLeftRadius: index === dataLength - 1 ? 4 : 0,
              borderBottomRightRadius: index === dataLength - 1 ? 4 : 0,
              marginBottom: isMobile
                ? index < dataLength - 1
                  ? Metrics.verticalScale(1)
                  : 0
                : 0,
            }}
            onPress={() => {
              category.onClick && category.onClick();
              closeDrawer && closeDrawer();
            }}>
            {({hover}) => {
              return (
                <Typography
                  variant="body2"
                  color={hover ? 'primary' : undefined}>
                  {category.title}
                </Typography>
              );
            }}
          </PressableCustom>
        ))}
      </View>
    </View>
  );
};

export default DefaultMenuDrawerContent;
