import {View} from 'react-native';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import AppDynamicHomePage from '../../../Common/AppDynamicHomePage/AppDynamicHomePage';
import HomeContentLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/HomeContentLoader';

const LearnHomePage = () => {
  const {page, loading} = useRegionUrl();
  const {t} = useTranslation('translation');

  if (loading) {
    return <HomeContentLoader />;
  }

  if (page.learn) {
    const contentId: string = page.learn.id;
    const contentType = page.learn.__typename;
    return (
      <AppDynamicHomePage
        id={contentId}
        __typename={contentType}
        contentSource="learn"
      />
    );
  }
  return (
    <div>
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ContentEmpty title={t('Not found')} />
      </View>
    </div>
  );
};

export default LearnHomePage;
