import React from 'react';
import {View, Pressable, StyleSheet} from 'react-native';
import {CustomActivityIndicator} from '../../CustomActivityIndicator';
import Icon from '../../Icon/Icon';
import PlayButton from '../PlayButton/PlayButton';
import {controlPlayer} from './ControlPlayer.web.styles';
import FavorisIcon, {
  FavorisProps,
} from '../../CheckBox/FavoirsIcon/FavorisIcon';

export interface ControlPlayerProps {
  onPlay?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onShare?: () => void;
  onFavoris?: FavorisProps['onPressFav'];
  isPlaying?: boolean;
  isLiked?: boolean;
  isLoading?: boolean;
  isMini?: boolean;
}

const ControlPlayer = (props: ControlPlayerProps) => {
  const {
    onPlay,
    onNext,
    onPrevious,
    isPlaying,
    onShare,
    onFavoris,
    isLiked,
    isLoading,
    isMini,
  } = props;

  return (
    <View style={controlPlayer.container}>
      {onShare && (
        <Pressable style={{padding: 13, margin: -13}} onPress={onShare}>
          <Icon name="share" size="32" />
        </Pressable>
      )}
      {onPrevious && (
        <Pressable onPress={onPrevious} style={controlPlayer.prev}>
          <Icon name="previous" />
        </Pressable>
      )}
      <View>
        <PlayButton
          size={isMini ? 40 : 76}
          isPlaying={isPlaying}
          onPress={onPlay}
          style={StyleSheet.flatten([
            controlPlayer.playButton,
            {
              opacity: isLoading ? 0 : 1,
            },
          ])}
        />
        {isLoading && (
          <View
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <CustomActivityIndicator size="large" />
          </View>
        )}
      </View>
      {onNext && (
        <Pressable onPress={onNext} style={controlPlayer.next}>
          <Icon name="next" />
        </Pressable>
      )}
      {onFavoris && (
        <FavorisIcon isLiked={isLiked} onPressFav={onFavoris} iconSize={'32'} />
      )}
    </View>
  );
};

export default ControlPlayer;
