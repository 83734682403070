import {Dispatch, useEffect, useState} from 'react';
import useEvent from '../../../Hooks/useEvent';

export default function useLocalStorage(
  key: string,
  initialValue: string = '',
): [string, Dispatch<string>] {
  const [value, setValue] = useState(
    () => window.localStorage.getItem(key) || initialValue,
  );

  const setItem = useEvent((newValue: string) => {
    setValue(newValue);
    window.localStorage.setItem(key, newValue);
  });

  useEffect(() => {
    const newValue = window.localStorage.getItem(key);
    if (value !== newValue) {
      setValue(newValue || initialValue);
    }
  });

  const handleStorage = useEvent((event: StorageEvent) => {
    if (event.key === key && event.newValue !== value) {
      setValue(event.newValue || initialValue);
    }
  });

  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return [value, setItem];
}
