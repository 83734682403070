/* eslint-disable react-native/no-inline-styles */
import {darkTheme} from 'design-system/src/styles/Themes';
import React, {ReactNode} from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import './AppDrawer.style.scss';
import Icon from 'design-system/src/Components/Icon/Icon';
import {Pressable} from 'react-native';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useRouteActive from '../AppHeader/useRouteActive';
import {useAppSelector} from '@src/Store/hooks';
import {useIsListenMiniPlayer} from '@src/Store/selectors/useMember';
import useViewPort from 'design-system/src/Hooks/useViewPort';
export interface ICustomDrawerProps {
  child: ReactNode;
  isOpen: boolean;
  onToggleDrawer?: () => void;
  isWithArrowBack?: boolean;
  zIndex?: number;
  style?: React.CSSProperties;
  isFromLanding?: boolean;
  onBack?: () => void;
  leftHeader?: ReactNode;
}
const CustomDrawer = (props: ICustomDrawerProps) => {
  const {
    child,
    isOpen,
    onToggleDrawer,
    isWithArrowBack,
    onBack,
    zIndex,
    style,
    isFromLanding,
    leftHeader,
  } = props;
  const {isMobile} = useBreakpoint();
  const {isRouteActive} = useRouteActive();

  const {drawerGoBack} = useAppSelector(state => state.app);
  const isMiniPlayer = useIsListenMiniPlayer();
  const {viewportHeight} = useViewPort();

  return (
    <Drawer
      open={isOpen}
      onClose={onToggleDrawer}
      lockBackgroundScroll
      zIndex={zIndex}
      direction="right"
      style={{
        backgroundColor: darkTheme.colors.backgroundBlack,
        width: isMobile ? '100%' : 492,
        height:
          isMobile && !isFromLanding
            ? `calc(${viewportHeight}px - 92px)`
            : viewportHeight,
        ...style,
      }}
      overlayClassName="drawer-overlay">
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent:
              isWithArrowBack || leftHeader ? 'space-between' : 'flex-end',
            // position: isMobile ? 'relative' : 'sticky',
            top: 0,
            zIndex: 10,
            padding: !isMobile ? '32px' : '32px 16px',
          }}>
          {isWithArrowBack && (
            <Pressable
              onPress={() => {
                if (drawerGoBack) {
                  drawerGoBack();
                } else {
                  onBack && onBack();
                }
              }}>
              <Icon name="retour" color="white" />
            </Pressable>
          )}
          {leftHeader && leftHeader}
          {/* {isMobile ? (
            <div
              id="drawer-header"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                padding: '28px 16px 16px 16px',
                alignItems: 'center',
              }}>
              <Pressable onPress={() => appNavigate(AppRoutesEnum.HOME)}>
                <TraceLogo width={91} height={16} />
              </Pressable>
              <div
                style={{
                  display: 'flex',
                  gap: 24,
                }}>
                <Pressable onPress={() => appNavigate(AppRoutesEnum.SEARCH)}>
                  <Icon name="search" color="white" />
                </Pressable>
                <Pressable
                  onPress={() => appNavigate(AppRoutesEnum.PROFILE_HOME)}>
                  <Icon
                    name="profil"
                    color={
                      isRouteActive([
                        AppRoutesEnum.PROFILE_HOME,
                        AppRoutesEnum.PROFILE_MY_PROFILE,
                        AppRoutesEnum.PROFILE_SUBSCRIPTION,
                        AppRoutesEnum.PROFILE_SETTINGS,
                        AppRoutesEnum.PROFILE_LEGAL_INFORMATION,
                        AppRoutesEnum.PROFILE_HELPDESK,
                        AppRoutesEnum.PROFILE_ZENDESK_FORM,
                        AppRoutesEnum.PROFILE_ACHIEVEMENT,
                        AppRoutesEnum.PROFILE_NOTIFICATION,
                      ])
                        ? darkTheme.colors.primaryOrange
                        : 'white'
                    }
                  />
                </Pressable>
              </div>
            </div>
          ) : (
            
          )} */}
          <Pressable onPress={onToggleDrawer}>
            <Icon name="close" color="white" />
          </Pressable>
        </div>

        <div
          style={{
            padding: isMobile ? '0px 16px 12px' : '0px 48px 12px',
            flex: 1,
            overflowY: 'scroll',
          }}>
          {child}
        </div>
        {isMiniPlayer && <div style={{height: 80}} />}
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
