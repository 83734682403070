import {PixelRatio, StyleSheet} from 'react-native';
import Metrics from '../Theme/Metrics';

export const TextStyles = StyleSheet.create({
  headline1: {},
  headline2: {},
  headline3: {},
  headline4: {},
  headline5: {
    fontWeight: '600',
    fontSize: Metrics.fontScale(14),
  },
  regularBody1: {},
  regularBody2: {
    fontSize: Metrics.fontScale(16),
  },
  regularBody3: {},
  buttonBold: {
    fontWeight: '600',
    fontSize: Metrics.fontScale(12),
  },
  linkRegular: {},
  readmoreRegular: {},
  navigationText: {
    fontSize: Metrics.fontScale(11),
  },
  tag: {
    fontSize: Metrics.fontScale(12),
    fontWeight: '600',
  },
  infoText: {
    fontSize: Metrics.fontScale(12),
  },
});
