import {Member} from '~Api/Umbraco/interface/Member.interface';
import {TraceApiEndpointBuilder} from '../traceApi';
import memberSlice from '~Store/Slices/Member.slice';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import documentLinkToUuid from '~Umbraco/Utils/documentLinkToUuid';
import {parsedCookieData} from '@src/Providers/DidomiInitProvider/parsedCookieData';

export const memberTransformer = (member: Member): Member => {
  if (member.memberDatasLink) {
    const memberDatasId = documentLinkToUuid(member.memberDatasLink);
    if (memberDatasId) {
      return {
        ...member,
        memberDatasId: memberDatasId,
      };
    }
  }
  return member;
};

export const createMemberEndpoint = (builder: TraceApiEndpointBuilder) => ({
  getMemberByUsername: builder.query<Member, void>({
    forceRefetch: () => true,
    query: () => ({
      url: '/me',
      method: 'GET',
    }),
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      try {
        const {data} = await queryFulfilled;
        dispatch(memberSlice.actions.setMember(data));
      } catch (err) {
        dispatch(memberSlice.actions.setMember(undefined));
        // toast.error('Member Error!');
        throw err;
      }
    },
  }),
  updateMember: builder.query<Member, {data: Member}>({
    query: ({data}) => {
      const {cookies} = parsedCookieData();
      return {
        url: '/me',
        method: 'PUT',
        body: {
          ...data,
          cookies: {
            ...data.cookies,
            ...cookies,
          },
        },
      };
    },
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      try {
        const {data} = await queryFulfilled;
        dispatch(memberSlice.actions.setMember(data));
        if (data) {
          tracker.signup.accountUpdated();
        }
      } catch (err) {
        // toast.error('Member Error!');
        tracker.errorEncountered('umbraco', 'Error updating member');
      }
    },
  }),
  removeMember: builder.query<Member, void>({
    query: () => ({
      url: '/me',
      method: 'DELETE',
    }),
  }),
});
