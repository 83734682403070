/* eslint-disable react-native/no-inline-styles */
// import {StackHeaderProps} from '@react-navigation/stack';
import CustomAppBar from 'design-system/src/Components/AppBar/CustomAppBar/CustomAppBar.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useEffect, useRef} from 'react';
import {Animated, StyleSheet, View} from 'react-native';
import {HeaderStyles} from './Header.styles';
// import {TabBarHeader} from 'design-system/src/Components/Navigation';
import useDefaultTheme from 'design-system/src/Theme/useTheme';
import HeaderActionButton from './HeaderActionButton/HeaderActionButton';
// import useUnReadMessageAndNotification from '~Store/selector/useUnReadMessageAndNotification';
import CustomBlurView from 'design-system/src/Components/CustomBlurView/CustomBlurView';
import useAppNavigation from '@src/utils/useAppNavigation';
import useParams from '@src/Hooks/useParams';
import {AppRoutesEnum} from '@src/Constant/routes';
import useHeaderOptions from '@src/Store/Slices/AppSlice/hooks/useHeaderOptions';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useHeaderAnimation from './useHeaderAnimation';
import {useAppSelector} from '@src/Store/hooks';
import SubMenuItem from '@src/layout/AppLayout/AppHeader/SubMenuItem';
import {useSubMenuLeft} from '@src/layout/AppLayout/AppHeader/AppSubMenuLeft';
import useScrollAnimation from './useScrollAnimation';
import {CSSTransition} from 'react-transition-group';
import './Header.css';
import {useAppRouterContext} from '@src/App/Route/AppRouterContext/AppRouterContext';

const statusBarHeight = 0;

const headerHeight = statusBarHeight + Metrics.verticalScale(54);
const headerHeightWithSubMenu = headerHeight + Metrics.horizontalScale(32);

interface IHeaderProps {
  onBack?: () => void;
}
const Header = (props: IHeaderProps) => {
  const {onBack} = props;
  const navigation = useAppNavigation();
  const params = useParams();
  const headerProps = useHeaderOptions();
  const {isMobile} = useBreakpoint();
  const {title} = params;
  const {interpolatedOpacity} = useHeaderAnimation();
  const isVisible = useScrollAnimation(100);

  const heightAnim = useRef(new Animated.Value(0)).current;

  const {
    back,
    overrideTitle,
    // options: {
    //   headerTitle,
    //   title,
    //   headerStyle,
    //   headerBackgroundContainerStyle,
    //   headerBackTitleStyle,
    // },
    // route: {params},
    isWithCast,
    subMenu,
    onPressLogo,
    onPressCastIcon,
    onPressArrowBack,
    isCenterTitle,
    hideAction,
    isWithLogo,
    extraAction,
    backIcon,
    activeProfile = false,
    activeSearch = false,
    noBack,
    middleContent,
    showBack,
    disableGoBack,
    isCastLoading,
    castConnect,
  } = headerProps || {};

  const {isModal} = useAppRouterContext();
  const {topMenu} = useAppSelector(state => state.app);
  const isTopMenu = !!topMenu && topMenu.length > 0;

  useEffect(() => {
    Animated.timing(heightAnim, {
      toValue: isVisible && isTopMenu ? headerHeightWithSubMenu : headerHeight, // Adjust the value as needed
      duration: 500, // Duration of the animation
      useNativeDriver: false,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  // TODO unread message and notification
  // const {total: totalUnread} = useUnReadMessageAndNotification();
  const headerTitleOverride: string = params.headerTitle || '';

  const {onGoBack, title: headerTitle, isEmpty} = useSubMenuLeft();

  const theme = useDefaultTheme();
  const textColor = theme.white;

  const handleGoToSearch = () => {
    navigation.navigate(AppRoutesEnum.SEARCH);
  };

  const handleGotoProfile = () => {
    navigation.navigate(AppRoutesEnum.PROFILE_HOME);
  };

  return (
    <View style={HeaderStyles.root}>
      <View
        style={[
          {
            minHeight: subMenu
              ? headerHeight + Metrics.horizontalScale(32)
              : headerHeight,
            // backgroundColor: back && !isAnimated ? 'transparent' : animatedAppBarBgColor,
          },
          // headerBackgroundContainerStyle,
        ]}>
        {isMobile && <View style={{height: 10}} />}
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            // headerBackgroundContainerStyle
            //   ? headerBackgroundContainerStyle
            //   : {opacity: 0},
            // subMenu ? headerBackTitleStyle : undefined,
            {
              opacity: interpolatedOpacity,
              overflow: 'hidden',
              height: heightAnim,
            },
          ]}>
          <CustomBlurView
            blurType="dark"
            blurAmount={20}
            style={[StyleSheet.absoluteFill]}
          />
        </Animated.View>
        <CustomAppBar
          isWithLogo={typeof isWithLogo === 'undefined' ? !back : isWithLogo}
          back={back ? {...back, icon: backIcon} : undefined}
          noBack={noBack}
          showBack={!isEmpty}
          isCastLoading={isCastLoading}
          castConnect={castConnect}
          onPressArrowBack={onPressArrowBack || onGoBack}
          headerTitle={headerTitle}
          style={{
            marginTop: statusBarHeight,
            height: Metrics.verticalScale(48),
          }}
          isWithCast={isWithCast}
          onPressAccountIcon={handleGotoProfile}
          onPressLogo={onPressLogo}
          onPressCastIcon={onPressCastIcon}
          onPressSearchIcon={handleGoToSearch}
          title={
            overrideTitle
              ? typeof overrideTitle === 'function'
                ? overrideTitle(headerTitleOverride || title || '')
                : overrideTitle || title
              : ''
          }
          isCenterTitle={isCenterTitle}
          hideAction={hideAction}
          leftAction={
            <>
              <HeaderActionButton
                active={activeSearch}
                onPress={handleGoToSearch}
                style={{marginRight: Metrics.horizontalScale(12)}}
                icon={'search'}
                color={textColor}
              />
              <HeaderActionButton
                active={activeProfile}
                onPress={handleGotoProfile}
                icon={'account'}
                color={textColor}
                // badgeCount={totalUnread}
              />
            </>
          }
          middleContent={middleContent}
          extraAction={extraAction}
        />
        {/* {!!subMenu && (
          <View
            style={[
              {
                position: 'relative',
                // zIndex: -1,
                //overflow: 'hidden',
              },
            ]}>
            <Animated.View
              style={[
                {
                  position: 'absolute',
                },
                headerStyle,
              ]}>
              <TabBarHeader {...subMenu} />
            </Animated.View>
          </View>
        )} */}
        {isTopMenu && !isModal && (
          <CSSTransition
            in={isVisible}
            timeout={500}
            classNames="fade"
            unmountOnExit>
            <div
              className="sub-menu-app-header"
              style={{
                marginBottom: 10,
                // display: isVisible ? undefined : 'none',
              }}>
              <div className="sub-menu-center">
                {topMenu &&
                  topMenu.map((item, index) => (
                    <SubMenuItem
                      key={index}
                      title={item.title}
                      onClick={item.onClick}
                      children={item.children}
                      color={item.color}
                    />
                  ))}
              </div>
            </div>
          </CSSTransition>
        )}
      </View>
    </View>
  );
  // };

  // return <HeaderMainComponent {...headerProps} />;
};

export default Header;
