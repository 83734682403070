import {GatsbyBrowser} from 'gatsby';
import React from 'react';
import LayoutProvider from '../../src/LayoutProvider';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <LayoutProvider props={props} element={element} />;
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: {location},
  // routerProps,
  prevRouterProps,
  pathname,
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  const previousPosition = prevRouterProps
    ? getSavedScrollPosition(prevRouterProps.location)
    : undefined;

  const isModal = location?.state?.modal;
  // @ts-ignore
  const isModalPrev = prevRouterProps?.location?.state?.modal;

  // const preventUpdateScroll = get(location, 'state.noScroll');

  // console.log('isModal', isModal, location);

  const canUpdateScroll =
    !isModalPrev && !isModal && pathname.includes('awards');

  // const pathTest = '/awards/voting/454574ca-97d0-4e39-a4af-bfc99e74fa5e/';
  // const pathTest = pathname;

  // const queriedPosition = routerProps
  //   ? getSavedScrollPosition({pathname: pathTest})
  //   : undefined;
  // console.log('=== currentPosition', currentPosition);
  // console.log('=== queriedPosition', queriedPosition, pathTest);
  // console.log('=== previousPosition', previousPosition);

  if (canUpdateScroll) {
    window.scrollTo(...[0, 0]);
    // // @ts-ignore
    // if (window.prevOfPrev) {
    //   // @ts-ignore
    //   window.scrollTo(...(window.prevOfPrev.position || [0, 0]));
    // } else {
    //   window.scrollTo(...(previousPosition || currentPosition || [0, 0]));
    // }
    // // @ts-ignore
    // window.prevOfPrev = prevRouterProps &&
    //   prevRouterProps.location && {
    //     uri: prevRouterProps.location.pathname,
    //     position: previousPosition,
    //   };
  }

  return false;
};
