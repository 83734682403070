import React, {useEffect, useRef, useState} from 'react';
import useIsDebugMode from '../useIsDebugMode';
import useDebugModeContext from '../useDebugModeContext';

interface IDebugUmbracoIdProps {
  children?: React.ReactNode;
  position?: 'page' | 'card' | 'page-root';
  umbracoId?: string;
}

const DebugUmbracoId = (props: IDebugUmbracoIdProps) => {
  const {children, umbracoId, position = 'card'} = props;
  const debugMode = useIsDebugMode();
  const {setCurrentPageId, forceReload, reload} = useDebugModeContext();
  const [copySuccess, setCopySuccess] = useState(false);
  const isPageRoot = position === 'page-root';
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const displayCopySuccess = () => {
    setCopySuccess(true);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setCopySuccess(false);
    }, 4000);
  };

  useEffect(() => {
    if (position === 'page' && debugMode) {
      setCurrentPageId(umbracoId);
      return () => {
        setCurrentPageId(undefined);
        reload();
      };
    }
  }, [umbracoId, position, setCurrentPageId, forceReload]);

  if (!debugMode || !umbracoId) {
    return <>{children}</>;
  }

  if (position === 'page') {
    return <></>;
  }

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(umbracoId).then(
        () => {
          console.log('Copied to clipboard successfully!');
          displayCopySuccess();
        },
        err => {
          console.error('Failed to copy to clipboard: ', err);
        },
      );
    } else {
      console.warn('Clipboard API not available');
    }
  };

  return (
    <div
      style={
        isPageRoot
          ? {position: 'fixed', bottom: 0, right: 0, zIndex: 999999}
          : {position: 'absolute'}
      }>
      <div
        style={{
          backgroundColor: 'rgba(255, 0, 0, 0.5)',
          padding: '2px 6px',
          marginBottom: '10px',
          position: isPageRoot ? undefined : 'absolute',
          top: 0,
          left: 0,
          zIndex: 999999,
          cursor: 'pointer',
        }}
        title="Click to copy Umbraco ID"
        onClick={e => {
          e.stopPropagation();
          copyToClipboard();
        }}>
        <p style={{fontSize: 13, color: 'white', fontFamily: 'sans-serif'}}>
          {umbracoId}
        </p>
        {copySuccess && (
          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              right: '10px',
              backgroundColor: '#32CD32', // cool green color
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              zIndex: 1000000,
            }}>
            Copied to clipboard!
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default DebugUmbracoId;
