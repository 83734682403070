import React from 'react';
import SharedCard from 'design-system/src/Components/Card/SharedCard/SharedCard.web';
import Metrics from 'design-system/src/Theme/Metrics';
import {IAudioSource} from 'design-system/src/Widget/Player/ListenPlayer/ListenPlayer';
import SingleHeader from 'design-system/src/Widget/SingleHeader/SingleHeader.web';
import {ReactNode, useState} from 'react';
import {View} from 'react-native';
import {
  IPodcastFragmentFragment,
  IPodcastItemFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {useFindProgression} from '~Hooks/useFindProgression';
import useUpdateBookmarks from '~Hooks/useUpdateBookmarks';
import {RouteTypename} from '~Navigation/type';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Typography from 'design-system/src/Components/Typography/Typography';
import {useLazyGetVimeoVideoQuery} from '~Api/TraceApi/traceApi';
import {useAppDispatch} from '@src/Store/hooks';
import appPodcastSlice from '@src/Store/Slices/AppSlice/podcast.slice';
import appListenSlice from '@src/Store/Slices/AppSlice/listen.slice';
import {toast} from 'react-toastify';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useGetLinkToShare from '@src/utils/useGetLinkToShare';
import {AppRoutesEnum} from '@src/Constant/routes';
import useShareContent from '@src/Hooks/useShareContent';

// TODO

const usePodCastSinglePageUtils = () => {
  const [currentEpisode] = useState(0);
  const {t} = useTranslation('translation');
  const shareContent = useShareContent({screenSource: 'detail'});
  const [isOpenDialogShare, setIsOpenDialogShare] = useState(false);
  const [linkToShare, setLinkToShare] = useState<string | undefined>(undefined);
  const {getLinkToShare} = useGetLinkToShare();

  const dispatch = useAppDispatch();

  const {isMobile} = useBreakpoint();

  const {findProgression, calculateTime, findCurrentTime, findDuration} =
    useFindProgression();

  const {isBookmarked, findBookmarkItem, updateBookMark} = useUpdateBookmarks({
    screenSource: 'detail',
  });

  const [fetchVimeoVideo] = useLazyGetVimeoVideoQuery();

  const renderPodCastSinglePage = (
    podcast: IPodcastFragmentFragment,
  ): ReactNode[] => {
    let listCategory: string[] = [];
    // let cover: string | undefined = undefined;
    const {author, children, description, title} = podcast;

    const handleButtonHeader = (
      items: IAudioSource[],
      currentTime: number,
      __typename: RouteTypename,
    ) => {
      dispatch(
        appPodcastSlice.actions.setPodcastParams({
          up: true,
          type: 'podcast',
          items,
          selectedIndex: currentEpisode,
          id: items[currentEpisode].id,
          isPaused: false,
          currentTime,
          currentUrl: items[currentEpisode].url,
          __typename: 'PodcastItem',
          title: undefined,
        }),
      );
      dispatch(appListenSlice.actions.closePlayer());
      dispatch(appPodcastSlice.actions.openPlayer());
    };

    author?.forEach(data => {
      if (data) {
        if (data.__typename === 'BlockListItem') {
          if (data.content.__typename === 'Author') {
            listCategory.push(data.content.authorName ?? '-');
          }
        }
      }
    });

    const pageBlocks: ReactNode[] = [];
    const episodeList: ReactNode[] = [];

    let header: ReactNode;

    if (children) {
      const {edges} = children;

      if (edges) {
        let items: IAudioSource[] = edges.map(item => {
          let node = item?.node as IPodcastItemFragmentFragment;
          return {
            id: node.id,
            uri: async () => {
              const res = await fetchVimeoVideo(node.vimeoId + '');
              if (res.error) {
                toast.error(t('Error while fetching vimeo url !'));
              }
              if (res && res.data && res.data.hls) {
                return res.data.hls;
              }
              return '';
            },
            subtitle: title,
            title: node.title,
            poster: node.thumbnail?.url,
            description: node.description,
            length: node.length,
            url: node.url,
            __typename: node.__typename,
            raw: node,
          };
        });

        if (edges && edges.length > 0) {
          const node = edges[currentEpisode]
            ?.node as IPodcastItemFragmentFragment;
          if (node && node.__typename === 'PodcastItem') {
            listCategory.push(t('{{minutes}} min', {minutes: node.length}));
            listCategory.push(
              new Date(node.releaseDate).getFullYear().toString(),
            );
            header = (
              <SingleHeader
                variant="centered"
                isBgBlur={true}
                key={'header'}
                text={node.description ?? ''}
                listCategory={listCategory}
                image={
                  node.thumbnail && node.thumbnail.url
                    ? {uri: node.thumbnail.url}
                    : undefined
                }
                numberOfLines={3}
                title={node.title ?? ''}
                buttonTitle={t('Resume reading') + ''}
                onPressButton={() => {
                  handleButtonHeader(items, 0, node.__typename);
                }}
                progress={Math.min(findProgression(node.id), 1)}
                length={
                  t('Stay {{length}}', {
                    length: calculateTime(
                      findDuration(node.id),
                      findCurrentTime(node.id),
                      true,
                    ),
                  }) || ''
                }
                episode={t('Episode {{num}}', {num: currentEpisode + 1}) || ''}
                isSerie={true}
                size="large"
                favoriLabel={t('Favoris') || ''}
                shareLabel={t('Share') || ''}
                downloadLabel={t('Download') || ''}
                trailerLabel={t('Trailer') || ''}
                seeMoreLabel={t('See more') + ''}
                seeLessLabel={t('See less') + ''}
                isPodcastSingle={true}
                onClickShare={async () => {
                  if (node?.url) {
                    shareContent.share({
                      __typename: node.__typename,
                      url: node.url,
                      message: node.title,
                      id: node.id,
                      category: podcast.parent?.name,
                      title: node.title,
                      cover: node.thumbnail?.url || '',
                      description: node.description || '',
                    });
                    setIsOpenDialogShare(true);

                    const link = getLinkToShare({
                      param: {
                        __typename: node.__typename,
                        id: node.id,
                        url: node.url,
                        selectedIndex: currentEpisode,
                      } as any,
                      path: AppRoutesEnum.PODCAST_PLAYER,
                    });
                    setLinkToShare(link);
                    setIsOpenDialogShare(true);
                  }
                }}
                isLiked={isBookmarked(podcast.id || '')}
                onClickFav={(value: boolean) => {
                  if (podcast) {
                    podcast.id &&
                      podcast.url &&
                      updateBookMark(podcast.id, podcast.url, value);
                  }
                  // podcast &&
                  //   podcast.url &&
                  //   toggleBookmark({
                  //     id: podcast.id,
                  //     url: podcast.url,
                  //     __typename: podcast.__typename,
                  //     name: podcast.title,
                  //     categoryName: podcast.parent?.name,
                  //     raw: podcast,
                  //   });
                }}
              />
            );
          }
        }

        edges.map((edge, index) => {
          if (edge?.__typename === 'ContentEdge') {
            const {node} = edge;
            if (node && node.__typename === 'PodcastItem') {
              let length = 0;
              let timeFormat = 'min';
              if (node.length && node.length >= 60) {
                length = Math.round(node.length / 60);
              } else {
                length = node.length ?? 0;
                timeFormat = 'sec';
              }
              episodeList.push(
                <View
                  key={index}
                  style={{
                    marginVertical: Metrics.verticalScale(14),
                    marginTop: index === 0 ? 0 : undefined,
                  }}>
                  <SharedCard
                    // title={t('EPISODE {{number}}', {number: index + 1})}
                    contentType="PodcastSingle"
                    title={node.title || ''}
                    length={length + ` ${timeFormat}`}
                    description={node.description ?? ''}
                    progress={Math.min(findProgression(node.id), 1)}
                    isLiked={isBookmarked(node?.id || '')}
                    onPressFavIcon={(value: boolean) => {
                      if (node) {
                        node.id &&
                          node.url &&
                          updateBookMark(node.id, node.url, value);
                      }
                      // node &&
                      //   node.url &&
                      //   toggleBookmark({
                      //     id: node.id,
                      //     url: node.url,
                      //     __typename: node.__typename,
                      //     name: node.title,
                      //     categoryName: podcast.parent?.name,
                      //     raw: podcast,
                      //   });
                    }}
                    cover={{
                      uri: node.thumbnail?.url,
                    }}
                    onPressShareIcon={async () => {
                      if (node?.url) {
                        shareContent.share({
                          __typename: node.__typename,
                          url: node.url,
                          message: node.title,
                          id: node.id,
                          category: podcast.parent?.name,
                          cover:
                            node.thumbnail && node.thumbnail.url
                              ? node.thumbnail.url
                              : '',
                          description:
                            node.description || node.shortDescription || '',
                          title: node.title,
                        });

                        setIsOpenDialogShare(true);

                        const link = getLinkToShare({
                          param: {
                            __typename: node.__typename,
                            id: node.id,
                            url: node.url,
                            selectedIndex: currentEpisode,
                          } as any,
                          path: AppRoutesEnum.PODCAST_PLAYER,
                        });
                        setLinkToShare(link);
                        setIsOpenDialogShare(true);
                      }
                    }}
                    onPressPlayIcon={() => {
                      // dispatch(
                      //   listenPlayerSlice.actions.setListenPlayerState({
                      //     up: true,
                      //     type: 'podcast',
                      //     playlist: items,
                      //     currentAudio: index,
                      //     currentAudioId: node.id,
                      //     isPaused: false,
                      //     currentTime: findCurrentTime(node.id),
                      //     currentUrl: node.url,
                      //     __typename: 'PodcastItem',
                      //     title: undefined,
                      //   }),
                      // );
                    }}
                  />
                </View>,
              );
            }
          }
        });

        if (header) {
          pageBlocks.push(header);
        } else {
          pageBlocks.push(
            <SingleHeader
              key={'header'}
              variant="vod"
              text={description ?? '-'}
              listCategory={listCategory}
              image={
                podcast && podcast.thumbnail && podcast.thumbnail.url
                  ? {
                      uri: podcast.thumbnail.url,
                    }
                  : undefined
              }
              hideButton={true}
              title={title ?? '-'}
              numberOfLines={2}
              isSerie
              size="large"
              onClickShare={async () => {
                if (podcast?.url) {
                  shareContent.share({
                    __typename: podcast.__typename,
                    url: podcast.url,
                    message: podcast.title,
                    id: podcast.id,
                    category: podcast.parent?.name,
                    cover:
                      podcast.thumbnail && podcast.thumbnail.url
                        ? podcast.thumbnail.url
                        : '',
                    description: podcast.description || '',
                    title: podcast.title,
                  });

                  setIsOpenDialogShare(true);

                  const link = getLinkToShare({
                    param: {
                      __typename: podcast.__typename,
                      id: podcast.id,
                      url: podcast.url,
                    } as any,
                    path: AppRoutesEnum.PODCAST_PLAYER,
                  });
                  setLinkToShare(link);
                  setIsOpenDialogShare(true);
                }
              }}
              favoriLabel={t('Favoris') || ''}
              shareLabel={t('Share') || ''}
              downloadLabel={t('Download') || ''}
              trailerLabel={t('Trailer') || ''}
              seeMoreLabel={t('See more') + ''}
              seeLessLabel={t('See less') + ''}
              isLiked={isBookmarked(podcast.id || '')}
              onClickFav={(value: boolean) => {
                if (podcast && podcast.url) {
                  updateBookMark(podcast.id, podcast.url, value);
                }
                // podcast &&
                //   podcast.url &&
                //   toggleBookmark({
                //     id: podcast.id,
                //     url: podcast.url,
                //     __typename: podcast.__typename,
                //     name: podcast.title,
                //     categoryName: podcast.parent?.name,
                //     raw: podcast,
                //   });
              }}
            />,
          );
        }

        // display list
        pageBlocks.push(
          <View
            key="episode-list"
            style={{
              paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
            }}>
            <Typography
              variant="h3"
              style={{
                paddingHorizontal: Metrics.horizontalScale(16),
                paddingTop: Metrics.verticalScale(16),
                paddingBottom: Metrics.verticalScale(16),
              }}>
              {t('All episodes')}
            </Typography>
            <View>{episodeList}</View>
          </View>,
        );
      }
    }

    return pageBlocks;
  };
  return {
    renderPodCastSinglePage,
    isOpenDialogShare,
    setIsOpenDialogShare,
    linkToShare,
  };
};

export default usePodCastSinglePageUtils;
