import React, {ReactNode, memo} from 'react';
import {
  StyleSheet,
  Text,
  Pressable,
  View,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';

import {subscribeStyles} from './Subscribe.styles';
import defaultTheme from '../../../Theme/defaultTheme';
import SubscribeCardGradientView from './SubscribeCardGradientView/SubscribeCardGradientView';
import SubscriptionCardContent from './SubscriptionCardContent/SubscriptionCardContent';
import Metrics from '../../../Theme/Metrics';
import Button from '../../Button/Button';
import {buttonSizeStyles} from '../../Button/Button.styles';
import GradientIconCard from '../GradientCard/GradientIconCard/GradientIconCard';

export type SubscribeCardrops = {
  title: string;
  description: string;
  initialValue?: boolean;
  onChange?: (value: boolean) => void;
  onPress?: () => void;
  price: string;
  percentageSave?: string;
  tagColor?: string;
  style?: StyleProp<ViewStyle>;
  period: 'm' | 'y' | 'w' | 'free' | 'more' | (string & {});
  icon: IconName | (() => ReactNode);
  isActive?: boolean;
  variant?: 'default' | 'center' | 'current';
  onConfirm?: () => void;
  confirmButtonTitle?: string;
  loading?: boolean;
  footerRender?: ReactNode;
};

const SubscribeCard = (props: SubscribeCardrops) => {
  const {
    title,
    description,
    onPress,
    price,
    percentageSave,
    icon,
    period,
    isActive,
    tagColor,
    variant = 'default',
    onConfirm,
    confirmButtonTitle = 'Confirm',
    loading,
    footerRender,
  } = props;
  const isFree = period === 'free';
  const isCenter = variant === 'center';
  const isCurrent = variant === 'current';
  const isWeb = isCurrent || !isCenter ? false : Platform.OS === 'web';
  const extraTag = percentageSave && (
    <View
      style={{
        marginTop: Metrics.verticalScale(-20),
        transform: [{translateX: Metrics.horizontalScale(12)}],
      }}>
      <View
        style={StyleSheet.flatten([
          subscribeStyles.tag,
          {
            backgroundColor: tagColor || defaultTheme.tag.secondary,
          },
        ])}>
        <Typography children={percentageSave} variant="ui3" />
      </View>
    </View>
  );
  return (
    <Pressable disabled={loading} style={{flex: 1}} onPress={onPress}>
      <SubscribeCardGradientView
        variant={isActive ? 'active' : 'default'}
        style={[
          !isCurrent
            ? subscribeStyles.container
            : {
                paddingVertical: Metrics.verticalScale(24),
                paddingRight: 0,
                paddingLeft: 0,
                borderRadius: Metrics.moderateScale(8),
              },
          isCenter && {
            marginTop: 'auto',
          },
          isWeb && {
            paddingVertical: Metrics.verticalScale(48),
            paddingHorizontal: isCurrent ? 0 : Metrics.horizontalScale(16),
          },
        ]}>
        <SubscriptionCardContent
          variant={variant}
          title={title}
          descTypo={isWeb ? 'body2' : 'ui3'}
          description={description}
          leadingCard={
            isCurrent ? (
              <GradientIconCard icon={icon} variant={'default'} />
            ) : (
              <View>
                {typeof icon === 'function' ? (
                  icon()
                ) : (
                  <Icon
                    name={icon}
                    size={isWeb ? '48' : undefined}
                    color="orange"
                  />
                )}
              </View>
            )
          }
          trailingStyle={
            isCenter && {
              height: 'auto',
              marginTop: 'auto',
            }
          }
          trailing={
            <View
              style={[
                subscribeStyles.trailing,
                isCenter && {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 'auto',
                  paddingTop: Metrics.verticalScale(20),
                },
                {
                  width: '100%',
                },
              ]}>
              {price !== '' && (
                <Text>
                  <Typography children={`${price}`} variant="extra2" />
                  {!isFree && (
                    <Typography children={` /${period}`} variant="extra2" />
                  )}
                </Text>
              )}

              {/* Footer Render */}
              {footerRender}

              {!isCurrent && (
                <View
                  style={{
                    marginTop: isWeb ? 44 : Metrics.verticalScale(16),
                  }}>
                  {isFree ? (
                    <View
                      style={{
                        ...buttonSizeStyles.xsmall,
                        paddingHorizontal: 0,
                        borderWidth: 1,
                        borderColor: 'transparent',
                      }}>
                      <Typography
                        children={confirmButtonTitle}
                        variant="button1"
                        color="orange"
                      />
                    </View>
                  ) : (
                    <Button
                      title={confirmButtonTitle}
                      size="xsmall"
                      variant={!loading || isActive ? 'contained' : 'disabled'}
                      onPress={onConfirm}
                      loading={isActive && loading}
                    />
                  )}
                </View>
              )}
            </View>
          }
          headerStyle={{
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
          headerExtra={!isCenter && extraTag}
        />
        {isCenter && (
          <View
            style={{
              position: 'absolute',
              top: Metrics.horizontalScale(25),
              right: Metrics.verticalScale(24),
            }}>
            {extraTag}
          </View>
        )}
      </SubscribeCardGradientView>
    </Pressable>
  );
};

export default SubscribeCard;
