import {StyleSheet} from 'react-native';
import defaultTheme from '../../Theme/defaultTheme';
import Metrics from '../../Theme/Metrics';

export const tagVariantStyles = StyleSheet.create({
  live: {
    color: defaultTheme.primaryTitle,
    backgroundColor: defaultTheme.tag.primary,
  },
  course: {
    color: defaultTheme.primaryTitle,
    backgroundColor: defaultTheme.tag.secondary,
  },
  quizz: {
    color: defaultTheme.primaryTitle,
    backgroundColor: defaultTheme.tag.secondary,
  },
  collection: {
    color: defaultTheme.primaryTitle,
    backgroundColor: defaultTheme.tag.secondary,
  },
  job: {
    color: defaultTheme.primaryTitle,
    backgroundColor: defaultTheme.tag.tertiary,
  },
  unit: {
    color: defaultTheme.tag.secondary,
    backgroundColor: defaultTheme.primaryTitle,
  },
  category: {
    color: defaultTheme.primaryTitle,
    backgroundColor: defaultTheme.primary,
  },
  realistic: {
    color: '#fff',
    backgroundColor: '#CC2A2A',
  },
  artistic: {
    color: '#fff',
    backgroundColor: '#258747',
  },
  social: {
    color: '#fff',
    backgroundColor: '#822FA2',
  },
  premium: {
    color: '#fff',
    backgroundColor: '#EA6C13',
  },
});

export const tagsStyles = StyleSheet.create({
  tagsContainer: {
    // flex: 1,
    alignItems: 'flex-start',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: Metrics.horizontalScale(4),
    paddingVertical: Metrics.verticalScale(2),
    borderRadius: Metrics.moderateScale(2),
    flexGrow: 0,
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  ellipse: {
    width: Metrics.horizontalScale(6),
    height: Metrics.horizontalScale(6),
    borderRadius: Metrics.moderateScale(3),
  },
  text: {
    marginLeft: Metrics.horizontalScale(4),
    lineHeight: Metrics.horizontalScale(16),
    alignItems: 'center',
  },
});
