import {IRegionFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {useRegionUrl} from './useRegionUrl';

const useSelectedRegion = (): IRegionFragmentFragment | undefined => {
  const {currentRegion} = useRegionUrl();

  return currentRegion;
};

export default useSelectedRegion;
