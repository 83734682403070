import React from 'react';
import {Control, FieldValues, Path, useController} from 'react-hook-form';
import {Pressable, StyleProp, View, ViewStyle} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import Typography from '../../Typography/Typography';
import RoundedTextInput, {
  RoundedTextInputProps,
} from '../RoundedTextInput/RoundedTextInput.web';
import {searchBarStyles} from './SearchBar.styles';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import {darkTheme} from '../../../styles/Themes';

export interface SearchBarProps<TField extends FieldValues = FieldValues>
  extends RoundedTextInputProps {
  isWithCancel?: boolean;
  isWithSuffixIcon?: boolean;
  isWithPrefixIcon?: boolean;
  onPressCancel?: () => void;
  activeOutlineColor?: string;
  control?: Control<TField, any>;
  name: Path<TField>;
  defaultValue?: any;
  rules?: any;
  cancelLabel?: string;
  style?: StyleProp<ViewStyle>;
}
function SearchBar<TField extends FieldValues = FieldValues>(
  props: SearchBarProps<TField>,
) {
  const {
    prefixIcon = 'search',
    suffixIcon = 'effacer',
    activeOutlineColor = defaultTheme.textInput.primary,
    isWithCancel,
    isWithSuffixIcon,
    isWithPrefixIcon = true,
    value,
    onPressCancel,
    control,
    name,
    defaultValue,
    rules,
    cancelLabel = 'Cancel',
    style,
    ...restProps
  } = props;

  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  const {isMobile} = useBreakpoint();

  return (
    <View
      style={[
        searchBarStyles.container,
        {backgroundColor: darkTheme.colors.backgroundGrey},
        style,
      ]}>
      <RoundedTextInput
        height={Metrics.verticalScale(isMobile ? 40 : 104)}
        activeOutlineColor={activeOutlineColor}
        prefixIcon={isWithPrefixIcon ? prefixIcon : undefined}
        suffixIcon={isWithSuffixIcon ? suffixIcon : undefined}
        value={field.value}
        onChangeText={field.onChange}
        variant="gray"
        disableLabel={true}
        alwaysShowPlaceholder={true}
        clearable={true}
        backgroundColor={darkTheme.colors.backgroundGrey}
        textAlign="center"
        fontSize={isMobile ? undefined : 32}
        {...restProps}
        // style={isWithCancel ? {width: Metrics.horizontalScale(280)} : {}}
      />
      {isWithCancel && (
        <Pressable
          style={searchBarStyles.buttonContainer}
          onPress={onPressCancel}>
          <Typography
            children={cancelLabel}
            variant="body2"
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              paddingRight: isMobile ? 0 : Metrics.horizontalScale(11),
            }}
          />
        </Pressable>
      )}
    </View>
  );
}

export default SearchBar;
