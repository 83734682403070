import {useAppSelector} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import {CardMessageProps} from 'design-system/src/Components/Card/CardMessage/CardMessage';
import {useCallback, useEffect, useState} from 'react';
import {
  IAllOpportunityByJobsQuery,
  useAllOpportunityByIdLazyQuery,
  useAllOpportunityByJobsLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import DateFactory from '~tools/DateFactory';
export type OppCardProps = CardMessageProps & {id: string};

const useMemberOpportunity = (): {
  refresh: () => Promise<void>;
  loading: boolean;
  data: Array<OppCardProps>;
} => {
  const {
    member,
    opportunities = [],
    jobs = [],
  } = useAppSelector(({member: memberState}) => ({
    member: memberState.member,
    opportunities: memberState.opportunities,
    jobs: memberState.obtainedJob,
  }));
  const [memberOpportunities, setMemberOpportunities] = useState<
    OppCardProps[]
  >([]);
  const [fetchAllOpportunity, {loading: _loading}] =
    useAllOpportunityByIdLazyQuery();
  const [fetchOppByJobs, {loading: isLoading}] =
    useAllOpportunityByJobsLazyQuery();
  const navigation = useAppNavigation();

  const mapData = useCallback((data: IAllOpportunityByJobsQuery) => {
    const _list: Array<OppCardProps> = [];
    if (data && data.allOpportunity && data.allOpportunity.items) {
      data.allOpportunity.items.forEach(item => {
        if (item && item.__typename === 'Opportunity') {
          const {title, shortDescription, thumbnail, endDate, partners, id} =
            item;
          _list.push({
            id,
            title: title || '',
            description: shortDescription,
            cover: {
              uri: thumbnail?.url,
            },
            date: endDate && new DateFactory(endDate).format(),
            logoPartner: partners?.map(partner => ({
              uri: partner?.__typename === 'Partner' ? partner.logo?.url : '',
            })),
            onPress: () => {
              if (item && item.__typename === 'Opportunity') {
                const {id, url, __typename} = item;
                // @ts-ignore
                navigation.navigate(AppRoutesEnum.OPPORTUNITY_SINGLE, {
                  state: {
                    params: {
                      id: id || '',
                      type: 'url',
                      url: url || '',
                      __typename,
                    },
                    canGoBack: true,
                  },
                });
              }
            },
          });
        }
      });
    }

    return _list;
  }, []);

  const handleFetchedData = useCallback(async () => {
    const listId = opportunities
      .map(item => item.contentId)
      .filter(_item => _item);

    let jobId: string[] = [];
    jobs?.forEach(_item => {
      if (_item.finished && _item.certificateId) {
        jobId.push(_item.certificateId);
      }
    });
    let _items: OppCardProps[] = [];
    const {data} = await fetchOppByJobs({
      variables: {
        id: jobId,
      },
    });
    if (data) {
      _items = mapData(data);
    }
    const {data: contentData} = await fetchAllOpportunity({
      variables: {
        id: listId,
      },
    });
    if (contentData) {
      let tempItems = mapData(contentData);
      _items.forEach(_item => {
        const isExist =
          tempItems.find(opp => opp.id === _item.id) !== undefined;
        if (!isExist) {
          tempItems.push(_item);
        }
      });
      _items = tempItems;
    }
    setMemberOpportunities(_items);
  }, [fetchAllOpportunity, opportunities, jobs, setMemberOpportunities]);
  const refresh = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (member) {
          handleFetchedData()
            .then(() => resolve())
            .catch(reject);
        }
      }, 500);
    });
  }, [handleFetchedData]);

  useEffect(() => {
    if (member) {
      handleFetchedData();
    }
  }, [handleFetchedData, member]);

  return {refresh, loading: isLoading || _loading, data: memberOpportunities};
};

export default useMemberOpportunity;
