import {pathToRegexp} from 'path-to-regexp';
import {AppRoutesEnum} from '@src/Constant/routes';
import getPrefixedPattern from './getPrefixedPattern';

const getMatchingRoute = (pathname: string): AppRoutesEnum | null => {
  for (const [routePattern, routeEnum] of Object.entries(AppRoutesEnum)) {
    const regex = pathToRegexp(`${getPrefixedPattern(routeEnum)}`);
    if (regex.test(pathname)) {
      return routeEnum;
    }
  }
  return null;
};

export default getMatchingRoute;
