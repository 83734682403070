import React, {useMemo} from 'react';
import {
  IArtistServiceFragmentFragment,
  useAristServiceByIdQuery,
} from '~Api/Graphql/gql/types.generated';

import ServiceCheckBoxListController, {
  ServiceCheckBoxListControllerProps,
} from 'design-system/src/Components/ServiceForm/ServiceCheckBoxListController';
import {FieldValues} from 'react-hook-form';

export interface ServiceCheckBoxListProps<
  TField extends FieldValues = FieldValues,
> extends Omit<ServiceCheckBoxListControllerProps<TField>, 'data'> {
  ids: string[];
  allID: string[];
}

const ServiceCheckBoxList = (props: ServiceCheckBoxListProps) => {
  const {ids, allID, ...rest} = props;
  const response = useAristServiceByIdQuery({
    variables: {
      id: allID,
    },
  });

  const {items} = useMemo(() => {
    const {data} = response;
    if (
      data &&
      data.allContent.items[0] &&
      data.allContent.items[0].__typename === 'ArtistService'
    ) {
      return {
        items: data.allContent.items.map((item, index) => {
          let value = item as IArtistServiceFragmentFragment;
          return {
            title: value.title ?? '-',
            description: value.subtitle ?? '-',
            id: ids[index],
          };
        }),
      };
    }
    return {
      items: [],
    };
  }, [ids, response]);
  // return (
  //   <View
  //     style={{
  //       marginTop: Metrics.verticalScale(31),
  //       marginBottom: Metrics.verticalScale(4),
  //     }}>
  //     {items.map((d, index) => (
  //       <ServiceFormAdditional {...d} key={`s4as-${index}`} />
  //     ))}
  //   </View>
  // );
  return <ServiceCheckBoxListController data={items} {...rest} />;
};

export default ServiceCheckBoxList;
