import { StyleSheet } from "react-native";
import Metrics from "../../../Theme/Metrics";
import useDefaultTheme from "../../../Theme/useTheme";

export const cardIconStyles = StyleSheet.create({
    container: {
        width: Metrics.horizontalScale(72),
        height: Metrics.verticalScale(72),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: Metrics.moderateScale(4),
        backgroundColor: useDefaultTheme().primaryNavBar
    },
})