import {StyleSheet} from 'react-native';
import Metrics, {isWeb, width} from '../../../Theme/Metrics';

export const customHeaderSize = StyleSheet.create({
  large: {
    minHeight: Metrics.verticalScale(632),
  },
  medium: {
    minHeight: Metrics.verticalScale(480),
  },
});

export const customHeaderStyles = StyleSheet.create({
  container: {
    display: 'flex',
    width: isWeb ? '100%' : width,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  children: {
    alignItems: 'center',
    marginTop: isWeb ? 13 : Metrics.verticalScale(10),
    marginBottom: Metrics.verticalScale(19),
  },
  content: {
    // alignItems: 'center',
    justifyContent: 'center',
    paddingTop: Metrics.verticalScale(42),
    paddingHorizontal: Metrics.horizontalScale(0),
    minHeight: Metrics.verticalScale(206),
    width: '100%',
  },
});
