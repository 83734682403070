import React, {PropsWithChildren} from 'react';
import {View, StyleProp, ViewStyle, StyleSheet} from 'react-native';
import Metrics, {width} from '../../../Theme/Metrics';
import {appBarStyles} from './AppBar.styles';

export type GenericAppBarProps = {
  leading: React.ReactNode;
  title?: React.ReactNode;
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
  isCenterTitle?: boolean;
  customWidth?: number | string;
  middleContent?: React.ReactNode;
  customHeightStyle?: StyleProp<ViewStyle>;
};

const GenericAppBar: React.FC<PropsWithChildren<GenericAppBarProps>> = ({
  leading,
  title,
  backgroundColor,
  children,
  style,
  isCenterTitle,
  customWidth,
  middleContent,
  customHeightStyle,
}) => {
  return (
    <View
      style={[
        appBarStyles.container,
        customHeightStyle ?? appBarStyles.heightContainer,
        {
          backgroundColor: backgroundColor ?? 'transparent',
        },
        style,
      ]}>
      {(isCenterTitle || middleContent) && (
        <View
          style={StyleSheet.flatten([
            appBarStyles.title,
            {
              alignItems: 'flex-end',
              justifyContent: 'center',
              flexDirection: 'row',
              width: '100%',
            },
          ])}>
          <View
            style={{width: Metrics.horizontalScale(200), alignItems: 'center'}}>
            {(middleContent || title) ?? <></>}
          </View>
        </View>
      )}
      <View
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: Metrics.horizontalScale(16),
          paddingRight: Metrics.horizontalScale(25),
          alignItems: 'center',
        }}>
        <View style={appBarStyles.leading}>{leading}</View>
        <View style={appBarStyles.actions}>{children}</View>
      </View>
    </View>
  );
};

export default GenericAppBar;
