import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ProfileWrapper from '../ProfileWrapper/ProfileWrapper';
import TabView from 'design-system/src/Components/DataDisplay/TabView/TabView';
import ProfileNotificationList from './ProfileNotificationList/ProfileNotificationList';
import ProfileMessageList from './ProfileMessageList/ProfileMessageList';

const ProfileNotification = () => {
  const {t} = useTranslation('profile');
  const viewElements = [
    {
      key: 'notification',
      label: t('Notification'),
    },
    {
      key: 'message',
      label: t('Messages'),
    },
  ];

  return (
    <ProfileWrapper title={t('Notification') || ''}>
      <TabView
        swipeEnabled={false}
        items={viewElements}
        activeKey=""
        customRenderScene={({route}) => {
          if (route.key === 'notification') {
            return <ProfileNotificationList />;
          }

          return <ProfileMessageList />;
        }}
      />
    </ProfileWrapper>
  );
};

export default ProfileNotification;
