import React from 'react';
import {ActivityIndicatorProps} from 'react-native';
import defaultTheme from '../../Theme/defaultTheme';
import styled from 'styled-components';

interface CustomActivityIndicatorProps
  extends Omit<ActivityIndicatorProps, 'color'> {
  customColor?: string;
}

const Loader = styled.span<{$color?: string}>`
  width: 20px;
  height: 20px;
  border: 2px solid rgba(234, 108, 19, 0.2);
  border-bottom-color: ${props => props.$color ?? '#EA6C13'};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.8s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CustomActivityIndicator = (props: CustomActivityIndicatorProps) => {
  let {customColor} = props;
  return (
    <Loader className="loader" $color={customColor ?? defaultTheme.primary} />
    // <ActivityIndicator color={customColor ?? defaultTheme.primary} {...props} />
  );
};

export default CustomActivityIndicator;
