import {useCallback} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  IQuizItemsSliderFragmentFragment,
  IQuizzSurveyCategoryFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {ICourseSliderItem} from '~Umbraco/Components/CourseSlider/CourseSlider';

export const useMapQuizzSurvey = () => {
  const {t} = useTranslation(['learn']);

  const mapQuizzSurvey = useCallback(
    (
      items:
        | IQuizItemsSliderFragmentFragment['contentItems']
        | IQuizzSurveyCategoryFragmentFragment['children']['items'],
    ): Array<ICourseSliderItem> => {
      let list: ICourseSliderItem[] = [];
      items?.forEach(item => {
        if (item) {
          if (item.__typename === 'QuizzSurvey') {
            const {id, __typename, title} = item;
            list.push({
              cover: {
                uri: item.thumbnail?.optiUrl || item.thumbnail?.url,
              },
              title: title || '',
              tag: {
                text: t('QUIZ'),
                variant: 'quizz',
              },
              id,
              url: '',
              size: 'small',
              __typename,
            });
          } else if (item.__typename === 'QuizzSurveyCollection') {
            const {id, __typename, title} = item;
            list.push({
              cover: {
                uri: item.thumbnail?.optiUrl || item.thumbnail?.url,
              },
              title: title || '',
              tag: {
                text: t('COLLECTION'),
                variant: 'collection',
              },
              id,
              url: '',
              size: 'small',
              __typename,
            });
          }
        }
      });
      return list;
    },
    [t],
  );

  return {mapQuizzSurvey};
};
