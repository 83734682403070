import React from 'react';
import {useRegions} from '@src/Store/selectors/useRegions';
import SelectController, {
  SelectControllerProps,
} from 'design-system/src/Components/Form/Select/SelectController.web';
import {FieldValues} from 'react-hook-form';

interface IRegionSelectorProps<TField extends FieldValues = FieldValues>
  extends Omit<SelectControllerProps<TField>, 'options'> {}

const RegionSelector = (props: IRegionSelectorProps) => {
  const {regionOptions} = useRegions();
  return <SelectController {...props} options={regionOptions} />;
};

export default RegionSelector;
