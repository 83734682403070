import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useMemo, useState, useEffect, useRef, ReactNode} from 'react';
import {View, Pressable, Text} from 'react-native';
import {RadioButtonItem} from 'design-system/src/Components/RadioButton/RadioButtonItem/RadioButtonItem';
import RadioButton from 'design-system/src/Components/RadioButton/RadioButton';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  ICourseFragmentFragment,
  IGapfillNuggetFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import TapToSelect from './GapRadioButtonItem';
import _ from 'lodash';
import {deleteObjectKey} from '@src/tools/utils/objectExtension';
import HtmlRender from '@src/Umbraco/Components/HtmlRender/HtmlRender';
import CourseNuggetWrapper from '../CourseNuggetWrapper';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

export type GapfillNuggetData = {
  choice: Record<string, RadioButtonItem[]>;
  text: string;
  imageReferences?: Record<string, string | undefined>;
};

export type RevealAnswerItem = {
  label: string;
  reveal: boolean;
  isCorrect?: boolean;
  isSelected?: boolean;
};

export type GapfillValueType = {
  [_questionRef: string]: {
    value: string;
    id: string;
  };
};

export type ISelectAnswerType = {
  [_questionRef: string]: {
    value: string;
    index: number;
  };
};

export type IGapfillNuggetProps = {
  data: GapfillNuggetData;
  title?: string;
  description?: string;
  showResult?: boolean;
  genericFeedback?: boolean;
  positiveFeedback?: string;
  negativeFeedback?: string;
  value?: GapfillValueType;
  onChange?: (data: GapfillValueType) => void;
  onCheckCorrect?: (isCorrect?: boolean) => void;
  raw: IGapfillNuggetFragmentFragment;
  property?: ILearnPropertyName;
  id: string;
};

export default function GapfillNugget(props: IGapfillNuggetProps) {
  const {
    data,
    title,
    description,
    value = {},
    showResult,
    raw,
    onChange,
    onCheckCorrect,
    property: learnProperty,
    id,
  } = props;

  const {isMobile} = useBreakpoint();
  const property: ILearnPropertyName = useMemo(() => {
    const {parent: courseUnit} = raw;
    let course: Partial<ICourseFragmentFragment> | undefined = undefined;
    if (
      courseUnit &&
      courseUnit.__typename === 'CourseUnit' &&
      courseUnit.parent &&
      courseUnit.parent.__typename === 'Course'
    ) {
      course = courseUnit.parent;
    }
    return {
      courseName: course?.name,
      courseId: course?.id,
      unitName: courseUnit?.__typename === 'CourseUnit' ? courseUnit.name : '',
      partnerName:
        course?.linkedPartners &&
        course.linkedPartners[0] &&
        course.linkedPartners[0].__typename === 'Partner'
          ? course.linkedPartners[0].partnerName
          : '',
      nuggetReference: raw.externalId,
      nuggetType: raw.__typename,
      // nuggetPlace: //TODO,
      certificateName: learnProperty?.certificateName,
      certificateId: learnProperty?.certificateId,
      time: new Date(),
    };
  }, [raw]);

  useEffect(() => {
    tracker.learn.nuggetStarted(property);
  }, []);

  const [currentSelectedQuestion, setCurrentSelectedQuestion] =
    useState<string>('');

  const [selectedAnswer, setSelectedAnswer] = useState<GapfillValueType>({});

  const {t} = useTranslation('translation');

  const splittData: string[] = useMemo(() => {
    const splitted = (data as GapfillNuggetData)?.text
      .replace(/\n/g, ' \n ')
      .replace(/\[.*?\]/g, t => {
        return ` [*__*] `;
      })
      .split(' ');
    return splitted;
  }, [data]);

  const allDataCorrect: Record<string, RadioButtonItem> = useMemo(() => {
    let dataCorrect: Record<string, RadioButtonItem> = {};
    Object.keys(data.choice).forEach(key => {
      const isCorrectItem = data.choice[key].find(item => item.isCorrect);
      if (isCorrectItem) {
        dataCorrect[key] = isCorrectItem;
      }
    });
    return dataCorrect;
  }, [data, data.choice]);

  const shuffleCorrect = useRef<RadioButtonItem[]>([]);
  const currentId = useRef<string>(id);
  const showOption = useRef<boolean>();
  const showAnswerOption = useRef<boolean>();

  useEffect(() => {
    if (currentId.current !== id) {
      shuffleCorrect.current = [];
      currentId.current = id;
      showOption.current = false;
      showAnswerOption.current = false;
    }
  }, [id]);
  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(selectedAnswer)) {
      let deleteKeys = Object.keys(value).filter(_key => !data.choice[_key]);
      let obj = deleteObjectKey(value, deleteKeys);
      setSelectedAnswer(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSelected = (reference: string) => () => {
    setCurrentSelectedQuestion(reference);
    // scroll to the bottom
    document.getElementById('gapfill-radio-button')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const content: ReactNode = useMemo(() => {
    if (splittData && splittData.length) {
      let counter: number = 1;

      return splittData.map((tt, index) => {
        if (tt === '[*__*]') {
          const questionKey = `[QUESTION${counter}]`;
          counter++;
          const isSelected = currentSelectedQuestion === questionKey;

          const questionAnswer = selectedAnswer[questionKey]
            ? data.choice[questionKey].find(
                _item => _item.value === selectedAnswer[questionKey].value,
              )
            : undefined;
          const hasSelected = !!questionAnswer;
          const isCorrect: boolean = !!(
            questionAnswer && questionAnswer.isCorrect
          );

          const info: {
            borderColor: string;
            backgroundColor: string;
          } = {
            borderColor: 'transparent',
            backgroundColor: '#262A33',
          };

          if (hasSelected && showResult) {
            if (isCorrect) {
              info.borderColor = 'rgba(5, 201, 107, 0.8)';
              info.backgroundColor = 'rgba(5, 201, 107, 0.2)';
            } else {
              info.borderColor = '#BE030A';
              info.backgroundColor = 'rgba(255, 30, 30, 0.2)';
            }
          } else {
            if (isSelected) {
              info.backgroundColor = 'rgba(234, 108, 19, 0.13)';
            } else {
              info.borderColor = 'transparent';
              info.backgroundColor = '#262A33';
            }
          }
          if (isSelected) {
            info.borderColor = '#EA6C13';
          }

          return (
            <Pressable
              key={`t-${index}`}
              onPress={handleSelected(questionKey)}
              style={{
                backgroundColor: info.backgroundColor,
                borderRadius: Metrics.verticalScale(12),
                borderWidth: 1,
                borderColor: info.borderColor,
                height: Metrics.verticalScale(28),
              }}>
              <Typography
                variant="body1"
                style={{
                  minWidth: Metrics.horizontalScale(80),
                  paddingLeft: Metrics.horizontalScale(8),
                  paddingRight: Metrics.horizontalScale(8),
                }}>
                {(questionAnswer && questionAnswer.value) || (
                  <Text style={{color: 'transparent'}}>{'_'}</Text>
                )}
              </Typography>
            </Pressable>
          );
        }
        if (tt === '\n') {
          return <View key={`t-${index}`} style={{flexBasis: '100%'}} />;
        }
        return (
          <View
            key={`t-${index}`}
            style={{
              height: Metrics.verticalScale(28),
            }}>
            <Typography variant="body1">{' ' + tt + ' '}</Typography>
          </View>
        );
      });
    }
    return undefined;
  }, [currentSelectedQuestion, splittData, selectedAnswer]);

  useEffect(() => {
    const isCorrect = Object.keys(allDataCorrect).every(
      key =>
        selectedAnswer[key] &&
        allDataCorrect[key].id === selectedAnswer[key]?.id,
    );
    if (!isCorrect && selectedAnswer[currentSelectedQuestion]) {
      tracker.learn.nuggetError({...property, time: new Date()});
    }
    onCheckCorrect && onCheckCorrect(isCorrect || !showResult);
  }, [selectedAnswer]);

  const lastGroups = useRef<RadioButtonItem[]>([]);

  const groups: RadioButtonItem[] = useMemo(() => {
    const _groups = Object.keys(data.choice).map((ref: string, _index) => {
      const isCorrect = allDataCorrect[ref]?.id === selectedAnswer[ref]?.id;
      const keys = Object.keys(selectedAnswer);
      const findIndex = keys.findIndex(_item => _item === ref);
      const index =
        findIndex >= 0
          ? findIndex + 1
          : currentSelectedQuestion === ref
            ? keys.length + 1
            : undefined;
      if (ref.length >= 20) {
        showOption.current = true;
      }
      return {
        label: ref,
        value: ref,
        isCorrect,
        select: currentSelectedQuestion === ref,
        isAnswered: selectedAnswer[ref] !== undefined,
        index,
        image: data.imageReferences ? data.imageReferences[ref] : undefined,
      };
    });
    lastGroups.current = _groups;
    return _groups;
  }, [
    allDataCorrect,
    selectedAnswer,
    currentSelectedQuestion,
    data.choice,
    data.imageReferences,
  ]);

  const answerValue: RadioButtonItem[] = useMemo(() => {
    if (!data.text) {
      const answers = Object.values(selectedAnswer);
      if (!shuffleCorrect.current.length) {
        shuffleCorrect.current = _.shuffle(Object.values(allDataCorrect));
      }
      let allCorrect: RadioButtonItem[] = shuffleCorrect.current;
      const _newAnswer = allCorrect.map(item => {
        let isCorrect = false;
        if (item.ref && selectedAnswer[item.ref]?.id === item.id) {
          isCorrect = true;
        }
        const isSelected = selectedAnswer[currentSelectedQuestion]
          ? selectedAnswer[currentSelectedQuestion]?.id === item.id
          : false;
        const index = groups.find(
          g => selectedAnswer[g.value]?.id === item.id,
        )?.index;
        if (item.label.length >= 20) {
          showAnswerOption.current = true;
        }
        return {
          ...item,
          isAnswered: answers.find(_item => _item.id === item.id) !== undefined,
          isCorrect: isCorrect,
          select: isSelected,
          index,
        };
      });
      return _newAnswer;
    }
    return [];
  }, [
    selectedAnswer,
    data.text,
    allDataCorrect,
    currentSelectedQuestion,
    groups,
  ]);

  const handleAnswerChange = (
    _value: string[],
    newValue?: string,
    _id?: string,
  ) => {
    if (_value[0] && currentSelectedQuestion) {
      setSelectedAnswer(_currentAnswer => {
        const findKey = Object.keys(_currentAnswer).find(
          key => _id && _currentAnswer[key]?.id === _id,
        );
        let _newData: GapfillValueType = {};
        if (findKey && !data.text) {
          if (findKey === currentSelectedQuestion) {
            let obj = deleteObjectKey(_currentAnswer, [findKey]);
            _newData = {
              ...obj,
            };
          } else if (_currentAnswer[currentSelectedQuestion]) {
            _newData = {
              ..._currentAnswer,
              [findKey]: _currentAnswer[currentSelectedQuestion],
              [currentSelectedQuestion]: {
                id: _id || '',
                value: _value[0],
              },
            };
          } else {
            let obj = deleteObjectKey(_currentAnswer, [findKey]);
            _newData = {
              ...obj,
              [currentSelectedQuestion]: {
                value: _value[0],
                id: _id || '',
              },
            };
          }
        } else {
          _newData = {
            ..._currentAnswer,
            [currentSelectedQuestion]: {
              value: _value[0],
              id: _id || '',
            },
          };
        }
        onChange && onChange(_newData);
        return _newData;
      });
    }
  };

  return (
    <CourseNuggetWrapper
      title={title || t('Select the answer you most agree with') || undefined}
      description={description}
      optionsWrapperStyle={{
        maxWidth: '80%',
      }}
      optionsRender={
        <View
          style={{
            marginTop: Metrics.verticalScale(32),
            paddingBottom: Metrics.verticalScale(100),
          }}>
          <View>
            {data.text && (
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  marginBottom: Metrics.verticalScale(80),
                  // @ts-ignore
                  gap: 5,
                }}>
                {content}
              </View>
            )}
            <div id="gapfill-radio-button">
              {currentSelectedQuestion &&
                data.choice[currentSelectedQuestion] &&
                data.text && (
                  <div
                    style={{
                      // position: 'sticky',
                      // bottom: isMobile ? 0 : 150,
                      background: defaultTheme.page.background,
                      padding: '8px',
                    }}>
                    <RadioButton
                      value={
                        selectedAnswer[currentSelectedQuestion]
                          ? [selectedAnswer[currentSelectedQuestion].value]
                          : []
                      }
                      useChoice
                      useCustomValue={!data.text}
                      showResult={showResult}
                      onChange={handleAnswerChange}
                      groups={data.choice[currentSelectedQuestion]}
                    />
                  </div>
                )}
            </div>
            <div>
              {!data.text &&
                groups.map((g, index) => {
                  return (
                    <TapToSelect
                      showOption={showOption.current}
                      showAnswerOption={showAnswerOption.current}
                      key={`gap-${index}`}
                      index={index}
                      showResult={showResult}
                      g={g}
                      answer={answerValue[index]}
                      onChangeAnswer={handleAnswerChange}
                      onChange={value => {
                        if (value[0]) {
                          setCurrentSelectedQuestion(value[0]);
                        }
                      }}
                    />
                  );
                })}
            </div>
          </View>
        </View>
      }
    />
  );
}
