/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import UnitOverView from './UnitOverView/UnitOverView';
import {
  ICourseFragmentFragment,
  ICourseNuggetFragmentFragment,
  ICourseUnitFragmentFragment,
  useJobTrainingByCourseQuery,
} from '~Api/Graphql/gql/types.generated';
import {HomeContentLoader} from 'design-system/src/Components/ContentLoader';
import CoursePage from './UnitPages/CoursePage/CoursePage';
import CourseNugget from './UnitPages/CourseNugget/CourseNugget';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import memberSlice from '~Store/Slices/Member.slice';
import {
  useLazyGetMemberUnitProgressionByIdQuery,
  useUpdateMemberUnitProgressionMutation,
} from '~Api/TraceApi/traceApi';
import {IMemberUnitProgressionBody} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataBody.interface';
import {useMemberObtainedJob} from '~Hooks/useMemberObtainedJob';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
import dayjs, {Dayjs} from 'dayjs';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {useMemberCourseStatus} from '~Hooks/useMemberCourseStatus';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import useParams from '@src/Hooks/useParams';
import useMember, {
  useSelecteUnitsProgression,
} from '@src/Store/selectors/useMember';
import {useUnitProgression} from '@src/Store/Slices/CourseUnitSlice/CourseUnit.selector';
import {useSnackbarNotification} from '@src/Store/Slices/SnackbarSlice/SnackBar.selector';
import courseUnitSlice from '@src/Store/Slices/CourseUnitSlice/CourseUnit.slice';
import TestScreenContainer from '../TestScreen/TestScreenContainer';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import tabBarSlice from '@src/Store/Slices/TabBarSlice/TabBar.slice';
import {AppRoutesEnum} from '@src/Constant/routes';
import {toast} from 'react-toastify';
import CustomModalHeader from '@src/components/general/CustomModal/CustomModalHeader';
import appSlice from '@src/Store/Slices/AppSlice';
import useFetchCourseUnit from './useFetchCourseUnit';
import {UnitScreenLoaderContainer} from './UniteScreen.styled';
import {getContentEndUrl} from '@src/Umbraco/Utils/getContentEndUrl';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

const UnitScreen = () => {
  const params = useParams();
  const navigation = useAppNavigation();
  const {endUrl = '', step = 0, currentIndex = 0} = params || {};

  const showTabBar = useAppSelector(state => state.tabBar.mount);
  const goBackInBackground = useAppSelector(
    state => state.app.goBackInBackground,
  );

  const {
    data: courseUnit,
    unitId: id,
    loading,
    courseUnitUrls = [],
    courseId = '',
    courseUrl = '',
  } = useFetchCourseUnit({endUrl});

  const dispatch = useAppDispatch();
  const {t} = useTranslation(['learn']);
  const member = useMember();
  const unitProgression = useUnitProgression(id || '');
  const [updateUnitProgression, {isLoading}] =
    useUpdateMemberUnitProgressionMutation();
  const snackbar = useSnackbarNotification();
  const [
    fetchUnitProgression,
    {isLoading: progressionLoading, data: progress},
  ] = useLazyGetMemberUnitProgressionByIdQuery();
  const isTest: boolean = false;
  const postLoadingRef = useRef<boolean>(isLoading);
  const showResult: boolean = !isTest;

  const startTime = useRef<Dayjs>(dayjs());
  const [isCorrect, setIsCorrect] = useState<boolean>();
  const unitsProgression = useSelecteUnitsProgression();

  const postLoading = isLoading;

  const certificateResponse = useJobTrainingByCourseQuery({
    variables: {
      id: courseId,
    },
    skip: !courseId,
  });
  // const {loading: networkLoading, isInternetReachable} = useCheckNetwork();
  const pageLoading = loading;
  const backLoading = progressionLoading || certificateResponse.loading;

  useEffect(() => {
    if (id && member) {
      fetchUnitProgression({
        id,
      });
    }
  }, [fetchUnitProgression, id, member]);

  // const {canPlay} = useListenPlayer();
  useEffect(() => {
    if (showTabBar) {
      dispatch(tabBarSlice.actions.setMount(false));
    }

    // if (canPlay) {
    //   dispatch(listenPlayerSlice.actions.setCanPlay(false));
    // }

    return () => {
      // if (!canPlay) {
      //   dispatch(listenPlayerSlice.actions.setCanPlay(true));
      // }
      if (!showTabBar) {
        dispatch(tabBarSlice.actions.setMount(true));
      }
    };
  }, [showTabBar, dispatch]);

  const {
    unitData,
    pages,
    totalStep,
    nextLabel,
    property,
    isTakeAways,
  }: {
    unitData?: ICourseUnitFragmentFragment | undefined;
    totalStep: number;
    nextLabel: string;
    property?: ILearnPropertyName;
    pages?: ICourseUnitFragmentFragment['pages'];
    isTakeAways?: boolean;
  } = useMemo(() => {
    let _unitData: ICourseUnitFragmentFragment | undefined;
    let _totalStep: number = 1;
    let _nextLabel: string = t('Finish');
    let certificateName: string | undefined;
    let certificateId: string | undefined;
    const {data: certificate} = certificateResponse;
    if (
      certificate &&
      certificate.allJobTraining &&
      certificate.allJobTraining.items &&
      certificate.allJobTraining.items[0] &&
      certificate.allJobTraining.items[0].__typename === 'JobTraining'
    ) {
      certificateName = certificate.allJobTraining.items[0].name;
      certificateId = certificate.allJobTraining.items[0].id;
    }
    _unitData = courseUnit;
    if (_unitData) {
      let _property: ILearnPropertyName | undefined;
      if (_unitData.parent && _unitData.parent.__typename === 'Course') {
        const course = _unitData.parent;
        const {interests} = course;
        let interest: string[] = [];
        interests?.forEach(_interest => {
          if (
            _interest &&
            _interest.__typename === 'ContentTag' &&
            _interest.name
          ) {
            interest.push(_interest.name);
          }
        });
        _property = {
          courseName: course.name,
          courseId: course.id,
          partnerName:
            course.linkedPartners &&
            course.linkedPartners[0] &&
            course.linkedPartners[0].__typename === 'Partner'
              ? course.linkedPartners[0].partnerName
              : '',
          certificateName,
          certificateId,
          unitName: _unitData.name,
          interestName: interest.join(','),
        };
      }
      let unitPages: ICourseUnitFragmentFragment['pages'] = _unitData.pages;
      let nuggetPages: ICourseUnitFragmentFragment['pages'] = [];
      if (_unitData.pages) {
        _unitData.pages.forEach(page => {
          if (page && page.content) {
            if (
              page.content.__typename === 'CourseNugget' &&
              page.content.nuggets
            ) {
              page.content.nuggets.forEach(nugget => {
                if (nugget) {
                  nuggetPages?.push({
                    ...page,
                    content: {
                      __typename: 'CourseNugget',
                      nuggets: [nugget],
                    },
                  });
                }
              });
            } else if (page.content.__typename === 'CoursePage') {
              nuggetPages?.push(page);
            }
          }
        });
        unitPages = nuggetPages;
      }
      _totalStep = (unitPages?.length || 0) + 1;
      _nextLabel =
        step <= 0
          ? t('Start')
          : step < _totalStep - 1
            ? t('Next')
            : t('Finish');
      return {
        unitData: _unitData,
        pages: unitPages,
        totalStep: _totalStep,
        nextLabel: _nextLabel,
        property: _property,
        isTakeAways: _unitData.title?.toLocaleLowerCase().includes('takeaways'),
      };
    }
    return {totalStep: _totalStep, nextLabel: _nextLabel};
  }, [courseUnit, step, t, certificateResponse]);

  const isFinished = progress?.finished || false;
  const currentLanguage = useCurrentLanguage();

  const {handleFetchObtainedJob} = useMemberObtainedJob();
  const {fetchCourseStatus} = useMemberCourseStatus();
  const handlePostProgress = async (finished: boolean) => {
    if (id && member) {
      const noProgress = Object.keys(unitProgression).length === 0;
      if (noProgress) {
        dispatch(
          courseUnitSlice.actions.saveUnitNuggetAnswer({
            unitId: id,
          }),
        );
      }
      const body: IMemberUnitProgressionBody = {
        json: JSON.stringify(unitProgression),
        finished,
        courseId,
        unitId: id,
      };
      //@ts-ignore
      const {data: updateData, error} = await updateUnitProgression({
        culture: currentLanguage,
        data: body,
      });
      const err = error as any;
      if (finished && updateData) {
        handleFetchObtainedJob();
        fetchCourseStatus();
        dispatch(
          memberSlice.actions.addUnitProgression({
            name: id,
            courseId,
          }),
        );
      } else if (err && err.data && err.data.message) {
        toast.error(err.data.message);
      }
    } else {
      console.log('unable to create progression');
    }
  };

  const handleNext = async (courseNugget?: ICourseNuggetFragmentFragment) => {
    // Check if courseNugget and its nuggets exist and have length
    if (courseNugget && courseNugget.nuggets && courseNugget.nuggets.length) {
      // Iterate through each nugget item
      courseNugget.nuggets.forEach(item => {
        if (item && item.id) {
          let _property: ILearnPropertyName | undefined;
          let course: ICourseFragmentFragment | undefined;
          // Check if unitData and its parent exist and parent is of type 'Course'
          if (
            unitData &&
            unitData.parent &&
            unitData.parent.__typename === 'Course'
          ) {
            course = unitData.parent as ICourseFragmentFragment;
          }
          // Check if item is of type 'ChoiceNugget' or 'GapfillNugget'
          if (
            item.__typename === 'ChoiceNugget' ||
            item.__typename === 'GapfillNugget'
          ) {
            // Set _property with relevant data
            _property = {
              courseName: course?.name,
              courseId: course?.id,
              unitName: unitData?.name,
              partnerName:
                course?.linkedPartners &&
                course.linkedPartners[0] &&
                course.linkedPartners[0].__typename === 'Partner'
                  ? course.linkedPartners[0].partnerName
                  : '',
              nuggetReference: item.externalId,
              nuggetType: item.__typename,
              // nuggetPlace: //TODO,
              certificateName: property?.certificateName,
              certificateId: property?.certificateId,
              time: new Date(),
            };
          }
          // If _property is defined, track nuggetFinished event
          if (_property) {
            tracker.learn.nuggetFinished(_property);
          }
        }
      });
    }
    // Check if current step is less than totalStep - 1
    if (step < totalStep - 1) {
      // Update navigation parameters
      navigation.setParams({
        endUrl,
        step: step + 1,
        courseUnitUrls,
        courseId,
      });
    } else {
      // If id and member exist
      if (id && member) {
        try {
          postLoadingRef.current = true;
          // Handle post progress
          await handlePostProgress(true);
          // Check if unitsProgression exists
          if (unitsProgression) {
            // Check if unitData and its parent exist and parent is of type 'Course'
            if (
              unitData &&
              unitData.parent &&
              unitData.parent.__typename === 'Course' &&
              unitData.parent.children &&
              unitData.parent.children.items
            ) {
              // Filter units that are not the current unit
              const units = unitData.parent.children.items.filter(
                item => item?.__typename === 'CourseUnit' && item.id !== id,
              );
              // Check if all units are finished
              if (units.length) {
                const isAllUnitsfinished = units.every(item => {
                  const unit = unitsProgression.find(
                    u =>
                      u &&
                      item &&
                      item.__typename === 'CourseUnit' &&
                      u.name === item.id,
                  );
                  return unit !== undefined;
                });
                // If all units are finished, track allUnitsFinished event
                if (isAllUnitsfinished && property) {
                  const {
                    courseName,
                    courseId: courseIdProperty,
                    partnerName,
                    interestName,
                    certificateId,
                    certificateName,
                  } = property;
                  tracker.learn.allUnitsFinished({
                    courseName,
                    courseId: courseIdProperty,
                    partnerName,
                    interestName,
                    certificateId,
                    certificateName,
                  });
                }
              }
            }
          }
          // If data exists, show snackbar notification
          if (courseUnit) {
            snackbar.add({
              title: t('Congratulation'),
              message: t('You have completed the unit'),
              duration: 4000,
            });
          }
          // Track unitFinished event
          if (property) {
            const endTime = dayjs().diff(startTime.current, 'second');
            tracker.learn.unitFinished({...property, unitTimestamp: endTime});
          }
        } catch (error) {
          // Show error snackbar notification
          snackbar.add({
            title: t('Error'),
            message: t('Error on saving'),
            duration: 8000,
          });
        } finally {
          // Navigate to next course unit or go back to course
          if (courseUnitUrls[currentIndex + 1]) {
            navigation.navigate(AppRoutesEnum.COURSE_UNIT, {
              state: {
                params: {
                  endUrl: getContentEndUrl(
                    courseUnitUrls[currentIndex + 1],
                    'CourseUnit',
                  ),
                  step: 0,
                  courseUnitUrls,
                  courseId,
                  currentIndex: currentIndex + 1,
                },
              },
            });
            setTimeout(() => (postLoadingRef.current = false), 1500);
          } else {
            navigation.navigate(AppRoutesEnum.COURSE_SINGLE, {
              state: {
                params: {
                  id: courseId,
                  url: courseUrl || '',
                  type: 'url',
                  __typename: 'Course',
                },
              },
            });
            // navigation.goBack();
            setTimeout(() => (postLoadingRef.current = false), 1500);
          }
        }
      } else {
        console.log('Error during update');
      }
    }
  };

  useEffect(() => {
    if (property) {
      tracker.learn.unitStarted(property);
    }
  }, [property]);

  const handleClose = async () => {
    const hasProgression = Object.keys(unitProgression).length !== 0;
    if (!postLoadingRef.current) {
      postLoadingRef.current = true;
      if (hasProgression) {
        handlePostProgress(isFinished);
      }
      navigation.goBack();
      setTimeout(() => (postLoadingRef.current = false), 1500);
    }
  };

  useEffect(() => {
    const handleGoBack = () => {
      if (step === 0) {
        handleClose();
      } else {
        if (!postLoadingRef.current) {
          // back to previous step
          navigation.setParams({
            endUrl,
            step: Math.max(step - 1, 0),
            courseUnitUrls,
            courseId,
          });
        }
      }
    };

    dispatch(
      appSlice.actions.setModalHeader(
        <CustomModalHeader
          title={
            <Typography variant="h4" color="white">
              {t('Step')}{' '}
              <Typography variant="h4" color="primary">
                {t('{{current}} of {{total}}', {
                  current: step + 1,
                  total: totalStep,
                })}
              </Typography>
            </Typography>
          }
          onGoBack={step !== totalStep ? handleGoBack : undefined}
          onClose={() => goBackInBackground?.()}
        />,
      ),
    );

    return () => {
      dispatch(appSlice.actions.setModalHeader(null));
    };
  }, [step, totalStep]);

  if (pageLoading) {
    return <HomeContentLoader />;
  }
  if (!unitData) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ContentEmpty title={t('Unit not found')} />
      </View>
    );
  }

  if (step === 0) {
    return (
      <UnitScreenLoaderContainer loading={backLoading}>
        <UnitOverView onNext={handleNext} unit={unitData} />
        <DebugUmbracoId umbracoId={unitData?.id} position="page" />
      </UnitScreenLoaderContainer>
    );
  }
  if (pages && pages[step - 1]) {
    const stepContent = pages[step - 1];
    if (stepContent && stepContent.content) {
      if (stepContent.content.__typename === 'CoursePage') {
        return (
          <UnitScreenLoaderContainer loading={backLoading}>
            <TestScreenContainer
              buttonTitle={nextLabel}
              onPressButton={handleNext}
              loading={postLoading || postLoadingRef.current}>
              <CoursePage
                content={stepContent.content}
                learnProperty={property}
                isConclusion={!isTakeAways && step === totalStep - 1}
              />
            </TestScreenContainer>
            <DebugUmbracoId umbracoId={unitData?.id} position="page" />
          </UnitScreenLoaderContainer>
        );
      } else if (stepContent.content.__typename === 'CourseNugget') {
        const nugget =
          stepContent.content.nuggets &&
          stepContent.content.nuggets[0] &&
          stepContent.content.nuggets[0]
            ? stepContent.content.nuggets[0]
            : undefined;
        let nuggetId = nugget?.id;
        let disabled: boolean = true;
        if (nuggetId && unitProgression[nuggetId] && isCorrect) {
          disabled = false;
        }
        return (
          <UnitScreenLoaderContainer loading={backLoading}>
            <TestScreenContainer
              buttonTitle={nextLabel}
              buttonDisabled={disabled}
              isNugget={
                nugget?.__typename === 'ChoiceNugget' ||
                nugget?.__typename === 'SumaryNugget'
              }
              onPressButton={() => {
                handleNext(
                  stepContent.content as ICourseNuggetFragmentFragment,
                );
              }}
              loading={postLoading || postLoadingRef.current}>
              <CourseNugget
                content={stepContent.content}
                property={property}
                showResult={showResult}
                unitId={id}
                onChange={value => {
                  setIsCorrect(value);
                }}
              />
            </TestScreenContainer>
            <DebugUmbracoId umbracoId={unitData?.id} position="page" />
          </UnitScreenLoaderContainer>
        );
      }
    }
  }

  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ContentEmpty title={t('Unit page not found')} />
    </View>
  );
};

export default UnitScreen;
