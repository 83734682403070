import React, {ReactNode} from 'react';
import {Pressable, PressableProps} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Typography from '../../Typography/Typography';
import CardIcon from '../CardIcon/CardIcon';
import {cardIconTextStyles} from './CardIconText.styles';

export interface CardIconTextProps extends PressableProps {
  icon: IconName | (() => ReactNode);
  text: string;
}

const CardIconText = (props: CardIconTextProps) => {
  const {icon, text, ...restProps} = props;

  return (
    <Pressable {...restProps} style={cardIconTextStyles.container}>
      <CardIcon icon={icon} />
      <Typography
        children={text}
        style={cardIconTextStyles.text}
        variant="body2"
      />
    </Pressable>
  );
};

export default CardIconText;
