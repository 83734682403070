import React, {FC, useEffect} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import SignInForm from './SignInForm/SignInForm';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import SocialNetworkAuthentication from '../SocialNetworkAuthentication/SocialNetworkAuthentication';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {useDispatch} from 'react-redux';
import useNavigation from '@src/Navigation/useNavigation';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useAppSelector} from '@src/Store/hooks';
import useEvent from 'design-system/src/Hooks/useEvent';

const SignIn: FC = () => {
  const {t} = useTranslation('auth');
  const {isMobile} = useBreakpoint();
  const dispatch = useDispatch();
  const rootNavigation = useNavigation();
  const currentModal = useAppSelector(
    state => state.appAuth.openAuthenticationModal,
  );

  const openModal = useEvent(() => {
    if (currentModal?.open) return;
    dispatch(
      appAuthSlice.actions.openAuthenticationModal({
        open: true,
        redirect: currentModal?.redirect || '/app',
      }),
    );
  });

  useEffect(() => {
    openModal();
  }, []);

  const handleSuccess = () => {
    if (
      typeof window !== 'undefined' &&
      !window.location.pathname.includes('/tv-auth') &&
      !window.location.pathname.includes('/subscription')
    ) {
      setTimeout(() => {
        rootNavigation.navigate('/app', {
          replace: true,
        });
      }, 500);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 418,
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}>
      <Typography
        variant={isMobile ? 'body2' : 'formTitle'}
        style={{
          textTransform: 'uppercase',
          // fontSize: 48,
          marginBottom: 25,
        }}>
        {t('Sign in')}
      </Typography>
      <SocialNetworkAuthentication isSignIn={true} onSuccess={handleSuccess} />
      <Typography variant="body2" style={{marginBottom: 25, marginTop: 25}}>
        {t('Or')}
      </Typography>
      <SignInForm onSuccess={handleSuccess} />
    </div>
  );
};

export default SignIn;
