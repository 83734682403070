import React, {ReactNode, useCallback} from 'react';
import {
  IArtistService,
  IArtistServiceFragmentFragment,
  IArtistServicesPageFragmentFragment,
  IButtonFragmentFragment,
  IContentLink,
  ICtaSlideFragmentFragment,
  IFaqItemFragmentFragment,
  IHeroVideoCoverFragmentFragment,
  IIconSlideFragmentFragment,
  IKeyFigureFragmentFragment,
  ILogoLink,
  ILogoSliderFragmentFragment,
  ITextSlide1FragmentFragment,
  ITitleSliderFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import StatisticInfo from 'design-system/src/Components/StatisticInfo/StatisticInfo';
import Statistic from 'design-system/src/Components/Statistic/Statistic';
import ServicePromote, {
  IBlockItemTitleSlider,
  IBlockTitleSlider,
} from 'design-system/src/Components/ServicePromote/ServicePromote.web';
import ServiceCarouselNumber from 'design-system/src/Components/ServiceCarouselNumber/ServiceCarouselNumber.web';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';

import ServiceFaq, {
  IServiceFaItem,
} from 'design-system/src/Components/ServiceFaq/ServiceFaq.web';

import ServiceChannel, {
  IItemServiceChannel,
} from 'design-system/src/Components/ServiceChannel/ServiceChannel.web';

import Metrics from 'design-system/src/Theme/Metrics';
import {View} from 'react-native';
import {HeroSliderData} from '~Umbraco/Utils/content/mapHeroSlide';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {IButtonProps} from 'design-system/src/Components/Button/Button';
import {RouteTypename} from '~Navigation/type';
import {useStringExtension} from '~Hooks/useStringExtension';
import {useDispatch} from 'react-redux';
import { useI18next } from 'gatsby-plugin-react-i18next';
import VideoBlock from 'design-system/src/Components/VideoBlock/VideoBlock.web';
import ContactBlock from 'design-system/src/Components/ContactBlock/ContactBlock.web';
import useGetResponsiveCover from './useGetResponsiveCover';

// TODO: check duplicted title variable

interface IButton {
  url?: string;
  id?: string;
  buttonProps?: IButtonProps;
  __typename: string;
}

const mapButton = (buttonContent: IButtonFragmentFragment): IButton => {
  let url;
  let id;
  let __typename = 'Unknown';
  const buttonProps: HeroSliderData['button'] | undefined = {
    variant:
      (buttonContent.variant as NonNullable<
        HeroSliderData['button']
      >['variant']) || 'outlined',
    icon: (buttonContent.icon || '').toLowerCase() as NonNullable<
      HeroSliderData['button']
    >['icon'],
    size:
      (buttonContent.size as NonNullable<HeroSliderData['button']>['size']) ||
      'small',
    title: buttonContent.title || '',
  };

  if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    (buttonContent.link_to[0].content.__typename === 'InternalLink' ||
      buttonContent.link_to[0].content.__typename === 'ContentLink')
  ) {
    url = buttonContent.link_to[0].content.link?.url || '';
    id = buttonContent.link_to[0].content.link?.id || '';
    __typename =
      buttonContent.link_to[0]?.content.link?.__typename || 'Unknown';
  }
  return {
    buttonProps: {
      ...buttonProps,
      title: buttonProps.title || '',
    },
    url,
    id: id || '',
    __typename,
  };
};

const useArtistServicesPageUtils = () => {
  const navigation = useI18next()
  const {formatFromHtml} = useStringExtension();
  const dispatch = useDispatch();
  const {getResponsiveImage} = useGetResponsiveCover();
  const handleButtonHeader = async (item: IButton | undefined) => {
    if (item) {
      console.log('typename =>', item.__typename);
      const {id, __typename, url} = item;
      if (item.__typename === 'FormPage') {
        navigation.navigate('ServiceForm', {
          id: item.id,
          __typename: item.__typename as RouteTypename,
          type: 'url',
        } as any);
      } else {
        new RouteFactory(
          {
            id: id || '',
            __typename: __typename as RouteTypename,
            url: url || '',
          },
          navigation,
          dispatch,
        ).navigate();
      }
    }
  };

  const handleClickLogoSliderItem = (
    item: IItemServiceChannel & {
      __typename: RouteTypename;
      url: string;
      id: string;
    },
  ) => {
    const {url, id, __typename} = item;
    const factory = new RouteFactory(
      {url, id, __typename},
      navigation,
      dispatch,
    );
    factory.navigate();
  };

  const handleClickButtonItem = (item: IBlockItemTitleSlider) => {
    if (item && item.button) {
      if (item.button.__typename === 'FormPage') {
        navigation.navigate('ServiceForm', {
          id: item.button.id,
          __typename: item.button.__typename as RouteTypename,
          type: 'url',
        } as any);
      } else {
        new RouteFactory(
          {
            id: item.button.id || '',
            __typename: item.button.__typename as RouteTypename,
            url: item.button.url || '',
          },
          navigation,
          dispatch,
        ).navigate();
      }
    }
  };
 
  const renderPage = (
    artistServicesPage:
      | IArtistServicesPageFragmentFragment
      | IArtistServiceFragmentFragment,
  ) => {
    const {
      pageBlocks: pageBlocksData,
      title,
      subtitle,
      cover,
      button,
    } = artistServicesPage;

    const pageBlocks: ReactNode[] = [];
    let buttonData: IButton | undefined;

    if (button && button[0] && button[0].content.__typename === 'Button') {
      let buttonContent = button[0].content as IButtonFragmentFragment;
      buttonData = mapButton(buttonContent);
    }

    pageBlocks.push(
      <ServiceHeader
        key={'header'}
        title={title ?? '-'}
        image={{
          uri:
            cover &&
            cover[0] &&
            cover[0].content.__typename === 'HeroVideoCover'
              ? getResponsiveImage(cover[0].content) || ''
              : '',
        }}
        description={subtitle ?? '-'}
        buttonTitle={buttonData?.buttonProps?.title ?? '-'}
        buttonProps={{
          ...buttonData?.buttonProps,
          variant: "contained",
          size: 'small'
        }}
        onPressButton={() => {
          handleButtonHeader(buttonData);
        }}
      />,
    );

    pageBlocksData?.forEach((block, blockIndex) => {
      if (block) {
        if (block.content.__typename === 'Wysiwyg') {
          const {title: _title, body} = block.content;
          pageBlocks.push(
            <StatisticInfo
              key={blockIndex}
              title={_title ?? '-'}
              description={formatFromHtml(body)}
            />,
          );
        } else if (block.content.__typename === 'KeyFigures') {
          const {list} = block.content;
          if (list && list[0] && list[0].content.__typename === 'KeyFigure') {
            pageBlocks.push(
              <View
                key={blockIndex}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  paddingHorizontal: Metrics.horizontalScale(29),
                }}
                >
                {list.map((d, index) => {
                  let {figure, description} = d!
                    .content as IKeyFigureFragmentFragment;
                  return (
                    <View
                      key={`st-${index}`}
                      style={{
                        maxWidth: 149,
                        width: Metrics.horizontalScale(375 / 2),
                        marginBottom: 35,
                      }}>
                      <Statistic
                        value={figure ?? '-'}
                        label={description ?? '-'}
                      />
                    </View>
                  );
                })}
              </View>,
            );
          }
        } else if (block.content.__typename === 'MultiSlider') {
          const {title: _title, description, sliders} = block.content;
          if (sliders && sliders[0]) {
            let itemSliders: Array<IBlockTitleSlider> = [];
            sliders.forEach(blockSlider => {
              if (
                blockSlider &&
                blockSlider.content.__typename === 'TitleSlider'
              ) {
                const {slides, title: contentTitle} =
                  blockSlider.content as ITitleSliderFragmentFragment;
                let itemSlider: IBlockTitleSlider = {
                  slideTitle: contentTitle ?? '-',
                  slides: [],
                };
                if (slides && slides[0]) {
                  if (slides[0].content.__typename === 'TextSlide1') {
                    slides.forEach(itemSlide => {
                      let item =
                        itemSlide?.content as ITextSlide1FragmentFragment;
                      let _cover: IHeroVideoCoverFragmentFragment | undefined;
                      if (item.cover && item.cover[0]) {
                        _cover = item.cover[0]
                          .content as IHeroVideoCoverFragmentFragment;
                      }
                      itemSlider.slides.push({
                        title: item.title ?? '-',
                        description: item.description ?? '-',
                        overTitle: item.overtitle ?? '-',
                        image: {
                          uri: _cover ? getResponsiveImage(_cover) : '',
                        },
                      });
                    });
                  } else if (slides[0].content.__typename === 'IconSlide') {
                    slides.forEach(itemSlide => {
                      let item =
                        itemSlide?.content as IIconSlideFragmentFragment;
                      let _cover: IHeroVideoCoverFragmentFragment | undefined;
                      if (item.cover && item.cover[0]) {
                        _cover = item.cover[0]
                          .content as IHeroVideoCoverFragmentFragment;
                      }
                      itemSlider.slides.push({
                        title: item.title ?? '-',
                        overTitle: item.overtitle ?? '-',
                        image: {
                          uri: _cover ? getResponsiveImage(_cover) : '',
                        },
                        icon: {
                          uri: item.icon?.url,
                        },
                      });
                    });
                  } else if (slides[0].content.__typename === 'CTASlide') {
                    slides.forEach(itemSlide => {
                      let item =
                        itemSlide?.content as ICtaSlideFragmentFragment;
                      let _cover: IHeroVideoCoverFragmentFragment | undefined;
                      if (item.cover && item.cover[0]) {
                        _cover = item.cover[0]
                          .content as IHeroVideoCoverFragmentFragment;
                      }
                      let _button;
                      if (item.button && item.button[0]) {
                        _button = mapButton(
                          item.button[0].content as IButtonFragmentFragment,
                        );
                      }
                      itemSlider.slides.push({
                        title: item.title ?? '-',
                        description: item.description,
                        image: {
                          uri: _cover ? getResponsiveImage(_cover) : '',
                        },
                        // TODO:
                        button: _button as any,
                      });
                    });
                  }
                }
                itemSliders.push(itemSlider);
              }
            });
            if (itemSliders.length > 0) {
              if (block.content.template !== 'numbers') {
                pageBlocks.push(
                  <ServicePromote
                    key={`${blockIndex}`}
                    title={_title ?? '-'}
                    items={itemSliders}
                    onButtonItemPress={handleClickButtonItem}
                  />,
                );
              } else {
                // TODO: Carousel
                pageBlocks.push(
                  <ServiceCarouselNumber
                    key={`${blockIndex}`}
                    title={_title ?? '-'}
                    items={itemSliders}
                    description={description ?? '-'}
                  />,
                );
              }
            }
          }
        } else if (block.content.__typename === 'LogoSlider') {
          let {slides, title: _title} =
            block.content as ILogoSliderFragmentFragment;
          let listChannel: Array<
            IItemServiceChannel & {
              __typename: RouteTypename;
              id: string;
              url: string;
            }
          > = [];
          if (
            slides &&
            slides[0] &&
            slides[0].content.__typename === 'LogoLink'
          ) {
            slides.map(item => {
              let content = item?.content as ILogoLink;
              let contentLink =
                content.link_to &&
                content.link_to[0] &&
                (content.link_to[0]?.content as IContentLink);
              listChannel.push({
                logo: {
                  uri: content.logo?.url,
                },
                url: contentLink?.link?.url || '',
                __typename: content.__typename!,
                id: contentLink?.link?.id || 'ExternalLink',
              }); 
            });
          }
          if (listChannel.length > 0) {
            pageBlocks.push(
              <ServiceChannel
                key={blockIndex}
                title={_title ?? '-'}
                items={listChannel}
                onItemPress={item => {
                  handleClickLogoSliderItem(item);
                }}
              />,
            );
          }
        } else if (block.content.__typename === 'Accordions') {
          const {title: _title, accordions} = block.content;
          if (
            accordions && accordions[0] && accordions[0].content.__typename === 'FaqItem'
          ) {
            let data: Array<IServiceFaItem> = accordions.map(accordion => {
              let {title: accordionTitle, htmlContent} =
                accordion?.content as IFaqItemFragmentFragment;
              return {
                title: accordionTitle ?? '-',
                description: formatFromHtml(htmlContent),
              };
            });
            pageBlocks.push(
              <ServiceFaq key={blockIndex} title={_title ?? '-'} data={data} />,
            );
          }
        }

        
      }
    });

    // TODO: add video block
    pageBlocks.push(
      <VideoBlock 
        key="vidoe"
        src="https://player.vimeo.com/external/807353098.m3u8?s=4740018836dd52d87cafc27d5d05db1cb2a921d5&oauth2_token_id=1702559564" 
        controls={true}
        width="100%"
        height="auto"
        poster='https://media.umbraco.io/dev-trace-back-office/zarfxsjq/cover_image8.png'
        controlsList="play,pause"
      />,
    );

    pageBlocks.push(
      <ContactBlock 
      key='contact'
      title="SUPPORT US AND BECOME A SPONSOR"
      description='In publishing and graphic design, lorem ipsum is a place order text commonly used to'
      buttonTitle='contact us'
      image={{
        uri: 'https://media.umbraco.io/dev-trace-back-office/zarfxsjq/cover_image8.png'
      }}
       />
    )

    return pageBlocks;
  };

  return {renderPage};
};

export default useArtistServicesPageUtils;
