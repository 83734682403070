import React from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import Typography from '../../Typography/Typography';
import {ITypographyProps} from '../../Typography/Typography.props';
import {genericPremiumBodyStyles} from './GenericPremiumBody.styles';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

export type GenericPremiumCardProps = {
  title: string;
  description: string;
  leadingCard: React.ReactNode;
  trailing?: React.ReactNode;
  isAlignCenter?: boolean;
  descriptionColor?: string;
  descTypo?: ITypographyProps['variant'];
  subTitle?: string;
  style?: StyleProp<ViewStyle>;
  descriptionLine?: number;
};

const GenericPremiumBody = (props: GenericPremiumCardProps) => {
  const {
    title,
    description,
    descriptionColor = defaultTheme.primaryInfo,
    leadingCard,
    trailing,
    isAlignCenter = true,
    descTypo = 'body3',
    subTitle,
    style,
    descriptionLine,
  } = props;
  const {isMobile} = useBreakpoint();
  return (
    <View style={[genericPremiumBodyStyles.body, style]}>
      {leadingCard}
      <View
        style={{
          ...genericPremiumBodyStyles.aside,
          ...(isAlignCenter && {alignItems: 'center'}),
        }}>
        <View
          style={{paddingRight: Metrics.horizontalScale(10), flexShrink: 1}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Typography
              variant={isMobile ? 'body2' : 'body1'}
              color={'white'}
              style={genericPremiumBodyStyles.title}>
              {title}
            </Typography>
            {subTitle !== undefined && (
              <Typography
                variant={isMobile ? 'ui3' : 'body2'}
                color="gray"
                style={{marginLeft: Metrics.horizontalScale(8), flexShrink: 1}}>
                {subTitle}
              </Typography>
            )}
          </View>
          <Typography
            numberOfLines={descriptionLine}
            variant={descTypo}
            color={() => descriptionColor}
            style={genericPremiumBodyStyles.description}>
            {description}
          </Typography>
        </View>
        <View style={{height: '100%'}}>{trailing}</View>
      </View>
    </View>
  );
};

export default GenericPremiumBody;
