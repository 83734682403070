/* eslint-disable react-native/no-inline-styles */
import {Pressable, View, ViewStyle, StyleProp, Platform} from 'react-native';
import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {CarouselCardStyles} from './CarouselCard.styles';
import Metrics from '../../../Theme/Metrics';
import {CardSize, selectSize} from '../../../tools/CardSizeTools';
import CustomFastImage from '../../CustomFastImage/CustomFastImage.web';
import CustomProgressBar from '../../CustomProgressBar/CustomProgressBar';
import useDefaultTheme from '../../../Theme/useTheme';
import Tags, {TagsProps} from '../../Tags/Tags';
import CarouselCardInfo from './CarouselCardInfo/CarouselCardInfo';
import IconWithText from '../../IconWithText/IconWithText';
import {IconName} from '../../../assets/Icones';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import {LeadingTopCardProps} from '../LeadingTopCard/LeadingTopCard';
import {VideoProperties} from 'react-native-video';
import TopTenCard from '../VodCard/TopTenVodCard/TopTenCard/TopTenCard.web';
import defaultTheme from '../../../Theme/defaultTheme';
import Typography from '../../Typography/Typography';
import PremiumIcon from '../PremiumIcon';
import usePressableHover from '../../PressableCustom/usePressableHover';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';

export type BasicMediaCover =
  | {
      uri: CustomFastImageProps['source'];
      type: 'image';
      isMobile?: boolean;
      hasDesktop?: boolean;
      poster?: CustomFastImageProps['source'];
    }
  | {
      uri: VideoProperties['source'];
      poster?: CustomFastImageProps['source'];
      type: 'video';
    };

export type IMediaCover =
  | BasicMediaCover
  | {
      request: () => Promise<BasicMediaCover>;
      poster?: CustomFastImageProps['source'];
      type: 'delay';
    };

export type ExtratContent =
  | {
      type: 'live';
      date: {
        start: string;
        end: string;
      };
    }
  | {
      type: 'date';
      date: string;
    }
  | {
      type: 'custom';
      icon: IconName;
      text: string;
    };

export interface ICarouselCardProps<T extends any = any> {
  title?: string;
  number?: number;
  subtitle?: string;
  onPress?: () => void;
  size?: CardSize;
  mediaCover?: IMediaCover;
  backgroundColor?: string;
  cover?: CustomFastImageProps['source'];
  progress?: number;
  tag?: TagsProps;
  logo?: ReactNode;
  overlayContent?: ReactNode;
  children?: ReactNode[];
  extraContent?: ExtratContent[];
  data?: T;
  top10Size?: LeadingTopCardProps['size'];
  isVertical?: boolean;
  isFreemium?: boolean;
  premiumText: string;
  hideTitle?: boolean;
  hideSubtitle?: boolean;
  date?: string;
  style?: StyleProp<ViewStyle>;
  forcedWidth?: number;
  disableHover?: boolean;
  fullWidth?: boolean;
}

export const renderExtraContent = (
  extraContent?: ExtratContent[],
): ReactNode[] => {
  return (
    extraContent?.map((extra, index) => {
      switch (extra.type) {
        case 'live':
          return (
            <IconWithText
              key={index}
              text={extra.date.start + '  •  ' + extra.date.end}
              icon="clock"
              size="16"
              color="gray"
            />
          );
        case 'date':
          return (
            <IconWithText
              key={index}
              text={extra.date}
              size="16"
              icon="date"
              color="gray"
            />
          );
        case 'custom':
          return (
            <IconWithText
              key={index}
              text={extra.text}
              size="16"
              icon={extra.icon}
              color="gray"
            />
          );

        default:
          return null;
      }
    }) || []
  );
};

const hoverStyle: ViewStyle = {
  transform: [{scale: 1.2}],
  zIndex: 8,
  ...(Platform.OS === 'web' && {
    // background: defaultTheme.page.background,
    transition: 'transform 0.2s',
    boxSizing: 'content-box',
    // border: `4px solid ${defaultTheme.page.background}`,
    position: 'absolute',
  }),
  borderRadius: 8,
};

const getWebSize = (width: number, isMobile?: boolean) => {
  const base = isMobile ? 375 : 1440;
  return width * (window.innerWidth / base);
};

const CarouselCard = <T extends any = any>(
  props: ICarouselCardProps<T>,
): React.ReactElement | null => {
  const {
    title,
    subtitle,
    progress,
    overlayContent,
    cover,
    size,
    tag,
    logo,
    extraContent,
    children,
    number,
    isVertical = true,
    backgroundColor,
    isFreemium,
    premiumText,
    hideTitle,
    hideSubtitle,
    date,
    style,
    forcedWidth,
    onPress,
    disableHover,
    fullWidth,
  } = props;

  const theme = useDefaultTheme();
  const {mobile} = useBreakpoint();
  const {hover, onHoverIn, onHoverOut} = usePressableHover();

  const cardSize = selectSize({type: size === 'top10' ? 'small' : size});
  const defaultWidth: number =
    forcedWidth || size === 'top10'
      ? Metrics.horizontalScale(223)
      : Metrics.horizontalScale(cardSize.width);

  const [width, setWidth] = useState(defaultWidth);

  const hasProgress: boolean = typeof progress !== 'undefined';
  const aspectRatio = size === 'news' ? 4 / 5 : 16 / 9;

  // size
  const height: number = size === 'news' ? width / (4 / 5) : width / (16 / 9);

  const os = Platform.OS;
  useEffect(() => {
    if (os === 'web') {
      const handler = () => {
        const newWidth = forcedWidth || getWebSize(defaultWidth, mobile);
        if (width !== newWidth) {
          setWidth(newWidth);
        }
      };

      handler();

      window.addEventListener('resize', handler);

      return () => {
        window.removeEventListener('resize', handler);
      };
    }
  }, [forcedWidth, width, mobile, defaultWidth, os]);

  const extraContentRendered: ReactNode[] = useMemo(() => {
    return renderExtraContent(extraContent);
  }, [extraContent]);
  const isTopTen = size === 'top10';

  const widthStyle = fullWidth ? '100%' : width;
  const heightStyle = fullWidth ? '100%' : height;

  const renderCarouselCard = (isBase?: boolean): ReactNode => {
    return (
      <>
        <Pressable
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          style={[
            {
              width: isTopTen ? '100%' : widthStyle,
              height: isTopTen ? heightStyle : undefined,
              ...((!disableHover && hover && !isBase && hoverStyle) || {}),
            },
            style,
          ]}
          onPress={onPress}>
          <View
            style={{
              marginBottom: isVertical
                ? Metrics.verticalScale(title ? 9 : 0)
                : undefined,
            }}>
            {!isTopTen ? (
              <View
                style={[
                  {
                    width: widthStyle,
                    height: heightStyle,
                    aspectRatio,
                    backgroundColor: backgroundColor || theme.gray,
                    // @ts-ignore
                    boxShadow:
                      !disableHover && hover && !isBase
                        ? '0px 0px 20px 3px rgba(0, 0, 0, 0.8)'
                        : undefined,
                    flexDirection: 'row',
                  },
                  CarouselCardStyles.radius,
                ]}>
                {Boolean(cover) && (
                  <View
                    style={[
                      CarouselCardStyles.coverWrapper,
                      {
                        width: widthStyle,
                        height: heightStyle,
                        aspectRatio,
                      },
                    ]}>
                    <CustomFastImage
                      style={[CarouselCardStyles.coverImage, {aspectRatio}]}
                      width={widthStyle}
                      height={heightStyle}
                      source={cover}
                    />
                  </View>
                )}
                <View
                  style={[
                    CarouselCardStyles.cover,
                    {
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: widthStyle,
                      height: heightStyle,
                      padding: Metrics.horizontalScale(9),
                      paddingBottom: hasProgress
                        ? Metrics.horizontalScale(4)
                        : undefined,
                      zIndex: 4,
                    },
                  ]}>
                  <View style={CarouselCardStyles.header}>
                    <View>
                      {!!tag ? (
                        <Tags
                          {...tag}
                          style={{
                            marginBottom: Metrics.verticalScale(5),
                          }}
                        />
                      ) : (
                        <View />
                      )}
                    </View>
                    {Boolean(logo) ?? logo}
                  </View>

                  {/* Progress bar */}
                  {typeof progress !== 'undefined' ? (
                    <View style={{height: 'auto'}}>
                      <CustomProgressBar progress={progress} />
                    </View>
                  ) : (
                    <View />
                  )}
                </View>
                {!!overlayContent && (
                  <View
                    style={{
                      ...CarouselCardStyles.center,
                      width: widthStyle,
                      height: heightStyle,
                    }}>
                    <View
                      style={{
                        width: size === 'channel' ? width * 0.39 : '100%',
                        height: size === 'channel' ? undefined : '100%',
                        aspectRatio: size === 'channel' ? 120 / 90 : undefined,
                        ...CarouselCardStyles.center,
                      }}>
                      {overlayContent}
                    </View>
                  </View>
                )}
              </View>
            ) : (
              <View
                style={[
                  CarouselCardStyles.topTenWrapper,
                  {
                    opacity: hover && isBase ? 0 : 1,
                  },
                ]}>
                <TopTenCard
                  size="big"
                  forceHeight={heightStyle}
                  forceWidth={widthStyle}
                  number={typeof number !== 'undefined' ? number + '' : ''}
                  source={cover}
                  premiumText={
                    isFreemium !== undefined && !isFreemium
                      ? premiumText
                      : undefined
                  }
                  isHover={!disableHover && hover}
                />
              </View>
            )}

            {/* premium icon */}
            {!isTopTen && (
              <View
                style={{
                  width: widthStyle,
                  height: heightStyle,
                  position: 'absolute',
                }}>
                <View
                  style={{
                    position: 'absolute',
                    bottom: Metrics.horizontalScale(9),
                    right: Metrics.horizontalScale(9),
                  }}>
                  {isFreemium !== undefined && !isFreemium && <PremiumIcon />}
                </View>
              </View>
            )}
          </View>

          {/* subtitle */}
          {!isBase &&
            !hideSubtitle &&
            size === 'channel' &&
            Boolean(subtitle) && (
              <Typography
                variant="ui3"
                color={() => defaultTheme.gray}
                numberOfLines={2}
                style={{
                  color: defaultTheme.gray,
                }}>
                {subtitle}
              </Typography>
            )}

          {/* title & info*/}
          {!isBase && !!title && size !== 'channel' && (
            <CarouselCardInfo
              hideTitle={hideTitle}
              hideSubtitle={hideSubtitle}
              title={title}
              subtitle={subtitle}>
              {!!date && (
                <Typography
                  variant="h4"
                  color={() => defaultTheme.primary}
                  numberOfLines={1}
                  style={{
                    color: defaultTheme.primary,
                  }}>
                  {date}
                </Typography>
              )}
              {extraContentRendered}
              {children}
            </CarouselCardInfo>
          )}
        </Pressable>
        {!disableHover && hover && !isBase && renderCarouselCard(true)}
      </>
    );
  };
  return renderCarouselCard() as React.ReactElement | null;
};

export default React.memo(CarouselCard);
