import {ICardLinkFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {CardLinkItem, ITipsCardProps} from './components/TipsCard/TipsCard';
import mapButton from '~Umbraco/Utils/element/mapButton';
import {RouteTypename} from '~Navigation/type';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {useAppDispatch} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';

export const useMapCardLink = () => {
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();

  const mapCardLink = (cardLink: ICardLinkFragmentFragment): ITipsCardProps => {
    const {title, description, image, links} = cardLink;
    let _cardLinkItem: CardLinkItem[] = [];
    if (links) {
      links.forEach(link => {
        if (link && link.content && link.content.__typename === 'Button') {
          const button = mapButton(link.content);
          if (button) {
            _cardLinkItem.push({
              title: button?.title,
              onPress: () => {
                if (button.linkTo) {
                  const {url, name, __typename, id} = button.linkTo;
                  new RouteFactory(
                    {
                      id: id || '',
                      __typename: __typename as RouteTypename,
                      url: url || '',
                    },
                    navigation,
                    dispatch,
                  ).navigate();
                }
              },
            });
          }
        }
      });
    }
    return {
      title: title || '',
      cover: image?.url,
      description: description || '',
      cardLinks: _cardLinkItem,
    };
  };

  return {mapCardLink};
};
