import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import Select, {ISelectOption, ISelectProps} from './Select.web';

export interface SelectControllerProps<TField extends FieldValues = FieldValues>
  extends Omit<ISelectProps<string>, 'value' | 'onChange'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
}

const SelectController = <TField extends FieldValues = FieldValues>(
  props: SelectControllerProps<TField>,
) => {
  const {control, defaultValue, name, rules, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });
  const {field: fieldSecondValue} = useController<TField>({
    control,
    defaultValue,
    name: (name + 'Value') as Path<TField>,
  });

  const handleChange = (value: string, item: ISelectOption) => {
    field.onChange(item.value);
    fieldSecondValue.onChange(item.label);
  };

  return <Select value={field.value} onChange={handleChange} {...rest} />;
};

export default SelectController;
