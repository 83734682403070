import React, {useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import {carouselHeaderStyles} from '../Header/CarouselHeader/CarouselHeader.styles';
import Icon, {IIconProps} from '../Icon/Icon';
import Typography from '../Typography/Typography';
import AcqCategoryWidget from '../../WebComponent/AcqCategoryWidget/AcqCategoryWidget.web';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

export type CarouselWithSpaceData = {
  image: string;
  overTitle: string;
  title: string;
  description?: string;
  icon?: IIconProps['name'];
  onPress?: () => void;
};

export type CarouselWithSpaceProps = {
  data: CarouselWithSpaceData[];
  overlayBg?: string;
  autoLoop?: boolean;
  interval?: number;
  radius?: number;
};

export default function CarouselWithSpace(props: CarouselWithSpaceProps) {
  const {data, overlayBg, radius} = props;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const {isMobile} = useBreakpoint();
  const itemWidth = isMobile ? 287 : 429;
  const itemHeight = isMobile ? 328 : 490;
  const itemWidthMin = isMobile ? 224 : 321;
  const itemHeightMin = isMobile ? 256 : 366;

  return (
    <View
      style={{overflow: 'hidden', marginBottom: Metrics.horizontalScale(49)}}>
      <AcqCategoryWidget
        list={data}
        currentIndex={selectedIndex}
        onChangeIndex={setSelectedIndex}
        getBackground={item => ({uri: item.image})}
        renderItem={(item, index, currentHover) => {
          const {image, title, overTitle, description, icon, onPress} = item;
          const isCurrentHover = currentHover === index;
          return (
            <TouchableOpacity
              onPress={onPress}
              key={index}
              style={{
                flex: 1,
                alignItems: 'center',
                position: 'relative',
                height: !isCurrentHover ? itemHeightMin : itemHeight,
                width: !isCurrentHover ? itemWidthMin : itemWidth,
              }}>
              <View
                style={{
                  position: 'absolute',
                  borderRadius: Metrics.horizontalScale(radius || 4),
                  overflow: 'hidden',
                }}>
                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: overlayBg || 'rgba(0, 0, 0, 0.4)',
                    zIndex: 2,
                  }}></View>
                {/* <CustomFastImage
                  source={{uri: image}}
                  style={{
                    borderRadius: Metrics.horizontalScale(radius || 4),
                  }}
                  width={itemWidth}
                  height={itemHeight}
                /> */}
              </View>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  zIndex: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: Metrics.verticalScale(35),
                  paddingBottom: Metrics.verticalScale(38),
                  paddingLeft: Metrics.horizontalScale(30),
                  paddingRight: Metrics.horizontalScale(30),
                  width: !isCurrentHover ? itemWidthMin : itemWidth,
                  height: !isCurrentHover ? itemHeightMin : itemHeight,
                  opacity: isCurrentHover ? 1 : 0,
                  transition: 'opacity 0.3s ease 0.2s',
                }}>
                <View>
                  <Typography style={{textAlign: 'center'}} variant="ui2">
                    {overTitle}
                  </Typography>
                  {icon && (
                    <View
                      style={{
                        alignItems: 'center',
                        marginTop: Metrics.horizontalScale(34),
                      }}>
                      <Icon name={icon} />
                    </View>
                  )}
                </View>
                <View
                  style={{
                    flexDirection: 'column',
                    width: 228,
                    marginHorizontal: 'auto',
                  }}>
                  <Typography
                    style={{textAlign: 'center'}}
                    variant={icon ? 'h3' : 'h4'}>
                    {title}
                  </Typography>

                  {description && (
                    <Typography
                      style={{textAlign: 'center', marginTop: 15}}
                      variant="body3">
                      {description}
                    </Typography>
                  )}
                </View>
              </div>
            </TouchableOpacity>
          );
        }}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: Metrics.horizontalScale(35),
        }}>
        {data.map((_, index) => (
          <View
            key={index}
            style={[
              carouselHeaderStyles.whiteCircle,
              StyleSheet.create({
                temp: {opacity: index === selectedIndex ? 1 : 0.5},
              }).temp,
            ]}
          />
        ))}
      </View>
    </View>
  );
}
