import React, {ReactNode, useState} from 'react';
import {ActivityIndicator, StyleSheet, Pressable, View} from 'react-native';
import {IconName} from '../../assets/Icones';
import defaultTheme from '../../Theme/defaultTheme';
import Metrics from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import {CustomActivityIndicator} from '../CustomActivityIndicator';
import Modal from 'react-modal';

export interface MenuModalItem {
  label: string;
  value: string;
  icon: IconName | (() => ReactNode);
  isLoading?: boolean;
  onPress?(): void | Promise<void>;
}

export type MenuModalProps = {
  title: string;
  content: Array<MenuModalItem>;
  onClose?(): void;
  isOpen: boolean;
};

export default function MenuModal(props: MenuModalProps) {
  const {title, content, onClose, isOpen} = props;
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const handlePress =
    (event: (() => void | Promise<void>) | undefined, index: number) =>
    async () => {
      setLoadingIndex(index);
      event && (await event());
      setLoadingIndex(undefined);
    };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={
        document.getElementById('profile-my-profile-page') as HTMLElement
      }
      overlayElement={(d, contentElement) => (
        <div
          {...d}
          style={{
            ...d.style,
            background: 'rgba(17, 18, 22, 0.60)',
            backdropFilter: 'blur(10px)',
            zIndex: 10900,
          }}>
          {contentElement}
        </div>
      )}
      style={{
        content: {
          border: 'none',
          background: 'transparent',
          padding: 0,
          margin: 'auto',
          width: 'fit-content',
          height: 'fit-content',
          inset: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        overlay: {
          background: 'rgba(17, 18, 22, 0.60)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: defaultTheme.page.background,
          borderRadius: 8,
          padding: 20,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <Typography variant="h3" style={{color: 'white'}}>
            {title}
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              height: 50,
            }}>
            <Pressable onPress={onClose}>
              <Icon name="close" color="white" />
            </Pressable>
          </div>
        </div>
        <View style={{paddingTop: Metrics.verticalScale(30)}}>
          {content.map((c, index) => (
            <Pressable
              key={`mm-${index}`}
              onPress={handlePress(c.onPress, index)}
              disabled={loadingIndex === index}
              style={StyleSheet.flatten({
                position: 'relative',
                flexDirection: 'row',
                marginBottom:
                  index < content.length - 1 ? Metrics.verticalScale(17) : 0,
              })}>
              {loadingIndex === index ? (
                <ActivityIndicator />
              ) : !c.isLoading ? (
                typeof c.icon === 'function' ? (
                  c.icon()
                ) : (
                  <Icon name={c.icon} />
                )
              ) : (
                <CustomActivityIndicator />
              )}
              <Typography
                variant="body2"
                style={{marginLeft: Metrics.horizontalScale(24)}}>
                {c.label}
              </Typography>
            </Pressable>
          ))}
        </View>
      </div>
    </Modal>
  );
}

const MenuModalStyle = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  overlay: {
    flex: 1,
  },
  content: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: defaultTheme.primaryNavBar,
    paddingHorizontal: Metrics.horizontalScale(24),
    paddingBottom: Metrics.verticalScale(53),
  },
});
