import React, {PropsWithChildren} from 'react';
import {View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import Icon from '../../Icon/Icon';

import CardBlurIcon from '../CardBlurIcon/CardBlurIcon';
import GradientIconCard from '../GradientCard/GradientIconCard/GradientIconCard';
import {gradientIconCardVariantStyles} from '../GradientCard/GradientIconCard/GradientIconCard.styles';
import {premiumStyles} from './PremiumCard.styles';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';
export interface IVariantContainerProps {
  source?: CustomFastImageProps['source'];
  style?: StyleProp<ViewStyle>;
}

// icon
const PremiumPrimaryIconCard = () => {
  return <GradientIconCard icon={'exclude'} variant="active" size="medium" />;
};

const PremiumSecondaryIconCard = () => {
  return (
    <View style={premiumStyles.iconCard}>
      <Icon name="exclude" />
    </View>
  );
};

const PremiumTertiaryIconCard = () => {
  return <CardBlurIcon icon={'exclude'} />;
};

const PremiumQuaternaryIconCard = () => {
  return (
    <View style={premiumStyles.iconCard}>
      <Icon name="exclude" color={defaultTheme.primary} />
    </View>
  );
};

// container
const DefaultContainer = (props: PropsWithChildren<IVariantContainerProps>) => {
  const {children, style} = props;
  return (
    <View
      style={[
        {
          ...premiumStyles.container,
          backgroundColor: defaultTheme.primaryNavBar,
        },
        style,
      ]}>
      {children}
    </View>
  );
};

const ActiveContainer = (props: PropsWithChildren<IVariantContainerProps>) => {
  const {children, style} = props;
  const variantStyle = gradientIconCardVariantStyles.active;
  return (
    <LinearGradient
      angleCenter={{x: 0.5, y: 0.5}}
      useAngle={true}
      angle={0}
      colors={['rgba(0, 0, 0, 0.2)', 'rgba(0, 0, 0, 0.2)']}
      style={style}>
      <LinearGradient
        style={premiumStyles.container}
        colors={[variantStyle.firstColor, variantStyle.secondColor]}
        locations={[0.5411, 1]}
        useAngle={true}
        angle={139.23}
        angleCenter={{x: 0.5, y: 0.5}}>
        {children}
      </LinearGradient>
    </LinearGradient>
  );
};

const ImagBackgroundContainer = (
  props: PropsWithChildren<IVariantContainerProps>,
) => {
  const {children, source, style} = props;
  return (
    <CustomFastImage
      style={[{borderRadius: Metrics.moderateScale(4)}, style]}
      // radius={Metrics.moderateScale(4)}
      backgroundFallbackColor="transparent"
      source={source}>
      {/* <LinearGradient
        style={premiumStyles.container}
        colors={['rgba(17, 18, 22, 0.4)', 'rgba(17, 18, 22, 0.4)']}
        useAngle={true}
        angle={0}
        angleCenter={{x: 0.5, y: 0.5}}> */}
      <View style={premiumStyles.container}>{children}</View>
      {/* </LinearGradient> */}
    </CustomFastImage>
  );
};

export const PremiumVariant: Record<
  Variant,
  {
    icon: () => JSX.Element;
    container: (
      props: PropsWithChildren<IVariantContainerProps>,
    ) => JSX.Element;
    color: string;
  }
> = {
  primary: {
    icon: PremiumPrimaryIconCard,
    container: DefaultContainer,
    color: defaultTheme.primaryInfo,
  },
  secondary: {
    icon: PremiumSecondaryIconCard,
    container: ActiveContainer,
    color: defaultTheme.primaryTitle,
  },
  tertiary: {
    icon: PremiumTertiaryIconCard,
    container: DefaultContainer,
    color: defaultTheme.primaryInfo,
  },
  quaternary: {
    icon: PremiumQuaternaryIconCard,
    container: ImagBackgroundContainer,
    color: defaultTheme.primaryTitle,
  },
};
