import React, {ReactNode} from 'react';
import {View, Pressable, ViewStyle, StyleProp} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import Typography from '../../Typography/Typography';

export type CardSettingProps = {
  title: string;
  children?: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  disabled?: boolean;
  onPress?(): void;
  isPressable?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function CardSetting(props: CardSettingProps) {
  const {
    title,
    children,
    disabled,
    onPress,
    isFirst,
    isLast,
    isPressable,
    style,
  } = props;
  const Main = isPressable ? Pressable : Pressable;
  return (
    <Main
      disabled={disabled}
      onPress={onPress}
      style={[
        {
          paddingTop: Metrics.verticalScale(16),
          paddingLeft: Metrics.horizontalScale(16),
          paddingBottom: Metrics.verticalScale(19),
          paddingRight: Metrics.horizontalScale(9),
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#1B1C21',
          borderTopLeftRadius: isFirst ? 4 : 0,
          borderTopRightRadius: isFirst ? 4 : 0,
          borderBottomLeftRadius: isLast ? 4 : 0,
          borderBottomRightRadius: isLast ? 4 : 0,
          marginBottom: 1,
          opacity: disabled ? 0.2 : 1,
        },
        style,
      ]}>
      {children ? (
        <Typography variant="body2">{title}</Typography>
      ) : (
        <View>
          <Typography variant="body2">{title}</Typography>
        </View>
      )}
      {children && <View>{children}</View>}
    </Main>
  );
}
