import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import SectionPage from '@src/Umbraco/Pages/SectionPage/SectionPage';
import React, {FC} from 'react';

const VodHomePage: FC = () => {
  const {page} = useRegionUrl();
  const sectionId = page.watch?.children.vod?.id;
  return (
    <div>
      <SectionPage id={sectionId} contentSource="watch" />
    </div>
  );
};

export default VodHomePage;
