import React from 'react';
import {darkTheme} from '../../../../styles/Themes';
import IconWithText from '../../../IconWithText/IconWithText';
import CardAside from '../../CardAside/CardAside';
import {ListenCardProps} from '../ListenCardProps';
import {TextStyles} from '../../../../styles/TextStyles';
import Typography from '../../../Typography/Typography';
import IcSettingSvg from '../../../../assets/svg/setting-icon.svg';

const ListenCardAside: React.FC<{
  asideProps: ListenCardProps;
  isTrailingIconCentered?: boolean;
}> = ({asideProps, isTrailingIconCentered}) => {
  return (
    <CardAside
      title={asideProps.title}
      subtitle={asideProps.subtitle}
      isFilledParent={asideProps.asideExpanded}
      titleColor={asideProps.titleColor}
      infoColor={asideProps.infoColor}
      TrailingIc={asideProps.hasTrailingIcon ? IcSettingSvg : undefined}
      onPressTrailingIc={asideProps.onPressTrailingIcon}
      leadingWidth={asideProps.widthInfoContainer}
      isTrailingIconCentered={isTrailingIconCentered}>
      {asideProps.isDownloadType && (
        <Typography
          variant="ui3"
          color={() => asideProps.infoColor ?? darkTheme.colors.primaryGray}>
          {asideProps.episode} - {asideProps.mediaSize}
        </Typography>
      )}
      {asideProps.length && (
        <IconWithText
          text={asideProps.length}
          icon="clock"
          size="16"
          textColor={asideProps.infoColor ?? darkTheme.colors.primaryGray}
          textStyle={TextStyles.infoText}
        />
      )}
      {asideProps.description && (
        <Typography
          variant="ui3"
          numberOfLines={asideProps.maxLineDescription}
          color={() => asideProps.infoColor ?? darkTheme.colors.primaryGray}>
          {asideProps.description}
        </Typography>
      )}
    </CardAside>
  );
};

export default ListenCardAside;
