import React, {FC /* useRef, useState */, Suspense} from 'react';
import {RouteComponentProps} from '@reach/router';
import {AppHomeWatchType} from '@src/Constant/routes';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import HomeContentLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/HomeContentLoader';

const AppDynamicHomePage = React.lazy(
  () => import('../../Common/AppDynamicHomePage/AppDynamicHomePage'),
);

const SectionPage = React.lazy(
  () => import('@src/Umbraco/Pages/SectionPage/SectionPage'),
);

const AppHomeWatch: FC<RouteComponentProps<{type: AppHomeWatchType}>> = () => {
  const {page, loading} = useRegionUrl();

  if (loading) {
    return <HomeContentLoader />;
  }

  if (page.watch) {
    const contentId: string = page.watch.id;
    const contentType = page.watch.__typename;
    return (
      <Suspense>
        <AppDynamicHomePage
          id={contentId}
          __typename={contentType}
          contentSource="watch"
        />
      </Suspense>
    );
  }

  return (
    <div>
      <Suspense>
        <SectionPage contentSource="watch" />
      </Suspense>
    </div>
  );
};

export default AppHomeWatch;
