import React, {useState} from 'react';
import {Pressable, View, ScrollView} from 'react-native';
import Metrics from '../../Theme/Metrics';
import CustomFastImage from '../CustomFastImage/CustomFastImage.web';
import {ServicePromoteProps} from '../ServicePromote/ServicePromote';
import ServiceTitle from '../ServiceTitle/ServiceTitle';
import Typography from '../Typography/Typography';
import AcqCategoryWidget from '../../WebComponent/AcqCategoryWidget/AcqCategoryWidget.web';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

export interface CarouselNumberProps extends ServicePromoteProps {
  description: string;
}

const ServiceCarouselNumber = (props: CarouselNumberProps) => {
  const {title, items, description, onButtonItemPress} = props;
  const [currentSlider, setCurrentSlider] = useState(0);
  const carouselRef = React.createRef<any>();
  const {isMobile} = useBreakpoint();
  const handleItemClick = (_item: any) => {
    onButtonItemPress?.(_item);
  };
  return (
    <div
      style={{
        marginTop: Metrics.horizontalScale(92),
        maxWidth: '100%',
        width: '100%',
        margin: '0px auto',
      }}>
      <ServiceTitle
        titleVariant={'h2'}
        title={title}
        description={description}
      />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexDirection: 'row',
          marginBottom: Metrics.horizontalScale(15),
          marginTop: Metrics.horizontalScale(30),
          marginLeft: 'auto',
          marginRight: 'auto',
          alignItems: 'baseline',
        }}>
        {items.map((d, index) => {
          const color = currentSlider === index ? 'primary' : 'gray';
          return (
            <Pressable
              style={{
                marginHorizontal: Metrics.horizontalScale(16),
                justifyContent: 'center',
              }}
              key={`sc-${d.slideTitle}-${currentSlider}`}
              onPress={() => {
                setCurrentSlider(index);
                carouselRef.current?.scrollTo({index: 0, animated: true});
              }}>
              <Typography
                color={color}
                variant="h2">{`${d.slideTitle}`}</Typography>
              {d.slideSubtitle ? (
                <Typography
                  color={color}
                  style={{textAlign: 'center'}}
                  variant="ui1">
                  {d.slideSubtitle}
                </Typography>
              ) : null}
            </Pressable>
          );
        })}
      </ScrollView>

      <AcqCategoryWidget
        list={items[currentSlider].slides}
        getBackground={item => item.image}
        onClikItem={handleItemClick}
        renderItem={item => {
          const {title, overTitle, description, icon} = item;
          const itemWidth = '100%';
          const itemHeight = '100%';
          return (
            <View style={{flex: 1, alignItems: 'center', height: '100%'}}>
              <div
                style={{
                  opacity: 1,
                  background:
                    'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 32.29%, rgba(17, 18, 22, 0.80) 100%, #111216 93.75%)',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: '72.8%',
                  width: '100%',
                }}></div>
              <View
                style={{
                  justifyContent: 'center',
                  // aspectRatio: aspectRatio,
                  // width: itemWidth,
                  // height: itemHeight,
                  borderRadius: 4,
                  height: '100%',
                }}>
                <View
                  style={{
                    zIndex: 10,
                    justifyContent: 'space-between',
                    paddingTop: Metrics.verticalScale(35),
                    paddingBottom: Metrics.verticalScale(38),
                    paddingLeft: isMobile ? 24 : Metrics.horizontalScale(30),
                    paddingRight: isMobile ? 24 : Metrics.horizontalScale(30),
                    width: itemWidth,
                    height: itemHeight,
                  }}>
                  <View>
                    <Typography style={{textAlign: 'center'}} variant="ui2">
                      {overTitle}
                    </Typography>
                    {icon && (
                      <View
                        style={{
                          alignItems: 'center',
                          marginTop: Metrics.horizontalScale(34),
                        }}>
                        <CustomFastImage
                          backgroundFallbackColor="transparent"
                          width={106}
                          height={108}
                          source={icon}
                        />
                      </View>
                    )}
                  </View>
                  <View style={{flexDirection: 'column'}}>
                    <Typography
                      style={{textAlign: 'center'}}
                      variant={icon ? 'h3' : 'h4'}>
                      {title}
                    </Typography>

                    {description && (
                      <Typography
                        style={{textAlign: 'center', marginTop: 15}}
                        variant="body3">
                        {description}
                      </Typography>
                    )}
                  </View>
                </View>
              </View>
            </View>
          );
        }}
      />
    </div>
  );
};

export default ServiceCarouselNumber;
