import React, {Suspense} from 'react';
import {IContentSliderItem} from '~Umbraco/Components/ContentSlider/ContentSlider';
import Push, {IPushProps} from '~Umbraco/Components/Push/Push';
import {getLinkUrl} from '~Umbraco/Utils/element/getLinkPickerUrl';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {IPushFragmentFragment} from '~Api/Graphql/gql/types.generated';
import usePaywal from '~Hooks/usePaywal';
import {useDispatch} from 'react-redux';
import useAppNavigation from '@src/utils/useAppNavigation';

// const Push = React.lazy(() => import('~Umbraco/Components/Push/Push'));

const usePushUtils = () => {
  const navigation = useAppNavigation();
  const {isMemberFreemium} = usePaywal();

  const dispatch = useDispatch();

  const handleContentSliderItemClick = (item: IContentSliderItem) => {
    const {url, title, id, __typename} = item;
    const factory = new RouteFactory(
      {url, id, __typename},
      navigation,
      dispatch,
      title,
    );
    factory.navigate();
  };
  const renderPush = (
    push: IPushFragmentFragment,
    additionnalProps?: Partial<IPushProps>,
  ) => {
    const {
      title,
      description,
      style,
      topTitle,
      icon,
      isPremium,
      link,
      background,
      backgroundDesktop,
    } = push;
    const {url, __typename, id} = getLinkUrl(link);

    return (
      <Push
        style={style as IPushProps['style']}
        title={title || ''}
        hideHeader={!link || !url}
        description={description || ''}
        headerTitle={topTitle || ''}
        icon={icon && icon.url ? {uri: icon.url} : undefined}
        onPress={() =>
          handleContentSliderItemClick({
            url: url,
            title,
            __typename,
            id,
            accessData: {},
          })
        }
        cover={
          (background && background.url && {uri: background.url}) || undefined
        }
        coverDesktop={
          (backgroundDesktop &&
            backgroundDesktop.url && {uri: backgroundDesktop.url}) ||
          undefined
        }
        {...additionnalProps}
      />
    );
  };
  return {renderPush};
};

export default usePushUtils;
