import React from 'react';
import {View} from 'react-native';
import Images from '../../../../assets/Images';
import Typography from '../../../Typography/Typography';
import GradientChannelCard from '../../GradientCard/GradientChannelCard/GradientChannelCard';
import {
  gradChannelSize,
  gradVariantStyle,
} from '../../GradientCard/GradientChannelCard/GradientChannelCard.styles';
import {tvChannelCardStyles} from './TvChannelCard.styles';

export type TvChannelCardProps = {
  variant: keyof typeof gradVariantStyle;
  logo: keyof typeof Images.channel;
  title: string;
  subtitle: string;
  size?: keyof typeof gradChannelSize;
};

const TvChannelCard = (props: TvChannelCardProps) => {
  const {variant, logo, title, subtitle, size = 'large'} = props;
  const width = gradChannelSize[size].width;

  return (
    <View style={{width: width}}>
      <GradientChannelCard variant={variant} logo={logo} size={size} />
      <Typography
        children={title.toUpperCase()}
        style={tvChannelCardStyles.title}
        variant="h4"
      />
      <Typography children={subtitle} variant="ui3" color="gray" />
    </View>
  );
};

export default TvChannelCard;
