import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {useAllCategoryMenuQuery} from '~Api/Graphql/gql/types.generated';
import {RouteTypename} from '~Navigation/type';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
// import useRegionCategoryTags from './useRegionCategoryTags';

interface ICategoryMenu {
  title: string;
  url: string;
  id: string;
}

export const useSelectCategory = (props: {
  contentTypeAlias: RouteTypename;
  id?: string;
  endUrl?: string;
  regionFilter?: boolean;
}): {
  categories: ICategoryMenu[];
  setCurrentId: Dispatch<SetStateAction<string>>;
  selectedCategory?: ICategoryMenu;
  loading: boolean;
  isCategNotFound: boolean;
} => {
  const {contentTypeAlias, id, endUrl} = props;
  const [currentId, setCurrentId] = useState<string>(id || '');
  useState;
  const currentLanguage = useCurrentLanguage();
  // const [allCategory, setAllCategory] = useState< IAllCategoryMenuQuery | undefined >(undefined);
  const {data: allCategory, loading} = useAllCategoryMenuQuery({
    variables: {
      contentTypeAlias: contentTypeAlias,
      culture: currentLanguage,
    },
    skip: !contentTypeAlias,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const {categories}: {categories: ICategoryMenu[]} = useMemo(() => {
    const _categories: ICategoryMenu[] = [];
    if (
      allCategory &&
      allCategory.allCategoryPage &&
      allCategory.allCategoryPage.items
    ) {
      allCategory.allCategoryPage.items.forEach(item => {
        if (item && item.name && item.url) {
          if (item.__typename === 'WatchCateg') {
            if (item.isExpand || item.id === id) {
              _categories.push({
                title: item.name,
                url: item.url,
                id: item.id,
              });
            }
          } else {
            _categories.push({
              title: item.name,
              url: item.url,
              id: item.id,
            });
          }
        }
      });
    }
    return {categories: _categories};
  }, [allCategory, allCategory?.allCategoryPage]);

  const selectedCategory: ICategoryMenu = useMemo(() => {
    return currentId
      ? categories.find(category => category.id === currentId) || categories[0]
      : categories[0];
  }, [categories, currentId]);

  const isCategNotFound: boolean = useMemo(() => {
    const categ = categories.find(category => category.id === currentId);
    if (!categ) {
      return true;
    }
    return false;
  }, [currentId, categories]);
  useEffect(() => {
    if (!id && !endUrl && categories[0]) {
      setCurrentId(categories[0].id);
    } else if (id) {
      setCurrentId(id);
    }
  }, [id, categories]);

  return {categories, setCurrentId, selectedCategory, loading, isCategNotFound};
};
