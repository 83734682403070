import {StyleProp, View, ViewStyle} from 'react-native';
import React from 'react';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import HtmlRender from '../../HtmlRender/HtmlRender';

interface IWysiwygBlockProps {
  title: string;
  body: string;
  style?: StyleProp<ViewStyle>;
  bodyColor?: string;
}

const WysiwygBlock = (props: IWysiwygBlockProps) => {
  const {title, body, style, bodyColor} = props;
  return (
    <View style={style}>
      {!!title && (
        <Typography
          variant="h3"
          children={title}
          style={{marginTop: Metrics.verticalScale(32)}}
        />
      )}
      <HtmlRender
        html={body}
        variant="body2"
        color={bodyColor || 'gray'}
        style={{marginTop: Metrics.verticalScale(13)}}
      />
    </View>
  );
};

export default React.memo(WysiwygBlock);
