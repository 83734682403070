import {mapHeroVideoCover} from '@src/Umbraco/Utils/mapHeroVideoCover';
import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {useMemo} from 'react';
import {usePromoCodeContentQuery} from '~Api/Graphql/gql/types.generated';

interface PromoCodeThankYouPage {
  thankYouBody?: string;
  thankYouTitle?: string;
  thankYouCover?: IMediaCover;
}

interface PromoCodeThankYouVariant {
  variantSurtitle?: string;
  variantTitle?: string;
  variantHighlightedTitle?: string;
  variantCover?: string;
  variantCoverMobile?: string;
  variantCtaLabel?: string;
  variantDescription?: string;
  variantHighlightedMention?: string;
  variantMention?: string;
}

interface PromoCodeContent {
  title?: string;
  description?: string;
  footerImage?: string;
  loading: boolean;
  thankYouPage?: PromoCodeThankYouPage;
  thankYouVariant?: PromoCodeThankYouVariant;
  subscriptionTitle?: string;
  subscriptionDescription?: string;
}

export const usePromoCodeContent = (): PromoCodeContent => {
  const response = usePromoCodeContentQuery();

  const content = useMemo(() => {
    const {data, loading} = response;

    if (data?.allSettings?.items?.[0]?.children?.items) {
      const promoCodeFolder = data.allSettings.items[0].children.items.find(
        item => item && item.__typename === 'PromoCodeFolder',
      );

      if (promoCodeFolder?.__typename === 'PromoCodeFolder') {
        const {
          title,
          description,
          footerImage,
          variantTitle,
          variantHighlightedTitle,
          variantCover,
          variantSurtitle,
          variantMention,
          variantCtaLabel,
          variantHighlightedMention,
          variantDescription,

          thankYouBody,
          thankYouTitle,
          thankYouCover,

          subscriptionTitle,
          subscriptionDescription,
        } = promoCodeFolder;

        let mappedCover: IMediaCover | undefined = undefined;
        if (
          thankYouCover &&
          thankYouCover[0] &&
          thankYouCover[0].content &&
          thankYouCover[0].content.__typename === 'HeroVideoCover'
        ) {
          mappedCover = mapHeroVideoCover(thankYouCover[0].content);
        }

        return {
          title,
          description,
          footerImage: footerImage?.webCover,
          variantCover: variantCover?.webCover,
          thankYouPage: {
            thankYouBody,
            thankYouTitle,
            thankYouCover: mappedCover,
          },
          thankYouVariant: {
            variantTitle,
            variantDescription,
            variantHighlightedTitle,
            variantCover: variantCover?.webCover,
            variantCoverMobile: variantCover?.mobileCover,
            variantSurtitle,
            variantMention,
            variantHighlightedMention,
            variantCtaLabel,
          },
          subscriptionTitle,
          subscriptionDescription,
          loading,
        };
      }
    }

    return {
      title: undefined,
      description: undefined,
      footerImage: undefined,
      loading,
      thankYouPage: undefined,
      thankYouVariant: undefined,
      subscriptionTitle: undefined,
      subscriptionDescription: undefined,
    };
  }, [response]);

  return content;
};
