import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import Request from '@src/Api/Rest/Request';
import {AppRoutesEnum} from '@src/Constant/routes';
import {IThankYouPagePassedData} from '@src/Umbraco/Pages/ThankYouPage/ThankYouPage';
import {IAppHeaderOptions} from '@src/layout/AppLayout/AppHeader/AppHeader';
import {ITabBarHeaderItem} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import React from 'react';
import {Member} from '~Api/Umbraco/interface/Member.interface';

export interface IProfilEditDrawerParams {
  field: keyof Member;
  headerTitle: string;
  defaultValue?: string | number;
  select?: Array<{
    label: string;
    value: string | number;
  }>;
}
export interface IProfilEditDrawerData {
  isOpenProfilEditDrawer: boolean;
  data?: IProfilEditDrawerParams;
}

interface AppState {
  dialCodeList?: Map<string, string>;
  currentRoute?: AppRoutesEnum;
  showSubMenu: boolean;
  leftSubMenu?: {
    title: string;
    onPress?: () => void;
  };
  isDrawerOpen: boolean;
  topMenu?: ITabBarHeaderItem[] | null;
  accountType?: 'email' | 'connect' | string;
  isProfilHomeDrawerOpen: boolean;
  isProfileMyProfileDrawerOpen: boolean;
  profilEditParams?: IProfilEditDrawerData;
  isProfileSettingDrawerOpen: boolean;
  isOpenMenuDrawer: boolean;
  thankYouModal?: IThankYouPagePassedData;
  headerOptions?: IAppHeaderOptions;
  modalBackground?: boolean | string;
  modalHeader?: React.ReactNode | null;
  goBackInBackground: any;
  drawerGoBack?: any;
}

const initialState: AppState = {
  showSubMenu: true,
  isDrawerOpen: false,
  isProfilHomeDrawerOpen: false,
  isProfileMyProfileDrawerOpen: false,
  profilEditParams: undefined,
  isProfileSettingDrawerOpen: false,
  isOpenMenuDrawer: false,
  goBackInBackground: undefined,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLeftSubMenu(state, action: PayloadAction<{title: string} | undefined>) {
      if (action.payload) {
        state.leftSubMenu = {
          title: action.payload.title,
        };
      } else {
        state.leftSubMenu = undefined;
      }
      return state;
    },
    // setCurrentRoute(
    //   state,
    //   action: PayloadAction<{
    //     route: AppRoutesEnum;
    //     appHomeWatchSubRoute: AppRoutesEnum;
    //   }>,
    // ) {
    //   const route = action.payload.route;
    //   const appHomeWatchSubRoute = action.payload.appHomeWatchSubRoute;
    //   state.currentRoute = route;
    //   if (route === AppRoutesEnum.HOME) {
    //     state.showSubMenu = true;
    //     state.leftSubMenu = undefined;
    //   }

    //   if (route === AppRoutesEnum.HOME_WATCH) {
    //     state.showSubMenu = true;
    //     if (appHomeWatchSubRoute !== AppRoutesEnum.HOME) {
    //       state.leftSubMenu = {
    //         title: 'Live TV',
    //       };
    //     } else {
    //       state.leftSubMenu = undefined;
    //     }
    //   }

    //   if (route === AppRoutesEnum.CATEGORY_WATCH) {
    //     state.showSubMenu = false;
    //     state.leftSubMenu = {
    //       title: 'On Demand',
    //     };
    //   }
    //   return state;
    // },
    setIsProfileSettingDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isProfileSettingDrawerOpen = action.payload;
      return state;
    },
    openThankYouModal(state, action: PayloadAction<AppState['thankYouModal']>) {
      state.thankYouModal = action.payload;
      return state;
    },
    closeThankYouModal(state) {
      state.thankYouModal = undefined;
      return state;
    },
    setIsOpenMenuDrawer(state, action: PayloadAction<boolean>) {
      state.isOpenMenuDrawer = action.payload;
      return state;
    },
    setIsDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
      return state;
    },
    setIsProfilHomeDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isProfilHomeDrawerOpen = action.payload;
      return state;
    },
    setIsMyProfileDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isProfileMyProfileDrawerOpen = action.payload;
      return state;
    },
    setIsOpenProfilEditDrawer(
      state,
      action: PayloadAction<IProfilEditDrawerData>,
    ) {
      state.profilEditParams = action.payload;
      return state;
    },
    setTopMenu(state, action: PayloadAction<ITabBarHeaderItem[] | null>) {
      state.topMenu = action.payload;
      return state;
    },
    setAccountType(state, action: PayloadAction<AppState['accountType']>) {
      state.accountType = action.payload;
      return state;
    },
    setHeaderOptions(state, action: PayloadAction<AppState['headerOptions']>) {
      state.headerOptions = action.payload;
      return state;
    },
    setModalBackground(
      state,
      action: PayloadAction<AppState['modalBackground']>,
    ) {
      state.modalBackground = action.payload;
      return state;
    },
    setModalHeader(state, action: PayloadAction<AppState['modalHeader']>) {
      state.modalHeader = action.payload;
      return state;
    },
    setGoBackInBackground(state, action: PayloadAction<any>) {
      state.goBackInBackground = action.payload;
      return state;
    },
    setDrawerGoBack(state, action: PayloadAction<any>) {
      state.drawerGoBack = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDialCodeList.fulfilled, (state, action) => {
      state.dialCodeList = action.payload;
    });
  },
});

export const getDialCodeList = createAsyncThunk<Map<string, string>, void>(
  'App/getDialCodeList',
  async (_, thunkAPI) => {
    try {
      const response = await Request.get({
        url: 'https://xcarpentier.github.io/react-native-country-picker-modal/countries/',
      });

      if (!response) {
        return thunkAPI.rejectWithValue('Error fetching dial code list');
      }

      const dialCodeList = new Map<string, string>();

      const keys = Object.keys(response);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = response[key].callingCode[0];
        if (value) dialCodeList.set(key, value);
      }

      return dialCodeList;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export default appSlice;
