import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {
  IPremiumPreviewResponse,
  IPremiumPreviewBody,
} from './interface/premiumPreview.interface';

const endpoint = '/me/premium-preview';

export const createPremiumPreviewEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  addPremiumPreview: builder.mutation<
    IPremiumPreviewResponse,
    IPremiumPreviewBody
  >({
    query: body => ({
      url: `${endpoint}`,
      method: 'POST',
      body,
    }),
  }),
  getPremiumPreview: builder.query<
    IPremiumPreviewResponse,
    {contentId: string}
  >({
    keepUnusedDataFor: 0,
    query: ({contentId}) => ({
      url: `${endpoint}/${contentId}`,
      method: 'GET',
    }),
  }),
});
