import Typography from 'design-system/src/Components/Typography/Typography';
import {darkTheme} from 'design-system/src/styles/Themes';
import React from 'react';
import {View} from 'react-native';
import ServiceItem, {IServiceItem} from './ServiceItem/ServiceItem';
import Metrics from 'design-system/src/Theme/Metrics';
import Button from 'design-system/src/Components/Button/Button';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import useNavigation from '@src/Navigation/useNavigation';
import {useDispatch} from 'react-redux';
import {IconName} from 'design-system/src/assets/Icones';
import ImageWithBackgroundShadow from 'design-system/src/WebComponent/ImgeWithBackgroundShadow/ImageWithBackgroundShadow.web';

interface IHeroServicesProps {
  title?: string;
  overTitle?: string;
  description?: string;
  image?: string;
  services?: IServiceItem[];
  id?: string;
  button?: {
    title?: string;
    icon?: string;
    linkTo?: string;
    id?: string;
  };
}

const HeroServices = (props: IHeroServicesProps) => {
  const {title, overTitle, description, image, services, id, button} = props;

  const {isMobile} = useBreakpoint();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (!button) return;
    const {icon, id: idButton, linkTo, title} = button;
    new RouteFactory(
      {
        id: idButton || '',
        __typename: 'ExternalLink',
        url: linkTo || '',
      },
      navigation,
      dispatch,
    ).navigate();
  };

  return (
    <div
      id={id}
      style={{
        alignItems: 'center',
        paddingTop: 48,
        paddingBottom: 48,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
      <View
        style={{
          flexDirection: isMobile ? 'column-reverse' : 'row',
          justifyContent: 'space-between',
          width: isMobile ? '90%' : '70%',
          alignItems: 'center',
        }}>
        {/* left */}
        <View
          style={{
            width: isMobile ? '100%' : '50%',
            alignItems: isMobile ? 'center' : 'flex-start',
          }}>
          {(!!title || !!overTitle) && (
            <View
              style={{
                marginBottom: 24,
                width: isMobile ? '90%' : '70%',
                alignItems: isMobile ? 'center' : 'flex-start',
              }}>
              {!!overTitle && (
                <Typography
                  variant="h1"
                  style={{color: darkTheme.colors.primaryOrange}}>
                  {overTitle}
                </Typography>
              )}
              {!!title && (
                <Typography
                  variant="h1"
                  style={{textAlign: isMobile ? 'center' : 'left'}}>
                  {title}
                </Typography>
              )}
            </View>
          )}
          {!!description && (
            <View style={{width: isMobile ? '100%' : '80%', marginBottom: 48}}>
              <Typography
                variant="body1"
                style={{textAlign: isMobile ? 'center' : 'left'}}>
                {description}
              </Typography>
            </View>
          )}
          {!!services && services.length > 0 && (
            <View
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'center' : undefined,
                marginBottom: isMobile ? 0 : 48,
                width: '100%',
              }}>
              {services.map((item, index) => (
                <ServiceItem key={index} item={item} />
              ))}
            </View>
          )}
          {button && (
            <Button
              variant="outlined"
              title={button.title ?? ''}
              size="large"
              icon={
                button.icon
                  ? (button.icon.toLowerCase() as IconName)
                  : undefined
              }
              iconStyle={{}}
              onPress={handleOnClick}
            />
          )}
        </View>
        {/* right */}
        {!!image && (
          <View
            style={{
              position: 'relative',
              marginBottom: isMobile ? 77 : undefined,
            }}>
            <View
              style={{
                opacity: 0.5,
                background:
                  'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 15.54%, rgba(17, 18, 22, 0.80) 57.45%, #111216 74.32%)',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 10,
              }}></View>
            <ImageWithBackgroundShadow
              image={image}
              opacity={0.4}
              blurFilter={8}
              containerStyle={{
                width: Metrics.horizontalScale(isMobile ? 225 : 375),
                height: Metrics.horizontalScale(isMobile ? 409 : 680),
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
              }}
              imageStyle={{
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
              }}
            />
          </View>
        )}
      </View>
    </div>
  );
};

export default HeroServices;
