import React from 'react';
import {StyleSheet, Text, Pressable, View} from 'react-native';
import {
  optionCardStyles,
  optionCardVariantStyles,
  optionIconVariant,
} from './OptionCard.styles';
import {TextStyles} from '../../../styles/TextStyles';
import Icon from '../../Icon/Icon';
import defaultTheme from '../../../Theme/defaultTheme';
import Typography from '../../Typography/Typography';

type OptionCardProps = {
  title: string;
  description: string;
  onClick?: () => void;
  variant?: keyof typeof optionCardVariantStyles;
};

const OptionCard = (props: OptionCardProps) => {
  const {variant = 'default'} = props;

  const variantStyle = optionCardVariantStyles[variant];

  const icon = optionIconVariant[variant];

  return (
    <Pressable
      onPress={props.onClick}
      style={StyleSheet.flatten([optionCardStyles.container, variantStyle])}>
      <View style={optionCardStyles.header}>
        <Icon name={icon.iconName} color={icon.color} />
        <Typography
          children={props.title}
          style={optionCardStyles.title}
          variant="body2"
        />
      </View>
      <Typography
        children={props.description}
        style={optionCardStyles.title}
        variant="ui3"
        color="gray"
      />
    </Pressable>
  );
};

export default OptionCard;
