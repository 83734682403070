import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberCourseStatusBody} from './interface/memberDataBody.interface';
import {ICourseStatusResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/course-status';
export const createMemberCourseStatusEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getCourseStatusById: builder.query<ICourseStatusResponse, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}/${id}`,
      method: 'GET',
    }),
  }),
  getAllCourseStatus: builder.query<ICourseStatusResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  updateMemberCourseStatus: builder.query<
    ICourseStatusResponse,
    {id?: string; data: IMemberCourseStatusBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
});
