import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Icon from '../../../Components/Icon/Icon';
import {Pressable} from 'react-native';
import PlayerSlider from './PlayerSlider/PlayerSlider.web';
import PlayerHeader from './PlayerHeader/PlayerHeader.web';
import PlayerParameters from './PlayerHeader/PlayerParameters.web';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import {darkTheme} from '../../../styles/Themes';
import {IVideoSource} from './VideoPlayer';
import {useChannel} from '../Hooks/useChannel.web';
import {EQuality, useVideoPlayer} from '../Hooks/useVideoPlayer.web';
import {CustomActivityIndicator} from '../../../Components/CustomActivityIndicator';
import screenfull from 'screenfull';
import useCastPlayer from './Factory/Player/hooks/useCastPlayer';
import useCast from './Factory/Player/hooks/useCast';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Typography from '../../../Components/Typography/Typography';
import {useMediaSession} from '../Hooks/useMediaSession';

export type PlayerTypeEnum = 'standard' | 'live' | 'verticale';

interface VideoPlayerProps {
  onNext?: (index: number) => void;
  isLive?: boolean;
  title: string;
  type?: PlayerTypeEnum;
  borderRadius?: number;
  onGoBack?: () => void;
  poster?: string;
  sourceList: Array<IVideoSource>;
  currentChannel?: number;
  onVideoLoad?: (params: {
    textTrack?: string;
    originalQuality?: number | string;
    loadTime?: number;
  }) => void;
  onGetDuration?: (duration: number) => void;
  onPlayEnd?: () => void;
  onVideoPlay?: (isPaused: boolean) => void;
  onTimeProgress?: (time: number) => void;
  handleOnLanguageChange?: (language: string) => void;
  handleOnResolutionChange?: (newResolution: string) => void;
  handleOnSpeedChange?: (isAccelerated: boolean) => void;
  handleOnSkipChange?: (isSkip: boolean) => void;
  initialTime?: number;
  onSetCurrentChannel?: (current: number) => void;
  memberVideoQuality?: EQuality;
  displayPaywall?: boolean;
  paywallContent?: React.ReactNode;
  disableCast?: boolean;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  disableCast,
  onNext,
  title,
  type = 'standard',
  borderRadius,
  onGoBack,
  poster,
  sourceList,
  currentChannel = 0,
  onVideoLoad,
  onGetDuration,
  onVideoPlay,
  onTimeProgress,
  handleOnLanguageChange,
  handleOnSpeedChange,
  handleOnResolutionChange,
  handleOnSkipChange,
  onPlayEnd,
  initialTime,
  onSetCurrentChannel,
  memberVideoQuality,
  displayPaywall,
  paywallContent,
}) => {
  const [isBuffering, setIsBuffering] = useState(true);
  const [isOpenParameters, setIsOpenParameters] = useState(false);

  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const {t} = useTranslation(['videoPlayer']);

  const {
    currentMedia,
    currentSource,
    isLoading: isLoadingSource,
    channelUp,
  } = useChannel<any>(sourceList, currentChannel);

  // useEffect(() => {
  //   onSetCurrentChannel && onSetCurrentChannel(currentMedia);
  // }, [currentMedia]);

  const timeLoadStart = useRef<number>();
  const isLive = type === 'live';
  const {isMobile} = useBreakpoint();

  const {
    videoRef,
    changeAudioTrack,
    changeSubtitle,
    loaded,
    setLoaded,
    duration,
    audioTracks,
    audioTrack,
    subtitle,
    subtitles,
    playbackRate,
    setPlaybackRate,
    playing,
    handlePlayPause,
    handleSliderChange,
    played,
    playedSeconds,
    resetPlayer,
    skip,
    handleTimeUpdate,
    handleLoadedMetadata,
    isLoadedMetadata,
    setPlaying,
    videoQualities,
    videoQuality,
    changeVideoQuality,
    muted,
    setMuted,
  } = useVideoPlayer({
    currentSource,
    initialTime,
    onVideoInitialized: ({duration, textTrack, originalQuality}) => {
      const timeStart = timeLoadStart.current || 0;
      const loadTime = Math.round((Date.now() - timeStart) / 1000);
      onVideoLoad && onVideoLoad({loadTime, originalQuality, textTrack});
      if (!isLive) {
        onGetDuration && onGetDuration(duration);
      }
    },
    memberVideoQuality,
    onTimeProgress,
    handleOnResolutionChange,
    isMobile,
  });

  // CAST
  const {deviceName} = useCast();
  const {isMediaLoaded, currentTime: currentCastTime, seek} = useCastPlayer();

  const previousCastTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (currentCastTime) {
      onTimeProgress && onTimeProgress(currentCastTime);
    }
  }, [currentCastTime]);

  useEffect(() => {
    if (previousCastTimeRef.current !== null) {
      if (previousCastTimeRef.current !== 0 && currentCastTime === 0) {
        handleSliderChange(previousCastTimeRef.current / duration);
      }
    }
    previousCastTimeRef.current = currentCastTime;
  }, [currentCastTime]);

  const isWithNext = currentMedia < sourceList.length - 1;

  // const [isHovering, setIsHovering] = useState(false);
  const [isMouseIdle, setIsMouseIdle] = useState(false);

  const hoverTimeoutRef = useRef<HTMLDivElement | null>(null);

  const isVerticale = type === 'verticale';

  const isHideControls = useMemo(
    () => !isVerticale && (isOpenParameters || isMouseIdle),
    [isOpenParameters, isMouseIdle],
  );

  // const isHideControls = false;

  const onEnd = useCallback(() => channelUp(onNext), [channelUp, onNext]);

  useEffect(() => {
    if (displayPaywall) {
      videoRef.current?.pause();
      setPlaying(false);
    } else if (isLive) {
      // videoRef.current?.play();
      setPlaying(true);
    }
  }, [displayPaywall, playing, isLive]);

  useEffect(() => {
    const timeoutRef: any = hoverTimeoutRef.current;
    // Clear the timeout when the component unmounts
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleWaiting = () => {
      setIsBuffering(true);
    };

    const handlePlaying = () => {
      setIsBuffering(false);
    };

    if (videoElement) {
      videoElement.addEventListener('waiting', handleWaiting);
      videoElement.addEventListener('playing', handlePlaying);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('waiting', handleWaiting);
        videoElement.removeEventListener('playing', handlePlaying);
      }
    };
  }, []);

  const handlePlaybackRate = (rate: number) => {
    if (rate > 1) {
      handleOnSpeedChange && handleOnSpeedChange(true);
    } else if (rate < 1) {
      handleOnSpeedChange && handleOnSpeedChange(false);
    }
    setPlaybackRate(rate);
  };

  // const handleMouseEnter = () => {
  //   handleMouseActivity();
  //   setIsHovering(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovering(false);
  // };

  const handleProgress = () => {
    const video = videoRef.current;
    if (video && video.buffered.length > 0) {
      const end = video.buffered.end(video.buffered.length - 1);
      if (!isLive) {
        onTimeProgress && onTimeProgress(end);
      }
      const percentage = (end / video.duration) * 100;
      setLoaded(percentage);
    }
    // handleOnCustomProgress
  };

  const handleMouseActivity = () => {
    setIsMouseIdle(false);
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current as any);
    }
    hoverTimeoutRef.current = setTimeout(
      () => {
        setIsMouseIdle(true);
      },
      isMobile ? 5000 : 1000,
    ) as any; // Set idle status after 5 seconds of inactivity
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && videoContainerRef.current) {
      screenfull.toggle(videoContainerRef.current);
      setIsFullscreen(!isFullscreen);
    }
  };

  // Use the media session hook
  useMediaSession({
    videoRef,
    title,
    poster,
    description: currentSource?.description,
    isLive: type === 'live',
    duration,
    playedSeconds,
    playbackRate,
    setPlaying,
    onVideoPlay,
    skip,
    handleOnSkipChange,
    onNext,
    channelUp,
    currentMedia,
    sourceListLength: sourceList.length,
  });

  return (
    <div
      ref={videoContainerRef}
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100%',
        top: 0,
        left: 0,
      }}>
      <div
        style={{
          width: '100%',
          position: 'relative',
          height: '100%',
        }}>
        <video
          ref={videoRef}
          poster={poster}
          // controls
          onTimeUpdate={handleTimeUpdate}
          onPlay={() => {
            onVideoPlay && onVideoPlay(false);
            setPlaying(true);
          }}
          onPause={() => {
            onVideoPlay && onVideoPlay(true);
            setPlaying(false);
          }}
          onLoadedMetadata={handleLoadedMetadata}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: borderRadius,
            backgroundColor: darkTheme.colors.backgroundBlack,
          }}
          onProgress={handleProgress}
          onLoadStart={() => {
            //    handleOnVideoLoad,
            timeLoadStart.current = Date.now();
          }}
          onEnded={() => {
            if (currentMedia < sourceList.length - 1) {
              onPlayEnd && onPlayEnd();
              onEnd();
            }
          }}
          x-webkit-airplay="allow"
          playsInline
          webkit-playsinline="true"
          controlsList="nodownload"
          autoPlay={true}
          muted={isMediaLoaded || muted}
          title={currentSource?.description ?? title}
          data-artist="Trace+"
          data-album={currentSource?.description ?? title}
          data-title={currentSource?.description ?? title}
          preload="metadata"
        />

        {displayPaywall && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 1000,
            }}>
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                zIndex: 1,
                padding: isMobile ? '0 16px' : `0 ${isVerticale ? 24 : 60}px`,
              }}>
              <PlayerHeader
                title={''}
                hideParameters={true}
                onGoBack={onGoBack}
                isHide={false}
                // poster={poster}
                currentMedia={currentMedia}
                uri={''}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                width: '100%',
                height: '100vh',
                overflow: 'scroll',
              }}>
              {paywallContent}
            </div>
          </div>
        )}

        {isMediaLoaded && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography variant="body1">
              {t('Playing on')} {deviceName}
            </Typography>
          </div>
        )}

        {isBuffering && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <CustomActivityIndicator size="small" />
          </div>
        )}

        {!displayPaywall && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 2,
            }}>
            <div
              // onClick={handlePlayPause}
              ref={hoverTimeoutRef}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseActivity}
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: isMobile ? '0 16px' : `0 ${isVerticale ? 24 : 60}px`,
                position: 'relative',
                cursor: isMouseIdle ? 'none' : 'default',
              }}>
              <PlayerHeader
                title={currentSource?.description ?? title}
                onSetOpenParameters={() => {
                  setIsOpenParameters(!isOpenParameters);
                }}
                isLive={isLive}
                onGoBack={() => {
                  onGoBack && onGoBack();
                  //  stop video
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlaying(false);
                  }
                }}
                isHide={isHideControls || isVerticale}
                poster={poster}
                currentMedia={currentMedia}
                playedSeconds={playedSeconds}
                uri={(currentSource as any)?.uri}
                handlePlayPause={playing ? handlePlayPause : undefined}
                disableCast={disableCast}
              />
              {isOpenParameters && !isMediaLoaded && (
                <PlayerParameters
                  onSetOpenParameters={() => {
                    setIsOpenParameters(!isOpenParameters);
                  }}
                  speedParameter={playbackRate}
                  onSetSpeedParameter={handlePlaybackRate}
                  audioTracks={audioTracks}
                  subtitles={subtitles}
                  audioTrack={audioTrack}
                  subtitle={subtitle}
                  onChangeAudioTrack={changeAudioTrack}
                  onChangeSubtitle={changeSubtitle}
                  handleOnLanguageChange={handleOnLanguageChange}
                  onChangeVideoQuality={changeVideoQuality}
                  videoQualities={videoQualities}
                  videoQuality={videoQuality}
                />
              )}
              {!isHideControls && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: isVerticale ? 0 : 86,
                      flex: 1,
                      alignItems: isVerticale ? 'center' : 'flex-end',
                    }}>
                    {isVerticale && !playing && (
                      <Pressable onPress={handlePlayPause}>
                        <Icon name="play-big" color="white" />
                      </Pressable>
                    )}
                    {!isLive && !isVerticale && !isMediaLoaded && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 76,
                          width: 380,
                          justifyContent: 'space-between',
                        }}>
                        <Pressable
                          onPress={() => {
                            skip(-15);
                            handleOnSkipChange && handleOnSkipChange(false);
                          }}>
                          <Icon name="circlePrevious" />
                        </Pressable>
                        <Pressable onPress={handlePlayPause}>
                          {isLoadedMetadata ? (
                            playing ? (
                              <Icon
                                name="pause"
                                size="24"
                                color="white"
                                style={{
                                  marginHorizontal: 26,
                                }}
                              />
                            ) : (
                              <Icon name="play" size="76" color="white" />
                            )
                          ) : (
                            <CustomActivityIndicator size="small" />
                          )}
                        </Pressable>
                        <Pressable
                          onPress={() => {
                            skip(15);
                            handleOnSkipChange && handleOnSkipChange(true);
                          }}>
                          <Icon name="circleForward" />
                        </Pressable>
                      </div>
                    )}
                  </div>
                  <PlayerSlider
                    duration={duration}
                    onSliderChange={value => {
                      if (!isMediaLoaded) {
                        handleSliderChange(value);
                      } else {
                        const newPlayedSeconds = Array.isArray(value)
                          ? value[0]
                          : value;
                        seek(newPlayedSeconds * duration);
                      }
                    }}
                    onResetPlayer={resetPlayer}
                    played={isMediaLoaded ? currentCastTime / duration : played}
                    playedSeconds={
                      isMediaLoaded ? currentCastTime : playedSeconds
                    }
                    onNext={() => channelUp(onNext)}
                    loaded={loaded}
                    isLive={isLive}
                    isVerticale={isVerticale}
                    isWithNext={isWithNext}
                    sourceList={sourceList}
                    toggleFullscreen={toggleFullscreen}
                    setMuted={setMuted}
                    muted={!isMediaLoaded ? muted : undefined}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
