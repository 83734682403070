import React, {useState} from 'react';
import {Pressable, View} from 'react-native';
import DropDownButton from '../../Button/DropDownButton/DropDownButton';
import ListModal from '../../Modal/ListModal/ListModal';
import Typography from '../../Typography/Typography';
import {tabBarHeaderStyles} from './TabBarHeader.styles';
import {typographyColor} from '../../Typography/Typography.props';

type WithRequiredChildren<T extends ITabBarHeaderItem> = T & {
  children: NonNullable<ITabBarHeaderItem['children']>;
};
export interface ITabBarHeaderItem {
  title: string;
  route?: string;
  color?: keyof typeof typographyColor;
  onClick?: () => void;
  children?: Omit<ITabBarHeaderItem, 'children'>[];
}

export type TabBarHeaderProps = {
  items: ITabBarHeaderItem[];
  onClick?: (item: ITabBarHeaderItem) => void;
};

const TabBarHeader = (props: TabBarHeaderProps) => {
  const {items, onClick} = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    WithRequiredChildren<ITabBarHeaderItem> | undefined
  >();

  const handleClick = (item: ITabBarHeaderItem) => () => {
    if (item.onClick) {
      item.onClick();
    }
    onClick && onClick(item);
  };

  const handleOpenSubmenu = (menuItem: ITabBarHeaderItem) => () => {
    setSelectedItem({...menuItem, children: menuItem.children || []});
    setShowModal(true);
  };

  const handleCloseSubmenu = () => {
    setShowModal(false);
  };
  const handleClickChildMenu = (item: ITabBarHeaderItem) => {
    handleClick(item)();
    setShowModal(false);
  };
  return (
    <View style={tabBarHeaderStyles.container}>
      {items.map((item, index) => {
        const {title, color, children} = item;
        if (children) {
          return (
            <DropDownButton
              key={index}
              text={title}
              onPress={handleOpenSubmenu(item)}
            />
          );
        }
        return (
          <Pressable
            key={index}
            onPress={handleClick(item)}
            style={tabBarHeaderStyles.item}>
            <Typography children={item.title} variant="button1" color={color} />
          </Pressable>
        );
      })}
      {!!selectedItem && (
        <ListModal<ITabBarHeaderItem>
          visibility={showModal}
          title={selectedItem.title}
          items={selectedItem.children}
          renderItemLabel={element => element.title}
          onClickItem={handleClickChildMenu}
          onClose={handleCloseSubmenu}
        />
      )}
    </View>
  );
};

export default TabBarHeader;
