import useViewPort from 'design-system/src/Hooks/useViewPort';
import React from 'react';

const PageModalGradient = () => {
  const {viewportHeight} = useViewPort();
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: viewportHeight,
      }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 30.49%, rgba(17, 18, 22, 0.80) 75.48%, #111216 93.58%)',
          top: 0,
          left: 0,
        }}></div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 30.49%, rgba(17, 18, 22, 0.80) 75.48%, #111216 93.58%)',
          top: 0,
          left: 0,
        }}></div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 0.4,
          background:
            'linear-gradient(0deg, rgba(17, 18, 22, 0.00) 44.86%, rgba(17, 18, 22, 0.80) 71.63%, #111216 100%)',
          top: 0,
          left: 0,
        }}></div>
    </div>
  );
};

export default PageModalGradient;
