import React, {useMemo} from 'react';
import HighlightedCarousel, {
  HighlightedCarouselProps,
} from 'design-system/src/Components/Card/HighlightedCarousel/HighlightedCarousel.web';
import {
  ICategorySliderItem,
  ICategorySliderProps,
} from '../CategorySlider/CategorySlider';
import {useCategoryChildrenByIdQuery} from '~Api/Graphql/gql/types.generated';
import {useFindProgression} from '~Hooks/useFindProgression';
import {useLiveEventProgression} from '~Hooks/useLiveEventProgression';
import {mapCategoryChildrenItems} from '~Umbraco/Utils/content/mapCategoryChildrenItems';
import usePaywal from '~Hooks/usePaywal';
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface ICategorySliderHighlightProps
  extends Omit<HighlightedCarouselProps<ICategorySliderItem>, 'premiumText'> {
  category?: ICategorySliderProps['category'];
}

const CategorySliderHighlight = (props: ICategorySliderHighlightProps) => {
  const {data: __oldData, category, ...rest} = props;
  const {findProgression} = useFindProgression();
  const {calculateProgression} = useLiveEventProgression();
  const {t} = useTranslation('translation');
  const {data} = useCategoryChildrenByIdQuery({
    variables: {
      first: 6,
      id: category && category.id,
    },
    fetchPolicy: 'cache-first',
    skip: !(category && category.id) || (!!__oldData && !!__oldData.length),
  });
  const {canSeeContent} = usePaywal();

  const items: ICategorySliderItem[] = useMemo(() => {
    return category && data && data.category && data.category.children
      ? mapCategoryChildrenItems(
          {
            items: data.category.children.edges.map(edge => edge?.node) as any,
            __typename: 'ContentConnection',
          },
          findProgression,
          canSeeContent,
          calculateProgression,
        )
      : [];
  }, [category, data, findProgression, calculateProgression, canSeeContent]);
  return (
    <HighlightedCarousel
      data={__oldData && __oldData.length ? __oldData : items}
      premiumText={t('premium') + ''}
      {...rest}
    />
  );
};

export default CategorySliderHighlight;
