import {graphql, HeadFC} from 'gatsby';
import React, {useContext, useMemo, useState} from 'react';
import {PageProps} from 'gatsby';
import useAwardSetting from '@src/layout/AwardLayout/useAwardSetting';
import FormPage from '@src/Umbraco/Pages/FormPage/FormPage';
import useNavigation from '@src/Navigation/useNavigation';
import ModalRoutingContext from '../../../../plugins/layout-wrapper/ModalRoutingContext';
import {useAwardsArtistByIdQuery} from '@src/Api/Graphql/gql/types.generated';
import PageLoader from '@src/components/general/PageLoader/PageLoader';
import {useLazyAwardsVotingQuery} from '@src/Api/TraceApi/traceApi';
import {AxiosError} from 'axios';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import {FieldValues} from 'react-hook-form';
import {listUserInfo} from '@src/page-templates/AwardArtistVotingPageTemplate';

const AwardVoting = (props: PageProps) => {
  const {params} = props;
  const {id: artistId} = params;
  const {navigate} = useNavigation();
  const {t} = useTranslation('awards');
  const tracker = useTracker();
  const [error, setError] = useState<string>();
  const {closeTo} = useContext(ModalRoutingContext);
  const [sendVoting] = useLazyAwardsVotingQuery();
  const {data, loading: loadingArtist} = useAwardsArtistByIdQuery({
    variables: {
      id: artistId,
    },
    skip: !artistId,
  });
  const {
    votingForm: {form, cover, title, introduction, label},
    loading: loadingSetting,
  } = useAwardSetting();

  const votedFor = useMemo(() => {
    return data?.awardsArtist?.exportID;
  }, [data]);
  const votedCategory = useMemo(() => {
    return data?.awardsArtist?.parent &&
      data?.awardsArtist?.parent.__typename === 'AwardCategoryPage'
      ? data?.awardsArtist?.parent.id
      : '';
  }, [data]);

  const loading: boolean = loadingSetting || loadingArtist;

  const initialValue: FieldValues = listUserInfo.reduce(
    (a, c) => ({...a, [c]: localStorage.getItem(c)}),
    {},
  );

  const handleClose = () => {
    if (closeTo) {
      navigate(closeTo);
    } else {
      navigate('/awards');
    }
  };
  const handleSubmit = async ({
    _buttonLabel,
    ...formData
  }: any): Promise<boolean> => {
    setError('');
    try {
      const _formData = {
        ...formData,
        votedFor,
        votedCategory,
      };
      // tracker.general.cTAClicked({
      //   name: _buttonLabel,
      //   voteName: votedFor,
      // });
      if (form._id) {
        try {
          const response = await sendVoting({
            formId: form._id,
            body: _formData,
          });

          listUserInfo.forEach(_key => {
            localStorage.setItem(_key, _formData[_key]);
          });
          if (response.error) {
            setError(t('Voting error') + '');
            return false;
          }
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.status === 422) {
            }
          }
          setError(t('Voting error') + '');

          return false;
        }
        return true;
      }
      return false;
    } catch (error) {
      setError(t('Voting error') + '');
      return false;
    }
  };

  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        form &&
        votedFor && (
          <FormPage
            form={form}
            cover={cover}
            title={title}
            subtitle={introduction}
            initialValue={initialValue}
            additionnalFormData={{
              votedFor,
              votedCategory,
            }}
            overrideSubmit={handleSubmit}
            onClose={handleClose}
            errors={(!!error && [error]) || undefined}
            thanksCloseTo={'/awards'}
            disableSubmitTracking={true}
          />
        )
      )}
    </div>
  );
};
AwardVoting.layoutComponent = 'AwardLayout';
AwardVoting.isModal = true;
AwardVoting.layoutProps = {
  variant: 'withBg',
};

export default AwardVoting;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head: HeadFC = () => <title>Award voting</title>;
