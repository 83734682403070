import React from 'react';
import {View, StyleSheet} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
type PromoCodeTagProps = {
  text: string;
};

const PromoCodeTag = ({text}: PromoCodeTagProps) => {
  return (
    <View style={styles.container}>
      <Icon name="check-circle" size={'24'} color={defaultTheme.primary} />
      <Typography variant="h4" color="orange">
        {text}
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'rgba(234, 108, 19, 0.40)',
    backgroundColor: 'rgba(234, 108, 19, 0.10)',
    paddingHorizontal: 8,
    paddingVertical: 4,
    gap: 8,
  },
});

export default PromoCodeTag;
