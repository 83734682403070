import React, {createContext, useState, useEffect, useRef} from 'react';
import {onAuthStateChanged, User} from 'firebase/auth';
import {auth} from '@src/firebase';
import {useLazyGetMemberByUsernameQuery} from '@src/Api/TraceApi/traceApi';
import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import useNavigation from '@src/Navigation/useNavigation';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import InitTrackerUser from './InitTrackerUser/InitTrackerUser';

type AuthState = {
  isLoggedIn: boolean;
  isLoading: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  checkAuth: (user: User | null) => Promise<void>;
};

export const AuthContext = createContext<AuthState>({
  isLoggedIn: false,
  isLoading: true,
  setIsLoggedIn: () => {},
  checkAuth: () => Promise.resolve(),
});

const AuthProvider = ({children}: {children: React.ReactElement}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [fetchMemberByUsernameAndUpdateStore] =
    useLazyGetMemberByUsernameQuery();
  const {signOut} = useFireBaseAuthentication();
  const {authentificationType, socialNetworkSource, openAuthenticationModal} =
    useAppSelector(state => state.appAuth);
  const rootNavigation = useNavigation();
  const dispatch = useAppDispatch();

  // Create refs to store the latest values
  const authTypeRef = useRef(authentificationType);
  const socialSourceRef = useRef(socialNetworkSource);

  // Add new ref for redirect URL
  const redirectUrlRef = useRef<string | undefined>(undefined);

  // Update refs whenever the values change
  useEffect(() => {
    authTypeRef.current = authentificationType;
    socialSourceRef.current = socialNetworkSource;
    // Store redirect URL in ref when modal state changes
    redirectUrlRef.current = openAuthenticationModal?.redirect;
  }, [authentificationType, socialNetworkSource, openAuthenticationModal]);

  const checkAuth = async (user: User | null) => {
    console.log('🚀 ~ checkAuth ~ user:', user);
    console.log('Authentication Modal State:', {
      isOpen: openAuthenticationModal?.open,
      storedRedirect: redirectUrlRef.current,
      currentRedirect: openAuthenticationModal?.redirect,
      authType: authTypeRef.current,
      socialSource: socialSourceRef.current
    });

    const handleNotLoggedIn = () => {
      setIsLoggedIn(false);
      signOut();
      redirectUrlRef.current = undefined;
    };

    const currentPath = window.location.pathname;

    try {
      if (
        authTypeRef.current === 'register' &&
        socialSourceRef.current !== 'email' &&
        user &&
        user.email
      ) {
        setIsLoggedIn(false);
      } else if (user && user.email) {
        const {data: memberData} = await fetchMemberByUsernameAndUpdateStore();
        if (
          memberData &&
          memberData.email &&
          memberData.email.toLowerCase() === user.email.toLowerCase()
        ) {
          setIsLoggedIn(true);

          console.log('Login successful, handling navigation with stored redirect:', redirectUrlRef.current);
          
          if (redirectUrlRef.current) {
            // Navigate first
            await rootNavigation.navigate(redirectUrlRef.current, {
              replace: true,
            });

            // Then close modal after navigation is complete
            dispatch(
              appAuthSlice.actions.openAuthenticationModal({
                open: false,
                redirect: undefined,
              }),
            );
            
            // Clear the stored redirect URL after successful navigation
            redirectUrlRef.current = undefined;
          } else {
            // Handle default navigation first
            if (currentPath.includes('/tv-auth')) {
              await rootNavigation.navigate(currentPath, {
                replace: true,
              });
            }

            // Then close authentication modal
            if (openAuthenticationModal?.open) {
              dispatch(
                appAuthSlice.actions.openAuthenticationModal({
                  open: false,
                }),
              );
            }
          }
        } else {
          handleNotLoggedIn();
        }
      } else {
        handleNotLoggedIn();
      }
    } catch (error) {
      console.log('🚀 ~ useEffect ~ error:', error);
      handleNotLoggedIn();
    } finally {
      setIsLoading(false);
    }
  };

  // Implement check for user status here (e.g., check tokens)
  useEffect(() => {
    // setIsLoggedIn(false);
    // setIsLoading(true);

    const unsub = onAuthStateChanged(auth, checkAuth);

    return () => {
      setIsLoggedIn(false);
      setIsLoading(true);
      unsub();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{isLoggedIn, isLoading, setIsLoggedIn, checkAuth}}>
      <InitTrackerUser />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
