import React from 'react';
import {Platform, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import CarouselLoader from './CarouselLoader';
import CustomHeaderLoader from './CustomHeaderLoader';

const HomeContentLoader = () => {
  return (
    <View
      style={Platform.select({
        web: {
          maxWidth: 920,
          width: '100%',
          marginHorizontal: 'auto',
          marginVertical: 0,
          overflow: 'hidden',
        },
      })}>
      <CustomHeaderLoader />
      <View
        style={{
          marginTop: Metrics.verticalScale(70),
        }}>
        <CarouselLoader itemsCount={Platform.OS === 'web' ? 5 : 2} />
        <CarouselLoader itemsCount={Platform.OS === 'web' ? 5 : 2} />
      </View>
    </View>
  );
};

export default HomeContentLoader;
