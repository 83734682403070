import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const ShareLeadingCardStyles = StyleSheet.create({
  icContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: Metrics.verticalScale(17),
  },
  icFav: {
    marginLeft: Metrics.horizontalScale(19),
  },
});
