import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import ServiceFormAdditional from './ServiceFormAdditional';
import {AdditionalServiceData} from './Step/Step4';

export interface ServiceCheckBoxListProps {
  data: AdditionalServiceData[];
  value?: Array<string>;
  onChange?: (value: Array<string>, isSelected?: boolean) => void;
}

const ServiceCheckBoxList = (props: ServiceCheckBoxListProps) => {
  const {data, value: inValue, onChange} = props;
  const [value, setValue] = useState(inValue);

  useEffect(() => {
    if (inValue !== value) {
      setValue(inValue);
    }
  }, [inValue]);

  return (
    <View style={{marginTop: 31, marginBottom: 4}}>
      {data.map((d, index) => (
        <ServiceFormAdditional
          isSelected={value?.includes(d.id)}
          onChange={(isChecked: boolean) => {
            let tempValue: Array<string> = [];
            if (isChecked) {
              tempValue = value ? [...value, d.id] : [d.id];
            } else {
              tempValue = value!.filter(e => e !== d.id);
            }
            setValue(tempValue);
            onChange && onChange(tempValue);
          }}
          {...d}
          key={`s4as-${index}`}
        />
      ))}
    </View>
  );
};

export default ServiceCheckBoxList;
