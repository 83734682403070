import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import SectionPage from '@src/Umbraco/Pages/SectionPage/SectionPage';
import React, {FC} from 'react';

const RadioHomePage: FC = () => {
  const {page} = useRegionUrl();
  const sectionId = page.listen?.children.radio?.id;
  return (
    <div>
      <SectionPage id={sectionId} contentSource="listen" />
    </div>
  );
};

export default RadioHomePage;
