import useSignUpForm from '@src/Api/Umbraco/Hooks/useSignupForm';
import React from 'react';
import {FieldValues} from 'react-hook-form';
import UmbracoForm from '~Umbraco/Components/UmbracoForm/UmbracoForm';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import FlexBox from 'design-system/src/WebComponent/FlexBox/FlexBox.web';
import useSignUpSubmit from '../useSignUpSubmit';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

const SignUpInterest = () => {
  const {submit} = useSignUpSubmit();
  const {isMobile} = useBreakpoint();
  const dispatch = useAppDispatch();

  const authState = useAppSelector(state => state.appAuth);

  const {registerStep} = authState;

  const initialValue: FieldValues = authState.formData;

  const {t} = useTranslation('auth');

  const {additionnalStep} = useSignUpForm();

  const length = additionnalStep.length;
  const {title, subtitle, form} = additionnalStep[registerStep.step] || {};

  if (!form) return null;

  const handleSignUp = async (formData: any): Promise<boolean> => {
    dispatch(appAuthSlice.actions.setFormData(formData));

    if (registerStep.step < length - 1) {
      dispatch(
        appAuthSlice.actions.setAuthStep({
          page: 'interest',
          step: registerStep.step + 1,
        }),
      );
      return true;
    } else {
      return await submit(formData);
    }
  };
  return (
    <div
      style={{
        width: '100%',
        paddingBottom: 48,
      }}>
      <UmbracoForm
        form={form!}
        submitLabel={t('Continue') + ''}
        autoValidate={false}
        initialValue={initialValue}
        overrideSubmit={handleSignUp}
        // onSuccess={() => {
        //   navigation.navigate(AppRoutesEnum.HOME);
        // }}
        fieldMap={field => {
          if (
            Object.keys(field.preValues || {}).length > 0 &&
            (field.alias === 'interests' || field.alias === 'musicGenre')
          ) {
            return {
              ...field,
              defaultRules: {
                required: true,
                validate: value => {
                  if (Array.isArray(value)) {
                    return value.length >= 3
                      ? true
                      : t('Select at least 3') || '';
                  }
                  return t('Required') + '';
                },
              },
            };
          }
          return field;
        }}
        renderPageHeader={() => (
          <FlexBox direction="column" gap={17}>
            <Typography
              variant={isMobile ? 'body2' : 'formTitle'}
              style={{
                marginBottom: Metrics.verticalScale(12),
                textAlign: 'center',
              }}>
              {title}
            </Typography>
            <Typography
              variant={isMobile ? 'body3' : 'body2'}
              style={{
                marginBottom: Metrics.verticalScale(12),
                textAlign: 'center',
              }}>
              {subtitle}
            </Typography>
          </FlexBox>
        )}
      />
    </div>
  );
};

export default React.memo(SignUpInterest);
