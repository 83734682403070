import {useMemo} from 'react';
import {
  IJobTrainingOverviewFragmentFragment,
  useJobTrainingOverviewByIdQuery,
  useJobTrainingOverviewByUrlEndQuery,
} from '~Api/Graphql/gql/types.generated';
import {IJobTrainingOverview} from '~Umbraco/Utils/Overview/overview.interface';
import {OverviewService} from '~Umbraco/Utils/Overview/overview.service';

export const useGetJobTrainingOverview = (
  endUrl: string,
): {
  loading: boolean;
  overview: IJobTrainingOverview | undefined;
  courses?: IJobTrainingOverviewFragmentFragment['courses'];
} => {
  const response = useJobTrainingOverviewByUrlEndQuery({
    variables: {
      urlEnd: endUrl,
    },
    skip: !endUrl,
  });

  const jobTrainingOverviews = response?.data?.allJobTraining?.items?.[0];

  const {
    overview,
    courses,
  }: {
    overview?: IJobTrainingOverview;
    courses?: IJobTrainingOverviewFragmentFragment['courses'];
  } = useMemo(() => {
    if (jobTrainingOverviews) {
      return {
        overview: OverviewService.getJobTrainingOverView(jobTrainingOverviews),
        courses: jobTrainingOverviews.courses || [],
      };
    }
    return {
      overview: undefined,
      courses: undefined,
    };
  }, [jobTrainingOverviews]);

  return {
    loading: response.loading,
    overview,
    courses,
  };
};
