import React, {ReactNode} from 'react';
import GenericCard from '../../GenericCard/GenericCard';
import {LiveCardProps} from '../LiveCardProps';
import IcChanelSvg from '../../../../assets/svg/Logo_chaine.svg';
import RowCard from '../../RowCard/RowCard.web';
import LiveCardAside from '../LiveCardAside/LiveCardAside';
import ChannelLogo from '../../../ChannelLogo/ChannelLogo';
import Metrics from '../../../../Theme/Metrics';
import Images from '../../../../assets/Images';
import {View} from 'react-native';
import PremiumIcon from '../../PremiumIcon';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';

const CategoryLiveCard: React.FC<
  LiveCardProps & {renderLogo?: ReactNode; onPress?(): void}
> = props => {
  const {renderLogo, onPress, isFreemium, premiumText, type} = props;
  const {isMobile} = useBreakpoint();
  return (
    <RowCard
      flexDirection={'row'}
      left={
        <GenericCard
          onPress={onPress}
          coverImage={props.cover}
          type={type}
          center={
            !!renderLogo ? (
              renderLogo
            ) : props.channelLogo ? (
              typeof props.channelLogo === 'function' ? (
                props.channelLogo()
              ) : (
                <ChannelLogo
                  source={Images.channel[props.channelLogo]}
                  size={'small'}
                />
              )
            ) : (
              <IcChanelSvg />
            )
          }
          contentStyle={{
            paddingBottom: Metrics.verticalScale(4),
          }}
          footer={
            <View
              style={{
                position: 'absolute',
                bottom: Metrics.horizontalScale(9),
                right: Metrics.horizontalScale(9),
              }}>
              {isFreemium !== undefined && !isFreemium && <PremiumIcon />}
            </View>
          }
        />
      }
      right={
        <View
          style={{
            width: !isMobile ? Metrics.horizontalScale(200) : undefined,
            // marginLeft: isMobile
            //   ? Metrics.horizontalScale(16)
            //   : Metrics.horizontalScale(12),
          }}>
          <LiveCardAside asideProps={props} />
        </View>
      }
    />
  );
};

export default CategoryLiveCard;
