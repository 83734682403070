import {
  useAllCourseBySomeIdsLazyQuery,
  useAllJobTrainingBySomeIdsLazyQuery,
} from '@src/Api/Graphql/gql/types.generated';
import {useLazyGetAllCourseStatusQuery} from '@src/Api/TraceApi/traceApi';
import {RouteTypename} from '@src/Navigation/type';
import {useAppDispatch} from '@src/Store/hooks';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ProfileWrapper from '../ProfileWrapper/ProfileWrapper';
import {formatDuration} from '~Hooks/useStringExtension';
import {View, Pressable} from 'react-native';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import LearnRowCard from 'design-system/src/Components/Card/LearnCard/LearnRowCard/LearnRowCard.web';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import styled from 'styled-components';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import {useSelectObtainedJob} from '@src/Store/selectors/useMember';
import useUpdateBookmarks from '@src/Hooks/useUpdateBookmarks';
import FavorisIcon from 'design-system/src/Components/CheckBox/FavoirsIcon/FavorisIcon';
import MenuModal, {
  MenuModalProps,
} from 'design-system/src/Components/MenuModal/MenuModal.web';
import Icon from 'design-system/src/Components/Icon/Icon';
import useGetLinkToShare from '@src/utils/useGetLinkToShare';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';
import useShareContent from '@src/Hooks/useShareContent';

const ResponsiveWrapper = styled.div<{
  $rowGap: number;
}>`
  row-gap: ${({$rowGap}) => Metrics.verticalScale($rowGap)}px;
  column-gap: ${Metrics.horizontalScale(12)}px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media ${responsiveMap.mobile} {
    grid-template-columns: 1fr;
  }
  @media ${responsiveMap.md} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${responsiveMap.xxl} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

interface CourseItem {
  title?: string;
  cover?: string;
  id: string;
  url?: string;
  partner?: string;
  __typename: RouteTypename;
  length?: number;
  partnerId?: string;
  partnerUrl?: string;
  partnerPageUrl?: string;
  description?: string;
}

const ProfileAchievement = () => {
  const [currentLearn, setCurrentLearn] = useState<CourseItem | undefined>();
  const [linkToShare, setLinkToShare] = useState<string | null>(null);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  const {t} = useTranslation(['profile', 'learn']);
  const {isMobile} = useBreakpoint();
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const shareContent = useShareContent();
  const [fetchCourse, {isLoading}] = useLazyGetAllCourseStatusQuery();
  const [fetchCourses, {loading: courseLoading, data}] =
    useAllCourseBySomeIdsLazyQuery();
  const [fetchJobTraining, {loading: jobLoading, data: jobData}] =
    useAllJobTrainingBySomeIdsLazyQuery();
  const obtainedJob = useSelectObtainedJob();
  const [screenLoading, setScreenLoading] = useState<boolean>();
  const {isBookmarked, findBookmarkItem, updateBookMark} = useUpdateBookmarks();
  const {getLinkToShare} = useGetLinkToShare();

  // ------ handlers

  const handleFetch = useCallback(async () => {
    setScreenLoading(true);
    const {data: _data} = await fetchCourse();
    let courseIds: string[] = [];
    let jobIds: string[] = obtainedJob
      .filter(_item => _item && _item.finished)
      .map(cert => cert.certificateId);
    _data?.forEach(item => {
      if (item.finished) {
        courseIds.push(item.courseId);
      }
    });
    if (courseIds.length) {
      await fetchCourses({
        variables: {
          ids: courseIds,
        },
      });
    }
    if (jobIds.length) {
      await fetchJobTraining({
        variables: {
          ids: jobIds,
        },
      });
    }
    setScreenLoading(false);
  }, [fetchCourse, obtainedJob, fetchCourses, fetchJobTraining]);
  const loading = useMemo(() => {
    return isLoading || courseLoading || screenLoading || jobLoading;
  }, [isLoading, courseLoading, screenLoading, jobLoading]);

  const refresh = useCallback(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const list: CourseItem[] = useMemo(() => {
    let _list: Array<CourseItem> = [];
    if (data && data.courses && data.courses.items) {
      data.courses.items.forEach(item => {
        if (item && item.__typename === 'Course') {
          const {
            title,
            thumbnail,
            id,
            url,
            linkedPartners: partners,
            __typename,
            length,
            description,
          } = item;
          _list.push({
            title,
            cover: thumbnail?.cropUrl || thumbnail?.url,
            id,
            url,
            partner:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].logo?.url
                : undefined,
            __typename,
            length,
            partnerUrl:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].partnerUrl
                : undefined,
            partnerPageUrl:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].url
                : undefined,
            partnerId:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].id
                : undefined,
            description,
          });
        }
      });
    }
    if (jobData && jobData.allJobTraining && jobData.allJobTraining.items) {
      jobData.allJobTraining.items.forEach(item => {
        if (item && item.__typename === 'JobTraining') {
          const {title, cover, id, url, partners, __typename, length} = item;
          _list.push({
            title,
            cover:
              cover &&
              cover[0] &&
              cover[0].content &&
              cover[0].content.__typename === 'HeroVideoCover'
                ? cover[0].content.image?.cropUrl || cover[0].content.image?.url
                : undefined,
            id,
            url,
            partner:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].logo?.url
                : undefined,
            __typename,
            length,
            partnerUrl:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].url
                : undefined,
            partnerId:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].id
                : undefined,
          });
        }
      });
    }
    return _list;
  }, [data, jobData]);

  const onPressJob = (item: CourseItem) => {
    const {id, url, __typename} = item;
    if (__typename === 'Course') {
      navigation.navigate(AppRoutesEnum.LINKED_JOB, {
        state: {
          params: {
            id: id,
            url,
          },
        },
      });
    } else {
      navigation.navigate(AppRoutesEnum.SINGLE_JOB, {
        state: {
          params: {
            id: id,
            url,
          },
        },
      });
    }
  };

  const onPressPartner = (item: CourseItem) => {
    const {partnerId, partnerUrl, partnerPageUrl} = item;
    if (partnerId) {
      new RouteFactory(
        {
          url: partnerPageUrl || '',
          id: partnerId,
          __typename: 'Partner',
        },
        navigation,
      ).navigate();
    }
  };

  const onPressBookmark = (item: CourseItem, value: boolean) => {
    item &&
      updateBookMark(item.id, item.url || '', value, {
        id: item.id,
        url: item.url || '',
        __typename: item.__typename,
      });
  };

  const onPressShare = (item: CourseItem) => {
    shareContent.share({
      __typename: item.__typename,
      url: item.url || '',
      id: item.id,
      message: item.title,
      cover: item.cover || '',
      description: item.description || '',
    });
    const link = getLinkToShare({
      param: {
        id: item.id,
        __typename: item.__typename,
        url: item.url || '',
      },
    });
    setLinkToShare(link);
    setIsShareDialogOpen(true);
  };

  const dataMenuModal: MenuModalProps['content'] = useMemo(() => {
    if (currentLearn) {
      const {id, __typename} = currentLearn;
      return [
        {
          icon: 'share',
          label: t('Share'),
          value: 'share',
          onPress: () => {
            onPressShare(currentLearn);
          },
        },
        {
          icon: 'news',
          label: t('See the job'),
          value: 'job',
          onPress: () => {
            onPressJob(currentLearn);
          },
        },
        {
          icon: 'parametre',
          label: t('See the partner'),
          value: 'partner',
          onPress: () => {
            onPressPartner(currentLearn);
          },
        },
        {
          icon: () => (
            <FavorisIcon
              style={{padding: 13, margin: -13}}
              isLiked={isBookmarked(currentLearn.id)}
              onPressFav={(value: boolean) => {
                onPressBookmark(currentLearn, value);
              }}
              iconSize={'24'}
            />
          ),
          label: t('Favoris'),
          value: 'favoris',
        },
        // {
        //   icon: 'delete',
        //   label: t('Delete'),
        //   value: 'delete',
        // },
      ];
    }
    return [];
  }, [
    currentLearn,
    t,
    navigation,
    dispatch,
    isBookmarked,
    findBookmarkItem,
    updateBookMark,
  ]);

  const handleOpenAction = (learn: CourseItem) => () => {
    setCurrentLearn(learn);
  };

  const handleEmptyPress = () => {
    navigation.navigate(AppRoutesEnum.HOME_LEARN);
  };

  return (
    <ProfileWrapper title={t('Achievments') || ''} withSeparator>
      {loading ? (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <CustomActivityIndicator />
        </View>
      ) : (
        <View
          style={{
            paddingHorizontal: Metrics.horizontalScale(16),
            paddingTop: Metrics.verticalScale(16),
          }}>
          {list && list.length > 0 ? (
            <View
              style={{
                // borderTopWidth: 1,
                // borderTopColor: 'rgba(115, 115, 115, 0.5)',
                paddingTop: Metrics.verticalScale(20),
              }}>
              <ResponsiveWrapper $rowGap={isMobile ? 12 : 50}>
                {list.map((d, index) => {
                  const {title, cover, id, url, partner, __typename, length} =
                    d;
                  const duration = length
                    ? formatDuration(length, length < 3600)
                    : undefined;
                  return (
                    <View
                      key={`ac-${index}`}
                      style={{
                        marginBottom: Metrics.verticalScale(12),
                      }}>
                      <LearnRowCard
                        title={title || ''}
                        duration={duration}
                        cover={{
                          uri: cover,
                        }}
                        tag={{
                          variant: 'course',
                          text: t('course', {ns: 'learn'}),
                        }}
                        partenerLogo={partner}
                        onPress={() =>
                          new RouteFactory(
                            {
                              url: url || '',
                              id: id,
                              __typename,
                            },
                            navigation,
                            dispatch,
                            title || '',
                          ).navigate()
                        }
                        hasTrailingIcon={isMobile}
                        onPressTrailingIcon={handleOpenAction(d)}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 16,
                          marginTop: Metrics.verticalScale(14),
                        }}>
                        <Pressable
                          onPress={() => {
                            onPressShare(d);
                          }}>
                          <Icon name="share" />
                        </Pressable>
                        <Pressable
                          onPress={() => {
                            onPressJob(d);
                          }}>
                          <Icon name="news" />
                        </Pressable>
                        <Pressable
                          onPress={() => {
                            onPressPartner(d);
                          }}>
                          <Icon name="parametre" />
                        </Pressable>
                        <FavorisIcon
                          style={{padding: 13, margin: -13}}
                          isLiked={isBookmarked(d.id)}
                          onPressFav={(value: boolean) => {
                            onPressBookmark(d, value);
                          }}
                          iconSize={'24'}
                        />
                      </div>
                    </View>
                  );
                })}
              </ResponsiveWrapper>
              <MenuModal
                title={currentLearn?.title || ''}
                isOpen={!!currentLearn}
                content={dataMenuModal}
                onClose={() => setCurrentLearn(undefined)}
              />
              <ShareDialog
                isOpen={isShareDialogOpen}
                onClose={() => setIsShareDialogOpen(false)}
                link={linkToShare}
              />
            </View>
          ) : (
            <ContentEmpty
              title={t('achivments.empty.title')}
              content={t('achivments.empty.content') || ''}
              buttonTitle={t('achivments.empty.button') || ''}
              onPress={handleEmptyPress}
            />
          )}
        </View>
      )}
    </ProfileWrapper>
  );
};

export default ProfileAchievement;
