import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  FlatList,
  ListRenderItem,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';

export interface CardLinkItem {
  title: string;
  onPress?: () => void;
}
export interface ITipsCardProps {
  title: string;
  cover?: string;
  description: string;
  cardLinks: CardLinkItem[];
}

const ItemCard = (props: CardLinkItem) => {
  const {title, onPress} = props;
  const {t} = useTranslation('translation');
  return (
    <View style={tipsCardStyles.itemCardContainer}>
      <Typography numberOfLines={3} children={title} variant="body2" />
      <Pressable style={{padding: 13, margin: -13}} onPress={onPress}>
        <Typography
          children={t('See more')}
          color="primary"
          variant="button2"
        />
      </Pressable>
    </View>
  );
};

const TipsCard = (props: ITipsCardProps) => {
  const {title, cover, description, cardLinks} = props;
  const renderItem: ListRenderItem<CardLinkItem> = renderData => {
    const {index, item} = renderData;
    const isMiddle = index !== cardLinks.length - 1 || index !== 0;
    return (
      <View
        style={{
          marginHorizontal: isMiddle ? Metrics.horizontalScale(4) : undefined,
          marginTop: 'auto',
        }}>
        <ItemCard {...item} />
      </View>
    );
  };
  return (
    <View style={tipsCardStyles.container}>
      <View
        style={{
          flexDirection: 'row',
        }}>
        <CustomFastImage
          width={Metrics.horizontalScale(80)}
          height={Metrics.horizontalScale(80)}
          source={{
            uri: cover,
          }}
          style={{
            borderRadius: Metrics.horizontalScale(4),
          }}
        />
        <Typography
          children={title || ''}
          variant="h3"
          style={tipsCardStyles.title}
        />
      </View>
      <Typography
        children={description}
        variant="body3"
        color={() => '#A0A0A0'}
        style={tipsCardStyles.description}
      />
      <FlatList
        style={{marginTop: 21, marginBottom: 23}}
        data={cardLinks}
        keyExtractor={(_, index) => index + ''}
        renderItem={renderItem}
        horizontal
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

const tipsCardStyles = StyleSheet.create({
  container: {
    paddingHorizontal: Metrics.horizontalScale(16),
    paddingTop: Metrics.verticalScale(16),
    paddingBottom: Metrics.verticalScale(16),
    backgroundColor: '#1B1C21',
    borderRadius: Metrics.moderateScale(8),
    marginVertical: Metrics.verticalScale(8),
  },
  title: {
    marginLeft: Metrics.horizontalScale(24),
    flex: 1,
  },
  description: {
    marginTop: Metrics.verticalScale(18),
  },
  itemCardContainer: {
    width: Metrics.horizontalScale(152),
    height: Metrics.verticalScale(104),
    backgroundColor: '#000000',
    paddingVertical: Metrics.verticalScale(11),
    paddingHorizontal: Metrics.horizontalScale(16),
    justifyContent: 'space-between',
    borderRadius: Metrics.moderateScale(8),
  },
});

export default TipsCard;
