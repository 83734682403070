import React, {ReactNode} from 'react';
import ModalBackground from './ModalBackground';

interface IProfileWrapperProps {
  children: ReactNode;
  backgroundImage?: string;
}

const ProfileWrapper = (props: IProfileWrapperProps) => {
  const {children, backgroundImage} = props;
  return (
    <div
      style={{
        position: 'relative',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: '#111216',
      }}>
      <ModalBackground backgroundImage={backgroundImage} />
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}>
        {children}
      </div>
    </div>
  );
};

export default ProfileWrapper;
