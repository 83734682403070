import {globalHistory} from '@reach/router';
import {useAppSelector} from '@src/Store/hooks';
import {pathToRegexp} from 'path-to-regexp';

const useRouteActive = () => {
  const {i18n, language} = useAppSelector(
    state => state.page.currentPageContext,
  );

  const getAppRoutePrefix = () => {
    if (i18n) {
      const pathLang =
        language === i18n.defaultLanguage &&
        !i18n.path.startsWith(i18n.defaultLanguage)
          ? ''
          : `/${language}`;
      return `${pathLang}/app`;
    }
    return '/app';
  };

  const isRouteActive = (route: string[] = []): boolean => {
    const {
      location: {pathname: _currentRoute},
    } = globalHistory;
    const routeRegex = pathToRegexp(route, []);
    const appBasePath = getAppRoutePrefix();

    return routeRegex.test(_currentRoute.replace(appBasePath, ''));
  };
  return {isRouteActive};
};

export default useRouteActive;
