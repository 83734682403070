import {PartnerCard} from 'design-system/src/Components/Card';
import BlockSlider, {
  IBlockSliderItem,
  IBlockSliderProps,
} from 'design-system/src/Components/Card/HighlightedCarousel/BlockSlider/BlockSlider';
import {Maybe} from 'graphql/jsutils/Maybe';
import React from 'react';
import {ListRenderItem, View} from 'react-native';
import {RouteTypename} from '~Navigation/type';
import {IAccessTypParam, IPaywallParam} from '../PaywallChecker/PaywallChecker';
import {useTranslation} from 'gatsby-plugin-react-i18next';
export interface IPartenerSliderItem extends IBlockSliderItem {
  url: string;
  id: string;
  __typename: RouteTypename;
  partnerLogo: string;
  countriesRestriction?: Maybe<string>[];
  accessData: {
    accessType?: IAccessTypParam;
    paywall?: IPaywallParam;
  };
}
export interface IPartenerSliderProps
  extends Omit<IBlockSliderProps<IPartenerSliderItem>, 'premiumText'> {}
const PartnerSlider = (props: IPartenerSliderProps) => {
  const {onSeeMore, seeMore, onItemPress, ...rest} = props;
  const {t} = useTranslation('translation');
  const customRenderItem: ListRenderItem<IPartenerSliderItem> = ({
    item,
    index,
  }) => {
    return (
      <PartnerCard
        key={index}
        partenerLogo={
          item.partnerLogo
            ? {
                uri: item.partnerLogo,
              }
            : undefined
        }
        activeHover={true}
        onPress={() => {
          onItemPress?.(item);
        }}
      />
    );
  };
  return (
    <View>
      <BlockSlider
        seeMore={
          seeMore ||
          (onSeeMore && {label: t('See more'), onPress: onSeeMore}) ||
          undefined
        }
        premiumText={t('premium') as string}
        onItemPress={onItemPress}
        customRenderItem={customRenderItem}
        {...rest}
      />
    </View>
  );
};

export default PartnerSlider;
