import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import React from 'react';
import styled from 'styled-components';
import {View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import clsx from 'clsx';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import CourseAdvantage, {
  ICourseAdvantageProps,
} from './CourseAdvantage/CourseAdvantage';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import Metrics from 'design-system/src/Theme/Metrics';

export type ECoursePageTemplate = 'overview' | 'media' | 'text' | 'image';

interface ICoursePageWrapperProps {
  template?: ECoursePageTemplate;
  children?: React.ReactNode;
  imageCover?: string;
  title?: string;
  subtitle?: string;
  messages?: ICourseAdvantageProps['advantage'];
}

const _Root = styled.div(() => {
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };
});

const _Main = styled.div(() => {
  return {
    '&.template-media': {
      width: '100%',
      maxWidth: '407px',
      margin: '0 auto',
    },

    '&.template-text': {
      maxWidth: '407px',
      margin: '0 auto',
    },
  };
});
const _Header = styled.div(() => {
  return {
    '&.template-media': {
      position: 'absolute',
      right: '0px',
      width: '20%',
    },
    '&.template-text': {
      textAlign: 'center',
    },
  };
});

const CoursePageWrapper = (props: ICoursePageWrapperProps) => {
  const {template, children, imageCover, title, subtitle, messages} = props;
  const {isMobile} = useBreakpoint();
  const templateClass = `template-${isMobile ? '' : template}`;
  return (
    <_Root>
      {imageCover && (
        <>
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
            }}>
            <CustomFastImage
              source={{
                uri: imageCover,
              }}
              resizeMode="cover"
              width={'100%'}
              height={'100%'}
            />
          </View>
          <LinearGradient
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              opacity: 0.5,
            }}
            colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
            locations={[0, 0.7163, 1]}
            useAngle
            angle={360}></LinearGradient>
          <LinearGradient
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '100%',
            }}
            colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
            locations={[0, 0.5745, 0.7432]}
            useAngle
            angle={180}></LinearGradient>
        </>
      )}
      <_Header className={clsx('course-page-header', templateClass)}>
        {isMobile && !!messages && (
          <View
            style={{
              marginBottom: Metrics.verticalScale(24),
            }}>
            <CourseAdvantage advantage={messages} cardVertical={!isMobile} />
          </View>
        )}
        {!!title && (
          <Typography variant="h2" style={{marginTop: 40}}>
            {title}
          </Typography>
        )}
        {!!subtitle && (
          <Typography
            variant="body3"
            style={{
              marginTop: 18,
              maxWidth: 226,
            }}>
            {subtitle || ''}
          </Typography>
        )}
        {!isMobile && !!messages && (
          <View
            style={{
              marginTop: Metrics.verticalScale(28),
            }}>
            <CourseAdvantage advantage={messages} cardVertical={!isMobile} />
          </View>
        )}
      </_Header>
      <_Main className={clsx('course-page-main', templateClass)}>
        {children}
      </_Main>
    </_Root>
  );
};

export default CoursePageWrapper;
