import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {RouteTypename} from '@src/Navigation/type';

interface AppPodcastState {
  params: {
    id: string;
    items: any;
    selectedIndex: number;
    isPaused: boolean;
    currentTime: number;
    up: boolean;
    type: string;
    __typename?: RouteTypename;
    currentUrl?: string;
    title?: string;
  };
  isOpenPlayer: boolean;
  isMini: boolean;
}

const initialState: AppPodcastState = {
  params: {
    id: '',
    items: [],
    selectedIndex: 0,
    isPaused: true,
    currentTime: 0,
    up: false,
    type: '',
  },
  isOpenPlayer: false,
  isMini: false,
};

const appPodcastSlice = createSlice({
  name: 'appPodcast',
  initialState,
  reducers: {
    setPodcastParams(state, action: PayloadAction<AppPodcastState['params']>) {
      state.params = action.payload;
      return state;
    },
    openPlayer(state) {
      state.isOpenPlayer = true;
      return state;
    },
    closePlayer(state) {
      state.isOpenPlayer = false;
      return state;
    },
    setMiniPlayer(state, action: PayloadAction<boolean>) {
      state.isMini = action.payload;
      return state;
    },
  },
});

export default appPodcastSlice;
