import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberJobProgressBody} from './interface/memberDataBody.interface';

const endpoint = '/me/job-progress';
export const createMemberJobProgressEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getMemberJobProgressById: builder.query<any, {id: string}>({
    query: ({id}) => ({
      url: `${endpoint}?contentLink=${id}`,
      method: 'GET',
    }),
  }),
  getAllMemberJobProgress: builder.query<any, void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  updateMemberJobProgress: builder.query<
    any,
    {id?: string; data: IMemberJobProgressBody}
  >({
    query: ({id, data}) => ({
      url: id ? `${endpoint}/${id}` : endpoint,
      method: id ? 'PUT' : 'POST',
      body: data,
    }),
  }),
});
