import ActionGroup from 'design-system/src/Components/ActionGroup/ActionGroup';
import Button from 'design-system/src/Components/Button/Button';
import FavorisIcon from 'design-system/src/Components/CheckBox/FavoirsIcon/FavorisIcon';
import {SingleContentLoader} from 'design-system/src/Components/ContentLoader';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader';
import {ReadMoreText} from 'design-system/src/Components/ReadMoreText';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {Fragment, useEffect, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {
  IQuizzSurveyCollectionFragmentFragment,
  useQuizzSurveyCollectionByIdLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import useShareContent from '~Hooks/useShareContent';
import useUpdateBookmarks from '~Hooks/useUpdateBookmarks';
import RelatedQuizz from './RelatedQuizz';
import {useFindProgression} from '~Hooks/useFindProgression';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import LearnRowCard from 'design-system/src/Components/Card/LearnCard/LearnRowCard/LearnRowCard.web';
import GridView from 'design-system/src/Components/GridView/GridView.web';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';
/**
 * QuizzSurveyCollectionScreen Component
 *
 * This component displays a collection of quizzes and surveys.
 * It fetches the collection data, renders a header, action buttons,
 * description, list of quizzes/surveys, and related quizzes.
 */

const QuizzSurveyCollectionScreen = () => {
  // Hooks for navigation, translation, and data fetching
  const params = useParams();
  const navigation = useAppNavigation();
  const {id} = params || {};
  const [fetchCollectionById, {data, loading}] =
    useQuizzSurveyCollectionByIdLazyQuery();
  const shareContent = useShareContent({screenSource: 'detail'});
  const {t} = useTranslation('learn');
  const {isBookmarked, updateBookMark} = useUpdateBookmarks({
    screenSource: 'detail',
  });
  const {isMobile} = useBreakpoint();
  const {findProgression} = useFindProgression();

  // Constants
  const containerPadding = isMobile ? 16 : 60;

  // Fetch collection data on component mount or when id changes
  useEffect(() => {
    if (id) {
      fetchCollectionById({
        variables: {id},
        errorPolicy: 'all',
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [fetchCollectionById, id]);

  // Memoized values derived from fetched data
  const {collection, list, firstQuizzSurvey, cover} = useMemo(() => {
    const element: IQuizzSurveyCollectionFragmentFragment | undefined =
      data?.quizzSurveyCollection;
    let _cover: string | undefined;
    const _list = data?.quizzSurveyCollection?.children?.items || [];
    const firstQuizzSurvey: IQuizzSurveyCollectionFragmentFragment['children']['items'][number] =
      _list[0];

    if (element) {
      const {cover, thumbnail} = element;
      _cover =
        cover &&
        cover[0] &&
        cover[0].content &&
        cover[0].content.__typename === 'HeroVideoCover'
          ? cover[0].content.image?.cropUrl || cover[0].content.image?.url
          : thumbnail?.optiUrl || thumbnail?.url;
    }
    return {
      collection: element,
      firstQuizzSurvey,
      cover: _cover,
      list: _list,
    };
  }, [data, findProgression]);

  // Render loading state or main content
  return (
    <View style={{flex: 1}}>
      <DebugUmbracoId umbracoId={id} position="page" />
      {loading ? (
        <SingleContentLoader />
      ) : (
        <>
          {/* Header component */}
          <ServiceHeader
            key={'header'}
            image={cover ? {uri: cover} : undefined}
            title={collection?.title || ''}
            buttonProps={{icon: 'learn'}}
            buttonTitle={
              (firstQuizzSurvey && collection?.playerCTALabel) ||
              (t('start watching') as string)
            }
            onPressButton={() => {
              // Navigate to quiz/survey player
              if (
                firstQuizzSurvey &&
                firstQuizzSurvey.__typename === 'QuizzSurvey'
              ) {
                const {id, url, __typename} = firstQuizzSurvey;
                navigation.navigate(AppRoutesEnum.PLAYER_QUIZ_SURVEY, {
                  state: {
                    params: {
                      id,
                      __typename,
                      url,
                      type: 'url',
                      collectionId: collection?.id,
                    },
                  },
                });
              }
            }}
          />

          {/* Action buttons (Favorites and Share) */}
          <View
            key={'action-group'}
            style={{marginTop: Metrics.verticalScale(36)}}>
            <ActionGroup
              data={[
                {
                  icon: (
                    <FavorisIcon
                      style={{padding: 13, margin: -13}}
                      isLiked={isBookmarked(collection?.id || '')}
                      onPressFav={(value: boolean) => {
                        // Update bookmark status
                        if (collection) {
                          collection.id &&
                            collection.url &&
                            updateBookMark(
                              collection.id,
                              collection.url,
                              value,
                              {
                                id: collection.id,
                                url: collection.url,
                                __typename: collection.__typename,
                                name: collection.title,
                                raw: collection,
                              },
                            );
                        }
                      }}
                      iconSize={'24'}
                    />
                  ),
                  label: t('Favoris'),
                },
                {
                  icon: 'share',
                  label: t('Share'),
                  onPress: () => {
                    // Share collection
                    collection &&
                      collection.url &&
                      shareContent.share({
                        __typename: collection.__typename,
                        url: collection.url,
                        message: collection.title,
                        id: collection.id,
                        cover:
                          collection.thumbnail && collection.thumbnail.url
                            ? collection.thumbnail.url
                            : '',
                        description: collection.shortDescription || '',
                        title: collection.title,
                      });
                  },
                },
              ]}
            />
          </View>

          <View style={{paddingHorizontal: containerPadding}}>
            {/* Collection description */}
            {collection?.shortDescription && (
              <View style={{marginTop: Metrics.verticalScale(32)}}>
                <ReadMoreText
                  seeMoreLabel={t('See more') as string}
                  seeLessLabel={t('See less') as string}
                  text={collection.shortDescription}
                />
              </View>
            )}

            {/* List of quizzes/surveys */}
            <View style={{marginTop: Metrics.verticalScale(32)}}>
              <View>
                <GridView
                  mobileColumns={1}
                  data={list}
                  noScroll
                  xPaddingWrap={4}
                  yPadding={4}
                  disableBigItem={true}
                  renderItem={(item, index) => {
                    if (item && item.__typename === 'QuizzSurvey') {
                      const {
                        title,
                        shortDescription,
                        thumbnail,
                        __typename,
                        id,
                        url,
                      } = item;
                      return (
                        <View
                          key={`sur-${index}`}
                          style={{marginBottom: Metrics.verticalScale(12)}}>
                          <LearnRowCard
                            title={title || ''}
                            description={shortDescription}
                            cover={{
                              uri: thumbnail?.optiUrl || thumbnail?.url,
                            }}
                            onPress={() => {
                              // Navigate to individual quiz/survey
                              navigation.navigate(
                                AppRoutesEnum.PLAYER_QUIZ_SURVEY,
                                {
                                  state: {
                                    params: {
                                      id,
                                      __typename,
                                      url,
                                      type: 'url',
                                      collectionId: collection?.id,
                                    },
                                  },
                                },
                              );
                            }}
                            customHeight={192}
                            customWidth={168}
                            progress={Math.min(findProgression(id), 1)}
                            showPlay
                          />
                        </View>
                      );
                    }
                    return <Fragment key={index}></Fragment>;
                  }}
                />
              </View>

              {/* Test button (if collection has questions) */}
              {!!collection?.questions?.length && (
                <View
                  style={{
                    paddingHorizontal: Metrics.horizontalScale(16),
                    marginTop: Metrics.verticalScale(40),
                    paddingBottom: Metrics.verticalScale(40),
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: isMobile ? undefined : 'center',
                  }}>
                  <Button
                    title={collection.quizzCTALabel || t('Test available')}
                    variant="contained"
                    style={{width: '100%'}}
                    size="medium"
                    onPress={() => {
                      // Navigate to quiz/survey test
                      if (collection) {
                        navigation.navigate(AppRoutesEnum.QUIZZ_SURVEY, {
                          state: {
                            params: {
                              id: collection?.id,
                              __typename: collection?.__typename,
                            },
                          },
                        });
                      }
                    }}
                  />
                </View>
              )}
            </View>
          </View>

          {/* Related quizzes */}
          {collection && <RelatedQuizz id={collection.id} />}
        </>
      )}
    </View>
  );
};

export default QuizzSurveyCollectionScreen;
