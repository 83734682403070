import styled, {css} from 'styled-components';
import {responsiveMap} from '../_utils/responsiveObserve';
import {ContainerProps} from './Container';

export const _ContainerRoot = styled('div')<{
  $bg?: string;
  $mt?: ContainerProps['mt'];
  $mb?: ContainerProps['mb'];
}>(
  ({$bg, $mb, $mt}) => {
    return {
      backgroundColor: $bg,
      marginTop: $mt,
      marginBottom: $mb,
    };
  },
  () => {
    const mobile = '16px';
    const desktop = '60px';
    return css`
      @media ${responsiveMap.xs} {
        padding-left: ${mobile};
        padding-right: ${mobile};
      }
      @media ${responsiveMap.sm} {
        padding-left: ${mobile};
        padding-right: ${mobile};
      }
      @media ${responsiveMap.md} {
        padding-left: ${desktop};
        padding-right: ${desktop};
      }
      @media ${responsiveMap.lg} {
        padding-left: ${desktop};
        padding-right: ${desktop};
      }
      @media ${responsiveMap.xl} {
        padding-left: ${desktop};
        padding-right: ${desktop};
      }
      @media ${responsiveMap.xxl} {
        padding-left: ${desktop};
        padding-right: ${desktop};
      }
    `;
  },
);
