import {memberTransformer} from '~Api/Umbraco/ContentManagmentApi/endpoints/members.endpoint';
import {useAppSelector} from '../hooks';

const useMember = () => {
  const memberState = useAppSelector(({member}) => member.member);

  if (memberState && !memberState.memberDatasId) {
    return memberTransformer(memberState);
  }
  return memberState;
};

export const useMemberDatas = () => {
  return useAppSelector(({member}) => member.memberDatas);
};

export const useSelectMemberWatched = () => {
  return useAppSelector(({member}) => member.watched || []);
};

export const useSelecteAllWatched = () => {
  return useAppSelector(({member}) => member.allWatched || {});
};

export const useSelecteAllSearch = () => {
  return useAppSelector(({member}) => member.allSearch || []);
};

export const useSelecteSubscription = () => {
  return useAppSelector(({member}) => member.memberSubscription);
};

export const useSelecteUnitsProgression = () => {
  return useAppSelector(({member}) => member.unitsProgression || []);
};

export const useFirebaseMember = () => {
  return useAppSelector(({member}) => member.firebaseUser);
};

export const useSelectCoursesStatus = () => {
  return useAppSelector(({member}) => member.coursesStatus) || [];
};

export const useSelectObtainedJob = () => {
  return useAppSelector(({member}) => member.obtainedJob) || [];
};

export const useIsListenMiniPlayer = () => {
  const isMiniRadio = useAppSelector(({appListen}) => appListen.isMini);
  const isMiniPodcast = useAppSelector(({appPodcast}) => appPodcast.isMini);
  return isMiniRadio || isMiniPodcast;
};

export default useMember;
