import usePressableHover from './usePressableHover';
import React from 'react';
import {Pressable, PressableProps} from 'react-native';

export interface PressableStateCallbackType {
  readonly hover: boolean;
}

interface IPressableCustomProps
  extends Omit<PressableProps, 'children' | 'onHoverIn' | 'onHoverOut'> {
  children?:
    | React.ReactNode
    | ((state: PressableStateCallbackType) => React.ReactNode);
  onHoverChange?: (hover: boolean) => void;
}

const PressableCustom = (props: IPressableCustomProps) => {
  const {children, onHoverChange, ...restProps} = props;
  const {hover, onHoverIn, onHoverOut} = usePressableHover({
    onChange: onHoverChange,
  });

  return (
    <Pressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} {...restProps}>
      {typeof children === 'function' ? children({hover}) : children}
    </Pressable>
  );
};

export default PressableCustom;
