import {
  ILiveEventFragmentFragment,
  IWatchCategFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import DateFactory from '~tools/DateFactory';
import {ICategorySliderItem} from '~Umbraco/Components/CategorySlider/CategorySlider';

export const mapCategoryChildrenItems = (
  children: IWatchCategFragmentFragment['children'],
  findProgression: (id: string) => number,
  canSeeContent: (
    accessType?: ILiveEventFragmentFragment['accessType'],
  ) => boolean,
  calculateLiveEventProgress?: (startDate: any, endDate: any) => number,
  options?: {
    showCover?: boolean;
  },
): ICategorySliderItem[] => {
  const {showCover} = options || {};
  const items: ICategorySliderItem[] = [];

  children.items?.forEach(item => {
    if (item && !isElementRestricted(item)) {
      if (item.__typename === 'Collection' && item.url && item.url !== '#') {
        const {id, title, subtitle, thumbnail, url = '', __typename} = item;
        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            isFreemium: canSeeContent(item.accessType),
          });
      } else if (
        item.__typename === 'VODItem' &&
        item.url &&
        item.url !== '#'
      ) {
        const {
          id,
          title,
          subtitle,
          name,
          shortDescription,
          thumbnail,
          url = '',
          __typename,
          vimeoHlsUrl,
        } = item;

        const parentAccessType =
          item.parent && item.parent.__typename === 'Collection'
            ? item.accessType
            : undefined;

        url &&
          items.push({
            id,
            title: title || name || '',
            subtitle: subtitle || shortDescription,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            //TODO value of max duration if length not in min
            progress: findProgression(id),
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            vimeoHlsUrl: vimeoHlsUrl,
            isFreemium:
              canSeeContent(item.accessType) && canSeeContent(parentAccessType),
          });
      } else if (item.__typename === 'Radio' && item.url && item.url !== '#') {
        const {
          id,
          title,
          subtitle,
          thumbnail,
          url = '',
          cover,
          __typename,
        } = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: showCover
              ? (cover &&
                  cover[0] &&
                  cover[0].content &&
                  cover[0].content.__typename === 'HeroVideoCover' &&
                  cover[0].content.image && {
                    uri: cover[0].content.image.url,
                  }) ||
                undefined
              : thumbnail
                ? {
                    uri: thumbnail.url,
                  }
                : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
      } else if (
        item.__typename === 'Podcast' &&
        item.url &&
        item.url !== '#'
      ) {
        const {id, title, subtitle, thumbnail, url = '', __typename} = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: findProgression(id),
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
      } else if (
        item.__typename === 'Playlist' &&
        item.url &&
        item.url !== '#'
      ) {
        const {id, title, subtitle, thumbnail, url = '', __typename} = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
          });
      } else if (
        item.__typename === 'PodcastItem' &&
        item.url &&
        item.url !== '#'
      ) {
        const {
          id,
          title,
          shortDescription,
          thumbnail,
          url = '',
          __typename,
          vimeoHlsUrl,
        } = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: shortDescription,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            //TODO value of max duration if length not in min
            progress: findProgression(id),
            accessData: {},
            vimeoHlsUrl,
          });
      } else if (
        item.__typename === 'LiveEvent' &&
        item.url &&
        item.url !== '#'
      ) {
        const {
          id,
          title,
          subtitle,
          thumbnail,
          url = '',
          __typename,
          startDate,
          endDate,
        } = item;

        url &&
          items.push({
            id,
            title: title || '',
            subtitle: subtitle,
            cover: thumbnail
              ? {
                  uri: thumbnail.url,
                }
              : undefined,
            url,
            __typename,
            progress: calculateLiveEventProgress
              ? calculateLiveEventProgress(startDate, endDate)
              : 0.0,
            accessData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            isFreemium: canSeeContent(item.accessType),
          });
      } else if (item.__typename === 'News' && item.url && item.url !== '#') {
        if (item && item.__typename === 'News') {
          const {
            title,
            thumbnail,
            shortDescription,
            pubDate,
            url,
            id,
            __typename,
            vimeoHlsUrl,
          } = item;
          if (!isElementRestricted(item) && url) {
            url &&
              items.push({
                id,
                title: title || '',
                subtitle: shortDescription || '',
                cover: thumbnail
                  ? {
                      uri: thumbnail.url,
                    }
                  : undefined,
                url,
                __typename,
                extraContent: pubDate
                  ? [
                      {
                        type: 'date',
                        date: new DateFactory(pubDate).format(),
                      },
                    ]
                  : undefined,
                accessData: {
                  accessType: item.accessType,
                  paywall: item.paywall,
                },
                vimeoHlsUrl,
              });
          }
        }
      }
    }
  });

  return items;
};
