import DropDownButton from 'design-system/src/Components/Button/DropDownButton/DropDownButton';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {ScrollView, View} from 'react-native';
import {
  IOpportunityFragmentFragment,
  IOpportunityOrderByInput,
  useGetAllOpportunityLazyQuery,
  useGetOpportunityCenterByUrlEndLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import {OpportunitiesData, OpportunityItem} from './components/OpportunityItem';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {IBubbleChartData} from 'design-system/src/Components/BubbleChart/BubbleChart';
import BubbleChartOverview from 'design-system/src/Widget/BubbleChartOverview/BubbleChartOverview.web';
import {CustomBottomSheetItem} from 'design-system/src/Components/CustomBottomSheet/CustomBottomSheet';
import {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import {IconTextButton} from 'design-system/src/Components/Button';
import {useCountUnitProgression} from './Hooks/useCountUnitProgression';
import {RouteTypename} from '~Navigation/type';
import useParams from '@src/Hooks/useParams';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import {OpportunityOverview} from '@src/utils/OpportunityOverview/OpportunityOverview.service';
import PaddingContainer from '@src/App/components/Containers/PaddingContainer/PaddingContainer';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';
interface ItemData {
  __typename: RouteTypename;
  url: string;
  id: string;
  title?: string;
}

const OpportunitiesScreen = () => {
  const params = useParams();

  const {endUrl, title, subtitle} = params || {};

  const [currentData, setCurrentData] = useState<{
    opportunityTitle: string | undefined;
    opportunitySubtitle: string | undefined;
    id?: string;
  }>({
    opportunityTitle: title,
    opportunitySubtitle: subtitle,
    id: undefined,
  });
  const [fetchOpportunities, {loading: oppLoading}] =
    useGetAllOpportunityLazyQuery();
  const {t} = useTranslation(['learn']);
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const {countCourse, loading: counterLoading} = useCountUnitProgression();
  const [fetchOpportunityCenter, {loading: centerLoading}] =
    useGetOpportunityCenterByUrlEndLazyQuery();

  const {
    profile: {cover},
  } = useAppSelector(state => state.settings);

  const {isMobile} = useBreakpoint();

  useEffect(() => {
    if (endUrl) {
      fetchOpportunityCenter({
        variables: {
          urlEnd: endUrl,
        },
      }).then(({data}) => {
        const opportunityCenter = data?.allOpportunityCenter?.items?.[0];
        if (opportunityCenter) {
          const {opportunityTitle, opportunitySubtitle} = opportunityCenter;
          setCurrentData({
            opportunityTitle,
            opportunitySubtitle,
            id: opportunityCenter.id,
          });
        }
      });
    }
  }, [endUrl]);

  const [opportunitiesData, setOpportunitiesData] = useState<
    OpportunitiesData<ItemData>[]
  >([]);

  const [bubbleData, setBubbleData] = useState<IBubbleChartData[]>([]);

  const [orderBy, setOrderBy] = useState<IOpportunityOrderByInput>();

  const data: Array<CustomBottomSheetItem> = useMemo(() => {
    return [
      {
        item: IOpportunityOrderByInput.NameDesc,
        label: t('Name'),
      },
      {
        item: IOpportunityOrderByInput.CreateDateAsc,
        label: t('Created date'),
      },
    ];
  }, [t]);

  // Fetch opportunities whenever the orderBy state changes
  useEffect(() => {
    fetchOpportunities({
      variables: {
        orderBy: orderBy as IOpportunityOrderByInput, // Use the current orderBy state for fetching
      },
    }).then(response => {
      const {data} = response; // Extract data from the response
      if (data && data.allOpportunity && data.allOpportunity.items) {
        let opportunities: OpportunitiesData<ItemData>[] = []; // Initialize an array to store opportunities data
        let _opportunities: IOpportunityFragmentFragment[] = []; // Initialize an array to store raw opportunity fragments

        // Iterate over each item in the fetched opportunities
        data.allOpportunity.items.forEach(item => {
          if (item && item.__typename === 'Opportunity') {
            _opportunities.push(item); // Add the raw opportunity fragment to the array

            // Destructure necessary fields from the item
            const {
              title,
              thumbnail,
              partners,
              location,
              contractType,
              certificate,
              shortDescription,
              __typename,
              url,
              id,
            } = item;

            let _certificates: Array<ItemData> = []; // Initialize an array to store certificate data

            // Iterate over each certificate in the item
            certificate?.forEach(item => {
              if (item && item.__typename === 'JobTraining') {
                const {__typename, id, url, title} = item; // Destructure necessary fields from the certificate
                _certificates.push({
                  __typename,
                  url: url || '', // Ensure URL is not undefined
                  id,
                  title,
                });
              }
            });

            // Add the processed opportunity data to the opportunities array
            opportunities.push({
              title: title || '', // Ensure title is not undefined
              cover: thumbnail?.url, // Extract the URL of the thumbnail
              partner:
                partners && partners[0] && partners[0].__typename === 'Partner'
                  ? partners[0].partnerName // Extract the partner name if available
                  : undefined,
              location: location || '', // Ensure location is not undefined
              contractType: contractType || '', // Ensure contract type is not undefined
              certficates: _certificates, // Add the processed certificates
              description: shortDescription || '', // Ensure description is not undefined
              __typename,
              url,
              id,
            });
          }
        });

        // Generate bubble chart data from the raw opportunity fragments
        let _bubbleData = OpportunityOverview.getOppOverview(_opportunities);
        setBubbleData(_bubbleData); // Update the state with the new bubble chart data
        setOpportunitiesData(opportunities); // Update the state with the new opportunities data
      }
    });
  }, [orderBy]); // Dependency array to trigger the effect when orderBy changes

  const sheetRef = useRef<BottomSheetMethods>(null);

  const handleBottomSheet = useCallback((index: number) => {
    sheetRef.current?.snapToIndex(index);
  }, []);

  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        width: '100%',
        backgroundColor: '#111216',
        height: '100%',
      }}>
      <DebugUmbracoId umbracoId={currentData.id} position="page" />
      {!isMobile && (
        <div
          style={{
            aspectRatio: 1440 / 642,
            position: 'absolute',
            zIndex: 0,
            backgroundImage: `url(${cover})`,
            backgroundSize: 'cover',
            width: '100%',
          }}>
          <div
            style={{
              background:
                'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 44.86%, rgba(17, 18, 22, 0.80) 71.63%, #111216 100%)',
              width: '100%',
              height: '100%',
            }}></div>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          backgroundColor: '#00000066',
        }}>
        {oppLoading || counterLoading || centerLoading ? (
          <CustomActivityIndicator style={{flex: 1}} />
        ) : (
          <>
            <ScrollView
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
                paddingTop: Metrics.verticalScale(isMobile ? 86 : 338),
                paddingBottom: Metrics.verticalScale(isMobile ? 90 : 20),
              }}>
              <BubbleChartOverview
                height={242}
                data={bubbleData}
                title={currentData.opportunityTitle || ''}
                subtitle={currentData.opportunitySubtitle || ''}
                desc={t(`{{info}} opportunities you may be interested in`, {
                  info: `${opportunitiesData.length}`,
                })}
                courseCompleted={countCourse.finished}
                courseCompletedTitle={t(
                  'The number of courses you have completed',
                )}
                courseStarted={countCourse.started}
                courseStartedTitle={t('The number of courses you have started')}
              />
              <PaddingContainer edgeHoriz={0} top={53} bottom={10}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <DropDownButton
                    text={t('Opportunities')}
                    style={{paddingVertical: Metrics.verticalScale(10)}}
                    variant="h2"
                    noIcon
                  />
                  <IconTextButton
                    text={t('Sort by')}
                    textColor="white"
                    icon={'sortby'}
                    onPress={() => {
                      handleBottomSheet(0);
                    }}
                  />
                </View>
              </PaddingContainer>
              <AppGrid nbColumns={isMobile ? 1 : 3} columnGap={12}>
                {opportunitiesData.map((oppurtinty, index) => {
                  return (
                    <OpportunityItem<ItemData>
                      onPress={() => {
                        const {url, id, __typename} = oppurtinty;
                        new RouteFactory(
                          {url: url || '', id, __typename},
                          navigation,
                          dispatch,
                        ).navigate();
                      }}
                      key={`${index}-op`}
                      onItemPress={item => {
                        const {url, id, __typename} = item;
                        new RouteFactory(
                          {url: url || '', id, __typename},
                          navigation,
                          dispatch,
                        ).navigate();
                      }}
                      {...oppurtinty}
                    />
                  );
                })}
              </AppGrid>

              {/* <CustomBottomSheet
              title={t('Sort by') || ''}
              data={data.map(element => ({
                ...element,
                isActive: element.item === orderBy,
              }))}
              ref={sheetRef}
              onItemPress={(item: CustomBottomSheetItem) => {
                setOrderBy(item.item as IOpportunityOrderByInput);
                sheetRef.current?.close();
              }}
            /> */}
            </ScrollView>
          </>
        )}
      </div>
    </View>
  );
};

export default OpportunitiesScreen;
