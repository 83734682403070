import React, {useMemo, useCallback} from 'react';
import {ScrollView, StyleSheet, View, Pressable} from 'react-native';
import {NotificationData} from 'design-system/src/Components/NotificationContent/NotificationContent';
import Metrics from 'design-system/src/Theme/Metrics';
import Typography from 'design-system/src/Components/Typography/Typography';
import LearnRowCard from 'design-system/src/Components/Card/LearnCard/LearnRowCard/LearnRowCard';
import SearchLiveCard from 'design-system/src/Components/Card/LiveCard/SearchLiveCard/SearchLiveCard';
import {LearnCardProps} from 'design-system/src/Components/Card/LearnCard/LearnCardProps';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import useMemberNotify from '~Hooks/useMemberNotify';
import DateFactory from '~tools/DateFactory';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {RouteTypename} from '~Navigation/type';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import {useDispatch} from 'react-redux';
import {INotifyData} from '~Store/Slices/Member.slice';
import useAppNavigation from '@src/utils/useAppNavigation';

const ProfileNotificationList = () => {
  const {t} = useTranslation(['profile', 'translations']);
  const navigation = useAppNavigation();
  const tracker = useTracker();
  const {
    data: notify,
    refresh,
    isRemoving,
    removeNotify,
    markAsRead,
    loading,
  } = useMemberNotify();
  const dispatch = useDispatch();

  const handlePress = useCallback(
    (
      // eslint-disable-next-line prettier/prettier
      notif: INotifyData,
      // eslint-disable-next-line prettier/prettier
      data: {__typename: RouteTypename; url: string; id: string},
      // eslint-disable-next-line prettier/prettier
    ) =>
      () => {
        if (notif && !notif.read) {
          markAsRead(notif.contentId);
        }
        tracker.general.actionOnNotification('open', {
          __typename: data.__typename,
        });
        new RouteFactory(
          {
            __typename: data.__typename,
            url: data.url,
            id: data.id,
          },
          navigation,
          dispatch,
        ).navigate();
      },
    [dispatch, markAsRead, navigation, tracker.general],
  );

  const data: NotificationData[] = useMemo(() => {
    const list: {[_typename: string]: NotificationData} = {
      Collection: {
        title: t('New season'),
        type: 'season',
        content: [],
      },
      VodItem: {
        title: t('New episode'),
        type: 'episode',
        content: [],
      },
      LiveEvent: {
        title: t('Events'),
        type: 'episode',
        content: [],
      },
    };
    notify?.forEach(item => {
      if (item.content) {
        if (item.content.__typename === 'Collection') {
          const {
            title,
            releaseDate,
            shortDescription,
            thumbnail,
            url,
            id,
            __typename,
          } = item.content;
          url &&
            id &&
            list.Collection.content.push({
              title: title || '',
              duration: new DateFactory(releaseDate).format(),
              description: shortDescription,
              cover: (thumbnail?.url && {uri: thumbnail?.url}) || undefined,
              onPress: handlePress(item, {
                __typename: __typename,
                url: url,
                id,
              }),
              rawData: item,
            });
        } else if (item.content.__typename === 'VODItem') {
          const {
            title,
            releaseDate,
            shortDescription,
            thumbnail,
            __typename,
            url,
            id,
          } = item.content;
          url &&
            id &&
            list.VodItem.content.push({
              title: title || '',
              duration: new DateFactory(releaseDate).format(),
              description: shortDescription,
              cover: (thumbnail?.url && {uri: thumbnail?.url}) || undefined,
              rawData: item,
              onPress: handlePress(item, {
                __typename: __typename,
                url: url,
                id,
              }),
            });
        } else if (item.content.__typename === 'LiveEvent') {
          const {
            title,
            startDate,
            shortDescription,
            thumbnail,
            __typename,
            url,
            id,
          } = item.content;
          url &&
            id &&
            list.LiveEvent.content.push({
              title: title || '',
              duration: new DateFactory(startDate).format(),
              description: shortDescription,
              cover: (thumbnail?.url && {uri: thumbnail?.url}) || undefined,
              rawData: item,
              onPress: handlePress(item, {
                __typename: __typename,
                url: url,
                id,
              }),
            });
        }
        // TODO: handle new course
      }
    });
    return Object.values(list) || [];
  }, [handlePress, notify, t]);

  const handleItemRemove = (rawData: INotifyData) => async () => {
    console.log('remove raw item', rawData._id);
    await removeNotify(rawData._id);
    rawData.content?.__typename &&
      tracker.general.actionOnNotification('remove', {
        __typename: rawData.content?.__typename,
      });
  };

  const hasElement =
    data.length > 0 && data.reduce((acc, d) => acc + d.content.length, 0) > 0;

  return (
    <ScrollView contentContainerStyle={styles.scrollView}>
      <View
        style={{
          paddingHorizontal: Metrics.horizontalScale(16),
          marginTop: Metrics.verticalScale(14),
        }}>
        {loading ? (
          <View
            style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <CustomActivityIndicator />
          </View>
        ) : hasElement ? (
          data.map(
            (d, index) =>
              !!d.content.length && (
                <View
                  key={`nc-${index}`}
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: 'rgba(115, 115, 115, 0.5)',
                    paddingBottom: Metrics.horizontalScale(6),
                  }}>
                  <Typography
                    variant="h4"
                    style={{
                      marginBottom: Metrics.horizontalScale(13),
                      paddingTop: Metrics.horizontalScale(19),
                    }}>
                    {d.title}
                  </Typography>
                  {d.content.map(dc => {
                    return (
                      <View
                        style={{
                          backgroundColor: defaultTheme.page.background,
                          paddingBottom: Metrics.verticalScale(16),
                        }}>
                        <Pressable onPress={dc.onPress}>
                          {d.type === 'course' ? (
                            <LearnRowCard {...(dc as LearnCardProps)} />
                          ) : (
                            <SearchLiveCard
                              {...dc}
                              premiumText={t('premium', {ns: 'translation'})}
                              descriptionProps={{numberOfLines: 2}}
                            />
                          )}
                        </Pressable>
                      </View>
                    );
                  })}
                </View>
              ),
          )
        ) : (
          <ContentEmpty title={t('No notification')} />
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  itemSeparator: {
    height: StyleSheet.hairlineWidth,
  },
  qaContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    width: Metrics.horizontalScale(181),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: defaultTheme.button.danger,
    position: 'relative',
  },
});

export default ProfileNotificationList;
