import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Icon from 'design-system/src/Components/Icon/Icon';
import Typography from 'design-system/src/Components/Typography/Typography';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {View} from 'react-native';
import {
  IChoiceNuggetFragmentFragment,
  IQuizzSurveyFragmentFragment,
  useGetSurveyCollectionByIdLazyQuery,
  useQuizzSurveyByIdLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import ExamNugget from '../ExamScreen/ExamNugget';
import TestScreenContainer from '../TestScreen/TestScreenContainer';
import {useCourseTestProgression} from '~Store/Slices/CourseUnitSlice/CourseUnit.selector';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
import {useSnackbarNotification} from '~Store/Slices/SnackbarSlice/SnackBar.selector';
import courseUnitSlice, {
  UnitProgressionData,
} from '~Store/Slices/CourseUnitSlice/CourseUnit.slice';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import {
  useLazyPostQuizzSurveyQuery,
  useLazyGetQuizzSurveyProgressQuery,
} from '@src/Api/TraceApi/traceApi';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PressableCustom from 'design-system/src/Components/PressableCustom/PressableCustom';
import tabBarSlice from '@src/Store/Slices/TabBarSlice/TabBar.slice';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

const QuizzSurveyScreen = () => {
  const params = useParams();
  const navigation = useAppNavigation();
  const {id, __typename, step = 0} = params || {};

  const {t} = useTranslation(['learn']);
  const courseTestProgression = useCourseTestProgression(id || '');
  const showTabBar = useAppSelector(state => state.tabBar.mount);
  const goBackInBackground = useAppSelector(
    state => state.app.goBackInBackground,
  );
  // const {canPlay} = useListenPlayer();
  const dispatch = useAppDispatch();
  const [fetchQuizzSurvey, {data: dataSurvey, loading: surveyLoading}] =
    useQuizzSurveyByIdLazyQuery();
  const [
    fetchSurveyCollection,
    {data: dataCollection, loading: collectionLoading},
  ] = useGetSurveyCollectionByIdLazyQuery();
  const [postQuizzSurvey, {isLoading: postLoading}] =
    useLazyPostQuizzSurveyQuery();
  const currentLanguage = useCurrentLanguage();
  const [getQuizzSurvey, {isLoading: progressLoading}] =
    useLazyGetQuizzSurveyProgressQuery();
  const snackbar = useSnackbarNotification();

  useEffect(() => {
    if (__typename === 'QuizzSurvey') {
      fetchQuizzSurvey({
        variables: {
          id,
        },
        fetchPolicy: 'cache-first',
      });
    } else {
      fetchSurveyCollection({
        variables: {
          id,
        },
        fetchPolicy: 'cache-first',
      });
    }
    getQuizzSurvey({id}).then(value => {
      if (value && value.data && value.data.json) {
        dispatch(
          courseUnitSlice.actions.setTestAnswer({
            testId: id,
            data: JSON.parse(value.data.json) as UnitProgressionData,
          }),
        );
      }
    });
  }, [__typename, id]);

  // TODO: check listen player can play
  useEffect(() => {
    if (showTabBar) {
      dispatch(tabBarSlice.actions.setMount(false));
    }

    // if (canPlay) {
    //   dispatch(listenPlayerSlice.actions.setCanPlay(false));
    // }

    return () => {
      // if (!canPlay) {
      //   dispatch(listenPlayerSlice.actions.setCanPlay(true));
      // }
      if (!showTabBar) {
        dispatch(tabBarSlice.actions.setMount(true));
      }
    };
  }, []);

  const {
    surveyList,
    totalStep,
    nextLabel,
  }: {
    surveyList?: IChoiceNuggetFragmentFragment[];
    totalStep: number;
    nextLabel: string;
  } = useMemo(() => {
    let _totalStep = 1;
    let _nextLabel: string =
      step < _totalStep - 1 ? t('Continue') : t('Finish');
    let _questions: IQuizzSurveyFragmentFragment['questions'] =
      dataSurvey?.quizzSurvey?.questions ||
      dataCollection?.quizzSurveyCollection?.questions;
    if (_questions) {
      let surveyList: IChoiceNuggetFragmentFragment[] = [];
      _questions?.forEach(nugget => {
        if (nugget?.__typename === 'ChoiceNugget') {
          const choiceGroup = nugget?.options?.filter(
            _item => _item?.content.__typename === 'ChoiceNuggetAnswerGroup',
          );
          const choiceAnswer = nugget?.options?.filter(
            _item => _item?.content.__typename === 'ChoiceNuggerAnswer',
          );
          choiceGroup?.forEach(_choice => {
            if (_choice?.content.__typename === 'ChoiceNuggetAnswerGroup') {
              const _itemNugget: IChoiceNuggetFragmentFragment = {
                ...nugget,
                options: _choice.content.answersList,
              };
              surveyList.push(_itemNugget);
            }
          });
          if (choiceAnswer && choiceAnswer.length) {
            const _nugget: IChoiceNuggetFragmentFragment = {
              ...nugget,
              options: choiceAnswer,
            };
            surveyList.push(_nugget);
          }
        }
      });
      _totalStep = surveyList.length;
      _nextLabel = step < _totalStep - 1 ? t('Continue') : t('Finish');
      return {
        surveyList,
        totalStep: _totalStep,
        nextLabel: _nextLabel,
      };
    }
    return {totalStep: _totalStep, nextLabel: _nextLabel};
  }, [dataSurvey, dataCollection, step, t]);

  const handleNext = useCallback(async () => {
    if (step < totalStep - 1) {
      navigation.setParams({
        id: id,
        step: step + 1,
      });
    } else {
      const response = await postQuizzSurvey({
        testId: id,
        data: courseTestProgression,
        culture: currentLanguage,
      });
      if (response.error) {
        snackbar.add({
          title: t('Error'),
          message: t('Error on saving'),
          duration: 8000,
        });
        console.log('error', response.error);
      } else {
        snackbar.add({
          title: t('Congratulation'),
          message: t('You have completed the quizz'),
          duration: 8000,
        });
      }
      navigation.setOptions();
      navigation.goBack();
    }
  }, [
    step,
    totalStep,
    navigation,
    id,
    snackbar,
    t,
    currentLanguage,
    courseTestProgression,
    postQuizzSurvey,
  ]);

  const postLoadingRef = useRef<boolean>(false);

  const handleClose = async () => {
    if (!postLoadingRef.current) {
      postLoadingRef.current = true;
      navigation.goBack();
      setTimeout(() => (postLoadingRef.current = false), 1500);
    }
  };

  useEffect(() => {
    const handleGoBack = async () => {
      if (step === 0) {
        handleClose();
      } else {
        if (!postLoadingRef.current) {
          navigation.setParams({
            id: id,
            step: Math.max(step - 1, 0),
          });
        }
      }
    };
    navigation.setOptions({
      isCenterTitle: true,
      overrideTitle: (
        <Typography variant="h4" color="white">
          {t('Step')}{' '}
          <Typography variant="h4" color="primary">
            {t('{{current}} of {{total}}', {
              current: step + 1,
              total: totalStep,
            })}
          </Typography>
        </Typography>
      ),
      isWithLogo: false,
      hideAction: true,
      extraAction: step !== totalStep && (
        <PressableCustom
          onPress={() => {
            goBackInBackground?.();
          }}>
          {({hover}) => {
            return <Icon name="close" color={hover ? 'primary' : undefined} />;
          }}
        </PressableCustom>
      ),
      back: step > 0 ? {title: ''} : undefined,
      noBack: step === totalStep,
      onPressArrowBack: step > 0 ? handleGoBack : undefined,
    });

    return () => {
      navigation.setOptions();
    };
  }, [step, totalStep]);

  const loading = surveyLoading || collectionLoading || progressLoading;

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
        <CustomActivityIndicator />
      </View>
    );
  }

  if (surveyList && surveyList.length && surveyList[step]) {
    const stepContent = surveyList[step];
    let nuggetId = stepContent?.id;
    let disabled: boolean = true;
    if (nuggetId && courseTestProgression[nuggetId]) {
      disabled = false;
    }

    return (
      <TestScreenContainer
        buttonTitle={nextLabel}
        onPressButton={handleNext}
        buttonDisabled={disabled}
        loading={postLoading}>
        <ExamNugget content={stepContent} showResult={false} testId={id} />
        <DebugUmbracoId umbracoId={id} position="page" />
      </TestScreenContainer>
    );
  }
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ContentEmpty
        title={t('Survey page not found')}
        buttonTitle={t('Return home') as string}
        onPress={() => navigation.goBack()}
      />
    </View>
  );
};

export default QuizzSurveyScreen;
