import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useCallback, useEffect, useMemo} from 'react';
import {View} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography';
import PushQuizCard from 'design-system/src/Components/Card/PushQuizCard/PushQuizCard';
import Button from 'design-system/src/Components/Button/Button';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  ICourseCategoryFragmentFragment,
  IPersonalityPagesFragmentFragment,
  useCoursesByPersonalityIdsLazyQuery,
  useQuizResultByUrlEndQuery,
} from '~Api/Graphql/gql/types.generated';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import CustomCircularProgressBase from './CustomCircularProgressBase';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import CourseSlider from '~Umbraco/Components/CourseSlider/CourseSlider';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {useMapCourseCateg} from '~Umbraco/Utils/content/mapCourseCategItems';
import {
  useLazyGetAllMemberPersonalityTypeQuery,
  useLazyUpdateMemberPersonalityTypeQuery,
} from '~Api/TraceApi/traceApi';
import {IPersonalityQuizBody} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataBody.interface';
import useParams from '@src/Hooks/useParams';
import {useAppDispatch} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import quizSlice from '@src/Store/Slices/Quiz.slice';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {IPersonalityQuizResponse} from '@src/Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

interface IQuizzStat extends IPersonalityPagesFragmentFragment {
  id: string;
  percentage: number;
  color?: string;
}

export default function QuizResultPage() {
  const params = useParams();
  const {result, quizId: _quizId, endUrl} = params || {};
  const [fetchUserPersonality, {data: answers, isLoading}] =
    useLazyGetAllMemberPersonalityTypeQuery();
  const {t} = useTranslation('translation');
  const dispatch = useAppDispatch();
  const {isMobile} = useBreakpoint();

  const navigation = useAppNavigation();
  const {mapCourseCategItems} = useMapCourseCateg();
  const [postAnswer, {isLoading: postLoading, data: postData}] =
    useLazyUpdateMemberPersonalityTypeQuery();

  const answerData: IPersonalityQuizResponse[] | undefined =
    postData || answers;

  const handleResult = useCallback(async () => {
    if (result && result.length) {
      const body: IPersonalityQuizBody = {
        answers: result.map((item: any) => ({
          weight: item.weight,
          answerPoint: item.answerPoint,
          id: item.tagId,
        })),
        quizId: _quizId || '',
      };
      try {
        await postAnswer({data: body});
      } catch (error) {
        console.log('error ==>', JSON.stringify(error));
      }
    }
  }, [result, _quizId]);

  useEffect(() => {
    fetchUserPersonality();
  }, []);

  useEffect(() => {
    handleResult();
  }, [handleResult]);

  const response = useQuizResultByUrlEndQuery({
    variables: {
      endUrl,
    },
    skip: !endUrl,
  });

  const quizPage = response.data?.allQuizPage?.items[0];
  const id = quizPage?.id;

  useEffect(() => {
    dispatch(
      quizSlice.actions.setQuizResult({
        id: undefined,
        quizResult: [],
      }),
    );
  }, []);

  const [fetchCourses, {data: courseResponse, loading: courseLoading}] =
    useCoursesByPersonalityIdsLazyQuery();

  const loading = response.loading || courseLoading || postLoading;

  const maxCircle = Math.round(Metrics.horizontalScale(300));
  const strokeWidth = Metrics.horizontalScale(20);
  const circlPad = Metrics.horizontalScale(25);

  const {handleClickCourseSlideItem} = useSectionPageUtils();

  const courseSlider = useMemo(() => {
    const data = courseResponse;
    let _pageBlocks: ReactNode;
    if (data && data.allCourse && data.allCourse.items) {
      const items = mapCourseCategItems(
        data.allCourse
          .items as ICourseCategoryFragmentFragment['children']['items'],
      );
      if (items.length) {
        _pageBlocks = (
          <CourseSlider
            containerStyle={{
              marginTop: Metrics.verticalScale(42),
            }}
            key={'slider'}
            data={items}
            title={t('just for you')}
            onItemPress={handleClickCourseSlideItem}
          />
        );
      }
    }
    return _pageBlocks;
  }, [courseResponse]);

  useEffect(() => {
    if (result && result.length) {
      const _personalityName = result
        .map((_quiz: any) => _quiz.personalityName)
        .join(',');
      tracker.learn.personalityQuizResult(_personalityName);
    }
  }, [result]);

  const {
    resultSubtitle,
    resultTitle,
    resultPush,
    resultProgress,
    lisTagId,
    ctaLabel,
  }: {
    resultSubtitle?: string;
    resultTitle?: string;
    resultPush: ReactNode[];
    resultProgress: ReactNode[];
    lisTagId: string[];
    ctaLabel?: string;
  } = useMemo(() => {
    let _resultPush: ReactNode[] = [];
    let _resultProgress: ReactNode[] = [];
    let _lisTagId: string[] = [];

    if (quizPage) {
      const {resultSubtitle, resultTitle, quizz, ctaLabel} = quizPage;
      if (
        quizz &&
        quizz[0] &&
        quizz[0].__typename === 'PersonalityQuiz1' &&
        quizz[0].children &&
        answerData
      ) {
        const {questions} = quizz[0];
        if (questions) {
          questions.forEach(item => {
            if (
              item &&
              item.content &&
              item.content.__typename === 'PersonalityQuizQuestion'
            ) {
              const {linkedPersonalities} = item.content;
              if (
                linkedPersonalities &&
                linkedPersonalities[0] &&
                linkedPersonalities[0].__typename === 'PersonalityPages' &&
                linkedPersonalities[0].linkedTag &&
                linkedPersonalities[0].linkedTag[0] &&
                linkedPersonalities[0].linkedTag[0].id
              ) {
                _lisTagId.push(linkedPersonalities[0].linkedTag[0].id);
              }
            }
          });
        }
        let _personlaties: IPersonalityPagesFragmentFragment[] = [];
        quizz[0].children.items?.forEach(item => {
          if (item && item.__typename === 'PersonalityPages') {
            _personlaties.push(item as any);
          }
        });
        answerData.forEach((answer, index) => {
          if (answer) {
            const {personalityId, score} = answer;
            const findPersonalityPage = _personlaties.find(item => {
              if (item) {
                const {linkedTag} = item;
                if (
                  linkedTag &&
                  linkedTag[0] &&
                  linkedTag[0].id === personalityId
                ) {
                  return true;
                }
              }
              return false;
            });
            if (findPersonalityPage) {
              const color =
                findPersonalityPage.linkedTag &&
                findPersonalityPage.linkedTag[0] &&
                findPersonalityPage.linkedTag[0].__typename === 'ContentTag'
                  ? findPersonalityPage.linkedTag[0].color
                  : undefined;
              const {icon, title, subtitle, id, url, __typename, introduction} =
                findPersonalityPage;
              const percentage = Number((score * 100).toFixed());
              _resultProgress.push(
                <CustomCircularProgressBase
                  key={`crc-${index}`}
                  stroke={'#32343E75'}
                  strokeWidth={strokeWidth}
                  cy={maxCircle / 2}
                  size={maxCircle - (strokeWidth + circlPad) * index}
                  activeStrokeColor={color || '#DB3434'}
                  height={maxCircle}
                  currentProgress={percentage}
                  textHeight={strokeWidth}
                  secondStrokeColor={color || '#DB2626'}
                />,
              );
              _resultPush.push(
                <View
                  key={`${index}-push`}
                  style={{marginBottom: Metrics.verticalScale(8)}}>
                  <PushQuizCard
                    title={title || ''}
                    description={subtitle || ''}
                    variant="blur"
                    percentage={percentage}
                    icon={() => (
                      <CustomFastImage
                        backgroundFallbackColor="transparent"
                        width={Metrics.horizontalScale(32)}
                        height={Metrics.horizontalScale(32)}
                        source={{
                          uri: icon?.url,
                        }}
                      />
                    )}
                    onPressArrowIc={() => {
                      // navigation.navigate('QuizzMethodology');
                      new RouteFactory(
                        {
                          id,
                          url: url || '',
                          __typename,
                        },
                        navigation,
                        dispatch,
                      ).navigate();
                    }}
                    descTypo="ui3"
                  />
                </View>,
              );
            }
          }
        });
      }
      return {
        resultSubtitle,
        resultTitle,
        resultPush: _resultPush,
        resultProgress: _resultProgress,
        lisTagId: _lisTagId,
        ctaLabel,
      };
    }
    return {
      resultSubtitle: undefined,
      resultTitl: undefined,
      resultPush: _resultPush,
      resultProgress: _resultProgress,
      lisTagId: _lisTagId,
    };
  }, [quizPage, result, answerData]);

  useEffect(() => {
    if (!!lisTagId.length) {
      fetchCourses({
        variables: {
          id: lisTagId,
        },
      });
    }
  }, [fetchCourses, lisTagId]);

  return (
    <div>
      <DebugUmbracoId umbracoId={quizPage?.id} position="page" />
      {endUrl ? (
        loading || isLoading ? (
          <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
        ) : (
          <>
            <View
              style={{
                marginTop: Metrics.verticalScale(130),
                paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
                flexDirection: isMobile ? 'column' : 'row',
              }}>
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                }}>
                {resultProgress.length !== 0 && (
                  <View
                    style={{
                      alignItems: 'center',
                      height: maxCircle,
                    }}>
                    {resultProgress}
                  </View>
                )}
                <View
                  style={{
                    width: isMobile ? undefined : Metrics.horizontalScale(300),
                  }}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      marginTop: Metrics.verticalScale(42),
                    }}>
                    {resultTitle || ''}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'center',
                      marginTop: Metrics.verticalScale(8),
                    }}
                    variant="body2">
                    {resultSubtitle || ''}
                  </Typography>
                  <View
                    style={{
                      marginTop: Metrics.verticalScale(29),
                      alignItems: 'center',
                    }}>
                    <Button
                      variant="outlined"
                      size="small"
                      title={ctaLabel || t('take quiz')}
                      onPress={() => {
                        navigation.navigate(AppRoutesEnum.QUIZ_QUESTION, {
                          state: {
                            params: {
                              id: id || '',
                              url: endUrl || '',
                            },
                          },
                        });
                      }}
                    />
                  </View>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    width: isMobile ? '100%' : Metrics.horizontalScale(432),
                  }}>
                  {resultPush && resultPush.length !== 0 && (
                    <View
                      style={{
                        marginTop: Metrics.verticalScale(43),
                      }}>
                      {resultPush}
                    </View>
                  )}
                  <View
                    style={{
                      marginTop: Metrics.verticalScale(29),
                      alignItems: isMobile ? 'center' : 'flex-end',
                    }}>
                    <Button
                      variant="outlined"
                      size="small"
                      title={t('Other Personalities')}
                      onPress={() => {
                        navigation.navigate(AppRoutesEnum.QUIZ_PERSONALITY, {
                          state: {
                            params: {
                              id: id || '',
                              url: endUrl || '',
                            },
                          },
                        });
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
            {courseSlider}
          </>
        )
      ) : (
        <ContentEmpty
          style={{flex: 1, justifyContent: 'center'}}
          title={t('Section not found')}
        />
      )}
    </div>
  );
}
