import {StyleSheet, View} from 'react-native';
import React, {useMemo} from 'react';
import CardMessage, {
  CardMessageProps,
} from 'design-system/src/Components/Card/CardMessage/CardMessage';
import Metrics from 'design-system/src/Theme/Metrics';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import DateFactory from '~tools/DateFactory';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {IMessageResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import useAppNavigation from '@src/utils/useAppNavigation';
import useMemberMessage from '@src/Hooks/useMemberMessage';
import CustomActivityIndicator from 'design-system/src/Components/CustomActivityIndicator/CustomActivityIndicator';

const ProfileMessageList = () => {
  const navigation = useAppNavigation();
  const {t} = useTranslation('profile');
  const {
    data: messages,
    loading,
    remove,
    isRemoving,
    markAsRead,
  } = useMemberMessage();

  const data: {
    view: CardMessageProps;
    rawData: IMessageResponse;
  }[] = useMemo(() => {
    if (messages) {
      return messages.map<{
        view: CardMessageProps;
        rawData: IMessageResponse;
      }>(message => {
        const {
          title,
          _createDate: createDate,
          message: messageContent,
          thumbnail,
        } = message;
        return {
          rawData: message,
          view: {
            title: title || '',
            description: messageContent,
            date: new DateFactory(createDate).format(),
            cover: {uri: thumbnail || ''},
            onPress: () => {
              if (!message.read) {
                markAsRead(message);
              }
              // TODO: open message details
              // navigation.navigate('ProfileNotificationMessageDetails', {
              //   type: 'data',
              //   data: message,
              // });
            },
          },
        };
      });
    }
    return [];
  }, [messages, navigation]);

  const handleItemRemove = (rawData: IMessageResponse) => async () => {
    await remove(rawData._id);
  };
  return (
    <View
      style={{
        paddingTop: Metrics.verticalScale(24),
        paddingHorizontal: Metrics.horizontalScale(16),
        flex: 1,
      }}>
      {loading ? (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <CustomActivityIndicator />
        </View>
      ) : data.length ? (
        data.map(item => {
          const {view} = item;
          return (
            <View style={{backgroundColor: defaultTheme.page.background}}>
              <CardMessage {...view} />
            </View>
          );
        })
      ) : (
        <ContentEmpty title={t('No message')} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemSeparator: {
    height: StyleSheet.hairlineWidth,
  },
  qaContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    width: Metrics.horizontalScale(181),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: defaultTheme.button.danger,
    position: 'relative',
  },
});

export default ProfileMessageList;
