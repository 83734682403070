import {StyleProp, View, ViewStyle} from 'react-native';
import React from 'react';
import InfoListTile from 'design-system/src/Components/InfoListTile/InfoListTile';
import {IAdvantagesListFragmentFragment} from '~Api/Graphql/gql/types.generated';
import Metrics from 'design-system/src/Theme/Metrics';
import SummaryCard from 'design-system/src/Components/Card/SummaryCard/SummaryCard';
import CardList from '@src/Umbraco/Pages/HomePage/CardList/CardList';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Typography from 'design-system/src/Components/Typography/Typography';

interface IAdvantageListProps {
  avantages: IAdvantagesListFragmentFragment['advantages'];
  template?: 'icon left' | 'image top';
  style?: StyleProp<ViewStyle>;
}

const AdvantageList = (props: IAdvantageListProps) => {
  const {avantages = [], template = 'icon left', style} = props;
  const {mobile} = useBreakpoint();
  const {t} = useTranslation('Advantages');
  if (template === 'image top' && !mobile)
    return (
      <div
        style={{
          width: '100%',
        }}>
        <CardList
          desktopPadding={0}
          cards={avantages.map(c =>
            c?.content.__typename === 'Advantage'
              ? {
                  description: c?.content?.description || '',
                  image: c?.content?.image?.url || '',
                  title: c?.content?.title || '',
                }
              : {
                  description: '',
                  image: '',
                  title: '',
                },
          )}
          // isTakeFirst={true}
        />
      </div>
    );
  return (
    <View
      style={[
        style,
        mobile
          ? {}
          : {
              paddingTop: 27,
              paddingBottom: 24,
            },
      ]}>
      {!mobile && (
        <Typography
          children={t('Avantages')}
          variant="h2"
          style={{
            marginBottom: Metrics.verticalScale(21),
          }}
        />
      )}

      <View>
        {avantages.map((item, index) => {
          if (item && item.content && item.content.__typename === 'Advantage') {
            const {title, description, image} = item.content;
            return (
              <View
                key={index}
                style={{marginBottom: Metrics.verticalScale(33)}}>
                {template === 'image top' ? (
                  <SummaryCard
                    source={{uri: image?.url}}
                    title={title || ''}
                    description={description || ''}
                  />
                ) : (
                  <InfoListTile
                    source={{uri: image?.url}}
                    title={title || ''}
                    description={description || ''}
                  />
                )}
              </View>
            );
          }
          return null;
        })}
      </View>
    </View>
  );
};

export default AdvantageList;
