export function capitalize(str?: string | null) {
  if (str && typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export const getMobileOperatingSystem = ():
  | 'iOS'
  | 'Android'
  | 'Windows Phone'
  | 'HarmonyOS'
  | 'unknown' => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return 'unknown';
  } else {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    // Add HarmonyOS check here
    // This is hypothetical and depends on how HarmonyOS identifies itself in the user agent string
    if (/HarmonyOS/i.test(userAgent)) {
      return 'HarmonyOS';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }
};
