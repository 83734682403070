import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';

class DateFactory {
  date: Dayjs;
  #defaultFormat = 'DD MMMM YYYY';
  #dateTimeFormat = 'DD MMM YYYY HH:mm';
  #timeFormat = 'HH:mm';

  constructor(date: string | number | Date | Dayjs) {
    dayjs.extend(utc);
    this.date = dayjs(date);
  }

  diffDate(unit?: dayjs.QUnitType): number {
    return dayjs().utc().diff(this.date, unit);
  }

  formatUtc(format?: string): string {
    return this.date.utc().format(format || this.#defaultFormat);
  }

  utcToISOFormat(): string {
    return this.date.utc().toISOString();
  }

  toISOFormat(): string {
    return this.date.toISOString();
  }

  scaleDateToISOFormat(unit: dayjs.ManipulateType, value?: number): string {
    return this.date
      .add(value ?? 1, unit)
      .utc()
      .toISOString();
  }
  scaleDateFormatUtc(
    unit: dayjs.ManipulateType,
    format?: string,
    value?: number,
  ): string {
    return this.date
      .add(value ?? 1, unit)
      .utc()
      .format(format || this.#defaultFormat);
  }

  format(format?: string): string {
    return this.date.format(format || this.#defaultFormat);
  }

  dateTime(format?: string): string {
    if (dayjs().format('DD MMMM YYYY') === this.date.format('DD MMMM YYYY')) {
      return this.time();
    }

    return this.date.format(format || this.#dateTimeFormat);
  }
  time(): string {
    return this.date.format(this.#timeFormat);
  }

  duration(
    reference: string | number | dayjs.Dayjs | Date | null | undefined,
    unit:
      | 'millisecond'
      | 'second'
      | 'minute'
      | 'hour'
      | 'day'
      | 'month'
      | 'year'
      | 'date'
      | 'milliseconds'
      | 'seconds'
      | 'minutes'
      | 'hours'
      | 'days'
      | 'months'
      | 'years'
      | 'dates' = 'minute',
  ) {
    return this.date.diff(reference, unit);
  }
}

export default DateFactory;
