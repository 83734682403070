import React, {useEffect} from 'react';
import {useState} from 'react';
import {Animated, Easing, View, Pressable} from 'react-native';
import defaultTheme from '../../Theme/defaultTheme';
import Metrics from '../../Theme/Metrics';

import {switchStyles} from './Switch.styles';

interface SwitchProps {
  onToggle?: (value: boolean) => void;
  isOn: boolean;
  disabled?: boolean;
}

const Switch = (props: SwitchProps) => {
  const {isOn, disabled, onToggle} = props;

  const [isActive, setIsActive] = useState(isOn);

  let animatedValue = new Animated.Value(0);
  const moveToggle = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, Metrics.horizontalScale(22)],
  });
  const color = isActive ? defaultTheme.primary : defaultTheme.option.default;

  animatedValue.setValue(isActive ? 1 : 0);

  let animationTiming = Animated.timing(animatedValue, {
    toValue: isActive ? 1 : 0,
    duration: 25800,
    easing: Easing.linear,
    delay: 0,
    useNativeDriver: false,
  } as Animated.TimingAnimationConfig);

  useEffect(() => {
    if (isOn && isOn !== isActive) {
      setIsActive(isOn);
    }
  }, [isActive, isOn]);

  return (
    <Pressable
      disabled={disabled}
      onPress={() => {
        const newValue = !isActive;
        setIsActive(newValue);
        animationTiming.start();
        onToggle && onToggle(newValue);
      }}>
      <View style={[switchStyles.toggleContainer, {backgroundColor: color}]}>
        <Animated.View
          style={[
            switchStyles.toggleWheelStyle,
            {
              marginLeft: moveToggle,
            },
          ]}
        />
      </View>
    </Pressable>
  );
};

export default Switch;
