/* eslint-disable no-unused-vars */
import {HttpDataParams} from './Type/HttpType';
const Request = {
  get: async (params: HttpDataParams) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ACCEPT: 'application/json',
      };
      const jsonRes = await (
        await fetch(params.url, {
          method: 'GET',
          headers: headers,
          ...params.options,
        })
      ).json();
      return jsonRes;
    } catch (error) {
      console.log('Fetch error');
      console.log(JSON.stringify(error));
      // throw error;
    }
  },
  post: async (params: HttpDataParams) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ACCEPT: 'application/json',
      };
      const jsonRes = await (
        await fetch(params.url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(params.data),
          ...params.options,
        })
      ).json();
      return jsonRes;
    } catch (error) {
      console.log('Fetch error');
      console.log(JSON.stringify(error));
      // throw error
    }
  },

  put: async (
    url: string,
    data: any,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    options: any,
  ) => {
    try {
      const jsonRes = await (
        await fetch(url, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(data),
          ...options,
        })
      ).json();
      return jsonRes;
    } catch (error) {
      console.log('Fetch error');
      console.log(JSON.stringify(error));
      // throw error
    }
  },
  postUpload: async (url: string, data: any, options: any = null) => {
    try {
      const headersAuthen = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      };
      const jsonRes = await (
        await fetch(url, {
          method: 'POST',
          headers: headersAuthen,
          body: data,
          ...options,
        })
      ).json();
      return jsonRes;
    } catch (error) {
      console.log('Fetch error');
      console.log(JSON.stringify(error));
    }
  },
};
export default Request;
