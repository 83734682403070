import React from 'react';
import ProfileWrapper from '../ProfileWrapper/ProfileWrapper';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import CardMessage from 'design-system/src/Components/Card/CardMessage/CardMessage';
import useMemberOpportunity from '@src/Hooks/useMemberOpportunity';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';

const ProfileOpportunities = () => {
  const {t} = useTranslation('profile');
  const {data, loading, refresh} = useMemberOpportunity();

  if (loading) {
    return <CustomActivityIndicator />;
  }

  return (
    <ProfileWrapper title={t('Opportunity') || ''}>
      <div>
        {data.length ? (
          data.map((d, index) => (
            <CardMessage
              key={`op-${index}`}
              {...d}
              border={false}
              coverType="square"
            />
          ))
        ) : (
          <ContentEmpty title={t('No data')} />
        )}
      </div>
    </ProfileWrapper>
  );
};

export default ProfileOpportunities;
