import {INewsFragmentFragment} from '~Api/Graphql/gql/types.generated';

const getAuthorName = (author: INewsFragmentFragment['author']): string => {
  return (
    (author &&
      author[0] &&
      author[0].content &&
      author[0].content.__typename === 'Author' &&
      author[0].content.authorName) ||
    ''
  );
};

export default getAuthorName;
