import {View} from 'react-native';
import React from 'react';
import {ICourseNuggetFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {useUnitProgression} from '~Store/Slices/CourseUnitSlice/CourseUnit.selector';
import {useDispatch} from 'react-redux';
import courseUnitSlice, {
  NuggetDataProgress,
} from '~Store/Slices/CourseUnitSlice/CourseUnit.slice';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import { mapNugget } from '../../../ExamScreen/mapLearnNugget';

interface ICourseNuggetProps {
  content: ICourseNuggetFragmentFragment;
  showResult?: boolean;
  unitId?: string;
  onChange?: (isCorrect?: boolean) => void;
  property?: ILearnPropertyName;
}

const CourseNugget = (props: ICourseNuggetProps) => {
  const {content, showResult, unitId = '', onChange, property} = props;
  const unitProgression = useUnitProgression(unitId);
  const dispatch = useDispatch();
  const handleChange =
    (nuggetId: string) => (data: NuggetDataProgress, isCorrect?: boolean) => {
      onChange && onChange(isCorrect);
      dispatch(
        courseUnitSlice.actions.saveUnitNuggetAnswer({
          unitId,
          nuggetId,
          data: data,
        }),
      );
    };
  return (
    <View style={{flex: 1}}>
      {content.nuggets?.map((item, index) => {
        if (item && item.id) {
          const nuggetProgression = unitProgression[item.id];
          if (item.__typename === 'ChoiceNugget' && item.options) {
            return mapNugget(item, index, {
              nuggetProgression,
              showResult,
              onChange,
              handleChange,
              property
            });
          } else if (item.__typename === 'GapfillNugget') {
            return  mapNugget(item, index, {
              nuggetProgression,
              showResult,
              onChange,
              handleChange,
              property
            }); 
          } else if (item.__typename === 'SumaryNugget') {
            return  mapNugget(item, index, {
              nuggetProgression,
              showResult,
              onChange,
              handleChange,
              property
            }); 
          }
        }
        return null;
      })}
    </View>
  );
};

export default React.memo(CourseNugget);
