import Metrics from 'design-system/src/Theme/Metrics';
import {StyleSheet} from 'react-native';

export const liveTvScreenStyles = StyleSheet.create({
  scrollContainer: {
    marginTop: Metrics.verticalScale(18),
  },
  gradient: {
    position: 'absolute',
    height: '100%',
    left: Metrics.horizontalScale(112),
    width: Metrics.horizontalScale(20),
  },
});
