import {RouteTypename} from '@src/Navigation/type';
import useNavigation from '@src/Navigation/useNavigation';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {capitalize} from '@src/Umbraco/Utils/common';
import {IFooterItemProps} from '@src/components/general/PageFooter/FooterItem/FooterItem';
import {
  ILegalFooter,
  IPageFooterProps,
  ISocialMediaFooter,
} from '@src/components/general/PageFooter/PageFooter';
import {graphql, useStaticQuery} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {useDispatch} from 'react-redux';

const useGetFooter = (): IPageFooterProps | null => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const {language} = useI18next();

  const tracker = useTracker();

  const data = useStaticQuery<Queries.GetFooterQuery>(graphql`
    query GetFooter {
      umbraco {
        en: allSettings(first: 1, culture: "en-US") {
          items {
            ...SettingFragment
          }
        }
        fr: allSettings(first: 1, culture: "fr") {
          items {
            ...SettingFragment
          }
        }
        pt: allSettings(first: 1, culture: "pt-BR") {
          items {
            ...SettingFragment
          }
        }
      }
    }
  `);

  const dataLang = data.umbraco[language as keyof typeof data.umbraco];

  if (!dataLang.items[0]) {
    return null;
  }

  const {
    footerLogo,
    footerMenu,
    footerShortDescription,
    legalFooter,
    socialBar,
    huaweiRedirectUrl,
    androidRedirectURL,
    iOSRedirectUrl,
    guestBurgerMenu: guestBurgerMenuData,
  } = dataLang.items[0];

  const footerMenuContent = footerMenu as
    | Queries.Umbraco_BlockListItem[]
    | null;

  const menus: IFooterItemProps[] =
    footerMenuContent?.map(item => {
      const itemContent = item.content as Queries.Umbraco_MenuColumn | null;
      return {
        title: itemContent?.title || '',
        list: itemContent?.column?.map(columnItem => {
          const {__typename, title, url, id} = formatButtonFooter(columnItem);
          return {
            title: title,
            onClick: () => {
              tracker.general.cTAClicked({
                type: __typename,
                name: title,
                page: 'footer',
                blockType: 'link_list',
                blockName: 'footer_menu',
              });
              new RouteFactory(
                {
                  id,
                  __typename: __typename as RouteTypename,
                  url: url,
                },
                navigation,
                dispatch,
              ).navigate();
            },
          };
        }),
      };
    }) || [];

  const legals: ILegalFooter[] =
    (legalFooter as Queries.Umbraco_BlockListItem[] | null)?.map(item => {
      const {__typename, title, url, id} = formatButtonFooter(item);
      return {
        title: title,
        onClick: () => {
          tracker.general.cTAClicked({
            type: __typename,
            name: title,
            page: 'footer',
            blockType: 'link_list',
            blockName: 'legal_menu',
          });
          new RouteFactory(
            {
              id,
              __typename: __typename as RouteTypename,
              url,
            },
            navigation,
            dispatch,
          ).navigate();
        },
      };
    }) || [];
  const guestBurgerMenu: ILegalFooter[] =
    (guestBurgerMenuData as Queries.Umbraco_BlockListItem[] | null)?.map(
      item => {
        const {__typename, title, url, id} = formatButtonFooter(item);
        return {
          title: title,
          onClick: () => {
            new RouteFactory(
              {
                id,
                __typename: __typename as RouteTypename,
                url: url,
              },
              navigation,
              dispatch,
            ).navigate();
          },
        };
      },
    ) || [];

  const socialMedias: ISocialMediaFooter[] =
    (socialBar as Queries.Umbraco_BlockListItem[] | null)?.map(item => {
      const itemContent = item.content as Queries.Umbraco_LinkIcon | null;
      return {
        title: itemContent?.title || '',
        icon: itemContent?.icon || '',
        onClick: () => {
          tracker.general.cTAClicked({
            type: 'SocialMedia',
            name: itemContent?.title || '',
            page: 'footer',
            blockType: 'link_list',
            blockName: 'social_media',
          });
          if (itemContent?.link?.length) {
            const externalLink = itemContent?.link[0]
              ?.content as Queries.Umbraco_ExternalLink | null;
            if (externalLink) {
              new RouteFactory(
                {
                  id: '',
                  __typename: 'ExternalLink',
                  url: externalLink.externalUrl || '',
                },
                navigation,
                dispatch,
              ).navigate();
            }
          }
        },
      };
    }) || [];

  return {
    logo: footerLogo?.url || '',
    description: footerShortDescription || '',
    menus,
    legals,
    socialMedias,
    iOSRedirectUrl,
    androidRedirectURL,
    huaweiRedirectUrl,
    guestBurgerMenu,
  };
};

function formatButtonFooter(
  content: Queries.Maybe<Queries.Umbraco_BlockListItem>,
) {
  let title = '';
  let url = '';
  let __typename = 'Unknown';
  let id = '';

  if (content?.content.contentTypeAlias === 'staticPageLink') {
    const staticLinkContent =
      content?.content as Queries.Umbraco_StaticPageLink | null;
    title = staticLinkContent?.title || '';
    url =
      (staticLinkContent?.link as Queries.Umbraco_LandingPage)?.urlRewrite ||
      (staticLinkContent?.link as Queries.Umbraco_LandingPage)?.url ||
      '';
    id = (staticLinkContent?.link as Queries.Umbraco_LandingPage)?.id || '';
    __typename =
      capitalize(staticLinkContent?.link?.contentTypeAlias) || 'Unknown';
  }

  if (content?.content.contentTypeAlias === 'externalLink') {
    const externalLinkContent =
      content?.content as Queries.Umbraco_ExternalLink | null;
    title = externalLinkContent?.title || '';
    url = externalLinkContent?.externalUrl || '';
    __typename = capitalize(externalLinkContent?.contentTypeAlias) || 'Unknown';
  }

  if (content?.content.contentTypeAlias == 'legalPageLink') {
    const legalPageLinkContent =
      content?.content as Queries.Umbraco_LegalPageLink | null;
    title = legalPageLinkContent?.title || '';
    url = legalPageLinkContent?.link?.url || '';
    __typename =
      capitalize(legalPageLinkContent?.link?.contentTypeAlias) || 'Unknown';
    id = legalPageLinkContent?.link?.id || '';
  }

  if (content?.content.contentTypeAlias == 'faqPageLink') {
    const faqPageLinkContent =
      content?.content as Queries.Umbraco_FaqPageLink | null;
    title = faqPageLinkContent?.title || '';
    url = (faqPageLinkContent?.helpdeskCategoriesPicker as any)?.[0]?.url || '';
    __typename =
      capitalize(
        (faqPageLinkContent?.helpdeskCategoriesPicker as any)?.[0]
          ?.contentTypeAlias,
      ) || 'Unknown';

    id = (faqPageLinkContent?.helpdeskCategoriesPicker as any)?.[0]?.id || '';
  }

  return {
    title,
    url,
    __typename,
    id,
  };
}

export default useGetFooter;
