import styled from 'styled-components';
import {Container} from '../Grid';
import {responsiveMap} from '../Grid/_utils/responsiveObserve';
import FlexBox from '../FlexBox/FlexBox.web';

export const _AcqCategorySliderInner = styled(FlexBox)<{
  $position?: number;
  $width?: number;
}>(({$position = 0, $width}) => {
  return {
    width: $width ? $width : '100%',
    transform: `translate3d(-${$position}px, 0px, 0px)`,
    transition: 'all 300ms ease-out 0s',
    alignItems: 'center',
    [`@media ${responsiveMap.lg}`]: {
      transition: 'all 250ms ease-out 0s',
    },
    padding: '0px 16px',
  };
});

export const _AcqCategoryContainer = styled(Container)<{}>(() => {
  return {
    // [`@media ${responsiveMap.lg}`]: {
    paddingTop: 24,
    paddingBottom: 24,
    // },
    ['.acq-category-slide']: {
      marginRight: -16,
      marginLeft: -16,
      marginTop: 16,
      [`@media ${responsiveMap.lg}`]: {
        marginRight: 0,
        marginTop: 0,
        // padding: "16px 0",
      },
    },
  };
});

export const _AcqCategoryHeadingWrap = styled(FlexBox)(() => {
  return {
    [`@media ${responsiveMap.lg}`]: {
      marginBottom: 32,
    },
  };
});
