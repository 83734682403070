import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import apiConfig from '~Configs/apiConfig';
import {onError} from '@apollo/client/link/error';
import {possibleTypes} from './gql/introspection-result.generated.json';
import wrapPromise from '~tools/wrapPromise';
import store from '@src/Store';
import {languageUnmap} from '@src/Services/i18n/languageMapper';

const httpLink = new HttpLink({
  uri: apiConfig.graphqlUrl,
  headers: {'umb-project-alias': apiConfig.umbProjectAlias},
  includeUnusedVariables: true,
  includeExtensions: true,
});

// set culture variable before query
const languageMiddleware = new ApolloLink((operation, forward) => {
  // TODO: get lang
  const pathname = window.location.pathname;
  const supportedLanguages = ['fr', 'en', 'pt'];
  let lang =
    supportedLanguages.find(language => pathname.startsWith(`/${language}`)) ||
    'en';
  const storeLang = store?.getState()?.language?.currentLanguage;
  // console.log('storeLang', {storeLang, lang});

  operation.variables.culture = languageUnmap(lang);
  operation.variables.imageFormat = 'WEBP';

  return forward(operation);
});

const errorLink = onError(data => {
  const {graphQLErrors, networkError} = data;
  if (graphQLErrors) {
    graphQLErrors.forEach(({message, locations, path}) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        path,
        data,
      ),
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    WatchCateg: {
      fields: {
        children: {
          merge: false,
        },
      },
    },
  },
});

export const initApolloInstance = async () => {
  // await persistCache({
  //   cache,
  //   storage: new AsyncStorageWrapper(AsyncStorage),
  // });

  const apolloClientInstance = new ApolloClient({
    link: from([languageMiddleware, errorLink, httpLink]),
    cache: cache,
    connectToDevTools: true,

    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });

  return apolloClientInstance;
};

export const apolloStaticInstance = initApolloInstance();

export let apolloClientInstance = wrapPromise(initApolloInstance());

export const translationClientInstance = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: cache,
  connectToDevTools: true,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
});
