import {useApolloClient} from '@apollo/client';
import {useCallback} from 'react';

// import useInitSetting from '~Services/Query/hooks/useInitSetting';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
import languageSlice from '~Store/Slices/LanguageSlice/Language.slice';
import {useAppDispatch} from '@src/Store/hooks';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {languageMapper} from '@src/Services/i18n/languageMapper';
import useInitSetting from '@src/Services/Query/hooks/useInitSetting';

const delay = (timer: number) =>
  new Promise(resolve => {
    setTimeout(resolve, timer);
  });

const useChangeLanguage = () => {
  const client = useApolloClient();
  const {init} = useInitSetting();
  const currentLanguage = useCurrentLanguage();
  const {changeLanguage} = useI18next();
  const dispatch = useAppDispatch();

  const updateApi = useCallback(
    async (lang: string) => {
      try {
        // First stop any in-flight queries
        client.stop();
        // Then clear the store
        await client.clearStore();
        // Finally reset the store
        await client.resetStore();
      } catch (error) {
        console.error('Error resetting Apollo store:', error);
      }
    },
    [client],
  );

  const updateStoreLang = useCallback(
    async (language: string, isFromApp?: boolean) => {
      // Update Redux store first
      dispatch(languageSlice.actions.setCurrentLanguage(language));

      // Finally reset Apollo store
      await updateApi(language);

      await init();

      // Then update i18n
      await changeLanguage(languageMapper(language), undefined, {
        replace: true,
      });
    },
    [dispatch, updateApi, currentLanguage, changeLanguage],
  );

  return {
    currentLanguage,
    updateStoreLang,
  };
};

export default useChangeLanguage;
