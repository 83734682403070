import {StyleSheet} from 'react-native';
import Metrics, {width} from '../../../Theme/Metrics';

export const UnitPlayerStyles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: Metrics.horizontalScale(16),
    paddingVertical: Metrics.verticalScale(20),
  },
  title: {
    marginBottom: Metrics.verticalScale(12),
  },
  defailsWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    paddingRight: Metrics.verticalScale(30),
  },
  actionsWrapper: {
    width: Metrics.horizontalScale(48),
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  actionGap: {
    marginBottom: Metrics.verticalScale(20),
  },
  footer: {
    position: 'absolute',
    bottom: Metrics.verticalScale(28),
    width: width,
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
  },
  videoView: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  indicatorStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  fastImageStyle: {
    position: 'absolute',
  },
});
