import React from 'react';
import {Pressable, View} from 'react-native';
import IcShareSvg from '../../../assets/svg/share-icon.svg';
import IcFavSvg from '../../../assets/svg/favoris-icon.svg';
import {ShareLeadingCardStyles} from './ShareLeadingCard.styles';

type ShareLeadingCardProps = {
  Card: React.ReactNode;
  onClickShareIc: () => void;
  onClickFavIc: () => void;
};
const ShareLeadingCard: React.FC<ShareLeadingCardProps> = ({
  Card,
  onClickShareIc,
  onClickFavIc,
}) => {
  return (
    <View>
      {Card}
      <View style={ShareLeadingCardStyles.icContainer}>
        <Pressable onPress={onClickShareIc}>
          <IcShareSvg />
        </Pressable>
        <Pressable
          style={ShareLeadingCardStyles.icFav}
          onPress={onClickFavIc}>
          <IcFavSvg />
        </Pressable>
      </View>
    </View>
  );
};

export default ShareLeadingCard;
