/* eslint-disable react-native/no-inline-styles */
import React, {ReactNode} from 'react';
import {View, Pressable} from 'react-native';
import {FastImageProps} from 'react-native-fast-image';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import Typography from '../../Typography/Typography';
import lightOrDarkColor from '@check-light-or-dark/color';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';

export type CardSearchItemProps = {
  title: string | (() => ReactNode);
  cover?: FastImageProps['source'];
  backgroundColor?: string;
  onPress?: () => void;
};

export default function CardSearchItem(props: CardSearchItemProps) {
  const {title, cover, backgroundColor = '#FFFFFF', onPress} = props;

  const {isMobile} = useBreakpoint();

  return (
    <Pressable
      onPress={onPress}
      style={{
        position: 'relative',
        width: Metrics.horizontalScale(isMobile ? 109 : 210),
        height: Metrics.verticalScale(isMobile ? 80 : 116),
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}>
        {cover ? (
          <CustomFastImage
            source={cover}
            width={Metrics.horizontalScale(isMobile ? 109 : 210)}
            height={Metrics.verticalScale(isMobile ? 80 : 116)}
            style={{borderRadius: Metrics.horizontalScale(4)}}
          />
        ) : (
          <View
            style={{
              backgroundColor: backgroundColor || '#1B1C21',
              width: Metrics.horizontalScale(isMobile ? 109 : 210),
              height: Metrics.verticalScale(isMobile ? 80 : 116),
              borderRadius: Metrics.horizontalScale(4),
            }}
          />
        )}
      </View>
      {!!title && (
        <View
          style={{
            position: 'relative',
            zIndex: 10,
            padding: Metrics.horizontalScale(12),
            maxWidth: '100%',
          }}>
          {typeof title === 'function' ? (
            title()
          ) : (
            <Typography
              variant="h4"
              style={{
                textAlign: 'center',
                display: 'inline-block',
                overflowWrap: 'break-word',
              }}
              numberOfLines={2}
              color={() =>
                !backgroundColor
                  ? '#FFFFFF'
                  : lightOrDarkColor(backgroundColor) === 'light'
                    ? '#FFFFFF'
                    : '#202020'
              }>
              {title}
            </Typography>
          )}
        </View>
      )}
    </Pressable>
  );
}
