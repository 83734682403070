import {View, Pressable, StyleSheet} from 'react-native';
import React from 'react';
import Metrics from 'design-system/src/Theme/Metrics';
import Typography from 'design-system/src/Components/Typography/Typography';
import Icon from 'design-system/src/Components/Icon/Icon';

interface IProfileAccountItemProps {
  isFirst?: boolean;
  isLast?: boolean;
  label?: string;
  value?: string;
  onPress?: () => void;
  disabled?: boolean;
}

const ProfileAccountItem = (props: IProfileAccountItemProps) => {
  const {isFirst, isLast, label, value, disabled, onPress} = props;
  return (
    <Pressable
      disabled={disabled}
      style={{overflow: 'hidden'}}
      onPress={onPress}>
      <View
        style={StyleSheet.flatten({
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#1B1C21',
          alignItems: 'center',
          paddingHorizontal: Metrics.horizontalScale(12),
          paddingVertical: Metrics.verticalScale(16),
          marginBottom: 1,
          borderTopLeftRadius: isFirst ? 4 : 0,
          borderTopRightRadius: isFirst ? 4 : 0,
          borderBottomLeftRadius: isLast ? 4 : 0,
          borderBottomRightRadius: isLast ? 4 : 0,
        })}>
        <View style={{flex: 1}}>
          <Typography variant="ui3" color="gray">
            {label}
          </Typography>
          <Typography variant="body2">{value}</Typography>
        </View>
        <View>{!disabled && <Icon name="arrow-right" size="16" />}</View>
      </View>
    </Pressable>
  );
};

export default ProfileAccountItem;
