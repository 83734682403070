import React, {FC, useMemo} from 'react';
import {useCgvQuery} from '@src/Api/Graphql/gql/types.generated';
import ProfileWrapper from '@src/Navigation/Profile/ProfileWrapper/ProfileWrapper';
import {
  CgvData,
  CgvType,
} from '@src/Navigation/Profile/ProfileHome/ProfileHome';
import useParams from '@src/Hooks/useParams';
import {View} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import HtmlRender from '@src/Umbraco/Components/HtmlRender/HtmlRender';

const TermsAndConditions: FC = () => {
  const params = useParams();
  const type = (params.type as CgvType) || 'termsConditions';
  const {data} = useCgvQuery();

  const {selected}: {selected?: CgvData} = useMemo(() => {
    let _termsConditions: CgvData | undefined;
    let _privacyPolicies: CgvData | undefined;
    if (
      data &&
      data.allSettings &&
      data.allSettings.items &&
      data.allSettings.items[0]
    ) {
      const {termsConditions, privacyPolicies} = data.allSettings.items[0];
      if (
        termsConditions &&
        termsConditions[0] &&
        termsConditions[0].__typename === 'TextPage'
      ) {
        const {title, body} = termsConditions[0];
        _termsConditions = {
          title: title || '',
          body: body || '',
        };
      }
      if (
        privacyPolicies &&
        privacyPolicies[0] &&
        privacyPolicies[0].__typename === 'TextPage'
      ) {
        const {title, body} = privacyPolicies[0];
        _privacyPolicies = {
          title: title || '',
          body: body || '',
        };
      }
    }
    return {
      selected:
        type === 'termsConditions' ? _termsConditions : _privacyPolicies,
    };
  }, [data, type]);

  return (
    <ProfileWrapper title={selected?.title} withSeparator={true}>
      <View style={{marginTop: Metrics.horizontalScale(42)}}>
        {selected?.body && (
          <HtmlRender html={selected.body} variant={'body2'} color="#A0A0A0" />
        )}
      </View>
    </ProfileWrapper>
  );
};

export default TermsAndConditions;
