import {useEffect, useState} from 'react';

const useViewPort = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {viewportHeight, viewportWidth};
};

export const useGetAbsoluteLeft = (
  ref: React.RefObject<HTMLElement>,
  rerender: any[],
  options: {type: 'offset' | 'boundingClientRect'} = {
    type: 'boundingClientRect',
  },
) => {
  const [leftPosition, setLeftPosition] = useState(0);

  const calculatePosition = () => {
    if (ref.current) {
      const triggerLeft =
        options.type === 'boundingClientRect'
          ? (ref.current as HTMLElement).getBoundingClientRect().left
          : (ref.current as HTMLElement).offsetLeft;
      setLeftPosition(-triggerLeft);
    }
  };

  useEffect(() => {
    if (rerender) {
      calculatePosition();
    }
  }, [rerender]);

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    return () => window.removeEventListener('resize', calculatePosition);
  }, []);

  return {leftPosition};
};

export default useViewPort;
