/* eslint-disable react-native/no-inline-styles */
import {View} from 'react-native';
import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {CategoryContentLoader} from 'design-system/src/Components/ContentLoader';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {RouteTypename} from '~Navigation/type';
import CategoryPageContent, {
  ICategoryPageContentProps,
} from './CategoryPageContent/CategoryPageContent';
import {CustomBottomSheetItem} from 'design-system/src/Components/CustomBottomSheet/CustomBottomSheet';
import {IContentOrderByInput} from '~Api/Graphql/gql/types.generated';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import Metrics from 'design-system/src/Theme/Metrics';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useSelectCategory} from './useSelectCategory';
import AppDropDownMenu from '@src/App/components/Common/AppDropDownMenu/AppDropDownMenu';
import styled from 'styled-components';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import CategoryTagFilter from '@src/App/components/Common/CategoryTagFilter/CategoryTagFilter';
import AppDropDownSelect from '@src/App/components/Common/AppDropDownSelect/AppDropDownSelect';
import {regionFilterFunc} from '@src/Umbraco/Utils/pages/mapCategoryPage';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';
import useParams from '@src/Hooks/useParams';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import useAppNavigation from '@src/utils/useAppNavigation';
import {useDispatch} from 'react-redux';
import useContentIdByEndUrl from '@src/Umbraco/Hooks/useContentIdByEndUrl';
import appSlice from '@src/Store/Slices/AppSlice';

interface ICategoryPageProps<Sort>
  extends Pick<ICategoryPageContentProps, 'gridProps'> {
  activeId?: string;
  type: RouteTypename;
  sort?: boolean;
  regionFilter?: boolean;
  customRenderContent?: (
    contentProps: ICategoryPageContentProps<Sort> & {
      setAllCategoryData: (data: any[]) => void;
    },
  ) => ReactNode;
  orderList?: Array<CustomBottomSheetItem>;
  initialOrder?: Sort;
}

const CategoryRoot = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 60px',
    [`@media ${responsiveMap.mobile}`]: {
      padding: '0 16px',
    },
  };
});
const CategoryPage = <Sort extends any>(props: ICategoryPageProps<Sort>) => {
  const {
    // activeId: id,
    type,
    sort,
    gridProps,
    regionFilter,
    orderList,
    customRenderContent,
    initialOrder,
  } = props;
  const tracker = useTracker();
  const {t} = useTranslation('translation');
  const {isMobile} = useBreakpoint();
  const [regionId, setRegionId] = useState<string>();
  const filterRef = useRef<HTMLDivElement | null>(null);
  const {endUrl} = useParams();
  const navigation = useAppNavigation();
  const dispatch = useDispatch();
  const {id, loading: loadingContentId} = useContentIdByEndUrl({
    variables: {
      endUrl: (endUrl + '/').replace('//', '/'),
      contentTypeAlias: type.charAt(0).toLowerCase() + type.slice(1),
    },
    skip: !endUrl,
  });

  const [orderBy, setOrderBy] = useState<IContentOrderByInput>(
    IContentOrderByInput.CreateDateDesc,
  );
  const [customOrderBy, setCustomOrderBy] = useState<Sort | undefined>(
    initialOrder,
  );
  const [allCategoryData, setAllCategoryData] = useState<any[]>([]);

  const {
    categories,
    setCurrentId,
    selectedCategory,
    loading: loadingCategory,
    isCategNotFound,
  } = useSelectCategory({
    contentTypeAlias: type,
    id,
    regionFilter,
    endUrl,
  });
  const loading = loadingContentId || loadingCategory;

  const data: Array<CustomBottomSheetItem> = orderList || [
    {
      item: IContentOrderByInput.NameDesc,
      label: t('Name'),
    },
    {
      item: IContentOrderByInput.CreateDateDesc,
      label: t('Created date'),
    },
  ];

  useEffect(() => {
    if (selectedCategory && !loading) {
      dispatch(
        appSlice.actions.setLeftSubMenu({title: selectedCategory.title || ''}),
      );
    }
    return () => {
      dispatch(appSlice.actions.setLeftSubMenu());
    };
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory) {
      tracker.general.contentGenreViewed({
        content_type: type,
        genre: selectedCategory?.title,
      });
    }
  }, [selectedCategory, tracker.general, type]);

  return (
    <CategoryRoot data-testID="category-page-root">
      <DebugUmbracoId umbracoId={selectedCategory?.id || id} position="page" />
      {
        <>
          <div
            ref={filterRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: isMobile ? '90px' : '144px',
              position: 'sticky',
              top: 0,
              zIndex: 6,
              backgroundColor: defaultTheme.page.background,
              boxShadow: `0px -60px 0px 60px ${defaultTheme.page.background}`,
            }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
                alignItems: 'center',
              }}>
              <AppDropDownMenu
                title={isCategNotFound ? '-' : selectedCategory?.title || '-'}
                style={{
                  paddingTop: Metrics.verticalScale(10),
                  paddingBottom: Metrics.verticalScale(10),
                }}
                titleVariant="h2"
                items={categories}
                onClickItem={item => {
                  if (item) {
                    const {id, url} = item;
                    const factory = new RouteFactory(
                      {
                        url: url || '',
                        __typename: type,
                        id: id || '',
                      },
                      navigation,
                      dispatch,
                    );
                    factory.navigate();
                    setCurrentId(item.id);
                  }
                }}
              />
              {sort && !isCategNotFound ? (
                <AppDropDownSelect
                  title={t('Sort by') || ''}
                  icon={'sortby'}
                  value={orderList ? String(customOrderBy) : String(orderBy)}
                  items={data}
                  getValue={item => item.item}
                  getLabel={item => item.label}
                  onClickItem={item => {
                    if (orderList) {
                      setCustomOrderBy(item.item as Sort);
                    } else {
                      setOrderBy(item.item as IContentOrderByInput);
                    }
                  }}
                />
              ) : (
                <View />
              )}
            </View>
            {regionFilter && (
              <div
                style={{
                  marginBottom: 20,
                }}>
                <CategoryTagFilter
                  active={regionId}
                  onChange={setRegionId}
                  style={{marginBottom: Metrics.horizontalScale(19)}}
                  filterRegions={regionID =>
                    allCategoryData.some(item =>
                      regionFilterFunc(item.node, regionID),
                    )
                  }
                />
              </div>
            )}
          </div>
          <div>
            <div style={{height: 20}} />
            {loading ? (
              <CategoryContentLoader />
            ) : !isCategNotFound ? (
              customRenderContent ? (
                customRenderContent({
                  id: selectedCategory?.id,
                  type: type,
                  gridProps: gridProps,
                  orderBy: customOrderBy,
                  regionId: regionId,
                  setAllCategoryData: setAllCategoryData,
                })
              ) : (
                <CategoryPageContent
                  id={selectedCategory?.id}
                  type={type}
                  gridProps={gridProps}
                  orderBy={orderBy}
                  regionId={regionId}
                  setAllCategoryData={setAllCategoryData}
                />
              )
            ) : (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ContentEmpty title={t('Category not found')} />
              </View>
            )}
          </div>

          {/* TO DO */}
          {/* {sort && (
            <CustomBottomSheet
              title={t('Sort by') || ''}
              data={data.map(element => ({
                ...element,
                isActive: orderList
                  ? element.item === customOrderBy
                  : element.item === orderBy,
              }))}
              ref={sheetRef}
              onItemPress={item => {
                if (orderList) {
                  setCustomOrderBy(item.item as Sort);
                } else {
                  setOrderBy(item.item as IContentOrderByInput);
                }
                sheetRef.current?.close();
              }}
            />
          )} */}
        </>
      }
    </CategoryRoot>
  );
};

export default CategoryPage;
