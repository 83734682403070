import {IPushFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import {IPushProps} from 'design-system/src/Widget/Push/Push';
import usePushUtils from '@src/Umbraco/Hooks/usePushUtils';
import React from 'react';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

const PushWrapper = (props: {
  push: IPushFragmentFragment;
  additionnalProps?: Partial<IPushProps>;
}) => {
  const {push} = props;
  const {renderPush} = usePushUtils();
  const {isMobile} = useBreakpoint();
  return (
    <React.Fragment>
      {renderPush(push, {
        responsiveRatio: true,
        containerStyle: {
          padding: 0,
          paddingLeft: isMobile ? 16 : 60,
          paddingRight: isMobile ? 16 : 60,
          marginBottom: isMobile ? 10 + 9 : 49 + 9,
        },
      })}
    </React.Fragment>
  );
};

export default PushWrapper;
