import React, {useState, useEffect, useRef} from 'react';
import CaretDownIcon from './caret-down';
import './CountryPicker.style.scss';
import countryList from './countryList';
import {CountryCode} from 'react-native-country-picker-modal';

interface CountryPickerProps {
  countryCode: CountryCode;
  onSelect: (country: any) => void;
}

const CountryPicker = (props: CountryPickerProps) => {
  const [countries, setCountries] = useState<any[]>(countryList);
  const [open, setOpen] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState<any>({});

  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
  }, []);

  useEffect(() => {
    const defaultC = countryList.find(
      country =>
        country.alpha2Code.toLowerCase() === props.countryCode.toLowerCase(),
    );
    setDefaultCountry(defaultC);
  }, [props.countryCode]);

  const handleClickOutSide = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const toggleDropDown = () => {
    /* reset all countries before closing */
    if (!open) {
      setCountries(countryList);
    }
    setOpen(!open);
  };

  const handleCountryClick = (country: any) => {
    const result = {
      name: country?.name,
      code: country?.alpha2Code,
      capital: country?.capital,
      region: country?.region,
      latlng: country?.latlng,
    };
    setDefaultCountry(country);

    if (props.onSelect) {
      props.onSelect(result);
    }

    /* Hide the dropdown menu on selecting a country */
    toggleDropDown();
  };

  const handleSearchInput = (e: any) => {
    const input = e.target.value.toLowerCase();
    let filteredCountries = countryList.filter(i =>
      i.name.toLowerCase().includes(input.toLowerCase()),
    );
    setCountries(filteredCountries);
  };

  return (
    <div className="cdp_container" ref={dropdownRef}>
      <div className="cdp_dropdown" onClick={toggleDropDown}>
        <img
          className="cdp_country_flag"
          src={defaultCountry?.flag}
          alt={defaultCountry?.name}
        />
        <span className="cdp_selected_country">
          {`+(${
            defaultCountry.callingCodes
              ? defaultCountry.callingCodes[0] ?? 0
              : 'O'
          })`}
        </span>
        <CaretDownIcon point={open ? 'up' : 'down'} />
      </div>

      {open && (
        <div className="cdp_dropdown_items_wrapper">
          <CaretDownIcon point="up_white" />
          <div className="cdp_input_wrapper">
            <input
              onChange={e => handleSearchInput(e)}
              className="cdp_country_search"
              type="text"
              placeholder="search coutries..."
            />
          </div>

          <div className="cdp_dropdown_items">
            {countries.map((i, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleCountryClick(i)}
                  className="cdp_dropdown_item">
                  <img className="cdp_country_flag" src={i.flag} alt="" />
                  <span className="cdp_dropdown_item_title"> {i.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryPicker;
