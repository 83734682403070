import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const rowCardStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  aside: {
    flex: 1,
    marginLeft: Metrics.horizontalScale(16),
  },
});
