import {IInterestFormPage} from '@src/Api/Umbraco/Hooks/useSignupForm';
import {Form} from '@src/Api/Umbraco/interface/UmbracoTypes';
import settingsSlice from '@src/Store/Slices/Settings.slice';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  IValidationElementFragmentFragment,
  useSettingsLazyQuery,
} from '~Api/Graphql/gql/types.generated';

const useInitSetting = () => {
  const [fetchSetting, {loading}] = useSettingsLazyQuery();
  const dispatch = useDispatch();

  const init = useCallback(async () => {
    const {data} = await fetchSetting({fetchPolicy: 'no-cache'});
    if (
      data &&
      data.allSettings &&
      data.allSettings.items &&
      data.allSettings.items[0] &&
      data.allSettings.items[0].__typename === 'Settings'
    ) {
      const {cover, webCover, title, subtitle, cTALabel, defaultHeaderImage} =
        data.allSettings.items[0];
      dispatch(
        settingsSlice.actions.setOnboarding({
          cTALabel: cTALabel,
          title,
          subtitle,
          cover: cover?.url,
          webCover: webCover?.url,
        }),
      );

      dispatch(
        settingsSlice.actions.setProfile({
          cover: defaultHeaderImage?.url,
        }),
      );

      // Init signup form
      let signupForm: Form | undefined;
      const additionnalStep: IInterestFormPage[] = [];

      if (data.allSettings.items[0].signupForm) {
        signupForm = data.allSettings.items[0].signupForm;
      }

      if (data.allSettings.items[0].signupSteps) {
        data.allSettings.items[0].signupSteps.forEach(step => {
          if (step && step.__typename === 'FormPage') {
            additionnalStep.push(step);
          }
        });
      }

      dispatch(settingsSlice.actions.setSignup({signupForm, additionnalStep}));

      // init password validation
      const rules: IValidationElementFragmentFragment[] = [];

      if (data.allSettings.items[0].passwordValidation) {
        data.allSettings.items[0].passwordValidation.forEach(item => {
          if (
            item &&
            item.content &&
            item.content.__typename === 'ValidationElement'
          ) {
            rules.push(item.content);
          }
        });
      }

      dispatch(settingsSlice.actions.setPassword({rules}));
    }
  }, [dispatch, fetchSetting]);
  return {init, loading};
};

export default useInitSetting;
