import React, {useCallback, useMemo, useState} from 'react';
import chunk from 'lodash/chunk';
import {Pressable, View} from 'react-native';
import Metrics from '../../Theme/Metrics';
export interface GridViewPartnerProps<T> {
  data: T[];
  renderItem(item?: T, index?: number): React.ReactNode;
  onPressLink?(item?: T, index?: number): void;
}

export default function GridViewPartner<T extends any>(
  props: GridViewPartnerProps<T>,
) {
  const {data, renderItem, onPressLink} = props;
  const chunckData = chunk(data, 3);

  return (
    <>
      {chunckData.map((d, parentIndex) => (
        <View
          key={`dp-${parentIndex}`}
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {d.map((item, index) => (
            <Pressable
              key={`gvp-${index}`}
              onPress={() => onPressLink && onPressLink(item, index)}
              style={{
                borderTopWidth: parentIndex === 0 ? 1 : 0,
                borderRightWidth: index === 0 || index === 1 ? 1 : 0,
                borderBottomWidth: 1,
                borderColor: '#32343E',
                flexBasis: '33.333333%'
              }}>
              {renderItem(item, index)}
            </Pressable>
          ))}
        </View>
      ))}
    </>
  );
}
