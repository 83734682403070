import BottomTabNavItemHome from './BottomTabNavigationItem/BottomTabNavItemHome/BottomTabNavItemHome';
import BottomTabNavItemWatch from './BottomTabNavigationItem/BottomTabNavItemWatch/BottomTabNavItemWatch';
import BottomTabNavItemLearn from './BottomTabNavigationItem/BottomTabNavItemLearn/BottomTabNavItemLearn';
import BottomTabNavItemListen from './BottomTabNavigationItem/BottomTabNavItemListen/BottomTabNavItemListen';
import BottomTabNavItemMenu from './BottomTabNavigationItem/BottomTabNavItemMenu/BottomTabNavItemMenu';
import BottomTabNavigation from './BottomTabNavigation/BottomTabNavigation';

import TabBarHeader from './TabBarHeader/TabBarHeader';

import NavLiveTvCard from './NavLiveTvCard/NavLiveTvCard';
import NavLiveTvCardWeb from './NavLiveTvCard/NavLiveTvCard.web';

const BottomNavigationItems = [
  BottomTabNavItemHome,
  BottomTabNavItemWatch,
  BottomTabNavItemListen,
  BottomTabNavItemLearn,
  BottomTabNavItemMenu,
];
export {
  BottomTabNavigation,
  BottomNavigationItems,
  TabBarHeader,
  NavLiveTvCard,
  NavLiveTvCardWeb,
};
