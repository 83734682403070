import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IPersonalityQuizBody} from './interface/memberDataBody.interface';
import {IPersonalityQuizResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/personality-type';
export const createMemberPersonalityTypeEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getAllMemberPersonalityType: builder.query<IPersonalityQuizResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  updateMemberPersonalityType: builder.query<
    IPersonalityQuizResponse[],
    {data: IPersonalityQuizBody}
  >({
    query: ({data}) => ({
      url: `/me/personality-quiz/answer`,
      method: 'POST',
      body: data,
    }),
  }),
});
