export const purposes: Record<string, string> = {
  technical_cookies: 'technical-V78NMPk2',
  app_installs: 'appinstal-kFdMkMbE',
  usage_statistics: 'statistiqu-BAXzzaNi',
  develop_and_improve_services: 'improve_products',
  measure_content_performance: 'measure_content_performance',
  storage_and_access_to_geolocation: 'geo_marketing_studies',
};

export const vendors: Record<string, string> = {
  amplitude: 'c:amplitude-NhfD7TLY',
  appsflyer: 'c:appsflyer-MFCic6i6',
  'trace+': 'c:trace-cNBAVEar',
};
