import React, {
  ForwardedRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import {View, Animated} from 'react-native';
import {
  useSectionPageContentLazyQuery,
  useSectionPageTopMenuLazyQuery,
} from '~Api/Graphql/gql/types.generated';
// import Header, {HeaderProps} from '~Components/Header/Header';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
// import {useNavigation} from '@react-navigation/native';
// import {StackHeaderProps} from '@react-navigation/stack';
import HomeContentLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/HomeContentLoader';
import useGetTopMenu from '~Umbraco/Hooks/useGetTopMenu';
import useCacheWithExpiration from '~Api/Graphql/hooks/useCacheWithExpiration';
import useNavigation from '@src/Navigation/useNavigation';
import {ContentSource} from '~Umbraco/Components/ContextualContentSlider/ContextualContentSlider';
import appSlice from '@src/Store/Slices/AppSlice';
import {useAppDispatch} from '@src/Store/hooks';
// import useMemberWatched from '~Hooks/useMemberWatched';

export interface ISectionPageContentRef {
  refetch: () => Promise<void>;
}
interface ISectionPageContentProps {
  id: string;
  title?: string;
  animatedValue?: Animated.Value;
  contentSource: ContentSource;
}
const SectionPageContent = (
  props: ISectionPageContentProps,
  ref: ForwardedRef<ISectionPageContentRef>,
) => {
  const {id, title, contentSource, animatedValue} = props;
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  // const {fetchWatched} = useMemberWatched();

  const fetchPolicy = useCacheWithExpiration();
  const [fetchSection, {data, loading: contentLoading, refetch}] =
    useSectionPageContentLazyQuery();
  const [
    fetchTopMenuSection,
    {refetch: refetchTopMenu, data: topMenuData, loading: topMenuLoading},
  ] = useSectionPageTopMenuLazyQuery();
  const {getTopMenu} = useGetTopMenu();

  const loading = topMenuLoading || contentLoading;

  const {renderSectionPage} = useSectionPageUtils();
  useEffect(() => {
    if (id) {
      fetchSection({
        variables: {id},
        errorPolicy: 'all',
        fetchPolicy: fetchPolicy,
        partialRefetch: true,
      });
      fetchTopMenuSection({
        variables: {id},
        errorPolicy: 'all',
        fetchPolicy: fetchPolicy,
        partialRefetch: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchPolicy]);

  useEffect(() => {
    if (
      topMenuData &&
      topMenuData.sectionPage &&
      topMenuData.sectionPage.__typename === 'SectionPage'
    ) {
      const topMenu = getTopMenu(topMenuData.sectionPage.topMenu);
      dispatch(appSlice.actions.setTopMenu(topMenu || null));
    }
    return () => {
      dispatch(appSlice.actions.setTopMenu(null));
    };
  }, [topMenuData]);

  // TODO: set header title
  // useEffect(() => {
  //   navigation.setOptions({
  //     headerTitle: title,
  //   });
  // }, [title, navigation]);

  const {pageContent}: {pageContent: ReactNode[]} = useMemo(() => {
    if (
      data &&
      data.sectionPage &&
      data.sectionPage.__typename === 'SectionPage'
    ) {
      return {
        pageContent: renderSectionPage(
          data.sectionPage,
          contentSource,
          animatedValue,
        ).map((content: any, index: number) => (
          <React.Fragment key={content.id || index}>{content}</React.Fragment>
        )),
      };
    }
    return {pageContent: []};
  }, [data, renderSectionPage, contentSource, animatedValue]);

  useImperativeHandle(
    ref,
    () => ({
      refetch: async () => {
        try {
          await Promise.allSettled([
            // await refetchTopMenu(),
            // await refetch(),
            // await fetchWatched(),
          ]);
        } catch (e) {}
      },
    }),
    [
      /* refetch, refetchTopMenu, fetchWatched */
    ],
  );

  return (
    <View style={{flex: 1}}>
      {loading ? <HomeContentLoader /> : pageContent}
    </View>
  );
};

export default React.memo(
  React.forwardRef<ISectionPageContentRef, ISectionPageContentProps>(
    SectionPageContent,
  ),
);
