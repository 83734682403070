import {
  IAwardCategoryPageFragmentFragment,
  IButtonFragmentFragment,
  IContentLink,
  ICtaSlideFragmentFragment,
  IFaqItemFragmentFragment,
  IHeroVideoCoverFragmentFragment,
  IIconSlideFragmentFragment,
  IKeyFigureFragmentFragment,
  ILogoLink,
  ILogoSliderFragmentFragment,
  ITextSlide1FragmentFragment,
  ITitleSliderFragmentFragment,
} from '@src/Api/Graphql/gql/types.generated';
import {useStringExtension} from '@src/Hooks/useStringExtension';
import {RouteTypename} from '@src/Navigation/type';
import useNavigation from '@src/Navigation/useNavigation';
import ContactBlock from 'design-system/src/Components/ContactBlock/ContactBlock.web';
import ServiceCarouselNumber from 'design-system/src/Components/ServiceCarouselNumber/ServiceCarouselNumber.web';
import ServiceChannel, {
  IItemServiceChannel,
} from 'design-system/src/Components/ServiceChannel/ServiceChannel.web';
import ServiceFaq, {
  IServiceFaItem,
} from 'design-system/src/Components/ServiceFaq/ServiceFaq.web';
import ServicePromote, {
  IBlockItemTitleSlider,
  IBlockTitleSlider,
} from 'design-system/src/Components/ServicePromote/ServicePromote.web';
import Statistic from 'design-system/src/Components/Statistic/Statistic';
import StatisticInfo from 'design-system/src/Components/StatisticInfo/StatisticInfo';
import VideoBlock from 'design-system/src/Components/VideoBlock/VideoBlock.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode} from 'react';
import {getLinkUrl} from '../Utils/element/getLinkPickerUrl';
import {RouteFactory} from '../Utils/RouteFactory';
import {useDispatch} from 'react-redux';
import {IButtonProps} from 'design-system/src/Components/Button/Button';
import {View} from 'react-native';
import {HeroSliderData} from '../Utils/content/mapHeroSlide';
import useGetResponsiveCover from './useGetResponsiveCover';

export interface IButton {
  url?: string;
  id?: string;
  buttonProps?: IButtonProps;
  __typename: string;
}

const mapButton = (buttonContent: IButtonFragmentFragment): IButton => {
  let url;
  let id;
  let __typename = 'Unknown';
  const buttonProps: HeroSliderData['button'] | undefined = {
    variant:
      (buttonContent.variant as NonNullable<
        HeroSliderData['button']
      >['variant']) || 'outlined',
    icon: (buttonContent.icon || '').toLowerCase() as NonNullable<
      HeroSliderData['button']
    >['icon'],
    size:
      (buttonContent.size as NonNullable<HeroSliderData['button']>['size']) ||
      'small',
    title: buttonContent.title || '',
  };

  if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    (buttonContent.link_to[0].content.__typename === 'InternalLink' ||
      buttonContent.link_to[0].content.__typename === 'ContentLink')
  ) {
    url = buttonContent.link_to[0].content.link?.url || '';
    id = buttonContent.link_to[0].content.link?.id || '';
    __typename =
      buttonContent.link_to[0]?.content.link?.__typename || 'Unknown';
  } else if (
    buttonContent.link_to &&
    buttonContent.link_to[0] &&
    buttonContent.link_to[0].content &&
    buttonContent.link_to[0].content.__typename === 'ExternalLink'
  ) {
    url = buttonContent.link_to[0].content.externalUrl || '';
    __typename = 'ExternalLink';
    id = '';
  }
  return {
    buttonProps: {
      ...buttonProps,
      title: buttonProps.title || '',
    },
    url,
    id: id || '',
    __typename,
  };
};

const useElementRenderer = () => {
  const navigation = useNavigation();
  const {formatFromHtml} = useStringExtension();
  const dispatch = useDispatch();
  const {getResponsiveImage} = useGetResponsiveCover();

  const handleButtonHeader = async (item: IButton | undefined) => {
    if (item) {
      const {id, __typename, url} = item;

      new RouteFactory(
        {
          id: id || '',
          __typename: __typename as RouteTypename,
          url: url || '',
        },
        navigation,
        dispatch,
      ).navigate();
    }
  };

  const handleClickLogoSliderItem = (
    item: IItemServiceChannel & {
      __typename: RouteTypename;
      url: string;
      id: string;
    },
  ) => {
    const {url, id, __typename} = item;
    const factory = new RouteFactory(
      {url, id, __typename},
      navigation,
      dispatch,
    );
    factory.navigate();
  };

  const handleClickButtonItem = (item: IBlockItemTitleSlider) => {
    if (item && item.button) {
      new RouteFactory(
        {
          id: item.button.id || '',
          __typename: item.button.__typename as RouteTypename,
          url: item.button.url || '',
        },
        navigation,
        dispatch,
      ).navigate();
    }
  };

  const renderBlock = (
    pageBlocksData: IAwardCategoryPageFragmentFragment['footerBlocks'],
  ): ReactNode[] => {
    const pageBlocks: ReactNode[] = [];
    pageBlocksData?.forEach((block, blockIndex) => {
      if (block) {
        if (block.content.__typename === 'Wysiwyg') {
          const {title: _title, body} = block.content;
          pageBlocks.push(
            <StatisticInfo
              key={blockIndex}
              title={_title ?? '-'}
              description={formatFromHtml(body)}
            />,
          );
        } else if (block.content.__typename === 'KeyFigures') {
          const {list} = block.content;
          if (list && list[0] && list[0].content.__typename === 'KeyFigure') {
            pageBlocks.push(
              <View
                key={blockIndex}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  paddingHorizontal: Metrics.horizontalScale(29),
                }}>
                {list.map((d, index) => {
                  let {figure, description} = d!
                    .content as IKeyFigureFragmentFragment;
                  return (
                    <View
                      key={`st-${index}`}
                      style={{
                        maxWidth: 149,
                        width: Metrics.horizontalScale(375 / 2),
                        marginBottom: 35,
                      }}>
                      <Statistic
                        value={figure ?? '-'}
                        label={description ?? '-'}
                      />
                    </View>
                  );
                })}
              </View>,
            );
          }
        } else if (block.content.__typename === 'MultiSlider') {
          const {title: _title, description, sliders} = block.content;
          if (sliders && sliders[0]) {
            let itemSliders: Array<IBlockTitleSlider> = [];
            sliders.forEach(blockSlider => {
              if (
                blockSlider &&
                blockSlider.content.__typename === 'TitleSlider'
              ) {
                const {
                  slides,
                  title: contentTitle,
                  subtitle: contentSubtitle,
                } = blockSlider.content as ITitleSliderFragmentFragment;
                let itemSlider: IBlockTitleSlider = {
                  slideTitle: contentTitle ?? '',
                  slideSubtitle: contentSubtitle ?? '',
                  slides: [],
                };
                if (slides && slides[0]) {
                  if (slides[0].content.__typename === 'TextSlide1') {
                    slides.forEach(itemSlide => {
                      let item =
                        itemSlide?.content as ITextSlide1FragmentFragment;
                      let _cover: IHeroVideoCoverFragmentFragment | undefined;
                      if (item.cover && item.cover[0]) {
                        _cover = item.cover[0]
                          .content as IHeroVideoCoverFragmentFragment;
                      }
                      // TODO: remove any
                      let _link = getLinkUrl(item.linkTo as any);
                      itemSlider.slides.push({
                        title: item.title ?? '-',
                        description: item.description ?? '-',
                        overTitle: item.overtitle ?? '-',
                        image: {
                          uri: _cover ? getResponsiveImage(_cover) : '',
                        },
                        button: _link && {
                          id: _link.id,
                          __typename: _link.__typename,
                          url: _link.url,
                        },
                      });
                    });
                  } else if (slides[0].content.__typename === 'IconSlide') {
                    slides.forEach(itemSlide => {
                      let item =
                        itemSlide?.content as IIconSlideFragmentFragment;
                      let _cover: IHeroVideoCoverFragmentFragment | undefined;
                      if (item.cover && item.cover[0]) {
                        _cover = item.cover[0]
                          .content as IHeroVideoCoverFragmentFragment;
                      }
                      itemSlider.slides.push({
                        title: item.title ?? '-',
                        overTitle: item.overtitle ?? '-',
                        image: {
                          uri: _cover ? getResponsiveImage(_cover) : '',
                        },
                        icon: {
                          uri: item.icon?.url,
                        },
                      });
                    });
                  } else if (slides[0].content.__typename === 'CTASlide') {
                    slides.forEach(itemSlide => {
                      let item =
                        itemSlide?.content as ICtaSlideFragmentFragment;
                      let _cover: IHeroVideoCoverFragmentFragment | undefined;
                      if (item.cover && item.cover[0]) {
                        _cover = item.cover[0]
                          .content as IHeroVideoCoverFragmentFragment;
                      }
                      let _button;
                      if (item.button && item.button[0]) {
                        _button = mapButton(
                          item.button[0].content as IButtonFragmentFragment,
                        );
                      }
                      itemSlider.slides.push({
                        title: item.title ?? '-',
                        description: item.description,
                        image: {
                          uri: _cover ? getResponsiveImage(_cover) : '',
                        },
                        // TODO:
                        button: _button as any,
                      });
                    });
                  }
                }
                itemSliders.push(itemSlider);
              }
            });
            if (itemSliders.length > 0) {
              if (block.content.template !== 'numbers') {
                pageBlocks.push(
                  <ServicePromote
                    key={`${blockIndex}`}
                    title={_title ?? '-'}
                    items={itemSliders}
                    onButtonItemPress={handleClickButtonItem}
                  />,
                );
              } else {
                // TODO: remove
                pageBlocks.push(
                  <ServiceCarouselNumber
                    key={`${blockIndex}`}
                    title={_title ?? '-'}
                    items={itemSliders}
                    description={description ?? '-'}
                    onButtonItemPress={handleClickButtonItem}
                  />,
                );
              }
            }
          }
        } else if (block.content.__typename === 'LogoSlider') {
          let {slides, title: _title} =
            block.content as ILogoSliderFragmentFragment;
          let listChannel: Array<
            IItemServiceChannel & {
              __typename: RouteTypename;
              id: string;
              url: string;
            }
          > = [];
          if (
            slides &&
            slides[0] &&
            slides[0].content.__typename === 'LogoLink'
          ) {
            slides.forEach(item => {
              if (item && item.content.__typename === 'LogoLink') {
                let content = item?.content;
                // TODO: remove any
                const contentLink =
                  content.link && getLinkUrl(content.link as any);
                listChannel.push({
                  logo: {
                    uri: content.logo?.url,
                  },
                  url: contentLink?.url || '',
                  __typename: contentLink?.__typename!,
                  id: contentLink?.id || '',
                });
              }
            });
          }
          if (listChannel.length > 0) {
            pageBlocks.push(
              <ServiceChannel
                key={blockIndex}
                title={_title ?? '-'}
                items={listChannel}
                onItemPress={item => {
                  // console.log('contentLink', item, listChannel);
                  handleClickLogoSliderItem(item);
                }}
              />,
            );
          }
        } else if (block.content.__typename === 'Accordions') {
          const {title: _title, accordions} = block.content;
          if (
            accordions &&
            accordions[0] &&
            accordions[0].content.__typename === 'FaqItem'
          ) {
            let data: Array<IServiceFaItem> = accordions.map(accordion => {
              let {title: accordionTitle, htmlContent} =
                accordion?.content as IFaqItemFragmentFragment;
              return {
                title: accordionTitle ?? '-',
                description: formatFromHtml(htmlContent),
              };
            });
            pageBlocks.push(
              <ServiceFaq key={blockIndex} title={_title ?? '-'} data={data} />,
            );
          }
        } else if (block.content.__typename === 'HeroBanner') {
          const {
            title,
            subtitle = '',
            backgroundImage,
            backgroundImageMobile,
            cTA,
          } = block.content;
          let cTAData: IButton | undefined;
          if (cTA && cTA[0] && cTA[0].content.__typename === 'Button') {
            let buttonContent = cTA[0].content as IButtonFragmentFragment;
            cTAData = mapButton(buttonContent);
          }
          console.log('--cTAData', cTAData, cTA);
          pageBlocks.push(
            <ContactBlock
              key={blockIndex}
              title={title || ''}
              description={subtitle}
              buttonTitle={cTAData?.buttonProps?.title ?? '-'}
              image={
                (backgroundImage && {
                  uri: backgroundImage?.url,
                }) ||
                undefined
              }
              imageMobile={
                (backgroundImageMobile && {
                  uri: backgroundImageMobile?.url,
                }) ||
                undefined
              }
              hideButton={!cTAData}
              onClick={() => {
                if (cTAData) {
                  handleButtonHeader(cTAData);
                }
              }}
            />,
          );
        } else if (block.content.__typename === 'HeroVideoCover') {
          const {image, desktopImage, vimeoHlsUrl, vimeoVideoId} =
            block.content;
          if (vimeoHlsUrl || vimeoVideoId) {
            // TODO get video url
            pageBlocks.push(
              <VideoBlock
                key={blockIndex}
                src={vimeoHlsUrl || ''}
                vimeoId={vimeoVideoId + ''}
                width="100%"
                height="auto"
                poster={image?.url}
                posterDesktop={desktopImage?.url}
              />,
            );
          }
        }
      }
    });

    return pageBlocks;
  };
  return {renderBlock};
};

export default useElementRenderer;
