import {StyleProp, View, ViewStyle} from 'react-native';
import React, {useMemo} from 'react';
import PayWallDevices, {
  IItemDevices,
} from 'design-system/src/Widget/PayWall/PayWallDevices/PayWallDevices';
import {IServicesListFragmentFragment} from '~Api/Graphql/gql/types.generated';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Metrics from 'design-system/src/Theme/Metrics';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

interface IServiceListProps {
  title?: string;
  services?: IServicesListFragmentFragment['services'];
  style?: StyleProp<ViewStyle>;
}

const ServiceList = (props: IServiceListProps) => {
  const {title, services, style} = props;
  const {mobile} = useBreakpoint();
  const items: IItemDevices[] = useMemo(() => {
    const list: IItemDevices[] = [];
    services?.forEach(service => {
      if (
        service &&
        service.content &&
        service.content.__typename === 'Service'
      ) {
        const {icon, text} = service.content;
        if (icon && icon.url) {
          list.push({
            icon: () => (
              <CustomFastImage
                backgroundFallbackColor="transparent"
                source={{uri: icon.url}}
                resizeMode="contain"
                width={Metrics.horizontalScale(24)}
                height={Metrics.horizontalScale(24)}
              />
            ),
            text: text || '',
          });
        }
      }
    });
    return list;
  }, [services]);
  return (
    <View style={[style]}>
      <PayWallDevices
        title={title || ''}
        // @ts-ignore
        contentWrapper={
          mobile
            ? undefined
            : {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }
        }
        items={items}
      />
    </View>
  );
};

export default ServiceList;
