import {
  PageProps,
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
} from 'gatsby';
import React, {Suspense, useEffect, useMemo, useRef} from 'react';
import ModalRoutingContext from '../plugins/layout-wrapper/ModalRoutingContext';
import Modal from './components/general/PageModal/PageModal';
import Home from './components/page-template/Home/Home';
// import useChangeLanguage from '@src/Hooks/useChangeLanguage';
import useTracker from './Services/Amplitude/hooks/useTracker';
import TranslationLoader from './Providers/TranslationLoader/TranslationLoader';
import EmptyLayout from './layout/EmptyLayout/EmptyLayout';
import {languageUnmap} from './tools/languageMapper';
import useChangeLanguage from './Hooks/useChangeLanguage';
import useNavigation from './Navigation/useNavigation';

const ThankYouModal = React.lazy(
  () => import('./App/components/Common/ThankYouModal/ThankYouModal'),
);

export type ILayoutProviderProps = {
  element: WrapPageElementBrowserArgs['element'];
  props:
    | WrapPageElementBrowserArgs<
        any,
        any,
        {modal?: boolean} & Record<string, any>
      >['props']
    | WrapPageElementNodeArgs<any>['props'];
  locationState?: WrapPageElementBrowserArgs<
    any,
    any,
    {modal?: boolean} & Record<string, any>
  >['props']['location']['state'];
};

export type InnerLayoutProps = {
  context: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  location?: PageProps['location'];
  isModal?: boolean;
};

const layouts = {
  AppLayout: React.lazy(() => import('./layout/AppLayout/AppLayout')),
  AwardLayout: React.lazy(() => import('./layout/AwardLayout/AwardLayout')),
  DefaultLayout: React.lazy(
    () => import('./layout/DefaultLayout/DefaultLayout'),
  ),
  EmptyLayout,
};

type TLayoutList = keyof typeof layouts;

const getElementLayout = (
  element?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
): TLayoutList => {
  let layout: TLayoutList = 'DefaultLayout';

  if (
    element &&
    typeof element.type !== 'string' &&
    // @ts-ignore
    element.type.layoutComponent
  ) {
    // @ts-ignore
    layout = element.type.layoutComponent;
  }

  if (
    element &&
    element?.type &&
    typeof element.type !== 'string' &&
    // @ts-ignore
    element?.type?.type &&
    // @ts-ignore
    typeof element?.type?.type !== 'string' &&
    // @ts-ignore
    element.type.type.layoutComponent
  ) {
    // @ts-ignore
    layout = element.type.type.layoutComponent;
  }

  return layout;
};

const getElementUri = (
  element?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
) => {
  let uri: string = element?.props?.uri + '';

  if (!uri) {
    return '/';
  }

  const {
    pageContext: {language},
  } = element?.props || {};

  if (language) {
    uri = uri.replace(new RegExp(`^/${language}|/${language}(?=/)`, 'g'), '');
    uri = uri === '' ? '/' : uri.startsWith('/') ? uri : `/${uri}`;
  }
  return uri;
};

const LayoutProvider = (props: ILayoutProviderProps) => {
  const {element, props: wrapperProps} = props;
  const {updateStoreLang} = useChangeLanguage();
  const tracker = useTracker();
  const navigation = useNavigation();

  // Init product data
  const PreviousLayout = useRef<TLayoutList>();
  const PreviousElement =
    useRef<
      React.ReactElement<any, string | React.JSXElementConstructor<any>>
    >();

  const {pageContext, location} = wrapperProps;
  const locationState: {modal?: boolean; closeTo?: string} = useMemo(
    () =>
      (location.state as WrapPageElementBrowserArgs<
        any,
        any,
        {modal?: boolean} & Record<string, any>
      >['props']['location']['state']) || {},
    [location.state],
  );

  const isModal: boolean = useMemo(
    () =>
      // @ts-ignore
      !!(locationState && locationState.modal) ||
      // @ts-ignore
      element.type.isModal === true,
    [
      // @ts-ignore
      element.type.isModal,
      locationState,
    ],
  );
  const closeTo: string = useMemo(
    () =>
      PreviousElement.current
        ? getElementUri(PreviousElement.current)
        : (locationState && locationState.closeTo) || '/',
    [locationState, PreviousElement.current?.props?.uri],
  );

  // ParentContent
  const ParentContent: React.ComponentType = useMemo(
    () =>
      typeof element.type !== 'string' &&
      // @ts-ignore
      element.type.ParentContent
        ? // @ts-ignore
          element.type.ParentContent
        : Home,
    [element.type],
  );

  /*  */
  const PageElement = useMemo(
    () =>
      isModal
        ? PreviousElement.current ||
          // @ts-ignore
          React.createElement(ParentContent)
        : element,
    [ParentContent, element, isModal],
  );

  // Rendering the current page as a modal, so create an element with the page contents
  const modalElement = isModal ? element : null;

  const handleModalClose = () => {
    navigation.navigate(closeTo);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const elUri = useMemo(() => getElementUri(element), [element?.props?.uri]);

  useEffect(() => {
    tracker.pageView({
      path: elUri,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elUri]);

  useEffect(() => {
    if (!isModal) {
      PreviousLayout.current = getElementLayout(element);
      PreviousElement.current = element;
    }
  }, [element, isModal]);

  useEffect(() => {
    if (location.pathname.includes('/app')) {
      return;
    }
    const lng = languageUnmap(pageContext.language);
    updateStoreLang(lng);
  }, [pageContext.language]);

  // Layout component
  const LayoutName = useMemo(
    () => (isModal ? PreviousLayout.current : getElementLayout(element)),
    [isModal, element],
  );

  const Layout = layouts[LayoutName || 'DefaultLayout'];

  // const modalLayoutName = useMemo(() => {
  //   return isModal ? getElementLayout(element) : undefined;
  // }, [isModal, element]);

  // const ModalLayout = modalLayoutName ? layouts[modalLayoutName] : undefined;

  return (
    <div className="layout-root">
      <TranslationLoader language={pageContext.language} />
      <Suspense fallback={null}>
        <Layout {...{context: wrapperProps.pageContext}}>{PageElement}</Layout>
      </Suspense>
      {isModal && (
        <Modal
          isOpen={isModal}
          onClose={handleModalClose}
          // @ts-ignore
          {...element.modalProps}>
          <ModalRoutingContext.Provider
            value={{
              modal: true,
              closeTo,
            }}>
            {/* {ModalLayout ? (
              <ModalLayout variant="modal">{modalElement}</ModalLayout>
            ) : ( */}
            {LayoutName === 'AwardLayout' ? (
              <Suspense fallback={null}>
                <Layout
                  {...{context: wrapperProps.pageContext}}
                  variant="modal">
                  {modalElement}
                </Layout>
              </Suspense>
            ) : (
              modalElement
            )}
            {/* )} */}
          </ModalRoutingContext.Provider>
        </Modal>
      )}
      {isModal && (
        <style
          id="body-override"
          dangerouslySetInnerHTML={{
            __html: `
              body {
                overflow: hidden !important;     
              }`,
          }}></style>
      )}
      <Suspense>
        <ThankYouModal />
      </Suspense>
    </div>
  );
};

export default LayoutProvider;
