import React, {useMemo} from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Metrics from '../../Theme/Metrics';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

export type GridViewProps<T> = {
  data: T[];
  onItemPress?: (item: T) => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  footer?: React.ReactNode;
  yPadding?: number;
  xPaddingWrap?: number;
  noScroll?: boolean;
  disableBigItem?: boolean;
  mobileColumns?: number;
};

const GridView = <T extends any>(props: GridViewProps<T>) => {
  const {
    data,
    onItemPress,
    renderItem,
    yPadding = 24,
    footer,
    xPaddingWrap = 16,
    noScroll,
    disableBigItem,
    mobileColumns = 2,
  } = props;
  const {isMobile} = useBreakpoint();
  const renderContent = (
    <>
      <div
        style={{
          gap: isMobile ? 8 : 12,
          display: 'grid',
          gridTemplateColumns: isMobile
            ? `repeat(${mobileColumns}, 1fr)`
            : 'repeat(4, 1fr)',
          width: '100%', // Ensure the grid follows the parent's width
          boxSizing: 'border-box', // Include padding and border in the element's total width and height
        }}
        data-testid="web-grid">
        {data.map((value, index) => {
          let item = value as any;
          const modeIndex = (index || 0) % 10;
          const showBig = disableBigItem
            ? false
            : isMobile
              ? index === 0
              : modeIndex === 0 || modeIndex === 1;
          return (
            <div
              key={index}
              style={{
                gridColumn: showBig
                  ? isMobile
                    ? '1 / 3'
                    : modeIndex === 1
                      ? '3 / 5'
                      : '1 / 3'
                  : undefined,
                marginTop: isMobile ? 0 : index > 1 && showBig ? 40 : 0,
                marginBottom: isMobile ? 0 : showBig ? 40 : 0,
              }}
              onClick={
                onItemPress &&
                (() => {
                  onItemPress(item);
                })
              }>
              {renderItem(item, index)}
            </div>
          );
        })}
      </div>
      {footer}
    </>
  );

  const containerStyle = useMemo(() => {
    return {
      flexGrow: 1,
      paddingHorizontal: Metrics.horizontalScale(xPaddingWrap - 4),
      marginTop: yPadding ? -yPadding / 2 : undefined,
    };
  }, [yPadding, Metrics, xPaddingWrap]);
  if (noScroll) {
    return (
      <View
        style={StyleSheet.flatten([
          containerStyle,
          {
            marginBottom: yPadding * 2,
          },
        ])}>
        {renderContent}
      </View>
    );
  }
  return (
    <ScrollView
      style={{marginBottom: yPadding * 2}}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={StyleSheet.flatten([containerStyle])}>
      {renderContent}
    </ScrollView>
  );
};

export default GridView;
