import React, {FC} from 'react';
import {View, Pressable} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography.web';

interface IAppGridProps {
  gridTemplateColumns?: string;
  columnGap?: number;
  rowGap?: number;
  nbColumns: number;
  children: React.ReactNode;
  title?: string | null;
  seeMore?:
    | {
        label: string;
        onPress?: (() => void) | undefined;
      }
    | undefined;
}

const AppGrid: FC<IAppGridProps> = ({
  gridTemplateColumns,
  columnGap = 0,
  rowGap = 0,
  nbColumns,
  children,
  title,
  seeMore,
}) => {
  return (
    <div>
      {Boolean(title) && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}>
          {Boolean(title) && <Typography variant="h3">{title}</Typography>}
          {seeMore && (
            <Pressable
              style={{padding: 13, margin: -13}}
              onPress={seeMore.onPress}>
              <Typography
                children={seeMore.label}
                color="primary"
                variant="button2"
              />
            </Pressable>
          )}
        </View>
      )}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns:
            gridTemplateColumns ?? `repeat(${nbColumns}, 1fr)`,
          columnGap,
          rowGap,
        }}>
        {children}
      </div>
    </div>
  );
};

export default AppGrid;
