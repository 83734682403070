import {StyleSheet} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
export const subscriptionCardContentStyles = StyleSheet.create({
  container: {
    paddingVertical: Metrics.verticalScale(16),
    paddingRight: Metrics.horizontalScale(12),
    paddingLeft: Metrics.horizontalScale(16),
    borderRadius: Metrics.moderateScale(4),
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: Metrics.horizontalScale(17),
  },
  description: {
    // color: defaultTheme.primaryInfo,

    opacity: 0.7,
  },
  title: {
    flex: 1,
  },
});
