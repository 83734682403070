import React from 'react';
import {View, Pressable, Platform} from 'react-native';
import {FastImageProps} from 'react-native-fast-image';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';

export type SubscriptionOtherProps = {
  title: string;
  subtitle: string;
  description: string;
  image: FastImageProps['source'];
  icon?: FastImageProps['source'];
  onPress?: () => void;
};

export default function SubscriptionOther(props: SubscriptionOtherProps) {
  const {title, subtitle, description, image, onPress, icon} = props;
  const width = Platform.OS === 'web' ? '100%' : Metrics.horizontalScale(343);
  const height = Platform.OS === 'web' ? '100%' : Metrics.verticalScale(232);
  return (
    <Pressable onPress={onPress}>
      <View
        style={{
          position: 'relative',
        }}>
        <View
          style={{
            justifyContent: 'space-between',
            position: 'relative',
            zIndex: 2,
            width: width,
            height: height,
            paddingHorizontal: Metrics.horizontalScale(18),
            paddingVertical: Metrics.verticalScale(16),
          }}>
          <Typography
            variant="h3"
            style={{paddingTop: Metrics.verticalScale(9)}}>
            {title}
          </Typography>
          <View style={{flexDirection: 'row'}}>
            <View style={{marginRight: Metrics.horizontalScale(19)}}>
              {icon ? (
                <CustomFastImage
                  source={icon}
                  width={Metrics.horizontalScale(72)}
                  height={Metrics.horizontalScale(72)}
                  backgroundFallbackColor="transparent"
                  resizeMode="contain"
                />
              ) : (
                <Icon name="subscription" />
              )}
            </View>
            <View style={{maxWidth: Metrics.horizontalScale(212)}}>
              <Typography variant="body2">{subtitle}</Typography>
              <Typography
                variant="body3"
                style={{marginTop: Metrics.verticalScale(4)}}>
                {description}
              </Typography>
            </View>
          </View>
        </View>
        <View
          style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
          <CustomFastImage
            source={image}
            width={width}
            height={height}
            style={{borderRadius: Metrics.horizontalScale(4)}}
          />
        </View>
      </View>
    </Pressable>
  );
}
