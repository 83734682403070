import React, {FC} from 'react';
import useOnScreen from '../../Hooks/useOnScreen';

interface IImageWithBackgroundShadowProps {
  image: string;
  blurFilter: number;
  opacity: number;
  containerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
}

const ImageWithBackgroundShadow: FC<
  IImageWithBackgroundShadowProps
> = props => {
  const {image, blurFilter, opacity, containerStyle, imageStyle} = props;

  const [ref, isVisible] = useOnScreen<HTMLDivElement>();

  const style = {
    imageContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: isVisible ? `url(${image})` : undefined,
      ...imageStyle,
    },
  };
  return (
    <div
      ref={ref}
      style={{
        ...containerStyle,
        position: 'relative',
      }}>
      <div
        style={{
          ...(style.imageContainer as any),
          zIndex: 9,
        }}></div>
      <div
        style={{
          ...(style.imageContainer as any),
          filter: `blur(${blurFilter}px)`,
          mixBlendMode: 'multiply',
          zIndex: 8,
          opacity: opacity,
        }}></div>
    </div>
  );
};

export default ImageWithBackgroundShadow;
