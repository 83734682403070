import PageLoader from '@src/components/general/PageLoader/PageLoader';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {ReactNode, useMemo} from 'react';
import {View} from 'react-native';
import {useAwardHomePageByIdQuery} from '~Api/Graphql/gql/types.generated';
import useAwardHomePageUtils from '~Umbraco/Hooks/useAwardHomePageUtils';
interface AwardHomePageProps {
  id: string;
}

const AwardHomePage = (props: AwardHomePageProps) => {
  const {id} = props;
  const {isMobile} = useBreakpoint();

  const response = useAwardHomePageByIdQuery({
    variables: {
      id,
    },
    errorPolicy: 'all',
    skip: !id,
  });
  const {renderPage} = useAwardHomePageUtils();

  const loading = response.loading;

  const {pageContent}: {pageContent: ReactNode[]} = useMemo(() => {
    const {data} = response;
    if (
      data &&
      data.awardsHomepage &&
      data.awardsHomepage.__typename === 'AwardsHomepage'
    ) {
      return {
        pageContent: renderPage(data.awardsHomepage),
      };
    }
    return {pageContent: []};
  }, [renderPage, response]);

  return (
    <View style={{flex: 1}}>
      {loading ? (
        <PageLoader />
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: isMobile ? 48 : 96,
          }}>
          {pageContent}
        </div>
      )}
    </View>
  );
};

export default AwardHomePage;
