import React from 'react';
import BottomTabNavigationItem, {
  BottomNavigationItemProps,
} from '../BottomTabNavigationItem';

interface BottomTabNavItemHomeProps
  extends Omit<BottomNavigationItemProps, 'icon'> {}
const BottomTabNavItemHome = (props: BottomTabNavItemHomeProps) => {
  const {title = 'Home', ...rest} = props;
  return <BottomTabNavigationItem icon={'home'} title={title} {...rest} />;
};

export default BottomTabNavItemHome;
