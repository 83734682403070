import React from 'react';
import GenericCard from '../../GenericCard/GenericCard';
import RowCard from '../../RowCard/RowCard';
import VodCardAside from '../VodCardAside/VodCardAside';
import {VodCardProps} from '../VodCardProps';

const SearchVodCard = (props: VodCardProps) => {
  return (
    <RowCard
      left={
        <GenericCard coverImage={props.cover} type={'small'} />
      }
      right={<VodCardAside asideProps={props} />}
    />
  );
};

export default SearchVodCard;
