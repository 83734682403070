import ThankYouPage from '@src/Umbraco/Pages/ThankYouPage/ThankYouPage';
import {graphql, PageProps} from 'gatsby';
import React, {useContext} from 'react';
import ModalRoutingContext from '../../../plugins/layout-wrapper/ModalRoutingContext';
import useNavigation from '@src/Navigation/useNavigation';

const Thanks = (props: PageProps) => {
  const {params: {id} = {}, location} = props;
  const {navigate} = useNavigation();
  const {closeTo} = useContext(ModalRoutingContext);
  const {state = {} as any} = location || {};
  const closeToState = state?.closeTo;

  const handleClose = () => {
    if (closeToState || closeTo) {
      navigate(closeToState || closeTo);
    } else {
      navigate('/');
    }
  };
  return (
    <div>
      <ThankYouPage id={id} onClose={handleClose} />
    </div>
  );
};

Thanks.isModal = true;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Thanks;
