import useMember from '@src/Store/selectors/useMember';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLazyGetUserSubscriptionQuery} from '~Api/TraceApi/traceApi';
import memberSlice, {IMemberSubscription} from '~Store/Slices/Member.slice';

export const useMemberSubscription = () => {
  const member = useMember();
  const dispatch = useDispatch();
  const [getUserSubscription] = useLazyGetUserSubscriptionQuery();

  useEffect(() => {
    if (member) {
      getUserSubscription()
        .then(({data}) => {
          if (data) {
            let memberSubscription: IMemberSubscription = {
              memberId: data.memberId,
              subscriptionStart: data.subscriptionStart.toString(),
              id: data._id,
              deviceType: data.deviceType,
              subscriptionType: data.subscriptionTypeId,
              productId: data.externalID,
              cancelRequested: data.cancelRequested,
            };
            dispatch(
              memberSlice.actions.setMemberSubscription(memberSubscription),
            );
            // checkReceipt(memberSubscription);
          } else {
            dispatch(memberSlice.actions.setMemberSubscription(undefined));
          }
        })
        .catch(error => {
          console.log('error get subscription', JSON.stringify(error));
          dispatch(memberSlice.actions.setMemberSubscription(undefined));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);
};
