import Button from 'design-system/src/Components/Button/Button';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import RadioButton from 'design-system/src/Components/RadioButton/RadioButton';
import {RadioButtonItem} from 'design-system/src/Components/RadioButton/RadioButtonItem/RadioButtonItem';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {useQuizPageQuestionByUrlEndQuery} from '~Api/Graphql/gql/types.generated';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {useAppDispatch} from '@src/Store/hooks';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import appSlice from '@src/Store/Slices/AppSlice';
import CustomModalHeader from '@src/components/general/CustomModal/CustomModalHeader';
import {useIsListenMiniPlayer} from '@src/Store/selectors/useMember';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

export interface IQuizResult {
  label: string;
  weight: number;
  answerPoint: number;
  id: string;
  tagId: string;
  value?: string;
  color?: string;
  personalityName: string;
}

export default function QuizQuestionPage() {
  const params = useParams();

  const {navigate, goBack} = useAppNavigation();

  const {endUrl} = params || {};

  const response = useQuizPageQuestionByUrlEndQuery({
    variables: {
      endUrl,
    },
    skip: !endUrl,
  });

  const [step, setStep] = useState(0);
  const [allQuiz, setAllQuiz] = useState<IQuizResult[]>([]);

  const loading = response.loading;
  const quizPage = response.data?.allQuizPage?.items[0];

  const {t} = useTranslation(['learn']);

  const [buttonDisable, setButtonDisable] = useState<boolean>();

  const currentResponse = useRef<IQuizResult[]>([]);

  const result = useRef<IQuizResult[]>([]);
  const dispatch = useAppDispatch();
  const isMiniPlayer = useIsListenMiniPlayer();

  const {isMobile} = useBreakpoint();

  useEffect(() => {
    if (allQuiz) {
      currentResponse.current = allQuiz;
    }
  }, [allQuiz]);

  const {
    quiz,
    totalStep,
    personalityName,
    quizId,
  }: {
    quiz: Array<IQuizResult>;
    totalStep: number;
    personalityName?: string;
    quizId?: string;
  } = useMemo(() => {
    let _quiz: Array<IQuizResult> = [];
    let _personalityName: string | undefined = undefined;
    let _quizId: string | undefined;

    if (quizPage) {
      const {quizz, name} = quizPage;
      if (quizz && quizz[0] && quizz[0].__typename === 'PersonalityQuiz1') {
        const {questions, id: currentQuizId} = quizz[0];
        _quizId = currentQuizId;
        if (questions) {
          questions.forEach(item => {
            if (
              item &&
              item.content &&
              item.content.__typename === 'PersonalityQuizQuestion'
            ) {
              const {label, weight, linkedPersonalities} = item.content;
              _quiz.push({
                label: label || '',
                weight: weight || 0,
                id:
                  linkedPersonalities &&
                  linkedPersonalities[0] &&
                  linkedPersonalities[0].__typename === 'PersonalityPages'
                    ? linkedPersonalities[0].id
                    : '',
                tagId:
                  linkedPersonalities &&
                  linkedPersonalities[0] &&
                  linkedPersonalities[0].__typename === 'PersonalityPages' &&
                  linkedPersonalities[0].linkedTag &&
                  linkedPersonalities[0].linkedTag[0]
                    ? linkedPersonalities[0].linkedTag[0].id
                    : '',
                color:
                  linkedPersonalities &&
                  linkedPersonalities[0] &&
                  linkedPersonalities[0].__typename === 'PersonalityPages' &&
                  linkedPersonalities[0].linkedTag &&
                  linkedPersonalities[0].linkedTag[0] &&
                  linkedPersonalities[0].linkedTag[0].__typename ===
                    'ContentTag'
                    ? linkedPersonalities[0].linkedTag[0].color
                    : undefined,
                answerPoint: 0,
                personalityName:
                  linkedPersonalities &&
                  linkedPersonalities[0] &&
                  linkedPersonalities[0].__typename === 'PersonalityPages' &&
                  linkedPersonalities[0].linkedTag &&
                  linkedPersonalities[0].linkedTag[0] &&
                  linkedPersonalities[0].linkedTag[0].__typename ===
                    'ContentTag'
                    ? linkedPersonalities[0].linkedTag[0].name || ''
                    : '',
              });
            }
          });
        }
      }
      _personalityName = name;
    }
    if (!currentResponse.current.length) {
      currentResponse.current = _quiz;
    }
    return {
      quiz: _quiz,
      totalStep: _quiz.length,
      personalityName: _personalityName,
      quizId: _quizId,
    };
  }, [quizPage]);

  useEffect(() => {
    if (!result.current[step]) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    if (step === 0 && quiz.length) {
      const _personalityName = quiz
        .map(_quiz => _quiz.personalityName)
        .join(',');
      tracker.learn.personalityQuizStarted(_personalityName);
    }
  }, [quiz, step]);

  const handleNext = useCallback(() => {
    if (step < totalStep - 1) {
      // navigation.setParams({
      //   id: id,
      //   step: step + 1,
      //   quiz: currentResponse.current,
      // });
      setStep(step + 1);
      setAllQuiz(currentResponse.current);
    } else {
      if (quiz.length) {
        const _personalityName = quiz
          .map(_quiz => _quiz.personalityName)
          .join(',');
        tracker.learn.personalityQuizCompleted(_personalityName);
      }
      navigate(AppRoutesEnum.QUIZ_RESULT, {
        state: {
          params: {
            result: result.current,
            id: quizPage?.id || '',
            url: quizPage?.url,
            quizId: quizId || '',
          },
        },
      });
    }
  }, [step, totalStep, navigate, quizPage, quiz, personalityName]);
  // const {canPlay} = useListenPlayer();

  // useEffect(() => {
  //   if (focused) {
  //     dispatch(tabBarSlice.actions.setMount(false));
  //   } else {
  //     dispatch(tabBarSlice.actions.setMount(true));
  //   }
  //   if (canPlay) {
  //     dispatch(listenPlayerSlice.actions.setCanPlay(false));
  //   }

  //   return () => {
  //     if (!canPlay) {
  //       dispatch(listenPlayerSlice.actions.setCanPlay(true));
  //     }
  //   };
  // }, [canPlay, focused, dispatch]);

  useEffect(() => {
    const handleGoBack = () => {
      if (step === 0) {
        goBack();
      } else {
        setStep(Math.max(step - 1, 0));
      }
    };

    dispatch(
      appSlice.actions.setModalHeader(
        <CustomModalHeader
          title={`${t('Step')} ${t('{{current}} of {{total}}', {
            current: step + 1,
            total: totalStep,
          })}`}
          onGoBack={handleGoBack}
          onClose={goBack}
        />,
      ),
    );
    return () => {
      dispatch(appSlice.actions.setModalHeader(null));
    };
  }, [step, totalStep]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}>
      <DebugUmbracoId umbracoId={quizPage?.id} position="page" />
      {endUrl ? (
        loading ? (
          <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
        ) : (
          <View
            style={{
              marginTop: Metrics.verticalScale(122),
              paddingHorizontal: Metrics.horizontalScale(24),
              width: isMobile ? '100%' : Metrics.horizontalScale(355),
            }}>
            <View
              style={{
                alignItems: 'center',
              }}>
              <Typography
                variant="h2"
                style={{
                  textAlign: 'center',
                  maxWidth: Metrics.horizontalScale(285),
                }}>
                {quiz[step] ? quiz[step].label : ''}
              </Typography>
            </View>
            <View style={{marginTop: Metrics.verticalScale(60)}}>
              <RadioButton
                useChoice
                value={
                  result.current[step]?.value
                    ? [result.current[step].value || '']
                    : []
                }
                useCheckValue
                groups={[
                  {
                    label: t('Strongly Agree'),
                    value: 'Strongly Agree',
                    isCorrect: true,
                    weight: 2,
                  },
                  {
                    label: t('Agree'),
                    value: 'Agree',
                    isCorrect: true,
                    weight: 1,
                  },
                  {
                    label: t('Unsure'),
                    value: 'Unsure',
                    isCorrect: true,
                    weight: 0,
                  },
                  {
                    label: t('Disagree'),
                    value: 'Disagree',
                    isCorrect: true,
                    weight: -1,
                  },
                  {
                    label: t('Strongly Disagree'),
                    value: 'Strongly Disagree',
                    isCorrect: true,
                    weight: -2,
                  },
                ]}
                onChecked={(data: RadioButtonItem) => {
                  const {weight: answerPoint, value} = data;
                  if (
                    currentResponse.current[step] &&
                    answerPoint !== undefined
                  ) {
                    result.current[step] = {
                      ...currentResponse.current[step],
                      value,
                      answerPoint,
                    };
                    setButtonDisable(false);
                  }
                }}
                itemStyle={{
                  borderRadius: Metrics.verticalScale(24),
                }}
              />
              <View
                style={{
                  marginTop: Metrics.verticalScale(32),
                }}>
                <Button
                  title={t('Continue')}
                  style={{width: '100%'}}
                  variant={!buttonDisable ? 'contained' : 'disabled'}
                  size="medium"
                  onPress={() => {
                    if (!buttonDisable) handleNext();
                  }}
                />
                <Typography
                  variant="ui3"
                  color="gray"
                  style={{
                    textAlign: 'center',
                    marginTop: Metrics.verticalScale(20),
                  }}>
                  {t('Select the answer that resonates best with you')}
                </Typography>
              </View>
              {isMiniPlayer && <div style={{height: 100}} />}
            </View>
          </View>
        )
      ) : (
        <ContentEmpty
          style={{flex: 1, justifyContent: 'center'}}
          title={t('Section not found')}
        />
      )}
      {isMiniPlayer && <div style={{height: 80}} />}
    </div>
  );
}
