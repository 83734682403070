import {useDispatch} from 'react-redux';
import memberSlice, {MemberDataSource} from '~Store/Slices/Member.slice';
import {RouteTypename} from '~Navigation/type';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import {ContentTypeRaw} from '~Interfaces/ContentTypeRaw';
import {useEffect, useRef} from 'react';
import {useMainContentByIdLazyQuery} from '~Api/Graphql/gql/types.generated';
import {
  useLazyCreateMemberSavedQuery,
  useLazyDeleteMemberSavedByContentIdQuery,
} from '~Api/TraceApi/traceApi';
import {ISavedResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {useAppSelector} from '@src/Store/hooks';

export interface IToggleBookmarkParams {
  id: string;
  url: string;
  __typename: RouteTypename;
  name?: string;
  categoryName?: string;
  raw?: ContentTypeRaw;
}
export const getContentSource = (url: string): MemberDataSource => {
  if (url) {
    if (url.indexOf('/learn') >= 0) {
      return 'learn';
    } else if (url.indexOf('/listen') >= 0) {
      return 'listen';
    }
  }
  return 'watch';
};

const useUpdateBookmarks = (options?: {
  screenSource?: 'discover' | 'detail';
}): {
  isLoading: boolean;
  isBookmarked: (contentId: string) => boolean;
  removeBookMark: (parentId: string, contentId: string) => Promise<void>;
  findBookmarkItem: (contentId: string) => ISavedResponse | undefined;
  // updateBookMark: DebouncedFunc<
  //   (
  //     contentId: string,
  //     url: string,
  //     isAdd: boolean,
  //     parentId: string | undefined,
  //     content: IToggleBookmarkParams,
  //   ) => Promise<void>
  // >;
  updateBookMark: any;
} => {
  const tracker = useTracker();
  const {screenSource} = options || {};
  const [createMemberSaved, {isLoading: isCreation}] =
    useLazyCreateMemberSavedQuery();
  const [deleteMemberSavedByContentId, {isLoading: isDeleting}] =
    useLazyDeleteMemberSavedByContentIdQuery();

  const {groupedBookmarks = []} = useAppSelector(({member: memberState}) => ({
    groupedBookmarks: memberState.saved,
  }));
  const dispatch = useDispatch();
  const allBookmarks = useRef<{contentId: string; id: string}[]>([]);
  const abortRef = useRef<{abortRemove?: () => void; abortCreate?: () => void}>(
    {},
  );

  useEffect(() => {
    allBookmarks.current = groupedBookmarks.map(el => ({
      contentId: el.contentId,
      id: el._id,
    }));
  }, [groupedBookmarks]);

  const removeBookMark = async (contentId: string) => {
    // Abort previous request if it exists
    if (abortRef.current.abortRemove) {
      abortRef.current.abortRemove();
    }
    if (abortRef.current.abortCreate) {
      abortRef.current.abortCreate();
    }

    const {abort, unwrap} = deleteMemberSavedByContentId({
      contentId,
    });
    abortRef.current.abortRemove = abort;

    try {
      await unwrap();
      dispatch(memberSlice.actions.addBookmarkCount({contentId, add: -1}));
      dispatch(
        memberSlice.actions.removeSaved({
          contentId,
        }),
      );
    } catch (error) {
      console.error('Request aborted or failed', error);
    }
  };

  const addBookmark = async (args: {
    contentId: string;
    contentSource: MemberDataSource;
    content: IToggleBookmarkParams;
  }) => {
    const {contentId, contentSource, content} = args;
    // Abort previous request if it exists
    if (abortRef.current.abortCreate) {
      abortRef.current.abortCreate();
    }
    if (abortRef.current.abortRemove) {
      abortRef.current.abortRemove();
    }

    const {abort, unwrap} = createMemberSaved({
      data: {
        contentId,
        contentSource,
      },
    });
    abortRef.current.abortCreate = abort;

    try {
      const data = await unwrap();
      if (data) {
        if (screenSource === 'discover') {
          tracker.discover.discoverSlideLiked(content, true);
        } else {
          tracker.general.bookmark(content, true);
        }
        dispatch(memberSlice.actions.addBookmarkCount({contentId, add: 1}));
        dispatch(memberSlice.actions.addSaved(data as any));
      }
    } catch (error) {
      console.error('Request aborted or failed', error);
    }
  };

  const findBookmarkItem = (contentId: string) => {
    return (
      (!!contentId &&
        groupedBookmarks.find(el => el.contentId === contentId)) ||
      undefined
    );
  };

  const isBookmarked = (contentId: string): boolean => {
    return !!findBookmarkItem(contentId);
  };

  const updateBookMark = async (
    contentId: string,
    url: string,
    isAdd: boolean,
    content: IToggleBookmarkParams,
  ) => {
    const contentSource = getContentSource(url);
    if (!isAdd) {
      await removeBookMark(contentId);
      if (screenSource === 'discover') {
        tracker.discover.discoverSlideLiked(content, false);
      } else {
        tracker.general.bookmark(content, false);
      }
    } else {
      await addBookmark({
        contentId,
        contentSource,
        content,
      });
    }
  };

  return {
    isLoading: isDeleting || isCreation,
    isBookmarked,
    removeBookMark,
    findBookmarkItem,
    updateBookMark,
  };
};

export default useUpdateBookmarks;
