import React from 'react';
import {View, Pressable, StyleSheet, ScrollView} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import Typography from '../../../Typography/Typography';
import {CustomTabBarProps} from '../CustomTabBar/CustomTabBar';
import {CustomTabBarStyles} from '../CustomTabBar/CustomTabBar.styles';

const DefaultTabBar = (props: CustomTabBarProps) => {
  const {navigationState, style, jumpTo} = props;
  const horizontalGap = 16;
  const listGap = 14;
  const computedStyle = StyleSheet.flatten(style);
  return (
    <View
      style={[
        CustomTabBarStyles.root,
        style, 
        {
          position: 'relative',
          zIndex: 30,
          marginTop: -13,
          marginBottom:
            computedStyle && typeof computedStyle.marginBottom === 'number'
              ? computedStyle.marginBottom - Metrics.horizontalScale(13)
              : Metrics.horizontalScale(-13),
        },
      ]}>
      <ScrollView
        horizontal
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        {navigationState.routes.map((route, index) => {
          const isActive = index === navigationState.index;

          return (
            <Pressable
              key={route.key}
              style={StyleSheet.flatten({
                justifyContent: 'center',
                marginLeft:
                  index === 0
                    ? Metrics.horizontalScale(horizontalGap)
                    : Metrics.horizontalScale(listGap),
                marginRight:
                  navigationState.routes.length === index + 1
                    ? Metrics.horizontalScale(horizontalGap)
                    : undefined,
                paddingTop: Metrics.horizontalScale(13),
                paddingBottom: Metrics.horizontalScale(13),
              })}
              onPress={() => jumpTo(route.key)}>
              <Typography
                variant="body2"
                color={isActive ? 'primary' : 'white'}
                style={[CustomTabBarStyles.tabItemText]}>
                {route.title}
              </Typography>
            </Pressable>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default DefaultTabBar;
