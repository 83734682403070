import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Metrics from 'design-system/src/Theme/Metrics';
import MediaCover from 'design-system/src/Widget/MediaCover/MediaCover';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import queryString from 'query-string';
import {useGetCheckoutSessionQuery} from '@src/Api/TraceApi/traceApi';
import {useInAppPurchase} from '@src/Hooks/useInAppPurchase';
import {HomeContentLoader} from 'design-system/src/Components/ContentLoader';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import VariantPromoCodeSuccess from './VariantPromoCodeSuccess/VariantPromoCodeSuccess';

const SuccessPlanScreen = () => {
  const {sessionId} = queryString.parse(location.search);
  const {data, isLoading} = useGetCheckoutSessionQuery(sessionId as string, {
    skip: !sessionId,
  });

  const {t} = useTranslation(['subscription']);
  const navigation = useAppNavigation();

  const {loading: loadingSubscription, allSubscription} = useInAppPurchase();
  const loading = isLoading || loadingSubscription;
  const planId = data?.price.product.metadata.externalID;
  const paidPlan = allSubscription.find(sub => sub.planId === planId);
  const activeCoupon = data?.couponDetails?.[0];
  const couponDuration = activeCoupon?.coupon?.duration_in_months;

  if (loading) {
    return <HomeContentLoader />;
  }
  if (!paidPlan) {
    return <ContentEmpty title={t('Error')} />;
  }

  // is three month promo code
  if (activeCoupon && couponDuration === 3) {
    return <VariantPromoCodeSuccess />;
  }
  const {
    thankYouTitle: title,
    thankYouBody: body,
    thankYouCover: mediaCover,
  } = paidPlan ? paidPlan : allSubscription[0];

  return (
    <div>
      <MediaCover
        isActive={true}
        cover={mediaCover}
        absoluteChilren={() => (
          <ServiceHeader
            key={'header'}
            topSpace={Metrics.verticalScale(389)}
            title={title || ''}
            hideCover={true}
            image={{
              uri: undefined,
            }}
            description={body || ''}
            buttonTitle={t('retourn.home') as string}
            onPressButton={() => {
              navigation.navigate(AppRoutesEnum.HOME);
            }}
            buttonProps={{
              size: 'medium',
              variant: 'outlined',
            }}
          />
        )}
      />
    </div>
  );
};

export default SuccessPlanScreen;
