import React from 'react';
import {View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import {CustomHeaderProps} from '../Header/CustomHeader/CustomHeader.props';
import CustomHeader from '../Header/CustomHeader/CustomHeader.web';
import Typography from '../Typography/Typography.web';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

interface IContactBlockProps {
  title: string;
  description?: string;
  buttonTitle?: string;
  image?: CustomHeaderProps['image'];
  imageMobile?: CustomHeaderProps['image'];
  onClick?: () => void;
  hideButton?: boolean;
  buttonVariant?: string;
}

const ContactBlock = (props: IContactBlockProps) => {
  const {
    description,
    title,
    image: imageDesktop,
    imageMobile,
    buttonTitle,
    onClick,
    hideButton,
    buttonVariant = 'contained',
  } = props;
  const {isMobile} = useBreakpoint();
  const aspectRatio = isMobile ? 3 / 4 : 21 / 9;
  const image = isMobile
    ? imageMobile || imageDesktop
    : imageDesktop || imageMobile;
  return (
    <View
      style={{
        aspectRatio,
      }}>
      <CustomHeader
        hideButtonIcon={true}
        contentBottom={48}
        aspectRatio={aspectRatio}
        image={image}
        variant="contact"
        title={title}
        buttonTitle={buttonTitle || 'contact us'}
        buttonProps={{
          variant: buttonVariant as any,
          size: 'small',
        }}
        hideButton={hideButton}
        onPressButton={onClick}>
        {description && (
          <Typography
            variant="body3"
            style={{
              textAlign: 'center',
            }}>
            {description}
          </Typography>
        )}
      </CustomHeader>
    </View>
  );
};

export default ContactBlock;
