import useDefaultTheme from 'design-system/src/Theme/useTheme';
import {graphql, PageProps} from 'gatsby';
import React, {useState} from 'react';
import {navigate} from 'gatsby';
import {View, StyleSheet} from 'react-native';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import UmbracoForm from '@src/Umbraco/Components/UmbracoForm/UmbracoForm';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const ZendeskFormTemplate = (props: PageProps) => {
  const helpdeskFolder = (props.data as any).umbraco.helpdeskFolder;

  const {t} = useTranslation('profile');

  const {zendeskForm} = helpdeskFolder;

  const [thanks, setThanks] = useState<string>();

  const theme = useDefaultTheme();

  const handleSuccess = () => {
    if (zendeskForm && zendeskForm.messageOnSubmit) {
      setThanks(zendeskForm.messageOnSubmit);
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <View style={{flex: 1, paddingHorizontal: '60px', boxSizing: 'border-box', paddingTop: '90px'}}>
        {zendeskForm ? (
          <UmbracoForm
            form={zendeskForm}
            additionnalFormData={{
              sourceQuestion: 'zendesk form',
            }}
            onSuccess={handleSuccess}
            contentContainerStyle={{flex: 1}}
          />
        ) : (
          <ContentEmpty title={t('Form not found')} />
        )}

        {thanks && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {backgroundColor: theme.page.background},
            ]}>
            <ContentEmpty
              title={thanks}
              buttonTitle={t('Go back') || ''}
              onPress={() => navigate(-1)}
            />
          </View>
        )}
      </View>
    </div>
  );
};

ZendeskFormTemplate.isModal = true;

export default ZendeskFormTemplate;

export const query = graphql`
  query ($language: String!, $id: ID!, $lang: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    umbraco {
      helpdeskFolder(id: $id, culture: $lang) {
        zendeskForm
        url
        id
      }
    }
  }
`;
