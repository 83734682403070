import React, {ReactNode} from 'react';
import {View} from 'react-native';
import {IconName} from '../../../assets/Icones';
import defaultTheme from '../../../Theme/defaultTheme';
import Icon from '../../Icon/Icon';
import {cardIconStyles} from './CardIcon.styles';

export interface CardIconProps {
  icon: IconName | (() => ReactNode);
}

const CardIcon = (props: CardIconProps) => {
  const {icon} = props;

  return (
    <View style={cardIconStyles.container}>
      {typeof icon === 'function' ? (
        icon()
      ) : (
        <Icon name={icon} color={defaultTheme.primary} />
      )}
    </View>
  );
};

export default CardIcon;
