import usePressableHover from '@src/Hooks/usePressableHover';
import React from 'react';
import {Pressable, PressableProps} from 'react-native';

export interface PressableStateCallbackType {
  readonly hover: boolean;
}

interface IPressableCustomProps
  extends Omit<PressableProps, 'children' | 'onHoverIn' | 'onHoverOut'> {
  children?:
    | React.ReactNode
    | ((state: PressableStateCallbackType) => React.ReactNode);
}

const PressableCustom = (props: IPressableCustomProps) => {
  const {children, ...restProps} = props;
  const {hover, onHoverIn, onHoverOut} = usePressableHover();
  return (
    <Pressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} {...restProps}>
      {typeof children === 'function' ? children({hover}) : children}
    </Pressable>
  );
};

export default PressableCustom;
