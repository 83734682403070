import useSignUpForm from '@src/Api/Umbraco/Hooks/useSignupForm';
import React, {FC, useMemo} from 'react';
import UmbracoForm from '~Umbraco/Components/UmbracoForm/UmbracoForm';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {FieldValues} from 'react-hook-form';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {TextInputLoader} from 'design-system/src/Components/ContentLoader';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useSignUpSubmit from '../useSignUpSubmit';

const SignUpForm: FC = () => {
  const authState = useAppSelector(state => state.appAuth);
  const {isMobile} = useBreakpoint();

  const initialValue: FieldValues = authState.formData;

  const {form, additionnalStep, loading} = useSignUpForm();

  const {t} = useTranslation('auth');

  const dispatch = useAppDispatch();
  const {submit} = useSignUpSubmit();

  const formattedFrom = useMemo(() => {
    if (!form) return null;

    const socialSource = authState.socialNetworkSource;

    const pages = form.pages.map(page => {
      const fieldsets = page.fieldsets.map(fieldset => {
        const columns = fieldset.columns.map(column => {
          const fields = column.fields.filter(field => {
            if (
              socialSource != 'email' &&
              (field.alias == 'email' || field.alias == 'password')
            ) {
              return false;
            }
            return true;
          });

          return {
            ...column,
            fields,
          };
        });

        return {
          ...fieldset,
          columns,
        };
      });

      return {
        ...page,
        fieldsets,
      };
    });

    return {
      ...form,
      pages,
    };
  }, [form]);

  if (loading) {
    return (
      <div
        style={{
          padding: '0 16px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: 418,
          gap: 8,
        }}>
        {Array.from({length: 8}).map((_, index) => (
          <TextInputLoader key={index} />
        ))}
      </div>
    );
  }

  if (!formattedFrom) return null;

  const handleSignUp = async (formData: any): Promise<boolean> => {
    dispatch(appAuthSlice.actions.setFormData(formData));

    if (additionnalStep && additionnalStep.length > 0) {
      dispatch(appAuthSlice.actions.setAuthStep({page: 'interest', step: 0}));
      return true;
    }
    return submit(formData);
  };

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 418,
        padding: isMobile ? '0 16px' : undefined,
      }}>
      <UmbracoForm
        form={formattedFrom}
        submitLabel={t('Register') + ''}
        autoValidate={false}
        initialValue={initialValue}
        overrideSubmit={handleSignUp}
        renderPageHeader={() => (
          <Typography
            variant={isMobile ? 'body2' : 'formTitle'}
            style={{
              marginBottom: Metrics.verticalScale(12),
              textAlign: 'center',
            }}>
            {t('Sign up')}
          </Typography>
        )}
      />
    </div>
  );
};

export default SignUpForm;
