import React from 'react';
import {
  FieldValues,
  UseControllerProps,
  PathValue,
  Control,
  Path,
  useController,
} from 'react-hook-form';
import ServiceFormChannel, {
  ServiceFormChannelProps,
} from './ServiceFormChannel';

export interface ServiceFormChannelControllerProps<
  TField extends FieldValues = FieldValues,
> extends Omit<ServiceFormChannelProps, 'value' | 'onChange'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
  valueMapper?: Record<string, string>;
}
const ServiceFormChannelController = <TField extends FieldValues = FieldValues>(
  props: ServiceFormChannelControllerProps<TField>,
) => {
  const {control, defaultValue, name, rules, valueMapper = {}, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  const {field: fieldSecondValue} = useController<TField>({
    control,
    defaultValue,
    name: (name + 'Value') as Path<TField>,
  });
  const handleChange: ServiceFormChannelProps['onChange'] = value => {
    field.onChange(value);
    fieldSecondValue.onChange(value.map(v => valueMapper[v] || v));
  };
  return (
    <ServiceFormChannel value={field.value} onChange={handleChange} {...rest} />
  );
};

export default ServiceFormChannelController;
