import {StyleSheet} from 'react-native';
import Metrics from '../../Theme/Metrics';

export const iconWithTextStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: Metrics.horizontalScale(6),
  },
});
