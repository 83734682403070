import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import React from 'react';
import AppDynamicHomePage from '../../Common/AppDynamicHomePage/AppDynamicHomePage';
import {View} from 'react-native';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import HomeContentLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/HomeContentLoader';

const ArtistHomePage = () => {
  const {page, loading} = useRegionUrl();
  const {t} = useTranslation('translation');

  if (loading) {
    return (
      <div>
        <View style={{flex: 1}}>
          <HomeContentLoader />
        </View>
      </div>
    );
  }

  if (page.artist) {
    const contentId: string = page.artist.id;
    const contentType = page.artist.__typename;
    return (
      <AppDynamicHomePage
        id={contentId}
        __typename={contentType}
        contentSource="watch"
      />
    );
  }
  return (
    <div>
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ContentEmpty title={t('Not found')} />
      </View>
    </div>
  );
};

export default ArtistHomePage;
