import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {FC, useState} from 'react';
import ThankYouPage from '@src/Umbraco/Pages/ThankYouPage/ThankYouPage';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {RouteComponentProps} from '@reach/router';

const ComingSoon: FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({id}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {isMobile} = useBreakpoint();
  const isNotified = false;
  const {t} = useTranslation('learn');
  const handlePress = () => {};
  if (!id) {
    return null;
  }
  return (
    <div>
      <ThankYouPage
        id={id}
        loading={isLoading}
        fullScreen={true}
        buttonFallBack={{
          disabled: isNotified || isLoading,
          title: !isNotified ? t('Notify me') + '' : t('Notified') + '',
          onPress: handlePress,
        }}
        contentWidth={isMobile ? 487 : 840}
      />
    </div>
  );
};

export default ComingSoon;
