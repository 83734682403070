import React, {useEffect} from 'react';
import useBraze from './useBraze';

const BrazeProvider = (props: {children?: React.ReactNode}) => {
  const {children} = props;
  const {initBraze} = useBraze();
  useEffect(() => {
    initBraze();
  }, []);
  return <>{children}</>;
};

export default React.memo(BrazeProvider);
