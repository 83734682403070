import {View} from 'react-native';
import React, {ReactNode, useEffect, useMemo} from 'react';
import {
  IElementFragment_ContentSlider_Fragment,
  useVodItemRelatedContentLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import CategorySlider, {
  ICategorySliderItem,
} from '~Umbraco/Components/CategorySlider/CategorySlider';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
import Metrics from 'design-system/src/Theme/Metrics';
import {mapCategoryChildrenItems} from '~Umbraco/Utils/content/mapCategoryChildrenItems';
import {mapContentItems} from '~Umbraco/Utils/mapContentItems';
import {useFindProgression} from '~Hooks/useFindProgression';
import {useLiveEventProgression} from '~Hooks/useLiveEventProgression';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import CarouselLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/CarouselLoader';
import usePaywal from '~Hooks/usePaywal';
import ContentSlider, {
  IContentSliderItem,
} from '~Umbraco/Components/ContentSlider/ContentSlider';

interface IRelatedCategoryProps {
  id: string;
  first?: number;
}

const RelatedCategory = (props: IRelatedCategoryProps) => {
  const {id, first = 10} = props;
  const {t} = useTranslation('translation');
  const [fetchRelatedContent, {data: relatedContent, loading}] =
    useVodItemRelatedContentLazyQuery();
  const {findProgression} = useFindProgression();
  const {calculateProgression} = useLiveEventProgression();
  const {canSeeContent} = usePaywal();

  useEffect(() => {
    if (id) {
      fetchRelatedContent({
        variables: {
          id: id,
          first: first,
        },
      });
    }
  }, [fetchRelatedContent, first, id]);
  const {
    handleCategorySliderItemClick,
    handleSeeMoreCategorySlider,
    handleContentSliderItemClick,
  } = useSectionPageUtils();
  const list = useMemo(() => {
    const _list: ReactNode[] = [];
    if (
      relatedContent &&
      relatedContent.vODItem &&
      relatedContent.vODItem.parent
    ) {
      if (relatedContent.vODItem.parent.__typename === 'WatchCateg') {
        const items: ICategorySliderItem[] = mapCategoryChildrenItems(
          relatedContent.vODItem.parent.children,
          findProgression,
          canSeeContent,
          calculateProgression,
        );

        const category = relatedContent.vODItem.parent;

        _list.push(
          <CategorySlider
            key={'related-content'}
            data={items.filter(el => el.id !== id)}
            title={t('Related content')}
            isLink={true}
            size={'small'}
            onItemPress={handleCategorySliderItemClick}
            onSeeMore={handleSeeMoreCategorySlider({
              url: category.url || '',
              id: category.id || '',
              __typename: category.__typename,
              name: category.name || '',
            })}
          />,
        );
      } else if (relatedContent.vODItem.parent.__typename === 'Collection') {
        const items: IContentSliderItem[] = mapContentItems(
          relatedContent.vODItem.parent.children
            ?.items as IElementFragment_ContentSlider_Fragment['contentItems'],
          findProgression,
          canSeeContent,
          calculateProgression,
          {t},
        );

        const category = relatedContent.vODItem.parent;
        _list.push(
          <ContentSlider
            key={'related-content'}
            title={t('Related content')}
            isLink={true}
            size={'small'}
            data={items.filter(el => el.id !== id)}
            onItemPress={item => handleContentSliderItemClick(item)}
            onSeeMore={handleSeeMoreCategorySlider({
              url: category.url || '',
              id: category.id || '',
              __typename: category.__typename,
            })}
          />,
        );
      }
    }

    return _list;
  }, [
    relatedContent,
    findProgression,
    calculateProgression,
    t,
    handleCategorySliderItemClick,
    handleSeeMoreCategorySlider,
    id,
    canSeeContent,
  ]);

  return (
    <View
      style={{
        marginBottom: Metrics.horizontalScale(24),
      }}>
      {loading ? <CarouselLoader /> : list}
    </View>
  );
};

export default RelatedCategory;
