import React from 'react';
import {Text, Pressable, View} from 'react-native';
import { TextStyles } from '../../../styles/TextStyles';
import {darkTheme} from '../../../styles/Themes';
import IconWithText from '../../IconWithText/IconWithText';

import {roundedButtonStyles} from './RoundedButton.styles';

export type RoundedButtonProps = {
  onClick: () => void;
  backgroundColor?: string;
  borderColor?: string;
  text: string;
  textColor?: string;
  Icon?: React.FC;
  paddingHorizontal: number;
  paddingVertical: number;
  borderRadius: number;
};

export const RoundedButton: React.FC<RoundedButtonProps> = ({
  onClick,
  backgroundColor,
  borderColor,
  textColor,
  text,
  Icon,
  paddingHorizontal,
  paddingVertical,
  borderRadius,
}: RoundedButtonProps) => {
  return (
    <View style={roundedButtonStyles.buttonContainer}>
      <Pressable
        onPress={onClick}
        style={{
          ...roundedButtonStyles.container,
          backgroundColor,
          paddingHorizontal,
          paddingVertical,
          borderRadius,
          borderColor,
        }}>
        {!Icon ? (
          <Text
            style={{
              ...TextStyles.buttonBold,
              color: textColor ?? darkTheme.colors.white,
            }}>
            {text}
          </Text>
        ) : (
          <IconWithText
            text={text}
            textColor={textColor ?? darkTheme.colors.white}
            Icon={Icon}
            textStyle={TextStyles.buttonBold}
          />
        )}
      </Pressable>
    </View>
  );
};

export default RoundedButton;
