import React from 'react';
import Typography from '../../Typography/Typography';

const PostTypeText: React.FC<{text?: string; textColor?: string}> = props => {
  return (
    <Typography
      children={props.text}
      color={() => '#F0F0F0'}
      style={{opacity: 0.5}}
      variant='body2'
    />
  );
};

export default PostTypeText;
