import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import {useCallback} from 'react';
import {IOpportunityResponse} from '~Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import {
  useLazyDeleteMemberOpportunityQuery,
  useLazyUpdateMemberOpportunityQuery,
} from '~Api/TraceApi/traceApi';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import memberSlice from '~Store/Slices/Member.slice';

export const useUpdateOpportunity: () => {
  handleAcceptOpportunity: (
    contentId: string,
    accept: boolean,
    partnerName: string,
  ) => Promise<void>;
  findJob: (contentId: string) => boolean;
  handleDeleteOpportunity: (id: string) => Promise<void>;
  createLoading: boolean;
  deleteLoading: boolean;
  findOpportunity: (contentId: string) => IOpportunityResponse | undefined;
} = () => {
  const [updateOpportunity, {isLoading: createLoading}] =
    useLazyUpdateMemberOpportunityQuery();

  const [deleteOpportunity, {isLoading: deleteLoading}] =
    useLazyDeleteMemberOpportunityQuery();

  const {opportunities = [], jobs = []} = useAppSelector(
    ({member: memberState}) => ({
      opportunities: memberState.opportunities,
      jobs: memberState.obtainedJob,
    }),
  );

  const tracker = useTracker();
  const dispatch = useAppDispatch();

  const findOpportunity = useCallback(
    (contentId: string) => {
      return opportunities.find(_item => _item.contentId === contentId);
    },
    [opportunities],
  );
  const findJob = useCallback(
    (jobId: string) => {
      return (
        (jobs || []).find(
          _item => _item.certificateId === jobId && _item.finished,
        ) !== undefined
      );
    },
    [jobs],
  );
  const handleAcceptOpportunity = useCallback(
    async (contentId: string, accept: boolean, partnerName: string) => {
      const {data} = await updateOpportunity({
        data: {
          contentId,
          accept,
        },
      });
      if (data) {
        let property = {
          opportunityId: contentId,
          partnerName,
        };
        if (accept) {
          tracker.learn.acceptOpportunity(property);
        } else {
          tracker.learn.declineOpportunity(property);
        }
        dispatch(memberSlice.actions.addOpportunity(data));
      }
    },
    [dispatch],
  );

  const handleDeleteOpportunity = useCallback(
    async (id: string) => {
      await deleteOpportunity({id});
      dispatch(memberSlice.actions.removeOpportunity(id));
    },
    [dispatch],
  );

  return {
    handleAcceptOpportunity,
    findJob,
    findOpportunity,
    handleDeleteOpportunity,
    createLoading,
    deleteLoading,
  };
};
