import Typography from 'design-system/src/Components/Typography/Typography';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {darkTheme} from 'design-system/src/styles/Themes';
import React, {FC} from 'react';

export interface IFooterItemProps {
  title?: string;
  list?: {
    title: string;
    onClick?: () => void;
  }[];
}

const FooterItem: FC<IFooterItemProps> = props => {
  const {title, list} = props;
  const {isMobile} = useBreakpoint();

  return (
    <div style={{marginTop: isMobile ? 32 : undefined}}>
      <Typography variant="h4" style={{textTransform: 'uppercase'}}>
        {title}
      </Typography>
      <ul style={{padding: 0, listStyle: 'none', margin: '14px 0 0 0'}}>
        {list?.map((item, index) => {
          if (item.title)
            return (
              <li
                key={index}
                onClick={item.onClick}
                style={{padding: '6px 0', cursor: 'pointer'}}>
                <Typography
                  variant="body3"
                  style={{color: darkTheme.colors.fontGray}}>
                  {item.title}
                </Typography>
              </li>
            );
        })}
      </ul>
    </div>
  );
};

export default FooterItem;
