import {useEffect} from 'react';
import {
  ICourseUnitFragmentFragment,
  useCourseUnitByEndUrlLazyQuery,
} from '~Api/Graphql/gql/types.generated';

interface UseFetchCourseUnitProps {
  endUrl: string;
}

interface UseFetchCourseUnitResult {
  data?: ICourseUnitFragmentFragment;
  courseUnitUrls?: string[];
  courseId?: string;
  courseUrl?: string;
  unitId?: string;
  loading: boolean;
}

const useFetchCourseUnit = ({
  endUrl,
}: UseFetchCourseUnitProps): UseFetchCourseUnitResult => {
  const [fetchCourseUnit, {data, loading}] = useCourseUnitByEndUrlLazyQuery();

  useEffect(() => {
    if (endUrl) {
      fetchCourseUnit({variables: {endUrl}});
    }
  }, [endUrl]);
  const courseUnit = data?.allCourseUnit?.items[0];
  const courseId =
    courseUnit?.parent?.__typename === 'Course' ? courseUnit?.parent?.id : '';
  const courseUrl =
    courseUnit?.parent?.__typename === 'Course' ? courseUnit?.parent?.url : '';
  const courseUnitUrls: string[] = [];

  if (courseUnit?.parent?.__typename === 'Course') {
    courseUnit?.parent?.children?.items?.forEach(child => {
      if (child?.__typename === 'CourseUnit') {
        courseUnitUrls.push(child?.url || '');
      }
    });
  }

  return {
    data: courseUnit,
    unitId: courseUnit?.id,
    courseUnitUrls,
    courseId,
    loading,
    courseUrl,
  };
};

export default useFetchCourseUnit;
