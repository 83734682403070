import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import Typography from '../../Typography/Typography';
import {EFontFamily} from '../../Typography/Typography.styles';
import {leadingCoverSize, leadingTopCardStyles} from './LeadingTopCard.styles';

export interface LeadingTopCardProps {
  number: string;
  source: CustomFastImageProps['source'];
  size?: keyof typeof leadingCoverSize;
  dist?: number;
  variant?: 'default' | 'top';
}

const LeadingTopCard = (props: LeadingTopCardProps) => {
  const {number, source, size = 'large', dist, variant = 'default'} = props;
  const sizeStyles = leadingCoverSize[size];
  return (
    <View
      style={StyleSheet.flatten([
        {height: sizeStyles.height},
        leadingTopCardStyles.card,
      ])}>
      <View
        style={{
          width: Metrics.horizontalScale(30),
          height: '100%',
          justifyContent: 'flex-end',
        }}>
        {variant === 'default' ? (
          <Typography
            children={number}
            style={{
              fontSize: sizeStyles.fontSize,
              // TODO: find a better way to fix text vertical align bottom
              marginBottom: Metrics.verticalScale(-14),
            }}
          />
        ) : (
          <Text
            style={{
              fontSize: sizeStyles.fontSize,
              color: 'white',
              marginBottom: Metrics.verticalScale(-14),
              fontFamily: EFontFamily.CONDENSED,
              fontStyle: 'normal',
              fontWeight: '600',
              letterSpacing: Metrics.fontScale(-0.006),
              textTransform: 'uppercase',
            }}>
            {number}
          </Text>
        )}
      </View>
      <CustomFastImage
        source={source}
        wrapperStyles={{marginLeft: dist}}
        width={sizeStyles.width}
        height={sizeStyles.height}
        style={StyleSheet.flatten([sizeStyles, leadingTopCardStyles.cover])}
        backgroundFallbackColor="black"
      />
    </View>
  );
};

export default LeadingTopCard;
