import React from 'react';
import {View} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import Constants from '../../../tools/Constant';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import IconWithText from '../../IconWithText/IconWithText';
import Typography from '../../Typography/Typography';
import CardAside from '../CardAside/CardAside';
import GenericCard from '../GenericCard/GenericCard';

export type NewsCadProps = {
  cover?: CustomFastImageProps['source'];
  title: string;
  date: string;
  autor: string;
  type?: 'medium' | 'small' | 'xsmall';
  onPress?(): void;
};

const NewsCard = (props: NewsCadProps) => {
  const {title, date, autor, cover, onPress} = props;
  return (
    <View
      style={{
        width: Metrics.horizontalScale(Constants.SMALL_WIDTH),
      }}>
      <View style={{marginBottom: Metrics.verticalScale(11)}}>
        <GenericCard
          onPress={onPress}
          coverImage={cover}
          width={Constants.SMALL_WIDTH}
          height={Constants.NEWS_HEIGHT}
        />
      </View>
      <CardAside title={title.toUpperCase()}>
        <Typography variant="ui3" color="gray">
          {autor}
        </Typography>
        <IconWithText text={date} icon="date" textColor={defaultTheme.gray} />
      </CardAside>
    </View>
  );
};

export default NewsCard;
