import React from 'react';
import {Pressable} from 'react-native';
import {ListenCardProps} from '../ListenCardProps';
import IconRoundPlaySvg from '../../../../assets/svg/ic_round_play.svg';
import IcDownloadSvg from '../../../../assets/svg/light-down.svg';
import ListenCardAside from '../ListenCardAside/ListenCardAside';
import CustomProgressBar from '../../../CustomProgressBar/CustomProgressBar';
import GenericCard from '../../GenericCard/GenericCard';
import RowCard from '../../RowCard/RowCard';
import ShareLeadingCard from '../../ShareLeadingCard/ShareLeadingCard';
import Metrics from '../../../../Theme/Metrics';

const SingleListenCard: React.FC<ListenCardProps> = props => {
  return (
    <RowCard
      left={
        <ShareLeadingCard
          Card={
            <GenericCard
              padding={Metrics.horizontalScale(5)}
              coverImage={props.cover}
              contentStyle={{
                paddingBottom: Metrics.verticalScale(4),
              }}
              type={'xsmall'}
              footer={<CustomProgressBar progress={props.progress} />}
              center={
                <Pressable onPress={props.onPressPlayIcon}>
                  <IconRoundPlaySvg />
                </Pressable>
              }
            />
          }
          onClickShareIc={props.onPressShareIcon ?? function (): void {}}
          onClickFavIc={props.onPressFavIcon ?? function (): void {}}
        />
      }
      right={<ListenCardAside asideProps={props} TrailingIc={IcDownloadSvg} />}
    />
  );
};

export default SingleListenCard;
