import React from 'react';
import {Platform, View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import ServiceTitle from '../ServiceTitle/ServiceTitle';
import Statistic, {StatisticProps} from '../Statistic/Statistic';

export type StatisticInfoProps = {
  title: string;
  description: string;
};

const data: StatisticProps[] = [
  {
    value: 28,
    label: 'Tv channels',
  },
  {
    value: 100,
    label: 'FM & Web Radio Stations',
  },
  {
    value: 180,
    label: 'Countries',
  },
  {
    value: 300,
    label: 'Million TV Viewers',
  },
  {
    value: 20,
    label: 'Million social media fans',
  },
  {
    value: '#1',
    label: 'Market share in Africa, the Caribbean and the Indian Ocean',
  },
];

export default function StatisticInfo(props: StatisticInfoProps) {
  const {title, description} = props;
  return (
    <View
      style={{
        marginTop: Platform.OS === "web" ? 0 : 52,
        paddingLeft: 24,
        paddingRight: 24,
        marginBottom: Platform.OS === "web" ? 0 : 44,
      }}>
      <ServiceTitle title={title} description={description} />
    </View>
  );
}
