import Typography from 'design-system/src/Components/Typography/Typography';
import React from 'react';
import GradiantText from '../../GradiantText/GradiantText';
import {
  _AwardCategoryBorderIcon,
  _AwardCategoryWrapper,
} from './AwardCategoryBand.styled';

interface IAwardCategoryBandProps {
  name: string;
  position?: 'left' | 'right';
}

const AwardCategoryBand = (props: IAwardCategoryBandProps) => {
  const {name, position = 'left'} = props;
  const verticalSpace = 21;
  const bandHeight = 24;
  return (
    <_AwardCategoryWrapper
      style={{
        position: 'fixed',
        right:
          position === 'left'
            ? `calc(100% - ${verticalSpace}px)`
            : verticalSpace + bandHeight,
        height: bandHeight,
        
      }}>
      <GradiantText variant="h4">{name}</GradiantText>
      <_AwardCategoryBorderIcon $variant="border" />
      <Typography variant="h4">{name}</Typography>
      <_AwardCategoryBorderIcon $variant="triangle" />
      <GradiantText variant="h4">{name}</GradiantText>
      <_AwardCategoryBorderIcon $variant="square" />
      <Typography variant="h4">{name}</Typography>
      <_AwardCategoryBorderIcon $variant="circle" />
    </_AwardCategoryWrapper>
  );
};

export default AwardCategoryBand;
