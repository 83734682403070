import React, {ReactNode, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {IconName} from '../../assets/Icones';
import Metrics from '../../Theme/Metrics';
import Icon from '../Icon/Icon';
import defaultTheme from '../../Theme/defaultTheme';
import Typography from '../Typography/Typography';
import {ITypographyProps} from '../Typography/Typography.props';
import PressableCustom from '../PressableCustom/PressableCustom';

export type ActionGroupItem = {
  icon:
    | IconName
    | ReactNode
    | ((data: {
        onHoverChange?: (hover: boolean) => void;
        onValueChange?(value: boolean): void;
      }) => ReactNode);
  title?: string;
  label: string;
  isLoading?: boolean;
  isActive?: boolean;
  labelColor?: ITypographyProps['color'];
  onPress?(): void;
};

export type ActionGroupProps = {
  data: ActionGroupItem[];
  withBorder?: boolean;
};

const ItemPressable = (props: {
  data: ActionGroupItem;
  index: number;
  withBorder?: boolean;
}) => {
  const {data: d, index, withBorder} = props;
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  const handleHoverChange = (v: boolean) => {
    setHover(v);
  };
  const handleActiveChange = (v: boolean) => {
    setActive(v);
  };
  return (
    <PressableCustom
      onPress={d.onPress}
      style={StyleSheet.flatten([
        {
          marginLeft:
            index > 0 && !withBorder ? Metrics.horizontalScale(40) : 0,
          width: withBorder ? Metrics.horizontalScale(140) : undefined,
          alignItems: 'center',
        },
        index !== 0 && withBorder ? styles.borderStyle : undefined,
      ])}>
      {({hover: pressableHover}) => {
        const hasHover = pressableHover || hover;
        const isActive = active || d.isActive;
        return (
          <>
            {typeof d.icon === 'string' ? (
              <Icon
                name={d.icon as IconName}
                color={isActive || hasHover ? defaultTheme.primary : 'white'}
              />
            ) : typeof d.icon === 'function' ? (
              d.icon({
                onHoverChange: handleHoverChange,
                onValueChange: handleActiveChange,
              })
            ) : (
              d.icon
            )}
            {d.title && (
              <Typography
                variant="ui3"
                color={isActive || hasHover ? 'primary' : 'gray'}
                style={{
                  marginTop: Metrics.verticalScale(8),
                }}>
                {d.title}
              </Typography>
            )}
            <Typography
              variant="ui3"
              color={hasHover || isActive ? 'primary' : d.labelColor ?? 'gray'}
              style={{
                marginTop: !d.title
                  ? Metrics.verticalScale(8)
                  : Metrics.verticalScale(2),
                textAlign: 'center',
              }}>
              {d.label}
            </Typography>
          </>
        );
      }}
    </PressableCustom>
  );
};

export default function ActionGroup(props: ActionGroupProps) {
  const {data, withBorder} = props;

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
      {data.map((d, index) => (
        <ItemPressable
          key={`ag-${index}`}
          data={d}
          index={index}
          withBorder={withBorder}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  borderStyle: {
    borderLeftWidth: 1,
    borderLeftColor: '#2F3139',
    paddingHorizontal: Metrics.horizontalScale(14),
  },
});
