import { StyleSheet } from "react-native";
import Metrics, { isWeb } from "../../Theme/Metrics";

export const infoListTileStyles = StyleSheet.create({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: Metrics.horizontalScale(16)
    },
    body: {
        flex: 1,
        marginLeft: Metrics.horizontalScale(22)
    },
    title: {
        marginBottom: Metrics.verticalScale(7)
    }
})