import React, {useEffect} from 'react';
import {View} from 'react-native';
import CategoryPage from '~Umbraco/Pages/CategoryPage/CategoryPage';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import useParams from '@src/Hooks/useParams';

const QuizzSurveyCategoryScreen = () => {
  const params = useParams();
  const {id} = params || {};
  const tracker = useTracker();

  useEffect(() => {
    tracker.general.contentCategoryViewed({
      __typename: 'QuizzSurveyCategory',
      source: params?.source || 'browse',
    });
  }, []);
  return (
    <View style={{flex: 1}}>
      <CategoryPage type="QuizzSurveyCategory" activeId={id} />
    </View>
  );
};

export default QuizzSurveyCategoryScreen;
