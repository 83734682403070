import React, {useMemo, useCallback, Suspense} from 'react';
import {WindowLocation, useLocation} from '@reach/router';
import {PageProps, graphql} from 'gatsby';
import AppRouter from '@src/App/Route/AppRouter/AppRouter';
import CustomDrawer from '@src/layout/AppLayout/AppDrawer/CustomDrawer';
import {PageContextI18n} from '@src/components/general/WrapPageElement/PageContext';
import PageModal from '@src/components/general/PageModal/PageModal';
import {isModalRoute} from '@src/App/Route/Utils/isModalRoute';
import {isDrawerRoute} from '@src/App/Route/Utils/isDrawerRoute';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import CastProvider from 'design-system/src/Widget/Player/VideoPlayer/Factory/Player/context/CastProvider';
// import {CastProvider} from 'react-cast-sender';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {AppRouterProvider} from '@src/App/Route/AppRouterContext/AppRouterContext';
import {useSubMenuLeft} from '@src/layout/AppLayout/AppHeader/AppSubMenuLeft';

const ProfileMyProfileFieldEditDrawer = React.lazy(
  () =>
    import(
      '@src/Navigation/Profile/ProfileMyProfileFieldEdit/ProfileMyProfileFieldEditDrawer'
    ),
);
const ThankYouModal = React.lazy(
  () => import('@src/App/components/Common/ThankYouModal/ThankYouModal'),
);

const getOldestLocation = (
  oldLocation?: WindowLocation<any>,
): {
  oldLocation: WindowLocation<any> | undefined;
  depth: number;
} => {
  let mainLocation = oldLocation;
  let depth = 0;
  while (mainLocation && mainLocation.state && mainLocation.state.oldLocation) {
    mainLocation = mainLocation.state.oldLocation;
    depth++;
  }
  return {oldLocation: mainLocation, depth};
};

const normalizePath = (basePath: string, pathname: string) => {
  if (pathname.startsWith(basePath)) {
    return pathname;
  }
  const basepathArr = basePath.split('/');
  const pathnameArr = pathname.split('/');

  const normalizedUrl = pathnameArr
    .map((path, index) => {
      if (basepathArr[index] !== path) {
        return basepathArr[index];
      }
      return path;
    })
    .join('/');

  return normalizedUrl.endsWith('/') ? normalizedUrl : normalizedUrl + '/';
};

const normalizeLocation = (
  basePath: string,
  location: WindowLocation<unknown>,
): WindowLocation<unknown> => {
  const {pathname} = location;
  if (pathname.startsWith(basePath)) {
    return location;
  }

  const normalizedUrl = normalizePath(basePath, pathname);

  return {
    ...location,
    pathname: normalizedUrl,
    origin: window.location.origin,
    href: window.location.origin + normalizedUrl,
  };
};

const App = (props: PageProps<any, PageContextI18n>) => {
  const {pageContext} = props;
  const {isMobile} = useBreakpoint();
  // use location from reach router to fix rerender issue
  const location = useLocation();
  const {goBack, navigate} = useAppNavigation();
  const {isEmpty} = useSubMenuLeft();

  // modal and drawer checking
  const memoizedData = useMemo(() => {
    const {language, i18n} = pageContext;
    const {
      oldLocation: _oldLocation,
      type,
      canGoBack,
    } = (location.state as {
      oldLocation?: WindowLocation<unknown>;
      type?: 'drawer' | 'modal';
      canGoBack?: boolean;
    }) || {};

    const {oldLocation, depth} = getOldestLocation(_oldLocation);
    const pathLang =
      language === i18n?.defaultLanguage &&
      !i18n.path.startsWith(i18n.defaultLanguage)
        ? ''
        : `/${language}`;
    const basePath = `${pathLang}/app`;
    const currentPathname = location.pathname.replace(basePath, '');

    // check old location is modal or drawer
    const oldLocationPathname = oldLocation
      ? oldLocation.pathname.replace(basePath, '')
      : '';
    const isOldLocationModal = oldLocation
      ? isModalRoute(oldLocationPathname, isMobile)
      : false;
    const isOldLocationDrawer = oldLocation
      ? isDrawerRoute(oldLocationPathname)
      : false;

    return {
      oldLocation: oldLocation
        ? normalizeLocation(basePath, oldLocation)
        : undefined,
      basePath,
      isOldLocationModal,
      isOldLocationDrawer,
      isModal: type === 'modal' || isModalRoute(currentPathname, isMobile),
      isDrawer: type === 'drawer' || isDrawerRoute(currentPathname),
      canGoBack: canGoBack || !isEmpty,
      depth,
    };
  }, [isMobile, location, pageContext, isEmpty]);

  const {
    basePath,
    isModal,
    isDrawer,
    oldLocation,
    canGoBack,
    depth,
    isOldLocationModal,
    isOldLocationDrawer,
  } = memoizedData;

  const defaultLocation: WindowLocation<unknown> = useMemo(
    () =>
      ({
        pathname: basePath,
        search: '',
        hash: '',
        href: window.location.origin + basePath,
        origin: window.location.origin,
        protocol: window.location.protocol,
        host: window.location.host,
        hostname: window.location.hostname,
        port: window.location.port,
      }) as WindowLocation<unknown>,
    [basePath],
  );

  const background = useMemo(
    () =>
      isModal || isDrawer
        ? isOldLocationModal || isOldLocationDrawer
          ? defaultLocation
          : oldLocation || defaultLocation
        : undefined,
    [
      defaultLocation,
      isModal,
      isDrawer,
      oldLocation,
      isOldLocationModal,
      isOldLocationDrawer,
    ],
  );

  const onGoToBackGround = useCallback(() => {
    goBack(depth + 1);
  }, [goBack, depth]);

  const onDrawerClose = useCallback(() => {
    const {oldLocation: _oldLocation} =
      (location.state as {
        oldLocation?: WindowLocation<unknown>;
        type?: 'drawer' | 'modal';
      }) || {};
    if (background) {
      onGoToBackGround();
    } else {
      navigate(AppRoutesEnum.HOME);
    }
  }, [location, basePath, background, depth]);

  const drawerRender = (
    <AppRouter basepath={basePath} location={location} className="router" />
  );

  const modalRender = <AppRouter basepath={basePath} location={location} />;

  return (
    <AppRouterProvider
      isModal={isModal}
      isDrawer={isDrawer}
      location={location}
      backgroundLocation={background}>
      <div>
        <CastProvider receiverApplicationId="CC1AD845">
          {/* main router */}
          <AppRouterProvider location={background || location}>
            <AppRouter basepath={basePath} location={background || location} />
          </AppRouterProvider>

          {/* drawer router */}
          <CustomDrawer
            child={!!background && isDrawer && drawerRender}
            isOpen={isDrawer}
            onToggleDrawer={onDrawerClose}
            isWithArrowBack={depth > 0}
            onBack={() => goBack()}
          />

          {/* modal router */}
          <PageModal
            isOpen={isModal}
            canGoBack={canGoBack}
            onBack={() => goBack()}
            onClose={onDrawerClose}>
            {!!background && isModal && modalRender}
          </PageModal>

          <Suspense>
            <ProfileMyProfileFieldEditDrawer onClose={onDrawerClose} />
          </Suspense>
        </CastProvider>
      </div>
    </AppRouterProvider>
  );
};

App.layoutComponent = 'AppLayout';

export const query = graphql`
  query App($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default App;
