import React from 'react';
import {ActivityIndicator, View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import CardSetting from '../Card/CardSetting/CardSetting';
import Icon from '../Icon/Icon';
import Switch from '../Switch/Switch';
import Typography from '../Typography/Typography';

export type SettingContentProps = {
  title: string;
  loadingKey?: string;
  settings: Array<
    | {
        type: 'text';
        key: string;
        title: string;
        value?: string;
        disabled?: boolean;
        onPress?: (params: {key: string; title: string}) => void;
      }
    | {
        type: 'switch';
        key: string;
        title: string;
        value?: boolean;
        disabled?: boolean;
        onChange?: (value: boolean) => void;
      }
  >;
};

export default function SettingContent(props: SettingContentProps) {
  const {title, settings, loadingKey} = props;

  return (
    <View
      style={{
        marginBottom: Metrics.verticalScale(25),
      }}>
      <Typography
        variant="h3"
        style={{marginBottom: Metrics.verticalScale(13)}}>
        {title}
      </Typography>
      <View>
        {settings.map((s, index) => (
          <CardSetting
            title={s.title}
            key={`sc-${index}`}
            isFirst={index === 0}
            disabled={s.disabled}
            isPressable={typeof s.value !== 'string'}
            isLast={index === settings.length - 1}
            onPress={
              (s.type === 'text' &&
                (() => s.onPress && s.onPress({key: s.key, title: s.title}))) ||
              undefined
            }
            style={
              (s.type === 'switch' && {
                paddingVertical: Metrics.verticalScale(13),
                paddingTop: Metrics.verticalScale(13),
                paddingBottom: Metrics.verticalScale(13),
              }) ||
              undefined
            }>
            {s.type === 'text' ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingRight: Metrics.horizontalScale(12),
                }}>
                <Typography variant="body2" color="gray">
                  {s.value}
                </Typography>
                <Icon
                  name="arrow-right"
                  style={{marginLeft: Metrics.horizontalScale(10)}}
                  size="16"
                />
              </View>
            ) : s.type === 'switch' ? (
              <View style={{flexDirection: 'row'}}>
                {loadingKey === s.key && (
                  <ActivityIndicator
                    size={'small'}
                    style={{marginRight: Metrics.horizontalScale(5)}}
                  />
                )}
                <Switch
                  isOn={s.value || false}
                  disabled={loadingKey === s.key || s.disabled}
                  onToggle={s.onChange}
                />
              </View>
            ) : null}
          </CardSetting>
        ))}
      </View>
    </View>
  );
}
