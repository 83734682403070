import {AxiosRequestConfig, AxiosError} from 'axios';
import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import {traceAxiosInstance} from './trace.axios';
import {auth} from '@src/firebase';
import Token from './utils/token';

const getFirebaseTokenId = async (): Promise<string | undefined> => {
  try {
    const currentToken = localStorage.getItem('ft');
    let hasValideToken: boolean =
      !!currentToken && new Token(currentToken).isValid();

    if (hasValideToken && currentToken) {
      return currentToken;
    } else if (auth) {
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken(true);
        localStorage.setItem('ft', token);

        return token;
      }
    }
  } catch (error) {
    // throw error;
  }
  return '';
};

export const traceBaseQueryFn: BaseQueryFn<
  {
    url: string;
    baseUrl?: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    apiVersion?: string;
    contentType?: string;
    noAuth?: boolean;
  },
  unknown,
  unknown,
  {}
> = async ({
  url,
  method,
  body: data,
  params,
  apiVersion = '2',
  contentType,
  noAuth,
}, { signal }) => {
  try {
    const token = await getFirebaseTokenId();
    const result = await traceAxiosInstance({
      url,
      method,
      data,
      params,
      headers: {
        Authorization: noAuth ? undefined : 'Bearer ' + token,
      },
      signal,
    });

    return {
      data: result.data,
      error: undefined,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    
    if (err.name === 'CanceledError' || err.message === 'canceled') {
      return {
        error: { status: 'ABORT_ERROR', data: 'Request was aborted' },
      };
    }
    
    return {
      error: {status: err.response?.status, data: err.response?.data},
      data: undefined,
    };
  }
};
