import React from 'react';

import Typography from 'design-system/src/Components/Typography/Typography';
import {ITypographyProps} from 'design-system/src/Components/Typography/Typography.props';

interface IHighlightedTypographyProps
  extends Omit<ITypographyProps, 'children'> {
  highlightedText?: string;
  highlightedColor?: ITypographyProps['color'];
  highlightedStyle?: ITypographyProps['style'];
  children: string;
}

const escapeStringRegexp = (text: string) => {
  return text.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};

const HighlightedTypography = (props: IHighlightedTypographyProps) => {
  const {
    highlightedText,
    highlightedColor: highlightedColorDefault = 'orange',
    children,
    style,
    highlightedStyle,
    ...rest
  } = props;
  const highlightedColor = highlightedColorDefault;

  const parts = highlightedText
    ? children.split(
        new RegExp(`(${escapeStringRegexp(highlightedText)})`, 'ig'),
      )
    : [children];

  return (
    <Typography {...rest} style={style}>
      {parts.map((part, index) =>
        part.toLowerCase() === highlightedText?.toLowerCase() ? (
          <Typography
            key={index}
            {...rest}
            color={highlightedColor}
            style={highlightedStyle}>
            {part}
          </Typography>
        ) : (
          part
        ),
      )}
    </Typography>
  );
};

export default HighlightedTypography;
