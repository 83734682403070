import React from 'react';
import {StyleProp, View, ViewStyle, Pressable} from 'react-native';
import {darkTheme} from '../../../styles/Themes';
import Metrics, {height} from '../../../Theme/Metrics';
import {CardSize, selectSize} from '../../../tools/CardSizeTools';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import {genericCardStyles} from './GenericCard.styles';

export interface GenericCardProps {
  coverImage?: CustomFastImageProps['source'];
  width?: number;
  height?: number;
  fullWidth?: boolean;
  maxWidth?: ViewStyle['maxWidth'];
  aspectRatio?: number;
  customHeight?: number;
  leadingHeader?: React.ReactNode;
  trailingHeader?: React.ReactNode;
  center?: React.ReactNode;
  footer?: React.ReactNode;
  backgroundColor?: string;
  type?: CardSize;
  padding?: number;
  contentStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  onPress?(): void;
}

const GenericCard = (props: GenericCardProps) => {
  const {
    coverImage,
    padding,
    backgroundColor,
    leadingHeader,
    trailingHeader,
    footer,
    center,
    contentStyle,
    customHeight,
    fullWidth,
    aspectRatio,
    onPress,
    style = {},
  } = props;
  const size = React.useCallback(selectSize, [props])(props, false);
  size.height = customHeight ?? size.width / (16 / 9);
  const fullWidthStyle = !fullWidth
    ? {}
    : {
        width: '100%',
        height: 'auto',
        aspectRatio,
      };

  const content = (
    <>
      {coverImage && (
        <View
          style={{
            width: Metrics.horizontalScale(size.width),
            height: Metrics.verticalScale(size.height),
            position: 'absolute',
            ...fullWidthStyle,
          }}>
          <CustomFastImage
            style={[genericCardStyles.cover, fullWidthStyle]}
            width={fullWidth ? '100%' : Metrics.horizontalScale(size.width)}
            height={fullWidth ? 'auto' : Metrics.verticalScale(size.height)}
            aspectRatio={aspectRatio}
            source={coverImage}
          />
        </View>
      )}
      <View
        style={[
          genericCardStyles.cover,
          {
            width: Metrics.horizontalScale(size.width),
            height: Metrics.verticalScale(size.height),
            ...(padding
              ? {padding: padding}
              : {padding: Metrics.horizontalScale(9)}),
            ...(!coverImage && {
              backgroundColor: backgroundColor ?? darkTheme.colors.fifthGray,
            }),
            zIndex: 4,
            ...fullWidthStyle,
          },
          contentStyle,
        ]}>
        <View style={genericCardStyles.header}>
          {leadingHeader ?? <View />}
          {trailingHeader ?? <View />}
        </View>
        <View>{footer}</View>
      </View>
      <View
        style={{
          ...genericCardStyles.center,
          width: Metrics.horizontalScale(size.width),
          height: Metrics.verticalScale(size.height),
          ...fullWidthStyle,
        }}>
        {center ?? center}
      </View>
    </>
  );

  return onPress ? (
    <Pressable
      onPress={onPress}
      style={[
        {
          width: Metrics.horizontalScale(size.width),
          height: Metrics.verticalScale(size.height),
        },
        fullWidthStyle,
        style,
      ]}
      testID="generic-card">
      {content}
    </Pressable>
  ) : (
    <View
      style={{
        width: Metrics.horizontalScale(size.width),
        height: Metrics.verticalScale(size.height),
        ...fullWidthStyle,
        ...(style as any),
      }}
      testID="generic-card">
      {content}
    </View>
  );
};

export default GenericCard;
