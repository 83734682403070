import React from 'react';
import {StyleProp, ViewStyle, View, TextStyle} from 'react-native';
import {urlifyRegexp} from '@src/tools/utils/constants';
import {ITypographyProps} from 'design-system/src/Components/Typography/Typography.props';
import Typography from 'design-system/src/Components/Typography/Typography';

interface IHtmlRenderProps extends Pick<ITypographyProps, 'variant'> {
  html: string;
  color?: string;
  style?: StyleProp<ViewStyle>;
  textAlign?: TextStyle['textAlign'];
}

const urlify = (text: string) => {
  return text.replace(/&nbsp;/g, ' ').replace(urlifyRegexp, function (url) {
    return '<a href="' + url + '">' + url + '</a>';
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
};

const HtmlRender = (props: IHtmlRenderProps) => {
  const {html, color = 'white', variant = 'h1', style, textAlign} = props;

  return (
    <View style={style}>
      <Typography
        variant={variant}
        color={() => color}
        style={{
          textAlign,
        }}>
        <div
          dangerouslySetInnerHTML={{
            __html: urlify(html),
          }}></div>
      </Typography>
      {/* <RenderHtml
        contentWidth={width}
        tagsStyles={{
          strong: {
            fontFamily: 'System',
          },
          em: {
            fontFamily: 'System',
          },
          ...typographyVariantStyled,
        }}
        source={{
          html: urlify(html),
        }}
        systemFonts={systemFonts}
        baseStyle={{
          color: color,
          ...variantStyle,
          textAlign,
        }}
      /> */}
    </View>
  );
};

export default HtmlRender;
