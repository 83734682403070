/* eslint-disable react-native/no-inline-styles */
import React, {useEffect} from 'react';
import {
  INewsFragmentFragment,
  INewsOrderByInput,
} from '~Api/Graphql/gql/types.generated';
import CategoryPage from '~Umbraco/Pages/CategoryPage/CategoryPage';
import {CustomBottomSheetItem} from 'design-system/src/Components/CustomBottomSheet/CustomBottomSheet';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import {RouteSource} from '@src/Navigation/type';
import useAppNavigation from '@src/utils/useAppNavigation';
import NewsCategoryContent from './NewsCategoryContent/NewsCategoryContent';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import useParams from '@src/Hooks/useParams';

const NewsWatchPage = () => {
  const params = useParams();

  const navigation = useAppNavigation();

  const tracker = useTracker();
  const {id} = params || {};
  const {t} = useTranslation('translation');
  const {isMobile} = useBreakpoint();

  const sortList: Array<CustomBottomSheetItem> = [
    {
      item: INewsOrderByInput.TitleAsc,
      label: t('Title'),
    },
    {
      item: INewsOrderByInput.PubDateAsc,
      label: t('Published date (old)'),
    },
    {
      item: INewsOrderByInput.PubDateDesc,
      label: t('Published date (recent)'),
    },
  ];

  useEffect(() => {
    tracker.general.contentCategoryViewed({
      __typename: 'NewsCateg',
      source: (params?.source as RouteSource) || 'browse',
    });
  }, [params?.source]);

  const handlePlay = (item: INewsFragmentFragment) => () => {
    // navigation.navigate(AppRoutesEnum.VIDEO_PLAYER_VERTICALE, {
    //   state: {params: {type: 'news', news: item, source: 'category-page'}},

    // });

    // TODO : source
    new RouteFactory(
      {
        url: item.url ?? '',
        __typename: item.__typename,
        title: item.title,
        id: item.id,
        source: 'category-page',
      },
      navigation,
    ).navigate();
  };
  return (
    <CategoryPage<INewsOrderByInput>
      initialOrder={INewsOrderByInput.PubDateDesc}
      type="NewsCateg"
      sort={true}
      activeId={id as string}
      orderList={sortList}
      regionFilter={true}
      gridProps={{
        onItemPress: item => {
          if (item && item.raw && item.raw.__typename === 'News') {
            handlePlay(item.raw)();
          } else {
            console.error('New item raw data error');
          }
        },
      }}
      customRenderContent={contentProps => {
        return <NewsCategoryContent {...contentProps} />;
      }}
    />
  );
};

export default NewsWatchPage;
