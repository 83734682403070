import {IButtonFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import {RouteTypename} from '@src/Navigation/type';
import useNavigation from '@src/Navigation/useNavigation';
import {getLinkUrl} from '@src/Umbraco/Utils/element/getLinkPickerUrl';
import mapButton from '@src/Umbraco/Utils/element/mapButton';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import ServiceCarouselNumber from 'design-system/src/Components/ServiceCarouselNumber/ServiceCarouselNumber.web';
import ServicePromote, {
  IBlockItemTitleSlider,
  IBlockTitleSlider,
} from 'design-system/src/Components/ServicePromote/ServicePromote.web';
import React from 'react';
import {useDispatch} from 'react-redux';

const MultiSlider = (props: {content: Queries.Umbraco_MultiSlider}) => {
  const {title: _title, description, sliders, template} = props.content;
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const handleClickButtonItem = (item: IBlockItemTitleSlider) => {
    if (item && item.button) {
      new RouteFactory(
        {
          id: item.button.id || '',
          __typename: item.button.__typename as RouteTypename,
          url: item.button.url || '',
        },
        navigation,
        dispatch,
      ).navigate();
    }
  };
  if (sliders && sliders[0]) {
    let itemSliders: Array<IBlockTitleSlider> = [];
    sliders.forEach(blockSlider => {
      if (
        blockSlider &&
        blockSlider.content.contentTypeAlias === 'titleSlider'
      ) {
        const {
          slides,
          title: contentTitle,
          subtitle: contentSubtitle,
        } = blockSlider.content as unknown as Queries.TitleSliderFragmentFragment;
        let itemSlider: IBlockTitleSlider = {
          slideTitle: contentTitle ?? '',
          slideSubtitle: contentSubtitle ?? '',
          slides: [],
        };
        if (slides && slides[0]) {
          if (slides[0].content.contentTypeAlias === 'textSlide1') {
            slides.forEach(itemSlide => {
              let item =
                itemSlide?.content as Queries.TextSlide1FragmentFragment;
              let _cover;
              if (item.cover && item.cover[0]) {
                _cover = item.cover[0]
                  .content as unknown as Queries.HeroVideoCoverFragmentFragment;
              }
              // TODO: remove any
              let _link = getLinkUrl(item.linkTo as any);
              itemSlider.slides.push({
                title: item.title ?? '-',
                description: item.description ?? '-',
                overTitle: item.overtitle ?? '-',
                image: {
                  uri: _cover?.image?.url ?? '-',
                },
                button: _link && {
                  id: _link.id,
                  __typename: _link.__typename,
                  url: _link.url,
                },
              });
            });
          } else if (slides[0].content.contentTypeAlias === 'iconSlide') {
            slides.forEach(itemSlide => {
              let item =
                itemSlide?.content as Queries.IconSlideFragmentFragment;
              let _cover;
              if (item.cover && item.cover[0]) {
                _cover = item.cover[0]
                  .content as Queries.HeroVideoCoverFragmentFragment;
              }
              itemSlider.slides.push({
                title: item.title ?? '-',
                overTitle: item.overtitle ?? '-',
                image: {
                  uri: _cover?.image?.url ?? '-',
                },
                icon: {
                  uri: item.icon?.url || '',
                },
              });
            });
          } else if (slides[0].content.contentTypeAlias === 'ctaSlide') {
            slides.forEach(itemSlide => {
              let item = itemSlide?.content as Queries.CTASlideFragmentFragment;
              let _cover;
              if (item.cover && item.cover[0]) {
                _cover = item.cover[0]
                  .content as Queries.HeroVideoCoverFragmentFragment;
              }
              let _button;
              if (item.button && item.button[0]) {
                _button = mapButton({
                  ...item.button[0].content,
                  __typename: 'Button',
                } as IButtonFragmentFragment);
              }
              itemSlider.slides.push({
                title: item.title ?? '-',
                description: item.description ?? '',
                image: {
                  uri: _cover?.image?.url ?? '-',
                },
                // TODO:
                button: _button as any,
              });
            });
          }
        }
        itemSliders.push(itemSlider);
      }
    });
    if (itemSliders.length > 0) {
      if (template !== 'numbers') {
        return (
          <div
            style={{
              padding: '96px 16px',
            }}>
            <ServicePromote
              title={_title ?? ''}
              items={itemSliders}
              onButtonItemPress={handleClickButtonItem}
            />
          </div>
        );
      } else {
        // TODO: remove
        return (
          <div
            style={{
              padding: '96px 16px',
            }}>
            <ServiceCarouselNumber
              title={_title ?? ''}
              items={itemSliders}
              description={description ?? ''}
              onButtonItemPress={handleClickButtonItem}
            />
          </div>
        );
      }
    }
  }
  return null;
};

export default MultiSlider;
