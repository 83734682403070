import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

export interface IFlexBoxProps extends HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  wrap?: React.CSSProperties['flexWrap'];
  background?: string;
  flexBox?: 'flex' | 'inline-flex';
  flex?: React.CSSProperties['flex'];
  order?: React.CSSProperties['order'];
  gap?: React.CSSProperties['gap'];
  maxWidth?: React.CSSProperties['maxWidth'];
  minWidth?: React.CSSProperties['minWidth'];
  width?: React.CSSProperties['width'];
  mb?: React.CSSProperties['marginBottom'];
  mt?: React.CSSProperties['marginTop'];
}

export const FlexBox = styled.div<IFlexBoxProps, IFlexBoxProps>(
  ({
    flexBox,
    direction,
    justify,
    align,
    background,
    wrap,
    flex,
    order,
    gap,
    maxWidth,
    minWidth,
    width,
    mb,
    mt,
  }) => {
    return {
      display: flexBox || 'flex',
      'flex-direction': direction || 'initial',
      justifyContent: justify || 'initial',
      alignItems: align || 'initial',
      background: background || 'transparent',
      flexWrap: wrap,
      padding: 0,
      border: 0,
      flex,
      order,
      gap,
      maxWidth,
      minWidth,
      width,
      marginBottom: mb,
      marginTop: mt,
    };
  },
);

export default FlexBox;
