import {View} from 'react-native';
import React, {useEffect, useState} from 'react';
import CheckBoxItem from './CheckBoxItem/CheckBoxItem';
import Metrics from '../../../Theme/Metrics';

interface ICheckboxGroupItem {
  label: string;
  value: string | number;
}

export interface ICheckboxGroupProps {
  options: ICheckboxGroupItem[];
  selected?: Array<ICheckboxGroupItem['value']>;
  onChange?: (list: Array<ICheckboxGroupItem['value']>) => void;
}

const CheckboxGroup = (props: ICheckboxGroupProps) => {
  const {options, selected = [], onChange} = props;
  const [checkedList, setCheckedList] = useState<
    Array<ICheckboxGroupItem['value']>
  >([]);

  useEffect(() => {
    if (selected && selected.length !== checkedList.length) {
      setCheckedList(selected);
    }
  }, [selected]);

  const handleItemChange = (
    value: ICheckboxGroupItem['value'],
    isChecked: boolean,
  ) => {
    setCheckedList(list => {
      const _newList = isChecked
        ? [...list, value]
        : list.filter(item => item !== value);
      onChange && onChange(_newList);
      return _newList;
    });
  };
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}>
        {options.map((item, index) => {
          return (
            <CheckBoxItem
              key={item.value + (index + '')}
              style={{
                marginRight: Metrics.horizontalScale(8),
                marginBottom: Metrics.horizontalScale(8),
              }}
              label={item.label}
              value={item.value}
              checked={checkedList.includes(item.value)}
              onChange={handleItemChange}
            />
          );
        })}
      </View>
    </View>
  );
};

export default CheckboxGroup;
