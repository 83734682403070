import {useEffect} from 'react';
import useGetVimeoVideo from '@src/Api/TraceApi/Hooks/useGetVimeoVideo';
import {useGetLanguagesQuery} from '@src/Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import PageLoader from '@src/components/general/PageLoader/PageLoader';
import ThemeProvider from 'design-system/src/Provider/ThemeProvider/ThemeProvider';
import VimeoProvider from 'design-system/src/Provider/VimeoProvider/VimeoProvider';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import GridGlobalCss from 'design-system/src/WebComponent/Grid/GridGlobalCss';
import React from 'react';
import AppTrackingProvider from '../AppTrackingProvider/AppTrackingProvider';
import {RadioProvider} from '../RadioProvider/RadioProvider';
import {FloatingListenProvider} from '../FloatingListenProvider/FloatingListenProvider';
import {PlayListProvider} from '../PlayListProvider/PlayListProvider';
import useInitSetting from '@src/Services/Query/hooks/useInitSetting';
import AuthProvider from '../AuthProvider/AuthProvider';
import DebugModeProvider from 'design-system/src/Provider/DebugModeProvider/DebugModeProvider';

interface IInnerProviderProps {
  children: React.ReactElement;
}

const InnerProvider = (props: IInnerProviderProps) => {
  const {children} = props;
  const {isLoading} = useGetLanguagesQuery();
  const {getVimeoVideo} = useGetVimeoVideo();

  const {init} = useInitSetting();

  useEffect(() => {
    init();
  }, []);

  return (
    <DebugModeProvider>
      <ThemeProvider
        theme={{
          ...defaultTheme,
        }}>
        <GridGlobalCss />
        <VimeoProvider
          value={{
            getVimeoVideo,
          }}>
          <RadioProvider>
            <PlayListProvider>
              <FloatingListenProvider>
                <AppTrackingProvider>
                  <AuthProvider>
                    {isLoading ? <PageLoader /> : children}
                  </AuthProvider>
                </AppTrackingProvider>
              </FloatingListenProvider>
            </PlayListProvider>
          </RadioProvider>
        </VimeoProvider>
      </ThemeProvider>
    </DebugModeProvider>
  );
};

export default React.memo(InnerProvider);
