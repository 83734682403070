import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import CheckBox, {ICheckBoxPropsProps} from '../../CheckBox/CheckBox';

export interface ICheckboxControllerProps<
  TField extends FieldValues = FieldValues,
> extends Omit<ICheckBoxPropsProps, 'name'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
}

export default function CheckBoxController<
  TField extends FieldValues = FieldValues,
>(props: ICheckboxControllerProps<TField>) {
  const {name, rules, control, defaultValue, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });

  return <CheckBox checked={field.value} onChange={field.onChange} {...rest} />;
}
