import {useState, useCallback} from 'react';

const usePressableHover = (props?: {onChange?(value: boolean): void}) => {
  const {onChange} = props || {};
  const [hover, setHover] = useState(false);
  const onHoverIn = useCallback(() => {
    setHover(true);
    onChange?.(true);
  }, []);
  const onHoverOut = useCallback(() => {
    setHover(false);
    onChange?.(false);
  }, []);
  return {hover, onHoverIn, onHoverOut};
};

export default usePressableHover;
