import {useAppDispatch} from '@src/Store/hooks';
import {useCallback} from 'react';
import {useLazyGetAllCourseStatusQuery} from '~Api/TraceApi/traceApi';
import memberSlice from '~Store/Slices/Member.slice';

export const useMemberCourseStatus = () => {
  const [fetchCourse] = useLazyGetAllCourseStatusQuery();
  const dispatch = useAppDispatch();
  const fetchCourseStatus = useCallback(async () => {
    const {data} = await fetchCourse();
    if (data) {
      dispatch(memberSlice.actions.setCoursesStatus(data));
    }
  }, [fetchCourse, dispatch]);
  return {fetchCourseStatus};
};
