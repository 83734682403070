import {ContentTypeRaw} from '@src/Interfaces/ContentTypeRaw';
import {tracker} from './tracker';
import {RouteTypename} from '@src/Navigation/type';

export interface ILearnPropertyName {
  courseName?: string;
  courseId?: string;
  certificateName?: string;
  certificateId?: string;
  partnerName?: string;
  nuggetReference?: string; // TODO
  time?: Date;
  nuggetType?: string; // single choice, summary
  nuggetPlace?: string;
  unitName?: string;
  interestName?: string;
  opportunityId?: string;
  unitTimestamp?: number;
}
export interface IPlayerPropertyName {
  id: string;
  title: string;
  typename: string;
  length: string | number;
  category: string;
  eventTime?: string;
  contentTimestamp?: Date;
  contentRegion: string;
  collectionTitle?: string;
  seasonTitle?: string;
  episodeTitle?: string;
  interestName?: string;
  musicGenre: string;
  source?: string;
  originalLanguage?: string;
  originalQuality?: string;
  watched?: number;
  duration?: number;
  newQuality?: string;
  newLanguage?: string;
  newResolution?: string;
  restrictedCountries?: string;
  unitName?: string;
  courseName?: string;
  certificateName?: string;
  partnerName?: string;
  raw?: ContentTypeRaw;
  loadTime: number;
  skipNumber?: number;
  backwardsNumber?: number;
  pauseNumber?: number;
  countryOfProduction?: string;
}

export interface IContentTracker {
  id: string;
  url?: string;
  __typename: RouteTypename;
  name?: string;
  title?: string;
  categoryName?: string;
  countryOfProduction?: Array<string | undefined>;
}

const useTracker = () => {
  return tracker;
};

export default useTracker;
