import {AppRoutesEnum} from '@src/Constant/routes';
import getPrefixedPattern from './getPrefixedPattern';

type Params = {[key: string]: string};

function extractParamsFromLocation(
  pathname: string,
  pattern: AppRoutesEnum,
): Params {
  const paramNames: string[] = [];
  const regexPattern = getPrefixedPattern(pattern).replace(
    /:(\w+)/g,
    (_, paramName) => {
      paramNames.push(paramName);
      return '([^/]+)';
    },
  );

  const regex = new RegExp(`^${regexPattern}/?$`);
  const match = pathname.match(regex);

  if (!match) return {};

  return paramNames.reduce((params, paramName, index) => {
    params[paramName] = match[index + 1];
    return params;
  }, {} as Params);
}

export default extractParamsFromLocation;
