import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export const premiumStyles = StyleSheet.create({
  container: {
    paddingHorizontal: Metrics.horizontalScale(18),
    paddingVertical: Metrics.verticalScale(18),
    borderRadius: Metrics.moderateScale(4),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Metrics.verticalScale(21),
  },
  headerTitle: {
    fontSize: Metrics.fontScale(14),
    color: defaultTheme.primaryTitle,
  },
  title: {
    color: defaultTheme.primaryTitle,
    fontSize: Metrics.fontScale(16),
    marginBottom: Metrics.verticalScale(5),
  },
  iconCard: {
    width: Metrics.horizontalScale(72),
    height: Metrics.horizontalScale(72),
    borderRadius: Metrics.moderateScale(4),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultTheme.primaryNavBar,
  },
});
