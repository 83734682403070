import React from 'react';
import {View, StyleProp, ViewStyle, StyleSheet, Pressable} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Metrics from '../../../Theme/Metrics';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';

export type CardProfileMainMenuProps = {
  icon: IconName;
  title: string;
  onPress?(): void;
  count?: number;
  style?: StyleProp<ViewStyle>;
};

export default function CardProfileMainMenu(props: CardProfileMainMenuProps) {
  const {icon, title, onPress, count, style = {}} = props;

  return (
    <Pressable
      onPress={onPress}
      style={StyleSheet.flatten([
        {
          alignItems: 'center',
          backgroundColor: '#1B1C21',
          borderRadius: 4,
          paddingHorizontal: Metrics.horizontalScale(12),
          paddingTop: Metrics.verticalScale(20),
          paddingBottom: Metrics.verticalScale(15),
        },
        style,
      ])}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
        <View
          style={{
            position: 'relative',
          }}>
          {count !== undefined && (
            <View
              style={{
                width: Metrics.horizontalScale(20),
                height: Metrics.horizontalScale(20),
                backgroundColor: '#EA6C13',
                borderRadius: Metrics.horizontalScale(10),
                position: 'absolute',
                top: Metrics.horizontalScale(-10),
                right: Metrics.horizontalScale(-10),
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography variant="button1">{count}</Typography>
            </View>
          )}
          <Icon name={icon} />
        </View>
      </View>
      <Typography
        ellipsizeMode="middle"
        variant="body2"
        numberOfLines={1}
        style={{
          marginTop: Metrics.verticalScale(8),
          textAlign: 'center',
          fontSize: Metrics.fontScale(15),
        }}>
        {title}
      </Typography>
    </Pressable>
  );
}
