import React, {useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Typography from '../../Components/Typography/Typography';
import BubbleChart, {
  IBubbleChartProps,
} from '../../Components/BubbleChart/BubbleChart';
import Metrics from '../../Theme/Metrics';
import chunk from 'lodash/chunk';
import {EFontFamily} from '../../Components/Typography/Typography.styles';
import defaultTheme from '../../Theme/defaultTheme';
import useBreakpoint from '../../WebComponent/Grid/hooks/useBreakpoint';

export interface IBubbleChartOverviewProps
  extends Omit<IBubbleChartProps, 'width'> {
  title: string;
  subtitle: string;
  desc: string;
  courseCompleted: number;
  courseCompletedTitle: string;
  courseStarted: number;
  courseStartedTitle: string;
}

const BubbleChartOverview = (props: IBubbleChartOverviewProps) => {
  const {
    title,
    subtitle,
    desc,
    courseCompleted,
    courseCompletedTitle,
    courseStarted,
    courseStartedTitle,
    data,
    ...restProps
  } = props;
  const chunkData = chunk(data, Math.ceil(data.length / 2));
  const [width, setWidth] = useState<number>(328);
  const {isMobile} = useBreakpoint();
  const summary = useMemo(() => {
    return chunkData.map((d, parentIndex) => {
      return (
        <View
          style={{
            marginRight: parentIndex === 0 ? Metrics.horizontalScale(23) : 0,
          }}
          key={`dp-${parentIndex}`}>
          {d.map((item, index) => {
            let key = index + 1;
            if (parentIndex === 0) {
              if (chunkData[1] && chunkData[1][index]) {
                key = 2 * index + 1;
              } else if (chunkData[1] && chunkData[1].length) {
                key = chunkData[1].length + index + 1;
              }
            } else if (parentIndex === 1) {
              key = 2 * (index + 1);
            }
            return (
              <View
                key={`gvp-${index}`}
                style={{
                  flexDirection: 'row',
                }}>
                <View
                  style={{
                    width: Metrics.horizontalScale(8),
                    height: Metrics.horizontalScale(8),
                    borderRadius: Metrics.horizontalScale(4),
                    backgroundColor: item.color,
                    marginRight: Metrics.horizontalScale(4),
                  }}
                />
                <Typography
                  numberOfLines={1}
                  children={` ${key}\/ ${item.name.toUpperCase()}`}
                  variant="ui2"
                />
              </View>
            );
          })}
        </View>
      );
    });
  }, []);
  return (
    <View
      onLayout={e => {
        if (e.nativeEvent.layout.width) {
          setWidth(e.nativeEvent.layout.width);
        }
      }}>
      <View
        style={{
          alignItems: 'center',
        }}>
        {title && (
          <Typography
            children={title}
            style={{
              textAlign: 'center',
              marginBottom: Metrics.verticalScale(11),
            }}
          />
        )}
        {subtitle && (
          <Typography
            children={subtitle}
            style={{
              textAlign: 'center',
              marginBottom: Metrics.verticalScale(11),
            }}
            variant="body2"
          />
        )}
        <Typography
          children={desc.toUpperCase()}
          style={{
            textAlign: 'center',
          }}
          variant="ui2"
          color={'primary'}
        />
      </View>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'flex-start' : 'flex-end',
          flexDirection: isMobile ? 'column' : 'row',
        }}>
        {!isMobile && (
          <View
            style={{
              flexDirection: 'row',
              marginTop: Metrics.verticalScale(25),
            }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Text style={styles.numberText} children={`${courseCompleted}`} />
              <View
                style={{
                  marginLeft: Metrics.horizontalScale(12),
                  maxWidth: Metrics.horizontalScale(118),
                }}>
                <Typography children={courseCompletedTitle} variant="ui1" />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginLeft: Metrics.horizontalScale(16),
                alignItems: 'center',
              }}>
              <Text style={styles.numberText} children={`${courseStarted}`} />
              <View
                style={{
                  marginLeft: Metrics.horizontalScale(12),
                  maxWidth: Metrics.horizontalScale(118),
                }}>
                <Typography children={courseStartedTitle} variant="ui1" />
              </View>
            </View>
          </View>
        )}
        <BubbleChart
          data={data}
          width={isMobile ? width : width / 3}
          {...restProps}
        />

        <View
          style={{
            flexDirection: 'row',
            marginTop: Metrics.verticalScale(50),
          }}>
          {summary}
        </View>
        {isMobile && (
          <View
            style={{
              flexDirection: 'row',
              marginTop: Metrics.verticalScale(25),
            }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Text style={styles.numberText} children={`${courseCompleted}`} />
              <View
                style={{
                  marginLeft: Metrics.horizontalScale(12),
                  maxWidth: Metrics.horizontalScale(118),
                }}>
                <Typography children={courseCompletedTitle} variant="ui1" />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginLeft: Metrics.horizontalScale(16),
                alignItems: 'center',
              }}>
              <Text style={styles.numberText} children={`${courseStarted}`} />
              <View
                style={{
                  marginLeft: Metrics.horizontalScale(12),
                  maxWidth: Metrics.horizontalScale(118),
                }}>
                <Typography children={courseStartedTitle} variant="ui1" />
              </View>
            </View>
          </View>
        )}
      </div>
    </View>
  );
};

const styles = StyleSheet.create({
  numberText: {
    fontSize: Metrics.fontScale(56),
    fontWeight: '600',
    fontFamily: EFontFamily.CONDENSED,
    color: defaultTheme.primary,
    marginBottom: Metrics.verticalScale(-10),
  },
});

export default BubbleChartOverview;
