import React, {useState} from 'react';
import {StyleSheet} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import SocialItemButton from './SocialItemButton/SocialItemButton';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useFireBaseAuthentication, {
  IFirebaseAuthenticationResponse,
} from '@src/Hooks/useFirebaseAuthentication';
import appAuthSlice, {
  SocialNetworkSource,
} from '@src/Store/Slices/AppSlice/auth.slice';
import {useAppDispatch} from '@src/Store/hooks';
import {toast} from 'react-toastify';
import appSlice from '@src/Store/Slices/AppSlice';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import apiConfig from '@src/Configs/apiConfig';

interface ISocialNetworkAuthenticationProps {
  isSignIn?: boolean;
  onSuccess?: () => void;
}

const SocialNetworkAuthentication = (
  props: ISocialNetworkAuthenticationProps,
) => {
  const {isSignIn, onSuccess} = props;
  const {t} = useTranslation('auth');
  const tracker = useTracker();
  const [loadingItem, setLoadingItem] = useState<SocialNetworkSource | null>(
    null,
  );

  const dispatch = useAppDispatch();

  const {signInWithApple, signInWithFacebook, signInWithGoogle, signOut} =
    useFireBaseAuthentication();

  const buttonLabel: Record<SocialNetworkSource, string> = {
    apple: isSignIn ? t('Sign in WITH apple') : t('Register with apple'),
    facebook: isSignIn
      ? t('Sign in WITH facebook')
      : t('Register with facebook'),
    google: isSignIn ? t('Sign in WITH google') : t('Register with google'),
    email: isSignIn ? t('Sign in with my Email') : t('Register with my Email'),
  };

  const handleOnClick = async (source: SocialNetworkSource) => {
    setLoadingItem(source);
    dispatch(appAuthSlice.actions.setSocialNetworkSource(source));
    dispatch(appSlice.actions.setAccountType(source));

    let response: IFirebaseAuthenticationResponse | null = null;

    switch (source) {
      case 'apple':
        response = await signInWithApple(!isSignIn);
        break;
      case 'facebook':
        response = await signInWithFacebook(!isSignIn);
        break;
      case 'google':
        response = await signInWithGoogle(!isSignIn);
        break;
      case 'email':
        break;
    }

    const processMember = async () => {
      tracker.general.loggedIn({account_type: source});
      dispatch(appAuthSlice.actions.resetAuth());
    };

    if (isSignIn) {
      if (!response || response.status == 'success') {
        processMember();
        onSuccess?.();
      } else if (response.status == 'error') {
        toast.error(response.errorMessage);
      }
    } else if (response) {
      if (response.status == 'success') {
        const splittedDisplayName: string[] =
          response.user?.displayName?.split(' ') || [];
        dispatch(
          appAuthSlice.actions.setFormData({
            email: response.user?.email || '',
            lastName: splittedDisplayName.pop() || '',
            firstName: splittedDisplayName.join(' '),
          }),
        );
        dispatch(appAuthSlice.actions.setAuthStep({page: 'form', step: 0}));
      } else {
        console.log('response.errorMessage', response.errorMessage);
        toast.error(response.errorMessage);
      }
    }
    setLoadingItem(null);
  };

  return (
    <div style={{width: '100%'}}>
      {apiConfig.withFacebookConnect && (
        <SocialItemButton
          style={SocialNetworkButtonStyles.button}
          title={buttonLabel.facebook}
          icon={'facebook'}
          onPress={() => {
            handleOnClick('facebook');
          }}
          loading={loadingItem === 'facebook'}
        />
      )}
      <SocialItemButton
        style={SocialNetworkButtonStyles.button}
        title={buttonLabel.apple}
        icon={'apple'}
        onPress={() => {
          handleOnClick('apple');
        }}
        loading={loadingItem === 'apple'}
      />
      <SocialItemButton
        title={buttonLabel.google}
        icon={'googleG'}
        onPress={() => {
          handleOnClick('google');
        }}
        loading={loadingItem === 'google'}
      />
    </div>
  );
};

const SocialNetworkButtonStyles = StyleSheet.create({
  title: {
    marginBottom: Metrics.horizontalScale(28),
  },
  button: {
    marginBottom: Metrics.horizontalScale(16),
  },
  emailButton: {
    marginTop: Metrics.horizontalScale(20),
    height: Metrics.verticalScale(48),
    justifyContent: 'center',
  },
});

export default SocialNetworkAuthentication;
