import React from 'react';
import {PressableProps, Pressable, View} from 'react-native';
import AvatarCircle from '../AvatarCircle/AvatarCircle';
import {IImageProps} from '../Image/Image';
import Typography from '../Typography/Typography';
import {infoListTileStyles} from './InfoListTile.styles';

export interface InfoListTileProps extends Omit<PressableProps, 'style'> {
  source: IImageProps['source'];
  title: string;
  description: string;
}

const InfoListTile = (props: InfoListTileProps) => {
  const {source, title, description, ...restProps} = props;

  return (
    <Pressable {...restProps} style={infoListTileStyles.container}>
      <AvatarCircle source={source} />
      <View style={infoListTileStyles.body}>
        <Typography
          children={title}
          variant="h3"
          style={infoListTileStyles.title}
        />
        <Typography children={description} variant="body3" color="gray" />
      </View>
    </Pressable>
  );
};

export default InfoListTile;
