import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const bottomNavigationStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: Metrics.verticalScale(16),
    paddingBottom: Metrics.verticalScale(30),
  },
});
