import React, {ReactNode, useEffect, useRef} from 'react';
import {
  Animated,
  StyleProp,
  StyleSheet,
  Pressable,
  ViewStyle,
  PressableProps,
} from 'react-native';
import {useThemeContext} from '../../../Provider/ThemeProvider/ThemeContext';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics, {isWeb} from '../../../Theme/Metrics';
import {RoundedTextInputVariant} from '../../TextInput/RoundedTextInput/RoundedTextInput.styles';
import Typography from '../../Typography/Typography';
import {typographyVariantStyled} from '../../Typography/Typography.styles';

interface IFloatingLabelProps {
  label?: ReactNode;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  hasFocus?: boolean;
  hasValue?: boolean;
  backgroundColor?: string;
  color?: string;
  focusColor?: string;
  focusBackgroundColor?: string;
  focusBorderColor?: string;
  variant?: keyof typeof RoundedTextInputVariant;
  disableLabel?: boolean;
  multiline?: boolean;
  onPress?: PressableProps['onPress'];
}

const BLUR_ANIMATION_DURATION = 200;
const FOCUS_ANIMATION_DURATION = 180;

const FloatingLabel = (props: IFloatingLabelProps) => {
  const {
    label,
    style,
    disableLabel,
    hasFocus: hasFocusProp,
    hasValue,
    children,
    variant = 'default',
    multiline,
    onPress,
  } = props;
  const _animatedLabel = useRef(new Animated.Value(0)).current;
  const {component: {textInput: {mapColor = (c: any) => c} = {}} = {}} =
    useThemeContext();
  const theme = RoundedTextInputVariant[variant];

  const backgroundColor = mapColor(theme.colors.background);
  const color = mapColor(theme.colors.primary);
  const focusColor = mapColor(theme.colors.focus);
  const focusBackgroundColor = mapColor(theme.colors.focusBackground);
  const focusBorderColor = mapColor(theme.colors.focusBorderColor);
  const hasFocus: boolean = !!(hasValue || hasFocusProp);

  useEffect(() => {
    _animatedLabel.stopAnimation();
    if (hasFocus /* || focused */) {
      // minimize label
      Animated.timing(_animatedLabel, {
        toValue: 0,
        duration: BLUR_ANIMATION_DURATION * 1,
        useNativeDriver: true,
      }).start();
    } else {
      // restore label
      Animated.timing(_animatedLabel, {
        toValue: 1,
        duration: FOCUS_ANIMATION_DURATION * 1,
        useNativeDriver: true,
      }).start();
    }
  }, [_animatedLabel, hasFocus]);

  const labelStyle: Animated.WithAnimatedObject<ViewStyle> = {
    flex: 1,
    position: 'absolute',
    left: Metrics.horizontalScale(20),
    top: multiline ? Metrics.horizontalScale(13) : undefined,
    // width: '100%',
    paddingRight: Metrics.horizontalScale(20),
    paddingHorizontal: Metrics.horizontalScale(4),
    backgroundColor: hasFocus ? 'rgba(17, 18, 22, 1)' : 'rgba(17, 18, 22, 0)',
    zIndex: isWeb && !hasFocus ? 0 : undefined,
    transform: [
      {
        // Move label to top
        translateY: _animatedLabel.interpolate({
          inputRange: [0, 1],
          outputRange: [-Metrics.horizontalScale(27), 0],
        }),
      },
    ],
  };

  return (
    <Pressable
      style={[
        SelectStyles.root,
        {
          borderColor: hasFocus
            ? !hasFocusProp
              ? hasValue
                ? mapColor(defaultTheme.input.borderColor)
                : focusBorderColor
              : mapColor(theme.colors.activeBorderColor) ||
                mapColor(defaultTheme.input.activeBorderColor)
            : mapColor(defaultTheme.input.borderColor),
          backgroundColor: hasFocus
            ? focusBackgroundColor || backgroundColor
            : backgroundColor,
        },
        style,
      ]}
      onPress={onPress}>
      {!disableLabel && !!label && (
        <Animated.View style={labelStyle}>
          <Typography
            variant={hasFocus ? 'body3' : 'body2'}
            color={color ? () => color : undefined}
            numberOfLines={1}
            style={{
              flex: 1,
              fontSize: hasFocus
                ? typographyVariantStyled.body3.fontSize
                : typographyVariantStyled.body2.fontSize,
              color: hasFocus
                ? focusColor || color || 'white'
                : color || 'white',
            }}>
            {label}
          </Typography>
        </Animated.View>
      )}

      {children}
    </Pressable>
  );
};

export const SelectStyles = StyleSheet.create({
  root: {
    minHeight: Metrics.verticalScale(48),
    borderRadius: Metrics.verticalScale(48),
    borderWidth: 1,
    borderColor: defaultTheme.input.borderColor,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
});

export default FloatingLabel;
