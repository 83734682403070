import styled, {css} from 'styled-components';

export const _AcqCategoryItemRoot = styled('div')<{
  $size: number;
  $aspectRatio: number;
}>(({$size, $aspectRatio}) => {
  return {
    width: $size,
    minWidth: $size,
    display: 'inline-block',
    transition: 'all 400ms ease-in-out',
    flexBasis: $size,
    aspectRatio: $aspectRatio,
    position: 'relative',

    ['&.disabled']: {
      opacity: 0.5,
      ['.category-cta-button']: {
        visibility: 'hidden',
        pointerEvents: 'none',
      },
    },
    ['&, &:hover']: {
      opacity: 1,
      ['.category-cta-button']: {
        visibility: 'visible',
        pointerEvents: 'all',
      },
    },
    ['.category-cta-button']: {
      marginTop: 16,
    },
  };
});
export const _AcqCategoryItemImage = styled('div')<{}>(() => {
  return css`
    cursor: inherit;
    width: 100%;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    &.disabled {
      &:before {
        transform: scale(1) !important;
      }
      &:hover {
        &:before {
          transform: scale(1.14) !important;
        }
      }
    }
  `;
});
