import React, {useMemo} from 'react';
import {IconName} from '../../assets/Icones';
import {View} from 'react-native';
import Icon from '../Icon/Icon';
import Metrics from '../../Theme/Metrics';
import Typography from '../Typography/Typography';

export interface ToastProps {
  valid: boolean;
  title: string;
}

const Toast = (props: ToastProps) => {
  const {valid, title} = props;
  const info: {
    borderColor: string;
    backgroundColor: string;
    icon: IconName;
    iconColor: string;
  } = useMemo(() => {
    return !valid
      ? {
          borderColor: '#BE030A',
          backgroundColor: 'rgba(255, 30, 30, 0.13)',
          icon: 'invalide',
          iconColor: '#737373',
        }
      : {
          borderColor: 'rgba(5, 201, 107, 0.8)',
          backgroundColor: 'rgba(5, 201, 107, 0.2)',
          icon: 'check-circle',
          iconColor: 'rgba(5, 201, 107, 0.8)',
        };
  }, [valid]);
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: Metrics.horizontalScale(8),
        borderRadius: Metrics.moderateScale(8),
        borderWidth: 1,
        borderColor: info.borderColor,
        backgroundColor: info.backgroundColor,
      }}>
      <Icon name={info.icon} color={info.iconColor} />
      <Typography
        style={{marginLeft: Metrics.horizontalScale(10), flexShrink: 1}}
        variant="body2">
        {title.toUpperCase()}
      </Typography>
    </View>
  );
};

export default Toast;
