import {useEffect, useMemo, useState} from 'react';
import ResponsiveObserve, {ScreenMap} from '../_utils/responsiveObserve';

function useBreakpoint(): ScreenMap & {
  isMobile: boolean;
  isMobileAndMd: boolean;
  isMobileAndLg: boolean;
} {
  const [screens, setScreens] = useState<ScreenMap>({});

  const isMobile = useMemo(() => {
    return !!screens.mobile;
  }, [screens]);

  const isMobileAndMd = useMemo(() => {
    return !!(
      (screens.xs || screens.sm || screens.md) &&
      !screens.lg &&
      !screens.xl &&
      !screens.xxl
    );
  }, [screens]);
  const isMobileAndLg = useMemo(() => {
    return !!(
      (screens.xs || screens.sm || screens.md || screens.lg) &&
      !screens.xl &&
      !screens.xxl
    );
  }, [screens]);

  useEffect(() => {
    const token = ResponsiveObserve.subscribe(supportScreens => {
      setScreens(prevScreens => {
        if (JSON.stringify(supportScreens) !== JSON.stringify(prevScreens)) {
          return supportScreens;
        }
        return prevScreens;
      });
    });

    return () => ResponsiveObserve.unsubscribe(token);
  }, []);

  return {
    ...screens,
    isMobile,
    isMobileAndMd,
    isMobileAndLg,
  };
}

export default useBreakpoint;
