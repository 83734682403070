import React from 'react';
import {View} from 'react-native';
import CustomProgressBar from '../../../../Components/CustomProgressBar/CustomProgressBar';
import {CustomHeaderWeb as CustomHeader} from '../../../../Components/Header';
import {CustomHeaderProps} from '../../../../Components/Header/CustomHeader/CustomHeader.props';
import IconWithText from '../../../../Components/IconWithText/IconWithText';
import Typography from '../../../../Components/Typography/Typography';
import defaultTheme from '../../../../Theme/defaultTheme';
import Metrics from '../../../../Theme/Metrics';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';

export interface LiveEventHeaderProps extends CustomHeaderProps {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  releaseDate?: string;
  progress?: number;
  isNofified?: boolean;
  isLive?: boolean;
  onPressTabBarItem?: (item: string) => void;
  notifiedLabel?: string;
  toNotifyLabel?: string;
  liveLabel?: string;
  hideButton?: boolean;
}

const LiveEventHeader = (props: LiveEventHeaderProps) => {
  const {
    title,
    description,
    startDate,
    endDate,
    image,
    releaseDate,
    progress,
    isNofified,
    isLive,
    onPressButton,
    notifiedLabel,
    toNotifyLabel,
    liveLabel = 'Watch Now',
    hideButton,
    buttonProps,
    // onPressTabBarItem
  } = props;
  const buttonNotified = isNofified
    ? notifiedLabel || 'NOTIFIED'
    : toNotifyLabel || 'Notify me';
  const buttonTitle = isLive ? liveLabel : buttonNotified;
  const dateMargin = releaseDate && !isLive ? 9 : 13;

  const {isMobile} = useBreakpoint();

  return (
    <View style={{width: '100%'}}>
      <CustomHeader
        image={image}
        title={title}
        buttonTitle={buttonTitle}
        onPressButton={onPressButton}
        hideButton={hideButton}
        contentBottom={24}
        buttonProps={{
          variant: 'outlined',
          icon: isNofified ? 'check' : 'play',
          size: 'small',
          buttonColor: isNofified ? 'white' : undefined,
          borderColor: isNofified ? 'white' : defaultTheme.primary,
          ...buttonProps,
        }}
        footer={
          progress &&
          isLive && (
            <View
              style={{
                width: '100%',
                paddingHorizontal: Metrics.horizontalScale(16),
                marginBottom: Metrics.verticalScale(28),
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
              }}>
              <View
                style={{
                  width: isMobile ? '100%' : Metrics.horizontalScale(432),
                }}>
                <CustomProgressBar
                  progress={progress}
                  backgroundColor="rgba(255,255,255, 0.5))"
                />
              </View>
            </View>
          )
        }>
        <Typography
          numberOfLines={3}
          ellipsizeMode="tail"
          children={description}
          variant="body2"
          style={{
            paddingHorizontal: Metrics.horizontalScale(44),
            textAlign: 'center',
          }}
        />
        {releaseDate && !isLive && (
          <Typography
            children={releaseDate}
            color="primary"
            variant="h4"
            style={{
              marginTop: Metrics.verticalScale(17),
            }}
          />
        )}
        <View
          style={{
            marginTop: Metrics.verticalScale(dateMargin),
          }}>
          <IconWithText
            text={startDate + '  •  ' + endDate}
            icon="clock"
            size="16"
            textColor={'gray'}
          />
        </View>
      </CustomHeader>
      {/* <View
        style={StyleSheet.flatten({
          position: 'absolute',
          top: Metrics.verticalScale(87),
        })}>
        <TabBarHeader
          items={[
            {title: 'Coming soon', color: !isLive ? 'primary' : undefined},
            {title: 'Live', color: isLive ? 'primary' : undefined},
          ]}
          onClick={item => {
            onPressTabBarItem(item.title)
          }}
        />
      </View> */}
    </View>
  );
};

export default LiveEventHeader;
