import {TraceApiEndpointBuilder} from '../traceApi';

export const createProxyEndpoint = (builder: TraceApiEndpointBuilder) => ({
  proxy: builder.query<any, {url: string}>({
    keepUnusedDataFor: 0,
    query: ({url}) => ({
      url: '/proxy',
      method: 'POST',
      body: {
        link: url,
      },
    }),
  }),
  extendUrl: builder.query<any, {url: string}>({
    keepUnusedDataFor: 0,
    query: ({url}) => ({
      url: '/proxy/extend',
      method: 'POST',
      body: {
        link: url,
      },
    }),
  }),
});
