import React, {FC} from 'react';

interface IGridItemProps {
  children: React.ReactNode;
  flex?: number;
}

const GridItem: FC<IGridItemProps> = ({children, flex}) => (
  <div style={{flex}}>{children}</div>
);

export default GridItem;
