import LearnHomeCard from 'design-system/src/Components/Card/LearnCard/LearnHomeCard/LearnHomeCard';
import BlockSlider, {
  IBlockSliderProps,
} from 'design-system/src/Components/Card/HighlightedCarousel/BlockSlider/BlockSlider';
import {ILearnHomeCardProps} from 'design-system/src/Components/Card/LearnCard/LearnHomeCard/LearnHomeCard';
import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {RouteTypename} from '~Navigation/type';

export interface ICourseSliderItem
  extends Omit<ILearnHomeCardProps, 'url' | 'onPress'> {
  __typename: RouteTypename;
  url: string;
}

export interface CourseSliderProps
  extends Omit<IBlockSliderProps<ICourseSliderItem>, 'premiumText'> {}

const CourseSlider = (props: CourseSliderProps) => {
  const {
    data: courses,
    title,
    onItemPress,
    size,
    onSeeMore,
    seeMore,
    ...restProps
  } = props;
  const {t} = useTranslation('translation');

  return (
    <View>
      <BlockSlider<ICourseSliderItem>
        seeMore={
          seeMore ||
          (onSeeMore && {label: t('See more'), onPress: onSeeMore}) ||
          undefined
        }
        title={title}
        data={courses}
        customRenderItem={({item}) => {
          // console.log('item progress', item.progress)
          return (
            <LearnHomeCard
              onPress={() => {
                onItemPress && onItemPress(item);
              }}
              {...item}
              size={size as ILearnHomeCardProps['size']}
              activeHover={true}
            />
          );
        }}
        onItemPress={onItemPress}
        premiumText={t('premium') as string}
        {...restProps}
      />
    </View>
  );
};

export default CourseSlider;
