/* eslint-disable react-native/no-inline-styles */
declare global {
  interface Window {
    chrome: any;
  }
}

import React, {FC, useEffect} from 'react';
import {Pressable} from 'react-native';
import Icon from '../../../../Components/Icon/Icon';
import Typography from '../../../../Components/Typography/Typography.web';
// import CastButton from '../CastControl';
import CastButton from '../Factory/Player/components/CastButton';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';
import useCast from '../Factory/Player/hooks/useCast';
import useCastPlayer from '../Factory/Player/hooks/useCastPlayer';

interface PlayerHeaderProps {
  title: string;
  onSetOpenParameters?: () => void;
  isLive?: boolean;
  onGoBack?: () => void;
  isHide?: boolean;
  poster?: string;
  currentMedia: number;
  isVertical?: boolean;
  playedSeconds?: number;
  uri: string;
  handlePlayPause?: () => void;
  hideParameters?: boolean;
  disableCast?: boolean;
}

const PlayerHeader: FC<PlayerHeaderProps> = ({
  title,
  onSetOpenParameters,
  isLive,
  onGoBack,
  isHide,
  isVertical,
  poster,
  uri,
  playedSeconds,
  handlePlayPause,
  hideParameters,
  disableCast,
}) => {
  const {isMobile} = useBreakpoint();

  const {connected} = useCast();

  const {thumbnail, loadMedia, seek, isMediaLoaded, stopMedia} =
    useCastPlayer();

  useEffect(() => {
    if (isMediaLoaded && Boolean(playedSeconds)) {
      seek(playedSeconds);
    }
  }, [isMediaLoaded, Boolean(playedSeconds)]);

  useEffect(() => {
    if (connected && !isLive && !isVertical) {
      const mediaInfo = new window.chrome.cast.media.MediaInfo(
        1,
        'application/x-mpegURL',
      );
      mediaInfo.contentUrl = uri;
      mediaInfo.hlsSegmentFormat = 'ts';

      const metadata = new window.chrome.cast.media.MovieMediaMetadata();
      metadata.title = title;
      metadata.images = [new window.chrome.cast.Image(poster ?? thumbnail)];
      mediaInfo.metadata = metadata;

      const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
      loadMedia(request);
      handlePlayPause && handlePlayPause();
    }
  }, [connected, uri]);

  const handleGoBack = () => {
    stopMedia();
    onGoBack && onGoBack();
  };

  return (
    <div
      style={{
        width: '100%',
        paddingTop: 32,
        display: isHide ? 'none' : 'flex',
        justifyContent: 'space-between',
        position: 'relative',
      }}>
      {((isVertical && isMobile) || !isVertical) && onGoBack && (
        <Pressable onPress={handleGoBack}>
          <Icon name="retour" size="76" />
        </Pressable>
      )}
      {!isVertical && <Typography variant="body2">{title}</Typography>}
      <div
        style={{
          display: 'inline-flex',
          gap: 22,
        }}>
        {!isLive && !isVertical && !isMediaLoaded && !hideParameters && (
          <Pressable onPress={onSetOpenParameters}>
            <Icon name="parametre" size="76" />
          </Pressable>
        )}
        {/* <Pressable>
          <Icon name="cast" size="76" />
        </Pressable> */}
        {!isLive && !isVertical && !hideParameters && !disableCast && (
          <div style={{height: 24}}>
            <CastButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerHeader;
