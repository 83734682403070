import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberUnitProgressionBody} from './interface/memberDataBody.interface';
import {IUnitProgressionResponse} from './interface/memberDataResponse.interface';

const endpoint = '/me/unit-progression';
export const createMemberUnitProgressionEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getAllUnitProgression: builder.query<IUnitProgressionResponse[], void>({
    query: () => ({
      url: endpoint,
      method: 'GET',
    }),
  }),
  getMemberUnitProgressionById: builder.query<
    IUnitProgressionResponse,
    {id: string}
  >({
    query: ({id}) => ({
      url: `${endpoint}/find?unitId=${id}`,
      method: 'GET',
    }),
  }),
  updateMemberUnitProgression: builder.mutation<
    IUnitProgressionResponse,
    {culture: string; data: IMemberUnitProgressionBody}
  >({
    query: ({culture, data}) => ({
      url: `${endpoint}?culture=${culture}`,
      method: 'POST',
      body: data,
    }),
  }),
});
