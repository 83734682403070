import {StyleSheet} from 'react-native';
import {darkTheme} from '../../../styles/Themes';
import Metrics from '../../../Theme/Metrics';

export const sharedCardStyles = StyleSheet.create({
  icContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: Metrics.verticalScale(12),
  },
  icFav: {
    marginLeft: Metrics.horizontalScale(16),
  },
  description: {
    color: darkTheme.colors.secondaryGray,
  },
  descriptionContainer: {
    marginTop: Metrics.verticalScale(16),
  },
  checkWatch: {
    position: 'absolute',
    bottom: Metrics.verticalScale(10),
    right: Metrics.horizontalScale(10),
  },
  pressableZone: {
    padding: Metrics.verticalScale(12),
    margin: Metrics.verticalScale(-12),
  },
});
