import {INewsCategFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {isElementRestricted} from '~Api/Graphql/links/utils/isElementRestricted';
import DateFactory from '~tools/DateFactory';
import {IContentSliderItem} from '~Umbraco/Components/ContentSlider/ContentSlider';

export const mapNewsCategItems = (
  items: INewsCategFragmentFragment['children']['items'],
): IContentSliderItem[] => {
  const list: IContentSliderItem[] = [];
  items.forEach(item => {
    if (item && item.__typename === 'News') {
      const {title, thumbnail, shortDescription, pubDate, url, id, __typename} =
        item;
      if (!isElementRestricted(item) && url) {
        list.push({
          id,
          url: url || '',
          title: title || '',
          subtitle: shortDescription || '',
          cover: thumbnail
            ? {
                uri: thumbnail.url,
              }
            : undefined,
          extraContent: pubDate
            ? [
                {
                  type: 'date',
                  date: new DateFactory(pubDate).format(),
                },
              ]
            : undefined,
          __typename,
          accessData: {
            accessType: item.accessType,
            paywall: item.paywall,
          },
        });
      }
    }
  });

  return list;
};
