import React from 'react';
import {Pressable, View} from 'react-native';
import {FastImageProps} from 'react-native-fast-image';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import Icon from '../../Icon/Icon';
import Tags, {TagsProps} from '../../Tags/Tags';
import Typography from '../../Typography/Typography';
import {CardBookmarkStyles} from './CardBookmark.styles';
import IcSettingSvg from '../../../assets/svg/setting-icon.svg';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';

type ImageSize = 'small' | 'news' | 'smallW' | 'newsW';

export type CardBookmarkProps = {
  cover: FastImageProps['source'];
  title: string;
  size?: ImageSize;
  types?: string[];
  duration?: string;
  date?: string;
  isTrace?: boolean;
  description?: string;
  tag?: TagsProps;
  onPress?: () => void;
  onPressTrailingIc?: () => void;
  fullWidth?: boolean;
  aspectRatio?: number;
};

const imageSize: Record<ImageSize, {width: number; height: number}> = {
  small: {
    width: 109,
    height: 61,
  },
  smallW: {
    width: 248,
    height: 138,
  },
  news: {
    width: 109,
    height: 124,
  },
  newsW: {
    width: 248,
    height: 280,
  },
};

export default function CardBookmark(props: CardBookmarkProps) {
  const {
    cover,
    size = 'small',
    title,
    types,
    duration,
    isTrace,
    date,
    description,
    tag,
    onPress,
    onPressTrailingIc,
    fullWidth,
    aspectRatio,
  } = props;

  const {isMobile} = useBreakpoint();

  const sizeValue = imageSize[size];

  const fullWidthStyle = !fullWidth
    ? {}
    : {
        width: '100%',
        height: 'auto',
        aspectRatio,
      };

  return (
    <Pressable
      onPress={onPress}
      style={{flexDirection: isMobile ? 'row' : 'column'}}>
      <View
        style={{
          marginRight: Metrics.horizontalScale(isMobile ? 16 : 0),
          position: 'relative',
          flex: 1,
        }}>
        {tag && (
          <Tags
            variant={tag?.variant}
            text={tag?.text as string}
            style={CardBookmarkStyles.tag}
          />
        )}
        <CustomFastImage
          source={cover}
          width={fullWidth ? '100%' : Metrics.horizontalScale(sizeValue.width)}
          height={
            fullWidth ? 'auto' : Metrics.horizontalScale(sizeValue.height)
          }
          style={[{borderRadius: 4}, fullWidthStyle]}
          aspectRatio={aspectRatio}
        />
      </View>
      <View
        style={{
          width: fullWidth
            ? '100%'
            : Metrics.horizontalScale(isMobile ? 170 : sizeValue.width),
          marginTop: isMobile ? 0 : Metrics.verticalScale(10),
          flex: isMobile ? 1 : undefined,
        }}>
        <Typography variant="h4">{title}</Typography>
        {types && types.length > 0 && (
          <View
            style={{flexDirection: 'row', ...CardBookmarkStyles.spacingTop}}>
            {types.map((t, index) => (
              <Typography variant="ui3" color="gray">
                {t}
                {index < types.length - 1 ? ' - ' : ''}
              </Typography>
            ))}
          </View>
        )}
        {description && (
          <Typography
            variant="ui3"
            color="gray"
            style={CardBookmarkStyles.spacingTop}>
            {description}
          </Typography>
        )}
        {date && (
          <View
            style={{flexDirection: 'row', ...CardBookmarkStyles.spacingTop}}>
            <Icon name="date" color="#737373" />
            <Typography
              variant="ui3"
              color="gray"
              style={CardBookmarkStyles.spaceBetweenIcon}>
              {date}
            </Typography>
          </View>
        )}
        {duration && (
          <View
            style={{flexDirection: 'row', ...CardBookmarkStyles.spacingTop}}>
            <Icon name="clock" color="#737373" />
            <Typography
              variant="ui3"
              color="gray"
              style={CardBookmarkStyles.spaceBetweenIcon}>
              {duration}
            </Typography>
          </View>
        )}
        {isTrace && (
          <View style={{marginTop: Metrics.verticalScale(11)}}>
            <Icon name="trace" />
          </View>
        )}
      </View>
    </Pressable>
  );
}
