import {StyleSheet} from 'react-native';
import Metrics from '../../../../Theme/Metrics';

export const CarouselCardInfoStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leading: {
    alignSelf: 'flex-start',
    flex: 1,
  },
  title: {
    marginBottom: Metrics.verticalScale(5),
  },
  subtitle: {
    paddingBottom: Metrics.verticalScale(5),
  },
  itemMargin: {
    marginBottom: Metrics.verticalScale(5),
  },
});
