import React, {FC, Suspense} from 'react';
import {RouteComponentProps} from '@reach/router';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import HomeContentLoader from 'design-system/src/Components/ContentLoader/HomeContentLoader/HomeContentLoader';

const AppDynamicHomePage = React.lazy(
  () => import('../../Common/AppDynamicHomePage/AppDynamicHomePage'),
);

const SectionPage = React.lazy(
  () => import('@src/Umbraco/Pages/SectionPage/SectionPage'),
);

const AppHomeListen: FC<RouteComponentProps<{}>> = () => {
  const {page, loading} = useRegionUrl();

  if (loading) {
    return <HomeContentLoader />;
  }

  if (page.listen) {
    const contentId: string = page.listen.id;
    const contentType = page.listen.__typename;
    return (
      <Suspense>
        <AppDynamicHomePage
          id={contentId}
          __typename={contentType}
          contentSource="listen"
        />
      </Suspense>
    );
  }

  return (
    <div>
      <Suspense>
        <SectionPage contentSource="listen" />
      </Suspense>
    </div>
  );
};

export default AppHomeListen;
