import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const appBarStyles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    
  },
  heightContainer: {
    height: Metrics.verticalScale(50),
    paddingVertical: Metrics.verticalScale(10),
    marginTop: Metrics.verticalScale(31),
  },
  leading: {
    marginRight: Metrics.horizontalScale(14),
  },
  title: {
    flex: 1,
    position: 'absolute',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
