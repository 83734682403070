import {useCallback, useEffect, useState} from 'react';
import {VideoProperties} from 'react-native-video';

export function useChannel<
  T extends {
    uri: string | (() => Promise<string>);
    castSource?: (
      cb: (isMp4: boolean, source: string) => void,
    ) => Promise<boolean | void>;
  },
>(sourceList: Array<T>, initialMedia: number, cb?: Function) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSource, setCurrentSource] = useState<
    Record<string, VideoProperties['source'] & {description: string}>
  >({});

  useEffect(() => {
    if (!currentSource[initialMedia]) {
      loadResource(initialMedia);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMedia]);

  // const goToChannel = useCallback(
  //   async (channel: number) => {
  //     if (sourceList[channel]) {
  //       cb && cb();
  //     }
  //   },
  //   [cb, sourceList],
  // );
  const channelUp = useCallback(
    (_cb?: (newChannel: number) => void) => {
      if (initialMedia < sourceList.length) {
        const updatedIndex = (initialMedia + 1) % sourceList.length;
        if (!currentSource[updatedIndex]) {
          setIsLoading(true);
        }
        // goToChannel(updatedIndex);
        _cb && _cb(updatedIndex);
      }
    },
    [initialMedia, sourceList.length],
  );

  const channelDown = useCallback(
    (_cb?: (newChannel: number) => void) => {
      if (initialMedia > 0) {
        const updatedIndex =
          (initialMedia + sourceList.length - 1) % sourceList.length;
        if (!currentSource[updatedIndex]) {
          setIsLoading(true);
        }
        // goToChannel(updatedIndex);
        _cb && _cb(updatedIndex);
      }
    },
    [initialMedia, sourceList.length],
  );

  const loadResource = useCallback(
    async (index: number) => {
      try {
        if (sourceList[index]) {
          const uri = sourceList[index].uri;
          if (typeof uri === 'function') {
            const response = await uri();

            if (response) {
              setCurrentSource(r => ({
                ...r,
                [index]: {
                  ...sourceList[index],
                  uri: response,
                },
              }));
            }
          } else if (typeof uri === 'string') {
            setCurrentSource(r => ({
              ...r,
              [index]: {
                ...sourceList[index],
                uri,
              },
            }));
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [sourceList],
  );
  return {
    currentMedia: initialMedia,
    isLoading,
    currentSource: currentSource[initialMedia],
    channelUp,
    channelDown,
    // goToChannel,
  };
}
