import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import snackbarSlice, {INotification} from './Snackbar.slice';
import {useAppSelector} from '@src/Store/hooks';

export const useSnackbarNotification = () => {
  const notifications = useAppSelector(
    state => state.snackbar.notifications || [],
  );
  const dispatch = useDispatch();

  const add = useCallback(
    (data: Omit<INotification, 'id'>) => {
      dispatch(snackbarSlice.actions.addNotification(data));
    },
    [dispatch],
  );
  const remove = useCallback(
    (id: string) => {
      dispatch(snackbarSlice.actions.removeNotification(id));
    },
    [dispatch],
  );

  return {list: notifications || [], add, remove};
};
