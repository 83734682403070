import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import DatePicker from './DatePicker.web';
import {IDatePickerProps} from './DatePicker.web';
import moment from 'moment';

interface DatePickerControllerProps<TField extends FieldValues = FieldValues>
  extends IDatePickerProps {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
}

const DatePickerController = <TField extends FieldValues = FieldValues>(
  props: DatePickerControllerProps<TField>,
) => {
  const {control, defaultValue, name, rules, ...rest} = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules: {
      ...rules,
      validate: value => {
        if (!value) {
          return rules && typeof rules?.required === 'string'
            ? rules.required
            : 'Date is required';
        }
        return moment(value, rest.dateFormat, true).isValid()
          ? true
          : 'Invalid date format';
      },
    },
  });

  const {field: fieldSecondValue} = useController<TField>({
    control,
    defaultValue,
    name: (name + 'Value') as Path<TField>,
  });

  const handleChange = (value: string) => {
    field.onChange(value as any);

    const rawDate = moment(value, rest.dateFormat).toISOString();
    fieldSecondValue.onChange(rawDate as any);
  };

  return <DatePicker value={field.value} onChange={handleChange} {...rest} />;
};

export default DatePickerController;
