import Modal from 'react-modal';
import React, {useState} from 'react';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import {Pressable} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Button from 'design-system/src/Components/Button/Button';
import TextInputController from 'design-system/src/Components/Form/TextInputController/TextInputController';
import {useForm} from 'react-hook-form';
import Metrics from 'design-system/src/Theme/Metrics';
import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';

interface DialogDeleteAccountProps {
  isOpen: boolean;
  onClose: () => void;
  userEmail: string;
}

interface ISignInForm {
  email: string;
  password: string;
}

const DialogDeleteAccount = ({
  isOpen,
  onClose,
  userEmail,
}: DialogDeleteAccountProps) => {
  const {t} = useTranslation('profile');
  const {control, handleSubmit} = useForm<ISignInForm>();
  const {deleteCurrentUser} = useFireBaseAuthentication();
  const [loading, setLoading] = useState(false);

  const handleDelete = async (data: ISignInForm) => {
    setLoading(true);
    await deleteCurrentUser({
      email: userEmail,
      password: data.password,
    });
    onClose();
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={
        document.getElementById('profile-my-profile-page') as HTMLElement
      }
      overlayElement={(d, contentElement) => (
        <div
          {...d}
          style={{
            ...d.style,
            background: 'rgba(17, 18, 22, 0.60)',
            backdropFilter: 'blur(10px)',
            zIndex: 11000,
          }}>
          {contentElement}
        </div>
      )}
      style={{
        content: {
          border: 'none',
          background: 'transparent',
          padding: 0,
          margin: 'auto',
          width: 'fit-content',
          height: 'fit-content',
          inset: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        overlay: {
          background: 'rgba(17, 18, 22, 0.60)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: defaultTheme.page.background,
          borderRadius: 8,
          padding: 20,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h3" style={{color: 'white'}}>
            {t('Do you want to remove your account?')}
          </Typography>
          <Pressable onPress={onClose}>
            <Icon name="close" color="white" />
          </Pressable>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
          <Typography variant="body2">
            {t('Enter your password to confirm') || ''}
          </Typography>
        </div>
        <TextInputController
          label={t('Password') + ''}
          control={control}
          name="password"
          rules={{
            required: t('Password is required') + '',
          }}
          style={{
            marginTop: Metrics.verticalScale(32),
            marginBottom: Metrics.verticalScale(32),
          }}
          variant="transparent"
          secureTextEntry={true}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 40,
            padding: 20,
          }}>
          <Button
            variant="contained"
            size="small"
            title={t('Cancel')}
            fullWidth
            onPress={onClose}
            style={{
              backgroundColor: 'transparent',
            }}
            disabled={loading}
          />
          <Button
            variant="contained"
            size="small"
            title={t('Delete')}
            fullWidth
            onPress={handleSubmit(handleDelete)}
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DialogDeleteAccount;
