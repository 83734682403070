import {StyleSheet} from 'react-native';
import defaultTheme from '../../../../Theme/defaultTheme';
import Metrics from '../../../../Theme/Metrics';

export const CustomTabBarStyles = StyleSheet.create({
  root: {
    position: 'relative',
    width: '100%',
  },
  tabBar: {
    height: Metrics.horizontalScale(56),
    flexDirection: 'row',
    borderBottomColor: defaultTheme.gray,
    borderBottomWidth: Metrics.verticalScale(1),
  },
  tabBarActiveIndicator: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderBottomColor: defaultTheme.primary,
    borderBottomWidth: Metrics.verticalScale(1),
  },
  tabItem: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: Metrics.horizontalScale(5),
  },
  tabItemText: {
    textAlign: 'center',
  },
  activeTab: {},
  tab: {},
});
