import Typography from 'design-system/src/Components/Typography/Typography';
import styled from 'styled-components';

const GradiantText = styled(Typography)(() => {
  return {
    background: 'linear-gradient(to right, #C6A76E 0%, #AD782F 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  };
});

export default GradiantText;
