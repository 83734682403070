import Typography from 'design-system/src/Components/Typography/Typography.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useMemo, useState} from 'react';
import {View, Pressable} from 'react-native';
import ProfileAccountItem from './ProfileAccountItem';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useMember from '@src/Store/selectors/useMember';
import DateFactory from '@src/tools/DateFactory';
import {useAllContentByListIdQuery} from '@src/Api/Graphql/gql/types.generated';
import documentLinkToUuid from '@src/Umbraco/Utils/documentLinkToUuid';
import appSlice from '@src/Store/Slices/AppSlice';
import {useAppDispatch} from '@src/Store/hooks';
import {Member} from '@src/Api/Umbraco/interface/Member.interface';
import DialogDeleteAccount from './DialogDeleteAccount';
import useInterestTags from '@src/App/components/ModalAuthentication/SignUp/SignUpInterest/useInterestTags';
import useMusicGenre from '@src/App/components/ModalAuthentication/SignUp/SignUpInterest/useMusicGenre';

const pattern =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const valideUiid = (value: string) => {
  const reg = new RegExp(pattern);
  return reg.test(value);
};

export type AccountData = {
  label: string;
  fieldKey: keyof Member;
  value: string;
  locked?: boolean;
  select?: Array<{
    label: string;
    value: string | number;
  }>;
};
export const ProfileMyProfile = () => {
  const member = useMember();
  const {t} = useTranslation('profile');

  const {findTagItem} = useInterestTags();
  const {findMusicGenreItem} = useMusicGenre();
  const interestIds: string[] = useMemo(() => {
    let uuid: string[] = member?.interests?.filter(v => valideUiid(v)) || [];
    if (!uuid.length && member && member.interestsTags) {
      member.interestsTags.split(',').forEach(_url => {
        const _id = documentLinkToUuid(_url);
        if (_id && valideUiid(_id)) {
          uuid.push(_id);
        }
      });
    }
    return uuid;
  }, [member]);
  const musicGenreIds: string[] = useMemo(() => {
    let uuid: string[] = member?.musicGenre?.filter(v => valideUiid(v)) || [];
    if (!uuid.length && member && member.musicGenreTags) {
      member.musicGenreTags.split(',').forEach(_url => {
        const _id = documentLinkToUuid(_url);
        if (_id && valideUiid(_id)) {
          uuid.push(_id);
        }
      });
    }
    return uuid;
  }, [member]);

  const {data, loading} = useAllContentByListIdQuery({
    variables: {
      id: [...interestIds, ...musicGenreIds],
    },
    skip: !member,
  });

  const {interestsTags, musicGenreTags} = useMemo(() => {
    const allList: Record<string, string> = {};
    if (data && data.allContent && data.allContent.items) {
      data.allContent.items.forEach(item => {
        if (item && item.name) {
          allList[item.id] = item.name;
        }
      });
    }
    let interest: string[] = [];
    let musicGenre: string[] = [];
    if (member?.interests?.length) {
      interest = Array.from(
        new Set(
          member.interests
            .map(v => findTagItem(v)?.name || '')
            .filter(e => !!e),
        ),
      );
    }
    if (member?.musicGenre?.length) {
      musicGenre = Array.from(
        new Set(
          member.musicGenre
            .map(v => findMusicGenreItem(v)?.name || '')
            .filter(e => !!e),
        ),
      );
    }

    return {
      interestsTags: interest,
      musicGenreTags: musicGenre,
    };
  }, [data, interestIds, musicGenreIds, findTagItem]);

  const dataAccount: AccountData[] = [
    {
      label: t('First name'),
      fieldKey: 'firstName',
      value: member?.firstName || '',
      locked: !member,
    },
    {
      label: t('Last name'),
      fieldKey: 'lastName',
      value: member?.lastName || '',
      locked: !member,
    },
    {
      label: t('Email'),
      fieldKey: 'email',
      value: member?.email || '',
      locked: true,
    },
    {
      label: t('Phone'),
      fieldKey: 'phone',
      value: member?.phone || '',
      locked: !member,
    },
    {
      label: t('Password'),
      fieldKey: 'password',
      value: '•••••••••',
      locked: true,
    },
    {
      label: t('Gender'),
      fieldKey: 'gender',
      value: member?.gender || '',
      locked: true,
    },
    {
      label: t('Date of birth'),
      fieldKey: 'dateOfBirth',
      value:
        member && member.dateOfBirth
          ? new DateFactory(member.dateOfBirth).formatUtc()
          : '',
      locked: !member,
    },
    {
      label: t('Interests'),
      fieldKey: 'interestsTags',
      value: loading ? '' : interestsTags.join(', '),
      locked: false,
    },
    {
      label: t('Music Genre'),
      fieldKey: 'musicGenreTags',
      value: loading ? '' : musicGenreTags.join(', '),
      locked: false,
    },
    // TODO: add region
    // {
    //   label: t('Region'),
    //   fieldKey: 'region',
    //   value: member ? findRegionsByDocumentUrl(member.region)?.name || '' : '',
    //   locked: !member,
    //   select: regionOptions,
    // },
  ];

  const [visible, setVisible] = useState<boolean>(false);

  const showDialog = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const dispatch = useAppDispatch();

  const handleUpdateMember =
    (
      fieldKey: AccountData['fieldKey'],
      label: AccountData['label'],
      select?: AccountData['select'],
    ) =>
    () => {
      dispatch(
        appSlice.actions.setIsOpenProfilEditDrawer({
          isOpenProfilEditDrawer: true,
          data: {
            field: fieldKey,
            headerTitle: label,
            select,
          },
        }),
      );
    };
  return (
    <>
      <View
        // id="profile-my-profile-page"
        style={{
          height: '100%',
          backgroundColor: '#111216',
        }}>
        <Typography variant="h2">{t('My profile')}</Typography>
        <View
          style={{
            marginTop: Metrics.horizontalScale(20),
            marginBottom: Metrics.horizontalScale(22),
          }}>
          {dataAccount.map((d, index) => (
            <ProfileAccountItem
              key={`account_${index}`}
              isFirst={index === 0}
              isLast={index === dataAccount.length - 1}
              label={d.label}
              value={d.value}
              disabled={d.locked}
              onPress={handleUpdateMember(d.fieldKey, d.label, d.select)}
            />
          ))}
        </View>
        <Pressable
          style={{
            height: Metrics.horizontalScale(48),
            justifyContent: 'center',
          }}
          onPress={showDialog}>
          <Typography variant="body2" color="gray">
            {t('Delete my account')}
          </Typography>
        </Pressable>
      </View>
      <DialogDeleteAccount
        isOpen={visible}
        onClose={handleCancel}
        userEmail={member?.email || ''}
      />
    </>
  );
};

export default ProfileMyProfile;
