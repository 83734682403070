import useAppNavigation from '@src/utils/useAppNavigation';
import Button from 'design-system/src/Components/Button/Button';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import TextInputController from 'design-system/src/Components/Form/TextInputController/TextInputController';
import React, {useState, useRef, useEffect} from 'react';
import {useForm, ValidateResult} from 'react-hook-form';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View, StyleSheet} from 'react-native';
import {usePromoCode} from './usePromocode';
import Metrics from 'design-system/src/Theme/Metrics';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import {useLazyVerifyPromocodeQuery} from '~Api/TraceApi/traceApi';
import awesomeDebouncePromise from 'awesome-debounce-promise';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import {usePromoCodeContent} from './usePromoCodeContent';
import {IVerifyPromocodeResponse} from '@src/Api/TraceApi/endpoints/memberDataEndpoint/memberPromocode.endpoint';
import {AppRoutesEnum} from '@src/Constant/routes';

interface ISendPromoCodeForm {
  code: string;
  product?: string;
}

const PromoCodePage = () => {
  const navigation = useAppNavigation();
  const {t} = useTranslation('subscription');
  const [verifyPromocode, {isLoading: verifyPromocodeLoading}] =
    useLazyVerifyPromocodeQuery();
  const abortControllerRef = useRef<{abort: () => void} | null>(null);

  // State to track the last validated code
  const [lastValidatedCode, setLastValidatedCode] = useState('');
  const [product, setProduct] = useState<string>('');
  const [source, setSource] =
    useState<IVerifyPromocodeResponse['source']>('umbraco');

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: {errors, isValid},
    setValue,
  } = useForm<ISendPromoCodeForm>({
    mode: 'onChange',
  });

  const {
    postPromocodeLoading: loading,
    generatePaymentLink,
    handlePostPromocode,
  } = usePromoCode();
  const promoCodeContent = usePromoCodeContent();

  const {
    title,
    description,
    footerImage,
    loading: contentLoading,
    thankYouPage,
  } = promoCodeContent;

  // Add cleanup effect for component unmount
  useEffect(() => {
    return () => {
      // Abort any pending request when component unmounts
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Memoized and debounced validation function
  const debouncedValidatePromoCode = awesomeDebouncePromise(
    async (value: string) => {
      // Skip if the code is empty or hasn't changed
      if (!value || value === lastValidatedCode) return;

      try {
        // Abort any pending request
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
          abortControllerRef.current = null;
        }

        // Create a new reference to store the current request
        const queryRequest = verifyPromocode({
          code: value,
        });

        // Store the abort function for potential cancellation
        abortControllerRef.current = {
          abort: () => queryRequest.abort(),
        };

        const response = await queryRequest;

        // If the response has data and is successful
        if (response.data && response.data.isValid) {
          setLastValidatedCode(value);
          clearErrors('code');
          if (response.data.source === 'umbraco') {
            setSource('umbraco');
          } else {
            setSource('stripe');
            setProduct(response.data.products?.[0] || '');
            setValue('product', response.data.products?.[0] || '');
          }

          return true;
        }

        return t('The code entered is invalid') + '';
      } catch (error) {
        // Don't show error for aborted requests
        if (
          (error as Error).name === 'AbortError' ||
          (error as any)?.error?.status === 'ABORT_ERROR'
        ) {
          return true;
        }
        return t('Error validating code');
      }
    },
    500,
  );

  // Validation function for react-hook-form
  const validatePromoCode = async (value: string): Promise<ValidateResult> => {
    // If the value is empty, it's handled by required rule
    if (!value) return true;

    // Trigger debounced validation
    return await debouncedValidatePromoCode(value);
  };

  const handleSubmitSendCode = async (data: ISendPromoCodeForm) => {
    if (source === 'stripe') {
      const isYearly = data.product === 'trace.yearly.subscription';
      const subLink = await generatePaymentLink(
        data.code,
        isYearly ? 'y' : 'm',
      );
      if (subLink) {
        window.open(subLink, '_self');
      }
    } else {
      // umbraco
      await handlePostPromocode(data.code, async () => {
        await navigation.goBack();
        navigation.navigate(AppRoutesEnum.THANK_YOU_MODAL, {
          state: {
            params: {
              title: thankYouPage?.thankYouTitle,
              body: thankYouPage?.thankYouBody,
              cover: thankYouPage?.thankYouCover,
            },
          },
        });
      });
    }
  };

  return (
    <View style={{flex: 1}}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .router {
            display: flex ;
            flex-direction: column;
            height: unset !important;
          }
        `,
        }}
      />
      <Typography
        variant="h2"
        style={{
          marginBottom: 24,
        }}>
        {title}
      </Typography>
      {description && (
        <Typography
          variant="body2"
          style={{
            marginBottom: 61,
          }}>
          {description}
        </Typography>
      )}
      {contentLoading ? (
        <CustomActivityIndicator
          style={{
            flex: 1,
          }}
        />
      ) : (
        <View style={[PromoCodePageStyles.content]}>
          {/* @ts-ignore */}
          <View style={{flex: 1, gap: 16, marginBottom: 32}}>
            <View style={PromoCodePageStyles.formWrapper}>
              <TextInputController
                name="product"
                control={control}
                style={{display: 'none'}}
                value={product}
              />
              <TextInputController
                name="code"
                label={t('Promo code') + ''}
                rules={{
                  required: t('code required') || 'required',
                  validate: validatePromoCode,
                }}
                control={control}
                clearable={true}
                variant="transparent"
                error={errors.code?.message}
              />
              {/* {!!isSubmitSuccessful && (
              <Typography variant="body2">{t('Email sent!')}</Typography>
            )} */}
            </View>
            <View style={[PromoCodePageStyles.buttonWrapper]}>
              <Button
                variant={isValid ? 'contained' : 'disabled'}
                size="medium"
                title={t('redeem')}
                fullWidth
                loading={loading || verifyPromocodeLoading}
                disabled={!isValid}
                onPress={handleSubmit(handleSubmitSendCode)}
              />
            </View>
          </View>

          {footerImage && (
            <View
              style={{
                width: '100%',
                aspectRatio: 16 / 9,
                position: 'relative',
                borderRadius: 4,
                overflow: 'hidden',
                marginTop: 'auto',
              }}>
              <CustomFastImage
                source={{uri: footerImage}}
                width={'100%'}
                height={'100%'}
                style={{width: '100%', height: '100%'}}
                wrapperStyles={{
                  width: '100%',
                  aspectRatio: 16 / 9,
                }}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export const PromoCodePageStyles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    paddingBottom: Metrics.verticalScale(50),
    paddingHorizontal: Metrics.horizontalScale(24),
    paddingVertical: Metrics.verticalScale(20),
    height: '100%',
  },
  formWrapper: {
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonWrapper: {
    // bottom: 34,
    alignSelf: 'stretch',
  },
});

export default PromoCodePage;
