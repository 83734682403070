import defaultTheme from 'design-system/src/Theme/defaultTheme';
import styled from 'styled-components';

export const _CustomModalRoot = styled.div<{$backgroundImage?: string}>(
  ({$backgroundImage}) => {
    return {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
      width: '100%',
      height: '100%',
      display: 'grid',
      backgroundColor: defaultTheme.page.background,
      background: ($backgroundImage && `url(${$backgroundImage})`) || undefined,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      zIndex: 1,
    };
  },
);
