import React, {Suspense} from 'react';
import FloatingMenu from './HeroServices/FloatingMenu/FloatingMenu';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {RouteTypename} from '@src/Navigation/type';
import useNavigation from '@src/Navigation/useNavigation';
import {useDispatch} from 'react-redux';
import {IButton} from '@src/Umbraco/Hooks/useElementRenderer';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useGetFooter from '@src/Hooks/useGetFooter';
import {mapButtonOnBuild} from '@src/Umbraco/Utils/content/mapButtonOnBuild';
import {useStringExtension} from '@src/Hooks/useStringExtension';

const VideoBlock = React.lazy(
  () => import('design-system/src/Components/VideoBlock/VideoBlock.web'),
);

const LogoSlider = React.lazy(() => import('./LogoSlider/LogoSlider'));
const CTASlider = React.lazy(() => import('./CTASlider/CTASlider'));
const MultiSlider = React.lazy(() => import('./MultiSlider/MultiSlider'));
const KeyFigures = React.lazy(() => import('./KeyFigures/KeyFigures'));
const StatisticInfo = React.lazy(
  () => import('design-system/src/Components/StatisticInfo/StatisticInfo'),
);
const ImageGallerie = React.lazy(
  () => import('design-system/src/WebComponent/ImageGallerie/ImageGallerie'),
);
const ServiceHeader = React.lazy(
  () =>
    import(
      'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web'
    ),
);
const HeroServices = React.lazy(() => import('./HeroServices/HeroServices'));
const CardList = React.lazy(() => import('./CardList/CardList'));
const AboutTrace = React.lazy(() => import('./AboutTrace/AboutTrace'));
const ContactBlock = React.lazy(
  () => import('design-system/src/Components/ContactBlock/ContactBlock.web'),
);
const PageFooter = React.lazy(
  () => import('@src/components/general/PageFooter/PageFooter'),
);
const HeroBanner1 = React.lazy(() => import('./HeroBanner1/HeroBanner1'));

interface IHomePageProps {
  item?: Queries.LandingPageQuery['umbraco']['allLandingPage']['items'][0];
}

const HomePage = (props: IHomePageProps) => {
  const {item} = props;

  if (!item) return null;

  const cover = item.cover;

  const {isMobile} = useBreakpoint();

  const footerData = useGetFooter();
  const {formatFromHtml} = useStringExtension();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const buttonHeaderData = item.cTA
    ? mapButtonOnBuild(item.cTA[0]?.content as Queries.Umbraco_Button)
    : undefined;

  const handleButtonHeader = async (item: IButton | undefined) => {
    if (item) {
      new RouteFactory(
        {
          id: item.id || '',
          __typename: item.__typename as RouteTypename,
          url: item.url || '',
        },
        navigation,
        dispatch,
      ).navigate();
    }
  };
  return (
    <div style={{position: 'relative'}}>
      <FloatingMenu data={item} />
      <Suspense>
        <ServiceHeader
          key={'header'}
          aspectRatio={isMobile ? 375 / 500 : 1920 / 823}
          title={item.title ?? ''}
          justifyContent={'center'}
          gradiantVariant="none"
          disableOverlay={true}
          image={{
            uri:
              cover &&
              cover[0] &&
              cover[0].content.contentTypeAlias === 'heroVideoCover'
                ? (cover[0].content as Queries.HeroVideoCoverFragmentFragment)
                    .image?.url || ''
                : '',
          }}
          desktopImage={{
            uri:
              cover &&
              cover[0] &&
              cover[0].content.contentTypeAlias === 'heroVideoCover'
                ? (cover[0].content as Queries.HeroVideoCoverFragmentFragment)
                    .desktopImage?.url || ''
                : '',
          }}
          description={item.subtitle ?? ''}
          buttonTitle={buttonHeaderData?.buttonProps?.title ?? ''}
          buttonProps={{
            ...buttonHeaderData?.buttonProps,
            size: 'small',
          }}
          onPressButton={() => {
            handleButtonHeader(buttonHeaderData);
          }}
        />
      </Suspense>

      {item.pageBlocks?.map((pageBlock, index) => {
        if (!pageBlock) {
          return null;
        }
        switch (pageBlock.content.contentTypeAlias) {
          case 'gallery':
            const gallery = pageBlock.content as Queries.Umbraco_Gallery;
            return (
              <Suspense
                key={`${index}-gallery`}
                fallback={<div style={{minHeight: 320}}></div>}>
                <ImageGallerie
                  key={`${index}-gallery`}
                  overTitle={gallery.overTitle ?? ''}
                  title={gallery.title ?? ''}
                  description={gallery.intro ?? ''}
                  galeries={
                    gallery.gallery?.map(e => ({url: e?.cropUrl ?? ''})) ?? []
                  }
                />
              </Suspense>
            );

          case 'heroServices':
            const heroServices =
              pageBlock.content as Queries.Umbraco_HeroServices;
            const services =
              heroServices.services?.map(e => {
                const content = e?.content as Queries.Umbraco_CardIcon;

                return {
                  title: content?.title ?? '',
                  description: content?.description ?? '',
                  icon: content?.icon ?? '',
                };
              }) ?? [];
            const buttonContent = heroServices?.cta
              ? mapButtonOnBuild(
                  heroServices.cta[0]?.content as Queries.Umbraco_Button,
                )
              : undefined;
            return (
              <Suspense
                key={`${index}-heroServices`}
                fallback={
                  <div style={{width: '100%', aspectRatio: 21 / 9}}></div>
                }>
                <HeroServices
                  id={heroServices.navigationTitle ?? ''}
                  key={`${index}-heroServices`}
                  overTitle={heroServices.overtitle ?? ''}
                  title={heroServices.title ?? ''}
                  description={heroServices.description ?? ''}
                  image={heroServices.image?.url ?? ''}
                  services={services}
                  button={
                    buttonContent
                      ? {
                          icon: buttonContent.buttonProps?.icon ?? '',
                          title: buttonContent.buttonProps?.title ?? '',
                          id: '',
                          linkTo: buttonContent.url,
                        }
                      : undefined
                  }
                />
              </Suspense>
            );

          case 'cardList':
            const cardList = pageBlock.content as Queries.Umbraco_CardList;
            const cards =
              cardList.cards?.map(e => {
                const content = e?.content as Queries.Umbraco_Card;
                return {
                  title: content?.title ?? '',
                  description: content?.description ?? '',
                  image: content?.image?.url ?? '',
                };
              }) ?? [];

            return (
              <Suspense
                key={`${index}-cardList`}
                fallback={
                  <div style={{width: '100%', aspectRatio: 16 / 9}}></div>
                }>
                <CardList
                  key={`${index}-cardList`}
                  cards={cards}
                  isTakeFirst={true}
                />
              </Suspense>
            );

          case 'landingAbout':
            const landingAbout =
              pageBlock.content as Queries.Umbraco_LandingAbout;

            const buttonContentAbout = landingAbout?.cTA
              ? mapButtonOnBuild(
                  landingAbout.cTA[0]?.content as Queries.Umbraco_Button,
                )
              : undefined;

            const cardsAbout =
              landingAbout.cards?.map(e => {
                const content = e?.content as Queries.Umbraco_Card;
                return {
                  title: content?.title ?? '',
                  description: content?.description ?? '',
                  image: content?.image?.url ?? '',
                };
              }) ?? [];

            return (
              <Suspense
                key={`${index}-aboutTrace`}
                fallback={
                  <div style={{width: '100%', aspectRatio: 16 / 9}}></div>
                }>
                <AboutTrace
                  key={`${index}-aboutTrace`}
                  title={landingAbout.title ?? ''}
                  description={landingAbout.subtitle ?? ''}
                  cards={cardsAbout}
                  button={
                    buttonContentAbout
                      ? {
                          icon: buttonContentAbout.buttonProps?.icon ?? '',
                          title: buttonContentAbout.buttonProps?.title ?? '',
                          id: '',
                          linkTo: buttonContentAbout.url,
                        }
                      : undefined
                  }
                />
              </Suspense>
            );
          case 'heroBanner':
            const heroBanner = pageBlock.content as Queries.Umbraco_HeroBanner;

            const {
              title,
              subtitle = '',
              backgroundImage,
              backgroundImageMobile,
              cTA,
            } = heroBanner;

            const cTAData = cTA
              ? mapButtonOnBuild(cTA[0]?.content as Queries.Umbraco_Button)
              : undefined;

            return (
              <Suspense
                key={`${index}-heroBanner`}
                fallback={
                  <div style={{width: '100%', aspectRatio: 16 / 9}}></div>
                }>
                <ContactBlock
                  key={`${index}-heroBanner`}
                  title={title || ''}
                  description={subtitle ?? ''}
                  buttonTitle={cTAData?.buttonProps?.title ?? '-'}
                  buttonVariant="outlined"
                  image={
                    (backgroundImage && {
                      uri: backgroundImage?.url ?? '',
                    }) ||
                    undefined
                  }
                  imageMobile={
                    (backgroundImageMobile && {
                      uri: backgroundImageMobile?.url ?? '',
                    }) ||
                    undefined
                  }
                  hideButton={!cTAData}
                  onClick={() => {
                    if (cTAData) {
                      handleButtonHeader(cTAData);
                    }
                  }}
                />
              </Suspense>
            );

          case 'heroBanner1':
            const heroBanner1 =
              pageBlock.content as Queries.Umbraco_HeroBanner1;

            const cTADataBanner1 = heroBanner1.cTA
              ? mapButtonOnBuild(
                  heroBanner1.cTA[0]?.content as Queries.Umbraco_Button,
                )
              : undefined;

            return (
              <Suspense key={`${index}-heroBanner1`}>
                <HeroBanner1
                  key={`${index}-heroBanner1`}
                  title={heroBanner1.title || ''}
                  subtitle={heroBanner1.subtitle ?? ''}
                  imageDesktop={heroBanner1.backgroundImage?.url ?? ''}
                  imageMobile={heroBanner1.backgroundImageMobile?.url ?? ''}
                  onClickButton={() => {
                    if (cTADataBanner1) {
                      handleButtonHeader(cTADataBanner1);
                    }
                  }}
                  template={heroBanner1.template ?? 'center'}
                  button={
                    cTADataBanner1
                      ? {
                          icon: cTADataBanner1.buttonProps?.icon ?? '',
                          title: cTADataBanner1.buttonProps?.title ?? '',
                        }
                      : undefined
                  }
                />
              </Suspense>
            );

          case 'wysiwyg':
            const {title: _title, body} =
              pageBlock.content as Queries.Umbraco_Wysiwyg;
            return (
              <Suspense key={`${index}-wysiwyg`}>
                <StatisticInfo
                  key={`${index}-wysiwyg`}
                  title={_title ?? '-'}
                  description={formatFromHtml(body)}
                />
              </Suspense>
            );

          case 'keyFigures':
            return (
              <Suspense key={`${index}-keyFigures`}>
                <KeyFigures
                  key={`${index}-keyFigures`}
                  content={pageBlock.content as Queries.Umbraco_KeyFigures}
                />
              </Suspense>
            );

          case 'multiSlider':
            return (
              <Suspense key={`${index}-multiSlider`}>
                <MultiSlider
                  key={`${index}-multiSlider`}
                  content={pageBlock.content as Queries.Umbraco_MultiSlider}
                />
              </Suspense>
            );
          case 'CTASlider':
            return (
              <Suspense key={`${index}-cTASlider`}>
                <CTASlider
                  key={`${index}-cTASlider`}
                  content={
                    pageBlock.content as Queries.CTASliderFragmentFragment
                  }
                />
              </Suspense>
            );

          case 'logoSlider':
            return (
              <Suspense key={`${index}-logoSlider`}>
                <LogoSlider
                  key={`${index}-logoSlider`}
                  content={
                    pageBlock.content as Queries.LogoSliderFragmentFragment
                  }
                />
              </Suspense>
            );

          // case 'heroBanner':
          //   const {
          //     title,
          //     subtitle = '',
          //     backgroundImage,
          //     backgroundImageMobile,
          //     cTA,
          //   } = block.content;
          //   let cTAData: IButton | undefined;
          //   if (cTA && cTA[0] && cTA[0].content.__typename === 'Button') {
          //     let buttonContent = cTA[0].content as IButtonFragmentFragment;
          //     cTAData = mapButton(buttonContent);
          //   }
          //   console.log('--cTAData', cTAData, cTA);
          //   pageBlocks.push(
          //     <ContactBlock
          //       key={blockIndex}
          //       title={title || ''}
          //       description={subtitle}
          //       buttonTitle={cTAData?.buttonProps?.title ?? '-'}
          //       image={
          //         (backgroundImage && {
          //           uri: backgroundImage?.url,
          //         }) ||
          //         undefined
          //       }
          //       imageMobile={
          //         (backgroundImageMobile && {
          //           uri: backgroundImageMobile?.url,
          //         }) ||
          //         undefined
          //       }
          //       hideButton={!cTAData}
          //       onClick={() => {
          //         if (cTAData) {
          //           handleButtonHeader(cTAData);
          //         }
          //       }}
          //     />,
          //   );
          //   break;

          case 'heroVideoCover':
            const {image, desktopImage, vimeoHlsUrl, vimeoVideoId} =
              pageBlock.content as Queries.HeroVideoCoverFragmentFragment;
            if (vimeoHlsUrl || vimeoVideoId) {
              // TODO get video url
              return (
                <Suspense key={`${index}-heroVideoCover`}>
                  <VideoBlock
                    key={`${index}-heroVideoCover`}
                    src={vimeoHlsUrl || ''}
                    vimeoId={vimeoVideoId + ''}
                    width="100%"
                    height="auto"
                    poster={image?.url || ''}
                    posterDesktop={desktopImage?.url || ''}
                  />
                </Suspense>
              );
            }
            return null;

          default:
            return <span>{pageBlock.content.contentTypeAlias}</span>;
        }
      })}
      <Suspense>
        <PageFooter {...footerData} />
      </Suspense>
    </div>
  );
};

export default HomePage;
