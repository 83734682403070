import {View} from 'react-native';
import React from 'react';
import {FormColumn} from '~Api/Umbraco/interface/UmbracoTypes';
import UmbracoFormField from './UmbracoFormField/UmbracoFormField';

interface IUmbracoFormColumnProps {
  column: FormColumn;
  index: number;
}

const UmbracoFormColumn = (props: IUmbracoFormColumnProps) => {
  const {
    column: {fields},
    index,
  } = props;
  const total = fields.length;
  return (
    <View style={{position: 'relative', zIndex: index}}>
      {fields.map((field, index) => {
        return (
          <UmbracoFormField field={field} key={index} index={total - index} />
        );
      })}
    </View>
  );
};

export default React.memo(UmbracoFormColumn);
