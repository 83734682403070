import {combineReducers} from '@reduxjs/toolkit';
import {freeApi} from '@src/Api/CommonApi/commonApi';
import {traceApi} from '@src/Api/TraceApi/traceApi';
import {contentManagmentApi} from '@src/Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import {umbracoCdnApi} from '@src/Api/Umbraco/ContentManagmentApi/UmbracoCdnApi/umbracoCdnApi';
import appSlice from './Slices/AppSlice';
import channelSlice from './Slices/Channel.slice';
import languageSlice from './Slices/LanguageSlice/Language.slice';
import layoutSlice from './Slices/LayoutSlice/Layout.slice';
import liveStreamSlice from './Slices/LiveStream.slice';
import regionSlice from './Slices/Region.slice';
import vimeoSlice from './Slices/Vimeo/Vimeo.slice';
import appAuthSlice from './Slices/AppSlice/auth.slice';
import memberSlice from './Slices/Member.slice';
import pageSlice from './Slices/PageSlice';
import subscriptionSlice from './Slices/Subscription.slice';
import settingsSlice from './Slices/Settings.slice';
import appListenSlice from './Slices/AppSlice/listen.slice';
import appPodcastSlice from './Slices/AppSlice/podcast.slice';
import courseUnitSlice from './Slices/CourseUnitSlice/CourseUnit.slice';
import snackbarSlice from './Slices/SnackbarSlice/Snackbar.slice';
import tabBarSlice from './Slices/TabBarSlice/TabBar.slice';
import quizSlice from './Slices/Quiz.slice';

const rootReducer = combineReducers({
  [contentManagmentApi.reducerPath]: contentManagmentApi.reducer,
  [traceApi.reducerPath]: traceApi.reducer,
  [freeApi.reducerPath]: freeApi.reducer,
  [umbracoCdnApi.reducerPath]: umbracoCdnApi.reducer,
  [appSlice.name]: appSlice.reducer,
  [languageSlice.name]: languageSlice.reducer,
  [vimeoSlice.name]: vimeoSlice.reducer,
  [liveStreamSlice.name]: liveStreamSlice.reducer,
  [channelSlice.name]: channelSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [regionSlice.name]: regionSlice.reducer,
  [appAuthSlice.name]: appAuthSlice.reducer,
  [memberSlice.name]: memberSlice.reducer,
  [pageSlice.name]: pageSlice.reducer,
  [subscriptionSlice.name]: subscriptionSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [appListenSlice.name]: appListenSlice.reducer,
  [appPodcastSlice.name]: appPodcastSlice.reducer,
  [courseUnitSlice.name]: courseUnitSlice.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [tabBarSlice.name]: tabBarSlice.reducer,
  [quizSlice.name]: quizSlice.reducer,
});

export default rootReducer;
