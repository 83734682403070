import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import CardIconText, {
  CardIconTextProps,
} from '../../../Components/Card/CardIconText/CardIconText';
import Typography from '../../../Components/Typography/Typography';
import {devicesStyles} from './PayWallDevices.styles';

export interface IItemDevices {
  icon: CardIconTextProps['icon'];
  text: string;
}

export interface PayWallDevicesProps {
  items: Array<IItemDevices>;
  title: string;
  onItemPress?: (item: IItemDevices) => void;
  contentWrapper?: StyleProp<ViewStyle>;
}

const PayWallDevices = (props: PayWallDevicesProps) => {
  const {title, items, contentWrapper, onItemPress} = props;

  return (
    <View style={devicesStyles.container}>
      <Typography
        children={title.toUpperCase()}
        variant="h2"
        style={devicesStyles.title}
      />
      <View style={[devicesStyles.gridContainer, contentWrapper]}>
        {items.map((item, index) => {
          return (
            <View key={index} style={devicesStyles.item}>
              <CardIconText
                onPress={() => {
                  onItemPress && onItemPress(item);
                }}
                icon={item.icon}
                text={item.text}
              />
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default PayWallDevices;
