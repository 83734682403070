import {useAppDispatch} from '@src/Store/hooks';
import {useCallback} from 'react';
import {useLazyGetAllMemberObtainedJobQuery} from '~Api/TraceApi/traceApi';
import memberSlice from '~Store/Slices/Member.slice';

export const useMemberObtainedJob = () => {
  const [fetchObtainJob] = useLazyGetAllMemberObtainedJobQuery();
  const dispatch = useAppDispatch();
  const handleFetchObtainedJob = useCallback(() => {
    fetchObtainJob().then(({data: jobData}) => {
      if (jobData) {
        dispatch(memberSlice.actions.setObtainedJob(jobData.data));
      }
    });
  }, [fetchObtainJob]);
  return {handleFetchObtainedJob};
};
