import {SiemaOptions} from 'siema';
import styled, {css} from 'styled-components';
import FlexBox from '../FlexBox/FlexBox.web';

export const _CarouselHeaderWrapper = styled(FlexBox)<{}>(({}) => {
  return {
    // display: 'flex',
    width: '100%',
  };
});

export const _CarouselRoot = styled('div')<{}>(({}) => {
  return {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  };
});

export const _CarouselWrapper = styled('div')<{
  $gap?: number;
  $sliderInitialized?: boolean;
  $totalPage?: number;
  $perPage?: SiemaOptions['perPage'];
}>(({$gap = 0, $sliderInitialized, $perPage = 4}) => {
  return css`
    // overflow: visible !important;
    // position: relative;
  `;
});
