import useMember from '@src/Store/selectors/useMember';
import {useEffect, useState} from 'react';
import {useCountMemberUnitProgressionLazyQuery} from '~Api/Graphql/gql/types.generated';
import {useLazyGetAllUnitProgressionQuery} from '~Api/TraceApi/traceApi';

export interface ICountCourse {
  started: number;
  finished: number;
}

export const useCountUnitProgression = () => {
  const member = useMember();
  const [fetchUnitProgression, {isLoading: loading}] =
    useLazyGetAllUnitProgressionQuery();
  const [countCourse, setCountCourse] = useState<ICountCourse>({
    started: 0,
    finished: 0,
  });
  useEffect(() => {
    fetchUnitProgression().then(({data}) => {
      if (data) {
        let _startedCounter = 0;
        let _finishedCounter = 0;
        data.forEach(item => {
          if (item) {
            const {finished} = item;

            if (finished) {
              _finishedCounter += 1;
            } else {
              _startedCounter += 1;
            }
          }
        });
        setCountCourse({
          started: _startedCounter,
          finished: _finishedCounter,
        });
      }
    });
  }, [member, fetchUnitProgression]);

  return {
    loading,
    countCourse,
  };
};
