import {useJobTrainingByUrlEndQuery} from '@src/Api/Graphql/gql/types.generated';

const useSingleJobFetch = (endUrl: string) => {
  const {data, loading} = useJobTrainingByUrlEndQuery({
    variables: {
      urlEnd: endUrl,
    },
    skip: !endUrl,
  });
  const jobTraining = data?.allJobTraining.items[0];
  return {
    loading,
    jobTraining,
  };
};

export default useSingleJobFetch;
