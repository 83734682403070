import Metrics from "../../Theme/Metrics";

export const channelLogoSizeStypes = {
  large: {
    width: Metrics.horizontalScale(64),
    height: Metrics.verticalScale(45),
  },
  medium: {
    width: Metrics.horizontalScale(47),
    height: Metrics.verticalScale(36),
  },
  small: {
    width: Metrics.horizontalScale(40),
    height: Metrics.verticalScale(28),
  },
};
