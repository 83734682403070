import {AppRoutesEnum} from '@src/Constant/routes';
import {appNavigate} from './navigationUtils';
import {navigate} from 'gatsby';
import {useDispatch} from 'react-redux';
import appSlice from '@src/Store/Slices/AppSlice';
import {IAppHeaderOptions} from '@src/layout/AppLayout/AppHeader/AppHeader';
import pageSlice from '@src/Store/Slices/PageSlice';

const useAppNavigation = () => {
  const dispatch = useDispatch();
  const drawer = (to: AppRoutesEnum) => {
    appNavigate(to, {
      state: {
        type: 'drawer',
      },
    });
  };
  const modal = (to: AppRoutesEnum) => {
    appNavigate(to, {
      state: {
        type: 'modal',
      },
    });
  };

  const setOptions = (options?: IAppHeaderOptions) => {
    dispatch(appSlice.actions.setHeaderOptions(options));
  };
  const setModalBackground = (options?: boolean | string) => {
    dispatch(appSlice.actions.setModalBackground(options));
  };

  return {
    drawer,
    modal,
    navigate: ((...args) => {
      dispatch(pageSlice.actions.setParams({}));
      dispatch(appSlice.actions.setModalHeader(null));
      setOptions(undefined);
      return appNavigate(...args);
    }) as typeof appNavigate,
    goBack: (nb?: number) => {
      const nbr = nb && isNaN(nb) ? 1 : nb;
      // reset params after back action
      dispatch(pageSlice.actions.setParams({}));
      dispatch(appSlice.actions.setModalHeader(null));
      setOptions(undefined);
      return navigate(nbr ? -nbr : -1);
    },
    setOptions,
    setModalBackground,
    setParams: (params: Record<string, any>) => {
      dispatch(pageSlice.actions.setParams(params));
    },
  };
};

export default useAppNavigation;
