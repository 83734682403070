/* eslint-disable react-native/no-inline-styles */
import Button from 'design-system/src/Components/Button/Button';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {IconName} from 'design-system/src/assets/Icones';
import React, {FC, useMemo} from 'react';

interface HeroBanner1Props {
  title?: string;
  subtitle?: string;
  imageDesktop?: string;
  imageMobile?: string;
  template?: string;
  button?: {
    title?: string;
    icon?: string;
  };
  onClickButton: () => void;
}

const HeroBanner1: FC<HeroBanner1Props> = ({
  title,
  subtitle,
  imageDesktop,
  imageMobile,
  template,
  button,
  onClickButton,
}) => {
  const {isMobile} = useBreakpoint();
  const justifyContent = useMemo(() => {
    switch (template) {
      case 'center':
        return 'center';
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';

      default:
        return 'center';
    }
  }, [template]);

  const textAlign = useMemo(() => {
    switch (template) {
      case 'center':
        return 'center';
      case 'left':
        return 'left';
      case 'right':
        return 'right';

      default:
        return 'center';
    }
  }, [template]);

  const aspectRatio = useMemo(() => {
    if (isMobile) {
      return 3 / 4;
    }
    return 21 / 9;
  }, [isMobile]);

  return (
    <div
      style={{
        // backgroundImage: `url(${isMobile ? imageMobile : imageDesktop})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent,
        // alignItems: isMobile ? 'flex-end' : 'center',
        // padding: isMobile ? '32px 16px' : '0 60px',
        aspectRatio: aspectRatio,
        position: 'relative',
      }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
        }}>
        <CustomFastImage
          source={{uri: isMobile ? imageMobile : imageDesktop}}
          width="100%"
          height="100%"
          isNotSetContainerHeight={true}
          wrapperStyles={{
            aspectRatio: aspectRatio,
            width: '100%',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent,
          alignItems: isMobile ? 'flex-end' : 'center',
          padding: isMobile ? '32px 16px' : '0 60px',
          aspectRatio: aspectRatio,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: justifyContent,
            width: isMobile ? '100%' : '33%',
          }}>
          {!!title && (
            <Typography
              variant={isMobile ? 'big3' : 'formTitle'}
              style={{
                marginBottom: 8,
                textAlign,
              }}>
              {title}
            </Typography>
          )}
          {!!subtitle && (
            <Typography
              variant="body2"
              style={{
                margin: '8px 0',
                textAlign,
              }}>
              {subtitle}
            </Typography>
          )}
          {button && (
            <Button
              variant="contained"
              title={button.title ?? ''}
              size="small"
              icon={
                button.icon
                  ? (button.icon.toLowerCase() as IconName)
                  : undefined
              }
              iconStyle={{}}
              onPress={onClickButton}
              style={{
                marginTop: 8,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroBanner1;
