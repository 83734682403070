import React, {PropsWithChildren, useCallback} from 'react';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';

import ShowMoreText from 'react-show-more-text';
import styled from 'styled-components';
import Typography from '../Typography/Typography';
import {ITypographyProps} from '../Typography/Typography.props';
import {useTranslation} from 'gatsby-plugin-react-i18next';

export type ReadMoreTextProps = {
  text: string;
  style?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  numberOfLines?: number;
  width?: number;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  color?: ITypographyProps['color'];
  variant?: ITypographyProps['variant'];
  endTextColor?: () => string;
};

const RootTypo = styled(Typography)<{$color?: string}>(({$color}) => {
  return {
    br: {
      content: '" "',
      padding: 2,
    },
    '.show-more-less-clickable': {
      color: $color,
      cursor: 'pointer',
    },
  };
});

const ReadMoreText = (props: PropsWithChildren<ReadMoreTextProps>) => {
  const {
    text,
    numberOfLines = 4,
    seeMoreLabel,
    seeLessLabel,
    style,
    color = 'gray',
    variant = 'body3',
    width,
    endTextColor,
  } = props;
  const {t} = useTranslation('translation');
  const seeMoreLabelDefault = t('See more');
  const seeLessLabelDefault = t('See less');

  const _renderTruncatedFooter = useCallback(() => {
    return (
      <>
        <Typography
          children={seeMoreLabel || seeMoreLabelDefault}
          color={endTextColor}
          variant="body3"
          style={{padding: 13, margin: -13}}
        />
      </>
    );
  }, [seeMoreLabel, seeMoreLabelDefault]);

  const _renderRevealedFooter = useCallback(() => {
    return (
      <Typography
        children={seeLessLabel || seeLessLabelDefault}
        color={endTextColor}
        variant="body3"
        style={{padding: 13, margin: -13}}
      />
    );
  }, [seeLessLabel, seeLessLabelDefault]);

  return (
    <RootTypo
      style={style}
      color={color}
      $color={endTextColor?.() || ''}
      variant={variant}>
      <ShowMoreText
        lines={numberOfLines}
        more={_renderTruncatedFooter()}
        less={_renderRevealedFooter()}
        expanded={false}
        width={width}
        truncatedEndingComponent={'  '}>
        {text}
      </ShowMoreText>
    </RootTypo>
  );
};

export default ReadMoreText;
