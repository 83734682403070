import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useMemo} from 'react';
import {ScrollView, View} from 'react-native';
import {useEntrepreneurialHubByUrlEndQuery} from '~Api/Graphql/gql/types.generated';
import CourseSlider, {
  ICourseSliderItem,
} from '~Umbraco/Components/CourseSlider/CourseSlider';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {useMapCourseCateg} from '~Umbraco/Utils/content/mapCourseCategItems';
import {useMapCardLink} from './useMapCardLink';
import TipsCard, {ITipsCardProps} from './components/TipsCard/TipsCard';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import useParams from '@src/Hooks/useParams';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';
import {useIsListenMiniPlayer} from '@src/Store/selectors/useMember';
import useAppNavigation from '@src/utils/useAppNavigation';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

const JobBoardEntrepreneurial = () => {
  const params = useParams();

  const {endUrl} = params || {};
  const response = useEntrepreneurialHubByUrlEndQuery({
    variables: {
      endUrl,
    },
    skip: !endUrl,
  });
  const loading = response.loading;
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();
  const {mapCardLink} = useMapCardLink();
  const {mapCourseCategItems} = useMapCourseCateg();

  const {
    profile: {cover},
  } = useAppSelector(state => state.settings);

  const {isMobile} = useBreakpoint();

  const isMiniPlayer = useIsListenMiniPlayer();

  const hub = response?.data?.allEntrepreunarialHub?.items[0];

  const {
    title,
    courseItems,
    courseTitle,
    tipsCard,
  }: {
    title?: string;
    courseItems: ICourseSliderItem[];
    courseTitle?: string;
    tipsCard: ITipsCardProps[];
  } = useMemo(() => {
    let _courseItems: ICourseSliderItem[] = [];
    let _courseTitle: string | undefined;
    let _tipsCard: ITipsCardProps[] = [];
    if (hub && hub.__typename === 'EntrepreunarialHub') {
      const {hubTitle, hubCourses, tips} = hub;
      if (
        hubCourses &&
        hubCourses.__typename === 'CourseCategory' &&
        hubCourses.children &&
        hubCourses.children.items
      ) {
        const {name} = hubCourses;
        const items = mapCourseCategItems(hubCourses.children.items);
        _courseTitle = name;
        _courseItems = items;
      }
      if (tips) {
        tips.forEach(tip => {
          if (tip && tip.content && tip.content.__typename === 'CardLink') {
            const card = mapCardLink(tip.content);
            _tipsCard.push(card);
          }
        });
      }
      return {
        title: hubTitle,
        courseItems: _courseItems,
        courseTitle: _courseTitle,
        tipsCard: _tipsCard,
      };
    }
    return {
      courseItems: _courseItems,
      tipsCard: _tipsCard,
      mapCourseCategItems,
    };
  }, [hub]);

  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        width: '100%',
        backgroundColor: '#111216',
        height: '100%',
      }}>
      <DebugUmbracoId umbracoId={hub?.id} position="page" />
      {!isMobile && (
        <div
          style={{
            aspectRatio: 1440 / 642,
            position: 'absolute',
            zIndex: 0,
            backgroundImage: `url(${cover})`,
            backgroundSize: 'cover',
            width: '100%',
          }}>
          <div
            style={{
              background:
                'linear-gradient(180deg, rgba(17, 18, 22, 0.00) 44.86%, rgba(17, 18, 22, 0.80) 71.63%, #111216 100%)',
              width: '100%',
              height: '100%',
            }}></div>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          backgroundColor: '#00000066',
        }}>
        {loading ? (
          <CustomActivityIndicator style={{flex: 1}} />
        ) : (
          <>
            <div
              style={{
                paddingTop: 138,
                paddingBottom: 95,
                display: 'flex',
                width: '100%',
                justifyContent: isMobile ? 'flex-start' : 'center',
                padding: isMobile ? '120px 16px 30px' : '138px 60px 95px',
              }}>
              <Typography
                style={{
                  fontSize: isMobile ? 32 : 64,
                  lineHeight: isMobile ? 32 : 62,
                }}
                children={title || ''}
                variant={'h2'}
              />
            </div>
            <ScrollView
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}>
              {courseItems.length !== 0 && (
                <CourseSlider
                  data={courseItems}
                  title={courseTitle || ''}
                  onItemPress={item => {
                    const {id, url, __typename} = item;
                    const factory = new RouteFactory(
                      {url: url || '', __typename, id: id || ''},
                      navigation,
                      dispatch,
                    );
                    factory.navigate();
                  }}
                />
              )}
              <View
                style={{
                  paddingHorizontal: Metrics.horizontalScale(
                    isMobile ? 16 : 60,
                  ),
                  paddingBottom: Metrics.verticalScale(isMobile ? 100 : 20),
                }}>
                <AppGrid nbColumns={isMobile ? 1 : 3} columnGap={12}>
                  {tipsCard.length !== 0 &&
                    tipsCard.map((tipCard, index) => (
                      <TipsCard key={`tp-${index}`} {...tipCard} />
                    ))}
                </AppGrid>
              </View>
            </ScrollView>
          </>
        )}
        {isMiniPlayer && <div style={{height: 80}} />}
      </div>
    </View>
  );
};

export default JobBoardEntrepreneurial;
