import clsx from 'clsx';
import React, {CSSProperties, ReactNode} from 'react';
import CustomFastImage from '../../../Components/CustomFastImage/CustomFastImage.web';
import {CustomFastImageProps} from '../../../Components/CustomFastImage/CustomFastImageProps';
import AcqCategoryWidgetContext from '../AcqCategoryWidgetContext';
import {
  _AcqCategoryItemImage,
  _AcqCategoryItemRoot,
} from './AcqCategoryItem.styled';

export type IAcqCategoryItemSize = 'small' | 'large';
export interface IAcqCategoryItemProps {
  image?: CustomFastImageProps['source'];
  size?: IAcqCategoryItemSize;
  disabled?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  onMouseEnter?: () => void;
  children?: ReactNode;
  aspectRatio: number;
}

const AcqCategoryItem = (props: IAcqCategoryItemProps) => {
  const {
    size = 'small',
    disabled = false,
    style,
    onClick,
    onMouseEnter,
    children,
    image,
    aspectRatio = 1,
  } = props;
  const {mapSize} = React.useContext(AcqCategoryWidgetContext);
  const itemWidth = '100%';
  const itemHeight = 'auto';

  return (
    <_AcqCategoryItemRoot
      $size={mapSize[size]}
      $aspectRatio={aspectRatio}
      style={style}
      className={clsx({disabled: disabled}, size)}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
      <_AcqCategoryItemImage
        className={clsx('category-image', {disabled: disabled})}
        style={{
          width: itemWidth,
          height: 'auto',
          aspectRatio,
        }}>
        <CustomFastImage
          source={image}
          width={itemWidth}
          height={itemHeight}
          radius={4}
          aspectRatio={aspectRatio}
          wrapperStyles={{
            aspectRatio: aspectRatio,
          }}
          style={{
            aspectRatio: aspectRatio,
          }}
        />
      </_AcqCategoryItemImage>
      {children}
    </_AcqCategoryItemRoot>
  );
};

export default AcqCategoryItem;
