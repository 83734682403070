import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export const playListVariantStyles = StyleSheet.create({
  primary: {
    backgroundColor: defaultTheme.playList.primary,
  },
  secondary: {
    backgroundColor: defaultTheme.playList.secondary,
  },
  transparent: {
    backgroundColor: 'none',
  },
});

export const listenPlayListStyles = StyleSheet.create({
  container: {
    borderRadius: Metrics.moderateScale(4),
    paddingLeft: Metrics.horizontalScale(18),
    paddingTop: Metrics.horizontalScale(16),
    paddingBottom: Metrics.horizontalScale(16),
    paddingRight: Metrics.horizontalScale(24),
  },
  description: {
    marginTop: Metrics.verticalScale(18),
    marginBottom: Metrics.verticalScale(33),
  },
  body: {
    marginTop: Metrics.verticalScale(13),
    flex: 1,
  },
});
