import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import IconWithText from '../../IconWithText/IconWithText';
import RoundedTextInput, {
  RoundedTextInputProps,
} from '../../TextInput/RoundedTextInput/RoundedTextInput';

interface IPasswordRule {
  label: string;
  regex: RegExp;
}

export interface PasswordControllerProps<
  TField extends FieldValues = FieldValues,
> extends Omit<RoundedTextInputProps, 'name'> {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
  rulesHelpers?: IPasswordRule[];
}

export default function PasswordController<
  TField extends FieldValues = FieldValues,
>(props: PasswordControllerProps<TField>) {
  const {
    name,
    label,
    rules,
    control,
    defaultValue,
    style,
    rulesHelpers,
    ...rest
  } = props;
  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules,
  });
  const isRuleValid = (regex: RegExp): boolean => regex.test(field.value || '');
  return (
    <View style={[{flex: 1}, style]}>
      <RoundedTextInput
        value={field.value}
        onChangeText={field.onChange}
        label={label}
        placeholderTextColor={'#fff'}
        {...rest}
        helperText={undefined}
      />
      {rulesHelpers && (
        <View style={PasswordControllerStyles.validationContainer}>
          {rulesHelpers.map((item, index) => {
            return (
              <IconWithText
                key={item.label + index}
                style={PasswordControllerStyles.validationItem}
                text={item.label}
                icon={'check'}
                color={isRuleValid(item.regex) ? 'primary' : 'gray'}
              />
            );
          })}
        </View>
      )}
    </View>
  );
}

const PasswordControllerStyles = StyleSheet.create({
  validationItem: {
    marginRight: Metrics.horizontalScale(18),
  },
  validationContainer: {
    flexDirection: 'row',
    marginTop: Metrics.verticalScale(12),
    marginBottom: Metrics.verticalScale(0),
    paddingLeft: Metrics.verticalScale(1),
  },
});
