import {createSlice, nanoid, PayloadAction} from '@reduxjs/toolkit';
import {useAppSelector} from '~Store/store';
interface TabBarSliceState {
  mount: boolean;
}

const initialState = {
  mount: true,
} as TabBarSliceState;

const tabBarSlice = createSlice({
  name: 'tabBar',
  initialState,
  reducers: {
    setMount: (state, action: PayloadAction<boolean>) => {
      state.mount = action.payload;
      return state;
    },
  },
});

export const useTabBarState = () => {
  return useAppSelector(state => state.tabBar) || {};
};

export default tabBarSlice;
