import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {
  ICollectionFragmentFragment,
  ICourseFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {mapHeroVideoCover} from './mapHeroVideoCover';

export const getCoverMedia = (
  cover: ICourseFragmentFragment['cover'],
): IMediaCover | undefined => {
  if (
    !!cover &&
    !!cover[0] &&
    !!cover[0].content &&
    cover[0].content?.__typename === 'HeroVideoCover'
  ) {
    return mapHeroVideoCover(cover[0].content);
  }
};
