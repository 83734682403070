import React, {FC} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Typography from '../../../../Components/Typography/Typography.web';
import {useTranslation} from 'gatsby-plugin-react-i18next';

interface PodcastSliderProps {
  played: number;
  playedSeconds: number;
  onSliderChange: (value: number | number[]) => void;
  duration: number;
  loaded: number;
  marginBottom?: number;
}

const PodcastSlider: FC<PodcastSliderProps> = ({
  played,
  playedSeconds,
  onSliderChange,
  duration,
  loaded,
  marginBottom = 32,
}) => {
  const formatTime = (seconds: number): string => {
    const date = new Date(0);
    date.setSeconds(seconds);
    const timeString = date.toISOString().substr(11, 8);
    return timeString.startsWith('00:') ? timeString.substr(3) : timeString;
  };

  const {t} = useTranslation(['videoPlayer']);
  return (
    <div
      style={{
        marginBottom: marginBottom,
        width: '100%',
      }}>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: 10,
        }}>
        <Typography variant="ui3">{formatTime(playedSeconds)}</Typography>
        <div
          style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={loaded}
            styles={{
              rail: {
                height: 4,
                backgroundColor: '#FFFFFF',
                opacity: 0.2,
              },
              track: {
                backgroundColor: '#FFFFFF',
                height: 4,
                opacity: 0.6,
              },
              handle: {
                display: 'none',
              },
            }}
            style={{position: 'absolute', zIndex: 1}}
          />
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={played}
            onChange={onSliderChange}
            onChangeComplete={onSliderChange}
            style={{position: 'absolute', zIndex: 2}}
            styles={{
              rail: {
                height: 4,
                backgroundColor: 'transparent',
              },
              track: {
                backgroundColor: '#FFF',
                height: 4,
              },
              handle: {
                borderColor: 'transparent',
                height: 16,
                width: 16,
                marginLeft: -2,
                marginTop: -6,
                backgroundColor: '#FFF',
              },
            }}
          />
        </div>
        <Typography variant="ui3">{formatTime(duration)}</Typography>
      </div>
    </div>
  );
};

export default PodcastSlider;
