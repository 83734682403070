import _ from 'lodash';
import React, {ReactNode, useMemo} from 'react';
import {View} from 'react-native';
import {
  IChoiceNuggetFragmentFragment,
  IGapfillNuggetFragmentFragment,
  ISummaryNuggetFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {useDispatch} from 'react-redux';
import {useCourseTestProgression} from '~Store/Slices/CourseUnitSlice/CourseUnit.selector';
import courseUnitSlice, {
  NuggetDataProgress,
} from '~Store/Slices/CourseUnitSlice/CourseUnit.slice';
import {mapNugget} from './mapLearnNugget';

interface IExamNugget {
  content:
    | IChoiceNuggetFragmentFragment
    | IGapfillNuggetFragmentFragment
    | ISummaryNuggetFragmentFragment;
  showResult?: boolean;
  testId?: string;
}

const ExamNugget = (props: IExamNugget) => {
  const {content: item, testId = '', showResult} = props;
  const courseTestProgression = useCourseTestProgression(testId);
  const dispatch = useDispatch();

  const handleChange = (nuggetId: string) => (data: NuggetDataProgress) => {
    dispatch(
      courseUnitSlice.actions.saveCourseTestNuggetAnswer({
        testId,
        nuggetId,
        data: data,
      }),
    );
  };

  const nugget: ReactNode = useMemo(() => {
    if (item && item.id) {
      const nuggetProgression = courseTestProgression[item.id];
      if (item.__typename === 'ChoiceNugget' && item.options) {
        return mapNugget(item, 1, {
          nuggetProgression,
          showResult,
          handleChange,
        });
      } else if (item.__typename === 'GapfillNugget') {
        return mapNugget(item, 1, {
          nuggetProgression,
          showResult,
          handleChange,
        });
      } else if (item.__typename === 'SumaryNugget') {
        return mapNugget(item, 1, {
          nuggetProgression,
          showResult,
          handleChange,
        });
      }
    } else {
      return null;
    }
  }, [item, mapNugget, courseTestProgression]);
  return <View>{nugget}</View>;
};

export default ExamNugget;
