import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';
import {IMemberSavedBody} from './interface/memberDataBody.interface';
import {
  ICountSavedResponse,
  ISavedResponse,
} from './interface/memberDataResponse.interface';

const endpoint = '/me/saved';
export const createMemberSavedEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getAllMemberSaved: builder.query<ISavedResponse[], void>({
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  createMemberSaved: builder.query<ISavedResponse, {data: IMemberSavedBody}>({
    query: ({data}) => ({
      url: endpoint,
      method: 'POST',
      body: data,
    }),
  }),
  deleteMemberSaved: builder.query<ISavedResponse, {id: string}>({
    query: ({id}) => ({
      url: endpoint + `/${id}`,
      method: 'DELETE',
    }),
  }),
  deleteMemberSavedByContentId: builder.query<
    ISavedResponse,
    {contentId: string}
  >({
    query: ({contentId}) => ({
      url: endpoint + `/content/${contentId}`,
      method: 'DELETE',
    }),
  }),
  countSavedById: builder.query<ICountSavedResponse, {id: string}>({
    query: ({id}) => ({
      url: endpoint + `/total/${id}`,
      method: 'GET',
    }),
  }),
});
