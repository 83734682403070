import {useLanguages} from '@src/Store/Slices/LanguageSlice/Language.selector';
import SelectController, {
  SelectControllerProps,
} from 'design-system/src/Components/Form/Select/SelectController.web';
import React from 'react';
import {FieldValues} from 'react-hook-form';

interface ILanguageSelectorProps<TField extends FieldValues = FieldValues>
  extends Omit<SelectControllerProps<TField>, 'options'> {}

const LanguageSelector = (props: ILanguageSelectorProps) => {
  const languages = useLanguages();
  return (
    <SelectController
      {...props}
      options={languages.map(lang => ({
        label: lang.cultureName,
        value: lang.isoCode,
      }))}
    />
  );
};

export default LanguageSelector;
