import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import CustomFastImage from '../CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../CustomFastImage/CustomFastImageProps';
import Typography from '../Typography/Typography';
import ServiceFormChannelItems from './ServiceFormChannelItems';

export type ServiceFormChannelData = {
  id: string;
  uid: string;
  logo: CustomFastImageProps['source'];
};

export type ServiceFormChannelProps = {
  title: string;
  lang?: string;
  data: ServiceFormChannelData[];
  onChange?: (value: Array<string>, isSelected?: boolean) => void;
  mt?: number;
  value?: Array<string>;
};

export default function ServiceFormChannel(props: ServiceFormChannelProps) {
  const {title, lang, data, mt = 48, onChange, value: inValue} = props;

  const [value, setValue] = useState(inValue);

  useEffect(() => {
    if (inValue !== value) {
      setValue(inValue);
    }
  }, [inValue]);

  return (
    <View>
      <View
        style={{
          marginTop: Metrics.horizontalScale(mt),
        }}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Typography variant="h3">{title}</Typography>
          {lang && (
            <Typography variant="ui3" color="gray" style={{marginLeft: 8}}>
              ({title})
            </Typography>
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: Metrics.horizontalScale(14),
            marginLeft: Metrics.verticalScale(-4),
            marginRight: Metrics.verticalScale(-4),
          }}>
          {data.map((d, index) => (
            <View
              key={`sfc-${index}`}
              style={{
                flexBasis: '33.333%',
                borderWidth: Metrics.verticalScale(4),
                borderColor: '#111216',
              }}>
              <ServiceFormChannelItems
                selectedNumber={value?.findIndex(e => e === d.id)}
                isChecked={value?.includes(d.id)}
                {...d}
                onChange={isSelected => {
                  let tempValue: Array<string> = [];
                  if (isSelected) {
                    tempValue = value ? [...value, d.id] : [d.id];
                  } else {
                    tempValue = value!.filter(e => e !== d.id);
                  }
                  setValue(tempValue);
                  onChange && onChange(tempValue);
                }}
              />
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}
