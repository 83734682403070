import {StyleSheet} from 'react-native';
import Metrics from '../../../../Theme/Metrics';

export const thumbnailTvGuideStyles = StyleSheet.create({
  container: {
    borderTopLeftRadius: Metrics.moderateScale(4),
    borderTopRightRadius: Metrics.moderateScale(4),
    // width: Metrics.horizontalScale(225),
    height: Metrics.verticalScale(96),
    backgroundColor: '#28292E',
    overflow: 'hidden',
  },
  body: {
    paddingHorizontal: Metrics.horizontalScale(8),
    paddingTop: Metrics.verticalScale(5),
    flex: 1,
  },
  title: {
    marginTop: Metrics.verticalScale(4),
    marginBottom: Metrics.verticalScale(6),
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});
