import React, {ReactNode, useEffect, useRef} from 'react';
import {_AppHeaderRoot} from './AppHeader.styled';
import {TraceLogo} from 'design-system/src/assets/ImageSvg';
import MenuNavigation, {
  IMenuNavigationItem,
} from '@src/Umbraco/Pages/HomePage/MenuNavigation/MenuNavigation';
import MenuActionHeader from './MenuActionHeader';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {appNavigate} from '@src/utils/navigationUtils';
import {AppRoutesEnum} from '@src/Constant/routes';
import {useAppSelector} from '@src/Store/hooks';
import AppSubMenuLeft from './AppSubMenuLeft';
import SubMenuItem from './SubMenuItem';
import useRouteActive from './useRouteActive';
import {appRoutes} from '@src/App/Route/AppRouter/AppRoutes';
import {IconName} from 'design-system/src/assets/Icones';
import {ITabBarHeaderItem} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import {Animated, StyleSheet, View} from 'react-native';
import useHeaderAnimation from '@src/App/components/Common/Header/useHeaderAnimation';
import Metrics from 'design-system/src/Theme/Metrics';
import CustomBlurView from 'design-system/src/Components/CustomBlurView/CustomBlurView';
import useScrollHeaderAnimation from './useScrollHeaderAnimation';
import {CSSTransition} from 'react-transition-group';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useAppNavigation from '@src/utils/useAppNavigation';

export interface IAppHeaderOptions {
  isWithCast?: boolean;
  // onPressAccountIcon?: () => void;
  onPressLogo?: () => void;
  // onPressSearchIcon?: () => void;
  onPressCastIcon?: () => void;
  onPressArrowBack?: () => void;
  subMenu?: ITabBarHeaderItem[];
  isAnimated?: boolean;
  isCenterTitle?: boolean;
  hideAction?: boolean;
  isWithLogo?: boolean;
  extraAction?: ReactNode;
  overrideTitle?: ReactNode | ((title: string) => ReactNode);
  backIcon?: IconName;
  activeProfile?: boolean;
  activeSearch?: boolean;
  unRead?: number;
  noBack?: boolean;
  middleContent?: ReactNode;
  showBack?: boolean;
  disableGoBack?: boolean;
  isCastLoading?: boolean;
  castConnect?: boolean;
  back?: {
    title: string;
    icon?: IconName;
  };
}

const statusBarHeight = 0;

const headerHeight = statusBarHeight + Metrics.verticalScale(70);
const headerHeightWithSubMenu = headerHeight + Metrics.horizontalScale(26);

const AppHeader = () => {
  const {isMobile} = useBreakpoint();
  const {t} = useTranslation('menu');
  const appNavigation = useAppNavigation();
  const {isRouteActive} = useRouteActive();
  const {interpolatedOpacity} = useHeaderAnimation();
  const heightAnim = useRef(new Animated.Value(0)).current;
  const isVisible = useScrollHeaderAnimation(100);

  const {topMenu} = useAppSelector(state => state.app);

  const isTopMenu = topMenu && topMenu.length > 0;

  useEffect(() => {
    Animated.timing(heightAnim, {
      toValue: isVisible && isTopMenu ? headerHeightWithSubMenu : headerHeight, // Adjust the value as needed
      duration: 500, // Duration of the animation
      useNativeDriver: false,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <_AppHeaderRoot>
      <div className="logo">
        {isMobile ? (
          <AppSubMenuLeft />
        ) : (
          <div
            className="logo-container"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => appNavigation.navigate(AppRoutesEnum.HOME)}>
            <TraceLogo width={136.6} height={24} />
          </div>
        )}
      </div>

      <div className="menu-app-header">
        {!isMobile && (
          <MenuNavigation
            list={appMenuItems.map(item => ({
              ...item,
              /* i18next-extract-disable-next-line */
              label: t(item.label),
              isActive: isRouteActive(item.route),
              route: undefined,
              translate: false,
            }))}
          />
        )}
        <CSSTransition
          in={isMobile ? isVisible : true}
          timeout={500}
          classNames="fade"
          unmountOnExit>
          <div className="sub-menu-app-header">
            {!isMobile && <AppSubMenuLeft />}
            {topMenu && topMenu.length > 0 && (
              <div className="sub-menu-center">
                {topMenu.map((item, index) => (
                  <SubMenuItem
                    key={index}
                    title={item.title}
                    onClick={item.onClick}
                    children={item.children}
                    color={item.color}
                  />
                ))}
              </div>
            )}
            <div />
          </div>
        </CSSTransition>
      </div>
      <div className="menu-action">
        <MenuActionHeader
          iconName="search"
          onClick={() => {
            appNavigate(AppRoutesEnum.SEARCH);
          }}
        />
        <MenuActionHeader
          iconName="profil"
          onClick={() => {
            appNavigate(AppRoutesEnum.PROFILE_HOME);
          }}
        />
      </div>
      {isMobile && (
        <View
          style={[
            {
              width: '100%',
              minHeight: isTopMenu ? headerHeightWithSubMenu : headerHeight,
            },
          ]}>
          <View style={{height: 10}} />
          <Animated.View
            style={[
              StyleSheet.absoluteFill,
              {
                opacity: interpolatedOpacity,
                overflow: 'hidden',
                height: heightAnim,
                zIndex: 1,
              },
            ]}>
            <CustomBlurView
              blurType="dark"
              blurAmount={20}
              style={[StyleSheet.absoluteFill, {zIndex: 1}]}
            />
          </Animated.View>
        </View>
      )}
    </_AppHeaderRoot>
  );
};

export default AppHeader;

export const appMenuItems: (IMenuNavigationItem & {
  route?: Array<AppRoutesEnum | string>;
})[] = [
  {
    label: 'Home',
    icon: 'home',
    route: [AppRoutesEnum.HOME],
    onClick: () => {
      appNavigate(AppRoutesEnum.HOME);
    },
  },
  {
    label: 'Watch',
    icon: 'watch',
    route: [
      AppRoutesEnum.HOME_WATCH,
      AppRoutesEnum.VOD_CATEGORY,
      AppRoutesEnum.HOME_WATCH + '/:subpath',
      ...Object.entries(appRoutes)
        .filter(([, value]) => value.parent === AppRoutesEnum.HOME_WATCH)
        .map<AppRoutesEnum>(([k]) => k as AppRoutesEnum),
    ],
    onClick: () => {
      appNavigate(AppRoutesEnum.HOME_WATCH);
    },
  },
  {
    label: 'Listen',
    icon: 'listen',
    route: [
      AppRoutesEnum.HOME_LISTEN,
      AppRoutesEnum.HOME_LISTEN + '/:subpath',
      ...Object.entries(appRoutes)
        .filter(([, value]) => value.parent === AppRoutesEnum.HOME_LISTEN)
        .map<AppRoutesEnum>(([k]) => k as AppRoutesEnum),
    ],
    onClick: () => {
      appNavigate(AppRoutesEnum.HOME_LISTEN);
    },
  },
  {
    label: 'Learn',
    icon: 'learn',
    route: [
      AppRoutesEnum.HOME_LEARN,
      AppRoutesEnum.HOME_QUIZ,
      AppRoutesEnum.QUIZ_METHODOLOGY,
      AppRoutesEnum.QUIZ_PERSONALITY,
      AppRoutesEnum.QUIZ_QUESTION,
      ...Object.entries(appRoutes)
        .filter(([, value]) => value.parent === AppRoutesEnum.HOME_LEARN)
        .map<AppRoutesEnum>(([k]) => k as AppRoutesEnum),
    ],
    onClick: () => {
      appNavigate(AppRoutesEnum.HOME_LEARN);
    },
  },
  {
    label: 'Menu',
    icon: 'more',
    route: [
      AppRoutesEnum.MORE,
      ...Object.entries(appRoutes)
        .filter(([, value]) => value.parent === AppRoutesEnum.MORE)
        .map<AppRoutesEnum>(([k]) => k as AppRoutesEnum),
    ],
    onClick: () => {
      appNavigate(AppRoutesEnum.MORE);
    },
  },
];
