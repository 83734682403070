import {Pressable, StyleProp, ViewStyle} from 'react-native';
import React from 'react';
import PremiumCard, {
  PremiumCardProps,
} from '../../Components/Card/PremiumCard/PremiumCard';
import Metrics from '../../Theme/Metrics';
import CustomFastImage from '../../Components/CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../../Components/CustomFastImage/CustomFastImageProps';
import {ImageStyle} from 'react-native-fast-image';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

export interface IPushProps
  extends Omit<PremiumCardProps, 'variant' | 'customIcon'> {
  style?: 'dark' | 'light';
  icon?: CustomFastImageProps['source'];
  containerStyle?: StyleProp<ViewStyle>;
  imageStyle?: StyleProp<ImageStyle>;
}

const Push = (props: IPushProps) => {
  const {
    style = 'dark',
    icon,
    cover,
    coverDesktop,
    containerStyle,
    imageStyle,
    onPress,
    ...rest
  } = props;
  const {mobile} = useBreakpoint();
  const iconSize =
    !rest.responsiveRatio || mobile
      ? Metrics.horizontalScale(72)
      : Metrics.horizontalScale(130);
  const padding =
    !rest.responsiveRatio || mobile
      ? Metrics.verticalScale(16)
      : Metrics.verticalScale(60);
  return (
    <Pressable onPress={onPress} style={[{padding: padding}, containerStyle]}>
      <PremiumCard
        onPress={onPress}
        variant={
          cover || coverDesktop
            ? 'quaternary'
            : style === 'light'
              ? 'secondary'
              : 'primary'
        }
        customIcon={
          icon
            ? () => (
                <CustomFastImage
                  source={icon}
                  width={iconSize}
                  height={iconSize}
                  style={imageStyle}
                  backgroundFallbackColor="transparent"
                  resizeMode="contain"
                />
              )
            : undefined
        }
        cover={cover}
        coverDesktop={coverDesktop}
        {...rest}
      />
    </Pressable>
  );
};

export default Push;
