import React from 'react';
import {
  StyleSheet,
  Pressable,
  PressableProps,
} from 'react-native';
import Typography from '../../../Typography/Typography';
import {vodThemeStyles, vodThemeVariant} from './VodThemeCard.styles';

export interface VodThemeCardProps extends PressableProps {
  text: string;
  variant?: keyof typeof vodThemeVariant;
}

const VodThemeCard = (props: VodThemeCardProps) => {
  const {text, variant = 'primary', ...restProps} = props;
  const variantStyle = vodThemeVariant[variant];

  return (
    <Pressable
      {...restProps}
      style={StyleSheet.flatten([vodThemeStyles.container, variantStyle])}>
      <Typography children={text.toUpperCase()} variant="h4" />
    </Pressable>
  );
};

export default VodThemeCard;
