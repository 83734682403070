import useEvent from '@src/Hooks/useEvent';
import useLocalStorage from 'design-system/src/Provider/DebugModeProvider/LocalStorage/useLocalStorage';
import {Dispatch} from 'react';

const useAppVideoPlayerStorage = (): [
  Record<string, any>,
  Dispatch<Record<string, any>>,
] => {
  const [videoPlayerArgs, setStorage] = useLocalStorage('v-params', '{}');

  const setVideoPlayerArgs = useEvent((newValue: Record<string, any>) => {
    setStorage(JSON.stringify(newValue));
  });
  return [JSON.parse(videoPlayerArgs || '{}'), setVideoPlayerArgs];
};

export default useAppVideoPlayerStorage;
