import React, {useEffect, useMemo} from 'react';
import {useThankYouPageByIdLazyQuery} from '~Api/Graphql/gql/types.generated';
import useThankYouPageMapper, {
  IThankYouPageData,
} from './useThankYouPageMapper';
import {HomeContentLoader} from 'design-system/src/Components/ContentLoader';
import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {mapHeroVideoCover} from '~Umbraco/Utils/mapHeroVideoCover';
import {View, Pressable} from 'react-native';
import FormHeader from '../FormPage/FormHeader/FormHeader';
import Icon from 'design-system/src/Components/Icon/Icon';
import useNavigation from '@src/Navigation/useNavigation';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import Button from 'design-system/src/Components/Button/Button';
import useViewPort from 'design-system/src/Hooks/useViewPort';
import appSlice from '@src/Store/Slices/AppSlice';
import {useDispatch} from 'react-redux';

export type IThankYouPagePassedData = {
  title?: string;
  body?: string;
  cover: IMediaCover | undefined;
  id?: string;
  redirectTo?: string;
};
interface IThankYouPageProps {
  id?: string;
  loading?: boolean;
  buttonFallBack?: {
    disabled?: boolean;
    loading?: boolean;
    title?: string;
    onPress?: () => void;
  };
  onClose?: () => void;
  fullScreen?: boolean;
  contentWidth?: number;
  data?: IThankYouPagePassedData;
}

const ThankYouPage = (props: IThankYouPageProps) => {
  const {
    id,
    buttonFallBack,
    loading: loadingButton,
    fullScreen = false,
    onClose,
    contentWidth = 487,
    data: passedData,
  } = props;
  const dispatch = useDispatch();
  const closable = !!onClose;
  // const contentWidth = 487;
  const contentPadding = 16;
  const navigation = useNavigation();
  const [fetchThankYouPage, {data, loading}] = useThankYouPageByIdLazyQuery();
  const {mapThankYouPage} = useThankYouPageMapper();
  const {viewportHeight} = useViewPort();

  useEffect(() => {
    if (id) {
      fetchThankYouPage({
        variables: {
          id: id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const {
    pageData,
    mediaCover,
    redirectTo,
  }: {
    pageData: IThankYouPageData | undefined;
    mediaCover?: IMediaCover;
    redirectTo?: string;
  } = useMemo(() => {
    let _pageData: IThankYouPageData | undefined;
    let _mediaCover: IMediaCover | undefined;
    let _redirectTo: string | undefined;

    if (passedData) {
      _pageData = {
        id: 'unknown',
        __typename: 'ThankYouPage',
        title: passedData.title,
        body: passedData.body,
      };
      _redirectTo = passedData.redirectTo;
      _mediaCover = passedData.cover;
    }

    // get data from query
    if (
      data &&
      data.thankYouPage &&
      data.thankYouPage.__typename === 'ThankYouPage'
    ) {
      const {cover} = data.thankYouPage;
      _pageData = mapThankYouPage(data.thankYouPage);
      if (
        cover &&
        cover[0] &&
        cover[0].content &&
        cover[0].content.__typename === 'HeroVideoCover'
      ) {
        _mediaCover = mapHeroVideoCover(cover[0].content);
      }
    }
    return {
      pageData: _pageData,
      mediaCover: _mediaCover,
      redirectTo: _redirectTo,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, passedData]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigation.navigate(redirectTo || '/');
    }
  };

  const handleButtonPress = () => {
    dispatch(appSlice.actions.closeThankYouModal());
    if (pageData?.buttonData?.onPress) {
      pageData.buttonData.onPress();
    } else if (buttonFallBack?.onPress) {
      buttonFallBack.onPress();
    } else {
      handleClose();
    }
  };

  if (loading) {
    return <HomeContentLoader />;
  }

  const buttonTitle =
    pageData?.buttonData?.title ||
    buttonFallBack?.title ||
    // @ts-ignore
    pageData?.cTA?.[0]?.content?.title ||
    '';

  return (
    <div
      style={{
        width: '100%',
        maxWidth: fullScreen ? '100%' : 1142,
        borderRadius: 4,
        background: 'var(--background, #111216)',
        position: 'relative',
        margin: '0 auto',
        overflow: 'hidden',
        marginTop: fullScreen ? 0 : 40,
        minHeight: fullScreen
          ? viewportHeight
          : `calc(${viewportHeight}px - 40px)`,
      }}>
      <View
        style={{
          position: 'relative',
          paddingBottom: '',
          height: fullScreen ? viewportHeight : undefined,
        }}>
        <View
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: 'auto',
          }}>
          <FormHeader
            cover={
              pageData?.coverUrl
                ? {
                    uri: pageData?.coverUrl,
                  }
                : undefined
            }
            coverDesktop={
              pageData?.coverUrlDesktop
                ? {
                    uri: pageData?.coverUrlDesktop,
                  }
                : undefined
            }
            // title={}
          />
        </View>
        {closable && (
          <View
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              paddingTop: 20,
              paddingRight: 33,
              zIndex: 1050,
            }}>
            <Pressable onPress={handleClose}>
              <Icon name="close" />
            </Pressable>
          </View>
        )}
        <View
          style={{
            //   position: 'absolute',
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            zIndex: 0,
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: contentWidth,
            paddingHorizontal: contentPadding,
            marginHorizontal: 'auto',
            paddingTop: 111,
          }}>
          <Typography
            style={{
              marginBottom: 31,
              textAlign: 'center',
            }}
            children={pageData?.title}
            variant="formTitle"
          />
          <Typography
            children={pageData?.subtitle || ''}
            variant="body1"
            style={{textAlign: 'center', marginBottom: 12}}
          />
          <Typography
            children={pageData?.body || ''}
            variant="body3"
            style={{textAlign: 'center', marginBottom: 43}}
            color={() => '#A0A0A0'}
          />

          {!!buttonTitle && (
            <Button
              title={buttonTitle}
              variant="contained"
              size="medium"
              isLoading={loadingButton}
              onPress={handleButtonPress}
            />
          )}
        </View>
      </View>
    </div>
  );
};
/* <MediaCover
  isActive={true}
  cover={mediaCover}
  absoluteChilren={() => (
    <ServiceHeader
      key={'header'}
      topSpace={Metrics.verticalScale(389)}
      title={pageData?.title || ''}
      // animatedValue={AnimatedHeaderValue}
      hideCover={true}
      image={{
        uri: pageData?.coverUrl || '',
      }}
      description={pageData?.subtitle || ''}
      buttonTitle={pageData?.buttonData?.title || buttonFallBack?.title}
      onPressButton={
        pageData?.buttonData?.onPress || buttonFallBack?.onPress
      }
      buttonProps={{
        size: 'medium',
        variant: buttonFallBack?.disabled ? 'contained' : 'outlined',
        isLoading: loadingButton,
      }}
    />
  )}
/> */

export default ThankYouPage;
