import React from 'react';
import {TextStyle, View, StyleSheet, ViewStyle, StyleProp} from 'react-native';
import defaultTheme from '../../Theme/defaultTheme';
import Icon, {IIconProps} from '../Icon/Icon';
import Typography from '../Typography/Typography';
import { ITypographyProps, typographyGetColor } from '../Typography/Typography.props';
import {iconWithTextStyles} from './IconWithText.styles';
export interface IconWithTextProps {
  text: string;
  icon: IIconProps['name'];
  size?: IIconProps['size'];
  color?: ITypographyProps['color'];
  textColor?: string;
  textStyle?: TextStyle;
  variant?: ITypographyProps['variant'];
  style?: StyleProp<ViewStyle>;
}

const IconWithText: React.FC<IconWithTextProps> = ({
  text,
  icon,
  size,
  color,
  textColor,
  textStyle,
  variant = 'ui3',
  style,
}) => {
  const finalTextColor = textColor || defaultTheme.gray;
  return (
    <View style={[iconWithTextStyles.container, style]}>
      <Icon
        name={icon}
        size={size}
        color={color ? typographyGetColor(color) : finalTextColor}
      />
      <Typography
        variant={variant}
        color={color || (() => finalTextColor)}
        style={StyleSheet.flatten([textStyle, iconWithTextStyles.text])}>
        {text}
      </Typography>
    </View>
  );
};

export default IconWithText;
