import React from 'react';
import { View, Platform } from 'react-native';
import Metrics from '../../Theme/Metrics';
import Typography from '../Typography/Typography';
import { ITypographyProps } from '../Typography/Typography.props';

export type ServiceTitleProps = {
  title: string;
  description?: string;
  titleVariant?: ITypographyProps['variant']
};

export default function ServiceTitle(props: ServiceTitleProps) {
  const {title, description, titleVariant= 'h2'} = props;
  return (
    <View>
      <Typography
        variant={titleVariant}
        style={{
          textAlign: 'center',
          maxWidth: Platform.OS === "web" ? 340 : Metrics.horizontalScale(264),
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        {title}
      </Typography>
      {description && (
        <Typography
          variant="body3"
          color="gray"
          style={{
            textAlign: 'center',
            marginTop: Metrics.horizontalScale(15),
            marginBottom: Platform.OS === "web" ? 16 : Metrics.horizontalScale(38),
            maxWidth: Platform.OS === "web" ? 446 : Metrics.horizontalScale(300),
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          {description}
        </Typography>
      )}
    </View>
  );
}
